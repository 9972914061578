import { useState } from "react";
import {
  Typography,
  InputLabel,
  Select,
  FormControl,
  MenuItem,
  Box,
} from "@mui/material";
import { useSelector } from "react-redux";
import CachedIcon from "@mui/icons-material/Cached";
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import axios from "axios";

const TimezoneSelect = ({
  options,
  timezone,
  setTimezone,
  availabilities,
  changeLocal,
  specialDisable,
  specialDisabled2,
  specificWidth,
}) => {
  const sessionName = useSelector((state) => state.sessions.currentSession);
  const [timezoneLoading, setTimezoneLoading] = useState(false);

  const handleTimezoneChange = async (e) => {
    try {
      if (changeLocal) {
        setTimezone(e.target.value);

        return;
      }
      if (timezone !== e.target.timezone) {
        setTimezoneLoading(true);
        const resp = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/data/change-timezone`,
          {
            timezone: e.target.value,
            availabilities: availabilities,
            sessionName: sessionName,
          },
          { withCredentials: true }
        );
        setTimezone(e.target.value);
        setTimezoneLoading(false);
      }
    } catch (e) {}
  };
  return (
    <FormControl
      disabled={
        timezoneLoading || Boolean(specialDisable) || Boolean(specialDisabled2)
      }
      size="small"
      sx={{ width: specificWidth ? specificWidth : "15rem" }}
      variant="filled"
    >
      <InputLabel id="role-label">TimeZone</InputLabel>
      <Select
        value={timezone}
        onChange={(e) => handleTimezoneChange(e)}
        MenuProps={{
          PaperProps: {
            style: {
              marginTop: "1.5px",
              maxHeight: 150, // Set the desired max height for the dropdown menu
              width: "auto",
              maxWidth: "100%",
            },
          },
        }}
        sx={{
          maxWidth: "100%", // Set the desired max-width for the input element
          "& .MuiTypography-body2": {
            whiteSpace: "wrap", // Prevent text from wrapping or shrinking
          },
        }}
      >
        {options.map(function (option) {
          return (
            <MenuItem key={option.value} value={option.value}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography mr={1.5} variant="body2">
                  {option.label}
                </Typography>
                {getUnicodeFlagIcon(option.countryCode)}
                {timezoneLoading && (
                  <>
                    <CachedIcon
                      className="notary-rotate"
                      sx={{ color: "#004976", ml: 1 }}
                      width={25}
                    />
                  </>
                )}
              </Box>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default TimezoneSelect;
