import React, { useState } from "react";
import { DateTime } from "luxon";
import {
  AppBar,
  Button,
  Dialog,
  IconButton,
  Toolbar,
  Typography,
  Chip,
  Box,
  useMediaQuery,
  Stack,
  Collapse,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
} from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import CloseIcon from "@mui/icons-material/Close";
import SwipeableViews from "react-swipeable-views";
import Grow from "@mui/material/Grow";
import MobileFooter from "../../layout/MobileFooter";

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Grow
      ref={ref}
      {...props}
      timeout={{
        enter: 500, // Duration for the enter animation in milliseconds
        exit: 300, // Duration for the exit animation
      }}
      style={{
        transformOrigin: "center",
        transitionDelay: props.in ? "0ms" : "200ms", // Delay the exit animation
      }}
    />
  );
});

const formatDate = (dateStr) => {
  // Assuming dateStr is in ISO format or a compatible format
  return DateTime.fromISO(dateStr).toLocaleString(DateTime.DATE_SHORT);
};

const InvoiceModal = React.memo((props) => {
  const miniSize = useMediaQuery("(max-width:350px)");

  return (
    <Dialog
      fullScreen={props?.tabletSize}
      open={props?.rowOpen}
      onClose={props?.handleClose}
      TransitionComponent={Transition}
    >
      <AppBar
        sx={{
          position: "relative",
          backgroundColor: "#333333",
          pl: props?.mobileSize ? 2 : undefined,
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={props?.handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Subscription Invoice
          </Typography>
        </Toolbar>
      </AppBar>

      <SwipeableViews
        axis="x"
        index={props?.rowIndex}
        onChangeIndex={props?.handleSwipe}
        enableMouseEvents
        style={{
          height: "calc(100% - 110px)",
          overflow: props?.smallHeight ? "auto" : "hidden",
        }}
      >
        {props?.invoiceRow?.map((row, index) => {
          return (
            <React.Fragment key={row.id}>
              <Box
                sx={{
                  p: 2,
                  pt: 1,
                  height: "100vh",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Chip
                    sx={{ fontWeight: "bold" }}
                    label={`Invoice: ${row?.id}`}
                    color="primary"
                  />
                  <Typography variant="h5">
                    {props?.rowIndex + 1}/{props?.invoiceRow.length}
                  </Typography>
                </Box>

                <Box mt={3}>
                  <Typography align="center" variant="h5" color="primary">
                    {row?.billing_reason}
                  </Typography>
                </Box>

                {/* TABLE */}
                <Box
                  sx={{
                    mt: props?.iphone12Height ? 7 : 5,
                    mb: props?.iphone12Height ? 5 : undefined,
                    boxShadow: "0 8px 16px rgba(0,0,0,0.4)",
                    borderRadius: "15px",
                  }}
                >
                  <TableContainer sx={{ borderRadius: "15px" }}>
                    <Table aria-label="plan table">
                      <TableBody>
                        <TableRow>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            Plan
                          </TableCell>
                          <TableCell align="right">{row.plan}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            Price
                          </TableCell>
                          <TableCell align="right">{row.price}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            Status
                          </TableCell>
                          <TableCell align="right">{row.status}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            Interval
                          </TableCell>
                          <TableCell align="right">{row.interval}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            Date
                          </TableCell>
                          <TableCell align="right">{row.created}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
                {/* ******* */}
                <Box mt={4}>
                  <Button
                    fullWidth
                    href={row?.invoice_pdf}
                    target="_blank"
                    variant="contained"
                    color="primary"
                    endIcon={<CloudDownloadIcon />}
                  >
                    Download Invoice
                  </Button>
                </Box>
                <Box mt={2}>
                  <Button
                    href={row?.hosted_invoice_url}
                    target="_blank"
                    fullWidth
                    variant="contained"
                    color="warning"
                    endIcon={<OpenInNewIcon />}
                  >
                    View Invoice
                  </Button>
                </Box>
              </Box>
            </React.Fragment>
          );
        })}
      </SwipeableViews>

      <MobileFooter pos="bottom" pl="0.5rem" />
    </Dialog>
  );
});

export default InvoiceModal;
