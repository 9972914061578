import React, { useState } from "react";
import {
  Paper,
  Typography,
  Box,
  Card,
  Grid,
  List,
  ListItem,
  ListItemText,
  Stack,
  Tooltip,
  Button,
  TableCell,
  TableRow,
  TableBody,
  Table,
  TableHead,
  TableContainer,
} from "@mui/material";
import { Link } from "react-router-dom";
import { LoadingButton } from "@mui/lab";

const NotaryJobNotice = ({
  currentUser,
  closestJobDate,
  fiveMinBeforeSession,
}) => {
  return (
    <Card sx={{ backgroundColor: "#FCF2F0" }}>
      <Grid container px={6} sx={{ mt: 4 }}>
        <Grid item xs={12}>
          <Typography
            color="primary"
            align={fiveMinBeforeSession ? "center" : "left"}
            variant="h3"
          >
            {fiveMinBeforeSession
              ? "RON Session is Started..."
              : "Next Notarization Appointment"}
          </Typography>
          {fiveMinBeforeSession && (
            <Typography
              color="primary"
              variant="body1"
              fontWeight="bold"
              align="center"
            >
              You may join the session up to 5 minutes before the scheduled
              start time.
            </Typography>
          )}
          {fiveMinBeforeSession && (
            <Box align="center" mt={1}>
              <Button
                onClick={() => {
                  window.open(
                    currentUser?.closest_job?.notarization?.notarization
                      ?.bn_session?.notary_url,
                    "_blank"
                  );
                }}
                variant="contained"
              >
                Join Session
              </Button>
            </Box>
          )}
        </Grid>

        <>
          <Grid
            item
            sm={8}
            sx={{
              pl: "6rem",
              pt: 3,
              pb: 0,
            }}
          >
            {currentUser?.closest_job === "N/A" ? (
              <Typography variant="h6" sx={{ mt: 3 }}>
                There are no appointments scheduled at the moment. Please wait
                until you receive invitations to require notarization by email
                and text message.
              </Typography>
            ) : (
              <>
                <List>
                  <ListItem sx={{ paddingLeft: 0, textAlign: "center" }}>
                    <Typography
                      component="div"
                      variant="h5"
                      sx={{ display: "block" }}
                    >
                      Date and Time
                      <Typography
                        component="div"
                        sx={{ fontWeight: "bold", color: "#3b663c" }}
                      >
                        {closestJobDate}
                      </Typography>
                    </Typography>
                  </ListItem>

                  <Box sx={{ display: "flex" }}>
                    <ListItem sx={{ paddingLeft: 0 }}>
                      <Typography
                        component="div"
                        variant="body2"
                        sx={{ display: "block" }}
                      >
                        <Typography variant="h6">Signer</Typography>

                        <Stack direction="column">
                          <Typography component="span" variant="body2">
                            {currentUser?.closest_job?.signer
                              .charAt(0)
                              .toUpperCase() +
                              currentUser?.closest_job?.signer.slice(1)}
                          </Typography>
                        </Stack>
                      </Typography>
                    </ListItem>
                    <ListItem sx={{ paddingLeft: 0, maxWidth: "353.33px" }}>
                      <Typography
                        component="div"
                        variant="body2"
                        sx={{ display: "block" }}
                      >
                        <Typography variant="h6">Document Type</Typography>
                        <Stack direction="column">
                          <Typography component="span" variant="body2">
                            Permit Package
                          </Typography>
                          <Typography component="span" variant="body2">
                            {
                              currentUser?.closest_job?.document
                                ?.property_address
                            }
                          </Typography>
                          <Typography component="span" variant="body2">
                            {currentUser?.closest_job?.document?.county}
                          </Typography>
                          <Typography
                            component="a"
                            href={currentUser?.closest_job?.document?.pdf}
                            target="_blank"
                            rel="noopener noreferrer"
                            variant="body2"
                            sx={{
                              maxWidth: "100%",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {currentUser?.closest_job?.document?.pdf}
                          </Typography>
                        </Stack>
                      </Typography>
                    </ListItem>
                  </Box>
                  <Box sx={{ display: "flex" }}>
                    <ListItem sx={{ paddingLeft: 0 }}>
                      <Typography
                        component="div"
                        variant="body2"
                        sx={{ display: "block" }}
                      >
                        <Typography variant="h6">Signer Details</Typography>
                        <Stack direction="column">
                          <Typography component="span" variant="body2">
                            {`${
                              currentUser?.closest_job?.signer_details?.first ||
                              ""
                            } ${
                              currentUser?.closest_job?.signer_details
                                ?.middle || ""
                            } ${
                              currentUser?.closest_job?.signer_details?.last ||
                              ""
                            }`
                              .trim()
                              .replace(/\s+/g, " ")}
                          </Typography>
                          <Typography component="span" variant="body2">
                            {`${
                              currentUser?.closest_job?.signer_details
                                ?.address || ""
                            } ${
                              currentUser?.closest_job?.signer_details?.city ||
                              ""
                            } ${
                              currentUser?.closest_job?.signer_details?.state ||
                              ""
                            }  ${
                              currentUser?.closest_job?.signer_details?.zip ||
                              ""
                            }`
                              .trim()
                              .replace(/\s+/g, " ")}
                          </Typography>
                          <Typography component="span" variant="body2">
                            {currentUser?.closest_job?.signer_details?.mobile}
                          </Typography>
                          <Link href="mailto:martin-w@ww.com" color="inherit">
                            <Typography component="span" variant="body2">
                              {currentUser?.closest_job?.signer_details?.email}
                            </Typography>
                          </Link>
                        </Stack>
                      </Typography>
                    </ListItem>
                    <ListItem sx={{ paddingLeft: 0, maxWidth: "353.33px" }}>
                      <Typography
                        component="div"
                        variant="body2"
                        sx={{ display: "block" }}
                      >
                        <Typography variant="h6">Selected Documents</Typography>
                        <Stack
                          direction="column"
                          sx={{ height: "6rem", overflow: "auto" }}
                        >
                          {currentUser?.closest_job?.notarization?.notarization?.selected_documents?.map(
                            (document, index) => (
                              <Box
                                key={index}
                                sx={{
                                  display: "flex",
                                }}
                              >
                                <Typography component="span" variant="body2">
                                  <Typography component="span" variant="body2">
                                    {document?.name
                                      ?.replace(/_/g, " ")
                                      .replace(/\b\w/g, (char) =>
                                        char.toUpperCase()
                                      )}
                                  </Typography>
                                </Typography>
                              </Box>
                            )
                          )}
                        </Stack>
                      </Typography>
                    </ListItem>
                  </Box>
                </List>
              </>
            )}
          </Grid>
        </>

        {/* {closestJobLogs?.document?.document_logs?.length > 0 && (
          <>
            <Grid item xs={12} sx={{ mb: 4, mt: 3 }}>
              <Button
                onClick={() => {
                  setShowLogs(!showLogs);
                  setHostSelected(true);
                  setNotarySelected(false);
                  setSigneeSelected(false);
                }}
                variant="contained"
                color={showLogs ? "warning" : "primary"}
                fullWidth
              >
                {showLogs ? "Hide Logs" : "Show Logs"}
              </Button>
              {!showLogs && (
                <>
                  <Typography
                    mt={1}
                    align="center"
                    sx={{ fontWeight: "bold", color: "red" }}
                  >
                    {setCompletedText}
                  </Typography>
                  <LoadingButton
                    loading={setCompletedLoading}
                    sx={{ mt: 2 }}
                    onClick={handleSetCompleted}
                    variant="contained"
                    color={"success"}
                    fullWidth
                  >
                    Set As Completed
                  </LoadingButton>
                </>
              )}
            </Grid>
          </>
        )} */}

        <Grid item sm={4}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center", // Aligns the image to the right
              alignItems: "center", // Centers the image vertically
              height: "100%", // Ensures the Box takes the full height of the grid item
            }}
          >
            <img
              src="/img/logos/stamp.png"
              style={{ width: "60%", height: "auto" }}
              alt="Notary Public Stamp"
            />
            {/* Other component content */}
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};

export default NotaryJobNotice;
