import axios from "axios";
import { invalidateSession } from "./sessionsActions";

export const updateGuestRegisterData = (data) => {
  return {
    type: "GUEST_REGISTER_DATA",
    payload: data,
  };
};

export const updateGuestManageData = (data) => {
  return {
    type: "GUEST_MANAGE_DATA",
    payload: data,
  };
};

export const authGuest = (params) => async () => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/nocApi/auth/login-guest`,
      params,
      { withCredentials: true }
    );
    if (!res.data || res.data.success !== true) {
      return {
        success: false,
      };
    }

    if (res.data.success === true) {
      return {
        success: true,
        data: res.data,
      };
    } else {
      return {
        success: false,
      };
    }
  } catch (e) {
    return {
      success: false,
    };
  }
};

export const fetchGuestData = (sessionName) => async (dispatch, getState) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/nocApi/signee/get-data`,
      { sessionName: sessionName },
      { withCredentials: true }
    );
    console.log(res.data);
    if (res.data.registerData === true) {
      dispatch(updateGuestRegisterData(res.data.data));
    } else {
      dispatch(updateGuestManageData(res.data.data));
    }

    return res.data;
  } catch (error) {
    const { sessions } = getState();
    dispatch(invalidateSession(sessions.currentSession));
    return;
  }
};

export const setInstantRonAccepted = () => {
  return { type: "SET_INSTANT_RON_ACCEPTED" };
};

export const setInstantRonInitialState = () => {
  return { type: "SET_INSTANT_RON_INITIAL_STATE" };
};

export const setDocumentAcceptedInitialState = () => {
  return { type: "SET_DOCUMENT_ACCEPTED_INITIAL_STATE" };
};

export const setDocumentAccepted = (data) => {
  return { type: "SET_DOCUMENT_ACCEPTED", payload: data };
};

export const setInstantRonStop = () => {
  return { type: "SET_INSTANT_RON_STOP" };
};

export const setInstantRonSuccess = () => {
  return { type: "SET_INSTANT_RON_SUCCESS" };
};

export const updateSignerRonDate = (data) => {
  return { type: "UPDATE_SIGNER_RON_DATE", payload: data };
};

export const setSignerRonInitial = () => {
  return { type: "SIGNER_RON_DATE_SET_INITIAL" };
};

export const completedNotaryRecordGuestSignal = (data) => {
  return { type: "COMPLETED_NOTARY_RECORD_GUEST_SIGNAL", payload: data };
};

export const getNotarizationStatus = (data) => {
  return { type: "NOTARIZATION_COMPLETED_STATUS_SIGNAL", payload: data };
};

export const setNotarizationStatusInitial = (data) => {
  return { type: "NOTARIZATION_COMPLETED_INITIAL" };
};
