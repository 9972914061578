import {
  Typography,
  Box,
  Modal,
  Button,
  Grid,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const NotarizedModal = ({
  notarizedModal,
  setNotarizedModal,
  pdfImg2,
  pdfImg,
  previewImg,
  preview,
  type,
  largeTabletSize,
  tabletSize,
  mobileSize,
}) => {
  const handleIMG = () => {
    if (pdfImg2) {
      if (preview) {
        return (
          <>
            <img alt="noc-pdf" src={previewImg[0]} style={{ width: "100%" }} />
            <img alt="noc-pdf" src={previewImg[1]} style={{ width: "100%" }} />
          </>
        );
      } else {
        return (
          <>
            <img alt="noc-pdf" src={pdfImg} style={{ width: "100%" }} />
            <img alt="noc-pdf" src={pdfImg2} style={{ width: "100%" }} />;
          </>
        );
      }
    } else {
      if (preview) {
        return (
          <>
            <img alt="noc-pdf" src={previewImg[0]} style={{ width: "100%" }} />
          </>
        );
      } else {
        return (
          <>
            <img alt="noc-pdf" src={pdfImg} style={{ width: "100%" }} />
          </>
        );
      }
    }
  };
  return (
    <Modal
      open={notarizedModal}
      onClose={() => {
        setNotarizedModal(false);
      }}
    >
      <Box
        sx={
          type === "registerSignee"
            ? {
                overflowY: "auto",
                position: "relative",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: mobileSize ? "100%" : tabletSize ? "80%" : "40%",
                maxWidth: "39rem",
                maxHeight: mobileSize ? "100%" : tabletSize ? "90%" : "80%",
                bgcolor: "background.paper",
                boxShadow: 24,
                borderRadius: "1rem",
                p: 4,
              }
            : {
                overflowY: "auto",
                position: "relative",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "40%",
                maxHeight: "80%",
                bgcolor: "background.paper",
                boxShadow: 24,
                borderRadius: "1rem",
                p: 4,
              }
        }
      >
        <IconButton
          onClick={() => {
            setNotarizedModal(false);
          }}
          sx={{
            position: "absolute",
            top: 0,
            right: mobileSize && type === "registerSignee" ? 35 : 0,
          }}
        >
          <CloseIcon />
        </IconButton>

        <Grid item md={6} sx={{ maxHeight: "100vh", overflow: "auto" }}>
          <Box>{handleIMG()}</Box>
        </Grid>
      </Box>
    </Modal>
  );
};

export default NotarizedModal;
