import React, { useState, useEffect } from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import EngineeringIcon from "@mui/icons-material/Engineering";
import { useSelector } from "react-redux";

export const FunFact = ({ type, data }) => {
  const mobileSize = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const currentSession = useSelector(
    (state) => state?.sessions?.currentSession
  );

  const funFactsFromState = useSelector(
    (state) => state?.[currentSession]?.user?.displays?.funFacts
  );

  const funFacts = type === "accept" ? data : funFactsFromState;

  const [index, setIndex] = useState(
    funFacts && funFacts.length > 0
      ? Math.floor(Math.random() * funFacts.length)
      : 0
  );
  const [animatingOut, setAnimatingOut] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      // First, animate out
      setAnimatingOut(true);
      setTimeout(() => {
        // Change the fact after the scale down completes
        const nextIndex = Math.floor(Math.random() * funFacts?.length);
        setIndex(nextIndex);
        setAnimatingOut(false); // Reset to scale up the new fact
      }, 500); // This should match the duration of the scale down
    }, 10000); // Change index every 6 seconds to a random index
    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, [funFacts]);

  const fact = funFacts?.[index];

  return (
    <Box
      sx={{
        minHeight: "180px",
        maxWidth: "85%",
        margin: "auto",
        overflow: "hidden",
        boxShadow:
          "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)", // Example box-shadow
        borderRadius: "8px", // Rounded corners for the card
        backgroundColor: "background.paper", // Use theme colors for adaptability
        p: 2, // Padding around the content
        mt: 2, // Margin top for spacing from other elements
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mb: 1,
        }}
      >
        <Typography
          color="primary"
          variant={mobileSize ? "h5" : "h4"}
          align="center"
        >
          Fun Facts
        </Typography>
        <EngineeringIcon
          color="primary"
          sx={{ ml: 1.5, fontSize: mobileSize ? "28px" : "35px" }}
        />
      </Box>

      <Box
        key={index}
        sx={{
          textAlign: "center",
          animation: `${
            animatingOut ? "scaleDown" : "scaleUp"
          } 0.5s ease-out forwards`,
          "@keyframes scaleDown": {
            from: { transform: "scale(1)" },
            to: { transform: "scale(0)" },
          },
          "@keyframes scaleUp": {
            from: { transform: "scale(0)" },
            to: { transform: "scale(1)" },
          },
        }}
      >
        <Typography
          variant={mobileSize ? "h6" : "h5"}
          sx={{ fontWeight: "bold", mb: 1 }}
        >
          {fact?.title}
        </Typography>
        <Typography variant={mobileSize ? "body2" : "body1"}>
          {fact?.text}
        </Typography>
      </Box>
    </Box>
  );
};
