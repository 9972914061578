import { Card, CardHeader, CardContent, Typography } from "@mui/material";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { DateTime } from "luxon";
import { useSelector } from "react-redux";

export const TasksGrid = ({
  title,
  isSm,
  page,
  setPage,
  timezone,
  tasksDataRows,
  setRowsPerPage,
  rowsPerPage,
  setTasksData,
  totalRowsCount,
  setTotalRowsCount,
  sortModel,
  setSortModel,
  filterModel,
  setFilterModel,
  setSingleRegenerationData,
  setDisabledFields,
}) => {
  const currentSession = useSelector((state) => state.sessions.currentSession);
  const [errorText, setErrorText] = useState("");
  const [gridLoading, setGridLoading] = useState(false);

  useEffect(() => {
    const clearTextInterval = setInterval(() => {
      if (errorText !== "") {
        setErrorText("");
      }
    }, 3000);

    return () => {
      clearInterval(clearTextInterval); // Clear the interval when the component unmounts
    };
  }, [errorText]);

  const handleSaveRow = (params, event, details) => {
    const row = params.row;
    const scrapingFault = row.seleniumErrorType !== "" ? "Yes" : "No";
    setSingleRegenerationData((prevData) => ({
      ...prevData,
      taskId: row.task_id,
      contractor: row.contractorName,
      date: row.date,
      email: row.user,
      scrapingFault: scrapingFault,
      county: row.county,
      status: row.notificationStatus,
    }));
    setDisabledFields(false);
  };

  const handleFilterModelChange = async (model, details) => {
    // Extract filter field, operator, and value from the model
    if (model.items.length > 0) {
      setGridLoading(true);
      const filterItem = model.items[0];
      const field = filterItem.field;
      const operator = filterItem.operator;
      const value = filterItem.value;

      // Make API call with filter data
      try {
        const resp = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/admin/filtered_tasks`,
          {
            page: page,
            rowsPerPage: rowsPerPage,
            filters: {
              field: field,
              operator: operator,
              value: value,
            },
            sortModel: sortModel,
            currentSession: currentSession,
          },
          {
            withCredentials: true,
          }
        );

        if (resp.data.success) {
          setTasksData(resp.data.data);
          setTotalRowsCount(resp.data.rows_count);
          setFilterModel(filterItem);
        }
        setGridLoading(false);
      } catch (e) {
        setGridLoading(false);
      }
    }
  };

  const handleSortModelChange = (model, details) => {
    setSortModel(model);
    fetchData(page, rowsPerPage, model, filterModel);
  };

  const handlePaginationModelChange = (model, details) => {
    const newPage = model.page;
    const newRowsPerPage = model.pageSize;
    setPage(newPage);
    setRowsPerPage(newRowsPerPage);
    fetchData(newPage, newRowsPerPage, sortModel, filterModel);
  };

  const fetchData = async (newPage, newRowsPerPage, sortModel, filterModel) => {
    setGridLoading(true);
    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/admin/filtered_tasks`,
        {
          page: newPage,
          rowsPerPage: newRowsPerPage,
          filters: {
            field: filterModel?.field,
            operator: filterModel?.operator,
            value: filterModel?.value,
          },
          sortModel: sortModel,
          currentSession: currentSession,
        },
        {
          withCredentials: true,
        }
      );

      setTasksData(resp.data.data);
      setGridLoading(false);
    } catch (e) {
      setGridLoading(false);
    }
  };

  const taskDataColumns = [
    {
      field: "task_id",
      headerName: "Task ID",
      width: isSm ? 180 : 180,
    },
    {
      field: "date",
      headerName: "Date",
      width: isSm ? 170 : 180,
      valueFormatter: (params) => {
        // Convert the ISO date string to the user-selected timezone and format it
        const localDate = DateTime.fromISO(params.value).setZone(timezone);
        return localDate.toLocaleString(DateTime.DATETIME_SHORT);
      },
    },
    {
      field: "county",
      headerName: "County",
      width: isSm ? 180 : 180,
    },
    {
      field: "second_took",
      headerName: "Creation Time",
      width: isSm ? 160 : 180,
    },
    {
      field: "notificationStatus",
      headerName: "Status",
      width: isSm ? 180 : 180,
    },
    {
      field: "address",
      headerName: "Address",
      width: isSm ? 170 : 180,
    },
    {
      field: "contractorName",
      headerName: "Contractor Name",
      width: isSm ? 130 : 180,
    },
    {
      field: "seleniumErrorType",
      headerName: "Error Type",
      width: isSm ? 130 : 180,
    },
    {
      field: "seleniumErrorMessage",
      headerName: "Error Message",
      width: isSm ? 130 : 180,
    },
    {
      field: "message",
      headerName: "Message",
      width: isSm ? 130 : 180,
    },
    {
      field: "user",
      headerName: "User",
      width: isSm ? 130 : 180,
    },
  ];

  return (
    <Card sx={{ minHeight: "35rem" }}>
      <CardHeader title={title} />
      <CardContent>
        <DataGrid
          className="cell"
          sx={{
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
            minHeight: "24rem",
          }}
          rows={tasksDataRows ? tasksDataRows : []}
          columns={taskDataColumns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5, 10, 25, 50, 100]}
          // disableRowSelectionOnClick
          rowCount={totalRowsCount}
          pageSize={rowsPerPage}
          paginationMode="server"
          paginationModel={{
            page: page, // Set the current page value here
            pageSize: rowsPerPage, // Set the page size value here
          }}
          onPaginationModelChange={(model, details) => {
            handlePaginationModelChange(model, details);
          }}
          onFilterModelChange={(model, details) => {
            handleFilterModelChange(model, details);
          }}
          onSortModelChange={(model, details) => {
            handleSortModelChange(model, details);
          }}
          onRowClick={(params, event, details) => {
            handleSaveRow(params, event, details);
          }}
          loading={gridLoading}
        />

        <Typography
          variant="body1"
          color="#F2CD00"
          fontWeight="bold"
          textAlign="center"
          sx={{ mt: "1rem", minHeight: "1.5rem" }}
        >
          {errorText}
        </Typography>
      </CardContent>
    </Card>
  );
};
