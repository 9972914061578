import React from "react";
import {
  IconButton,
  Collapse,
  Box,
  Typography,
  TextField,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const ExpandableDefaults = ({
  open,
  setOpen,
  defaultValues,
  setDefaultValues,
}) => {
  return (
    <div style={{ width: "100%", position: "relative", textAlign: "center" }}>
      {/* IconButton centered horizontally at the top */}
      <IconButton
        onClick={() => setOpen(!open)}
        aria-expanded={open}
        aria-label="show more"
        style={{ zIndex: 1 }} // Ensure some space from the top of the container
      >
        <ExpandMoreIcon
          sx={{
            fontSize: "40px",
            transform: open ? "rotate(180deg)" : "rotate(0deg)",
            transition: "transform 0.3s",
          }}
        />
      </IconButton>

      {/* Collapse expands directly below the IconButton */}
      <Collapse in={open} sx={{ width: "100%" }}>
        <Box
          p={2}
          bgcolor="background.paper"
          boxShadow="0 4px 6px -1px rgba(0,0,0,0.3), 0 2px 4px -2px rgba(0,0,0,0.12)"
        >
          <Typography variant="h6">
            It is not necessary to type in the same values over and over again.
            You can simply set these values as defaults.
          </Typography>
          <Box mt={3}>
            <TextField
              sx={{ mb: "1rem" }}
              fullWidth
              label="General description of improvements"
              name="general"
              onChange={(e) =>
                setDefaultValues({
                  ...defaultValues,
                  general: e.target.value,
                })
              }
              value={defaultValues["general"]}
            />
            <TextField
              fullWidth
              label="Owner's interest in site of the improvement"
              name="interest"
              onChange={(e) =>
                setDefaultValues({
                  ...defaultValues,
                  interest: e.target.value,
                })
              }
              value={defaultValues["interest"]}
            />
          </Box>
        </Box>
      </Collapse>
    </div>
  );
};

export default ExpandableDefaults;
