import {
  Dialog,
  Grow,
  IconButton,
  Button,
  MobileStepper,
  Box,
  Typography,
  Chip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SwipeableViews from "react-swipeable-views";
import React, { useState } from "react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Grow
      ref={ref}
      {...props}
      timeout={{
        enter: 500, // Duration for the enter animation in milliseconds
        exit: 300, // Duration for the exit animation
      }}
      style={{
        transformOrigin: "center",
        transitionDelay: props.in ? "0ms" : "200ms", // Delay the exit animation
      }}
    />
  );
});

const MaximisePDF = ({ open, setOpen, fullScreen = true, imgs = [] }) => {
  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      TransitionComponent={Transition}
      PaperProps={{
        style: {
          backgroundColor: "white",
        },
      }}
      sx={{ overflow: "hidden" }}
    >
      {fullScreen && (
        <IconButton
          sx={{
            position: "fixed",
            top: 8,
            right: 8,
            zIndex: 1,
            backgroundColor: "#fff",
            border: "3px solid #000",
            zIndex: 99999,
          }}
          color="inherit"
          onClick={() => {
            setOpen(false);
          }}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      )}

      {imgs?.length > 1 && (
        <Chip
          sx={{
            fontWeight: "bold",
            position: "absolute",
            top: 15,
            left: 8,
            zIndex: 1,

            zIndex: 99999,
          }}
          label="2 Pages"
          color="primary"
        />
      )}

      <Box style={{ height: "100%" }}>
        {imgs?.map((image, idx) => (
          <Box
            key={idx}
            component="img"
            src={image}
            alt={`Form Page ${idx + 1}`}
            sx={{
              width: "100%",
              height: "99vh",
              objectFit: "fit",
            }}
          />
        ))}
      </Box>
    </Dialog>
  );
};

export default MaximisePDF;
