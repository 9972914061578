import * as yup from "yup";
import YupPassword from "yup-password";
import validator from "validator";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MultiStepForm, {
  FormStep,
} from "./registerSteps/multiStepForm/MultiStepForm";
import {
  Box,
  Stack,
  Link,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useDispatch } from "react-redux";
import axios from "axios";
import PersonalDetails from "./registerSteps/PersonalDetails";
import ContractorDetails from "./registerSteps/ContractorDetails";
import ChoosePlan from "./registerSteps/ChoosePlan";
import Additional from "./registerSteps/Additional";
YupPassword(yup);

const RegisterComponent = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const mobileSize = useMediaQuery(theme.breakpoints.down("sm"));
  const tabletSize = useMediaQuery(theme.breakpoints.down("md"));
  const [errorText, setErrorText] = useState("");
  const [terms, setTerms] = useState(false);
  // const [newsLetter, setNewsLetter] = useState(true);
  const [selectedName, setSelectedName] = useState("Trial");
  const [checked, setChecked] = useState(true);
  const [isLoad, setIsload] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState();
  const [tiers, setTiers] = useState([]);
  const remember = false;
  const [customLicenseType, setCustomLicenseType] = useState("");
  const [invite, setInvite] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [additionalData, setAdditionalData] = useState();
  const [page, setPage] = useState(0);
  const [buttons, setButtons] = useState(["New Contractor"]);
  const [disableInputs, setDisableInputs] = useState(true);
  const [duplicatedLicenses, setDuplicatedLicenses] = useState([]);
  const [disabledTabs, setDisabledTabs] = useState([]);
  const [validLicense, setValidLicense] = useState(false);
  const [licenseText, setLicenseText] = useState("");
  const [isEmailError, setIsEmailError] = useState(false);
  const [isPhoneError, setIsPhoneError] = useState(false);
  const [validationComplete, setValidationComplete] = useState(false);
  const [userInfoCopy, setUserInfoCopy] = useState();

  const [open, setOpen] = useState(false);

  const [promoCode, setPromoCode] = useState("");
  const [promoCodeLoading, setPromoCodeLoading] = useState(false);
  const [promoCodeName, setPromoCodeName] = useState("");
  const [invalidPromo, setInvalidPromo] = useState("");
  const [plans, setPlans] = useState([]);

  const [termsOnError, setTermsOnError] = useState(false);
  const [termsOpen, setTermsOpen] = useState(false);

  const [options, setOptions] = useState([]);
  const [personalAddress, setPersonalAddress] = useState("");
  const [contractorAddressState, setContractorAddressState] = useState("");

  const [contractorList, setContractorList] = useState([
    {
      licenseType: "",
      licenseNumber: "",
      contractorName: "",
      contractorAddress: "",
      contractorPhone: "",
      contractorFax: "",
      contractorEmail: "",
      validLicense: true,
    },
  ]);

  const [invitationRows, setInvitationRows] = useState([
    {
      name_1: "",
      email_1: "",
      name_1_error: false,
      email_1_error: false,
      disabled_1: false,
    },
    {
      name_2: "",
      email_2: "",
      name_2_error: false,
      email_2_error: false,
      disabled_2: true,
    },
    {
      name_3: "",
      email_3: "",
      name_3_error: false,
      email_3_error: false,
      disabled_3: true,
    },
    {
      name_4: "",
      email_4: "",
      name_4_error: false,
      email_4_error: false,
      disabled_4: true,
    },
    {
      name_5: "",
      email_5: "",
      name_5_error: false,
      email_5_error: false,
      disabled_5: true,
    },
  ]);
  let count = 0;

  const validationSchemaStep1 = yup.object({
    firstName: yup.string().min(3).required("First name is required"),
    lastName: yup.string().min(3).required("Last name is required"),
    address: yup
      .string()
      .required("Address is required")
      .test("address-test", "Address is required", function (value) {
        if (personalAddress === "" || personalAddress === null) {
          return false;
        } else {
          return true;
        }
      }),
    email: yup.string().email("Must be a valid email").required(),
    phone: yup
      .string()
      .required("Phone number is required")
      .test("PhoneTest", "Phone number is invalid", function (value) {
        const isValidPhoneNumber = validator.isMobilePhone(value);
        if (isValidPhoneNumber) {
          return true;
        } else {
          return false;
        }
      }),
    password: yup
      .string()
      .min(8, "Password must contain 8 or more characters")
      .max(255)
      .minLowercase(1, "Password must contain at least 1 lower case letter")
      .minUppercase(1, "Password must contain at least 1 upper case letter")
      .minNumbers(1, "Password must contain at least 1 number")
      .minSymbols(1, "Password must contain at least 1 special character")
      .required("Password is required"),
    confirmPassword: yup
      .string()
      .required("Password confirmation is required")
      .test("Passwords-match", "Passwords must match", function (value) {
        return this.parent.password === value;
      }),
  });

  // const validationSchemaStep2 = yup.object({
  //   licenseType: yup.string().required("License type is required"),
  //   licenseNumber: yup
  //     .string()
  //     .min(7, "License number must be at least 7 characters long.")
  //     .max(13, "License number must not exceed 13 characters.")
  //     .required("License number is required")
  //     .test(
  //       "customValidation",
  //       "LIcense number prefix doesn't correspond with the license type selected",
  //       function (value) {
  //         // Find the tuple where the first value matches customLicenseType
  //         const matchingTuple = additionalData?.combined_license_types.find(
  //           (tuple) => tuple[0] === customLicenseType
  //         );

  //         // If a matching tuple is found, apply specific validation logic
  //         if (matchingTuple) {
  //           const [, secondValue] = matchingTuple;

  //           // Check if the value starts with the second value from the tuple and the rest are numbers
  //           const regex = new RegExp(`^${secondValue}\\d+$`);
  //           return regex.test(value);
  //         }

  //         // No matching tuple found, invalid license number
  //         return false;
  //       }
  //     ),
  //   contractorName: yup
  //     .string()
  //     .min(4, "Contractor name is too short")
  //     .required("Contractor name is required"),
  //   contractorAddress: yup.string().required("Contractor Address is required"),
  //   // .test(
  //   //   "contractor-address-test",
  //   //   "Contractor Address is required",
  //   //   function (value) {
  //   //     if (
  //   //       contractorAddressState === "" ||
  //   //       contractorAddressState === null
  //   //     ) {
  //   //       return false;
  //   //     } else {
  //   //       return true;
  //   //     }
  //   //   }
  //   // ),
  //   contractorPhone: yup
  //     .string()
  //     .required("Phone number is required")
  //     .test("PhoneTest", "Phone number is invalid", function (value) {
  //       const isValidPhoneNumber = validator.isMobilePhone(value);
  //       if (isValidPhoneNumber) {
  //         return true;
  //       } else {
  //         return false;
  //       }
  //     }),
  //   contractorFax: yup
  //     .string()
  //     .matches(
  //       /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/,
  //       "Fax Number is Invalid"
  //     ),
  //   contractorEmail: yup.string().email("Must be a valid email"),
  // });

  const validationSchemaStep4 = yup.object({
    jobTitle: yup.string().required("Job Title is required"),
    referralSource: yup.string().required("Referral Source is required"),
    companyName: yup.string().required("Company Name is required"),
    companySize: yup.string().required("Company Size is Required"),
    yearsInBusiness: yup
      .number()
      .min(0, "Must be greater then or equal to 0")
      .max(60, "Should be less than or equal to 60")
      .required("Years in Business is Required"),
    // sector: yup.string().required("Sector is required"),
    // purpose: yup.string().required("Purpose of Registration is required"),
    // preferences: yup.string().required("Interests or Preferences is required"),
    revenue: yup.string().required("Revenue Range is required"),
    avgNoc: yup.string().required("Avg Monthly NOC is required"),
    topProject: yup
      .array()
      .min(1, "Select at least 1 projects")
      .max(5, "Select at most 5 projects")
      .required("Select at least 1 projects"),
    formsYouLike: yup
      .array()
      .min(1, "Select at least 1 forms")
      .max(3, "Select at most 3 forms")
      .required("Select at least 1 forms"),
    primaryGoals: yup.string().required("Primary Goals is required"),
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setInvitationRows((prevRows) =>
        prevRows.map((row, index) => {
          const prevName = prevRows[index - 1]?.[`name_${index}`];
          const prevEmail = prevRows[index - 1]?.[`email_${index}`];

          return {
            ...row,
            [`email_${index + 1}_error`]:
              invite && row[`name_${index + 1}`] && !row[`email_${index + 1}`],
            [`name_${index + 1}_error`]:
              (invite &&
                row[`email_${index + 1}`] &&
                !row[`name_${index + 1}`]) ||
              (row[`name_${index + 1}`] && row[`name_${index + 1}`].length < 3),
            [`disabled_${index + 1}`]:
              index === 0 ? false : !prevName || !prevEmail,
          };
        })
      );
    }, 1000); // Adjust the interval as needed

    return () => clearInterval(interval); // Clear interval when component unmounts
  }, [invite]);

  const validatePromoCode = async () => {
    try {
      setPromoCodeLoading(true);
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/data/validate-promo-code`,
        {
          promoCode,
        }
      );

      if (resp.data.success) {
        setPromoCodeName(resp.data.name);
      } else {
        setInvalidPromo("Invalid Promotional Code");
        setPromoCode("");
      }

      setPromoCodeLoading(false);
    } catch (e) {
      setPromoCodeLoading(false);
    }
  };

  useEffect(() => {
    if (!invite) {
      setInvitationRows([
        {
          name_1: "",
          email_1: "",
          name_1_error: false,
          email_1_error: false,
          disabled_1: false,
        },
        {
          name_2: "",
          email_2: "",
          name_2_error: false,
          email_2_error: false,
          disabled_2: true,
        },
        {
          name_3: "",
          email_3: "",
          name_3_error: false,
          email_3_error: false,
          disabled_3: true,
        },
        {
          name_4: "",
          email_4: "",
          name_4_error: false,
          email_4_error: false,
          disabled_4: true,
        },
        {
          name_5: "",
          email_5: "",
          name_5_error: false,
          email_5_error: false,
          disabled_5: true,
        },
      ]);
    }
  }, [invite]);

  useEffect(() => {
    if (mobileSize) {
      setButtons(["New"]);
    }
  }, []);

  useEffect(() => {
    if (invalidPromo !== "") {
      const interval = setInterval(() => {
        setInvalidPromo("");
      }, 3000);

      return () => clearInterval(interval);
    }
  }, [invalidPromo]);

  useEffect(() => {
    if (errorText !== "") {
      const timer = setTimeout(() => setErrorText(""), 10000);
      return () => clearTimeout(timer);
    }
    const getTiers = async () => {
      try {
        const resp = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/nocApi/data/tiers`
        );

        const res = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/nocApi/data/additional`
        );

        if (resp.data.success) {
          setTiers(resp.data.data);
          setPlans(resp.data.plans);
        }

        if (res.data.success) {
          setAdditionalData(res.data.data);
        }
      } catch (e) {}
    };

    if (count < 1) {
      getTiers();
      count += 1;
    }
  }, [errorText]);
  return (
    <>
      <Box
        sx={
          mobileSize
            ? {
                justifyContent: "center",
                overflowX: "hidden",
                px: 1,
                minHeight: "100vh",
                overflowY: "hidden",
                display: "flex",

                flexDirection: "column",
              }
            : {
                backgroundImage: "url('/img/website_img/homeImg.jpeg')",
                backgroundRepeat: "repeat",

                minHeight: "100vh",
                overflow: "hidden",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }
        }
      >
        <Box
          sx={
            mobileSize
              ? {
                  backgroundColor: "#fff",
                  py: "1rem",
                  maxWidth: "100%",
                  flexGrow: 1,
                }
              : {
                  backgroundColor: "#fff",
                  mt: 2,
                  maxWidth: "1500px",
                  borderRadius: "25px",
                  borderBottomLeftRadius: "0px",
                  borderBottomRightRadius: "0px",
                  px: 3,
                  py: "10px",
                  width: "80%",
                  pt: "2rem",
                }
          }
        >
          {/* <div
            style={
              mobileSize
                ? { width: "100%", padding: "1rem" }
                : { padding: "0rem" }
            }
          > */}
          {!mobileSize ? (
            <>
              <Stack spacing={1} sx={{ mb: 1 }}>
                <Box display="flex" justifyContent="space-between">
                  <Link
                    sx={{ fontWeight: "bold", cursor: "pointer" }}
                    onClick={(e) => navigate("/login")}
                    underline="hover"
                    variant="subtitle2"
                    display="flex"
                    alignItems="center"
                  >
                    {" "}
                    <ArrowBackIcon sx={{ marginRight: 1 }} />
                    Back to login
                  </Link>
                  <div style={{ display: "flex", alignSelf: "center" }}>
                    <img
                      style={{
                        width: "6rem",
                        marginRight: "7.5rem",
                        marginTop: "-9px",
                      }}
                      alt="Permit Rockstar Logo"
                      src="/img/website_img/PermitRockstar.png"
                    />
                  </div>

                  <div />
                </Box>
                <Typography
                  sx={{ textAlign: "center" }}
                  color="#004976"
                  variant="h6"
                >
                  Register
                </Typography>
              </Stack>
              {/* <Box
                  sx={{
                    position: "absolute",
                    display: "flex",
                    top: 30,
                    right: 50,
                  }}
                >
                  <img
                    style={{
                      width: "8rem",
                      marginTop: "-9px",
                    }}
                    alt="nocCreatorlogo"
                    src="/img/website_img/promoICON.png"
                  />
                  <Typography color="#F79009" variant="h4">
                    USE CODE:
                    <Typography color="#F79009" variant="h4">
                      PROMO2024
                    </Typography>
                  </Typography>
                </Box> */}
            </>
          ) : (
            <>
              <Stack spacing={1} sx={{ mb: 3 }}>
                <Box display="flex" justifyContent="center">
                  <img
                    style={{
                      width: "6rem",
                      marginLeft: "auto",
                      marginRight: "auto",
                      marginTop: "-9px",
                    }}
                    alt="Permit Rockstar Logo"
                    src="/img/website_img/PermitRockstar.png"
                  />
                </Box>
                <Typography
                  sx={{ textAlign: "center" }}
                  color="#004976"
                  variant="h6"
                >
                  Register
                </Typography>
              </Stack>
            </>
          )}
        </Box>
        <Box
          sx={
            !mobileSize
              ? {
                  backgroundColor: "#fff",
                  // mb: 2,
                  maxWidth: "1500px",
                  borderRadius: "25px",
                  borderTopLeftRadius: "0px",
                  borderTopRightRadius: "0px",
                  px: 3,
                  // py: "10px",
                  pb: 1,
                  width: "80%",
                  // pt: "2rem",
                }
              : { mb: 3, backgroundColor: "#fff" }
          }
        >
          <MultiStepForm
            setTerms={setTerms}
            terms={terms}
            termsOnError={termsOnError}
            setTermsOnError={setTermsOnError}
            setOpen={setOpen}
            setUserInfoCopy={setUserInfoCopy}
            setIsEmailError={setIsEmailError}
            setIsPhoneError={setIsPhoneError}
            setPage={setPage}
            setOptions={setOptions}
            page={page}
            setDuplicatedLicenses={setDuplicatedLicenses}
            tiers={tiers}
            setSelectedName={setSelectedName}
            setSelectedPlan={setSelectedPlan}
            contractorList={contractorList}
            setContractorList={setContractorList}
            setUserEmail={setUserEmail}
            isLoad={isLoad}
            selectedPlan={selectedPlan}
            open={open}
            checked={checked}
            setErrorText={setErrorText}
            personalAddress={personalAddress}
            errorText={errorText}
            isExpired={false}
            setTermsOpen={setTermsOpen}
            mobileSize={mobileSize}
            // scrollToTop={scrollToTop}
            initialValues={{
              firstName: "",
              lastName: "",
              address: "",
              email: "",
              phone: "",
              password: "",
              confirmPassword: "",
              licenseType: "",
              licenseNumber: "",
              contractorName: "",
              contractorAddress: "",
              contractorPhone: "",
              contractorFax: "",
              contractorEmail: "",
              jobTitle: "",
              referralSource: "",
              companyName: "",
              companySize: "",
              yearsInBusiness: "",
              revenue: "",
              avgNoc: "",
              topProject: "",
              formsYouLike: "",
              primaryGoals: "",
            }}
            onSubmit={async (values) => {
              try {
                setIsload(true);
                if (terms === false) {
                  setErrorText("Please accept Terms & Conditions");
                  setTermsOnError(true);
                  setIsload(false);
                  return;
                }
                setErrorText("Please Wait...");

                // const anyErrors = invitationRows.some(
                //   (row) =>
                //     row.email_1_error ||
                //     row.name_1_error ||
                //     row.email_2_error ||
                //     row.name_2_error ||
                //     row.email_3_error ||
                //     row.name_3_error ||
                //     row.email_4_error ||
                //     row.name_4_error ||
                //     row.email_5_error ||
                //     row.name_5_error
                // );
                // // If any errors exist, just return
                // if (anyErrors) {
                //   setErrorText("Invitation list's name or email is invalid");
                //   setIsload(false);
                //   return;
                // }

                // values["invitations"] = invitationRows;

                const priceId =
                  checked === true
                    ? selectedPlan?.yearly_price_id
                    : selectedPlan?.monthly_price_id;

                const plan_obj = {
                  title: selectedPlan?.title,
                  product_id: selectedPlan?.product_id,
                  price_id: priceId,
                  monthly_restart: selectedPlan?.monthly_restart,
                  license: parseInt(selectedPlan?.license),
                  subuser: parseInt(selectedPlan?.subuser),
                  noc: selectedPlan?.noc,
                  county: selectedPlan?.county,
                  interval: checked === true ? "year" : "month",
                };

                // values["contractors"] = contractorList;
                values["address"] = personalAddress;
                values["contractorAddress"] = contractorAddressState;
                values["plan"] = plan_obj;
                values["terms"] = terms;
                values["promoCode"] = promoCode;
                values["timezone"] = "America/New_York";

                // if (invite === true) {
                //   if (
                //     invitationRows[0].name_1 === "" ||
                //     invitationRows[0].email_1 === ""
                //   ) {
                //     setInvitationRows((prevState) =>
                //       prevState.map((row, index) => ({
                //         ...row,
                //         name_1_error: index === 0 && row.name_1 === "",
                //         email_1_error: index === 0 && row.email_1 === "",
                //       }))
                //     );
                //     setErrorText(
                //       "Invitation list's name and email can not be empty"
                //     );

                //     setIsload(false);
                //     return; // Exit the function if there are errors
                //   }
                // }

                // SUCCESS //////
                const resp = await axios.post(
                  `${process.env.REACT_APP_BASE_URL}/nocApi/auth/createUser`,
                  {
                    values,
                  },
                  { withCredentials: true }
                );

                // try {
                //   await axios.post(
                //     `${process.env.REACT_APP_BASE_URL}/nocApi/auth/license-validation-celery`,
                //     {
                //       contractorLists: values["contractors"],
                //       email: values["email"],
                //     },
                //     {
                //       withCredentials: true,
                //     }
                //   );
                // } catch (e) {
                //   setIsload(false);
                //   setErrorText(e?.response?.data?.message);
                //   return;
                // }

                if (resp.data.is_staff) {
                  navigate("/2fa-secure?token=" + resp.data.token);
                  return;
                }

                // ADD STRIPE PAYMENT //
                try {
                  const stripe_resp = await axios.post(
                    `${process.env.REACT_APP_BASE_URL}/nocApi/stripe/create-checkout-session`,
                    {
                      values,
                    },
                    { withCredentials: true }
                  );
                  window.location.href = stripe_resp.data;
                } catch (e) {
                  // Handle the error
                  console.error(e);
                  setIsload(false);
                  // Check if the error is due to a cancellation
                  if (
                    e.response &&
                    e.response.data &&
                    e.response.data.message === "Payment session canceled"
                  ) {
                    // Handle cancellation logic if needed
                  } else {
                    // Handle other errors
                    console.error("An error occurred during checkout:", e);
                  }
                }

                // if (resp.data.success) {
                //   const token = resp.data.data;
                //   navigate(`/2fa-secure?token=${token}`);
                // }

                // setIsload(false);
              } catch (err) {
                setErrorText(err.response.data.message);
                setIsload(false);
              }
            }}
          >
            <FormStep
              stepName="Personal Details"
              validationSchema={validationSchemaStep1}
              onSubmit={(v) => {}}
            >
              <PersonalDetails
                isEmailError={isEmailError}
                isPhoneError={isPhoneError}
                setIsPhoneError={setIsPhoneError}
                setPersonalAddress={setPersonalAddress}
                options={options}
                setOptions={setOptions}
              />
            </FormStep>
            {/* <FormStep
              stepName="Contractor Details"
              onSubmit={(e) => {}}
              validationSchema={validationSchemaStep2}
            >
              <ContractorDetails
                options={options}
                setOptions={setOptions}
                setValidationComplete={setValidationComplete}
                validationComplete={validationComplete}
                setLicenseText={setLicenseText}
                licenseText={licenseText}
                contractorList={contractorList}
                setContractorList={setContractorList}
                validLicense={validLicense}
                setValidLicense={setValidLicense}
                disabledTabs={disabledTabs}
                setDisabledTabs={setDisabledTabs}
                duplicatedLicenses={duplicatedLicenses}
                setDuplicatedLicenses={setDuplicatedLicenses}
                disableInputs={disableInputs}
                setDisableInputs={setDisableInputs}
                page={page}
                setPage={setPage}
                buttons={buttons}
                setButtons={setButtons}
                customLicenseType={customLicenseType}
                setCustomLicenseType={setCustomLicenseType}
                additionalData={additionalData}
                setContractorAddressState={setContractorAddressState}
              />
            </FormStep> */}

            <FormStep
              stepName="Additional Information"
              onSubmit={(e) => {}}
              validationSchema={validationSchemaStep4}
            >
              <Additional
                additionalData={additionalData}
                contractorList={contractorList}
                invite={invite}
                setInvite={setInvite}
                invitationRows={invitationRows}
                setInvitationRows={setInvitationRows}
                promoCode={promoCode}
                setPromoCode={setPromoCode}
                promoCodeLoading={promoCodeLoading}
                promoCodeName={promoCodeName}
                setPromoCodeName={setPromoCodeName}
                validatePromoCode={validatePromoCode}
                invalidPromo={invalidPromo}
              />
            </FormStep>
            <FormStep stepName="Choose a Plan" onSubmit={(e) => {}}>
              <ChoosePlan
                terms={terms}
                setTerms={setTerms}
                setTermsOnError={setTermsOnError}
                termsOnError={termsOnError}
                contractorList={contractorList}
                additionalData={additionalData}
                userEmail={userEmail}
                checked={checked}
                setChecked={setChecked}
                selectedName={selectedName}
                setSelectedName={setSelectedName}
                tiers={tiers}
                isChangePlan={false}
                promoCodeName={promoCodeName}
                plans={plans}
                termsOpen={termsOpen}
                setTermsOpen={setTermsOpen}
                setSelectedPlan={setSelectedPlan}
                selectedPlan={selectedPlan}
                mobileSize={mobileSize}
                tabletSize={tabletSize}
              />
            </FormStep>
          </MultiStepForm>
          {mobileSize && (
            <>
              <div
                style={{
                  border: "1px solid #E7E5E5",
                  width: "100%",
                  marginTop: "2rem",
                }}
              />
              <Box
                sx={
                  mobileSize
                    ? {
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                        mt: "0.5rem",
                        pb: "1rem",
                      }
                    : {
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                        mt: "1.1rem",
                        pb: "1rem",
                      }
                }
              >
                <Link
                  sx={{ fontWeight: "bold", cursor: "pointer" }}
                  onClick={(e) => navigate("/login")}
                  underline="hover"
                  variant="subtitle2"
                >
                  Back to login
                </Link>
              </Box>
            </>
          )}
          {/* </div> */}
        </Box>
      </Box>
    </>
  );
};

export default RegisterComponent;
