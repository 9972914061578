import React, { useEffect, useState } from "react";
import { DateTime } from "luxon";
import {
  IconButton,
  Toolbar,
  Typography,
  Chip,
  Box,
  Stack,
  TextField,
  InputAdornment,
  FormHelperText,
} from "@mui/material";
import SwipeableViews from "react-swipeable-views";
import MobileFooter from "../layout/MobileFooter";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import EventIcon from "@mui/icons-material/Event";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import MaximisePDF from "../notary/modals/MaximisePDF";

const MobileCreateSelectCountyForm = (props) => {
  const [open, setOpen] = useState(false);
  const [currentImgs, setCurrentImgs] = useState(null);

  const getImages = (form) => {
    const baseImg = form;
    const images = [baseImg];

    const imgNumberMatch = baseImg.match(/(\d+)(?=\.\w+$)/);
    if (imgNumberMatch) {
      const imgNumber = parseInt(imgNumberMatch[0], 10);
      const nextImg = baseImg.replace(imgNumberMatch[0], imgNumber + 1);
      images.push(nextImg);
    }

    return images;
  };

  useEffect(() => {
    if (props?.filteredCountiesData?.length > 0) {
      const county = sortTasksByName(props?.filteredCountiesData)[
        props?.rowIndex
      ];
      if (county) {
        const imgs = getImages(county?.form);
        setCurrentImgs(imgs);
      }
    }
  }, [props?.rowIndex, props?.filteredCountiesData]);

  const sortTasksByName = (tasks) => {
    if (props?.type === "create-noc") {
      return tasks.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
    } else {
      return tasks;
    }
  };

  const isFavorite = (county) => {
    const favorites = JSON.parse(localStorage.getItem("favorites")) || [];
    return favorites.some((favorite) => favorite.name === county.name);
  };

  const handleAddToFavorites = (county) => {
    // Get the existing favorites from local storage or initialize an empty array
    const existingFavorites =
      JSON.parse(localStorage.getItem("favorites")) || [];

    // Check if the county is already in the favorites
    const existingIndex = existingFavorites.findIndex(
      (favorite) => favorite.name === county.name
    );

    if (props?.type === "create-noc") {
      if (existingIndex === -1) {
        // Add the county to the favorites array
        existingFavorites.push(county);
      } else {
        // Remove the county from the favorites array
        existingFavorites.splice(existingIndex, 1);
      }

      // Save the updated favorites array to local storage
      localStorage.setItem("favorites", JSON.stringify(existingFavorites));

      // Create a copy of filteredCountiesData to update the buttonText
      const updatedFilteredCountiesData = [...props?.filteredCountiesData];

      // Update the state to reflect the changes
      props?.setFilteredCountiesData(updatedFilteredCountiesData);

      if (props?.favoritEnabled) {
        if (existingFavorites.length === props?.rowIndex) {
          if (existingFavorites.length > 1) {
            props?.setRowIndex(existingFavorites.length - 1); // Point to the new last item
          } else if (existingFavorites.length === 1) {
            props?.setRowIndex(0); // Set to the first and only item
          }
        }
        props?.setCountiesData(existingFavorites);
        props?.setFilteredCountiesData(existingFavorites);
      }
    }
  };

  const getCountyData = (county, noc_data, currentTimezone) => {
    let avgCreationTime = "";
    let isAppraiserSuccess = "";
    let checkedDate = "";

    const currentCounty = noc_data?.appraiser_results?.[county.name];
    const matchingEntry = noc_data?.avg_nocs?.find(
      (entry) => entry?._id === county?.name
    );

    avgCreationTime = matchingEntry
      ? Math.floor(matchingEntry?.avg_second_took)
      : "N/A";

    // APPraiser
    isAppraiserSuccess = currentCounty?.is_success ? true : false;
    const checkedDateTimestamp = currentCounty?.date * 1000;
    const checkedDateMoment = DateTime.fromMillis(checkedDateTimestamp, {
      zone: currentTimezone,
    });

    checkedDate = checkedDateMoment.toFormat("MMM d, h:mm a"); // Other days, show date and time

    return { avgCreationTime, isAppraiserSuccess, checkedDate };
  };

  return (
    <>
      <MobileFooter
        pos="top"
        py="6px"
        pl="0.5rem"
        success={
          props?.noc_data?.appraiser_results[props?.rowData?.name]?.is_success
        }
      />
      <Box
        sx={{
          p: 1,
          pb: props?.favoritEnabled ? 0 : undefined,
          backgroundColor: "#fff",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5">Creating New Permit Package</Typography>
        <Typography variant="h5">
          {props?.rowIndex + 1}/{props?.filteredCountiesData?.length}
        </Typography>
      </Box>
      {props?.favoritEnabled && (
        <Typography sx={{ color: "#cdaf0b" }} align="center" variant="h6">
          Favorites
        </Typography>
      )}
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <TextField
          id="searchInput"
          onChange={(e) => {
            props?.handleSearch(e);
          }}
          size="small"
          placeholder="SEARCH"
          sx={{ p: 1 }}
          InputProps={{
            sx: { padding: "0px 10px" },
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          variant="outlined"
        />
      </Box>
      {props?.filteredCountiesData?.length !== 0 && (
        <SwipeableViews
          axis={"x"}
          index={props?.rowIndex}
          onChangeIndex={props?.handleSwipe}
          enableMouseEvents
          style={{ height: "calc(100% - 110px)", overflow: "hidden" }}
        >
          {sortTasksByName(props?.filteredCountiesData)?.map(
            (county, index) => {
              let avgCreationTime = "";
              let isAppraiserSuccess = "";
              let checkedDate = "";

              if (props?.noc_data) {
                const countyData = getCountyData(
                  county,
                  props?.noc_data,
                  props?.currentTimezone
                );
                avgCreationTime = countyData.avgCreationTime;
                isAppraiserSuccess = countyData.isAppraiserSuccess;
                checkedDate = countyData.checkedDate;
              }
              return (
                <Box
                  key={index}
                  style={{
                    padding: 15,
                    paddingTop: 0,
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "#fff",
                    overflow: "hidden",
                  }}
                >
                  {/* MORE CONTENT HERE */}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h5">
                      {county?.name}{" "}
                      {avgCreationTime !== "N/A" && (
                        <Chip
                          size="small"
                          sx={{ fontWeight: "bold" }}
                          label={
                            <span style={{ fontSize: "14px" }}>
                              {avgCreationTime} sec in avg
                            </span>
                          }
                          color="success"
                        />
                      )}
                    </Typography>
                    {/* ENLARGE PDF ******************************************************/}
                    <Box ml={"auto"}>
                      <IconButton
                        onClick={() => {
                          setOpen(true);
                        }}
                      >
                        <SearchIcon sx={{ fontSize: "25px", zIndex: 99999 }} />
                      </IconButton>
                    </Box>
                    {/* *************************************************************** */}
                    {isFavorite(county) ? (
                      <IconButton onClick={() => handleAddToFavorites(county)}>
                        <StarIcon
                          color="#fff"
                          sx={{ fontSize: "1.8rem", color: "#cdaf0b" }}
                        />
                      </IconButton>
                    ) : (
                      <IconButton onClick={() => handleAddToFavorites(county)}>
                        <StarBorderIcon
                          color="#fff"
                          sx={{ fontSize: "1.8rem", color: "#cdaf0b" }}
                        />
                      </IconButton>
                    )}
                  </Box>
                  <Stack
                    sx={{ position: "relative" }}
                    direction="row"
                    alignItems="center"
                    spacing={1}
                  >
                    <EventIcon sx={{ color: "#333333", fontSize: "20px" }} />
                    <Typography variant="body2">
                      <span style={{ fontWeight: "bold" }}>Last NOC:</span>{" "}
                      {checkedDate}
                    </Typography>
                  </Stack>
                  <img
                    src={county?.form}
                    style={{ width: "100%", height: "auto" }}
                    alt={`Form Page ${index + 1}`}
                  />
                </Box>
              );
            }
          )}
        </SwipeableViews>
      )}
      <MaximisePDF open={open} setOpen={setOpen} imgs={currentImgs} />
      <Toolbar
        sx={{
          px: 0,
          py: 0.5,
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          opacity: 1,
          backgroundColor: "#333333",
        }}
      >
        <IconButton
          variant="contained"
          onClick={props?.handleVisitClick}
          sx={{ flex: 1, opacity: props?.visitEnabled ? 1 : 0.3 }}
        >
          <Box>
            <PictureAsPdfIcon sx={{ color: "#fff", fontSize: "1.7rem" }} />
            <FormHelperText
              sx={{ fontWeight: "bold", color: "#fff", mt: -1, px: "5px" }}
            >
              Counties
            </FormHelperText>
            <Box
              sx={{
                borderBottom: props?.visitEnabled
                  ? "3px solid #757575"
                  : "3px solid transparent",
                mt: "-4px",
              }}
            ></Box>
          </Box>
        </IconButton>

        <IconButton
          disabled={
            props?.noc_data?.appraiser_results[props?.rowData?.name]
              ?.is_success === false
          }
          sx={{
            flex: 1,
            backgroundColor: "#909090",
            "&:hover": {
              backgroundColor: "#909090", // Maintain the color even on hover
            },
            "&.Mui-focusVisible": {
              backgroundColor: "#909090", // Maintain the color when focused (clicked)
            },
            "&:active": {
              backgroundColor: "#909090", // Maintain the color when active
            },
          }}
          onClick={props?.handleAddClick}
          variant="contained"
        >
          <AddIcon sx={{ fontSize: "2.5rem", opacity: 0.8, color: "#fff" }} />
        </IconButton>
        <IconButton
          sx={{ padding: 0, flex: 1 }}
          variant="contained"
          onClick={props?.handleFavoritClick}
        >
          <Box>
            {props?.favoritEnabled ? (
              <StarIcon sx={{ fontSize: "1.8rem", color: "#fff" }} />
            ) : (
              <StarBorderIcon sx={{ fontSize: "1.8rem", color: "#fff" }} />
            )}
            <FormHelperText
              sx={{ fontWeight: "bold", color: "#fff", mt: -1, px: "5px" }}
            >
              Favorites
            </FormHelperText>
            <Box
              sx={{
                borderBottom: props?.favoritEnabled
                  ? "3px solid #757575"
                  : "3px solid transparent",
                mt: "-4px",
              }}
            ></Box>
          </Box>
        </IconButton>
      </Toolbar>
    </>
  );
};

export default MobileCreateSelectCountyForm;
