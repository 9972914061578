import React, { useRef, useEffect, useState } from "react";
import { DateTime } from "luxon";
import { useSelector } from "react-redux";
import {
  Modal,
  Box,
  Typography,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  Stack,
  Link,
  Card,
  IconButton,
  FormHelperText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";

import { convertDateStringToObject } from "../functions";

function CompletedModal({
  open,
  onClose,
  selectedJob,
  setCompletedModalOpen,
  setRescheduleErrorText,
  setSelectedScheduledJob,
}) {
  const currentSession = useSelector((state) => state.sessions.currentSession);
  const currentUser = useSelector((state) => state.notarySession?.user);
  const [availableChecked, setAvailableChecked] = useState(true);
  const [unavailableText, setUnavailableText] = useState("");
  const [rescheduleLoading, setRescheduleLoading] = useState(false);
  const [cancelSuccess, setCancelSuccess] = useState(false);
  const [failedAt, setFailedAt] = useState("N/A");
  const [completedAt, setCompletedAt] = useState("N/A");

  const convertDataTZ = (isoDate, userZone) => {
    const utcDate = DateTime.fromISO(isoDate, { zone: "utc" });
    // Convert to US timezone (example: America/New_York)
    const usDate = utcDate.setZone(userZone);
    // Format the date in the desired format
    const formattedDate = usDate.toFormat("MMMM dd, yyyy hh:mm a");
    return <span>{formattedDate}</span>;
  };

  function convertDayjsToObject(dayjsObj) {
    return {
      day_of_month: dayjsObj.date(), // Get the day of the month
      month: dayjsObj.month() + 1, // Months are zero-indexed, add 1 for 1-12 format
      year: dayjsObj.year(),
      time: dayjsObj.format("HH:mm"), // Formats the time in 'HH:mm' format
    };
  }

  function isValidTime(time) {
    // Check if the input is a valid date string
    if (typeof time === "string" && !isNaN(Date.parse(time))) {
      const dateObj = new Date(time);
      const minutes = dateObj.getMinutes();
      return minutes === 0 || minutes === 30;
    }
    return false;
  }

  useEffect(() => {}, [selectedJob]);

  const createValidationSchema = (availableChecked) => {
    return Yup.object({
      scheduledDate: Yup.string()
        .required("Date is required")
        .test(
          "check-availability",
          "Check availability is required",
          function (value) {
            if (typeof value === "string" && !isNaN(Date.parse(value))) {
              if (!isValidTime(value)) {
                return this.createError({
                  path: `scheduledDate`,
                  message: "Time must be on the hour or half-hour",
                });
              }
            } else {
              // Handle invalid or incomplete date format
              return this.createError({
                path: `scheduledDate`,
                message: "Invalid date format",
              });
            }
            // Now availableChecked is accessible
            return availableChecked;
          }
        ),
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      scheduledDate: null,
    },
    validationSchema: createValidationSchema(availableChecked),
    onSubmit: async (values, helpers) => {
      setRescheduleLoading(true);
      const formattedObject = convertDayjsToObject(values.scheduledDate);
      try {
        const resp = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/notary/reschedule-appointment`,
          {
            formattedObject,
            noc_id: selectedJob.id,
            task_id: selectedJob.task_id,
            currentSession: currentSession,
          },
          {
            withCredentials: true,
          }
        );

        setRescheduleErrorText(resp.data.message);
        setRescheduleLoading(false);
        setSelectedScheduledJob(resp.data.data);
        formik.resetForm();
      } catch (e) {
        setRescheduleErrorText(e.response.data.message);
        setRescheduleLoading(false);
      }
    },
  });

  const formikRef = useRef(formik);
  formikRef.current = formik;

  // Effect for the countdown timer
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "60%",
          bgcolor: "inherit",
          boxShadow: 24,
        }}
      >
        <IconButton
          onClick={() => {
            setCompletedModalOpen(false);
          }}
          sx={{ position: "absolute", right: 10, top: 10 }}
        >
          <CloseIcon />
        </IconButton>
        <Card sx={{ backgroundColor: "#FCF2F0", p: 4 }}>
          <Typography variant="h4" align="center">
            Closed RON Session Details
          </Typography>

          <Typography
            color={
              selectedJob?.completed_status === "Cancelled"
                ? "error"
                : "#4CAF50"
            }
            variant="h5"
            align="center"
            // sx={{ mb: 1 }}
          >
            {selectedJob?.completed_status}
          </Typography>

          <Typography
            color={
              selectedJob?.completed_status === "Cancelled"
                ? "error"
                : "#4CAF50"
            }
            variant="body2"
            align="center"
            sx={{
              mb: selectedJob?.completed_status === "Cancelled" ? 0 : 4,
              fontWeight: "bold",
            }}
          >
            {selectedJob?.completed_status === "Cancelled"
              ? convertDataTZ(selectedJob?.failed_at, currentUser?.timezone)
              : convertDataTZ(selectedJob?.notarized_at, currentUser?.timezone)}
          </Typography>
          {selectedJob?.completed_status === "Cancelled" && (
            <>
              <Typography mt={2} color="error" variant="h5" align="center">
                {selectedJob?.document?.failure[0].reason}
              </Typography>
            </>
          )}

          <Box>
            <Box sx={{ display: "flex" }}>
              <List
                sx={
                  cancelSuccess
                    ? { flex: 1, textDecoration: "line-through" }
                    : { flex: 1 }
                }
              >
                <Grid container>
                  <Grid item sm={4}>
                    <ListItem>
                      <Typography
                        component="div"
                        variant="body2"
                        sx={{ display: "block" }}
                      >
                        <Typography variant="h6">Signer</Typography>
                        <Stack direction="column">
                          <Typography component="span" variant="body2">
                            {selectedJob?.signee?.fullName}
                          </Typography>
                          <Typography component="span" variant="body2">
                            {selectedJob?.signee?.address}
                          </Typography>
                          <Typography component="span" variant="body2">
                            {selectedJob?.signee?.phone}
                          </Typography>
                          <Link
                            href={`mailto:${selectedJob?.signee?.email}`}
                            color="inherit"
                          >
                            <Typography component="span" variant="body2">
                              {selectedJob?.signee?.email}
                            </Typography>
                          </Link>
                        </Stack>
                      </Typography>
                    </ListItem>
                  </Grid>
                  <Grid item sm={4}>
                    <ListItem>
                      <Typography
                        component="div"
                        variant="body2"
                        sx={{ display: "block" }}
                      >
                        <Typography variant="h6">Document Details</Typography>
                        <Stack direction="column">
                          <Typography component="span" variant="body2">
                            {selectedJob?.document?.owner}
                          </Typography>
                          <Typography component="span" variant="body2">
                            {selectedJob?.document?.property_address}
                          </Typography>
                          <Typography component="span" variant="body2">
                            {selectedJob?.document?.county}
                          </Typography>
                        </Stack>
                      </Typography>
                    </ListItem>
                  </Grid>
                  <Grid item sm={4}>
                    <ListItem>
                      <Typography
                        component="div"
                        variant="body2"
                        sx={{ display: "block" }}
                      >
                        <Typography variant="h6">Contractor</Typography>
                        <Stack direction="column">
                          <Typography component="span" variant="body2">
                            {selectedJob?.contractor?.name}
                          </Typography>
                          <Typography component="span" variant="body2">
                            {selectedJob?.contractor?.address}
                          </Typography>
                          <Typography component="span" variant="body2">
                            {selectedJob?.contractor?.phone}
                          </Typography>
                        </Stack>
                      </Typography>
                    </ListItem>
                  </Grid>
                </Grid>
              </List>
            </Box>
          </Box>
        </Card>
      </Box>
    </Modal>
  );
}

export default CompletedModal;
