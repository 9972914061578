// EnableDisableNotary.js
import React from "react";
import { Modal, Box, Typography, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const EnableDisableNotary = ({
  open,
  handleClose,
  handleConfirm,
  currentAction,
  bnRegisteredLoading,
}) => {
  return (
    <Modal
      open={open}
      onClose={bnRegisteredLoading ? () => {} : handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0,0,0,0.3)",
        },
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: "2px solid #d3d3d3",
          borderRadius: "20px",
          boxShadow: 2,
          p: 4,
        }}
      >
        <Typography id="modal-title" variant="h6" component="h2">
          {currentAction === "enable"
            ? "Enable Notary User Account"
            : currentAction === "disable"
            ? "Disable Notary User Account"
            : ""}
        </Typography>
        <Typography id="modal-description" sx={{ mt: 2 }}>
          {currentAction === "enable"
            ? "Do you wish to enable this Notary User Account?"
            : currentAction === "disable"
            ? "Do you wish to disable this Notary User Account?"
            : ""}
        </Typography>
        <Box
          sx={{ mt: 3, display: "flex", justifyContent: "flex-end", gap: 2 }}
        >
          <LoadingButton
            loading={bnRegisteredLoading}
            color="error"
            onClick={handleConfirm}
            sx={{ ml: 2 }}
          >
            Confirm
          </LoadingButton>
          <Button
            disabled={bnRegisteredLoading}
            variant="contained"
            onClick={handleClose}
            color="primary"
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default EnableDisableNotary;
