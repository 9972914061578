import { LoadingButton } from "@mui/lab";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";

const ProfessionalsTextFields = (props) => {
  const handleLicenseNumberInputChange = (event) => {
    const value = event?.target.value.toUpperCase();
    props?.professionalsFormik?.setFieldValue("licenseNumber", value);
  };

  const handlePhoneChange = (event) => {
    const { value } = event.target;
    const digitsOnly = value.replace(/\D/g, ""); // Remove non-numeric characters
    let formattedPhoneNumber = "";

    // Add dashes after 3rd and 6th digits
    for (let i = 0; i < digitsOnly.length; i++) {
      if (i === 3 || i === 6) {
        formattedPhoneNumber += "-";
      }
      formattedPhoneNumber += digitsOnly[i];
    }

    props?.setFormattedPhone(formattedPhoneNumber); // Update the formatted phone in state
    props?.professionalsFormik.setFieldValue("phone", formattedPhoneNumber); // Update formik field value
  };

  return (
    <form noValidate onSubmit={props?.professionalsFormik.handleSubmit}>
      <Grid container justifyContent="center" spacing={1}>
        <Grid item xs={12}>
          <TextField
            disabled={
              props?.addProfessionalsLoading ||
              (props?.edit && !props?.editEnabled) ||
              props?.submitLoading
            }
            size={props?.mobileSize ? "small" : undefined}
            error={
              !!(
                props?.professionalsFormik?.touched.companyName &&
                props?.professionalsFormik?.errors.companyName
              )
            }
            helperText={
              props?.professionalsFormik?.touched.companyName &&
              props?.professionalsFormik?.errors.companyName
            }
            onBlur={props?.professionalsFormik?.handleBlur}
            onChange={props?.professionalsFormik?.handleChange}
            fullWidth
            label="Company Name"
            name="companyName"
            value={props?.professionalsFormik?.values.companyName || ""}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled={
              props?.addProfessionalsLoading ||
              (props?.edit && !props?.editEnabled) ||
              props?.submitLoading
            }
            size={props?.mobileSize ? "small" : undefined}
            error={
              !!(
                props?.professionalsFormik?.touched.licenseName &&
                props?.professionalsFormik?.errors.licenseName
              )
            }
            helperText={
              props?.professionalsFormik?.touched.licenseName &&
              props?.professionalsFormik?.errors.licenseName
            }
            onBlur={props?.professionalsFormik?.handleBlur}
            onChange={props?.professionalsFormik?.handleChange}
            fullWidth
            label="License Name"
            name="licenseName"
            value={props?.professionalsFormik?.values.licenseName || ""}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled={
              props?.addProfessionalsLoading ||
              (props?.edit && !props?.editEnabled) ||
              props?.submitLoading
            }
            size={props?.mobileSize ? "small" : undefined}
            error={
              !!(
                props?.professionalsFormik?.touched.licenseNumber &&
                props?.professionalsFormik?.errors.licenseNumber
              )
            }
            helperText={
              props?.professionalsFormik?.touched.licenseNumber &&
              props?.professionalsFormik?.errors.licenseNumber
                ? props?.professionalsFormik?.errors.licenseNumber
                : ""
            }
            onBlur={props?.professionalsFormik?.handleBlur}
            onChange={(event) => {
              handleLicenseNumberInputChange(event);
            }}
            fullWidth
            label="License Number"
            name="licenseNumber"
            // value={newLicenseNumber}
            value={props?.professionalsFormik?.values.licenseNumber || ""}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled={
              props?.addProfessionalsLoading ||
              (props?.edit && !props?.editEnabled) ||
              props?.submitLoading
            }
            size={props?.mobileSize ? "small" : undefined}
            error={
              !!(
                props?.professionalsFormik?.touched.address &&
                props?.professionalsFormik?.errors.address
              )
            }
            helperText={
              props?.professionalsFormik?.touched.address &&
              props?.professionalsFormik?.errors.address
            }
            onBlur={props?.professionalsFormik?.handleBlur}
            onChange={props?.professionalsFormik?.handleChange}
            fullWidth
            label="Address"
            name="address"
            value={props?.professionalsFormik?.values.address || ""}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled={
              props?.addProfessionalsLoading ||
              (props?.edit && !props?.editEnabled) ||
              props?.submitLoading
            }
            size={props?.mobileSize ? "small" : undefined}
            error={
              !!(
                props?.professionalsFormik?.touched.email &&
                props?.professionalsFormik?.errors.email
              )
            }
            helperText={
              props?.professionalsFormik?.touched.email &&
              props?.professionalsFormik?.errors.email
            }
            onBlur={props?.professionalsFormik?.handleBlur}
            onChange={props?.professionalsFormik?.handleChange}
            fullWidth
            label="Email Address"
            name="email"
            value={props?.professionalsFormik?.values.email || ""}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled={
              props?.addProfessionalsLoading ||
              (props?.edit && !props?.editEnabled) ||
              props?.submitLoading
            }
            size={props?.mobileSize ? "small" : undefined}
            error={
              !!(
                props?.professionalsFormik?.touched.phone &&
                props?.professionalsFormik?.errors.phone
              )
            }
            helperText={
              props?.professionalsFormik?.touched.phone &&
              props?.professionalsFormik?.errors.phone
            }
            onBlur={props?.professionalsFormik?.handleBlur}
            onChange={handlePhoneChange}
            fullWidth
            label="Phone"
            name="phone"
            value={props?.professionalsFormik?.values.phone || ""}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            disabled={
              props?.addProfessionalsLoading ||
              (props?.edit && !props?.editEnabled) ||
              props?.submitLoading
            }
            size={props?.mobileSize ? "small" : undefined}
            error={
              !!(
                props?.professionalsFormik?.touched.fax &&
                props?.professionalsFormik?.errors.fax
              )
            }
            helperText={
              props?.professionalsFormik?.touched.fax &&
              props?.professionalsFormik?.errors.fax
            }
            onBlur={props?.professionalsFormik?.handleBlur}
            onChange={props?.professionalsFormik?.handleChange}
            fullWidth
            label="Fax"
            name="fax"
            value={props?.professionalsFormik?.values.fax || ""}
          />
        </Grid>
        <Typography
          mt={2}
          align="center"
          variant="body1"
          sx={{ color: props?.waitingColor }}
        >
          {props?.designProfessionalsErrorText}
        </Typography>
        {props?.edit ? (
          <>
            <Grid item xs={12} align="center" sm={6} mt={2}>
              <LoadingButton
                loading={props?.deleteLoading}
                onClick={() => {
                  if (props?.edit && props?.editEnabled) {
                    props?.setEditEnabled(false);
                  } else if (props?.edit && !props?.editEnabled) {
                    props?.setConfirmModalOpen(true);
                  }
                }}
                fullWidth={props?.mobileSize}
                sx={{ width: !props?.mobileSize ? "70%" : undefined }}
                color={props?.edit && !props?.editEnabled ? "error" : "warning"}
                size={props?.mobileSize ? "small" : undefined}
                variant="contained"
              >
                {props?.edit && !props?.editEnabled
                  ? "Delete Record"
                  : "Cancel Edit"}
              </LoadingButton>
            </Grid>
            <Grid
              item
              xs={12}
              align="center"
              sm={6}
              mt={props?.mobileSize ? 0 : 2}
            >
              <LoadingButton
                disabled={props?.deleteLoading}
                loading={props?.submitLoading}
                // type={props?.edit && !props?.editEnabled ? "button" : "submit"}
                onClick={(e) => {
                  if (props?.edit && !props?.editEnabled) {
                    props?.setEditEnabled(true);
                  } else if (props?.edit && props?.editEnabled) {
                    props?.professionalsFormik.submitForm();
                  }
                }}
                fullWidth={props?.mobileSize}
                sx={{ width: !props?.mobileSize ? "70%" : undefined }}
                color={
                  props?.edit && !props?.editEnabled ? "warning" : "success"
                }
                size={props?.mobileSize ? "small" : undefined}
                variant="contained"
              >
                {props?.edit && !props?.editEnabled
                  ? "Enable Edit"
                  : "Submit Changes"}
              </LoadingButton>
            </Grid>
          </>
        ) : (
          <Grid item xs={12} mt={2}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <LoadingButton
                loading={props?.addProfessionalsLoading}
                type="submit"
                fullWidth={props?.mobileSize}
                sx={{ width: !props?.mobileSize ? "40%" : undefined }}
                color="success"
                size={props?.mobileSize ? "small" : undefined}
                variant="contained"
              >
                Submit
              </LoadingButton>
            </Box>
          </Grid>
        )}
      </Grid>
    </form>
  );
};

export default ProfessionalsTextFields;
