import { LoadingButton } from "@mui/lab";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { isValid, parseISO } from "date-fns";
import {
  Box,
  Typography,
  Grid,
  InputAdornment,
  FormHelperText,
  CardContent,
  Card,
  Button,
  Stack,
  Collapse,
  TextField,
} from "@mui/material";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import { useRef, useState } from "react";
import { TimePicker, DatePicker } from "@mui/x-date-pickers";
const Schedule = ({
  propertyDetails,
  userTimezone,
  formik,
  timeLeft,
  setTimeLeft,
  startInstantTimer,
  handleStartClick,
  handleImmediateRON,
  checkDateLoading,
  slots,
  slotIndex,
  setSlotIndex,
  convertObjectToDayjs,
  isAvailable,
  setIsAvailable,
  startTimer,
  unavailableText,
  setUnavailableText,
  checkInstantLoading,
  type,
  instantTimeLeft,
  isInstantAvailable,
  setIsInstantAvailable,
  instantData,
  instantError,
  setInstantError,
  setInstantTimeLeft,
  setInstantData,
  setSlots,
  isManage,
  immediateRequested,
  setCanceledInstant,
  instantAccepted,
  mobileSize,
  tabletSize,
  largeTabletSize,
  setSubmitting,
  immediateLock,
  expeditedSelectedBtn,
  setExpeditedSelectedBtn,
  furtherSelectedBtn,
  setFurtherSelectedBtn,
  tempDate,
  setTempDate,
  tempTime,
  setTempTime,
  furtherDateObj,
  setFurtherDateObj,
  yesLoading,
  furtherRequested,
  isFurtherAvailable,
  furtherData,
  furtherError,
  setIsFurtherAvailable,
  setFurtherData,
  setCheckDateLoading,
  setFurtherRequested,
}) => {
  function formatSlotToUSDateTime(slot) {
    const date = new Date(slot.year, slot.month - 1, slot.day_of_month); // JavaScript months are 0-indexed
    const options = { month: "long", day: "numeric", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);

    // Convert 'HH:MM' format to 12-hour format with AM/PM
    const [hours, minutes] = slot.time.split(":");
    const hours12 = hours % 12 || 12; // Convert 0 to 12 for midnight
    const amPm = hours < 12 ? "AM" : "PM";
    const formattedTime = `${hours12}:${minutes} ${amPm}`;

    return `${formattedDate}, ${formattedTime}`;
  }

  function areDatesEqual(slot, appointmentStr) {
    // Parse the slot object into a Date
    const slotDate = new Date(
      slot.year,
      slot.month - 1,
      slot.day_of_month,
      ...slot.time.split(":").map(Number)
    );

    // Parse the appointment string into a Date
    const appointmentDate = new Date(
      appointmentStr.replace(/^[a-zA-Z]+,\s*/, "")
    );

    // Compare the dates
    return slotDate.getTime() === appointmentDate.getTime();
  }

  const handleDateChange = (newDate) => {
    // if (isValid(newDate) || newDate === null) {
    setTempDate(newDate);
    // formik.setFieldValue("scheduledDate", newDate, true); // Optionally remove time component here if needed
    // }
  };

  const handleTimeChange = (newTime) => {
    setTempTime(newTime);
  };

  const expandableRef = useRef(null);

  const startInstantCheck = async () => {
    await handleImmediateRON();
    setExpeditedSelectedBtn(true);
    setFurtherSelectedBtn(false);
    setTimeout(() => {
      expandableRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 300); // Adjust time based on your collapse animation time
  };

  const startFurtherCheck = async () => {
    setFurtherSelectedBtn(true);
    setExpeditedSelectedBtn(false);
    setTimeout(() => {
      expandableRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 300); // Adjust time based on your collapse animation time
  };

  const handleExpeditedClick = async () => {
    if (!expeditedSelectedBtn) {
      startInstantCheck();
    }
  };

  const handleFurtherClick = async () => {
    if (!furtherSelectedBtn) {
      startFurtherCheck();
    }
  };
  return (
    <>
      {type !== "manage" && (
        <Box
          sx={{
            borderBottom: "3px solid #f3f3f3",
            mb: 2,
            mt: 4,
          }}
        >
          <Typography
            variant={mobileSize ? "h5" : tabletSize ? "h5" : "h4"}
            align="center"
          >
            Remote Online Notarization (RON) Session Scheduling
          </Typography>
        </Box>
      )}
      <Box sx={{ mb: 2, px: 0.5 }}>
        <Typography variant="h6" align="center">
          Would you like to check if a notary is available to assist you now?
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center", gap: 1, mt: 1 }}>
          <Button
            disabled={
              isInstantAvailable || checkInstantLoading || expeditedSelectedBtn
            }
            onClick={handleFurtherClick}
            fullWidth={tabletSize}
            sx={{ minWidth: "70.2px" }}
            variant={furtherSelectedBtn ? "contained" : "outlined"}
          >
            NO
          </Button>
          <LoadingButton
            disabled={furtherSelectedBtn}
            loading={yesLoading}
            onClick={handleExpeditedClick}
            fullWidth={tabletSize}
            sx={{ minWidth: "70.2px" }}
            variant={expeditedSelectedBtn ? "contained" : "outlined"}
          >
            YES
          </LoadingButton>
        </Box>
      </Box>

      {/* EXPEDITED APPOINTMENT */}
      <Collapse in={expeditedSelectedBtn}>
        <div ref={expandableRef}>
          <Grid container spacing={mobileSize ? 1 : 2} sx={{ mb: 2 }}>
            {expeditedSelectedBtn && (
              <>
                <Grid
                  item
                  xs={12}
                  lg={6}
                  sx={{ mx: mobileSize ? "0px" : "auto" }}
                >
                  <Card elevation={mobileSize ? 0 : 4}>
                    {startInstantTimer && instantAccepted === false && (
                      <Box
                        sx={{
                          backgroundColor: "#FF9800",
                          borderRadius: "20px 20px 0 0",
                          p: 1,
                          mt: mobileSize ? 2 : 0,
                        }}
                      >
                        <Typography
                          align="center"
                          color="#fff"
                          fontWeight="bold"
                          variant="body2"
                        >
                          {immediateRequested === true
                            ? `Maximum waiting time left ${instantTimeLeft}`
                            : `Selected Date And Time Is Reserved For: ${instantTimeLeft}`}
                        </Typography>
                      </Box>
                    )}
                    <CardContent sx={{ pt: mobileSize ? 0 : 2, px: 0 }}>
                      <Typography
                        variant={mobileSize ? "h6" : "h5"}
                        align="center"
                        sx={{
                          mb: mobileSize ? 1 : 1,
                          mt: mobileSize ? 3 : 0,
                        }}
                      >
                        {isInstantAvailable
                          ? "Expedited Notarization"
                          : "Expedited Appointments"}
                      </Typography>
                      {immediateRequested === true &&
                        startInstantTimer === true &&
                        instantAccepted === false && (
                          <>
                            <Stack
                              direction="row"
                              justifyContent="center"
                              sx={{ width: "100%", fontWeight: "bold" }}
                            >
                              <Typography
                                align="center"
                                variant="tbody2"
                                sx={{ mb: 4, mr: 1, color: "#10b981" }}
                              >
                                Please wait while a notary is being assigned to
                                your expedited notarization request. Note:
                                Notaries have up to 5 minutes to accept your
                                request.
                              </Typography>
                            </Stack>
                          </>
                        )}
                      {instantAccepted === true && (
                        <>
                          <Stack
                            direction="row"
                            justifyContent="center"
                            sx={{ width: "100%", fontWeight: "bold" }}
                          >
                            <Typography
                              align="center"
                              variant="tbody2"
                              sx={{ mb: 4, mr: 1, color: "#10b981" }}
                            >
                              Please wait while the system validates and
                              prepares your document for notarization. This
                              could take up to 60 seconds.
                            </Typography>
                          </Stack>
                        </>
                      )}

                      {instantData?.success && (
                        <>
                          <Typography px={1} align="center">
                            We currently have{" "}
                            <span
                              style={{
                                fontWeight: "bold",
                                fontSize: "24px",
                                color: "#4caf50",
                              }}
                            >
                              {instantData?.success
                                ? instantData?.notaries.length
                                : 0}
                            </span>{" "}
                            {instantData?.notaries.length > 1
                              ? "notaries"
                              : "notary"}{" "}
                            that might be available to assist you at:
                          </Typography>
                          {isInstantAvailable && (
                            <Typography
                              sx={{ fontWeight: "bold" }}
                              align="center"
                            >
                              {instantData?.dateString}
                            </Typography>
                          )}
                          {immediateRequested === true &&
                          startInstantTimer === true ? (
                            <></>
                          ) : (
                            <>
                              <Typography align="center" fontWeight="bold">
                                Would you like to submit a request for an
                                appointment?
                              </Typography>
                              <Typography align="center">
                                Please note that availability is not guaranteed.
                              </Typography>
                            </>
                          )}
                        </>
                      )}

                      <Typography align="center" color="error">
                        {instantError}
                      </Typography>
                      <Grid
                        container
                        spacing={mobileSize ? 0 : 1}
                        pl={mobileSize ? 0.5 : tabletSize ? 1 : 2}
                        pr={mobileSize ? 0.5 : tabletSize ? 1 : 2}
                      >
                        <Grid
                          sx={{ order: mobileSize ? 2 : 1 }}
                          item
                          sm={!isInstantAvailable && instantError ? 12 : 6}
                          xs={12}
                        >
                          <Button
                            onClick={() => {
                              if (!isInstantAvailable && instantError) {
                                setExpeditedSelectedBtn(false);
                                setFurtherSelectedBtn(true);
                                setInstantError("");
                                setTimeout(() => {
                                  expandableRef.current?.scrollIntoView({
                                    behavior: "smooth",
                                  });
                                }, 300);
                              } else if (!isInstantAvailable) {
                                setExpeditedSelectedBtn(false);
                              } else {
                                setInstantTimeLeft(0);
                                setCanceledInstant(true);
                                setInstantData({});
                                setIsInstantAvailable(false);
                                setExpeditedSelectedBtn(false);
                              }
                            }}
                            disabled={
                              Boolean(checkInstantLoading) ||
                              Boolean(instantAccepted) ||
                              Boolean(immediateLock)
                            }
                            fullWidth
                            sx={{ mt: mobileSize ? 1 : 2 }}
                            variant="contained"
                            color={
                              !isInstantAvailable && instantError
                                ? "success"
                                : "error"
                            }
                          >
                            {!isInstantAvailable && instantError
                              ? "Schedule for a later time"
                              : "Cancel"}
                          </Button>
                        </Grid>
                        {!isInstantAvailable && instantError ? (
                          <></>
                        ) : (
                          <Grid
                            sx={{ order: mobileSize ? 1 : 2 }}
                            item
                            sm={6}
                            xs={12}
                          >
                            <LoadingButton
                              type={isInstantAvailable ? "submit" : "button"}
                              loading={
                                checkInstantLoading ||
                                immediateRequested ||
                                instantAccepted
                              }
                              onClick={() => {
                                if (!isInstantAvailable) {
                                  startInstantCheck();
                                  setInstantError("");
                                } else if (isInstantAvailable) {
                                  setSubmitting(true);
                                  formik.submitForm();
                                }
                              }}
                              disabled={
                                Boolean(formik.values.scheduledDate) ||
                                Boolean(slots.length !== 0) ||
                                Boolean(checkDateLoading)
                              }
                              fullWidth
                              variant="contained"
                              sx={{ mt: mobileSize ? 1 : 2 }}
                              color={isInstantAvailable ? "success" : "warning"}
                              startIcon={
                                isInstantAvailable &&
                                immediateRequested === false &&
                                checkInstantLoading === false ? (
                                  <InputAdornment position="start">
                                    <CheckCircleIcon
                                      style={{ color: "white" }}
                                    />
                                  </InputAdornment>
                                ) : null
                              }
                            >
                              {isInstantAvailable
                                ? "Send Request"
                                : "Check Availability"}
                            </LoadingButton>
                          </Grid>
                        )}
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </>
            )}
          </Grid>
        </div>
      </Collapse>

      {/* FURTHER APPOINTMENT */}
      <Collapse in={furtherSelectedBtn}>
        <div ref={expandableRef}>
          <Grid container spacing={mobileSize ? 1 : 2} sx={{ mb: 2 }}>
            <Grid item xs={12} lg={6} sx={{ mx: mobileSize ? "0px" : "auto" }}>
              <Card elevation={mobileSize ? 0 : 4}>
                {startTimer && instantAccepted === false && (
                  <Box
                    sx={{
                      backgroundColor: "#FF9800",
                      borderRadius: "20px 20px 0 0",
                      p: 1,
                      mt: mobileSize ? 2 : 0,
                      mb: !isManage && mobileSize ? 2 : undefined,
                    }}
                  >
                    <Typography
                      align="center"
                      color="#fff"
                      fontWeight="bold"
                      variant="body2"
                    >
                      {furtherRequested === true
                        ? `Maximum waiting time left ${timeLeft}`
                        : `Selected Date And Time Is Reserved For: ${timeLeft}`}
                    </Typography>
                  </Box>
                )}
                <CardContent
                  sx={{ pt: mobileSize ? 0 : 2, px: tabletSize ? 1 : 2 }}
                >
                  <Typography
                    variant={mobileSize ? "h6" : "h5"}
                    align="center"
                    sx={{
                      mb: isAvailable || mobileSize ? 2 : 3,
                      mt:
                        type === "manage" && mobileSize && !isAvailable
                          ? 2
                          : undefined,
                    }}
                  >
                    Schedule a later appointment date and time
                    {/* {isFurtherAvailable
                      ? "Schedule a later appointment date and time"
                      : "Appointment Unavailable"} */}
                  </Typography>
                  {furtherRequested === true &&
                    startTimer === true &&
                    instantAccepted === false && (
                      <>
                        <Stack
                          direction="row"
                          justifyContent="center"
                          sx={{ width: "100%", fontWeight: "bold" }}
                        >
                          <Typography
                            align="center"
                            variant="h6"
                            sx={{
                              mb: 0,
                              mr: 1,
                              mt: mobileSize ? 2 : undefined,
                              color: "#10b981",
                            }}
                          >
                            Please wait while a notary is being assigned to your
                            notarization request. Note: Notaries have up to 5
                            minutes to accept your request.
                          </Typography>
                        </Stack>
                      </>
                    )}
                  {instantAccepted === true && (
                    <>
                      <Stack
                        direction="row"
                        justifyContent="center"
                        sx={{ width: "100%", fontWeight: "bold" }}
                      >
                        <Typography
                          align="center"
                          variant="tbody2"
                          sx={{ mb: 4, mr: 1, color: "#10b981" }}
                        >
                          Please wait while the system validates and prepares
                          your document for notarization. This could take up to
                          60 seconds.
                        </Typography>
                      </Stack>
                    </>
                  )}

                  {furtherData?.success && (
                    <>
                      <Typography px={1} align="center">
                        We currently have{" "}
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: "24px",
                            color: "#4caf50",
                          }}
                        >
                          {furtherData?.success
                            ? furtherData?.notaries.length
                            : 0}
                        </span>{" "}
                        {furtherData?.notaries.length > 1
                          ? "notaries"
                          : "notary"}{" "}
                        that might be available to assist you.
                      </Typography>
                      {isInstantAvailable && (
                        <Typography sx={{ fontWeight: "bold" }} align="center">
                          {furtherData?.dateString}
                        </Typography>
                      )}
                      {furtherRequested === true && startTimer === true ? (
                        <></>
                      ) : (
                        <Typography pb={1} align="center" fontWeight="bold">
                          Would you like to check if a notary is available to
                          assist you on your selected date and time?
                        </Typography>
                      )}
                    </>
                  )}

                  <Typography align="center" color="error">
                    {furtherError}
                  </Typography>

                  <Box
                    sx={{ display: "flex", gap: 2, justifyContent: "center" }}
                  >
                    <DatePicker
                      label="Select Date"
                      value={tempDate}
                      onChange={handleDateChange}
                      renderInput={(params) => <TextField {...params} />}
                      disabled={
                        Boolean(isInstantAvailable) ||
                        Boolean(checkInstantLoading) ||
                        Boolean(immediateRequested) ||
                        Boolean(furtherData?.success)
                      }
                      disablePast
                    />
                    <TimePicker
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                      }}
                      closeOnSelect={false}
                      label="Select Time"
                      sx={{
                        borderRadius: "10px",
                      }}
                      value={tempTime}
                      onChange={handleTimeChange}
                      renderInput={(params) => <TextField {...params} />}
                      disabled={
                        Boolean(furtherData?.success) ||
                        Boolean(isInstantAvailable) ||
                        Boolean(checkInstantLoading) ||
                        Boolean(immediateRequested) ||
                        !tempDate
                      }
                      minutesStep={5}
                    />
                  </Box>

                  <FormHelperText sx={{ color: "error.main" }}>
                    {Boolean(formik.errors.scheduledDate) &&
                      Boolean(formik.touched.scheduledDate) &&
                      formik.errors.scheduledDate}
                  </FormHelperText>
                  <FormHelperText
                    sx={{ textAlign: "center", color: "error.main" }}
                  >
                    {unavailableText}
                  </FormHelperText>
                  <Grid container spacing={1}>
                    <Grid
                      sx={{ order: mobileSize ? 2 : 1 }}
                      item
                      sm={6}
                      xs={12}
                    >
                      <Button
                        onClick={() => {
                          if (startTimer) {
                            setTimeLeft(0);
                          }

                          formik.values.scheduledDate = null;
                          setTempDate(null);
                          setTempTime(null);
                          setSlots([]);
                          setIsAvailable(false);
                          setFurtherSelectedBtn(false);
                          setFurtherDateObj(null);
                          setUnavailableText("");
                          setFurtherData(null);
                          setCheckDateLoading(false);
                          setFurtherRequested(false);
                          setIsFurtherAvailable(false);
                        }}
                        disabled={Boolean(instantAccepted)}
                        fullWidth
                        sx={{ mt: mobileSize ? 0 : 2 }}
                        variant="contained"
                        color="error"
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid
                      sx={{ order: mobileSize ? 1 : 2 }}
                      item
                      sm={6}
                      xs={12}
                    >
                      <LoadingButton
                        type={isInstantAvailable ? "submit" : "button"}
                        loading={
                          checkDateLoading ||
                          furtherRequested ||
                          instantAccepted
                        }
                        onClick={async () => {
                          if (!isFurtherAvailable) {
                            await handleStartClick(formik);
                            setSlotIndex(-1);
                            setTimeout(() => {
                              expandableRef.current?.scrollIntoView({
                                behavior: "smooth",
                              });
                            }, 300);
                          } else if (isFurtherAvailable) {
                            setSubmitting(true);
                            formik.submitForm();
                          }

                          // startFurtherCheck();
                        }}
                        disabled={
                          Boolean(!furtherDateObj) ||
                          Boolean(!tempDate) ||
                          Boolean(!tempTime) ||
                          Boolean(isAvailable) ||
                          Boolean(checkInstantLoading)
                        }
                        fullWidth
                        variant="contained"
                        sx={{ mt: mobileSize ? 1 : 2 }}
                        color={isFurtherAvailable ? "success" : "warning"}
                        startIcon={
                          isFurtherAvailable &&
                          furtherRequested === false &&
                          checkInstantLoading === false ? (
                            <InputAdornment position="start">
                              <CheckCircleIcon style={{ color: "white" }} />
                            </InputAdornment>
                          ) : null
                        }
                      >
                        {isFurtherAvailable
                          ? "Send Request"
                          : "Check Availability"}
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
      </Collapse>

      <Grid container spacing={largeTabletSize ? 1 : 2} px={1} align="center">
        {slots.length !== 0 && (
          <Grid item xs={12} sx={{ mt: 2 }}>
            <Typography variant="h6" color="primary">
              Optimal Scheduling Options
            </Typography>
          </Grid>
        )}

        {slots.map((slot, index) => (
          <Grid item md={4} sm={6} xs={12} key={index}>
            <Button
              disabled={
                Boolean(type === "manage") &&
                Boolean(propertyDetails?.appointment)
                  ? Boolean(areDatesEqual(slot, propertyDetails.appointment))
                  : false
              }
              onClick={() => {
                if (slotIndex === index) {
                  setTempDate(null);
                  setTempTime(null);
                  setFurtherDateObj(null);
                  setSlotIndex(-1);
                } else {
                  convertObjectToDayjs(slot, setTempDate, setTempTime);
                  setSlotIndex(index);
                  setUnavailableText("");
                }
              }}
              fullWidth
              variant={slotIndex === index ? "contained" : "outlined"}
            >
              {formatSlotToUSDateTime(slot)}
            </Button>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Schedule;
