import { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { disconnectWebsocket } from "../../../store/actions/websocketActions";
import axios from "axios";
import {
  Box,
  Card,
  CircularProgress,
  Container,
  useMediaQuery,
  useTheme,
  Grid,
  Typography,
  IconButton,
  CardContent,
  Table,
  TableContainer,
  Paper,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Button,
  Tooltip,
  Stack,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import RefreshIcon from "@mui/icons-material/Refresh";
import NotariesGrid from "../../datagridActions/NotariesGrid";
import NotaryPerformanceChart from "../../notary/charts/NotaryPerformanceChart";
import NotaryRequestsGrid from "../../notary/admin/NotaryRequestsGrid";
import { LoadingButton } from "@mui/lab";
import EnableDisableNotary from "../../admin/modal/EnableDisableNotary";

const Notaries = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [notaryRow, setNotaryRow] = useState([]);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const currentSession = useSelector((state) => state.sessions.currentSession);
  const currentUser = useSelector((state) => state[currentSession].user);
  const [pageLoading, setPageLoading] = useState(true);
  const [fetching, setFetching] = useState(false);
  const [addNotaryLoading, setAddNotaryLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [addedText, setAddedText] = useState("");
  const [addNotaryError, setAddNotaryError] = useState("");
  const [selectedNotary, setSelectedNotary] = useState();
  const [removeNotaryLoading, setRemoveNotaryLoading] = useState(false);
  const [isCancelExpanded, setIsCancelExpanded] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  const [requestsSelectionModel, setRequestsSelectionModel] = useState([]);
  const [firstTData, setFirstTData] = useState([]);
  const [secondTData, setSecondTData] = useState([]);
  const [firstSData, setFirstSData] = useState([]);
  const [secondSData, setSecondSData] = useState([]);
  const [year, setYear] = useState(String(new Date().getFullYear()));

  const [notaryRequests, setNotaryRequests] = useState();
  const [notaryRequestRows, setNotaryRequestRows] = useState([]);

  const [activeLogLoading, setActiveOwnerLogLoading] = useState(false);
  const [isActiveLog, setIsActiveLog] = useState(false);
  const [activeLogs, setActiveLogs] = useState([]);
  const [selectedCurrentScheduleRow, setSelectedCurrentScheduleRow] = useState(
    []
  );
  const [hostSelected, setHostSelected] = useState(false);
  const [notarySelected, setNotarySelected] = useState(false);
  const [signeeSelected, setSigneeSelected] = useState(false);

  const [enableNotaryModal, setEnableNotaryModal] = useState(false);
  const [bnRegisteredLoading, setBnRegisteredLoading] = useState(false);

  let initialFetch = 0;
  const dispatchCount = useRef(0);

  const handleEnableNotaryModalClose = () => {
    setEnableNotaryModal(false);
  };

  useEffect(() => {
    if (currentUser) {
      if (currentUser?.user?.role !== "admin" || !currentUser?.user?.admin) {
        if (dispatchCount.current < 1) {
          dispatch(disconnectWebsocket(currentSession));
          navigate("/login");
          dispatchCount.current += 1;
        }
      }
    }
  }, [currentUser]);

  const handleConfirmEnableDisableNotary = async () => {
    try {
      setBnRegisteredLoading(true);
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/admin/enable-disable-notary`,
        {
          notary_email: selectedNotary?.email,
          currentSession: currentSession,
        },
        { withCredentials: true }
      );

      if (resp?.data?.success) {
        const index = notaryRow.findIndex(
          (row) => row.id === selectedNotary?.id
        );
        if (index !== -1) {
          // Create a new array with the modified object
          const updatedRows = notaryRow.map((row, idx) =>
            idx === index ? { ...row, bn_registered: resp?.data?.data } : row
          );
          setNotaryRow(updatedRows);
        }

        setSelectedNotary((prev) => ({
          ...prev,
          bn_registered: resp?.data?.data,
        }));
      }

      setEnableNotaryModal(false);
    } catch (error) {
    } finally {
      setBnRegisteredLoading(false);
    }
  };

  const handleActiveLog = async () => {
    try {
      setActiveOwnerLogLoading(true);
      if (isActiveLog === true) {
        setIsActiveLog(false);
        setNotarySelected(true);
        setSigneeSelected(true);
      } else if (isActiveLog === false && activeLogs.length < 1) {
        const resp = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/admin/get-active-logs`,
          {
            ss_reference: selectedCurrentScheduleRow?.ss_reference,
            signee_email: selectedCurrentScheduleRow?.signee_email,
            notary_name: selectedCurrentScheduleRow?.notary_name,
            currentSession: currentSession,
          },
          { withCredentials: true }
        );

        if (resp.data.success === true) {
          setActiveLogs(resp.data.data);
          setIsActiveLog(true);
          setNotarySelected(false);
          setSigneeSelected(false);
        }
      } else if (isActiveLog === false && activeLogs.length > 0) {
        setIsActiveLog(true);
        setNotarySelected(false);
        setSigneeSelected(false);
        setHostSelected(false);
      }
    } catch (e) {}
    setActiveOwnerLogLoading(false);
  };

  const handleFetch = async (fromCancel) => {
    if (fromCancel === false) {
      setFetching(true);
    }

    try {
      const fetch_resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/notary/notary-users`,
        { currentSession: currentSession },
        { withCredentials: true }
      );
      setNotaryRow(fetch_resp.data.data);
      setNotaryRequestRows(fetch_resp?.data?.notary_requests);
      setFetching(false);
      setPageLoading(false);

      return fetch_resp.data;
    } catch (e) {
      setAddNotaryError(e.response.data.message);
    }
  };

  const addNotaryFormik = useFormik({
    enableReinitialize: false,
    initialValues: {
      first: "",
      last: "",
      email: "",
    },
    validationSchema: Yup.object({
      first: Yup.string().min(3).required("First name is required"),
      last: Yup.string().min(3).required("Last name is required"),
      email: Yup.string()
        .required("Email is required")
        .email("Must be a valid email"),
    }),
    onSubmit: async (values, helpers) => {
      setAddNotaryLoading(true);
      try {
        const resp = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/notary/notary-invitation`,
          {
            values,
            currentSession: currentSession,
          },
          { withCredentials: true }
        );

        setNotaryRow((prevNotaryRow) => [...prevNotaryRow, resp.data.data]);

        setIsExpanded(false);
        setAddedText(resp.data.message);
        helpers.resetForm();
      } catch (e) {
        setAddNotaryError(e.response.data.message);

        if (e.response.data?.type === "email") {
          helpers.setFieldValue("email", "");
        }
      }

      setAddNotaryLoading(false);
    },
  });

  useEffect(() => {
    if (
      !currentUser ||
      currentUser?.user?.role !== "admin" ||
      !currentUser?.user?.admin
    ) {
      return;
    } else {
      if (initialFetch < 1) {
        initialFetch += 1;
        handleFetch(false);
      }
    }
  }, []);

  useEffect(() => {
    if (!currentUser) {
      return;
    }

    if (currentUser?.user?.expired) {
      navigate("/dashboard");
    }
  }, [currentUser]);

  useEffect(() => {
    if (addNotaryError !== "") {
      const addNotaryErrorTimer = setInterval(() => {
        setAddNotaryError("");
      }, 5000);
      return () => clearInterval(addNotaryErrorTimer);
    }

    if (addedText !== "") {
      const addedTextTimer = setInterval(() => {
        setAddedText("");
      }, 5000);
      return () => clearInterval(addedTextTimer);
    }
  }, [addNotaryError, addedText]);

  const handleChartValues = () => {
    let completedJobs = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let acceptedJobs = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let cancelledJobs = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    selectedNotary?.activity.map((item) => {
      if (item?.year === year) {
        completedJobs[item.month] = item.completed;
        acceptedJobs[item.month] = item.accepted;
        cancelledJobs[item.month] = item.cancelled;
      }
      return null;
    });

    return [
      {
        name: "Completed Jobs",
        type: "column",
        data: completedJobs,
      },
      {
        name: "Accepted Jobs",
        type: "area",
        data: acceptedJobs,
      },
      {
        name: "Cancelled Jobs",
        type: "line",
        data: cancelledJobs,
      },
    ];
  };

  return (
    <>
      {pageLoading ? (
        <>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100vh",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CircularProgress color="warning" size="10rem" />
          </Box>
        </>
      ) : (
        <>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              py: 2,
              backgroundColor: "#EEF2F6",
            }}
          >
            <Container maxWidth="xl" py={3}>
              <Card
                py={3}
                px={isSmallScreen ? 0 : 3}
                component={Grid}
                container
              >
                <Grid container>
                  <Grid item xs={12} sx={{ mb: 3, position: "relative" }}>
                    <Typography variant="h5" align="center">
                      Notary Users
                    </Typography>
                    <Tooltip title="Refresh All Data">
                      <IconButton
                        sx={{ position: "absolute", right: 0, top: -5 }}
                        className={fetching ? "rotate" : ""}
                        color="primary"
                        onClick={() => {
                          handleFetch(false);
                        }}
                      >
                        <RefreshIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>

                  <Grid item xs={12}>
                    <NotariesGrid
                      handleFetch={handleFetch}
                      currentUser={currentUser}
                      notariesRow={notaryRow}
                      setNotariesRow={setNotaryRow}
                      formik={addNotaryFormik}
                      addedText={addedText}
                      setSelectedNotary={setSelectedNotary}
                      selectedNotary={selectedNotary}
                      removeNotaryLoading={removeNotaryLoading}
                      setRemoveNotaryLoading={setRemoveNotaryLoading}
                      addNotaryLoading={addNotaryLoading}
                      addNotaryError={addNotaryError}
                      isExpanded={isExpanded}
                      setIsExpanded={setIsExpanded}
                      isCancelExpanded={isCancelExpanded}
                      setIsCancelExpanded={setIsCancelExpanded}
                      selectionModel={selectionModel}
                      setSelectionModel={setSelectionModel}
                      setFirstTData={setFirstTData}
                      setSecondTData={setSecondTData}
                      setNotaryRow={setNotaryRow}
                      setNotaryRequestRows={setNotaryRequestRows}
                    />
                  </Grid>
                  {selectedNotary && (
                    <>
                      <Grid item xs={12} sx={{ mt: "1rem" }}>
                        <Card>
                          <CardContent>
                            <Grid container>
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Typography align="center" variant="h4">
                                  {selectedNotary?.full_name
                                    .split(" ")
                                    .map(
                                      (word) =>
                                        word.charAt(0).toUpperCase() +
                                        word.slice(1)
                                    )
                                    .join(" ")}
                                </Typography>
                                <div
                                  style={{
                                    marginLeft: "0.5rem",
                                    backgroundColor:
                                      selectedNotary?.online_status ===
                                      "Offline"
                                        ? "red"
                                        : "green",
                                    minWidth: "15px",
                                    borderRadius: "50%",
                                    height: "15px",
                                  }}
                                ></div>
                                <Typography
                                  align="center"
                                  variant="h6"
                                  sx={{ ml: "5px" }}
                                >
                                  {selectedNotary?.online_status}
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography align="center" variant="h6">
                                  {selectedNotary?.isAdmin
                                    ? "Notary Admin"
                                    : ""}
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography align="center" variant="body2">
                                  {selectedNotary?.email}
                                </Typography>
                                <Typography align="center" variant="body2">
                                  {selectedNotary?.phone}
                                </Typography>
                              </Grid>

                              <Box
                                sx={{
                                  mt: 1,
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {selectedNotary?.availabilities?.length > 0 &&
                                selectedNotary?.on_site_registered &&
                                !selectedNotary?.bn_registered ? (
                                  <Button
                                    onClick={() => setEnableNotaryModal(true)}
                                    variant="contained"
                                    color="success"
                                  >
                                    Enable Notary
                                  </Button>
                                ) : selectedNotary?.availabilities?.length >
                                    0 &&
                                  selectedNotary?.on_site_registered &&
                                  selectedNotary?.bn_registered ? (
                                  <>
                                    <Button
                                      onClick={() => setEnableNotaryModal(true)}
                                      variant="outlined"
                                      color="error"
                                    >
                                      Disable Notary
                                    </Button>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </Box>

                              <Grid item xs={12} sx={{ mt: 4 }}>
                                <Grid container>
                                  <Grid item sm={6}>
                                    <Box
                                      sx={{
                                        bgcolor: "#fff",
                                        minHeight: "100px", // Adjust as needed
                                      }}
                                    >
                                      <TableContainer component={Paper}>
                                        <Table>
                                          <TableHead>
                                            <TableRow>
                                              <TableCell>Detail</TableCell>
                                              <TableCell align="right">
                                                Information
                                              </TableCell>
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                            {firstTData?.map((row, index) => (
                                              <TableRow key={index}>
                                                <TableCell
                                                  component="th"
                                                  scope="row"
                                                  sx={{ fontWeight: "bold" }}
                                                >
                                                  {row.label}
                                                </TableCell>
                                                {row.label ===
                                                "NOC Document" ? (
                                                  <TableCell
                                                    sx={{
                                                      color: "blue",
                                                      textDecoration:
                                                        "underline",
                                                      cursor: "pointer",
                                                      "&:hover": {
                                                        textDecoration: "none",
                                                        color: "darkblue",
                                                      },
                                                    }}
                                                    align="right"
                                                  >
                                                    {row.value}
                                                  </TableCell>
                                                ) : (
                                                  <TableCell align="right">
                                                    {row.value}
                                                  </TableCell>
                                                )}
                                              </TableRow>
                                            ))}
                                          </TableBody>
                                        </Table>
                                      </TableContainer>
                                    </Box>
                                  </Grid>
                                  <Grid item sm={6}>
                                    <Box
                                      sx={{
                                        bgcolor: "#fff",
                                        minHeight: "100px", // Adjust as needed
                                      }}
                                    >
                                      <TableContainer component={Paper}>
                                        <Table>
                                          <TableHead>
                                            <TableRow>
                                              <TableCell>Detail</TableCell>
                                              <TableCell align="right">
                                                Information
                                              </TableCell>
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                            {secondTData?.map((row) => (
                                              <TableRow key={row.label}>
                                                <TableCell
                                                  component="th"
                                                  scope="row"
                                                  sx={{ fontWeight: "bold" }}
                                                >
                                                  {row.label}
                                                </TableCell>
                                                <TableCell align="right">
                                                  {row.value}
                                                </TableCell>
                                              </TableRow>
                                            ))}
                                          </TableBody>
                                        </Table>
                                      </TableContainer>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  mt: 4,
                                }}
                              >
                                <Typography align="center" variant="h5">
                                  Work Hours
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sx={{ mt: 2 }}>
                                <Grid container>
                                  <Grid item xs={12}>
                                    <Box
                                      sx={{
                                        bgcolor: "#fff",
                                        minHeight: "100px", // Adjust as needed
                                      }}
                                    >
                                      <TableContainer component={Paper}>
                                        <Table>
                                          <TableHead>
                                            <TableRow>
                                              <TableCell>Day</TableCell>
                                              <TableCell align="right">
                                                Start Time
                                              </TableCell>
                                              <TableCell align="right">
                                                End Time
                                              </TableCell>
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                            {selectedNotary?.availabilities?.map(
                                              (row, index) => (
                                                <TableRow key={index}>
                                                  <TableCell
                                                    component="th"
                                                    scope="row"
                                                    sx={{ fontWeight: "bold" }}
                                                  >
                                                    {row.day}
                                                  </TableCell>

                                                  <TableCell align="right">
                                                    {row.start}
                                                  </TableCell>

                                                  <TableCell align="right">
                                                    {row.end}
                                                  </TableCell>
                                                </TableRow>
                                              )
                                            )}
                                          </TableBody>
                                        </Table>
                                      </TableContainer>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                        <Card sx={{ mt: 4 }}>
                          <CardContent>
                            <Grid container>
                              <Grid item xs={12}>
                                <NotaryPerformanceChart
                                  year={year}
                                  setYear={setYear}
                                  handleChartValues={handleChartValues}
                                  chartSeries={handleChartValues()}
                                  sx={{ height: "100%" }}
                                />
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Card>
              <Card
                mt={3}
                py={3}
                px={isSmallScreen ? 0 : 3}
                component={Grid}
                container
              >
                <Grid container>
                  <Grid item xs={12} sx={{ mb: 3, position: "relative" }}>
                    <Typography variant="h5" align="center">
                      Active Notarization Requests
                    </Typography>
                    {/* <IconButton
                      sx={{ position: "absolute", right: 0, top: -5 }}
                      className={fetching ? "rotate" : ""}
                      color="primary"
                      onClick={handleFetch}
                    >
                      <RefreshIcon />
                    </IconButton> */}
                  </Grid>
                  <Grid item xs={12}>
                    <NotaryRequestsGrid
                      setNotaryRequests={setNotaryRequests}
                      setRequestsSelectionModel={setRequestsSelectionModel}
                      requestsSelectionModel={requestsSelectionModel}
                      notaryRequestRows={notaryRequestRows}
                      setFirstSData={setFirstSData}
                      setSecondSData={setSecondSData}
                      setSelectedCurrentScheduleRow={
                        setSelectedCurrentScheduleRow
                      }
                      setActiveLogs={setActiveLogs}
                      setIsActiveLog={setIsActiveLog}
                      setNotarySelected={setNotarySelected}
                      setSigneeSelected={setSigneeSelected}
                    />
                  </Grid>
                  {notaryRequests && (
                    <>
                      <Grid item xs={12} sx={{ mt: "1rem" }}>
                        <Card>
                          <CardContent>
                            <Grid container>
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Typography align="center" variant="h5">
                                  {isActiveLog
                                    ? "RON Document LOG"
                                    : notaryRequests?.ron_status}
                                </Typography>
                              </Grid>

                              {isActiveLog && (
                                <>
                                  <Grid item container mt={2}>
                                    {activeLogs?.[0]?.document_status?.map(
                                      (item, index) => (
                                        <Grid key={index} item sm={4}>
                                          <Stack
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center"
                                          >
                                            <Typography
                                              sx={{ fontWeight: "bold" }}
                                            >
                                              {item?.label}
                                            </Typography>
                                            <div
                                              style={{
                                                marginLeft: "0.5rem",
                                                width: "15px",
                                                height: "15px",
                                                borderRadius: "50%",
                                                backgroundColor:
                                                  item?.value === true
                                                    ? "green"
                                                    : "red",
                                              }}
                                            ></div>
                                          </Stack>
                                        </Grid>
                                      )
                                    )}
                                  </Grid>
                                </>
                              )}

                              <Grid item xs={12} sx={{ mt: 4 }}>
                                <Grid container>
                                  {isActiveLog && (
                                    <>
                                      <Grid item sm={12}>
                                        <Box
                                          sx={{
                                            bgcolor: "#fff",
                                            minHeight: hostSelected
                                              ? "100px"
                                              : "0px", // Adjust as needed
                                          }}
                                        >
                                          <Typography
                                            onClick={() => {
                                              setHostSelected(!hostSelected);
                                            }}
                                            align="center"
                                            sx={{
                                              cursor: "pointer",
                                              color: "#004976",
                                              mb: 2,
                                              textDecoration: "underline",
                                            }}
                                            variant="h5"
                                          >
                                            Host
                                          </Typography>
                                          {hostSelected && (
                                            <>
                                              <TableContainer component={Paper}>
                                                <Table>
                                                  <TableHead>
                                                    <TableRow>
                                                      <TableCell>
                                                        DATE
                                                      </TableCell>
                                                      <TableCell align="right">
                                                        ACTIONS
                                                      </TableCell>
                                                    </TableRow>
                                                  </TableHead>

                                                  <TableBody>
                                                    {activeLogs?.[0]?.document_preparation?.map(
                                                      (row, index) => (
                                                        <TableRow key={index}>
                                                          <TableCell
                                                            component="th"
                                                            scope="row"
                                                            sx={{
                                                              fontWeight:
                                                                "bold",
                                                            }}
                                                          >
                                                            {row.date}
                                                          </TableCell>
                                                          <TableCell align="right">
                                                            {row.action}
                                                          </TableCell>
                                                        </TableRow>
                                                      )
                                                    )}
                                                  </TableBody>
                                                </Table>
                                              </TableContainer>
                                            </>
                                          )}
                                        </Box>
                                      </Grid>
                                    </>
                                  )}
                                  <Grid item sm={6} sx={{ mt: 3 }}>
                                    <Box
                                      sx={{
                                        bgcolor: "#fff",
                                        minHeight: notarySelected
                                          ? "100px"
                                          : "0px", // Adjust as needed
                                      }}
                                    >
                                      {isActiveLog && (
                                        <>
                                          <Typography
                                            align="center"
                                            onClick={() => {
                                              setNotarySelected(
                                                !notarySelected
                                              );
                                            }}
                                            sx={{
                                              color: "#10B981",
                                              cursor: "pointer",
                                              mb: 2,
                                              textDecoration: "underline",
                                            }}
                                            variant="h5"
                                          >
                                            Notary Actions
                                          </Typography>
                                        </>
                                      )}
                                      {notarySelected && (
                                        <>
                                          <TableContainer component={Paper}>
                                            <Table>
                                              <TableHead>
                                                <TableRow>
                                                  <TableCell>
                                                    {isActiveLog
                                                      ? "DATE"
                                                      : "DETAIL"}
                                                  </TableCell>
                                                  <TableCell align="right">
                                                    {isActiveLog
                                                      ? "ACTIONS"
                                                      : "INFORMATION"}
                                                  </TableCell>
                                                </TableRow>
                                              </TableHead>
                                              {isActiveLog ? (
                                                <>
                                                  <TableBody>
                                                    {activeLogs?.[0]?.notary_log.map(
                                                      (row, index) => (
                                                        <TableRow key={index}>
                                                          <TableCell
                                                            component="th"
                                                            scope="row"
                                                            sx={{
                                                              fontWeight:
                                                                "bold",
                                                            }}
                                                          >
                                                            {row.date}
                                                          </TableCell>

                                                          <TableCell align="right">
                                                            {row.action}
                                                          </TableCell>
                                                        </TableRow>
                                                      )
                                                    )}
                                                  </TableBody>
                                                </>
                                              ) : (
                                                <>
                                                  <TableBody>
                                                    {firstSData?.map(
                                                      (row, index) => (
                                                        <TableRow key={index}>
                                                          <TableCell
                                                            component="th"
                                                            scope="row"
                                                            sx={{
                                                              fontWeight:
                                                                "bold",
                                                            }}
                                                          >
                                                            {row.label}
                                                          </TableCell>
                                                          {row.label ===
                                                          "NOC Document" ? (
                                                            <TableCell
                                                              sx={{
                                                                color: "blue",
                                                                textDecoration:
                                                                  "underline",
                                                                cursor:
                                                                  "pointer",
                                                                "&:hover": {
                                                                  textDecoration:
                                                                    "none",
                                                                  color:
                                                                    "darkblue",
                                                                },
                                                              }}
                                                              align="right"
                                                            >
                                                              {row.value}
                                                            </TableCell>
                                                          ) : (
                                                            <TableCell align="right">
                                                              {row.value}
                                                            </TableCell>
                                                          )}
                                                        </TableRow>
                                                      )
                                                    )}
                                                  </TableBody>
                                                </>
                                              )}
                                            </Table>
                                          </TableContainer>
                                        </>
                                      )}
                                    </Box>
                                  </Grid>
                                  <Grid item sm={6} sx={{ mt: 3 }}>
                                    <Box
                                      sx={{
                                        bgcolor: "#fff",
                                        minHeight: signeeSelected
                                          ? "100px"
                                          : "0px", // Adjust as needed
                                      }}
                                    >
                                      {isActiveLog && (
                                        <>
                                          <Typography
                                            align="center"
                                            onClick={() => {
                                              setSigneeSelected(
                                                !signeeSelected
                                              );
                                            }}
                                            sx={{
                                              color: "#F79009",
                                              cursor: "pointer",
                                              mb: 2,
                                              textDecoration: "underline",
                                            }}
                                            variant="h5"
                                          >
                                            Signee Actions
                                          </Typography>
                                        </>
                                      )}
                                      {signeeSelected && (
                                        <>
                                          <TableContainer component={Paper}>
                                            <Table>
                                              <TableHead>
                                                <TableRow>
                                                  <TableCell>
                                                    {isActiveLog
                                                      ? "DATE"
                                                      : "DETAIL"}
                                                  </TableCell>
                                                  <TableCell align="right">
                                                    {isActiveLog
                                                      ? "ACTIONS"
                                                      : "INFORMATION"}
                                                  </TableCell>
                                                </TableRow>
                                              </TableHead>
                                              {isActiveLog ? (
                                                <>
                                                  <TableBody>
                                                    {activeLogs?.[0]?.signee_log?.map(
                                                      (row, index) => (
                                                        <TableRow key={index}>
                                                          <TableCell
                                                            component="th"
                                                            scope="row"
                                                            sx={{
                                                              fontWeight:
                                                                "bold",
                                                            }}
                                                          >
                                                            {row.date}
                                                          </TableCell>
                                                          <TableCell align="right">
                                                            {row.action}
                                                          </TableCell>
                                                        </TableRow>
                                                      )
                                                    )}
                                                  </TableBody>
                                                </>
                                              ) : (
                                                <>
                                                  <TableBody>
                                                    {secondSData?.map((row) => (
                                                      <TableRow key={row.label}>
                                                        <TableCell
                                                          component="th"
                                                          scope="row"
                                                          sx={{
                                                            fontWeight: "bold",
                                                          }}
                                                        >
                                                          {row.label}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                          {row.value}
                                                        </TableCell>
                                                      </TableRow>
                                                    ))}
                                                  </TableBody>
                                                </>
                                              )}
                                            </Table>
                                          </TableContainer>
                                        </>
                                      )}
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid container spacing={3} mt={3}>
                              {/* <Grid item xs={6}>
                                <Button
                                  disabled={
                                    activeLogLoading ||
                                    isActiveLog ||
                                    Boolean(
                                      selectedCurrentScheduleRow?.ss_reference
                                    )
                                  }
                                  fullWidth
                                  variant="contained"
                                  color="error"
                                >
                                  Cancel Remote Notarization Request
                                </Button>
                              </Grid> */}
                              <Grid item xs={12}>
                                <LoadingButton
                                  disabled={Boolean(
                                    !selectedCurrentScheduleRow?.ss_reference
                                  )}
                                  loading={activeLogLoading}
                                  onClick={() => {
                                    handleActiveLog();
                                  }}
                                  fullWidth
                                  variant="contained"
                                  color={isActiveLog ? "warning" : "success"}
                                >
                                  {isActiveLog ? "Hide Log" : "Show Log"}
                                </LoadingButton>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Card>
            </Container>
          </Box>

          <EnableDisableNotary
            open={enableNotaryModal}
            handleClose={handleEnableNotaryModalClose}
            handleConfirm={handleConfirmEnableDisableNotary}
            currentAction={
              selectedNotary?.bn_registered &&
              selectedNotary?.on_site_registered
                ? "disable"
                : !selectedNotary?.bn_registered &&
                  selectedNotary?.on_site_registered &&
                  selectedNotary?.availabilities?.length > 0
                ? "enable"
                : ""
            }
            bnRegisteredLoading={bnRegisteredLoading}
          />
        </>
      )}
    </>
  );
};

export default Notaries;
