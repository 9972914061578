import { useState, useRef } from "react";
import FileUploadBox from "./FileUploadBox";
import { floridaCounties } from "../../../data/counties";
import { useFormikContext } from "formik";
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import CompareIcon from "@mui/icons-material/Compare";
import Magnifier from "../../customComponents/Magnifier";

const FileUpload = ({
  formik,
  mobileSize,
  tabletSize,
  setMobileUploaderOpen,
  uploadError,
  setUploadError,
  initialValues,
}) => {
  const {
    values,
    setFieldValue,
    resetForm,
    touched,
    errors,
    handleChange,
    handleBlur,
  } = useFormikContext();

  const handleRemovePDF = () => {
    if (tabletSize) {
      setMobileUploaderOpen(false);
    }
    setUploadError("");
    resetForm({ values: initialValues });
  };

  return (
    <>
      {values.previewUrl && (values.files || values.fileName) && !tabletSize ? (
        <Grid container>
          <Grid
            item
            sm={6}
            sx={{
              position: "relative",
              backgroundColor: "#e0e0e0",
              padding: "1rem",
              border: "2px dashed gray",
            }}
            xs={12}
          >
            <Magnifier src={values.previewUrl} lensSize={400} zoomFactor={4} />
            <Tooltip title="Discard and Upload New Document">
              <IconButton
                onClick={handleRemovePDF}
                sx={{
                  position: "absolute",
                  right: 5,
                  top: 5,
                  backgroundColor: "red",
                  "&:hover": {
                    backgroundColor: "darkred", // Change to your preferred darker red color
                  },
                }}
              >
                <CompareIcon sx={{ fontSize: 30, color: "white" }} />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item sm={6} xs={12} sx={{ px: "1rem" }}>
            <Box
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                width: "100%",
              }}
            >
              <Typography variant="h5" align="center" noWrap>
                {values.fileName}
              </Typography>
            </Box>
            <Grid container>
              <Grid item xs={12} sx={{ mt: 3 }}>
                <Typography
                  variant="h6"
                  sx={{
                    textAlign: "center",
                    color: touched.county && errors.county ? "red" : "black",
                  }}
                  fontWeight="bold"
                >
                  County
                </Typography>
                <FormControl
                  variant="filled"
                  sx={{ mt: 0.5 }}
                  fullWidth
                  error={Boolean(touched.county && errors.county)}
                >
                  <InputLabel
                    sx={
                      touched.county && errors.county
                        ? { color: "#f04438" }
                        : { color: "auto" }
                    }
                    id="county-label"
                  >
                    Please select one
                  </InputLabel>
                  <Select
                    error={Boolean(touched.county && errors.county)}
                    labelId="county-label"
                    id="county"
                    fullWidth
                    name="county"
                    value={values?.county}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          marginTop: "1.5px",
                          maxHeight: 350, // Set the desired max height for the dropdown menu
                          width: "auto",
                          maxWidth: "100%",
                        },
                      },
                    }}
                    sx={{
                      maxWidth: "100%", // Set the desired max-width for the input element
                      "& .MuiTypography-body2": {
                        whiteSpace: "wrap", // Prevent text from wrapping or shrinking
                      },
                    }}
                  >
                    {floridaCounties.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText sx={{ color: "#f04438" }}>
                    {touched.county && errors.county ? errors.county : ""}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sx={{ mt: 3 }}>
                <Typography
                  variant="h6"
                  sx={{
                    textAlign: "center",
                    color:
                      touched.documentType && errors.documentType
                        ? "red"
                        : "black",
                  }}
                  fontWeight="bold"
                >
                  Document Type
                </Typography>
                <FormControl
                  variant="filled"
                  sx={{ mt: 0.5 }}
                  fullWidth
                  error={Boolean(touched.documentType && errors.documentType)}
                >
                  <InputLabel
                    sx={
                      touched.documentType && errors.documentType
                        ? { color: "#f04438" }
                        : { color: "auto" }
                    }
                    id="county-label"
                  >
                    Please select one
                  </InputLabel>
                  <Select
                    error={Boolean(touched.documentType && errors.documentType)}
                    labelId="documentType-label"
                    id="documentType"
                    fullWidth
                    name="documentType"
                    value={values?.documentType}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          marginTop: "1.5px",
                          maxHeight: 350, // Set the desired max height for the dropdown menu
                          width: "auto",
                          maxWidth: "100%",
                        },
                      },
                    }}
                    sx={{
                      maxWidth: "100%", // Set the desired max-width for the input element
                      "& .MuiTypography-body2": {
                        whiteSpace: "wrap", // Prevent text from wrapping or shrinking
                      },
                    }}
                  >
                    {["Notice Of Commencement (NOC)"].map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText sx={{ color: "#f04438" }}>
                    {touched.documentType && errors.documentType
                      ? errors.documentType
                      : ""}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sx={{ mt: 3 }}>
                <Typography
                  variant="h6"
                  sx={{
                    textAlign: "center",
                    color: touched.reason && errors.reason ? "red" : "black",
                  }}
                  fontWeight="bold"
                >
                  Process Type
                </Typography>
                <FormControl
                  variant="filled"
                  sx={{ mt: 0.5 }}
                  fullWidth
                  error={Boolean(touched.reason && errors.reason)}
                >
                  <InputLabel
                    sx={
                      touched.reason && errors.reason
                        ? { color: "#f04438" }
                        : { color: "auto" }
                    }
                    id="county-label"
                  >
                    Please select one
                  </InputLabel>
                  <Select
                    error={Boolean(touched.reason && errors.reason)}
                    labelId="reason-label"
                    id="reason"
                    fullWidth
                    name="reason"
                    value={values?.reason}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          marginTop: "1.5px",
                          maxHeight: 350, // Set the desired max height for the dropdown menu
                          width: "auto",
                          maxWidth: "100%",
                        },
                      },
                    }}
                    sx={{
                      maxWidth: "100%", // Set the desired max-width for the input element
                      "& .MuiTypography-body2": {
                        whiteSpace: "wrap", // Prevent text from wrapping or shrinking
                      },
                    }}
                  >
                    {[
                      "Notarization & Recording",
                      "Notarization",
                      "Recording",
                    ].map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText sx={{ color: "#f04438" }}>
                    {touched.reason && errors.reason ? errors.reason : ""}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <FileUploadBox
          formik={formik}
          uploadError={uploadError}
          setUploadError={setUploadError}
          mobileSize={mobileSize}
          tabletSize={tabletSize}
          setMobileUploaderOpen={setMobileUploaderOpen}
        />
      )}
    </>
  );
};

export default FileUpload;
