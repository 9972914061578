import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useFormikContext } from "formik";
import { floridaCounties } from "../../../data/counties";

const MobileFileUpload = () => {
  const {
    values,
    setFieldValue,
    resetForm,
    touched,
    errors,
    handleChange,
    handleBlur,
  } = useFormikContext();

  return (
    <Box sx={{ p: 1, mx: 1 }}>
      <Box>
        <Typography color="primary" align="center" variant="h6">
          {values.fileName}
        </Typography>
      </Box>
      <Box mt={5} sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
        {/* COUNTY */}
        <Box>
          <Typography
            variant="body1"
            sx={{
              color: touched.county && errors.county ? "red" : "black",
              mb: -1,
            }}
            fontWeight="bold"
          >
            County
          </Typography>
          <FormControl
            variant="filled"
            sx={{ mt: 0.5 }}
            fullWidth
            error={Boolean(touched.county && errors.county)}
          >
            <InputLabel
              sx={
                touched.county && errors.county
                  ? { color: "#f04438" }
                  : { color: "auto" }
              }
              id="county-label"
            >
              Please select one
            </InputLabel>
            <Select
              size="small"
              error={Boolean(touched.county && errors.county)}
              labelId="county-label"
              id="county"
              fullWidth
              name="county"
              value={values?.county}
              onChange={handleChange}
              onBlur={handleBlur}
              MenuProps={{
                PaperProps: {
                  style: {
                    marginTop: "1.5px",
                    maxHeight: 170, // Set the desired max height for the dropdown menu
                    width: "auto",
                    maxWidth: "100%",
                  },
                },
              }}
              sx={{
                maxWidth: "100%", // Set the desired max-width for the input element
                "& .MuiTypography-body2": {
                  whiteSpace: "wrap", // Prevent text from wrapping or shrinking
                },
              }}
            >
              {floridaCounties.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText sx={{ color: "#f04438" }}>
              {touched.county && errors.county ? errors.county : ""}
            </FormHelperText>
          </FormControl>
        </Box>
        {/* *************** */}
        {/* DOCUMENT TYPE */}
        <Box>
          <Typography
            variant="body1"
            sx={{
              color: touched.county && errors.county ? "red" : "black",
              mb: -1,
            }}
            fontWeight="bold"
          >
            Document Type
          </Typography>
          <FormControl
            variant="filled"
            sx={{ mt: 0.5 }}
            fullWidth
            error={Boolean(touched.documentType && errors.documentType)}
          >
            <InputLabel
              sx={
                touched.documentType && errors.documentType
                  ? { color: "#f04438" }
                  : { color: "auto" }
              }
              id="county-label"
            >
              Please select one
            </InputLabel>
            <Select
              error={Boolean(touched.documentType && errors.documentType)}
              labelId="documentType-label"
              id="documentType"
              fullWidth
              name="documentType"
              value={values?.documentType}
              onChange={handleChange}
              onBlur={handleBlur}
              MenuProps={{
                PaperProps: {
                  style: {
                    marginTop: "1.5px",
                    maxHeight: 170, // Set the desired max height for the dropdown menu
                    width: "auto",
                    maxWidth: "100%",
                  },
                },
              }}
              sx={{
                maxWidth: "100%", // Set the desired max-width for the input element
                "& .MuiTypography-body2": {
                  whiteSpace: "wrap", // Prevent text from wrapping or shrinking
                },
              }}
            >
              {["Notice Of Commencement (NOC)"].map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText sx={{ color: "#f04438" }}>
              {touched.documentType && errors.documentType
                ? errors.documentType
                : ""}
            </FormHelperText>
          </FormControl>
        </Box>
        {/* *************** */}
        {/* ******* PROCESS TYPE ******** */}
        <Box>
          <Typography
            variant="body1"
            sx={{
              color: touched.county && errors.county ? "red" : "black",
              mb: -1,
            }}
            fontWeight="bold"
          >
            Process Type
          </Typography>
          <FormControl
            variant="filled"
            sx={{ mt: 0.5 }}
            fullWidth
            error={Boolean(touched.reason && errors.reason)}
          >
            <InputLabel
              sx={
                touched.reason && errors.reason
                  ? { color: "#f04438" }
                  : { color: "auto" }
              }
              id="county-label"
            >
              Please select one
            </InputLabel>
            <Select
              error={Boolean(touched.reason && errors.reason)}
              labelId="reason-label"
              id="reason"
              fullWidth
              name="reason"
              value={values?.reason}
              onChange={handleChange}
              onBlur={handleBlur}
              MenuProps={{
                PaperProps: {
                  style: {
                    marginTop: "1.5px",
                    maxHeight: 170, // Set the desired max height for the dropdown menu
                    width: "auto",
                    maxWidth: "100%",
                  },
                },
              }}
              sx={{
                maxWidth: "100%", // Set the desired max-width for the input element
                "& .MuiTypography-body2": {
                  whiteSpace: "wrap", // Prevent text from wrapping or shrinking
                },
              }}
            >
              {["Notarization & Recording", "Notarization", "Recording"].map(
                (item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                )
              )}
            </Select>
            <FormHelperText sx={{ color: "#f04438" }}>
              {touched.reason && errors.reason ? errors.reason : ""}
            </FormHelperText>
          </FormControl>
        </Box>
        {/* *************** */}
      </Box>
    </Box>
  );
};

export default MobileFileUpload;
