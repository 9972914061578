import { useState } from "react";
import { Box, Divider, Typography } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const SideNavAd = ({
  handleMouseUp,
  handleMouseDown,
  isClicked,
  isTop,
  isBottom,
}) => {
  return (
    <>
      <Divider sx={{ borderColor: "neutral.700" }} />
      <Box
        sx={{
          height: "2.6rem",
          backgroundColor: isClicked ? "neutral.600" : "neutral.700",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          // You can still keep other styles here
        }}
        onMouseDown={handleMouseDown} // Handles mouse click
        onMouseUp={handleMouseUp} // Resets the state when the mouse button is released
        // Optional: Resets the state if the cursor leaves the component while holding the mouse button
        onTouchStart={handleMouseDown} // Handles touch
        onTouchEnd={handleMouseUp} // Resets the state when the touch ends
      >
        {isTop === true ? (
          <KeyboardArrowDownIcon
            sx={{ color: "neutral.400", fontSize: "40px" }}
          />
        ) : (
          <KeyboardArrowUpIcon
            sx={{ color: "neutral.400", fontSize: "40px" }}
          />
        )}
      </Box>
      <Box
        sx={{
          mb: 2,
          px: 2,
          py: 1,
        }}
      >
        <Typography color="neutral.100" align="center" variant="h6">
          Powered By
        </Typography>
        <Typography color="neutral.500" align="center" variant="body2">
          SR360 Solutions
        </Typography>

        <Box
          component="a"
          href="https://www.sr360solutions.com"
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            display: "flex",
            mx: "auto",
            width: "160px",
            "& img": {
              width: "100%",
            },
          }}
        >
          <img alt="SR360solutions" src="/img/logos/Ball-icon.png" />
        </Box>
        {/* <Button
          endIcon={
            <SvgIcon fontSize="small">
              <ArrowTopRightOnSquareIcon />
            </SvgIcon>
          }
          fullWidth
          sx={{ mt: 2 }}
          variant="contained"
        >
          Start Subscription
        </Button> */}
      </Box>
    </>
  );
};

export default SideNavAd;
