import { useRef } from "react";
import { useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Box,
  Divider,
  Grid,
  MenuItem,
  MenuList,
  Popover,
  Typography,
  IconButton,
  Tooltip,
} from "@mui/material";
import axios from "axios";
import TimezoneSelect from "../layout/TimezoneSelect";
import { UserAddOutlined, UserDeleteOutlined } from "@ant-design/icons";

import { disconnectWebsocket } from "../../store/actions/websocketActions";
import { logoutHandler } from "../../store/actions/sessionsActions";

export const AccountPopover = (props) => {
  const { anchorEl, onClose, open } = props;
  const fileInputRef = useRef(null);

  const removeAvatar = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/data/remove-avatar`,
        { currentSession: props?.currentSession },

        {
          withCredentials: true,
        }
      );

      props?.setAvatarUrl("");
      if (fileInputRef.current) {
        fileInputRef.current.value = null;
      }
    } catch (e) {}
  };

  const handleAvatarChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    // Validate the file size and type
    const maxSize = 5 * 1024 * 1024; // 5MB
    if (file.size > maxSize) {
      alert("File is too large. Please select a file smaller than 5MB.");
      return;
    }
    if (!file.type.startsWith("image/")) {
      alert("Please select an image file.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("currentSession", props?.currentSession);

    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/data/upload-avatar`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        }
      );

      props?.setAvatarUrl(resp?.data.data);
    } catch (e) {}
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const exitHandler = async () => {
    props?.navigate("/login");
    await props?.dispatch(logoutHandler(props?.currentSession));
  };
  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: "left",
        vertical: "bottom",
      }}
      onClose={onClose}
      open={open}
      sx={{ width: "auto" }}
    >
      <Box
        sx={{
          py: 1.5,
          px: 2,
          textAlign: "center",
        }}
      >
        <Typography variant="h6" align="center" sx={{ marginTop: "1rem" }}>
          {props?.user?.user && props?.user?.subuser
            ? props?.user?.subuser?.role
            : props?.user?.user?.role === "admin"
            ? "Super User"
            : props?.user?.user?.role === "tester"
            ? "Tester"
            : props?.user?.role === "notary" && props?.user?.is_notary_admin
            ? "Notary Admin"
            : props?.user?.role === "notary" && !props?.user?.is_notary_admin
            ? "Notary User"
            : "Administrator"}
        </Typography>
        <div
          style={{
            borderBottom: "3px solid #d3d3d3",
            marginTop: "3px",
          }}
        ></div>
        {props?.user?.role === "notary" ? (
          <>
            <Typography variant="overline" align="center">
              {props?.user?.full_name}
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="overline" align="center">
              {props?.user?.user &&
                props?.user?.user?.personal?.[0]?.firstName +
                  " " +
                  props?.user?.user?.personal?.[0]?.lastName}
            </Typography>
          </>
        )}

        <Typography color="text.secondary" variant="body2">
          {props?.user?.user && props?.user?.subuser
            ? props?.user?.subuser?.email
            : props?.user?.role === "notary"
            ? props?.user?.email
            : props?.user?.user?.email}
        </Typography>
        <Divider />

        {props?.user?.subuser && (
          <>
            <div
              style={{
                borderBottom: "3px solid #d3d3d3",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            ></div>
            <Typography
              variant="overline"
              align="center"
              sx={{ marginTop: "1rem" }}
            >
              Your Administrator
            </Typography>
            <Typography color="text.secondary" variant="body2">
              {typeof props?.user?.subuser?.admin === "string"
                ? props.user.subuser.admin
                : ""}
            </Typography>
            <Typography color="text.secondary" variant="body2">
              {props?.user?.subuser && props?.user?.user?.email}
            </Typography>
          </>
        )}
      </Box>

      <input
        type="file"
        ref={fileInputRef}
        onChange={handleAvatarChange}
        style={{ display: "none" }}
        accept="image/*"
      />
      <Typography align="center" variant="body2">
        Avatar
      </Typography>
      <Box
        sx={{
          borderTop: "1px solid #d3d3d3",

          pb: 1,
        }}
      >
        {props?.avatarUrl ? (
          <>
            <Grid container align="center" px={2} sx={{ width: "100%" }}>
              <Grid item xs={6}>
                <Tooltip title="Remove Avatar">
                  <IconButton
                    aria-label="add"
                    sx={{ color: "#f44336" }}
                    onClick={removeAvatar}
                  >
                    <UserDeleteOutlined />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={6}>
                <Tooltip title="Change Avatar">
                  <IconButton
                    aria-label="add"
                    sx={{ color: "#1976d2" }}
                    onClick={triggerFileInput}
                  >
                    <UserAddOutlined />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="body2"
                  align="center"
                  sx={{ fontSize: "12px" }}
                >
                  Remove
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="body2"
                  align="center"
                  sx={{ fontSize: "12px" }}
                >
                  Change
                </Typography>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <Grid container align="center" px={2}>
              <Grid item xs={12}>
                <Tooltip title="Add Avatar">
                  <IconButton
                    aria-label="add"
                    sx={{ color: "#4caf50" }}
                    onClick={triggerFileInput}
                  >
                    <UserAddOutlined />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  align="center"
                  sx={{ fontSize: "12px" }}
                >
                  Add
                </Typography>
              </Grid>
            </Grid>
          </>
        )}
      </Box>

      {props?.tabletSize && (
        <Box sx={{ px: 1, pb: 1 }}>
          <TimezoneSelect
            options={props?.timezoneMappings}
            timezone={props?.timezone}
            setTimezone={props?.setTimezone}
          />
        </Box>
      )}

      <MenuList
        disablePadding
        dense
        sx={{
          "& > *": {
            borderRadius: 1,
          },
        }}
      >
        <LoadingButton
          loading={props?.signOutLoading}
          variant="contained"
          fullWidth
          size="small"
          onClick={exitHandler}
        >
          Sign out
        </LoadingButton>
      </MenuList>
    </Popover>
  );
};
