import React from "react";
import { Box, Button } from "@mui/material";

const WizardImage = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center", // This aligns children (your Box) vertically center
        justifyContent: "center", // This aligns children horizontally center
        height: "100%",
        zIndex: 0,
      }}
    >
      <Box
        component="img"
        src="img/packages/buildingPermitWizard.jpg"
        alt="Legal Size"
        sx={{
          width: "100%",
          maxWidth: "50rem",
          height: "auto",
          maxHeight: "90vh",
          aspectRatio: "0.807",
        }}
      />
      <Button
        variant="contained"
        size="small"
        sx={{
          position: "absolute",
          top: "55%",
          left: "36.5%",
          transform: "translate(-50%, -50%)",
          padding: "10px 20px",
          backgroundColor: "#f2eadf",
          border: "2px solid",
          borderColor: "#715e60",
          color: "#715e60",
          "&:hover": {
            backgroundColor: "#715e60",
            color: "#f2eadf",
          },
        }}
      >
        Create Building Permit
      </Button>
    </Box>
  );
};

export default WizardImage;
