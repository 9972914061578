import {
  Box,
  Grid,
  Container,
  Card,
  CircularProgress,
  Typography,
  Stack,
  useMediaQuery,
  useTheme,
  TextField,
  InputAdornment,
  Pagination,
  Snackbar,
  Alert,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import ErrorIcon from "@mui/icons-material/Error";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { LoadingButton } from "@mui/lab";
import SelectCountyFormSelect from "../noc/SelectCountyFormSelect";
import SearchIcon from "@mui/icons-material/Search";
import { disableEmptyCountyFlag } from "../../store/actions/dataActions";

const SelectCounties = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [formDrawerOpen, setFormDrawerOpen] = useState(false);
  const [selectedPreForm, setSelectedPreForm] = useState("");
  const [openDrawer, setOpenDrawer] = useState(false);
  const [nameInitial, setNameInitial] = useState([]);
  const [selectedName, setSelectedName] = useState([]);
  const [selectedForm, setSelectedForm] = useState([]);
  const [cardRemain, setCardRemain] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const cardPerPage = 8;
  const [notModified, setNotModified] = useState(true);
  const [load, setLoad] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [snackbarKey, setSnackbarKey] = useState(0);

  const currentSession = useSelector((state) => state.sessions.currentSession);
  const currentUser = useSelector((state) => state[currentSession].user);

  const noc_data = useSelector((state) => state.nocData.nocData);
  const emptyCountyFlag = useSelector((state) => state.nocData.emptyCountyFlag);

  const tabletSize = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const largePC = useMediaQuery((theme) => theme.breakpoints.up("xl"));
  const lg = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const mobileSize = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [selectedPreName, setSelectedPreName] = useState("");

  const [cardDisabled, setCardDisabled] = useState({});
  const [freeCounty, setFreeCounty] = useState(0);
  const loading = true;
  const [lockedNames, setLockedNames] = useState([]);
  const [cardText, setCardText] = useState("");
  const [isScrollingDown, setIsScrollingDown] = useState(false);

  const [enlargeFormInput, setEnlargeFormInput] = useState({
    general: "Alterations and repairs",
    interest: "Fee simple",
    form: "",
    name: "",
    added_name: "",
    added_email: "",
    locked: false,
  });
  const [countiesData, setCountiesData] = useState([]);
  const [filteredCountiesData, setFilteredCountiesData] = useState([
    countiesData,
  ]);

  const [waitingCountiesUnlock, setWaitingCountiesUnlock] = useState(false);
  const [isCountyUnlocked, setIsCountyUnlocked] = useState(null);
  const [waitingText, setWaitingText] = useState("");
  const [waitingColor, setWaitingColor] = useState("");

  //! Mobile /////////////////////////////////////////
  const [visitEnabled, setVisitEnabled] = useState(true);
  const [favoritEnabled, setFavoritEnabled] = useState(false);
  const [addEnabled, setAddEnabled] = useState(false);
  const [rowIndex, setRowIndex] = useState(0);
  const [availableIndex, setAvailableIndex] = useState(0);
  const [savedIndex, setSavedIndex] = useState(0);
  const [rowData, setRowData] = useState(null);
  const [isFadingOut, setIsFadingOut] = useState(false);
  const [showPersonInfo, setShowPersonInfo] = useState(false);
  const [personInfo, setPersonInfo] = useState("");
  const [availableCounties, setAvailableCounties] = useState([]);
  const [savedCounties, setSavedCounties] = useState([]);
  const [availableData, setAvailableData] = useState(null);
  const [savedData, setSavedData] = useState(null);
  const [shouldSubmit, setShouldSubmit] = useState(false);
  const [defaultOpen, setDefaultOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [defaultValues, setDefaultValues] = useState({
    general: "Alterations and repairs",
    interest: "Fee simple",
  });
  const [disableAdd, setDisableAdd] = useState(false);
  const [disableRemove, setDisableRemove] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);

  useEffect(() => {
    if (emptyCountyFlag) {
      setSnackOpen(true);
    }
  }, []);

  useEffect(() => {
    if (showPersonInfo) {
      // First timeout to start the fade-out effect after 4500ms
      const timeout = setTimeout(() => {
        setIsFadingOut(true); // Start fading out

        // Second timeout to hide the alert and reset isFadingOut after the fade-out duration
        const fadeOutTimeout = setTimeout(() => {
          setShowPersonInfo(false); // Hide alert after fade-out completes
          setIsFadingOut(false); // Reset fading state
        }, 1000); // This duration should match the CSS fade-out duration

        return () => clearTimeout(fadeOutTimeout);
      }, 3500); // Show the alert for 5 seconds minus fade duration

      return () => clearTimeout(timeout);
    }
  }, [showPersonInfo]);

  const snackHandleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
  };

  const getPersonInfo = (county) => {
    for (let i = 0; i < currentUser.user.selectedCountiesNOC.length; i++) {
      const selectedCounty = currentUser.user.selectedCountiesNOC[i];
      if (county.name === selectedCounty.name) {
        if (currentUser.subuser) {
          if (
            selectedCounty.added_email !== currentUser.subuser.email &&
            selectedCounty.added_email !== currentUser.user.email
          ) {
            return `The selection is made by ${selectedCounty.added_name}.`;
          } else if (selectedCounty.added_email === currentUser.subuser.email) {
            return "The selection is made by you.";
          } else {
            return "The selection is made by your administrator.";
          }
        } else {
          if (selectedCounty.added_email !== currentUser.user.email) {
            return `The selection is made by ${selectedCounty.added_name}.`;
          } else {
            return "The selection is made by you.";
          }
        }
      }
    }
  };

  const separateCounties = () => {
    // Filtering counties that are not in selectedCountiesNOC
    const filtered = filteredCountiesData
      .filter(
        (county) =>
          !currentUser?.user?.selectedCountiesNOC.some(
            (item) => item.name === county.name
          )
      )
      .sort((a, b) => a.name.localeCompare(b.name)); // Sorting alphabetically

    setAvailableCounties(filtered);
    setAvailableData(filtered[0]);

    // Create a copy of the array before sorting to avoid mutating the original Redux state
    const sortedSavedCounties = [
      ...currentUser?.user?.selectedCountiesNOC,
    ].sort((a, b) => {
      if (a.locked !== b.locked) {
        return a.locked ? -1 : 1;
      }
      return a.name.localeCompare(b.name);
    });

    setSavedCounties(sortedSavedCounties);
    setSavedData(sortedSavedCounties[0]);
  };

  useEffect(() => {
    // Function to filter out the counties based on the selected counties
    separateCounties();
  }, [filteredCountiesData, currentUser?.user?.selectedCountiesNOC]);

  const handlePersonInfoClick = (county) => {
    const personInfoData = getPersonInfo(county);
    setPersonInfo(personInfoData);
    setShowPersonInfo(true);
  };

  useEffect(() => {
    setVisitEnabled(true);
  }, []);

  useEffect(() => {
    if (addEnabled && !tabletSize) {
      setAddEnabled(false);
    }
  }, [addEnabled, tabletSize]);

  // ? SWIPES /////////////////////////
  const handleSwipe = (index) => {
    setRowIndex(index);
    setRowData(filteredCountiesData[index]);
    // setCopySelected(filteredCountiesData[index]);
  };

  const handleAvailableSwipe = (index) => {
    setAvailableIndex(index);
    setAvailableData(availableCounties[index]);
    setDefaultOpen(false);
  };

  const handleSavedSwipe = (index) => {
    const user_email = currentUser?.subuser
      ? currentUser.subuser.email
      : currentUser?.user?.email;

    const needDisable =
      savedCounties[index].locked ||
      (currentUser?.subuser && // Check if it's a subuser
        savedCounties[index].added_email && // Check if there is an added email
        savedCounties[index].added_email !== currentUser?.subuser?.email) || // Check if emails match
      (!searchValue && savedCounties[index].locked); // No search value and county is locked

    setDisableRemove(needDisable);

    setSavedIndex(index);
    setSavedData(savedCounties[index]);
  };
  //? ///////////////////////////////////////////

  const handleVisitClick = () => {
    if (visitEnabled) {
      return;
    }
    separateCounties();
    const inputElement = document.getElementById("searchInput");
    if (inputElement) {
      inputElement.value = ""; // Clear the search input value
    }
    setVisitEnabled(!visitEnabled);
    if (!visitEnabled) {
      setFavoritEnabled(false);
    }
    setDisableAdd(false);
    setDisableRemove(false);
  };

  const handleFavoritClick = () => {
    if (favoritEnabled) {
      return;
    }
    setDefaultOpen(false);
    setFavoritEnabled(!favoritEnabled);
    setSavedIndex(0);
    const inputElement = document.getElementById("searchInput");
    if (inputElement) {
      inputElement.value = ""; // Clear the search input value
    }
    if (!favoritEnabled) {
      setVisitEnabled(false);
    }
  };
  const handleSaveCounties = async () => {
    setLoad(true);
    setDefaultOpen(false);
    setWaitingText("");
    setWaitingColor("");
    setIsCountyUnlocked(null);
    setWaitingCountiesUnlock(false);

    const resp = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/nocApi/check-unlocked-select-counties`,
      { sessionName: currentSession },
      { withCredentials: true }
    );

    if (resp?.data?.success === false || resp?.data?.is_locked === true) {
      setWaitingCountiesUnlock(true);
      setIsCountyUnlocked(false);
      setWaitingText(resp?.data?.message);
      setNotModified(false);
      setLoad(false);
      setWaitingColor("error");
      return;
    }

    const sortedSavedCounties = [
      ...currentUser?.user?.selectedCountiesNOC,
    ].sort((a, b) => {
      if (a.locked !== b.locked) {
        return a.locked ? -1 : 1;
      }
      return a.name.localeCompare(b.name);
    });

    let updatedCounties = visitEnabled
      ? [...savedCounties, { ...availableData }] // Ensure deep copy of availableData if it's an object
      : sortedSavedCounties.filter((item) => item?.name !== savedData?.name); // Filter creates a new array without the specified county

    setSelectedForm((prevState) => {
      // Create a new array based on the current state
      let newForm = [...prevState];

      // Update newForm based on updatedCounties
      // First, remove all items that are not in updatedCounties
      newForm = newForm.filter((formItem) =>
        updatedCounties.some((county) => county.name === formItem.name)
      );

      // Then, add or update counties from updatedCounties
      updatedCounties.forEach((county) => {
        const index = newForm.findIndex((item) => item.name === county.name);

        const countyData = {
          ...county,
          general: defaultValues.general,
          interest: defaultValues.interest,
          added_name: `${currentUser?.user.personal[0].firstName[0].toUpperCase()}.${
            currentUser?.user.personal[0].lastName
          }`,
          added_email: currentUser?.subuser
            ? currentUser?.subuser?.email
            : currentUser?.user.email,
          locked: false,
        };

        if (index !== -1) {
          // Update existing item
          newForm[index] = countyData;
        } else {
          // Add new item
          newForm.push(countyData);
        }
      });

      return newForm;
    });

    setShouldSubmit(true);
  };
  // ! ///////////////////////////////////////////////

  useEffect(() => {
    let lastScrollTop = 0; // Keep track of the last scroll position

    const onScroll = () => {
      let currentScrollTop =
        window.scrollY || document.documentElement.scrollTop;

      // Check if the user is scrolling down
      if (currentScrollTop > lastScrollTop) {
        setIsScrollingDown(true);
      } else if (currentScrollTop === 0) {
        // If the scroller is at the top, set isScrollingDown to false
        setIsScrollingDown(false);
      }
      // Update lastScrollTop with the current scroll position
      lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop; // For Mobile or negative scrolling
    };

    // Add scroll event listener
    window.addEventListener("scroll", onScroll, { passive: true });

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("scroll", onScroll);
    };
  }, []); // Empty dependency array means this effect only runs once after the initial render

  useEffect(() => {
    if (waitingCountiesUnlock === true) {
      if (currentUser?.user?.locked_forms === false) {
        setWaitingText(
          "The user has completed the modifications. You are now able to make changes"
        );
        setWaitingColor("#0E870F");
      }
    }
  }, [currentUser]);

  const handleSubmit = async () => {
    try {
      setLoad(true);
      setWaitingText("");
      setWaitingColor("");
      setIsCountyUnlocked(null);
      setWaitingCountiesUnlock(false);

      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/selectnoc`,
        { selectedForm, sessionName: currentSession },
        { withCredentials: true }
      );

      if (resp?.data?.success === false && resp?.data?.is_locked === true) {
        setWaitingCountiesUnlock(true);
        setIsCountyUnlocked(false);
        setWaitingText(resp?.data?.message);
        setNotModified(false);
        setLoad(false);
        setWaitingColor("error");
        return;
      }

      /////////////////////////
      setCardText("Your Selected Counties Have Been Saved Successfully!");
      setNotModified(true);
      setLoad(false);
      const inputElement = document.getElementById("searchInput");
      if (inputElement) {
        inputElement.value = "";
      }
      setCurrentPage(1);
    } catch (err) {
      window.location.reload();
      setLoad(false);
    }
  };

  // Effect to handle submission
  useEffect(() => {
    const performSubmission = async () => {
      if (shouldSubmit) {
        await handleSubmit();
        setSavedIndex(0);
        setAvailableIndex(0);
        setLoad(false);
        setShouldSubmit(false); // Reset submit flag after submission
      }
    };

    performSubmission();
  }, [shouldSubmit]);

  useEffect(() => {
    let names = [];
    let lockedNames = [];

    if (currentUser.user.expired === true) {
      navigate("/subscription");
    } else {
      try {
        // if (
        //   currentUser.user.role === "admin" ||
        //   currentUser.user.role === "tester" ||
        //   currentUser?.user?.admin === true
        // ) {
        //   setCardRemain(67);
        //   setFreeCounty(67);
        // } else {
        setCardRemain(
          parseInt(currentUser.tier.county) +
            parseInt(currentUser?.user.analytics?.additions.numCounties)
        );
        setFreeCounty(
          parseInt(currentUser.tier.county) +
            parseInt(currentUser?.user.analytics?.additions.numCounties)
        );
        // }

        setSelectedForm(currentUser.user.selectedCountiesNOC);
        currentUser.user.selectedCountiesNOC.forEach((item) => {
          names.push(item.name);
          if (item.locked) {
            lockedNames.push(item.name);
          }
        });

        setNameInitial(names);
        setSelectedName(names);
        setLockedNames(lockedNames);
      } catch (err) {
        if (err.code === "ERR_NETWORK") {
          document.cookie =
            "NocCreator=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
        }
        window.location.reload();
        setPageLoading(false);
      }

      const { displays, user } = currentUser;

      // Assuming displays and user are defined
      if (displays && user) {
        // Assuming both countyCards and selectedCountiesNOC are arrays
        const { countyCards } = displays;

        const sortedCounties = [...countyCards].sort((a, b) => {
          // Find the index of the current county name in selectedCountiesNOC
          const indexA = user.selectedCountiesNOC.findIndex(
            (county) => county.name === a.name
          );
          const indexB = user.selectedCountiesNOC.findIndex(
            (county) => county.name === b.name
          );

          // If both counties are in selectedCountiesNOC, sort by locked status
          if (indexA !== -1 && indexB !== -1) {
            return user.selectedCountiesNOC[indexA].locked
              ? -1
              : user.selectedCountiesNOC[indexB].locked
              ? 1
              : 0;
          }

          // If only one of the counties is in selectedCountiesNOC, prioritize it
          if (indexA !== -1) {
            return -1;
          }

          if (indexB !== -1) {
            return 1;
          }

          // If neither is in selectedCountiesNOC, maintain the current order
          return 0;
        });

        setCountiesData(sortedCounties);
        setFilteredCountiesData(sortedCounties);
      }

      setPageLoading(false);
    }
  }, [currentUser, noc_data]);

  useEffect(() => {
    if (cardText !== "") {
      const timer = setTimeout(() => setCardText(""), 5000);
      if (emptyCountyFlag) {
        dispatch(disableEmptyCountyFlag());
        navigate(emptyCountyFlag);
      }
      return () => clearTimeout(timer);
    }
    const isChanged =
      JSON.stringify(selectedName?.sort()) !==
      JSON.stringify(nameInitial?.sort());
    if (isChanged) {
      setNotModified(false);
    } else {
      setNotModified(true);
    }
  }, [nameInitial, selectedName, cardRemain, cardText]);

  // resp.data.data.tier.freeCounty

  const handleSearch = (event) => {
    setCurrentPage(1);
    let value = event.target.value.toLowerCase();
    let result = [];
    result = countiesData.filter((data) => {
      return data.name.toLowerCase().search(value) !== -1;
    });
    setFilteredCountiesData(result);
  };

  const handleSelectCountySearch = (event) => {
    let value = event.target.value.toLowerCase();
    let result = [];

    if (visitEnabled) {
      setAvailableIndex(0);
      const filterCounties = () => {
        // Create a copy of filteredCountiesData to prevent modifying the original data
        const sortedCounties = [...filteredCountiesData];
        return sortedCounties.filter(
          (county) =>
            !currentUser?.user?.selectedCountiesNOC.some(
              (item) => item.name === county.name
            )
        );
      };

      // filterCounties is a function, you need to call it to execute
      result = filterCounties().filter((data) => {
        return data.name.toLowerCase().search(value) !== -1;
      });
      setAvailableCounties(result);
    } else {
      setSavedIndex(0);

      result = currentUser?.user?.selectedCountiesNOC.filter((data) => {
        return data.name.toLowerCase().search(value) !== -1;
      });
      const sortedSavedCounties = result.sort((a, b) => {
        if (a.locked !== b.locked) {
          return a.locked ? -1 : 1;
        }
        return a.name.localeCompare(b.name);
      });
      setSavedCounties(sortedSavedCounties);

      if (sortedSavedCounties?.length === 0) {
        setDisableRemove(true);
      } else {
        const user_email = currentUser?.subuser
          ? currentUser.subuser.email
          : currentUser?.user?.email;

        const needDisable =
          sortedSavedCounties[0].locked ||
          (sortedSavedCounties[0].added_email &&
            sortedSavedCounties[0].added_email !== user_email) ||
          (!searchValue && sortedSavedCounties[0].locked);

        if (needDisable) {
          setDisableRemove(true);
        } else {
          setDisableRemove(false);
        }
      }
    }
    setSearchValue(value);
  };

  useEffect(() => {
    if (searchValue && visitEnabled) {
      const newData = availableCounties[0];
      setAvailableData(newData);

      if (!newData) {
        setDisableAdd(true);
      } else {
        setDisableAdd(false);
      }
    } else {
      setDisableAdd(false);
    }

    if (searchValue && favoritEnabled) {
      const newData = savedCounties[0];
      setSavedData(newData);

      if (!newData) {
        setDisableRemove(true);
      } else {
        setDisableRemove(false);
      }
    }
  }, [searchValue, visitEnabled, favoritEnabled]);

  const getCurrentTasks = (tasks) => {
    const indexOfLastCard = currentPage * cardPerPage;
    const indexOfFirstCard = indexOfLastCard - cardPerPage;
    return tasks.slice(indexOfFirstCard, indexOfLastCard);
  };

  const selectCards = (countyName, form, nocForm) => {
    setSelectedName((prevState) => {
      let copyArray = [...prevState];
      const index = copyArray.findIndex((name) => name === countyName);
      if (index !== -1) {
        copyArray.splice(index, 1);
      } else {
        copyArray.push(countyName);
      }
      return copyArray;
    });

    setSelectedForm((prevState) => {
      let copyForm = [...prevState];
      const index = copyForm.findIndex((item) => item.name === countyName);
      if (index !== -1) {
        copyForm.splice(index, 1);
      } else {
        copyForm.push({
          ...enlargeFormInput,
          form: form,
          nocForm: nocForm,
          name: countyName,
          added_name: `${currentUser?.user.personal[0].firstName[0].toUpperCase()}.${
            currentUser?.user.personal[0].lastName
          }`,
          added_email: currentUser?.subuser
            ? currentUser?.subuser?.email
            : currentUser?.user.email,
          locked: false,
        });
      }
      return copyForm;
    });

    setCardRemain((prevState) => {
      const index = selectedName.findIndex((name) => name === countyName);
      return index !== -1 ? prevState + 1 : prevState - 1;
    });
  };

  const handleDisplayNOC = () => {
    return (
      <SelectCountyFormSelect
        setSelectedPreName={setSelectedPreName}
        selectedPreName={selectedPreName}
        cardDisabled={cardDisabled}
        setCardDisabled={setCardDisabled}
        noc_data={noc_data}
        formDrawerOpen={formDrawerOpen}
        setFormDrawerOpen={setFormDrawerOpen}
        isSmallScreen={isSmallScreen}
        setSelectedPreForm={setSelectedPreForm}
        selectedPreForm={selectedPreForm}
        setOpenDrawer={setOpenDrawer}
        openDrawer={openDrawer}
        cardText={cardText}
        lockedNames={lockedNames}
        freeCounty={freeCounty}
        currentUser={currentUser}
        filteredCountiesData={filteredCountiesData}
        getCurrentTasks={getCurrentTasks}
        type="welcome"
        selectedName={selectedName}
        selectCards={selectCards}
        cardRemain={cardRemain}
        loading={loading}
        totalCounty={countiesData.length}
        cardPerPage={cardPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        enlargeType="firstSelect"
        enlargeHeader="Add Default Fields"
        enlargeNote="It is not necessary to type in the same values over and over again. 
You can simply set these values as defaults.
Leave it blank if you are unsure."
        enlargeFormInput={enlargeFormInput}
        setEnlargeFormInput={setEnlargeFormInput}
        monthlyRestart={currentUser?.tier?.monthly_restart}
        setSelectedRow=""
        addressRow=""
        cardFlip=""
        // MOBILE
        visitEnabled={visitEnabled}
        handleSwipe={handleSwipe}
        rowData={rowData}
        favoritEnabled={favoritEnabled}
        handleFavoritClick={handleFavoritClick}
        handleVisitClick={handleVisitClick}
        addEnabled={addEnabled}
        handleSearch={handleSearch}
        isFadingOut={isFadingOut}
        showPersonInfo={showPersonInfo}
        personInfo={personInfo}
        setShowPersonInfo={setShowPersonInfo}
        handlePersonInfoClick={handlePersonInfoClick}
        availableCounties={availableCounties}
        savedCounties={savedCounties}
        rowIndex={rowIndex}
        setRowIndex={setRowIndex}
        availableIndex={availableIndex}
        savedIndex={savedIndex}
        handleAvailableSwipe={handleAvailableSwipe}
        handleSavedSwipe={handleSavedSwipe}
        handleSelectCountySearch={handleSelectCountySearch}
        setPersonInfo={setPersonInfo}
        availableData={availableData}
        savedData={savedData}
        handleSaveCounties={handleSaveCounties}
        load={load}
        setLoad={setLoad}
        defaultOpen={defaultOpen}
        setDefaultOpen={setDefaultOpen}
        defaultValues={defaultValues}
        setDefaultValues={setDefaultValues}
        disableAdd={disableAdd}
        disableRemove={disableRemove}
        waitingCountiesUnlock={waitingCountiesUnlock}
        waitingText={waitingText}
        waitingColor={waitingColor}
      />
    );
  };
  return (
    <>
      {pageLoading ? (
        <>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100vh",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CircularProgress color="warning" size="10rem" />
          </Box>
        </>
      ) : (
        <>
          <Box
            py={1}
            sx={{
              width: "100%",
              height: "calc(100vh - 175px)", // Ensure it takes up 100% of the viewport height
              maxWidth: tabletSize ? undefined : "1500px",
              mx: "auto",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {!tabletSize ? (
              <Card
                sx={{
                  py: 2,
                  position: "relative",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    flexGrow: 1,
                  }}
                >
                  {/* HEADER */}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      px: 2,
                      gap: 2,
                      mb: 1,
                    }}
                  >
                    <Box
                      sx={{ width: 150, flexShrink: 0, whiteSpace: "nowrap" }}
                    >
                      <Typography
                        sx={{ fontWeight: "bold" }}
                        color="primary"
                        textAlign="center"
                        variant="body2"
                      >
                        Counties Remaining
                      </Typography>
                      <Typography
                        textAlign="center"
                        variant="h6"
                        color="primary"
                      >
                        {freeCounty - selectedName.length < 0
                          ? "0"
                          : freeCounty - selectedName.length}{" "}
                        / {freeCounty}
                      </Typography>
                    </Box>
                    <Box sx={{ flexGrow: 1, flexShrink: 1, minWidth: 0 }}>
                      {/* Middle Box */}
                      <Typography
                        mb={0}
                        textAlign="center"
                        variant="h5"
                        color="primary"
                      >
                        Select Counties
                      </Typography>
                      <Typography
                        color="primary"
                        textAlign="center"
                        variant="subtitle2"
                      >
                        Select the counties you want to use. Once you have
                        selected a county it will be highlighted. Don't forget
                        to hit the Save button to confirm all your selected
                        counties.
                      </Typography>
                      <Typography
                        variant="body1"
                        color={waitingText ? waitingColor : "#4caf50"}
                        fontWeight="bold"
                        textAlign="center"
                        sx={
                          isSmallScreen
                            ? {
                                minHeight: "1.5rem",
                                paddingLeft: "3rem",
                                paddingRight: "3rem",
                                color: waitingText ? waitingColor : "#4caf50",
                              }
                            : {
                                minHeight: "1.5rem",
                                color: waitingText ? waitingColor : "#4caf50",
                              }
                        }
                      >
                        {waitingText || cardText}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          mt: 1,
                        }}
                      >
                        <LoadingButton
                          loading={load}
                          onClick={() => handleSubmit()}
                          disabled={notModified}
                          size="small"
                          sx={{
                            backgroundColor: "primary",
                            width: "15rem", // Consider controlling width here or ensure it's not affecting centering
                          }}
                          variant="contained"
                        >
                          Save
                        </LoadingButton>
                      </Box>
                    </Box>
                    <Box
                      sx={{ width: 200, flexShrink: 0, whiteSpace: "nowrap" }}
                    >
                      <TextField
                        id="searchInput"
                        onChange={(e) => {
                          handleSearch(e);
                        }}
                        fullWidth
                        placeholder="SEARCH"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                        variant="outlined"
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      px: 2,
                      flexGrow: 1,
                      overflowY: "auto", // Enable vertical scrolling if content overflows
                      maxHeight: "calc(100vh - 390px)", // Adjust height to account for padding and spacing
                    }}
                  >
                    <Grid
                      container
                      sx={{
                        overflowY: "auto",
                        maxHeight: "100%",
                      }}
                    >
                      {handleDisplayNOC()}
                    </Grid>
                  </Box>
                </Box>
                <Box
                  sx={{
                    position: "fixed",
                    bottom: 100,
                    pb: 1,
                    width: lg ? "calc(100% - 280px)" : "100%",
                    // maxWidth: "1500px",
                    px: "auto",
                    left: lg ? "280px" : "0px",
                    height: "40px", // Set height for pagination area
                    // bgcolor: "background.paper", // Background color to match the card
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",

                    zIndex: 1, // Ensure pagination is above other content
                  }}
                >
                  <Pagination
                    count={Math.ceil(filteredCountiesData.length / cardPerPage)}
                    page={currentPage}
                    onChange={(e, value) => setCurrentPage(value)}
                  />
                </Box>
              </Card>
            ) : (
              handleDisplayNOC()
            )}
            <Snackbar
              sx={{ zIndex: 9999999999999 }}
              open={snackOpen}
              autoHideDuration={5000}
              onClose={snackHandleClose}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <Alert
                onClose={snackHandleClose}
                severity="error"
                sx={{
                  width: "100%",
                  marginTop: "115px",
                  marginLeft: lg ? "280px" : "0px",
                  backgroundColor: "#d32f2f", // Slightly darker red
                  color: "#fff",
                  fontWeight: "bold",
                }}
                iconMapping={{
                  error: <ErrorIcon sx={{ color: "#fff" }} />,
                }}
              >
                In order to continue, you will need to select a county first.
              </Alert>
            </Snackbar>
          </Box>
        </>
      )}
    </>
  );
};

export default SelectCounties;
