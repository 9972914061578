import React from "react";
import { LoadingButton } from "@mui/lab";
import InputField from "../../inputComponents/InputField";
import SelectField from "../../inputComponents/SelectField";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import {
  Grid,
  Typography,
  FormControlLabel,
  TextField,
  Checkbox,
  Link,
  useMediaQuery,
  useTheme,
  Paper,
  Box,
  Stack,
  Tooltip,
} from "@mui/material";
import { useState } from "react";

const Additional = ({
  invite,
  additionalData,
  setInvitationRows,
  invitationRows,
  contractorList,
  promoCode,
  setPromoCode,
  promoCodeLoading,
  promoCodeName,
  setPromoCodeName,
  validatePromoCode,
  invalidPromo,
}) => {
  const theme = useTheme();
  const mobileSize = useMediaQuery(theme.breakpoints.down("sm"));
  const tabletSize = useMediaQuery(theme.breakpoints.down("md"));

  const handleInvitationNameChange = (index, value) => {
    setInvitationRows((prevState) => {
      const updatedRows = [...prevState];
      updatedRows[index][`name_${index + 1}`] = value;
      return updatedRows;
    });
  };

  const handleInvitationEmailChange = (index, value) => {
    setInvitationRows((prevState) => {
      const updatedRows = [...prevState];
      updatedRows[index][`email_${index + 1}`] = value;
      return updatedRows;
    });
  };

  return (
    <React.Fragment>
      {mobileSize ? (
        <></>
      ) : (
        <Typography
          color="primary"
          align="center"
          sx={
            mobileSize
              ? { fontWeight: "bold", mt: 3 }
              : { fontWeight: "bold", mt: 6 }
          }
          variant="subtitle1"
        >
          Share your thoughts in our quick survey and enjoy free access to our
          website for a limited time! {<br />} Your feedback matters!
          Participate in the survey to help us improve our services.
        </Typography>
      )}

      <Grid
        container
        spacing={mobileSize ? 1 : 2}
        sx={{
          mt: "1rem",
          minHeight: "55vh",
          overflowY: "auto",
          paddingRight: "2px",
          position: "relative",
        }}
      >
        <Grid item xs={12} md={6}>
          <InputField name="jobTitle" label="Job Title/Position" />
        </Grid>

        <Grid item xs={12} md={6}>
          <SelectField
            name="referralSource"
            label="Referral Source"
            menu={additionalData?.referrals}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <InputField
            name="companyName"
            contractorList={contractorList}
            label="Company Name"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectField
            name="companySize"
            label="Company Size"
            menu={additionalData?.company_size}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputField
            type="number"
            name="yearsInBusiness"
            label="Years in Business"
          />
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <InputField name="preferences" label="Interests or Preferences" />
        </Grid> */}
        {/* <Grid item xs={12} md={6}>
          <InputField name="sector" label="Industry or Sector" />
        </Grid> */}
        {/* <Grid item xs={12} md={6}>
          <InputField name="purpose" label="Purpose of Registration" />
        </Grid> */}
        <Grid item xs={12} md={6}>
          <SelectField
            multiple={false}
            name="revenue"
            label="Revenue Range"
            menu={additionalData?.revenue_range}
            sx={{ maxWidth: "100%" }} // Limit the maximum width
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectField
            multiple={false}
            name="avgNoc"
            label="Average Monthly NOC Recordings"
            menu={additionalData?.average_noc}
            sx={{ maxWidth: "100%" }} // Limit the maximum width
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectField
            multiple={true}
            numSelection={5}
            name="topProject"
            label="Top Company Project Types (Select up to five)"
            menu={additionalData?.top_projects}
            sx={{ maxWidth: "100%" }} // Limit the maximum width
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectField
            multiple={true}
            numSelection={3}
            name="formsYouLike"
            label="Forms You'd Like Us to Assist in Creating (Choose Your Top 3)"
            menu={additionalData?.forms_you_like}
            sx={{ maxWidth: "100%" }} // Limit the maximum width
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectField
            multiple={false}
            name="primaryGoals"
            label={`Primary Goals for Using NOC Creator (Select Your Top Priority)`}
            menu={additionalData?.primary_goals}
            sx={{ maxWidth: "100%" }} // Limit the maximum width
          />
        </Grid>
        <Grid item xs={12}>
          {promoCodeName && (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mt: mobileSize && 3,
                  mb: 1,
                  ml: mobileSize && "6px",
                  gap: 1,
                }}
              >
                <Typography variant="h6">
                  <span
                    style={{
                      fontSize: mobileSize ? "1.2rem" : "1.5rem",
                      color: "#F79009",
                    }}
                  >
                    "{promoCodeName}"
                  </span>{" "}
                  Activated
                </Typography>
                <LocalOfferIcon
                  color="warning"
                  sx={{
                    fontSize: mobileSize ? "1.5" : "2rem",
                    mr: mobileSize && "3rem",
                  }}
                />
              </Box>
            </>
          )}

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: 1,
              flexDirection: mobileSize ? "column" : "row",
            }}
          >
            <TextField
              disabled={Boolean(promoCodeLoading) || Boolean(promoCodeName)}
              name="promoCode"
              label="Promotional Code"
              value={promoCode}
              onChange={(e) => {
                const value = e.target.value.toUpperCase();
                setPromoCode(value);
              }}
            />
            <LoadingButton
              onClick={() => {
                if (promoCodeName) {
                  setPromoCode("");
                  setPromoCodeName("");
                } else {
                  validatePromoCode();
                }
              }}
              color={promoCodeName ? "error" : "warning"}
              disabled={
                Boolean(promoCode.length < 4) || Boolean(promoCode === "")
                  ? true
                  : false
              }
              loading={promoCodeLoading}
              variant="contained"
            >
              {promoCodeName !== "" ? "R E M O V E" : "S E L E C T"}
            </LoadingButton>
          </Box>
          <Typography
            sx={{ fontWeight: "bold" }}
            color="error"
            mt={0.4}
            align="center"
          >
            {invalidPromo}
          </Typography>
        </Grid>
        {/* <Grid item xs={12} md={12}>
          <Typography
            sx={{ fontWeight: "bold", textAlign: "center" }}
            color="text.secondary"
            variant="body2"
          >
            Would you like to invite some other contractors to use NOC Creator?
          </Typography>
          <Stack direction="row">
            <FormControlLabel
              sx={{ mt: "5px", ml: "auto" }}
              control={
                <Checkbox
                  checked={invite}
                  onChange={(e) => setInvite(e.target.checked)}
                />
              }
              label="Yes"
              labelPlacement="start"
              size="sm"
            />
            <FormControlLabel
              sx={{ mt: "5px", mr: "auto" }}
              control={
                <Checkbox
                  checked={!invite}
                  onChange={(e) => setInvite(!e.target.checked)}
                />
              }
              label="No"
              labelPlacement="start"
              size="sm"
            />
          </Stack>
        </Grid> */}

        <Box sx={invite ? { display: "block" } : { display: "none" }}>
          <Typography variant="h5" align="center" sx={{ mt: 2 }}>
            Invitations
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="body1" fontWeight="bold" align="center">
                Name
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1" fontWeight="bold" align="center">
                Email
              </Typography>
            </Grid>
          </Grid>
          <Paper
            elevation={5}
            style={
              mobileSize
                ? {
                    padding: "20px",
                    backgroundColor: "#e0e0e0", // Darker background color
                    marginLeft: "16px",
                  }
                : {
                    padding: "20px",
                    backgroundColor: "#e0e0e0", // Darker background color
                    marginLeft: "17px",
                  }
            }
          >
            <Grid container spacing={2}>
              {/* Map over invitation rows */}
              {invitationRows.map((row, index) => (
                <Grid container spacing={2} item xs={12} key={index}>
                  <Grid item xs={6}>
                    <TextField
                      label=""
                      disabled={Boolean(row[`disabled_${index + 1}`])}
                      fullWidth
                      variant="standard"
                      name={`name_${index + 1}`}
                      value={row[`name_${index + 1}`]}
                      onChange={(e) =>
                        handleInvitationNameChange(index, e.target.value)
                      }
                      error={Boolean(row[`name_${index + 1}_error`])}
                      sx={{
                        "& input": {
                          borderBottom: row[`name_${index + 1}_error`]
                            ? "2px solid #f00"
                            : "1px solid #ccc",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      type="email"
                      disabled={Boolean(row[`disabled_${index + 1}`])}
                      label=""
                      fullWidth
                      variant="standard"
                      name={`email_${index + 1}`}
                      value={row[`email_${index + 1}`]}
                      onChange={(e) =>
                        handleInvitationEmailChange(index, e.target.value)
                      }
                      error={Boolean(row[`email_${index + 1}_error`])}
                      sx={{
                        "& input": {
                          borderBottom: row[`email_${index + 1}_error`]
                            ? "2px solid #f00"
                            : "1px solid #ccc",
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Box>

        <Grid
          container
          spacing={2}
          sx={{
            paddingRight: "1rem",
            paddingLeft: "1rem",
            mt: "1rem",
          }}
        ></Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Additional;
