import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Divider,
  Drawer,
  Stack,
  Typography,
  useMediaQuery,
  Collapse,
} from "@mui/material";
import { items, adminItems, notaryItems } from "./side-nav-items";
import { SideNavItem } from "./side-nav-item";
import SideNavAd from "./side-nav-ad";

const SideNav = (props) => {
  const { open, onClose } = props;
  const pathname = window.location.pathname;
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const [hideTrial, setHideTrial] = useState(false);
  const [isTop, setIsTop] = useState(true);
  const [isBottom, setIsBottom] = useState(false);
  const [inMiddle, setInMiddle] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [openItems, setOpenItems] = useState({});
  const scrollContainerRef = useRef(null);
  const scrollDirection = useRef("down");
  const scrollIntervalRef = useRef(null);

  const handleScrollDown = () => {
    if (scrollContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        scrollContainerRef.current;
      const isAtBottom = scrollTop + clientHeight >= scrollHeight;
      const isAtTop = scrollTop === 0;

      if (isAtTop) {
        setIsTop(true);
        setIsBottom(false);
      } else if (isAtBottom) {
        setIsTop(false);
        setIsBottom(true);
      }

      if (isBottom) {
        scrollContainerRef.current.scrollTop -= 15;
      } else if (isTop) {
        scrollContainerRef.current.scrollTop += 15;
      }
    }
  };

  const handleMouseUp = () => {
    setIsClicked(false);
    if (scrollIntervalRef.current) {
      clearInterval(scrollIntervalRef.current);
      scrollIntervalRef.current = null;
    }
  };

  const handleMouseDown = () => {
    setIsClicked(true);
    if (!scrollIntervalRef.current) {
      handleScrollDown();
      scrollIntervalRef.current = setInterval(handleScrollDown, 100);
    }
  };

  useEffect(() => {
    const scrollableElement = scrollContainerRef.current;
    const lastScrollPos = localStorage.getItem("sideNavScrollPos") || 0;

    if (scrollableElement) {
      scrollableElement.scrollTop = lastScrollPos;

      const handleScroll = () => {
        localStorage.setItem("sideNavScrollPos", scrollableElement.scrollTop);
      };

      scrollableElement.addEventListener("scroll", handleScroll);

      return () => {
        scrollableElement.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  const handleToggle = (title) => {
    setOpenItems((prev) => ({
      ...prev,
      [title]: !prev[title],
    }));
  };

  const isActive = (item) => {
    if (item.path && pathname === item.path) return true;
    if (item.children) {
      return item.children.some((child) => pathname === child.path);
    }
    return false;
  };

  const renderNavItems = (navItems) => (
    <Stack
      component="ul"
      spacing={0.5}
      sx={{
        listStyle: "none",
        p: 0,
        m: 0,
      }}
    >
      {navItems.map((item) => {
        const active = isActive(item);
        const isParentActive = item.children && openItems[item.title];
        const isChildActive =
          item.children &&
          item.children.some((child) => pathname === child.path);

        let newTitle = null;
        if (
          item.title === "Manage Subscription" &&
          props?.user?.subuser?.role === "Power User"
        ) {
          newTitle = "Subscription Details";
        } else {
          newTitle = null;
        }

        return (
          <React.Fragment key={item.title}>
            <SideNavItem
              active={active || isChildActive}
              disabled={item.disabled}
              external={item.external}
              icon={item.icon}
              path={item.path}
              title={newTitle ? newTitle : item.title}
              hasChildren={!!item.children}
              onClick={() => {
                if (item.children) handleToggle(item.title);
              }}
            />
            {item.children && (
              <Collapse in={openItems[item.title] || isChildActive}>
                <Stack
                  component="ul"
                  spacing={0.5}
                  sx={{ listStyle: "none", pl: 2 }}
                >
                  {item.children.map((subItem) => {
                    const subActive = pathname === subItem.path;
                    return (
                      <SideNavItem
                        key={subItem.title}
                        active={subActive}
                        disabled={subItem.disabled}
                        external={subItem.external}
                        icon={subItem.icon}
                        path={subItem.path}
                        title={subItem.title}
                      />
                    );
                  })}
                </Stack>
              </Collapse>
            )}
          </React.Fragment>
        );
      })}
    </Stack>
  );

  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Box sx={{ p: 3 }}>
        <Box
          sx={{
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "transparent",
            cursor: "pointer",
            display: "flex",
            textDecoration: "none",
            borderRadius: "27px",
            // p: "12px",
          }}
        >
          <div>
            <img
              style={{
                width: "7rem",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "-3px",
                marginBottom: "-13px",
              }}
              alt="Permit Rockstar Logo"
              src="/img/website_img/PermitRockstar.png"
            />
          </div>
        </Box>
        {props?.user?.role === "notary" && (
          <Typography align="center" variant="h5" mt={1}>
            Notary
          </Typography>
        )}
      </Box>
      <Divider sx={{ borderColor: "neutral.700" }} />
      <Box
        component="nav"
        ref={scrollContainerRef}
        sx={{
          overflowY: "auto",
          flexGrow: 1,
          px: 2,
          py: 3,
          "&::-webkit-scrollbar": {
            display: "none",
          },
          scrollbarWidth: "none",
          msOverflowStyle: "none",
        }}
      >
        {props?.user?.role === "notary"
          ? renderNavItems(notaryItems)
          : renderNavItems(items)}
        {props?.user?.user?.role === "admin" && renderNavItems(adminItems)}
      </Box>
      {!hideTrial && (
        <SideNavAd
          setHideTrial={setHideTrial}
          handleScrollDown={handleScrollDown}
          scrollIntervalRef={scrollIntervalRef}
          handleMouseUp={handleMouseUp}
          handleMouseDown={handleMouseDown}
          isClicked={isClicked}
          isTop={isTop}
          isBottom={isBottom}
        />
      )}
      <Typography
        color="neutral.500"
        sx={{ textAlign: "center" }}
        variant="body2"
      >
        2023, All Rights Reserved
      </Typography>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: "neutral.800",
            color: "common.white",
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: "neutral.800",
          color: "common.white",
          width: 280,
        },
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

export default SideNav;
