import React from "react";
import SelectCountyForm from "./SelectCountyForm";
import MobileCreateSelectCountyForm from "./MobileCreateSelectCountyForm";
import { useMediaQuery } from "@mui/material";
import MobileSelectCountyForm from "./MobileSelectCountyForm";

const SelectCountyFormSelect = (props) => {
  const tabletSize = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const {
    visitEnabled,
    handleSwipe,
    rowIndex,
    rowData,
    favoritEnabled,
    handleFavoritClick,
    handleVisitClick,
    handleAddClick,
    addEnabled,
    setRowIndex,
    handleSearch,
    isFadingOut,
    showPersonInfo,
    setShowPersonInfo,
    handlePersonInfoClick,
    personInfo,
    availableCounties,
    savedCounties,
    availableIndex,
    savedIndex,
    handleAvailableSwipe,
    handleSavedSwipe,
    selectCountyEnabled,
    handleSelectCountySearch,
    setPersonInfo,
    availableData,
    savedData,
    handleSaveCounties,
    load,
    setLoad,
    defaultValues,
    setDefaultValues,
    waitingCountiesUnlock,
  } = props;

  return (
    <>
      {!tabletSize ? (
        <SelectCountyForm {...props} />
      ) : props?.type === "welcome" ? (
        <MobileSelectCountyForm
          {...props}
          visitEnabled={visitEnabled}
          handleSwipe={handleSwipe}
          rowData={rowData}
          favoritEnabled={favoritEnabled}
          handleFavoritClick={handleFavoritClick}
          handleVisitClick={handleVisitClick}
          addEnabled={addEnabled}
          handleAddClick={handleAddClick}
          handleSearch={handleSearch}
          isFadingOut={isFadingOut}
          showPersonInfo={showPersonInfo}
          setShowPersonInfo={setShowPersonInfo}
          handlePersonInfoClick={handlePersonInfoClick}
          personInfo={personInfo}
          availableCounties={availableCounties}
          savedCounties={savedCounties}
          availableIndex={availableIndex}
          savedIndex={savedIndex}
          handleAvailableSwipe={handleAvailableSwipe}
          handleSavedSwipe={handleSavedSwipe}
          selectCountyEnabled={selectCountyEnabled}
          handleSelectCountySearch={handleSelectCountySearch}
          setPersonInfo={setPersonInfo}
          availableData={availableData}
          savedData={savedData}
          handleSaveCounties={handleSaveCounties}
          load={load}
          setLoad={setLoad}
          defaultValues={defaultValues}
          setDefaultValues={setDefaultValues}
          waitingCountiesUnlock={waitingCountiesUnlock}
        />
      ) : (
        <MobileCreateSelectCountyForm
          {...props}
          visitEnabled={visitEnabled}
          handleSwipe={handleSwipe}
          rowIndex={rowIndex}
          rowData={rowData}
          favoritEnabled={favoritEnabled}
          handleFavoritClick={handleFavoritClick}
          handleVisitClick={handleVisitClick}
          addEnabled={addEnabled}
          handleAddClick={handleAddClick}
          setRowIndex={setRowIndex}
        />
      )}
    </>
  );
};

export default SelectCountyFormSelect;
