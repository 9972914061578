import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useFormik, Formik } from "formik";
import * as Yup from "yup";
import YupPassword from "yup-password";
import dayjs from "dayjs";

import axios from "axios";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Grid,
  Container,
  Card,
  Typography,
  Stack,
  TextField,
  useMediaQuery,
  useTheme,
  CircularProgress,
  IconButton,
  InputAdornment,
  CardHeader,
  CardContent,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { fetchNotaryData } from "../../store/actions/notaryActions";
import ChangeFA from "../ChangeFA";
import ChangeNotaryAvailability from "./dashboardComponents/ChangeNotaryAvailability";
YupPassword(Yup);

const NotarySettings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));

  const currentUser = useSelector((state) => state.notarySession.user);
  const currentSession = useSelector((state) => state.sessions.currentSession);
  const notaryJobs = useSelector((state) => state.notarySession.notaryJobs);

  const [errorTextPassword, setErrorTextPassword] = useState("");
  const [errorTextContractor, setErrorTextContractor] = useState("");
  const [errorTextNewsletter, setErrorTextNewsletter] = useState("");
  const [errorTextCancel, setErrorTextCancel] = useState("");
  const [errorTextRemove, setErrorTextRemove] = useState("");

  const [dataLoading, setDataLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [readyChange, setReadyChange] = useState(true);
  const [changeErrorText, setChangeErrorText] = useState("");

  const [imageList, setImageList] = useState([]);
  const [currentFA, setCurrentFA] = useState("");
  const [currentImg, setCurrentImg] = useState("");
  const [imgWidth, setImgWidth] = useState("");
  const [qrCodeSrc, setQRCodeSrc] = useState(null);

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const [saveLoading, setSaveLoading] = useState(false);
  const [availabilities, setAvailabilities] = useState([]);
  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const [notifyText, setNotifyText] = useState("");

  const currentUserRef = useRef(currentUser); // Use a ref to hold the currentUser

  const [selectedDays, setSelectedDays] = useState([]);
  const [selectedStartTime, setSelectedStartTime] = useState(null);
  const [selectedEndTime, setSelectedEndTime] = useState(null);
  const [prefillLoading, setPrefillLoading] = useState(false);

  useEffect(() => {
    // Update the ref whenever currentUser changes
    currentUserRef.current = currentUser;
  }, [currentUser]);

  let count = 0;

  useEffect(() => {
    if (errorTextPassword !== "") {
      const passwordTimer = setTimeout(() => setErrorTextPassword(""), 3000);
      return () => clearTimeout(passwordTimer);
    }

    if (changeErrorText !== "") {
      const changeAvTimer = setTimeout(() => setChangeErrorText(""), 5000);
      return () => clearTimeout(changeAvTimer);
    }

    if (notifyText !== "") {
      const notifyTextTimer = setTimeout(() => setNotifyText(""), 5000);
      return () => clearTimeout(notifyTextTimer);
    }

    if (errorTextNewsletter !== "") {
      const contractorTimer = setTimeout(
        () => setErrorTextNewsletter(""),
        3000
      );
      return () => clearTimeout(contractorTimer);
    }

    if (errorTextCancel !== "") {
      const cancelTimer = setTimeout(() => setErrorTextCancel(""), 3000);
      return () => clearTimeout(cancelTimer);
    }

    if (errorTextRemove !== "") {
      const removeTimer = setTimeout(() => setErrorTextRemove(""), 3000);
      return () => clearTimeout(removeTimer);
    }
  }, [
    errorTextPassword,
    errorTextContractor,
    errorTextCancel,
    errorTextRemove,
    errorTextNewsletter,
    changeErrorText,
  ]);

  useEffect(() => {
    const getQr = async () => {
      dispatch(fetchNotaryData(currentSession));
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/auth/2fa/generate_to_change_qr`,
          { currentSession: currentSession },
          {
            responseType: "blob",
            withCredentials: true,
          }
        );
        if (response.status === 200) {
          const qrCodeUrl = URL.createObjectURL(new Blob([response.data]));
          setQRCodeSrc(qrCodeUrl);
        }
      } catch (e) {}
    };

    if (count < 1) {
      getQr();
      count += 1;
    }
  }, []);

  useEffect(() => {
    setImageList([
      {
        alt: "email",
        src: "/img/logos/emailImage.png",
        // width: 120,
        width: 80,
      },
      {
        alt: "mobile",
        src: "/img/logos/phone.png",
        // width: 180,
        width: 140,
      },

      {
        alt: "authenticator",
        src: "/img/logos/authenticator.png",
        // width: 167,
        width: 127,
      },
    ]);

    if (currentUser?.auth_type === "totp") {
      setCurrentFA("totp");
      setCurrentImg("/img/logos/authenticator.png");
      setImgWidth(127);
    } else if (currentUser?.auth_type === "emailFa") {
      setCurrentFA("emailFa");
      setCurrentImg("/img/logos/emailImage.png");
      setImgWidth(80);
    } else if (currentUser?.auth_type === "smsFa") {
      setCurrentFA("smsFa");
      setImgWidth(140);
      setCurrentImg("/img/logos/phone.png");
    }

    try {
      if (!currentUser || !notaryJobs) {
        return;
      }
      if (!currentUser?.role === "notary") {
        navigate("/login");
      }

      if (
        currentUser?.on_site_registered === false ||
        currentUser?.bn_registered === false ||
        currentUser?.availabilities.length === 0
      ) {
        navigate("/notary/finalize");
        return;
      }

      const processedAvailability = currentUser?.availabilities.map((day) => {
        return {
          ...day,
          startTime: day.startTime ? day.startTime : null,
          endTime: day.endTime ? day.endTime : null,
        };
      });
      setAvailabilities(processedAvailability);
    } catch (err) {}
  }, [currentUser]);

  const passwordFormik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      newPasswordConfirmation: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string()
        .min(8, "password must contain 8 or more characters")
        .max(255)
        .minLowercase(1, "password must contain at least 1 lower case letter")
        .minUppercase(1, "password must contain at least 1 upper case letter")
        .minNumbers(1, "password must contain at least 1 number")
        .minSymbols(1, "password must contain at least 1 special character")
        .required("Password is required"),
      newPassword: Yup.string()
        .min(8, "password must contain 8 or more characters")
        .max(255)
        .minLowercase(1, "password must contain at least 1 lower case letter")
        .minUppercase(1, "password must contain at least 1 upper case letter")
        .minNumbers(1, "password must contain at least 1 number")
        .minSymbols(1, "password must contain at least 1 special character")
        .required("Password is required"),
      newPasswordConfirmation: Yup.string()

        .required("Password confirmation is required")
        .test("passwords-match", "Passwords must match", function (value) {
          return this.parent.newPassword === value;
        }),
    }),
    onSubmit: async (values, helpers) => {
      try {
        setLoading(true);
        try {
          const res = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/nocApi/password/changepwd`,
            {
              oldPassword: values.oldPassword,
              newPassword: values.newPassword,
              newPasswordConfirmation: values.newPasswordConfirmation,
              currentSession: currentSession,
            },
            { withCredentials: true }
          );
          if (res.data.success) {
            setErrorTextPassword(res.data.message);
            setLoading(false);
            values.oldPassword = "";
            values.newPassword = "";
            values.newPasswordConfirmation = "";
          }
        } catch (err) {
          setErrorTextPassword(err.response.data.message);
          values.oldPassword = "";
          values.newPassword = "";
          values.newPasswordConfirmation = "";
          setLoading(false);
        }
      } catch (err) {
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
        setLoading(false);
      }
    },
  });

  const daySchema = Yup.object()
    .shape({
      day: Yup.string(),
      available: Yup.boolean(),
      startTime: Yup.date()
        .nullable()
        .typeError("Start time must be a valid date and time"),
      endTime: Yup.date()
        .nullable()
        .typeError("End time must be a valid date and time"),
    })
    .test("time-conditional", "Invalid start or end time", function (obj) {
      if (obj.available) {
        const startTime = new Date(obj.startTime);
        const endTime = new Date(obj.endTime);
        const startMinutes = startTime.getHours() * 60 + startTime.getMinutes();
        const endMinutes = endTime.getHours() * 60 + endTime.getMinutes();

        if (!isValidTime(startTime)) {
          return this.createError({
            path: `${this.path}.startTime`,
            message: "Start time must be on the hour or half-hour",
          });
        }
        if (!isValidTime(endTime)) {
          return this.createError({
            path: `${this.path}.endTime`,
            message: "End time must be on the hour or half-hour",
          });
        }

        if (!obj.startTime || isNaN(startTime.getTime())) {
          return this.createError({
            path: `${this.path}.startTime`, // Updated path
            message: "Start time is required",
          });
        }
        if (!obj.endTime || isNaN(endTime.getTime())) {
          return this.createError({
            path: `${this.path}.endTime`, // Updated path
            message: "End time is required",
          });
        }
        if (
          endMinutes <= startMinutes &&
          !(endTime.getHours() === 0 && endTime.getMinutes() === 0)
        ) {
          // This error handling assumes the function is part of a context where `this.createError` is defined, like a custom validation method
          return this.createError({
            path: `${this.path}.endTime`, // Updated path
            message:
              "End time must be after start time unless it's exactly 00:00 marking the end of the day.",
          });
        }
        const hourDifference =
          ((endTime - startTime + 24 * 60 * 60 * 1000) %
            (24 * 60 * 60 * 1000)) /
          (1000 * 60 * 60);

        if (hourDifference < 1) {
          return this.createError({
            path: `${this.path}.endTime`, // Updated path
            message:
              "There must be at least 1 hour difference between start and end times",
          });
        }
      }
      return true;
    });

  const avaValidationSchema = Yup.object({
    availability: Yup.array()
      .of(daySchema)
      .test(
        "total-hours",
        "Minimum of 1 hours per week required",
        function (availabilityList) {
          let totalMinutes = 0;
          for (const day of availabilityList) {
            if (day.available && day.startTime && day.endTime) {
              // Ensure startTime and endTime are Date objects
              const startTime = new Date(day.startTime);
              const endTime = new Date(day.endTime);
              // Check if the dates are valid
              if (!isNaN(startTime.getTime()) && !isNaN(endTime.getTime())) {
                const diff = endTime.getTime() - startTime.getTime();
                totalMinutes += diff / (1000 * 60);
              }
            }
          }
          const totalHours = totalMinutes / 60;
          return totalHours >= 1;
        }
      ),
  });

  const defaultInitialValues = {
    availability: daysOfWeek.map((day) => ({
      day: day,
      available: false,
      startTime: null,
      endTime: null,
    })),
  };

  const avaFormik = useFormik({
    enableReinitialize: false,
    initialValues: defaultInitialValues,
    validationSchema: avaValidationSchema,
    onSubmit: async (values) => {
      const processedAvailability = values.availability.map((day) => {
        return {
          ...day,
          startTime: day.startTime ? formatTime(day.startTime) : null,
          endTime: day.endTime ? formatTime(day.endTime) : null,
        };
      });

      try {
        setSaveLoading(true);
        const resp = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/notary/save-availabilities`,
          { payload: processedAvailability, currentSession: currentSession },
          {
            withCredentials: true,
          }
        );
        if (resp.data.success) {
          setAvailabilities(processedAvailability);
          setReadyChange(true);
          setChangeErrorText(
            "The availabilities have been successfully saved."
          );
        }
        setSaveLoading(false);
      } catch (e) {
        setSaveLoading(false);
      }
    },
  });

  useEffect(() => {
    const updatedValues = daysOfWeek.map((day) => {
      const availabilityData = availabilities.find((a) => a.day === day);
      return {
        day: day,
        available: availabilityData ? availabilityData.available : false,
        startTime: availabilityData
          ? createTimeFromString(availabilityData.startTime)
          : null,
        endTime: availabilityData
          ? createTimeFromString(availabilityData.endTime)
          : null,
      };
    });

    avaFormik.setValues({ ...avaFormik.values, availability: updatedValues });
  }, [availabilities]);

  useEffect(() => {
    if (availabilities.length !== 0) {
      const timer = setTimeout(() => {
        setDataLoading(false);
      }, 3000);

      // Return a cleanup function that clears the timeout
      return () => {
        clearTimeout(timer);
      };
    }
  }, [availabilities]);

  function createTimeFromString(timeString) {
    if (!timeString) return null;

    const [hours, minutes] = timeString.split(":").map(Number);
    // Create a Day.js object from the current date, then set the hours and minutes
    return dayjs().hour(hours).minute(minutes);
  }

  function isValidTime(time) {
    if (!time || isNaN(time.getTime())) return false;
    const minutes = time.getMinutes();
    return minutes === 0 || minutes === 30;
  }

  function formatTime(time) {
    const date = new Date(time);
    let hours = date.getHours();
    let minutes = date.getMinutes();

    // Format hours and minutes to ensure two digits
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;

    return `${hours}:${minutes}`;
  }

  const handlePrefillTimes = () => {
    setPrefillLoading(true);
    const updatedAvailability = avaFormik.values.availability.map((day) => {
      if (selectedDays.includes(day.day)) {
        return {
          ...day,
          available: true,
          startTime: selectedStartTime, // Ensure these are in the correct format
          endTime: selectedEndTime,
        };
      } else {
        // For days not included in selectedDays, set available to false and clear times
        return {
          ...day,
          available: false,
          startTime: null,
          endTime: null,
        };
      }
    });

    avaFormik.setFieldValue("availability", updatedAvailability);
    // Reset the selected days and times after updating
    setSelectedDays([]);
    setSelectedStartTime(null);
    setSelectedEndTime(null);
    setPrefillLoading(false);
    setReadyChange(false);
  };

  return (
    <>
      {dataLoading ? (
        <>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100vh",
              justifyContent: "center",
              alignItems: "center", // Add this line to center vertically
              flexDirection: "column",
            }}
          >
            <CircularProgress color="warning" size="10rem" />
          </Box>
        </>
      ) : (
        <>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              py: 2,
              backgroundColor: "#EEF2F6",
            }}
          >
            <Container maxWidth="lg" py={3}>
              {/* Password CHANGE */}
              <Card
                py={3}
                px={3}
                mb={4}
                component={Grid}
                sx={
                  isMd
                    ? { width: "90vw", ml: "auto", mr: "auto" }
                    : { width: "50vw", ml: "auto", mr: "auto" }
                }
              >
                <Grid container justifyContent="center">
                  <Grid item sm={12}>
                    <Typography
                      sx={{ textAlign: "center", mb: 5 }}
                      variant="h5"
                    >
                      Change Password
                    </Typography>
                  </Grid>
                  <Grid item sm={12} width="100%">
                    <form noValidate onSubmit={passwordFormik.handleSubmit}>
                      <Stack spacing={3} mt={4}>
                        <TextField
                          error={
                            !!(
                              passwordFormik.touched.oldPassword &&
                              passwordFormik.errors.oldPassword
                            )
                          }
                          helperText={
                            passwordFormik.touched.oldPassword &&
                            passwordFormik.errors.oldPassword
                          }
                          onBlur={passwordFormik.handleBlur}
                          onChange={passwordFormik.handleChange}
                          fullWidth
                          label="Old Password*"
                          name="oldPassword"
                          type={showOldPassword ? "text" : "password"}
                          value={passwordFormik.values.oldPassword}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => {
                                    setShowOldPassword(!showOldPassword);
                                  }}
                                  onMouseDown={(e) => e.preventDefault()} // Prevent focus change on mouse down
                                  onMouseUp={(e) => e.preventDefault()} // Prevent focus change on mouse up
                                  edge="end"
                                >
                                  {showOldPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <TextField
                          error={
                            !!(
                              passwordFormik.touched.newPassword &&
                              passwordFormik.errors.newPassword
                            )
                          }
                          helperText={
                            passwordFormik.touched.newPassword &&
                            passwordFormik.errors.newPassword
                          }
                          onBlur={passwordFormik.handleBlur}
                          onChange={passwordFormik.handleChange}
                          fullWidth
                          label="New Password*"
                          name="newPassword"
                          type={showNewPassword ? "text" : "password"}
                          value={passwordFormik.values.newPassword}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => {
                                    setShowNewPassword(!showNewPassword);
                                  }}
                                  onMouseDown={(e) => e.preventDefault()} // Prevent focus change on mouse down
                                  onMouseUp={(e) => e.preventDefault()} // Prevent focus change on mouse up
                                  edge="end"
                                >
                                  {showNewPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <TextField
                          error={
                            !!(
                              passwordFormik.touched.newPasswordConfirmation &&
                              passwordFormik.errors.newPasswordConfirmation
                            )
                          }
                          helperText={
                            passwordFormik.touched.newPasswordConfirmation &&
                            passwordFormik.errors.newPasswordConfirmation
                          }
                          onBlur={passwordFormik.handleBlur}
                          onChange={passwordFormik.handleChange}
                          fullWidth
                          label="Confirm Password*"
                          name="newPasswordConfirmation"
                          type="password"
                          value={passwordFormik.values.newPasswordConfirmation}
                        />
                        {passwordFormik.errors.submit && (
                          <Typography
                            color="error"
                            sx={{ mt: 3 }}
                            variant="body2"
                          >
                            {passwordFormik.errors.submit}
                          </Typography>
                        )}
                        <Typography
                          variant="body1"
                          color="error"
                          fontWeight="bold"
                          textAlign="center"
                        >
                          {errorTextPassword}
                        </Typography>

                        <LoadingButton
                          loading={loading}
                          type="submit"
                          fullWidth
                          size="large"
                          sx={{
                            mt: 2,
                            backgroundColor: "primary",
                          }}
                          variant="contained"
                        >
                          Submit Changes
                        </LoadingButton>
                      </Stack>
                    </form>
                  </Grid>
                </Grid>
              </Card>

              {/* NOTARY AVAILABILITY */}
              <Box>
                <Card
                  py={3}
                  px={3}
                  mb={4}
                  component={Grid}
                  sx={
                    isMd
                      ? { width: "90vw", ml: "auto", mr: "auto" }
                      : { width: "50vw", ml: "auto", mr: "auto" }
                  }
                >
                  <CardHeader title="Change Remote Online Notary (RON) Session Availability" />
                  <CardContent>
                    <ChangeNotaryAvailability
                      formik={avaFormik}
                      availabilities={availabilities}
                      saveLoading={saveLoading}
                      settings={true}
                      readyChange={readyChange}
                      setReadyChange={setReadyChange}
                      changeErrorText={changeErrorText}
                      selectedDays={selectedDays}
                      setSelectedDays={setSelectedDays}
                      selectedStartTime={selectedStartTime}
                      setSelectedStartTime={setSelectedStartTime}
                      selectedEndTime={selectedEndTime}
                      setSelectedEndTime={setSelectedEndTime}
                      prefillLoading={prefillLoading}
                      handlePrefillTimes={handlePrefillTimes}
                    />
                  </CardContent>
                </Card>
              </Box>
              {/* CAHGNE 2FA */}
              <ChangeFA
                qrCodeSrc={qrCodeSrc}
                currentUser={currentUser}
                currentFA={currentFA}
                setCurrentFA={setCurrentFA}
                imageList={imageList}
                setImageList={setImageList}
                currentImg={currentImg}
                setCurrentImg={setCurrentImg}
                imgWidth={imgWidth}
                setImgWidth={setImgWidth}
              />
            </Container>
          </Box>
        </>
      )}
    </>
  );
};

export default NotarySettings;
