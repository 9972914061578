import InputField from "../../inputComponents/InputField";
import { useFormikContext } from "formik";
import { useEffect } from "react";
import ContractorCard from "../../permitComponents/ContractorCard";

import {
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  Box,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const ChangePlanContractor = (props) => {
  const handleNavigate = (direction) => {
    let newPage;
    if (direction === "forward") {
      newPage = props?.contractorIndex + 1;
    } else if (direction === "back") {
      newPage = props?.contractorIndex - 1;
    }

    props?.setContractorIndex(newPage);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <IconButton
            onClick={() => handleNavigate("back")}
            sx={{
              visibility:
                props?.contractorIndex === 0 ||
                props?.currentUser?.user?.contractors?.length <= 1
                  ? "hidden"
                  : "visible",
            }}
          >
            <ArrowBackIosNewIcon sx={{ fontSize: "60px", color: "#d3d3d3" }} />
          </IconButton>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <ContractorCard
            data={
              props?.currentUser?.user?.contractors?.[props?.contractorIndex]
            }
            mb={4}
            colorIndex={props?.contractorIndex}
            empty={props?.currentUser?.user?.contractors.length === 0}
            showLicenseInput={false}
            deleteLicenses={props?.deleteLicenses}
            currLicense={
              props?.currentUser?.user?.contractors[props?.contractorIndex]
                ?.licenseNumber
            }
          />
        </Box>
        <Box sx={{ display: "flex", flex: 1, alignItems: "center" }}>
          <IconButton
            onClick={() => handleNavigate("forward")}
            sx={{
              visibility:
                props?.currentUser?.user?.contractors?.length <= 0 ||
                props?.contractorIndex + 1 ===
                  props?.currentUser?.user?.contractors?.length
                  ? "hidden"
                  : "visible",
            }}
          >
            <ArrowForwardIosIcon sx={{ fontSize: "60px", color: "#d3d3d3" }} />
          </IconButton>
        </Box>
      </Box>
      {props?.type === "subscription" &&
      props?.currentUser?.user?.contractors?.length === 0 ? (
        <Typography align="center" variant="h5">
          0/0
        </Typography>
      ) : (
        <Typography align="center" variant="h5">
          {props?.contractorIndex +
            1 +
            "/" +
            props?.currentUser?.user?.contractors?.length}
        </Typography>
      )}
    </>
  );
};

export default ChangePlanContractor;
