import React, { useCallback, useState, useEffect } from "react";
import { Formik } from "formik";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import FormNavigation from "./FormNavigation";
import axios from "axios";
import {
  Step,
  StepLabel,
  Stepper,
  Typography,
  useMediaQuery,
  useTheme,
  Drawer,
  TextField,
  Box,
  Button,
  Grid,
  Tooltip,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

const MultiStepForm = ({
  setOptions,
  page,
  setPage,
  tiers,
  setSelectedName,
  isLoad,
  children,
  initialValues,
  onSubmit,
  isExpired,
  cardRemain,
  isSSOLogin,
  setErrorText,
  errorText,
  checked,
  setUserEmail,
  contractorList,
  setContractorList,
  setDuplicatedLicenses,
  setIsEmailError,
  setIsPhoneError,
  setUserInfoCopy,
  personalAddress,
  setTerms,
  terms,
  termsOnError,
  setTermsOnError,
  setOpen,
  open,
  setTermsOpen,
  selectedPlan,
  setSelectedPlan,
  mobileSize,
}) => {
  const theme = useTheme();
  const [stepLabel, setStepLabel] = useState("");
  const [stepNumber, setStepNumber] = useState(0);
  const [nextLoad, setNextLoad] = useState(false);
  const [inputValues, setInputValues] = useState(["", "", "", "", ""]);
  const [isPasting, setIsPasting] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const steps = React.Children.toArray(children);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [errorMsg, setErrorMsg] = useState("");
  const [snapshot, setSnapshot] = useState(initialValues);
  const [needScroll, setNeedScroll] = useState(false);

  const tooltipValues = {
    "Contractor Details":
      "Please be careful while entering this information and double check your entries. This is the contractor information that will be incorporated in all the Notices of Commencement (NOC) you will file with all counties. You will only be allowed to change this one time after you have registered. No other changes will be permitted",

    "Additional Information":
      "Share your thoughts in our quick survey and enjoy free access to our website for a limited time! Your feedback matters! Participate in the survey to help us improve our services.",
  };

  const step = steps[stepNumber];
  const totalSteps = steps.length;

  const isLastStep = stepNumber === totalSteps - 1;

  useEffect(() => {
    if (errorMsg !== "") {
      const timer = setTimeout(() => setErrorMsg(""), 5000);
      return () => clearTimeout(timer);
    }
  }, [errorMsg]);

  const handleInputChange = (index, value) => {
    if (!isPasting && value.length > 1) {
      value = value.slice(0, 1); // Allow only one character
    }
    const newInputValues = [...inputValues];
    newInputValues[index] = value;
    setInputValues(newInputValues);

    if (value && index < 4) {
      const nextInput = document.getElementById(`input-${index + 1}`);
      if (nextInput) {
        nextInput.focus();
      }
    } else if (!value && index > 0) {
      const prevInput = document.getElementById(`input-${index - 1}`);
      if (prevInput) {
        prevInput.focus();
      }
    }
  };

  const handlePaste = (event) => {
    const pasteData = event.clipboardData.getData("text");

    if (pasteData.length === 5) {
      const newInputValues = pasteData.split("");
      setInputValues(newInputValues);
      setIsPasting(true);
      setTimeout(() => {
        setIsPasting(false);
      }, 0);
      event.preventDefault(); // Prevent default paste behavior
    }
  };

  const handleVerifyEmail = async (values, actions) => {
    setIsLoading(true);

    if (
      inputValues[0] === "" ||
      inputValues[1] === "" ||
      inputValues[2] === "" ||
      inputValues[3] === "" ||
      inputValues[4] === ""
    ) {
      setErrorMsg("Please enter the verification code sent to your email");
      setIsLoading(false);
      return;
    }

    const emailAddress = values["email"];
    const code =
      inputValues[0] +
      inputValues[1] +
      inputValues[2] +
      inputValues[3] +
      inputValues[4];

    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/email/checkverify`,
        {
          code,
          emailAddress,
        },
        { withCredentials: true }
      );

      if (resp.data.success) {
        nextStep(values);
        setOpen(false);
        setIsLoading(false);
        setInputValues(["", "", "", "", ""]);
      } else {
        setErrorMsg(resp.data.message);
        setInputValues(["", "", "", "", ""]);
        setIsLoading(false);
      }
    } catch (e) {
      setErrorMsg(e.response.data.message);
      setIsLoading(false);
      setInputValues(["", "", "", "", ""]);
    }
  };

  const sendCode = async (emailValue) => {
    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/email/generateverify`,
        {
          userEmail: emailValue,
        },
        { withCredentials: true }
      );

      return {
        success: resp.data.success,
        message: resp.data.message,
        isEmailSent: resp.data.isEmailSent,
        isVerified: resp.data.isVerified,
      };
      // setErrorText(resp.data.message);
      // setVerifyLoading(false);
    } catch (e) {
      if (e.response && e.response.data) {
        // If error response contains data, display the error message
        return {
          success: false,
          message: e.response.data.message,
        };
        // setErrorText(e.response.data.message);
      } else {
        // Otherwise, handle the error gracefully without specific error message
        return { success: false, message: "Server is not working" };
      }
    }

    /// SEND EMAIL API WITH CODE ////
    /////////////////////////////////
  };

  const handleVerifyLicense = async (values, actions) => {
    contractorList[page]["licenseType"] = values["licenseType"];
    contractorList[page]["licenseNumber"] = values["licenseNumber"];
    contractorList[page]["contractorName"] = values["contractorName"];
    contractorList[page]["contractorAddress"] = values["contractorAddress"];
    contractorList[page]["contractorPhone"] = values["contractorPhone"];
    contractorList[page]["contractorFax"] = values["contractorFax"];
    contractorList[page]["contractorEmail"] = values["contractorEmail"];
    contractorList[page]["validLicense"] = contractorList[page]["validLicense"];
    const email = values["email"];

    const hasInvalid = contractorList.some(
      (contractor) => !contractor.validLicense
    );

    if (hasInvalid) {
      setErrorText(
        "Please modify the license number(s) as some contractor(s) have invalid ones."
      );
      return false;
    }
    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/auth/checklicensenumber`,
        {
          contractorList,
          email,
        }
      );

      if (resp.data.success === true) {
        setContractorList(resp.data.data.copy_contractors);
      }

      if (resp.data.data.exist_indexes.length > 0) {
        actions.setFieldError("licenseNumber", "");
        setPage(resp.data.data.exist_indexes[0]);
        const updatedContractor =
          contractorList[resp.data.data.exist_indexes[0]];

        if (updatedContractor) {
          actions.setFieldTouched("licenseType", true);
          actions.setFieldTouched("licenseNumber", true);
          actions.setFieldTouched("contractorName", true);
          actions.setFieldTouched("contractorAddress", true);
          actions.setFieldTouched("contractorPhone", true);
          actions.setFieldTouched("contractorFax", true);
          actions.setFieldTouched("contractorEmail", true);
          actions.setFieldValue("licenseType", updatedContractor.licenseType);
          actions.setFieldValue(
            "licenseNumber",
            updatedContractor.licenseNumber
          );
          actions.setFieldValue(
            "contractorName",
            updatedContractor.contractorName
          );
          actions.setFieldValue(
            "contractorAddress",
            updatedContractor.contractorAddress
          );
          actions.setFieldValue(
            "contractorPhone",
            updatedContractor.contractorPhone
          );
          actions.setFieldValue(
            "contractorFax",
            updatedContractor.contractorFax
          );
          actions.setFieldValue(
            "contractorEmail",
            updatedContractor.contractorEmail
          );
        }

        actions.setFieldError(
          "licenseNumber",
          "License number already exists."
        ); // Set error text
        actions.setFieldTouched("licenseNumber", true); // Mark the field as touched
        actions.setFieldValue("licenseNumber", ""); // Clear the value (optional, depends on your use case)

        return false;
      } else {
        return true;
      }
    } catch (e) {
      setIsLoading(false);
    }
  };

  const handleApi = async (actions, values) => {
    setNextLoad(true);
    let emailAddress = values["email"];
    const phone = values["phone"];
    try {
      // CHECK EMAIL FOR DUPLICATIONS //
      const emailResp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/email/verifyEmail`,
        {
          emailAddress,
          phone,
        },
        { withCredentials: true }
      );

      if (emailResp.data.success === true) {
        if (
          emailResp.data.data.userExist === true ||
          emailResp.data.data.phoneExist === true
        ) {
          const errors = {};
          const touched = {};

          if (emailResp.data.data.userExist === true) {
            setIsEmailError(true);
            errors.email = "Email already exists.";
            touched.email = true;
          }

          if (emailResp.data.data.phoneExist === true) {
            setIsPhoneError(true);
            errors.phone = "Phone number already exists.";
            touched.phone = true;
          }

          actions.setErrors(errors);
          actions.setTouched(touched);

          // Clear values only for the fields that have issues
          actions.setValues({
            ...values,
            email: errors.email ? "" : values.email,
            phone: errors.phone ? "" : values.phone,
          });

          setNextLoad(false);
        } else {
          setIsEmailError(false);
          setIsPhoneError(false);

          const sendCodeValue = await sendCode(emailAddress);
          if (sendCodeValue.success === false) {
            setNextLoad(false);
          } else {
            if (sendCodeValue.isVerified === true) {
              actions.setTouched({});
              setUserInfoCopy(values);
              nextStep(values);
              setNextLoad(false);
            } else {
              setOpen(true);
              setNextLoad(false);
            }
          }
        }
      }
      /////////////////////////////////
    } catch (e) {
      setErrorText(e?.response.data.message);
      setNextLoad(false);
    }
  };

  const nextStep = (values) => {
    setSnapshot(values);
    setStepNumber(stepNumber + 1);
    setOptions([]);
  };

  const previousStep = (values) => {
    setSnapshot(values);
    setStepNumber(stepNumber - 1);
    setOptions([]);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // For smooth scrolling
    });
  };

  useEffect(() => {
    if (needScroll === true) {
      scrollToTop();
      setNeedScroll(false);
    }
  }, [needScroll]);

  const handleSubmit = async (values, actions) => {
    if (step.props.onSubmit) {
      await step.props.onSubmit;
    }

    if (isLastStep) {
      return onSubmit(values, actions);
    } else if (stepNumber === 0) {
      handleApi(actions, values, values.emailVerify);
    } else if (stepNumber === 1) {
      actions.setTouched({});
      nextStep(values);
      setNextLoad(false);
      // checkLicense(values, actions);
    } else {
      actions.setTouched({});
      nextStep(values);
    }
    if (!isLastStep) {
      setNeedScroll(true);
    }
  };

  // Update the `stepLabel` state outside the map function
  // and use `stepNumber` to get the current step's label
  const updateStepLabel = useCallback(
    (stepIndex) => {
      if (stepIndex >= 0 && stepIndex < steps.length) {
        setStepLabel(steps[stepIndex].props.stepName);
      }
    },
    [steps]
  );

  useEffect(() => {
    updateStepLabel(stepNumber);
  }, [stepNumber]);

  return (
    <div>
      <Formik
        initialValues={snapshot}
        onSubmit={handleSubmit}
        validationSchema={step.props.validationSchema}
        // enableReinitialize={true}
      >
        {(formik) => (
          <>
            <form onSubmit={formik.handleSubmit}>
              {isSmallScreen ? (
                <Stepper activeStep={stepNumber}>
                  {steps.map((currentStep, id) => {
                    return (
                      <Step key={id}>
                        <StepLabel></StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              ) : (
                <Stepper activeStep={stepNumber}>
                  {steps.map((currentStep, id) => {
                    const label = currentStep.props.stepName;
                    return (
                      <Step key={id}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              )}
              {isSmallScreen && (
                <Box
                  sx={{
                    mt: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    color="primary"
                    fontWeight="bold"
                    align="center"
                    variant="body1"
                    mt={1}
                    mb={0}
                    pb={0}
                    sx={{ position: "relative" }}
                  >
                    {stepLabel}
                  </Typography>
                  {tooltipValues?.[stepLabel] && (
                    <Tooltip
                      sx={{ mt: "5px", ml: "3px" }}
                      title={tooltipValues?.[stepLabel]}
                    >
                      <HelpOutlineIcon color="success" />
                    </Tooltip>
                  )}
                </Box>
              )}
              {step}
              <FormNavigation
                isLoad={isLoad}
                nextLoad={nextLoad}
                emailError={formik.errors}
                emailValue={formik.values.email}
                setErrorText={setErrorText}
                errorText={errorText}
                isSSOLogin={isSSOLogin}
                stepNumber={stepNumber}
                isExpired={isExpired}
                cardRemain={cardRemain}
                isLastStep={isLastStep}
                hasPrevious={stepNumber > 0}
                onBackClick={() => {
                  formik.setErrors({});
                  previousStep(formik.values);
                  updateStepLabel(stepNumber - 1);
                  stepNumber === 3 && setSelectedPlan();
                }}
                setTerms={setTerms}
                terms={terms}
                termsOnError={termsOnError}
                setTermsOnError={setTermsOnError}
                setOpen={setOpen}
                setTermsOpen={setTermsOpen}
                selectedPlan={selectedPlan}
                mobileSize={mobileSize}
              />
            </form>
            <Drawer
              anchor="left"
              open={open}
              onClose={() => {
                setOpen(false);
              }}
            >
              <Box display="flex" justifyContent="center">
                <img
                  style={{
                    width: "10rem",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: "2rem",
                  }}
                  alt="Permit Rockstar Logo"
                  src="/img/website_img/PermitRockstar.png"
                />
              </Box>
              <Box p={isSmallScreen ? 1 : 10} textAlign="center">
                <Typography variant="h4" gutterBottom>
                  Verify Your Email
                </Typography>
                <Typography
                  sx={{ mb: 2 }}
                  variant="body1"
                  color="textSecondary"
                  fontWeight="bold"
                  gutterBottom
                >
                  Please enter the verification code sent to your email.
                </Typography>
                <Grid container spacing={2}>
                  {inputValues.map((value, index) => (
                    <Grid item key={index} sx={{ mx: "auto" }}>
                      <TextField
                        id={`input-${index}`}
                        value={value}
                        variant="outlined"
                        onChange={(e) =>
                          handleInputChange(index, e.target.value)
                        }
                        onPaste={index === 0 ? handlePaste : undefined}
                        inputProps={{
                          maxLength: 1,
                          style: { fontSize: "28px", fontWeight: "bold" },
                        }}
                        sx={{ width: "48px", textAlign: "center" }}
                      />
                    </Grid>
                  ))}
                </Grid>
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: 1 }}
                  mt={3}
                >
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    textAlign="center"
                    color="#CDAF0B"
                    mb={2}
                    sx={{ minHeight: "1.5rem" }}
                  >
                    {errorMsg}
                  </Typography>
                  <LoadingButton
                    sx={{ minWidth: "9rem" }}
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      handleVerifyEmail(formik.values, formik.actions)
                    }
                    loading={isLoading}
                  >
                    Verify Email
                  </LoadingButton>
                  <Button
                    sx={{ minWidth: "9rem" }}
                    variant="contained"
                    color="error"
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Drawer>
          </>
        )}
      </Formik>
    </div>
  );
};

export default MultiStepForm;
export const FormStep = ({ stepName = "", children }) => children;
