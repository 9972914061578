import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";

import {
  Box,
  Stack,
  Link,
  Typography,
  Grid,
  useMediaQuery,
  useTheme,
  CircularProgress,
} from "@mui/material";

const EmailFaAuth = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const mobileSize = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedImage, setSelectedImage] = useState(null);
  const [pageLoading, setPageLoading] = useState(true);
  const [blockedText, setBlockedText] = useState("");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tokenFromQuery = queryParams.get("token");
  const rememberFromQuery = queryParams.get("remember");
  const [resendDisabled, setResendDisabled] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  let count = 0;

  useEffect(() => {
    if (emailVerified) {
      sessionStorage.removeItem("__nocCreator__r_rid");
      sessionStorage.removeItem("exp");
      const interval = setInterval(() => {
        navigate("/");
      }, 120000);

      return () => clearInterval(interval);
    }
  }, [emailVerified]);

  useEffect(() => {
    // Helper function to update state based on localStorage value
    const updateEmailVerificationStatus = () => {
      // Assuming 'emailVerified' in localStorage is a string "true" or "false"
      const isEmailVerified = localStorage.getItem("emailVerified") === "true";
      setEmailVerified(isEmailVerified);
    };

    // Initialize state on mount
    updateEmailVerificationStatus();

    // Event handler for storage events
    const handleStorageChange = (event) => {
      if (event.key === "emailVerified") {
        updateEmailVerificationStatus();
      }
    };

    const cleanup = () => {
      if (localStorage.getItem("emailVerified")) {
        localStorage.removeItem("emailVerified");
      }
    };

    window.addEventListener("beforeunload", cleanup);

    // Register the event listener for storage changes
    window.addEventListener("storage", handleStorageChange);

    // Cleanup function to remove the event listener on component unmount
    return () => {
      window.removeEventListener("storage", handleStorageChange);
      window.removeEventListener("beforeunload", cleanup);
      cleanup();
    };
  }, []);

  useEffect(() => {
    if (!tokenFromQuery) {
      navigate("/login");
      return;
    }

    const process_data = async () => {
      try {
        const tokenResponse = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/auth/2fa/totp-verify`,
          {
            token: tokenFromQuery,
          }
        );
        if (tokenResponse.data.success) {
          const emailResp = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/nocApi/auth/2fa/email-pass`,
            {
              token: tokenFromQuery,
              remember_token: rememberFromQuery,
            },
            { withCredentials: true }
          );

          setPageLoading(false);
        } else {
          navigate("/login");
          throw new Error("Network response was not ok");
        }
        //}
      } catch (error) {
        console.error("Error fetching Email:", error);
        navigate("/login");
      }
    };

    if (count < 1) {
      process_data();
      count += 1;
    }
  }, []);

  const handleResendCode = async () => {
    try {
      setResendDisabled(true);
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/auth/2fa/email-pass`,
        {
          token: tokenFromQuery,
          second_try: true,
          resend: true,
          remember_token: rememberFromQuery,
        },
        { withCredentials: true }
      );

      setBlockedText("The email has been resent");
    } catch (e) {
      navigate("/login");
    }
  };

  return (
    <>
      {pageLoading ? (
        <>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100vh",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CircularProgress color="warning" size="10rem" />
          </Box>
        </>
      ) : (
        <Box
          sx={
            mobileSize
              ? {
                  justifyContent: "center",
                  overflow: "auto",
                  width: "100%",
                  height: "100vh",
                }
              : {
                  backgroundImage: "url('/img/website_img/homeImg.jpeg')",
                  backgroundRepeat: "repeat",
                  width: "100%",
                  flex: "1 1 auto",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  height: "100vh",
                }
          }
        >
          <Box
            sx={
              mobileSize
                ? {
                    display: "flex",
                    alignItems: "center",
                    flex: "1 1 auto",
                    justifyContent: "center",
                    backgroundColor: "#fff",
                    py: "10px",
                    width: "100%",
                  }
                : {
                    overflowY: "auto",
                    backgroundColor: "#fff",
                    maxWidth: 900,
                    maxHeight: "95vh",
                    borderRadius: "25px",
                    px: 3,
                    py: "10px",
                    width: "100%",
                    pt: "2rem",
                  }
            }
          >
            <div
              style={
                mobileSize
                  ? { width: "100%", padding: "1rem" }
                  : { padding: "1rem" }
              }
            >
              <Stack spacing={1} sx={{ mb: 3 }}>
                <Box
                  sx={{ cursor: "pointer" }}
                  display="flex"
                  justifyContent="center"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <img
                    style={{
                      width: mobileSize ? "8rem" : "10rem",
                    }}
                    alt="Permit Rockstar Logo"
                    src="/img/website_img/PermitRockstar.png"
                  />
                </Box>
              </Stack>
              <Typography
                sx={{ mb: 2 }}
                color={emailVerified ? "#4caf50" : "primary"}
                variant={mobileSize ? "h5" : "h4"}
                align="center"
              >
                {emailVerified
                  ? "Two Factor Authentication Was Successful"
                  : "Two Factor Authentication is Required"}
              </Typography>
              <Typography
                color="primary"
                variant={mobileSize ? "h6" : "h5"}
                align="center"
              >
                {emailVerified
                  ? "Your email has been verified"
                  : "An email has already been sent to you"}
              </Typography>

              <Grid container justifyContent="center" spacing={2}>
                {images.map((image, index) => (
                  <Grid item sm={12} lg={4} key={index}>
                    <Box
                      sx={{
                        borderRadius: "10px",
                        backgroundColor: "transparent",
                        flexDirection: "column",
                        cursor: "auto",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <Box sx={{ pb: 3, pt: 1 }}>
                        <img
                          alt={image.alt}
                          src={image.src}
                          width={
                            selectedImage === index
                              ? image.width * 1.1
                              : image.width
                          }
                        />
                      </Box>
                    </Box>
                  </Grid>
                ))}

                <>
                  <Grid container justifyContent="center" mb={2} mt={5}>
                    <Grid item sm={12} sx={{ width: "100%" }}>
                      {/* <LoadingButton
                        onClick={callAuth}
                        disabled={blocked}
                        loading={loading}
                        fullWidth
                        size="large"
                        sx={{ mt: 0, p: 2 }}
                        type="submit"
                        variant="contained"
                      >
                        SEND VERIFICATION EMAIL
                      </LoadingButton> */}
                      <Typography
                        color="primary"
                        variant="body1"
                        align="center"
                        sx={{ mb: 5 }}
                      >
                        {emailVerified
                          ? "You may now safely close this window and proceed to the other tab."
                          : "Please follow the instructions in the email provided for two-factor authentication."}
                      </Typography>
                    </Grid>
                    <Grid item sm={12} sx={{ mt: 2, minHeight: "1.5rem" }}>
                      <Typography
                        variant="body1"
                        textAlign="center"
                        color="error"
                        fontWeight="bold"
                      >
                        {blockedText}
                      </Typography>
                      {!emailVerified && (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                          onClick={handleResendCode}
                        >
                          <Typography
                            component={Link}
                            variant="body1"
                            sx={
                              resendDisabled
                                ? {
                                    textAlign: "center",
                                    cursor: "auto",
                                    textDecoration: "none",
                                  }
                                : { textAlign: "center", cursor: "pointer" }
                            }
                            color={resendDisabled ? "#d4d4d4" : "primary"}
                            fontWeight="bold"
                          >
                            Resend Code
                          </Typography>
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </>
              </Grid>
            </div>
          </Box>
        </Box>
      )}
    </>
  );
};

const images = [
  {
    alt: "authenticator",
    src: "/img/logos/2falogo.png",
    // width: 167,
    width: 250,
  },
];

export default EmailFaAuth;
