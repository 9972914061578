import React, { useEffect, useRef } from "react";
import {
  AppBar,
  Dialog,
  IconButton,
  Toolbar,
  Typography,
  Box,
  CircularProgress,
  FormHelperText,
} from "@mui/material";
import { PencilIcon } from "@heroicons/react/24/outline";
import CloseIcon from "@mui/icons-material/Close";
import Grow from "@mui/material/Grow";
import MobileAlertComponent from "../popover/MobileAlert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CallMadeIcon from "@mui/icons-material/CallMade";
import ListIcon from "@mui/icons-material/List";
import MobileCreateForm from "../noc/MobileCreateForm";
import ConfirmModal from "../popover/ConfirmModal";

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Grow
      ref={ref}
      {...props}
      timeout={{
        enter: 500, // Duration for the enter animation in milliseconds
        exit: 300, // Duration for the exit animation
      }}
      style={{
        transformOrigin: "center",
        transitionDelay: props.in ? "0ms" : "200ms", // Delay the exit animation
      }}
    />
  );
});

const CreateNocMobile = (props) => {
  const dialogRef = useRef(null);

  useEffect(() => {
    if (props?.previewEnabled || props?.createEnabled || props?.listEnabled) {
      const scrollContainer = dialogRef.current; // Get the DOM node
      if (scrollContainer) {
        scrollContainer.scrollTo(0, 0); // Use scrollTo for better control
      }
    }
  }, [props?.previewEnabled, props?.createEnabled, props?.listEnabled]);

  return (
    <Dialog
      fullScreen={props?.tabletSize}
      open={props?.addEnabled}
      onClose={props?.handleClose}
      TransitionComponent={Transition}
    >
      <AppBar
        sx={{
          position: "relative",
          overflow: "hidden",
          backgroundColor: "#333333",
          pl: props?.mobileSize ? 2 : undefined,
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={props?.handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography
            sx={{
              ml: props?.mobileSize ? 1 : props?.tabletSize ? 2 : 0,
              flex: 1,
            }}
            variant="h6"
            component="div"
          >
            Creating New Permit Package
          </Typography>
        </Toolbar>

        {props?.unavailableLicense && (
          <MobileAlertComponent
            alertText="Before proceeding, please verify your license number."
            btnText="Close"
            severity="error"
            secondaryColor="#fff"
            loading={undefined}
            license={true}
            onClick={() => props?.setUnavailableRecordingOpen(false)}
          />
        )}
      </AppBar>
      {/* {props?.createEnabled ? ( */}
      <Box
        ref={dialogRef}
        style={{ height: "calc(100% - 110px)", overflow: "auto" }}
      >
        <MobileCreateForm {...props} mobileRow={props?.rowData} />
      </Box>
      <ConfirmModal
        open={props?.exitModal}
        setOpen={props?.setExitModal}
        handleClose={props?.confirmedHandleClose}
        title="Do you really want to leave?"
        subTitle="You will lose all the field values you entered!"
        btnText1="Leave"
        btnText2="Stay"
      />
      <ConfirmModal
        type="create"
        open={props?.beforeCreateModal}
        setOpen={props?.setBeforeCreateModal}
        handleStartCreate={props?.handleStartCreate}
        title="Do you really want to start creating NOC?"
        subTitle="Ensure that all the information you have entered is accurate. Once the NOC creation process has begun, it cannot be reversed."
        btnText1="Stay"
        btnText2="Create"
      />

      <Toolbar
        sx={{
          px: 0,
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          opacity: 0.7,
          backgroundColor: "#333333",
        }}
      >
        <IconButton
          disabled={props?.previewLoading}
          variant="contained"
          onClick={props?.handleCreateClick}
          sx={{ flex: 1 }}
        >
          <Box>
            <PencilIcon width={25} color="#fff" />
            <FormHelperText
              sx={{ fontWeight: "bold", color: "#fff", mt: -1, px: "5px" }}
            >
              Add Values
            </FormHelperText>
            <Box
              sx={{
                borderBottom: props?.createEnabled
                  ? "3px solid #757575"
                  : "3px solid transparent",
                mt: "-4px",
              }}
            ></Box>
          </Box>
        </IconButton>

        <IconButton
          disabled={props?.previewLoading}
          sx={{ flex: 1 }}
          onClick={props?.handlePreviewClick}
          variant="contained"
        >
          <Box>
            {props?.previewLoading ? (
              <CircularProgress sx={{ color: "#fff" }} size="18px" />
            ) : props?.previewEnabled ? (
              <VisibilityIcon sx={{ fontSize: "25px", color: "#fff" }} />
            ) : (
              <VisibilityOffIcon sx={{ fontSize: "25px", color: "#fff" }} />
            )}
            <FormHelperText
              sx={{
                fontWeight: "bold",
                color: "#fff",
                mt: -1,
                px: "5px",
                display: props?.previewLoading ? "none" : undefined,
              }}
            >
              Preview
            </FormHelperText>
            <Box
              sx={{
                borderBottom: props?.previewEnabled
                  ? "3px solid #757575"
                  : "3px solid transparent",
                mt: "-4px",
              }}
            ></Box>
          </Box>
        </IconButton>
        <IconButton
          disabled={props?.previewLoading || props?.addressRow.length === 0}
          sx={{ flex: 1, opacity: props?.addressRow.length !== 0 ? 1 : 0.3 }}
          onClick={props?.handleListClick}
          variant="contained"
        >
          <Box>
            <ListIcon sx={{ fontSize: "1.8rem", color: "#fff" }} />
            <FormHelperText
              sx={{ fontWeight: "bold", color: "#fff", mt: -1.5, px: "5px" }}
            >
              Addresses
            </FormHelperText>
            <Box
              sx={{
                borderBottom: props?.listEnabled
                  ? "3px solid #757575"
                  : "3px solid transparent",
                mt: "-4px",
              }}
            ></Box>
          </Box>
        </IconButton>
        <IconButton
          disabled={props?.previewLoading}
          onClick={() => {
            const hasErrors = Object.keys(props?.formik.errors).length > 0;
            if (
              hasErrors === false &&
              props?.formik?.values?.permitNumber === "" &&
              props?.emptyPermitConfirmed === false
            ) {
              props?.setEmptyPermitOpen(true);
            } else {
              if (hasErrors) {
                return;
              } else {
                props?.setEmptyPermitConfirmed(false);
                props?.setBeforeCreateModal(true);
              }
            }
          }}
          size="small"
          sx={{
            padding: 0.5,
            flex: 1,
            backgroundColor: "#909090",
            "&:hover": {
              backgroundColor: "#909090", // Maintain the color even on hover
            },
            "&.Mui-focusVisible": {
              backgroundColor: "#909090", // Maintain the color when focused (clicked)
            },
            "&:active": {
              backgroundColor: "#909090", // Maintain the color when active
            },
          }}
          variant="contained"
          // onClick={props?.handlesStartEnabled}
        >
          <CallMadeIcon
            color="#fff"
            sx={{ fontSize: "2.5rem", color: "#fff", opacity: 0.8 }}
          />
        </IconButton>
      </Toolbar>
    </Dialog>
  );
};

export default CreateNocMobile;
