import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Fade,
  Typography,
  List,
  ListItemText,
  ListItem,
  Checkbox,
  Grid,
  Button,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import BlockIcon from "@mui/icons-material/Block";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { FunFact } from "../noc/FunFact";

function PdfProgressModal({
  open,
  nocStates,
  setNocStates,
  pdfProgress,
  noc_data,
  selectedPreName,
  setPermitDrawerOpen,
}) {
  const tabletSize = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const below1200 = useMediaQuery("(max-width:1200px)");
  const mobileSize = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [avgTime, setAvgTime] = useState("N/A");
  const firstItem = "Downloading Property Details";
  const lastItem = "PDF file created successfully";
  const failedError = "Could'nt find property for the given address";
  const appraiserError =
    "Property Appraiser website is currently not responding.";
  const moreError = "More than one property found for the given address";
  useEffect(() => {
    if (
      pdfProgress === firstItem &&
      !nocStates?.items.includes(pdfProgress) &&
      setNocStates
    ) {
      setNocStates((prevState) => ({
        ...prevState,
        initializeQueue: false,
        items: [...prevState.items, pdfProgress],
      }));
      // setInitializeQueue(false);
      // setItems((prevItems) => [...prevItems, pdfProgress]);
      startDotAnimation(pdfProgress);
    } else if (
      pdfProgress === lastItem &&
      nocStates?.isFinished === false &&
      setNocStates
    ) {
      setNocStates((prevState) => ({
        ...prevState,
        isFinished: true,
        items: [...prevState.items, pdfProgress],
        completed: { ...prevState.completed, [pdfProgress]: true },
      }));

      // setIsFinished(true);
      // Directly add "done" to items and mark it as completed
      // setItems((prevItems) => [...prevItems, pdfProgress]);
      // Since pdfProgress is "done", use it directly to update the completed state
      // setCompleted((prev) => ({ ...prev, [pdfProgress]: true }));

      if (nocStates?.items.length > 0) {
        const lastItem = nocStates?.items[nocStates?.items.length - 1];
        markAsCompleted(lastItem);
      }
    } else if (
      pdfProgress === moreError &&
      nocStates?.isMoreError === false &&
      setNocStates
    ) {
      setNocStates((prevState) => ({
        ...prevState,
        isMoreError: true,
        items: [...prevState.items, pdfProgress],
        completed: { ...prevState.completed, [pdfProgress]: true },
      }));

      // setIsMoreError(true);
      // Directly add "done" to items and mark it as completed
      // setItems((prevItems) => [...prevItems, pdfProgress]);
      // Since pdfProgress is "done", use it directly to update the completed state
      // setCompleted((prev) => ({ ...prev, [pdfProgress]: true }));

      if (nocStates?.items.length > 0) {
        const moreError = nocStates?.items[nocStates?.items.length - 1];
        markAsCompleted(moreError);
      }
    } else if (
      pdfProgress === failedError &&
      nocStates?.isFailedError === false &&
      setNocStates
    ) {
      setNocStates((prevState) => ({
        ...prevState,
        isFailedError: true,
        items: [...prevState.items, pdfProgress],
        completed: { ...prevState.completed, [pdfProgress]: true },
      }));

      // setIsFailedError(true);
      // Directly add "done" to items and mark it as completed
      // setItems((prevItems) => [...prevItems, pdfProgress]);
      // Since pdfProgress is "done", use it directly to update the completed state
      // setCompleted((prev) => ({ ...prev, [pdfProgress]: true }));

      if (nocStates?.items.length > 0) {
        const failedError = nocStates?.items[nocStates?.items.length - 1];
        markAsCompleted(failedError);
      }
    } else if (
      pdfProgress === appraiserError &&
      nocStates?.isFailedError === false &&
      setNocStates
    ) {
      setNocStates((prevState) => ({
        ...prevState,
        isFailedError: true,
        items: [...prevState.items, pdfProgress],
        completed: { ...prevState.completed, [pdfProgress]: true },
      }));

      // setIsFailedError(true);
      // Directly add "done" to items and mark it as completed
      // setItems((prevItems) => [...prevItems, pdfProgress]);
      // Since pdfProgress is "done", use it directly to update the completed state
      // setCompleted((prev) => ({ ...prev, [pdfProgress]: true }));

      if (nocStates?.items.length > 0) {
        const appraiserError = nocStates?.items[nocStates?.items.length - 1];
        markAsCompleted(appraiserError);
      }
    } else if (
      pdfProgress &&
      !nocStates?.items.includes(pdfProgress) &&
      setNocStates
    ) {
      // Before adding the new item, mark the previous one as completed if it exists
      if (nocStates?.items.length > 0) {
        // Check if there are already items present
        const previousItem = nocStates?.items[nocStates?.items.length - 1]; // Get the last item in the current list
        markAsCompleted(previousItem); // Mark the last item as completed
      }

      setNocStates((prevState) => ({
        ...prevState,
        items: [...prevState.items, pdfProgress],
        completed: { ...prevState.completed, [pdfProgress]: true },
      }));

      // Then add the new pdfProgress item
      // setItems((prevItems) => [...prevItems, pdfProgress]);
      startDotAnimation(pdfProgress);
    }
  }, [
    nocStates?.pdfProgressModalOpen,
    pdfProgress,
    nocStates?.items,
    nocStates?.isFailedError,
  ]); // Dependency array

  useEffect(() => {
    if (nocStates?.isFinished && setNocStates) {
      setTimeout(() => {
        setNocStates((prevState) => ({
          ...prevState,
          pdfProgressModalOpen: false,
        }));
        // handleClose();
        // setIsFinished(false);
      }, 3000);
    } else if (nocStates?.isMoreError && setNocStates) {
      setTimeout(() => {
        setNocStates((prevState) => ({
          ...prevState,
          pdfProgressModalOpen: false,
        }));
        // handleClose();
      }, 5000);
    } else if (
      nocStates?.isFailedError &&
      pdfProgress === appraiserError &&
      setNocStates
    ) {
      setTimeout(() => {
        // if (setOpenDrawer) {
        //   setOpenDrawer(false);
        //   handleClose();
        // }
        setNocStates((prevState) => ({
          ...prevState,
          waitingList: true,
        }));
      }, 5000);
    } else if (
      nocStates?.isFailedError &&
      pdfProgress !== appraiserError &&
      setNocStates
    ) {
      setTimeout(() => {
        setNocStates((prevState) => ({
          ...prevState,
          pdfProgressModalOpen: false,
        }));
        // handleClose();
      }, 5000);
    }
  }, [nocStates?.isFinished, nocStates?.isMoreError, nocStates?.isFailedError]);
  useEffect(() => {
    if (nocStates) {
      if (noc_data) {
        const matchingEntry = noc_data?.appraiser_results?.[selectedPreName];

        if (matchingEntry?.creation_data?.average === 0) {
          setAvgTime("N/A");
        } else {
          setAvgTime(matchingEntry?.creation_data?.average);
        }
      }
    }
  }, [selectedPreName, noc_data]);

  useEffect(() => {
    if (nocStates?.intervalIds) {
      return () => {
        Object.values(nocStates?.intervalIds).forEach(clearInterval);
      };
    }
  }, [nocStates?.intervalIds]);

  // Starts the dot animation for a new item
  const startDotAnimation = (item) => {
    if (setNocStates) {
      const interval = setInterval(() => {
        setNocStates((prevState) => ({
          ...prevState,
          dotStates: {
            ...prevState.dotStates,
            [item]: ((prevState.dotStates[item] || 0) + 1) % 4,
          },
        }));

        // setDotStates((prev) => ({
        //   ...prev,
        //   [item]: ((prev[item] || 0) + 1) % 4,
        // }));
      }, 500);

      setNocStates((prevState) => ({
        ...prevState,
        intervalIds: { ...prevState.intervalIds, [item]: interval },
      }));
    }
    // setIntervalIds((prev) => ({ ...prev, [item]: interval }));
  };

  const markAsCompleted = (item) => {
    setNocStates((prevState) => ({
      ...prevState,
      completed: { ...prevState.completed, [item]: true },
    }));

    // setCompleted((prev) => ({ ...prev, [item]: true }));
    if (nocStates?.intervalIds[item]) {
      clearInterval(nocStates?.intervalIds[item]);

      setNocStates((prevState) => {
        const newIntervalIds = { ...prevState.intervalIds };
        delete newIntervalIds[item];

        return {
          ...prevState,
          intervalIds: newIntervalIds,
        };
      });

      // setIntervalIds((prev) => {
      //   const newState = { ...prev };
      //   delete newState[item];
      //   return newState;
      // });
    }
  };

  const modalStyle = {
    borderRadius: 2,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: below1200 ? "100%" : "auto",
    maxWidth: below1200 ? "100vw" : undefined,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    py: mobileSize ? 2 : undefined,
  };

  return (
    <Modal
      open={open}
      // onClose={
      //   tabletSize
      //     ? () => {}
      //     : () =>
      //         setNocStates((prevState) => ({
      //           ...prevState,
      //           pdfProgressModalOpen: false,
      //         }))
      // }
      closeAfterTransition
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        // Modal overlay (backdrop) styles
        "& .MuiBackdrop-root": {
          backdropFilter: "blur(3px)",
          backgroundColor: "rgba(0, 0, 0, 0.2)",
        },
        // Adjust transition duration in the theme or here directly
        transitionDuration: {
          enter: 1500, // Customize entering duration
          exit: 500, // Customize exiting duration
        },
      }}
    >
      <Fade in={nocStates?.pdfProgressModalOpen}>
        <Box sx={modalStyle}>
          <IconButton
            onClick={() => {
              setNocStates({
                ...nocStates,
                pdfProgressModalOpen: false,
              });
              setPermitDrawerOpen(false);
            }}
            sx={{ position: "absolute", right: 10, top: 10 }}
          >
            <CloseIcon />
          </IconButton>
          {nocStates?.waitingList === true ? (
            <>
              <Typography
                variant={mobileSize ? "h5" : "h3"}
                sx={{ px: mobileSize ? 0.5 : undefined }}
                align="center"
              >
                Notice of Commencement PDF Generation Failed
              </Typography>
            </>
          ) : (
            <>
              <Typography
                variant={mobileSize ? "h5" : "h3"}
                sx={{ px: mobileSize ? 0.5 : undefined }}
                align="center"
              >
                {nocStates?.initializeQueue
                  ? "Calculating queue position... Please Wait"
                  : "Notice of Commencement PDF Generation In Progress"}
              </Typography>
            </>
          )}

          {!nocStates?.initializeQueue && (
            <Typography variant="h6" align="center" mt={1}>
              Average Processing Time:{" "}
              <span
                style={{
                  fontSize: below1200 ? "1.5rem" : "2rem",
                  color: "#10B981",
                }}
              >
                {avgTime}
              </span>{" "}
              {avgTime !== "N/A" && "Seconds"}
            </Typography>
          )}

          <Box sx={{ textAlign: "center", mt: 2 }}>
            <img
              src="/img/gif/HI9M.gif"
              alt="loading"
              width={mobileSize ? 80 : 100}
            />
          </Box>
          {nocStates?.items?.length > 0 && <FunFact />}
          <Box sx={{ textAlign: "center", mt: 2 }}>
            <List sx={{ width: "100%", bgcolor: "background.paper" }}>
              {nocStates?.items.map((item, index) => (
                <ListItem
                  key={index}
                  sx={
                    nocStates?.completed[item] && item === lastItem
                      ? {
                          animation: "slide-in 0.5s ease-out forwards",
                          "@keyframes slide-in": {
                            from: { transform: "translateX(100%)" },
                            to: { transform: "translateX(0)" },
                          },
                          backgroundColor: "#90EE90",
                        }
                      : nocStates?.completed[item] &&
                        (item === failedError || item === appraiserError)
                      ? {
                          animation: "slide-in 0.5s ease-out forwards",
                          "@keyframes slide-in": {
                            from: { transform: "translateX(100%)" },
                            to: { transform: "translateX(0)" },
                          },
                          backgroundColor: "#E06666",
                        }
                      : nocStates?.completed[item] && item === moreError
                      ? {
                          animation: "slide-in 0.5s ease-out forwards",
                          "@keyframes slide-in": {
                            from: { transform: "translateX(100%)" },
                            to: { transform: "translateX(0)" },
                          },
                          backgroundColor: "#FFFD8D",
                        }
                      : {
                          animation: "slide-in 0.5s ease-out forwards",
                          "@keyframes slide-in": {
                            from: { transform: "translateX(100%)" },
                            to: { transform: "translateX(0)" },
                          },
                        }
                  }
                >
                  <ListItemText
                    primary={
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs>
                          <Typography
                            variant={mobileSize ? "body2" : "h6"}
                            sx={{
                              fontWeight: "normal",
                              display: "inline",
                              color: (theme) => {
                                // First, check if the item is completed
                                if (nocStates?.completed[item]) {
                                  // Nested conditions for specific item checks within the completed branch
                                  if (
                                    item === failedError ||
                                    item === appraiserError
                                  ) {
                                    return "#fff"; // Red color specifically for failedError
                                  } else if (item === moreError) {
                                    return "#CF2A2A"; // Yellow color specifically for moreError
                                  } else if (item === lastItem) {
                                    return "green"; // Green color for the last item if completed
                                  } else {
                                    return "#808080"; // Default gray color for other completed items
                                  }
                                } else {
                                  // If the item is not completed, use the default text color
                                  return theme.palette.text.primary;
                                }
                              },
                              textDecoration: (theme) => {
                                // Assuming you don't want any textDecoration for failedError and moreError
                                if (
                                  nocStates?.completed[item] &&
                                  item !== lastItem &&
                                  item !== failedError &&
                                  item !== appraiserError &&
                                  item !== moreError
                                ) {
                                  return "line-through";
                                } else {
                                  return "none";
                                }
                              },
                            }}
                          >
                            {item}
                          </Typography>
                          {!nocStates?.completed[item] && (
                            <Typography
                              component="span"
                              sx={{
                                fontSize: mobileSize ? "1rem" : "2rem",

                                display: "inline",
                              }}
                            >
                              {nocStates?.dotStates[item] !== undefined
                                ? ".".repeat(nocStates?.dotStates[item])
                                : ""}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item>
                          {nocStates?.completed[item] && item == moreError ? (
                            <BlockIcon
                              sx={{
                                color: "#CF2A2A",
                                fontSize: mobileSize ? "20px" : undefined,
                              }}
                            />
                          ) : nocStates?.completed[item] &&
                            (item == failedError || item === appraiserError) ? (
                            <HighlightOffIcon
                              sx={{
                                color: "#fff",
                                fontSize: mobileSize ? "20px" : undefined,
                              }}
                            />
                          ) : (
                            nocStates?.completed[item] && (
                              <CheckCircleOutlineIcon
                                color="success"
                                sx={{
                                  fontSize: mobileSize ? "20px" : undefined,
                                }}
                              />
                            )
                          )}
                        </Grid>
                      </Grid>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Box>
          {nocStates?.waitingList === true && (
            <>
              <Typography
                variant={tabletSize ? "body2" : "h6"}
                sx={{ fontWeight: tabletSize ? "bold" : undefined }}
                color="error"
                mb={2}
                px={tabletSize ? 1 : undefined}
                align="center"
              >
                Once the appraiser website has been reached successfully, we
                will retry the process. Please refer to the notifications tab
                for updates.
              </Typography>
              <Button
                onClick={() => {
                  setNocStates((prevState) => ({
                    ...prevState,
                    waitingList: false,
                    isFailedError: false,
                    pdfProgressModalOpen: false,
                  }));

                  if (nocStates?.waitingList === true) {
                    setPermitDrawerOpen(false);
                  }
                  // setOpenDrawer(false);
                  // setWaitingList(false);
                  // setIsFailedError(false);
                  // if (tabletSize) {
                  //   setAddEnabled(false);
                  // }
                  // handleClose();
                }}
                fullWidth
                variant="contained"
              >
                Close
              </Button>
            </>
          )}
        </Box>
      </Fade>
    </Modal>
  );
}

export default PdfProgressModal;
