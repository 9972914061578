import { useEffect, useState, useRef } from "react";

import {
  Box,
  Card,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useFormikContext } from "formik";
import CollapsiblePaymentCard from "../../notary/modals/CollapsiblePaymentCard";

const MobileFinalizeUpload = (props) => {
  const {
    values,
    setFieldValue,
    resetForm,
    touched,
    errors,
    handleChange,
    handleBlur,
  } = useFormikContext();

  const findPriceByTitle = (title) =>
    props?.prices.find((price) => price.name === title)?.price || 0;
  const [vat, setVat] = useState(0);
  const [calculatedContent, setCalculatedContent] = useState(null);
  const scrollableBoxRef = useRef(null);

  const calculateDiscount = () => {
    const notarizationPrice = parseFloat(findPriceByTitle("notary")); // Convert to float
    const recordingPrice = parseFloat(findPriceByTitle("record")); // Convert to float
    const combinedPrice = parseFloat(findPriceByTitle("notaryRecord")); // Convert to float

    const discount = notarizationPrice + recordingPrice - combinedPrice;
    const discountPercent =
      (discount / (notarizationPrice + recordingPrice)) * 100;
    // Round to two decimal places and convert back to a float
    const roundedDiscountPercent = parseFloat(discountPercent.toFixed(2));

    return {
      notarizationPrice: notarizationPrice.toFixed(2),
      recordingPrice: recordingPrice.toFixed(2),
      combinedPrice,
      discount: discount.toFixed(2),
      discountPercent: isNaN(roundedDiscountPercent)
        ? 0
        : roundedDiscountPercent,
    };
  };

  const handleDotClick = (index) => {
    props?.setCurrentIndex(index);
  };

  const scrollToBottom = () => {
    // Scroll logic
    const scrollableElement = scrollableBoxRef.current;
    if (scrollableElement) {
      scrollableElement.scrollTop = scrollableElement.scrollHeight;
    }
  };

  useEffect(() => {
    let content;
    switch (values.reason) {
      case "Notarization":
        let sub_price_notary = findPriceByTitle("notary");
        content = (
          <>
            <Grid container justifyContent="space-between" sx={{ mb: 1 }}>
              <Grid item>
                <Typography variant="body1">Notarization Fee</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">${sub_price_notary}</Typography>
              </Grid>
            </Grid>
            {/* VAT */}
            <Grid container justifyContent="space-between" sx={{ mb: 1 }}>
              <Grid item>
                <Typography variant="body1">VAT</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  ${parseFloat(vat).toFixed(2)}
                </Typography>
              </Grid>
            </Grid>

            <Divider sx={{ my: 2 }} />

            {/* Subtotal */}
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant="body1" fontWeight="bold">
                  Subtotal
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" fontWeight="bold">
                  ${(parseFloat(sub_price_notary) + parseFloat(vat)).toFixed(2)}
                </Typography>
              </Grid>
            </Grid>
          </>
        );
        break;
      case "Recording":
        let sub_price_record = findPriceByTitle("record");
        content = (
          <>
            <Grid container justifyContent="space-between" sx={{ mb: 1 }}>
              <Grid item>
                <Typography variant="body1">Recording Fee</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">${sub_price_record}</Typography>
              </Grid>
            </Grid>
            {/* VAT */}
            <Grid container justifyContent="space-between" sx={{ mb: 1 }}>
              <Grid item>
                <Typography variant="body1">VAT</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  ${parseFloat(vat).toFixed(2)}
                </Typography>
              </Grid>
            </Grid>

            <Divider sx={{ my: 2 }} />

            {/* Subtotal */}
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant="body1" fontWeight="bold">
                  Subtotal
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" fontWeight="bold">
                  ${(parseFloat(sub_price_record) + parseFloat(vat)).toFixed(2)}
                </Typography>
              </Grid>
            </Grid>
          </>
        );

        break;
      case "Notarization & Recording":
        const {
          notarizationPrice,
          recordingPrice,
          combinedPrice,
          discount,
          discountPercent,
        } = calculateDiscount();
        content = (
          <>
            <Grid container justifyContent="space-between" sx={{ mb: 1 }}>
              <Grid item>
                <Typography variant="body1">Notarization Fee</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">${notarizationPrice}</Typography>
              </Grid>
            </Grid>
            <Grid container justifyContent="space-between" sx={{ mb: 1 }}>
              <Grid item>
                <Typography variant="body1">Recording Fee</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">${recordingPrice}</Typography>
              </Grid>
            </Grid>
            <Grid container justifyContent="space-between" sx={{ mb: 1 }}>
              <Grid item>
                <Typography variant="body1">Total</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  $
                  {(
                    parseFloat(recordingPrice) + parseFloat(notarizationPrice)
                  ).toFixed(2)}
                </Typography>
              </Grid>
            </Grid>
            <Grid container justifyContent="space-between" sx={{ mb: 1 }}>
              <Grid item>
                <Typography
                  sx={{ color: "#4caf50", fontWeight: "bold" }}
                  variant="body1"
                >
                  Discount
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  sx={{ color: "#4caf50", fontWeight: "bold" }}
                  variant="body1"
                >
                  ${discount} / {discountPercent.toFixed(2)}%
                </Typography>
              </Grid>
            </Grid>
            {/* VAT */}
            <Grid container justifyContent="space-between" sx={{ mb: 1 }}>
              <Grid item>
                <Typography variant="body1">VAT</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  ${parseFloat(vat).toFixed(2)}
                </Typography>
              </Grid>
            </Grid>

            <Divider sx={{ my: 2 }} />

            {/* Subtotal */}
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant="body1" fontWeight="bold">
                  Subtotal
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" fontWeight="bold">
                  ${(parseFloat(combinedPrice) + parseFloat(vat)).toFixed(2)}
                </Typography>
              </Grid>
            </Grid>
          </>
        );

        break;
      default:
        content = <Typography>Please select a processing type.</Typography>;
    }

    setCalculatedContent(content);
  }, [values.reason]);

  return (
    <Box sx={{ p: 1, mx: 1 }} ref={scrollableBoxRef}>
      <Box mb={1}>
        <CollapsiblePaymentCard
          paymentCards={props?.paymentCards}
          currentIndex={props?.currentIndex}
          handleDotClick={handleDotClick}
          scrollToBottom={scrollToBottom}
          screenLimit={props?.below1450}
          documentUploader={true}
          tabletSize={props?.tabletSize}
          mobileSize={props?.mobileSize}
        />
      </Box>

      <Box
        sx={{
          mt: 2,
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.3)",
          borderRadius: "18px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          width: "100%",
        }}
      >
        <Card sx={{ m: 1, p: 1 }}>
          <Typography variant="h6" gutterBottom>
            Receipt Overview
          </Typography>
          <Divider sx={{ mb: 2 }} />
          {/* Processing Type */}
          <Grid container justifyContent="space-between" sx={{ mb: 1 }}>
            <Grid item>
              <Typography variant="body1" fontWeight="bold">
                Processing Type
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">{values?.reason}</Typography>
            </Grid>
          </Grid>
          {/* Document Type */}
          <Grid container justifyContent="space-between" sx={{ mb: 1 }}>
            <Grid item>
              <Typography variant="body1" fontWeight="bold">
                Document Type
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">{values?.documentType}</Typography>
            </Grid>
          </Grid>
          {calculatedContent}
        </Card>
      </Box>
    </Box>
  );
};

export default MobileFinalizeUpload;
