import React from "react";
import {
  Box,
  TextField,
  Typography,
  Grid,
  Select,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  InputLabel,
  FormControl,
} from "@mui/material";
import { useEffect } from "react";

const TableOverview = ({
  propertyDetails,
  setDocumentModal,
  formik,
  fullOwners,
  copyOwners,
  ownerships,
  isFieldsDisabled,
  record,
  img,
  source,
  setSignerType,
  tabletSize,
  largeTabletSize,
  mobileSize,
}) => {
  useEffect(() => {
    let updateList = [];
    let updatedOwnersSelect = [...formik.values.ownersSelect];

    copyOwners.forEach((copy) => {
      if (copy.type === "ORGANIZATION") {
        if (
          formik.values?.fullOwnerSelect.some(
            (selected) => selected === copy.value.ORGANIZATION
          )
        ) {
          updateList.push(copy);
        }
      } else if (copy.type === "PERSON") {
        if (
          formik.values?.fullOwnerSelect.some(
            (selected) => selected === copy.value.PERSON
          )
        ) {
          updateList.push(copy);
        }
      }

      const indexInOwnersSelect = updatedOwnersSelect.findIndex(
        (select) => select.label === copy.label
      );
      if (indexInOwnersSelect !== -1) {
        updatedOwnersSelect[indexInOwnersSelect].value = copy.type;
      }
    });

    formik.setFieldValue("owners", updateList);

    if (formik.values.ownersSelect.length !== 0) {
      formik.setFieldValue("ownersSelect", updatedOwnersSelect);
    }
  }, [formik.values.fullOwnerSelect, copyOwners]);

  const handleChangeType = (e, formik, label) => {
    const formikOwnerIndex = formik.values.owners.findIndex(
      (owner) => owner.label === label
    );

    if (formikOwnerIndex !== -1) {
      const selected_value = e.target.value;
      // Get current values to switch them
      const currentValue = formik.values.owners[formikOwnerIndex].value;

      // Prepare new values for both PERSON and ORGANIZATION
      const newValues = {
        PERSON: "",
        ORGANIZATION: "",
      };

      if (formikOwnerIndex === 0) {
        setSignerType(selected_value);
      }

      // Depending on the selected value, set the appropriate fields
      if (selected_value === "PERSON") {
        newValues.PERSON = currentValue.ORGANIZATION;
        if (formikOwnerIndex === 0) {
          formik.setFieldError("signatoryTitle", "");
          formik.setFieldError("authority", "");
        }
      } else if (selected_value === "ORGANIZATION") {
        newValues.ORGANIZATION = currentValue.PERSON;
      }

      // Update the formik state atomically
      formik.setFieldValue(`owners[${formikOwnerIndex}]`, {
        ...formik.values.owners[formikOwnerIndex], // Keep existing data
        type: selected_value, // Update type
        value: newValues, // Set new object for values
      });
    }
  };

  return (
    <>
      {!mobileSize && (
        <Box
          sx={{
            width: "100%",
            pb: 1,
            mb: 2,
            pt: 2,
          }}
        >
          <Typography variant={mobileSize ? "h5" : "h4"} align="center">
            Overview
          </Typography>
        </Box>
      )}

      <Grid container spacing={1} mt={mobileSize ? 1 : 0}>
        <Grid item xs={12} lg={8}>
          <Typography variant="h6" align="center">
            Property Information
          </Typography>
          <Box
            sx={{
              bgcolor: "#fff",
              py: largeTabletSize ? 0.5 : 2,
              px: tabletSize ? 0 : 2,
              minHeight: "100px", // Adjust as needed
            }}
          >
            <TableContainer component={Paper}>
              <Table sx={{ border: 1, borderColor: "grey.300" }}>
                <TableBody>
                  {propertyDetails?.affected_property?.map((row, index) => {
                    const formikOwnerIndex = formik?.values?.owners?.findIndex(
                      (owner) => owner?.label === row?.label
                    );

                    const baseKey = `${row.label}-${index}}`;
                    const formikSelectIndex =
                      formik?.values?.ownersSelect.findIndex(
                        (owner) => owner?.label === row?.value
                      );
                    const isOwnerInList = formik?.values?.owners?.some(
                      (owner) => owner.label === row.value
                    );
                    const isOwnerAddress = row.label === "Owner Address";
                    const isOwnership = row.label === "Ownership Type";
                    const isCounty = row.label === "County";
                    const isPropertyAddress = row.label === "Property Address";
                    const isOwnerPhone = row.label === "ownerPhone";
                    const isOwner =
                      row.label.startsWith("Owner") &&
                      !row.label.includes("Address");
                    const hasOwners = formikOwnerIndex > -1;
                    const shouldDisplayRow =
                      (isCounty || isPropertyAddress) &&
                      !isOwnerPhone &&
                      (!isOwner || hasOwners);

                    if (isOwnerAddress && copyOwners?.length > 1) {
                      return (
                        <>
                          <TableRow
                            key={baseKey}
                            sx={{
                              display: "flex",
                              flexDirection: mobileSize ? "column" : "row",
                              alignItems: mobileSize ? "left" : "center",
                            }}
                          >
                            <TableCell
                              component="th"
                              scope="column"
                              sx={{
                                fontWeight: "bold",
                                minWidth: "10rem",
                                pb: mobileSize ? 0 : undefined,
                                px: mobileSize ? 0.5 : undefined,
                              }}
                            >
                              <Typography fontWeight="bold">
                                Select Owners
                              </Typography>
                            </TableCell>
                            <TableCell
                              sx={{
                                flexGrow: !mobileSize ? 1 : 0,
                                py: mobileSize ? 0 : undefined,
                              }}
                            >
                              <Select
                                disabled={isFieldsDisabled()}
                                multiple
                                fullWidth
                                name="fullOwnerSelect"
                                value={formik?.values?.fullOwnerSelect}
                                onChange={(event) => {
                                  const value = event.target.value;
                                  if (
                                    Array.isArray(value) &&
                                    value.length === 0 &&
                                    formik.values.fullOwnerSelect.length === 1
                                  ) {
                                    return;
                                  }
                                  formik.setFieldValue(
                                    "fullOwnerSelect",
                                    value
                                  );
                                }}
                                onBlur={formik.handleBlur}
                                error={Boolean(
                                  formik.touched.fullOwnerSelect &&
                                    formik.errors.fullOwnerSelect
                                )}
                              >
                                {fullOwners.map((item, i) => {
                                  return (
                                    <MenuItem key={`${item}-${i}`} value={item}>
                                      {item}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </TableCell>
                          </TableRow>
                        </>
                      );
                    } else {
                      return (
                        <TableRow
                          key={index + row.label}
                          sx={{
                            display: "flex",
                            flexDirection: mobileSize ? "column" : "row",
                            alignItems: mobileSize ? "left" : "center",
                          }}
                        >
                          <TableCell
                            component="th"
                            scope="column"
                            sx={{
                              fontWeight: "bold",
                              minWidth: "10rem",
                              pb: mobileSize ? 0 : undefined,
                              px: mobileSize ? 0.5 : undefined,
                            }}
                          >
                            <Typography fontWeight="bold">
                              {row.label === "Type" ||
                              row.label === "ownerPhone"
                                ? null
                                : isOwner &&
                                  formikOwnerIndex === -1 &&
                                  !isOwnership
                                ? null
                                : row.label === "Documents" && record
                                ? null
                                : row.label}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              flexGrow: !mobileSize ? 1 : 0,
                              py: mobileSize ? 0 : undefined,
                              px:
                                row.label.includes("Owner") &&
                                row.label !== "Owner Address" &&
                                mobileSize
                                  ? 0.5
                                  : undefined,
                            }}
                          >
                            {isOwnerAddress ? (
                              <Grid container>
                                {!tabletSize && <Grid item md={4}></Grid>}

                                <Grid item xs={12} lg={8}>
                                  <Typography
                                    align={mobileSize ? "left" : "right"}
                                  >
                                    {formik.values.ownerAddress}
                                  </Typography>
                                  {/* <TextField
                                    disabled={isFieldsDisabled()}
                                    error={Boolean(
                                      formik.touched.ownerAddress &&
                                        formik.errors.ownerAddress
                                    )}
                                    helperText={
                                      formik.touched.ownerAddress &&
                                      formik.errors.ownerAddress
                                    }
                                    name="ownerAddress"
                                    label="Owner Address"
                                    variant="filled"
                                    fullWidth
                                    onBlur={formik.handleBlur}
                                    value={formik.values.ownerAddress}
                                    onChange={(e) => {
                                      formik.handleChange(e);
                                    }}
                                  /> */}
                                </Grid>
                              </Grid>
                            ) : row.label.includes("Owner") && row.type ? (
                              <Grid container spacing={mobileSize ? 1 : 2}>
                                {propertyDetails?.affected_property?.map(
                                  (row, i) => {
                                    if (
                                      row.label.includes("Owner") &&
                                      row.type &&
                                      i == index
                                    ) {
                                      // Find the index of this owner in the formik state
                                      const formikIndex =
                                        formik.values.owners.findIndex(
                                          (owner) => owner.label === row.label
                                        );

                                      // Check if the formik state for this owner is populated
                                      const isOwnerPopulated =
                                        formikIndex !== -1;

                                      // If it's an 'Owner' row and we have an owner in formik state
                                      if (isOwnerPopulated) {
                                        return (
                                          <React.Fragment
                                            key={`${row.label}- INNER -${i}`}
                                          >
                                            <Grid item xs={12} sm={6}>
                                              {/* Display Owner Name */}
                                              <TextField
                                                disabled={isFieldsDisabled()}
                                                variant="filled"
                                                onBlur={formik.handleBlur}
                                                name={`owners[${formikIndex}].value.${formik.values.ownersSelect?.[formikIndex]?.value}`}
                                                value={
                                                  formik.values.owners[
                                                    formikIndex
                                                  ].value[
                                                    formik.values.ownersSelect[
                                                      formikIndex
                                                    ].value
                                                  ]
                                                }
                                                onChange={formik.handleChange}
                                                fullWidth
                                                label="Full Name"
                                                error={Boolean(
                                                  formik.touched?.owners?.[
                                                    formikIndex
                                                  ]?.value[
                                                    formik.values.ownersSelect[
                                                      formikIndex
                                                    ].value
                                                  ] &&
                                                    formik.errors?.owners?.[
                                                      formikIndex
                                                    ]?.value[
                                                      formik.values
                                                        .ownersSelect[
                                                        formikIndex
                                                      ].value
                                                    ]
                                                )}
                                                helperText={
                                                  formik.touched?.owners?.[
                                                    formikIndex
                                                  ]?.value[
                                                    formik.values.ownersSelect[
                                                      formikIndex
                                                    ].value
                                                  ] &&
                                                  formik.errors?.owners?.[
                                                    formikIndex
                                                  ]?.value[
                                                    formik.values.ownersSelect[
                                                      formikIndex
                                                    ].value
                                                  ]
                                                }
                                              />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                              <FormControl
                                                variant="filled"
                                                fullWidth
                                              >
                                                <InputLabel id="fullOwnerSelect-label">
                                                  Owner Type
                                                </InputLabel>
                                                <Select
                                                  fullWidth
                                                  disabled={isFieldsDisabled()}
                                                  name={`ownersSelect[${formikIndex}].value`}
                                                  value={
                                                    formik.values.ownersSelect[
                                                      formikIndex
                                                    ].value
                                                  }
                                                  onChange={(e) => {
                                                    handleChangeType(
                                                      e,
                                                      formik,
                                                      formik?.values
                                                        ?.ownersSelect?.[
                                                        formikIndex
                                                      ]?.label
                                                    );
                                                    formik.handleChange(e);
                                                  }}
                                                  onBlur={formik.handleBlur}
                                                  error={Boolean(
                                                    formik.touched
                                                      .ownersSelect?.[
                                                      formikIndex
                                                    ]?.value &&
                                                      formik.errors
                                                        .ownersSelect?.[
                                                        formikIndex
                                                      ]?.value
                                                  )}
                                                >
                                                  <MenuItem value="PERSON">
                                                    INDIVIDUAL
                                                  </MenuItem>
                                                  <MenuItem value="ORGANIZATION">
                                                    ORGANIZATION
                                                  </MenuItem>
                                                </Select>
                                              </FormControl>
                                            </Grid>
                                          </React.Fragment>
                                        );
                                      }
                                    }

                                    return null; // or appropriate fallback
                                  }
                                )}
                              </Grid>
                            ) : row.label === "Documents" &&
                              (!record || source === "external") ? (
                              <Typography
                                align={mobileSize ? "left" : "right"}
                                onClick={() => {
                                  setDocumentModal(true);
                                }}
                                sx={{
                                  color: "blue",
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                  "&:hover": {
                                    textDecoration: "none",
                                    color: "darkblue",
                                  },
                                }}
                              >
                                {row.value}
                              </Typography>
                            ) : (
                              shouldDisplayRow &&
                              row.value && (
                                // Default case for other rows
                                <Typography
                                  variant="body1"
                                  align={mobileSize ? "left" : "right"}
                                >
                                  {row.value}
                                </Typography>
                              )
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    }
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
        <Grid item xs={12} lg={4} mt={largeTabletSize ? 1 : 0}>
          <Typography variant="h6" align="center">
            Contractor
          </Typography>
          <Box
            sx={{
              bgcolor: "#fff",
              py: largeTabletSize ? 0.5 : 2,
              px: tabletSize ? 0 : 2,
              minHeight: "100px", // Adjust as needed
            }}
          >
            <TableContainer component={Paper}>
              <Table sx={{ border: 1, borderColor: "grey.300" }}>
                <TableBody>
                  {propertyDetails?.contractor?.map((row, index) => {
                    let licenseStatus = null;
                    if (
                      row.label === "License Status" &&
                      (row.value === "not_found" || row.value === "error")
                    ) {
                      licenseStatus = "Unverified";
                    }
                    return (
                      <TableRow
                        key={`${row.label}- TT -${index}`}
                        sx={{
                          display: "flex",
                          flexDirection: mobileSize ? "column" : "row",
                          alignItems: mobileSize ? "left" : "center",
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="column"
                          sx={{
                            fontWeight: "bold",
                            pl: mobileSize ? 0.5 : undefined,
                            pb: mobileSize ? 0 : undefined,
                          }}
                        >
                          {row.label}
                        </TableCell>
                        <TableCell
                          align={mobileSize ? "left" : "right"}
                          sx={{
                            color: licenseStatus ? "red" : "#000",
                            fontWeight: licenseStatus ? "bold" : "normal",
                            flexGrow: !mobileSize ? 1 : 0,
                            py: mobileSize ? 0 : undefined,
                          }}
                        >
                          {licenseStatus ? licenseStatus : row.value}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default TableOverview;
