import {
  setDocumentAccepted,
  setInstantRonStop,
  setInstantRonAccepted,
  setInstantRonSuccess,
  updateSignerRonDate,
  completedNotaryRecordGuestSignal,
  getNotarizationStatus,
  updateGuestManageData,
} from "../guestActions";

export const guestSessionOnMessage =
  (receivedData) => async (dispatch, getState) => {
    switch (receivedData.event) {
      case "instant_ron_accepted":
        const ronAcceptedUpdate = receivedData.data;
        if (ronAcceptedUpdate.success === true) {
          dispatch(setInstantRonAccepted());
          break;
        } else if (ronAcceptedUpdate.success === "stop") {
          dispatch(setInstantRonStop());
          break;
        } else if (ronAcceptedUpdate.success === "success") {
          dispatch(setInstantRonSuccess());
          break;
        }
        break;
      case "document_accepted":
        dispatch(setDocumentAccepted(receivedData.data?.success));
        break;
      case "update_signee_manage_data":
        dispatch(updateGuestManageData(receivedData?.data));
        break;
      case "update_signer_appointment":
        dispatch(updateSignerRonDate(receivedData.data.new_appointment));
        break;
      case "completed_notaryRecord_session":
        dispatch(
          completedNotaryRecordGuestSignal(receivedData.data.completed_iso)
        );
        break;
      case "notarization_session_status":
        dispatch(getNotarizationStatus(receivedData?.data?.finished));
        break;
      default:
        return;
    }
  };
