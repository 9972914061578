import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { DateTime } from "luxon";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { green } from "@mui/material/colors";

import { convertTimezoneFromObj } from "../../../functions/helperFunctions";
import { useSelector } from "react-redux";

const ContractorSignerTab = (props) => {
  const currentSession = useSelector((state) => state.sessions.currentSession);
  const currentUser = useSelector((state) => state[currentSession].user);
  const [ronDate, setRonDate] = useState("");

  const { contractor } = props?.selectedRow?.processing_permit?.notarization;
  const { owner } = props?.selectedRow?.processing_permit?.notarization;
  const { signing_order } = props?.selectedRow?.processing_permit?.notarization;
  const { notarization } = props?.selectedRow?.processing_permit;

  const isRonScheduled = () => {
    const notarization =
      props?.selectedRow?.processing_permit?.notarization?.contractor
        ?.notarization;

    if (
      notarization?.appointments?.booked?.length > 0 &&
      notarization?.has_notary === true &&
      notarization?.is_notarized === false &&
      notarization?.waiting_to_schedule === false
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    const notarization =
      props?.selectedRow?.processing_permit?.notarization?.contractor
        ?.notarization;
    if (isRonScheduled()) {
      const { year, month, day_of_month, time, timezone } =
        notarization?.appointments?.booked[0];
      const userZone = currentUser?.subuser
        ? currentUser?.subuser?.timezone
        : currentUser?.user?.personal?.[0]?.timezone;

      const dateString = convertTimezoneFromObj(
        notarization?.appointments?.booked[0],
        userZone || "America/New_York",
        "cccc, LLLL dd, yyyy hh:mm a"
      );

      setRonDate(dateString);

      // Convert isoString to Date object
      const ronnDate = DateTime.utc(
        year,
        month,
        day_of_month,
        parseInt(time?.split(":")[0]),
        parseInt(time?.split(":")[1])
      );

      const checkTimeDifference = () => {
        // Get the current time in user's timezone
        const nowUTC = DateTime.utc();

        // Calculate the difference in milliseconds
        const timeDifference = ronnDate - nowUTC;

        // Check if the difference is less than 5 minutes (300,000 milliseconds)
        if (timeDifference <= 600000) {
          props?.setFiveMinBeforeSession(true);
        } else {
          props?.setFiveMinBeforeSession(false);
        }
      };
      // Perform the initial check
      checkTimeDifference();

      const intervalId = setInterval(checkTimeDifference, 30000);

      // Clear the interval when the component unmounts or dependencies change
      return () => clearInterval(intervalId);
    } else {
      setRonDate("N/A");
      props?.setFiveMinBeforeSession(false);
    }
  }, [
    props?.selectedRow,
    currentUser?.user?.personal?.[0]?.timezone,
    currentUser?.subuser?.timezone,
  ]);

  return (
    <Box>
      {signing_order === "owner" && !owner?.notarization?.is_notarized ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Typography variant="body2" color="primary" fontWeight="bold">
              Please wait for the owner's/agent's notarization/signing to
              complete.
            </Typography>
          </Box>
        </>
      ) : contractor?.notarization?.waiting_to_schedule &&
        !contractor?.notarization?.only_sign ? (
        <Box mt={3} align="center">
          <Button
            onClick={() => {
              props?.setRonScheduleOpen(true);
            }}
            size="small"
            variant="contained"
          >
            Schedule your RON Session
          </Button>
        </Box>
      ) : contractor?.notarization?.only_sign &&
        !contractor?.notarization?.is_signed ? (
        <Box mt={3} align="center">
          <Typography variant="h6" px={1} color="primary" mb={2}>
            Your documents are ready and only require your signature
          </Typography>
          <Button
            onClick={() => {
              window.open(
                contractor?.notarization?.bn_session?.signer_url,
                "_blank"
              );
            }}
            size="small"
            variant="contained"
          >
            Sign Your Documents
          </Button>
        </Box>
      ) : isRonScheduled() ? (
        <>
          <Typography
            align="center"
            variant="h6"
            color={props?.fiveMinBeforeSession ? "error" : "primary"}
          >
            {props?.fiveMinBeforeSession
              ? "RON Session is Started!"
              : "RON Session is Scheduled at:"}
          </Typography>
          <Typography
            align="center"
            variant="body2"
            fontWeight="bold"
            color="secondary"
          >
            {ronDate}
          </Typography>
          <Box align="center" mt={2}>
            {props?.fiveMinBeforeSession ? (
              <Button
                variant="contained"
                onClick={() => {
                  window.open(
                    props?.selectedRow?.processing_permit?.notarization
                      ?.contractor?.notarization?.bn_session?.signer_url,
                    "_blank"
                  );
                }}
              >
                Join Session
              </Button>
            ) : (
              <Typography px={2}>
                It is recommended that you join the session five minutes before
                it starts in order to allow enough time for biometric
                authentication.
              </Typography>
            )}
          </Box>
        </>
      ) : contractor?.notarization?.is_notarized ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h6"
              color="primary"
              align="center"
              fontWeight="bold"
              px={1}
              mt={1}
            >
              {!contractor?.notarization?.is_notarized
                ? "Notarization is in progress"
                : "Notarization is completed"}
            </Typography>
            {contractor?.notarization?.is_notarized && (
              <CheckCircleIcon
                style={{
                  color: green[500],
                  paddingTop: 4,
                  fontSize: "30px",
                }}
              />
            )}
          </Box>
          <Typography
            px={2}
            mt={2}
            align="center"
            variant="body2"
            fontWeight="bold"
            color="primary"
          >
            {!notarization?.finalized
              ? "Your documents have been notarized successfully. In order to access your final documents, please wait until the owner has completed the RON session as well."
              : "The notarization of your documents has been completed. "}
          </Typography>
        </>
      ) : contractor?.notarization?.only_sign &&
        contractor?.notarization?.is_signed ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h6"
              color="primary"
              align="center"
              fontWeight="bold"
              px={1}
              mt={1}
            >
              Signed Documents!
            </Typography>

            <CheckCircleIcon
              style={{
                color: green[500],
                paddingTop: 4,
                fontSize: "30px",
              }}
            />
          </Box>
          <Typography
            px={2}
            mt={2}
            align="center"
            variant="body2"
            fontWeight="bold"
            color="primary"
          >
            Your documents have been signed successfully. In order to access
            your final documents, please wait until the owner has completed the
            RON session as well.
          </Typography>
        </>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default ContractorSignerTab;
