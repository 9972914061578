import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error"; // Optional for error state
import { green, grey, red } from "@mui/material/colors";
import { Box } from "@mui/material";

function StatusIcon({ status }) {
  const color = {
    completed: green[500], // Filled with green when completed
    inProgress: grey[500], // Filled with gray when in progress
    failed: red[500], // Filled with red when failed
  };

  return (
    <Box>
      {status === "completed" && (
        <CheckCircleIcon style={{ color: color.completed }} />
      )}
      {status === "inProgress" && (
        <CheckCircleIcon style={{ color: color.inProgress }} />
      )}
      {status === "failed" && <ErrorIcon style={{ color: color.failed }} />}
    </Box>
  );
}

export default StatusIcon;
