import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import { cityScraperList } from "../../../data/cityScraperList";
import { useEffect } from "react";
import { formatPermitNumber } from "../../../functions/helperFunctions";

const CitySiteTab = (props) => {
  useEffect(() => {}, [props?.selectedRow?.city_scraper]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          mt: 1,
        }}
      >
        <img
          src="/img/website_img/city_img.png"
          style={{
            width: "100%",
            maxWidth: "5rem",
            height: "auto",
            borderRadius: "20px",
          }}
        />
        <Typography align="center" px={1} variant="h5" mt={1} color="primary">
          City Permitting
        </Typography>

        {!cityScraperList.includes(props?.selectedRow?.city) ? (
          <Box mt={6}>
            <Typography align="center" px={2} variant="h6" color="primary">
              Unfortunately, we do not currently support permitting automation
              for this city. Please check back later for future updates.
            </Typography>
          </Box>
        ) : cityScraperList.includes(props?.selectedRow?.city) &&
          !props?.selectedRow?.city_scraper?.permit_number ? (
          <Box mt={6}>
            <Typography align="center" px={2} variant="h6" color="primary">
              Permit creation requests have not yet been initiated.
            </Typography>
          </Box>
        ) : cityScraperList.includes(props?.selectedRow?.city) &&
          props?.selectedRow?.city_scraper?.permit_number ? (
          <>
            <Typography
              align="center"
              px={1}
              variant="body2"
              fontWeight="bold"
              color="primary"
            >
              {props?.selectedRow?.permit_number}
            </Typography>
            <Typography
              mt={1}
              px={2}
              align="center"
              variant="body2"
              sx={{ color: "#10b981", fontWeight: "bold" }}
            >
              {props?.selectedRow?.city_scraper?.description}
            </Typography>
            <Button
              variant="contained"
              sx={{ mt: 3 }}
              size="small"
              color="success"
              href={props?.selectedRow?.city_scraper?.link}
              target="_blank"
            >
              Visit City Permitting Application
            </Button>
          </>
        ) : null}
      </Box>
    </>
  );
};

export default CitySiteTab;
