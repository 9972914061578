import React from "react";
import { Snackbar, Alert, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import { setExpiredSession } from "../../store/actions/sessionsActions";

const NotificationCard = ({ open, setOpen }) => {
  // const dispatch = useDispatch();

  const closeSessionAlert = () => {
    // dispatch(setExpiredSession(null));
    setOpen(false);
  };
  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      sx={{
        transform: open ? "translateY(0)" : "translateY(-100%)",
        transition: "transform 0.5s ease-in-out",
      }}
    >
      <Alert
        severity="error"
        sx={{
          width: "100%",
          backgroundColor: "red",
          color: "white",
          fontWeight: "bold",
          "& .MuiAlert-icon": {
            // Target the icon within the Alert

            color: "#fff",
          },
        }}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={closeSessionAlert}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      >
        Your session has expired!
      </Alert>
    </Snackbar>
  );
};

export default NotificationCard;
