import { Box, Grid, styled, Switch, Typography, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import PricingCard from "../components/auth/registerSteps/PricingCard";
import TermsAndConditionsModal from "../components/modals/TermsAndConditionsModal";

const ChangePlan = ({
  checked,
  setChecked,
  defPlan,
  selectedPlan,
  setSelectedPlan,
  promoCodeName,
  tiers,
  plans,
  contractorList,
  mobileSize,
  tabletSize,
  trial,
  currentUser,
}) => {
  const [showAlert, setShowAlert] = useState(false);
  const [isFadingOut, setIsFadingOut] = useState(false);

  const handleDisable = (tierTitle) => {
    const checkedSatus = defPlan.interval === "month" ? false : true;

    if (
      tierTitle === defPlan.title &&
      checkedSatus === checked &&
      !trial?.has_trial
    ) {
      return true;
    }
  };

  useEffect(() => {
    if (showAlert) {
      // First timeout to start the fade-out effect after 4500ms
      const timeout = setTimeout(() => {
        setIsFadingOut(true); // Start fading out

        // Second timeout to hide the alert and reset isFadingOut after the fade-out duration
        const fadeOutTimeout = setTimeout(() => {
          setShowAlert(false); // Hide alert after fade-out completes
          setIsFadingOut(false); // Reset fading state
        }, 1000); // This duration should match the CSS fade-out duration

        return () => clearTimeout(fadeOutTimeout);
      }, 3500); // Show the alert for 5 seconds minus fade duration

      return () => clearTimeout(timeout);
    }
  }, [showAlert]);

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 38,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 20,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));

  return (
    <>
      <Box sx={{ height: "100%" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              mt={0}
              color="primary"
              align="center"
              variant={mobileSize ? "h6" : tabletSize ? "h5" : "h3"}
            >
              Choose your new plan
            </Typography>
          </Grid>
        </Grid>
        <Stack
          sx={{ pb: 2, mt: 1 }}
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            variant={mobileSize ? "body2" : "body1"}
            sx={!checked ? { fontWeight: "bold" } : { fontWeight: "normal" }}
          >
            Monthly
          </Typography>
          <AntSwitch
            checked={checked}
            onChange={(e) => {
              setChecked(e.target.checked);
              setSelectedPlan(null);
            }}
            inputProps={{ "aria-label": "ant design" }}
          />
          <Typography
            variant={mobileSize ? "body2" : "body1"}
            sx={
              checked
                ? { fontWeight: "bold", color: "#1890ff" }
                : { fontWeight: "normal" }
            }
          >
            Annual (get 2 free months)
          </Typography>
        </Stack>
        <Grid container spacing={2} mt={0}>
          {tiers?.map(
            (plan, index) =>
              plan?.title && (
                <Grid
                  item
                  sm={3}
                  xs={12}
                  key={index}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    position: "relative",
                  }}
                >
                  <PricingCard
                    selectedPlan={selectedPlan}
                    setSelectedPlan={setSelectedPlan}
                    planOptions={plan.features}
                    title={plan.title}
                    price={checked ? plan.yearly_price : plan.monthly_price}
                    checked={checked}
                    plan={plan}
                    trial={trial}
                    contractorList={contractorList}
                    showAlert={showAlert}
                    setShowAlert={setShowAlert}
                    isFadingOut={isFadingOut}
                    type="change"
                    handleDisable={handleDisable}
                    currentUser={currentUser}
                  />
                </Grid>
              )
          )}
        </Grid>
        {/* <TermsAndConditionsModal
          open={termsOpen}
          onClose={() => setTermsOpen(false)}
        /> */}
      </Box>
    </>
  );
};

export default ChangePlan;
