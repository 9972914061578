import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./store/store";
import "./theme/global.css";
import { createTheme } from "./theme";
import { ThemeProvider } from "@mui/material/styles";
const theme = createTheme();

// const script = document.createElement("script");
// script.src = `${process.env.REACT_APP_APISERVICEURL}/${process.env.REACT_APP_APIVERSION}/client/scripts/require.js`;
// script.async = true;
// document.head.appendChild(script);

const root = createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </BrowserRouter>
  </Provider>
  // </React.StrictMode>
);

reportWebVitals();
