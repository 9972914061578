// src/utils/loadComponent.js
const globalComponents = {
  energy: "CreatingEnergyForm",
  noc: "CreatingNocForm",
  someOtherGlobal: "CreatingSomeOtherGlobalForm",
  anotherGlobal: "CreatingAnotherGlobalForm",
};

const countySpecificComponents = {
  //   Duval: {
  //     building_permit: "CreatingCountySpecificForm",
  //     pais: "CreatingCountySpecificPaisForm",
  //     // Add more county-specific forms here
  //   },
  // Add more counties here
};

const preprocessName = (name) => {
  if (!name) return ""; // Handle cases where name might be undefined or null
  return name.toLowerCase().replace(/\s+/g, "_");
};

export const loadComponent = async (creatingDoc, county, city) => {
  // Preprocess county and city names
  const processedCounty = preprocessName(county);
  const processedCity = preprocessName(city);
  // Check if the document is a global component
  if (globalComponents[creatingDoc]) {
    return await import(
      `../components/permitForms/Global/${globalComponents[creatingDoc]}`
    );
  }

  // Check if the document is a county-specific component
  if (Object.keys(countySpecificComponents).length > 0) {
    if (
      countySpecificComponents[processedCounty] &&
      countySpecificComponents[processedCounty][creatingDoc]
    ) {
      return await import(
        `../components/permitForms/CountySpecific/${processedCounty}/${countySpecificComponents[processedCounty][creatingDoc]}`
      );
    }
  }

  // Otherwise, assume it's county/city-specific
  if (processedCity) {
    switch (creatingDoc) {
      case "building_permit":
        return await import(
          `../components/permitForms/BuildingPermit/${processedCounty}/${processedCity}/CreatingBuildingForm`
        );
      case "pais":
        return await import(
          `../components/permitForms/PAIS/${processedCounty}/${processedCity}/CreatingPaisForm`
        );
      case "obtain_permit":
        return await import(
          `../components/permitForms/ObtainPermit/${processedCounty}/${processedCity}/CreatingPermissionForm`
        );
      case "asbestos":
        return await import(
          `../components/permitForms/Asbestos/${processedCounty}/${processedCity}/CreatingAsbestosForm`
        );
      case "ownership":
        return await import(
          `../components/permitForms/Ownership/${processedCounty}/${processedCity}/CreatingOwnershipForm`
        );
      case "roofing":
        return await import(
          `../components/permitForms/Roofing/${processedCounty}/${processedCity}/CreatingRoofingForm`
        );
      default:
        return null;
    }
  }

  return null;
};
