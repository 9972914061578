import { useState, useEffect } from "react";
import { Typography, Switch, styled, Stack, Box, Grid } from "@mui/material";
import TermsAndConditionsModal from "../../modals/TermsAndConditionsModal";
import PricingCard from "./PricingCard";

const ChoosePlan = ({
  tiers,
  isChangePlan,
  checked,
  setChecked,
  plans,
  promoCodeName,
  termsOpen,
  setTermsOpen,
  setSelectedPlan,
  selectedPlan,
  contractorList,
  mobileSize,
  tabletSize,
}) => {
  const [showAlert, setShowAlert] = useState(false);
  const [isFadingOut, setIsFadingOut] = useState(false);

  useEffect(() => {
    if (showAlert) {
      // First timeout to start the fade-out effect after 4500ms
      const timeout = setTimeout(() => {
        setIsFadingOut(true); // Start fading out

        // Second timeout to hide the alert and reset isFadingOut after the fade-out duration
        const fadeOutTimeout = setTimeout(() => {
          setShowAlert(false); // Hide alert after fade-out completes
          setIsFadingOut(false); // Reset fading state
        }, 1000); // This duration should match the CSS fade-out duration

        return () => clearTimeout(fadeOutTimeout);
      }, 3500); // Show the alert for 5 seconds minus fade duration

      return () => clearTimeout(timeout);
    }
  }, [showAlert]);

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 38,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 20,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));
  const plansWithPrices = tiers
    .filter(
      (plan) => plans.includes(plan.title) || plan.title === promoCodeName
    )
    .map((plan) => {
      const featuresWithPrices = plan.features.map((feature) => {
        // Find a matching service for this feature
        const service = tiers.find(
          (s) => s.type === plan.title && s.service === feature
        );
        let featureWithPrice = feature;

        // Prepend price to feature name if a matching service is found
        if (service) {
          featureWithPrice = `$${service.price} ${feature}`;
        }

        // Special handling for specific features
        if (feature.includes("Notices of Commencement")) {
          featureWithPrice = `${plan.noc} ${featureWithPrice}`;
        } else if (feature.includes("Counties")) {
          featureWithPrice = `${plan.county} ${featureWithPrice}`;
        } else if (
          feature.includes("Contractor licenses") ||
          feature.includes("Contractor license")
        ) {
          featureWithPrice = `${plan.license} ${featureWithPrice}`;
        } else if (feature.includes("Additional users")) {
          featureWithPrice = `${plan.subuser} ${featureWithPrice}`;
        }

        return featureWithPrice;
      });

      return {
        ...plan,
        features: featuresWithPrices,
      };
    });

  return (
    <>
      <Box sx={{ height: "100%" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {!mobileSize && (
              <Typography mt={2} color="#333333" align="center" variant="h5">
                Choose your plan
              </Typography>
            )}
          </Grid>
        </Grid>
        <Stack
          sx={isChangePlan ? { pb: 2 } : { mt: 1, mb: 0 }}
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            variant="body1"
            sx={!checked ? { fontWeight: "bold" } : { fontWeight: "normal" }}
          >
            Monthly
          </Typography>
          <AntSwitch
            checked={checked}
            onChange={(e) => {
              setChecked(e.target.checked);
            }}
            inputProps={{ "aria-label": "ant design" }}
          />
          <Typography
            variant="body1"
            sx={
              checked
                ? { fontWeight: "bold", color: "#1890ff" }
                : { fontWeight: "normal" }
            }
          >
            Annual (get 2 free months)
          </Typography>
        </Stack>
        <Grid container spacing={2} mt={1} mb={5}>
          {tiers.map((plan, index) => (
            <Grid
              item
              md={6}
              lg={3}
              sm={12}
              xs={12}
              key={index}
              sx={{
                display: "flex",
                justifyContent: "center",
                position: "relative",
              }}
            >
              <PricingCard
                promoCodeName={promoCodeName}
                selectedPlan={selectedPlan}
                setSelectedPlan={setSelectedPlan}
                planOptions={plan.features}
                title={plan.title}
                price={checked ? plan.yearly_price : plan.monthly_price}
                checked={checked}
                plan={plan}
                contractorList={contractorList}
                showAlert={showAlert}
                setShowAlert={setShowAlert}
                isFadingOut={isFadingOut}
                mobileSize={mobileSize}
              />
            </Grid>
          ))}
        </Grid>
        <TermsAndConditionsModal
          open={termsOpen}
          onClose={() => setTermsOpen(false)}
        />
      </Box>
    </>
  );
};

export default ChoosePlan;
