import React from "react";
import Lottie from "react-lottie";
import animationData from "./waiting_notarise.json";
import completedAnimationData from "./completed.json";
import failedAnimationData from "./failed.json";

export const DocumentsHubWaiting = ({ width = 200, height = 200 }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div>
      <Lottie
        options={defaultOptions}
        height={height}
        width={width}
        isStopped={false}
        isPaused={false}
      />
    </div>
  );
};

export const DocumentsHubCompleted = ({ width = 200, height = 200 }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: completedAnimationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div>
      <Lottie
        options={defaultOptions}
        height={height}
        width={width}
        isStopped={false}
        isPaused={false}
      />
    </div>
  );
};

export const DocumentsHubFailed = ({ width = 200, height = 200 }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: failedAnimationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div>
      <Lottie
        options={defaultOptions}
        height={height}
        width={width}
        isStopped={false}
        isPaused={false}
      />
    </div>
  );
};
