import { LoadingButton } from "@mui/lab";
import { useSelector } from "react-redux";
import { Modal, Typography, Button, Box, Grid } from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "55%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const DefaultModal = ({
  openDefaultModal,
  setOpenDefaultModal,
  setOpenDrawer,
  cancelDefaultLoading,
  setCancelDefaultLoading,
  selectedPreName,
}) => {
  const currentSession = useSelector((state) => state.sessions.currentSession);
  const navigate = useNavigate();
  const handleDefaultUpdate = async () => {
    try {
      setCancelDefaultLoading(true);
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/data/update-selected-counties`,
        { form: selectedPreName, currentSession: currentSession },
        { withCredentials: true }
      );

      setCancelDefaultLoading(false);
      setOpenDrawer(true);
      setOpenDefaultModal(false);
    } catch (e) {
      setCancelDefaultLoading(false);
    }
  };

  return (
    <>
      <Modal
        open={openDefaultModal}
        onClose={() => {
          setOpenDefaultModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            color="primary"
            align="center"
            variant="h4"
            component="h2"
          >
            Update Default Values
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            This county was initiated by someone else in your organization.
            Would you like to view and/or modify the default inputs for "General
            Description of Improvements" and/or "Owner's Interest in Site of the
            Improvement" for this County?
          </Typography>
          <Grid container spacing={2} mt={5}>
            <Grid item sm={6} xs={12}>
              <LoadingButton
                loading={cancelDefaultLoading}
                onClick={handleDefaultUpdate}
                fullWidth
                color="error"
                variant="outlined"
              >
                Continue Without Updating
              </LoadingButton>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Button
                onClick={() => {
                  navigate("/defaults", {
                    state: { option: selectedPreName },
                  });
                }}
                variant="outlined"
                fullWidth
              >
                Update Default Values
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default DefaultModal;
