import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useFormikContext } from "formik";

const MobileDocumentDetails = (props) => {
  const {
    values,
    setFieldValue,
    resetForm,
    touched,
    errors,
    handleChange,
    handleBlur,
  } = useFormikContext();

  const handlePhoneInputChange = (event) => {
    const { value, name } = event.target;

    // Existing code for phone number formatting
    const digitsOnly = value.replace(/\D/g, ""); // Remove non-numeric characters
    let formattedPhoneNumber = "";

    // Add dashes after 3rd and 6th digits
    for (let i = 0; i < digitsOnly.length; i++) {
      if (i === 3 || i === 6) {
        formattedPhoneNumber += "-";
      }
      formattedPhoneNumber += digitsOnly[i];
    }

    setFieldValue("signeePhone", formattedPhoneNumber);
  };

  return (
    <Box sx={{ p: 1, mx: 1 }}>
      <Box>
        <Typography color="primary" align="center" variant="h6">
          {values.fileName}
        </Typography>
      </Box>
      <Box mt={1} sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        {/* Contractor who will carry on the work */}
        <Box>
          <FormControl
            variant="filled"
            sx={{ mt: 0.5 }}
            fullWidth
            error={Boolean(touched.contractor && errors.contractor)}
          >
            <InputLabel
              sx={
                touched.contractor && errors.contractor
                  ? { color: "#f04438" }
                  : { color: "auto" }
              }
              id="contractor-label"
            >
              Contractor who will carry on the work
            </InputLabel>
            <Select
              size="small"
              error={Boolean(touched.contractor && errors.contractor)}
              labelId="contractor-label"
              id="contractor"
              fullWidth
              name="contractor"
              value={values?.contractor}
              onChange={handleChange}
              onBlur={handleBlur}
              MenuProps={{
                PaperProps: {
                  style: {
                    marginTop: "1.5px",
                    maxHeight: 170, // Set the desired max height for the dropdown menu
                    width: "auto",
                    maxWidth: "100%",
                  },
                },
              }}
              sx={{
                maxWidth: "100%", // Set the desired max-width for the input element
                "& .MuiTypography-body2": {
                  whiteSpace: "wrap", // Prevent text from wrapping or shrinking
                },
              }}
            >
              {props?.contractors?.map((item, index) => (
                <MenuItem key={index} value={item.licenseNumber}>
                  {item.licenseType} - {item.licenseNumber}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText sx={{ color: "#f04438" }}>
              {touched.contractor && errors.contractor ? errors.contractor : ""}
            </FormHelperText>
          </FormControl>
        </Box>
        {/* *************** */}
        {/* property Address */}
        <Box>
          <TextField
            size="small"
            error={Boolean(touched.propertyAddress && errors.propertyAddress)}
            helperText={touched.propertyAddress && errors.propertyAddress}
            name="propertyAddress"
            label="Property Address"
            variant="filled"
            fullWidth
            onBlur={handleBlur}
            value={values.propertyAddress}
            onChange={handleChange}
          />
        </Box>
        {/* *************** */}
        {/* ******* SIGNEE ******** */}
        {props?.iphone12Height && (
          <Typography variant="h6" mt={2} align="center">
            Signee: Property Owner or Agent
          </Typography>
        )}

        <Box>
          <TextField
            error={Boolean(touched.signeeFirst && errors.signeeFirst)}
            helperText={touched.signeeFirst && errors.signeeFirst}
            name="signeeFirst"
            label={
              values.reason === "Recording"
                ? "Owner First Name"
                : "Signee First Name"
            }
            variant="filled"
            fullWidth
            onBlur={handleBlur}
            value={values.signeeFirst}
            onChange={handleChange}
          />
        </Box>
        <Box>
          <TextField
            error={Boolean(touched.signeeLast && errors.signeeLast)}
            helperText={touched.signeeLast && errors.signeeLast}
            name="signeeLast"
            label={
              values.reason === "Recording"
                ? "Owner Last Name"
                : "Signee Last Name"
            }
            variant="filled"
            fullWidth
            onBlur={handleBlur}
            value={values.signeeLast}
            onChange={handleChange}
          />
        </Box>
        <Box>
          <TextField
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
            name="email"
            label={
              values.reason === "Recording"
                ? "Owner Email Address"
                : "Signee Email Address"
            }
            variant="filled"
            fullWidth
            onBlur={handleBlur}
            value={values.email}
            onChange={handleChange}
          />
        </Box>
        <Box>
          <TextField
            error={Boolean(touched.signeePhone && errors.signeePhone)}
            helperText={touched.signeePhone && errors.signeePhone}
            name="signeePhone"
            label={
              values.reason === "Recording"
                ? "Owner Phone Number"
                : "Signee Phone Number"
            }
            variant="filled"
            fullWidth
            onBlur={handleBlur}
            value={values.signeePhone}
            onKeyUp={handlePhoneInputChange}
            onChange={handleChange}
          />
        </Box>
        <Box>
          <FormHelperText sx={{ color: "#F0463A" }}>
            {props?.contractors?.length === 0
              ? "Contractors are not currently available"
              : ""}
          </FormHelperText>
        </Box>
        {/* *************** */}
      </Box>
    </Box>
  );
};

export default MobileDocumentDetails;
