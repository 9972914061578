import { Box, Typography } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";

const RecordingTab = (props) => {
  const row = props?.selectedRow;
  const { processing_permit } = row;
  const isEmptyObject = (obj) => {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          mt: 1,
        }}
      >
        <img
          src="/img/website_img/record.png"
          style={{
            width: "100%",
            maxWidth: "5rem",
            height: "auto",
            borderRadius: "20px",
          }}
        />
        {!processing_permit ||
        Object.keys(processing_permit).length === 0 ||
        (!processing_permit?.recording?.is_recorded &&
          !processing_permit?.recording?.in_progress &&
          (!processing_permit?.recording?.failure ||
            processing_permit?.recording?.failure.length === 0)) ? (
          <Box mt={6}>
            <Typography align="center" px={1} variant="h6" color="primary">
              Recording requests have not yet been initiated
            </Typography>
          </Box>
        ) : (
          <>
            <Typography mt={1} fontWeight="bold">
              {processing_permit?.businessName}
            </Typography>
            <Typography mt={-0.5} fontWeight="bold">
              {processing_permit?.licenseType}
            </Typography>
            <Typography mt={-0.5} fontWeight="bold">
              {processing_permit?.licenseNumber}
            </Typography>
            <Box mt={6}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 0.5,
                  mb: 0.5,
                }}
              >
                <LocationOnIcon />
                <Typography>{processing_permit?.mainAddress}</Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 0.5,
                  mb: 0.5,
                }}
              >
                <LocationOnIcon />
                <Typography>{processing_permit?.mainAddressCounty}</Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 0.5,
                  mb: 0.5,
                }}
              >
                <EmailIcon />
                <Typography>{processing_permit?.email}</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 0.5,
                  mb: 0.5,
                }}
              >
                <PhoneIcon />
                <Typography>{processing_permit?.mobile}</Typography>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default RecordingTab;
