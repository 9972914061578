const initialState = {
  registerData: null,
  manageData: null,
  instantRonAccepted: false,
  documentAccepted: null,
  signerRonDate: null,
  notaryRecordCompleted: false,
  notaryRecordCompletedTime: null,
  notarizationCompleted: null,
};

const guestSessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GUEST_REGISTER_DATA": {
      return {
        ...state,
        registerData: action.payload,
      };
    }
    case "GUEST_MANAGE_DATA": {
      return {
        ...state,
        manageData: action.payload,
      };
    }
    case "SET_INSTANT_RON_ACCEPTED":
      return { ...state, instantRonAccepted: true };
    case "SET_INSTANT_RON_INITIAL_STATE":
      return { ...state, instantRonAccepted: false };
    case "SET_INSTANT_RON_STOP":
      return { ...state, instantRonAccepted: "stop" };
    case "SET_INSTANT_RON_SUCCESS":
      return { ...state, instantRonAccepted: "success" };
    case "SET_DOCUMENT_ACCEPTED":
      return { ...state, documentAccepted: action.payload };
    case "SET_DOCUMENT_ACCEPTED_INITIAL_STATE":
      return { ...state, documentAccepted: null };
    case "UPDATE_SIGNER_RON_DATE":
      return { ...state, signerRonDate: action.payload };
    case "SIGNER_RON_DATE_SET_INITIAL":
      return { ...state, signerRonDate: null };
    case "COMPLETED_NOTARY_RECORD_GUEST_SIGNAL":
      return {
        ...state,
        notaryRecordCompleted: true,
        notaryRecordCompletedTime: action.payload,
      };
    case "NOTARIZATION_COMPLETED_STATUS_SIGNAL":
      return {
        ...state,
        notarizationCompleted: action.payload,
      };
    case "NOTARIZATION_COMPLETED_INITIAL":
      return {
        ...state,
        notarizationCompleted: null,
      };
    default:
      return state;
  }
};

export default guestSessionReducer;
