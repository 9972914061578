import React, { useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  IconButton,
  Dialog,
  DialogContent,
  Tooltip,
  Typography,
  DialogTitle,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import RefreshIcon from "@mui/icons-material/Refresh";

export default function ListItemWithPopup({
  primary,
  secondary,
  imageUrl,
  step,
  primarySx,
  finishedStep,
  fetching,
  handleFetch,
}) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ListItem>
      <ListItemText
        sx={primarySx}
        primary={step + "." + " " + primary}
        secondary={secondary}
      />
      {/* Conditional rendering based on finishedStep */}
      {step !== "5" && step !== "6" ? (
        finishedStep ? (
          // For finished steps, show disabled icon with "Completed" tooltip
          <Tooltip sx={{ ml: 2 }} title="Completed">
            <HelpOutlineIcon sx={{ color: "#9e9e9e", mr: 1, mt: 1 }} />
          </Tooltip>
        ) : (
          // For unfinished steps, show interactive icon with "More Info" tooltip
          <Tooltip sx={{ ml: 2 }} title="More Info">
            <IconButton onClick={handleClickOpen}>
              <HelpOutlineIcon color="primary" />
            </IconButton>
          </Tooltip>
        )
      ) : (
        step === "6" && (
          // This handles the case for step "5", adjust as needed

          <Tooltip sx={{ ml: 2 }} title="Refresh">
            <IconButton
              sx={{ mr: 1, mb: 2 }}
              className={fetching ? "rotate" : ""}
              color="primary"
              onClick={handleFetch}
            >
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        )
      )}

      <Dialog open={open} onClose={handleClose} maxWidth="lg">
        <DialogTitle sx={{ fontSize: "24px", textAlign: "center" }}>
          Step {step}
        </DialogTitle>
        <Typography align="center">{primary}</Typography>
        <DialogContent>
          <img src={imageUrl} alt="Info" style={{ maxWidth: "100%" }} />
        </DialogContent>
      </Dialog>
    </ListItem>
  );
}
