import {
  Card,
  CardHeader,
  CardContent,
  Tooltip,
  IconButton,
  Box,
  Drawer,
  useMediaQuery,
  useTheme,
  Modal,
  Backdrop,
  Fade,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Grid,
  Container,
} from "@mui/material";
import UserNOCUsage from "./UserNOCUsage";
import AvailableUserNoc from "./AvailableUserNoc";
import ModeEditSharpIcon from "@mui/icons-material/ModeEditSharp";
import DeleteSharpIcon from "@mui/icons-material/DeleteSharp";
import EditCustomer from "../admin/EditCustomer";
import { DataGrid } from "@mui/x-data-grid";
import { disconnectWebsocket } from "../../store/actions/websocketActions";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState, useRef } from "react";

const CustomersGrid = ({ title }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentSession = useSelector((state) => state.sessions.currentSession);
  const currentUser = useSelector((state) => state[currentSession].user);
  const [customersDataRows, setCustomersDataRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [dataLoading, setDataLoading] = useState(true);
  const [selectedUser, setSelectedUser] = useState();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [open, setOpen] = useState(false);
  const [confirm, setConfirm] = useState("");
  const [errorText, setErrorText] = useState("");
  const handleClose = () => setOpen(false);
  const theme = useTheme();
  const issm = useMediaQuery(theme.breakpoints.up("md"));
  const [year, setYear] = useState(String(new Date().getFullYear()));
  let userCount = 0;

  const dispatchCount = useRef(0);

  useEffect(() => {
    if (currentUser) {
      if (currentUser?.user?.role !== "admin" || !currentUser?.user?.admin) {
        if (dispatchCount.current < 1) {
          dispatch(disconnectWebsocket(currentSession));
          navigate("/login");
          dispatchCount.current += 1;
        }
      }
    }
  }, [currentUser]);

  const customersDataColumns = [
    {
      field: "edit",
      headerName: "",
      // type: "actions",
      width: 20,
      renderCell: (params) => {
        return (
          <div>
            <Tooltip title="Edit user values">
              <IconButton
                onClick={() => {
                  handleOpenUsersDrawer(params);
                }}
              >
                <ModeEditSharpIcon color="primary" />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },

    { field: "id", headerName: "ID", width: 200 },
    {
      field: "registrationDate",
      headerName: "Registration Date",
      width: 150,
      editable: false,
    },
    {
      field: "customerName",
      headerName: "Customer Name",
      width: 150,
      editable: false,
    },
    {
      field: "role",
      headerName: "Role",
      width: 80,
      editable: false,
    },
    {
      field: "email",
      headerName: "Email",
      width: 150,
      editable: false,
    },
    {
      field: "customerAddress",
      headerName: "Customer Address",
      width: 300,
      editable: false,
    },
    {
      field: "customerPhone",
      headerName: "Customer Phone",
      width: 140,
      editable: false,
    },
    {
      field: "subscriptionType",
      headerName: "Subscription Type",
      width: 150,
      editable: false,
    },
    {
      field: "nocCreated",
      headerName: "# NOC Created",
      width: 140,
      editable: false,
    },
    {
      field: "currentNoc",
      headerName: "# Current NOC",
      width: 120,
      editable: false,
    },
    {
      field: "totalNoc",
      headerName: "# Total NOC",
      width: 120,
      editable: false,
    },
    {
      field: "counties",
      headerName: "# Counties",
      width: 110,
      editable: false,
    },
    {
      field: "contractorChanges",
      headerName: "# Contractor Changes",
      width: 180,
      editable: false,
    },
    {
      field: "status",
      headerName: "Status",
      width: 110,
      editable: false,
    },
    {
      field: "reasonLeave",
      headerName: "Reason for Leave",
      width: 160,
      editable: false,
    },
    {
      field: "licenseNumber",
      headerName: "License Number",
      width: 170,
      editable: false,
    },
    {
      field: "contractorName",
      headerName: "Contractor Name",
      width: 170,
      editable: false,
    },
    {
      field: "contractorAddress",
      headerName: "Contractor Address",
      width: 250,
      editable: false,
    },
    {
      field: "contractorPhone",
      headerName: "Contractor Phone",
      width: 150,
      editable: false,
    },
    {
      field: "contractorFax",
      headerName: "Contractor Fax",
      width: 150,
      editable: false,
    },
    {
      field: "contractorEmail",
      headerName: "Contractor Email",
      width: 150,
      editable: false,
    },
    {
      field: "jobTitle",
      headerName: "Job Title",
      width: 110,
      editable: false,
    },
    {
      field: "referralSource",
      headerName: "Referral Source",
      width: 110,
      editable: false,
    },
    {
      field: "companyName",
      headerName: "Company Name",
      width: 150,
      editable: false,
    },
    {
      field: "companySize",
      headerName: "Company Size",
      width: 110,
      editable: false,
    },
    {
      field: "yearsInBusiness",
      headerName: "Years in Business",
      width: 150,
      editable: false,
    },
    {
      field: "revenue",
      headerName: "Revenue Range",
      width: 80,
      editable: false,
    },
    {
      field: "avgNoc",
      headerName: "Avg monthly NOC Recordings",
      width: 160,
      editable: false,
    },
    {
      field: "topProject",
      headerName: "Top Project Types",
      width: 160,
      editable: false,
    },
    {
      field: "formsYouLike",
      headerName: "Forms the User Like",
      width: 150,
      editable: false,
    },
    {
      field: "primaryGoals",
      headerName: "Primary Goals",
      width: 150,
      editable: false,
    },
    {
      field: "remove",
      headerName: "",
      // type: "actions",
      width: 20,
      renderCell: (params) => {
        return (
          <div>
            <Tooltip title="Remove user">
              <IconButton
                onClick={() => {
                  handleRemoveUser(params);
                }}
              >
                <DeleteSharpIcon color="error" />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    textAlign: "center",
  };

  const handleSubmit = () => {
    if (confirm === "") {
      setErrorText("Confirmation is required!");
    } else if (confirm !== `Remove ${selectedRow?.customerName}`) {
      setErrorText("Confirmation input is not match!");
      setConfirm("");
    } else {
      setErrorText("Removed user successfully!");
      setConfirm("");
    }
  };

  const handleOpenUsersDrawer = (params) => {
    setOpenDrawer(true);
    setSelectedRow(params.row);
  };

  const handleRemoveUser = (params) => {
    setOpen(true);
    setSelectedRow(params.row);
  };

  const getUserData = (params, event, details) => {
    setSelectedUser(params.row);
  };

  useEffect(() => {
    if (errorText !== "") {
      if (errorText === "Removed user successfully!") {
        const timer = setTimeout(() => setOpen(false), 3000);
        return () => clearTimeout(timer);
      } else {
        const timer = setTimeout(() => setErrorText(""), 3000);
        return () => clearTimeout(timer);
      }
    }
  }, [errorText, open]);

  useEffect(() => {
    if (!currentUser || !currentUser.user) {
      // Data is not available yet, do nothing
      return;
    }

    const getUsers = async () => {
      try {
        const resp = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/admin/getUsers`,
          { currentSession: currentSession },
          { withCredentials: true }
        );
        setCustomersDataRows(resp.data.data);
        setDataLoading(false);
      } catch (err) {}
    };
    if (
      !currentUser ||
      currentUser?.user?.role !== "admin" ||
      !currentUser?.user?.admin
    ) {
      return;
    } else {
      if (userCount < 1) {
        getUsers();
        userCount += 1;
      }
    }
  }, [currentUser]);

  const handleChartValues = () => {
    if (selectedUser) {
      let chartList = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

      selectedUser.analytics.nocUsage.map((item) => {
        if (item.year === year) {
          chartList[item.month] = item.noc;
        }
        return null;
      });

      return chartList;
    }
  };

  return (
    <>
      {dataLoading ? (
        <>
          <>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                height: "100vh",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <CircularProgress color="warning" size="10rem" />
            </Box>
          </>
        </>
      ) : (
        <>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              py: 1,
              backgroundColor: "#EEF2F6",
            }}
          >
            <Container maxWidth="xl">
              <Grid container sx={{ my: 2 }} spacing={3}>
                <Grid item xs={12}>
                  <Card>
                    <CardHeader title={title} />
                    <CardContent>
                      <DataGrid
                        className="cell"
                        sx={{
                          "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                            outline: "none !important",
                          },
                        }}
                        rows={customersDataRows}
                        columns={customersDataColumns}
                        initialState={{
                          pagination: {
                            paginationModel: {
                              pageSize: 5,
                            },
                          },
                        }}
                        pageSizeOptions={[5, 10, 25, 50, 100]}
                        onRowClick={(params, event, details) => {
                          getUserData(params, event, details);
                        }}
                      />
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={8}>
                  {selectedUser ? (
                    <UserNOCUsage
                      role={selectedUser?.role}
                      year={year}
                      setYear={setYear}
                      handleChartValues={handleChartValues}
                      chartSeries={[
                        {
                          name: "Created NOC's",
                          data: handleChartValues(),
                        },
                      ]}
                      sx={{ height: "100%" }}
                    />
                  ) : (
                    <div></div>
                  )}
                </Grid>
                <Grid item xs={12} md={4}>
                  {selectedUser ? (
                    <AvailableUserNoc
                      chartSeries={
                        selectedUser?.role === "admin" ||
                        selectedUser?.role === "tester"
                          ? [
                              100000000000000,
                              parseInt(
                                selectedUser?.analytics.nocCreatedInPeriod
                              ),
                            ]
                          : [
                              selectedUser?.analytics?.availableNocInPeriod ===
                              "unlimited"
                                ? 100000000000000
                                : parseInt(
                                    selectedUser?.analytics
                                      ?.availableNocInPeriod
                                  ),
                              parseInt(
                                selectedUser?.analytics.nocCreatedInPeriod
                              ),
                            ]
                      }
                      labels={["Available", "Used"]}
                      role={selectedUser?.role}
                      tier={selectedUser?.currentNoc}
                      sx={{ height: "100%" }}
                    />
                  ) : (
                    <div></div>
                  )}
                </Grid>
              </Grid>
            </Container>
          </Box>
        </>
      )}

      <Drawer
        PaperProps={
          issm && {
            sx: {
              width: "50vw",
            },
          }
        }
        anchor="right"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <EditCustomer
          row={selectedRow}
          setSelectedRow={setSelectedRow}
          setOpenDrawer={setOpenDrawer}
          setCustomersDataRows={setCustomersDataRows}
        />
      </Drawer>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography variant="h3" color="error">
              Remove User
            </Typography>
            <Typography variant="h5" fontWeight="bold" sx={{ mt: 2 }}>
              Are you sure to remove this user?
            </Typography>
            <Typography variant="body1">
              Type in "Remove {selectedRow?.customerName}" to remove this user!
            </Typography>
            <TextField
              sx={{ mt: 3 }}
              fullWidth
              label="Confirm Delete User"
              onChange={(e) => setConfirm(e.target.value)}
              value={confirm}
            />
            <Typography
              variant="body1"
              color="#F2CD00"
              fontWeight="bold"
              textAlign="center"
              sx={{ minHeight: "2rem", mt: "1rem" }}
            >
              {errorText}
            </Typography>
            <Button
              fullWidth
              onClick={handleSubmit}
              size="large"
              color="error"
              sx={{ mt: 0 }}
              variant="contained"
            >
              REMOVE USER
            </Button>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default CustomersGrid;
