import React, { useState } from "react";
import { DateTime } from "luxon";
import {
  AppBar,
  Button,
  Dialog,
  IconButton,
  Toolbar,
  Typography,
  Chip,
  Box,
  useMediaQuery,
  Stack,
  Collapse,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
} from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import CloseIcon from "@mui/icons-material/Close";
import SwipeableViews from "react-swipeable-views";
import Grow from "@mui/material/Grow";
import MobileFooter from "../../layout/MobileFooter";

const extractLastPart = (str) => {
  const parts = str.split("-");
  return parts[parts.length - 1];
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Grow
      ref={ref}
      {...props}
      timeout={{
        enter: 500, // Duration for the enter animation in milliseconds
        exit: 300, // Duration for the exit animation
      }}
      style={{
        transformOrigin: "center",
        transitionDelay: props.in ? "0ms" : "200ms", // Delay the exit animation
      }}
    />
  );
});

const NotaryInvoiceModal = React.memo((props) => {
  return (
    <Dialog
      fullScreen={props?.tabletSize}
      open={props?.rowOpen}
      onClose={props?.handleClose}
      TransitionComponent={Transition}
    >
      <AppBar
        sx={{
          position: "relative",
          backgroundColor: "#333333",
          pl: props?.mobileSize ? 2 : undefined,
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={props?.handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Notary-Record Services Invoice
          </Typography>
        </Toolbar>
      </AppBar>

      <SwipeableViews
        axis="x"
        index={props?.rowIndex}
        onChangeIndex={props?.handleSwipe}
        enableMouseEvents
        style={{
          height: "calc(100% - 120px)",
          overflow: props?.smallHeight ? "auto" : "hidden",
        }}
      >
        {props?.notaryInvoiceRow?.map((row, index) => {
          return (
            <React.Fragment key={row.id}>
              <Box
                sx={{
                  p: 2,
                  pt: 1,
                  height: "100vh",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Chip
                    sx={{ fontWeight: "bold" }}
                    label={`Invoice: ${extractLastPart(row?.id)}`}
                    color="primary"
                  />
                  <Typography variant="h5">
                    {props?.rowIndex + 1}/{props?.notaryInvoiceRow.length}
                  </Typography>
                </Box>

                <Box mt={props?.iphone12Height ? 3 : 2}>
                  <Typography align="center" variant="h5" color="primary">
                    {row?.reason === "Recording"
                      ? `Document ${row?.reason}`
                      : row?.reason}
                  </Typography>
                  <Typography px={2} align="center">
                    {row?.property}
                  </Typography>
                </Box>

                {/* TABLE */}
                <Box
                  sx={{
                    mt: props?.iphone12Height ? 5 : 3,
                    mb: 1,
                    boxShadow: "0 8px 16px rgba(0,0,0,0.4)",
                    borderRadius: "15px",
                  }}
                >
                  <TableContainer sx={{ borderRadius: "15px" }}>
                    <Table aria-label="plan table">
                      <TableBody>
                        <TableRow>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            Payment Type
                          </TableCell>
                          <TableCell align="right">
                            {row?.payment_type}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            Price
                          </TableCell>
                          <TableCell align="right">{row?.price}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            Document Type
                          </TableCell>
                          <TableCell align="right">{`${row?.source} Document`}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            Status
                          </TableCell>
                          <TableCell align="right">{row?.status}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            Initiated Name
                          </TableCell>
                          <TableCell align="right">{row?.user}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            Initiated By
                          </TableCell>
                          <TableCell align="right">
                            {row.isSubuser ? "Sub-User" : "Main User"}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            Date
                          </TableCell>
                          <TableCell align="right">{row?.created_at}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Box>
            </React.Fragment>
          );
        })}
      </SwipeableViews>

      <MobileFooter pos="bottom" pl="0.5rem" />
    </Dialog>
  );
});

export default NotaryInvoiceModal;
