import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import YupPassword from "yup-password";
import LoadingButton from "@mui/lab/LoadingButton";
import CheckIcon from "@mui/icons-material/Check";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import {
  Box,
  Typography,
  Grid,
  useMediaQuery,
  useTheme,
  CircularProgress,
  Button,
} from "@mui/material";
import TableOverview from "./TableOverview";
import RecordingStartedModal from "../modals/sealRecords/RecordingStartedModal";
import NotarizedModal from "../../NotarizedModal";

YupPassword(Yup);

const RegisterRecordSignee = () => {
  const theme = useTheme();
  const currentSession = useSelector((state) => state.sessions.currentSession);
  const guestUser = useSelector((state) => state.guestSession);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const scrollableElementRef = useRef(null);
  const mobileSize = useMediaQuery(theme.breakpoints.down("sm"));
  const tabletSize = useMediaQuery(theme.breakpoints.down("md"));
  const largeTabletSize = useMediaQuery(theme.breakpoints.down("lg"));
  const largePC = useMediaQuery(theme.breakpoints.up("xl"));
  const [pageLoading, setPageLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [propertyDetails, setPropertyDetails] = useState([]);
  const [ids, setIds] = useState();
  const [documentModal, setDocumentModal] = useState(false);
  const [activeOwner, setActiveOwner] = useState({ type: null, index: null });
  const [recordError, setRecordError] = useState("");
  const [ownerTypes, setOwnerTypes] = useState({});
  const [fullOwners, setFullOwners] = useState([]);
  const [ownerships, setOwnerships] = useState([]);

  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [notaryErrorText, setNotaryErrorText] = useState("");
  const initialValuesSet = useRef(false);

  const [signerType, setSignerType] = useState("ORGANIZATION");

  const [source, setSource] = useState("");
  const [img, setImg] = useState("");

  const [modal, setModal] = useState(false);

  const [initialFormValues, setInitialFormValues] = useState({
    owners: [],
    fullOwnerSelect: [],
    ownersSelect: [],
    ownerAddress: "",
  });
  const [copyOwners, setCopyOwners] = useState([]);
  let count = 0;

  const removeFile = () => {
    setSelectedFile(null);
    setNotaryErrorText("");
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileExtension = file.name.split(".").pop().toLowerCase();
      const fileSizeInMB = file.size / (1024 * 1024);
      if (fileExtension !== "pdf") {
        setNotaryErrorText("Please upload a PDF file.");
        // Optionally, clear the file input here
        // event.target.value = '';
      } else if (fileSizeInMB > 2) {
        setNotaryErrorText("Please upload a file smaller than 2MB.");
      } else {
        setSelectedFile(file);
        if (notaryErrorText !== "") {
          setNotaryErrorText("");
        }
      }
    }
  };
  useEffect(() => {
    if (!activeOwner?.type) {
      setRecordError("");
    }
  }, [recordError, activeOwner]);

  const extractOwnersData = (overviewData) => {
    const owners = [];
    const ownersSelect = [];
    let ownerAddress = "";

    overviewData.forEach((item) => {
      if (
        item.label.startsWith("Owner") &&
        item.label !== "Owner Address" &&
        item.label !== "Ownership Type"
      ) {
        // Add to owners array
        owners.push(item);
        ownersSelect.push({ label: item.label, value: item.type });
      } else if (item.label === "Owner Address") {
        ownerAddress = item.value;
      }
    });

    for (const owner of owners) {
      if (owner.type === "PERSON") {
        setSignerType("PERSON");
        break;
      }
    }

    return {
      owners,
      ownersSelect,
      ownerAddress,
    };
  };

  useEffect(() => {
    if (modal === true) {
      const modalInterval = setInterval(() => {
        setModal(false);
        navigate("/");
      }, 10000); // Check every 3 seconds

      return () => {
        clearInterval(modalInterval);
      };
    }
  }, [modal]);

  useEffect(() => {
    if (!initialValuesSet.current && guestUser?.registerData) {
      initialValuesSet.current = true;
      const { owners, ownersSelect, ownerAddress } = extractOwnersData(
        guestUser?.registerData?.overview_data.affected_property
      );
      setCopyOwners(owners);
      setOwnerships(guestUser?.registerData?.ownerships);
      setFullOwners(guestUser?.registerData?.ownersList);
      setInitialFormValues((prevState) => ({
        ...prevState,
        owners: owners,
        fullOwnerSelect: guestUser?.registerData?.ownersList,
        ownersSelect: ownersSelect,
        ownerAddress: ownerAddress,
      }));
      setPropertyDetails(guestUser?.registerData?.overview_data);
      setSource(guestUser?.registerData?.source);
      if (
        guestUser?.registerData?.img &&
        guestUser?.registerData?.img.length > 0
      ) {
        setImg(guestUser?.registerData?.img);
      }

      setIds(guestUser?.registerData?.id);
      setPageLoading(false);
    }
  }, [guestUser?.registerData]);

  function fileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        // Remove the Data URL scheme prefix
        const base64Content = reader.result.replace(/^data:.*;base64,/, "");
        resolve(base64Content);
      };
      reader.onerror = (error) => reject(error);
    });
  }

  const createValidationSchema = () => {
    const ownerSchema = Yup.object().shape({
      value: Yup.object()
        .shape({
          PERSON: Yup.string(),
          ORGANIZATION: Yup.string(),
        })
        .test(
          "type-dependent-validation",
          "Invalid type dependent data",
          function (value) {
            // const { type } = this.parent;
            if (signerType === "PERSON") {
              if (!value.PERSON) {
                return this.createError({
                  path: `${this.path}.PERSON`,
                  message: "Individual Name is required",
                });
              }
            } else if (signerType === "ORGANIZATION") {
              if (!value.ORGANIZATION) {
                return this.createError({
                  path: `${this.path}.ORGANIZATION`,
                  message: "Organization name is required",
                });
              }
            }
            return true;
          }
        ),
    });

    const schemaFields = {
      ownerAddress: Yup.string()
        .min(5, "Owner address is too short")
        .required("Owner address is required"),
      owners: Yup.array().of(ownerSchema),
    };

    return Yup.object(schemaFields);
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialFormValues,
    validationSchema: createValidationSchema(),
    validateOnBlur: true, // Validate only when an input field is blurred
    validateOnChange: false,
    onSubmit: async (values, helpers) => {
      setSubmitLoading(true);
      let base64File = null;
      let payload = null;
      try {
        // Convert the file to Base64
        if (source === "internal") {
          base64File = await fileToBase64(selectedFile);
          payload = {
            source: source,
            file: base64File,
            fileName: selectedFile.name,
            ownerAddress: values["ownerAddress"],
            owners: values["owners"],
            currentSession: currentSession,
          };
        } else {
          payload = {
            source: source,
            ownerAddress: values["ownerAddress"],
            owners: values["owners"],
            currentSession: currentSession,
          };
        }

        const resp = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/recording/upload-noc-file`,
          {
            payload,
          },
          {
            withCredentials: true,
          }
        );

        if (resp.data.success === true) {
          setModal(true);
          setSubmitLoading(false);
        } else {
          setNotaryErrorText(resp.data.message);
          setSubmitLoading(false);
        }
      } catch (e) {
        setNotaryErrorText(e.response.data.message);
        setSubmitLoading(false);
      }
    },
  });
  return (
    <>
      {pageLoading ? (
        <>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100vh",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CircularProgress color="warning" size="10rem" />
          </Box>
        </>
      ) : (
        <Box
          sx={
            tabletSize
              ? {
                  justifyContent: "center",
                  overflowX: "hidden",
                  px: 1,
                  minHeight: "100vh",
                  overflowY: "hidden",
                  display: "flex",
                  flexDirection: "column",
                }
              : {
                  backgroundImage: "url('/img/website_img/homeImg.jpeg')",
                  backgroundRepeat: "repeat",

                  minHeight: "100vh",
                  overflow: "hidden",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }
          }
        >
          <Box
            sx={
              tabletSize
                ? {
                    backgroundColor: "#fff",
                    py: "1rem",
                    // maxWidth: "100%",
                    flexGrow: 1,
                  }
                : {
                    backgroundColor: "#fff",
                    my: 2,
                    maxWidth: largePC ? "1300px" : "80%",
                    borderRadius: "25px",
                    px: 3,
                    width: "80%",
                    py: "2rem",
                    position: "relative",
                  }
            }
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
              // onClick={() => {
              //   navigate("/");
              // }}
            >
              <img
                style={{
                  width: mobileSize ? "90%" : tabletSize ? "8rem" : "10rem",
                  maxWidth: mobileSize ? "15rem" : undefined,
                }}
                alt="Permit Rockstar Logo"
                src="/img/website_img/PermitRockstar.png"
              />
            </Box>
            {/* Top Fixed Text */}
            <Typography
              color="primary"
              variant={mobileSize ? "h5" : "h4"}
              sx={{ mb: mobileSize ? 1 : 2, mt: 1 }}
              align="center"
            >
              Schedule Your Document Recording Session
            </Typography>
            <Typography
              color="primary"
              variant={mobileSize ? "body2" : "body1"}
              align="center"
              sx={{ mb: 1 }}
            >
              {source === "external"
                ? "To get started, carefully review the important details provided below. Then fill out the required fields."
                : "To get started, carefully review the important details provided below. Then fill out the required fields and upload the notarized document."}
            </Typography>

            {/* Scrollable Area Start */}
            <form noValidate onSubmit={formik.handleSubmit}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "63vh", // Adjust the height as needed
                }}
              >
                {/* Scrollable Area */}
                <Box
                  ref={scrollableElementRef}
                  sx={{
                    maxHeight: "calc(100vh - 45vh)", // Adjust this value based on your header and footer height
                    overflowY: "auto",
                    width: "100%",
                    flex: "1 1 auto", // Makes this box flexible in terms of height
                    mt: 1,
                    pt: 1,
                    boxShadow: "2px 2px 14px 1px #d3d3d3",
                  }}
                >
                  {/* Overview and Details */}
                  <TableOverview
                    propertyDetails={propertyDetails}
                    setDocumentModal={setDocumentModal}
                    ownerTypes={ownerTypes}
                    setOwnerTypes={setOwnerTypes}
                    formik={formik}
                    fullOwners={fullOwners}
                    copyOwners={copyOwners}
                    setCopyOwners={setCopyOwners}
                    ownerships={ownerships}
                    isFieldsDisabled={() => submitLoading}
                    record={true}
                    img={img}
                    source={source}
                    setSignerType={setSignerType}
                    mobileSize={mobileSize}
                    tabletSize={tabletSize}
                    largeTabletSize={largeTabletSize}
                  />

                  {/*  */}
                  {source !== "external" && (
                    <>
                      <Box
                        sx={{
                          borderTop: !largeTabletSize
                            ? "3px solid #f3f3f3"
                            : undefined,
                          width: "100%",
                          pt: !largeTabletSize ? 2 : 0,
                          pb: 1,
                          mb: 2,
                          mt: 2,
                        }}
                      >
                        <Typography
                          variant={mobileSize ? "h5" : "h4"}
                          align="center"
                          color="primary"
                        >
                          Upload PDF Document
                        </Typography>
                        <Typography
                          sx={{ mt: 1, px: 0.5 }}
                          variant={mobileSize ? "body2" : "body1"}
                          color="primary"
                          align="center"
                        >
                          A notarized PDF document must be uploaded. The
                          document must be notarized using the same document
                          that you received.
                        </Typography>
                      </Box>
                      <Grid container align="center">
                        {selectedFile && isUploading === false && (
                          <>
                            <Grid item xs={12}>
                              {selectedFile && (
                                <Typography
                                  variant="h6"
                                  fontWeight="bold"
                                  color="#4caf50"
                                >
                                  Selected File:
                                </Typography>
                              )}
                            </Grid>
                            <Grid item xs={12}>
                              {selectedFile && (
                                <Typography
                                  sx={{ mt: "0.2rem", px: 0.5 }}
                                  variant={mobileSize ? "body2" : "body1"}
                                  fontWeight="bold"
                                  color="primary"
                                >
                                  {selectedFile?.name}
                                </Typography>
                              )}
                            </Grid>
                          </>
                        )}
                        <Grid item xs={12}>
                          <Button
                            fullWidth
                            disabled={
                              Boolean(fileUploaded) || Boolean(selectedFile)
                            }
                            color={
                              fileUploaded || selectedFile ? "error" : "success"
                            }
                            variant="contained"
                            onClick={
                              fileUploaded || selectedFile
                                ? removeFile
                                : undefined
                            }
                            component="label"
                            sx={{ mt: 2, maxWidth: tabletSize ? "95%" : "70%" }}
                            startIcon={
                              fileUploaded || selectedFile ? (
                                <CheckIcon />
                              ) : (
                                <AttachFileIcon />
                              )
                            }
                          >
                            {fileUploaded || selectedFile
                              ? "File Selected"
                              : "Select Notarized PDF Document"}
                            {!selectedFile && (
                              <input
                                type="file"
                                hidden
                                onChange={handleFileChange}
                              />
                            )}
                          </Button>
                        </Grid>
                      </Grid>
                    </>
                  )}

                  <Typography
                    align="center"
                    variant="body2"
                    sx={{
                      color: "#f44336",
                      fontWeight: "bold",
                      my: 1,
                    }}
                  >
                    {notaryErrorText}
                  </Typography>
                  <Grid container align="center" sx={{ height: "5.5rem" }}>
                    {selectedFile && isUploading === false && (
                      <>
                        <Grid item xs={12}>
                          <Button
                            disabled={submitLoading}
                            fullWidth
                            variant="contained"
                            color="error"
                            onClick={removeFile}
                            sx={{ mt: 0, maxWidth: tabletSize ? "95%" : "70%" }}
                            startIcon={<DeleteIcon />}
                          >
                            {selectedFile &&
                            !selectedFile &&
                            isUploading === false
                              ? "Select New File"
                              : "Remove"}
                          </Button>
                        </Grid>
                      </>
                    )}
                    <Grid item xs={12}>
                      {isUploading && (
                        <Box sx={{ width: "100%" }}>
                          <CircularProgress
                            sx={{ mt: 5 }}
                            size={50}
                            variant="determinate"
                            value={uploadProgress}
                          />
                          <Typography sx={{ mt: 1 }}>{`${Math.round(
                            uploadProgress
                          )}%`}</Typography>
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              {/* Scrollable Area End */}
              {/* Fixed Submit Button */}
              <Grid item xs={12}>
                {/* Ensures the button doesn't grow or shrink */}
                <LoadingButton
                  disabled={
                    source === "internal"
                      ? Boolean(selectedFile) === false ||
                        Boolean(notaryErrorText)
                      : false
                  }
                  type="submit"
                  loading={submitLoading}
                  startIcon={
                    source === "external" ? <></> : <DriveFolderUploadIcon />
                  }
                  size="large"
                  variant="contained"
                  fullWidth
                >
                  {source === "external"
                    ? "Submit"
                    : "Submit and Upload Document"}
                </LoadingButton>
              </Grid>
            </form>
          </Box>
        </Box>
      )}
      <NotarizedModal
        notarizedModal={documentModal}
        setNotarizedModal={setDocumentModal}
        pdfImg={img[0]}
        pdfImg2={img?.[1]}
        previewImg=""
        preview={false}
      />
      <RecordingStartedModal
        tabletSize={tabletSize}
        mobileSize={mobileSize}
        largePC={largePC}
        modal={modal}
        setModal={setModal}
      />
    </>
  );
};

export default RegisterRecordSignee;
