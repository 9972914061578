import {
  IconButton,
  Tooltip,
  Typography,
  CircularProgress,
  Box,
  Container,
  useMediaQuery,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import CachedIcon from "@mui/icons-material/Cached";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { DocumentArrowDownIcon } from "@heroicons/react/24/outline";
import { unavailable_record_list } from "../../../data/counties";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import SettingsIcon from "@mui/icons-material/Settings";
import DocumentSettingsModal from "../../notary/modals/sealRecords/DocumentSettingsModal";
import DocumentRecordModal from "../../notary/modals/sealRecords/DocumentRecordModal";
import SealRecordGrids from "../../datagridActions/SealRecordGrids";
import { updateSigneeEmailAction } from "../../../store/actions/userActions";
import { NotarizeIcon } from "../../../styles/icons/svgIcons";
import DocumentFailedModal from "../../notary/modals/sealRecords/DocumentFailedModal";
import MobileSealRecord from "../MobileSealRecord";
import StatusIcon from "../../layout/StatusIcon";
import MobileSealRecordModal from "../../popover/datagridRowPopover/MobileSealRecordModal";
import { useGridApiRef } from "@mui/x-data-grid";

const PermitInvitations = () => {
  const currentSession = useSelector((state) => state.sessions.currentSession);
  const currentUser = useSelector((state) => state[currentSession].user);
  const location = useLocation();
  const { fromExternal } = location.state || { fromExternal: false };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const mobileSize = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const tabletSize = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const largePC = useMediaQuery((theme) => theme.breakpoints.up("xl"));
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [failedModal, setFailedModal] = useState(false);
  const [recordModal, setRecordModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [reminderMessage, setReminderMessage] = useState("");
  const [emailMessage, setEmailMessage] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [changeEmailLoading, setChangeEmailLoading] = useState(false);
  const [emailText, setEmailText] = useState("");
  const [emailChanged, setEmailChanged] = useState(false);
  const [downloadingRowId, setDownloadingRowId] = useState(null);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [notaryPrice, setNotaryPrice] = useState("0.00");

  const [reminderLoading, setReminderLoading] = useState(false);

  const [recordLoading, setRecordLoading] = useState(false);
  const [recordText, setRecordText] = useState("");
  const [reminderSent, setReminderSent] = useState(false);

  const [mobileDownloading, setMobileDownloading] = useState(false);
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);

  const [cancelSelected, setCancelSelected] = useState(false);
  const [cancelDisabled, setCancelDisabled] = useState(false);
  const [restartSelected, setRestartSelected] = useState(false);
  const [restartDisabled, setRestartDisabled] = useState(false);

  const [failures, setFailures] = useState({
    failedHeader: "",
    notaryErrorReason: "",
    failedReason: "",
    isNotarized: false,
    isRecorded: false,
    againButtonText: "",
    againButtonHeader: "",
    cancelButtonText: "",
    againButtonSelected: false,
    cancelButtonSelected: false,
    refundText: "",
    hasRefund: false,
    cancelNotaryLoading: false,
    closeDisabled: false,
    cancelButtonDisabled: false,
    againButtonDisabled: false,
    cancelSuccessText: "",
    againSuccessText: "",
  });

  const initialFailures = () => {
    setFailures((currentFailures) => ({
      ...currentFailures,
      failedHeader: "",
      notaryErrorReason: "",
      failedReason: "",
      isNotarized: false,
      isRecorded: false,
      againButtonText: "",
      againButtonHeader: "",
      cancelButtonText: "",
      againButtonSelected: false,
      cancelButtonSelected: false,
      refundText: "",
      hasRefund: false,
      cancelNotaryLoading: false,
      againNotaryLoading: false,
      closeDisabled: false,
      cancelButtonDisabled: false,
      againButtonDisabled: false,
      cancelSuccessText: "",
      againSuccessText: "",
    }));
  };

  const handleCancelNotarization = async () => {
    try {
      setFailures((currentFailures) => ({
        ...currentFailures,
        cancelNotaryLoading: true,
        closeDisabled: true,
        cancelButtonDisabled: true,
        againButtonDisabled: true,
      }));

      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/notary/cancel-failed-notarization`,
        {
          noc_id: selectedRow?.id,
          task_id: selectedRow?.task_id,
          reason: selectedRow?.reason,
          currentSession: currentSession,
        },
        { withCredentials: true }
      );

      if (resp.data.success === true) {
        setFailures((currentFailures) => ({
          ...currentFailures,
          cancelSuccessText: resp.data.message,
        }));
        if (tabletSize) {
          setRowOpen(false);
          setSelectedRow([]);
          setCancelSelected(false);
          setCancelDisabled(false);
          setRestartSelected(false);
          setRestartDisabled(false);
          setIsCollapseOpen(false);
          setOverviewEnabled(true);
          setSettingsEnabled(false);
        }
      } else {
        setFailures((currentFailures) => ({
          ...currentFailures,
          cancelSuccessText: resp.data.message,
          cancelNotaryLoading: false,
          closeDisabled: false,
          cancelButtonDisabled: false,
          againButtonDisabled: false,
        }));
      }
    } catch (e) {
      setSelectedRow([]);
      setFailures((currentFailures) => ({
        ...currentFailures,
        cancelSuccessText: e.response.data.message,
      }));
    }
  };

  const handleRepeateNotarization = async () => {
    try {
      setFailures((currentFailures) => ({
        ...currentFailures,
        againNotaryLoading: true,
        closeDisabled: true,
        cancelButtonDisabled: true,
        againButtonDisabled: true,
      }));
      if (
        selectedRow?.reason === "record" ||
        (selectedRow?.reason === "notaryRecord" &&
          selectedRow?.is_notarized === true)
      ) {
        const { id, ...rest } = selectedRow;
        const modifiedSelectedRow = { noc_id: id, ...rest };
        const resp = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/data/record-document`,
          {
            selectedRow: modifiedSelectedRow,
            currentSession: currentSession,
            re: true,
          },
          { withCredentials: true }
        );
        setFailures((currentFailures) => ({
          ...currentFailures,
          againSuccessText: resp.data.message,
        }));
      } else {
        const resp = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/notary/notarize-record-again`,
          {
            noc_id: selectedRow?.id,
            task_id: selectedRow?.task_id,
            reason: selectedRow?.reason,
            currentSession: currentSession,
          },
          { withCredentials: true }
        );

        setFailures((currentFailures) => ({
          ...currentFailures,
          againSuccessText: resp.data.message,
        }));
      }
      if (tabletSize) {
        setRowOpen(false);
        setSelectedRow([]);
        setCancelSelected(false);
        setCancelDisabled(false);
        setRestartSelected(false);
        setRestartDisabled(false);
        setIsCollapseOpen(false);
        setOverviewEnabled(true);
        setSettingsEnabled(false);
      }
    } catch (e) {
      setSelectedRow([]);
      setFailures((currentFailures) => ({
        ...currentFailures,
        againSuccessText: e.response.data.message,
      }));
    }
  };
  useEffect(() => {
    if (selectedRow) {
      if (
        selectedRow?.reason === "record" &&
        selectedRow?.recording_failed === true
      ) {
        setFailures((currentFailures) => ({
          ...currentFailures,
          refundText: "You can cancel the recording and try again later",
          hasRefund: true,
        }));
        setNotaryPrice("0.00");
      } else {
        setFailures((currentFailures) => ({
          ...currentFailures,
          refundText: "You will be refunded for the notarization fee",
          hasRefund: true,
        }));
        setNotaryPrice("0.00");
      }

      if (
        selectedRow?.reason === "notaryRecord" &&
        selectedRow?.notary_failed === true
      ) {
        setFailures((currentFailures) => ({
          ...currentFailures,
          againButtonText: "Notarize and Record Again",
          cancelButtonText: "Cancel Notarization and Recording",
          againButtonHeader: "Notarize and Record Document",
        }));
      } else if (
        selectedRow?.reason === "notaryRecord" &&
        selectedRow?.notary_failed === false &&
        selectedRow?.recording_failed === true
      ) {
        setFailures((currentFailures) => ({
          ...currentFailures,
          againButtonText: "Record Again",
          cancelButtonText: "Cancel Recording",
          againButtonHeader: "Record Document",
        }));
      } else if (
        selectedRow?.reason === "notary" &&
        selectedRow?.notary_failed === true
      ) {
        setFailures((currentFailures) => ({
          ...currentFailures,
          againButtonText: "Notarize Again",
          cancelButtonText: "Cancel Notarization",
          againButtonHeader: "Notarize Document",
        }));
      }
      if (
        (selectedRow?.reason === "notaryRecord" ||
          selectedRow?.reason === "notary") &&
        selectedRow?.notary_failed
      ) {
        setFailures((currentFailures) => ({
          ...currentFailures,
          failedHeader: "Notarization Failed",
          notaryErrorReason:
            selectedRow?.notary_failure?.[0]?.reason ||
            currentFailures.notaryErrorReason,
          failedReason: "notary",
          isNotarized: false,
          isRecorded: false,
        }));
      } else if (
        selectedRow?.reason === "notaryRecord" &&
        selectedRow?.recording_failed &&
        selectedRow?.is_notarized === true
      ) {
        setFailures((currentFailures) => ({
          ...currentFailures,
          failedHeader: "Recording Failed",
          notaryErrorReason:
            selectedRow?.recording?.failure?.[0]?.reason ||
            currentFailures.notaryErrorReason,
          failedReason: "record",
          isNotarized: true,
          isRecorded: false,
        }));
      } else if (
        selectedRow?.reason === "record" &&
        selectedRow?.recording_failed
      ) {
        setFailures((currentFailures) => ({
          ...currentFailures,
          failedHeader: "Recording Failed",
          notaryErrorReason:
            selectedRow?.recording?.failure?.[0] ||
            currentFailures.notaryErrorReason,
          failedReason: "record",
          isNotarized: false,
          isRecorded: false,
          againButtonText: "Record Again",
          cancelButtonText: "Cancel Recording",
        }));
      }
    }
  }, [selectedRow]);

  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt?.charAt(0)?.toUpperCase() + txt?.substring(1)?.toLowerCase();
    });
  }

  const handleSendReminder = async () => {
    try {
      setReminderLoading(true);
      if (selectedRow?.reason === "record") {
        const resp = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/recording/send-record-register-reminder`,
          {
            selectedRow,
            currentSession: currentSession,
          },
          { withCredentials: true }
        );
        if (resp.data.success === true) {
          setReminderMessage(resp.data.message);
          setReminderSent(true);
        }
        setReminderLoading(false);
      } else {
        const resp = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/notary/send-signee-register-reminder`,
          {
            selectedRow,
            currentSession: currentSession,
          },
          { withCredentials: true }
        );
        if (resp.data.success === true) {
          setReminderMessage(resp.data.message);
          setReminderSent(true);
        }
        setReminderLoading(false);
      }
    } catch (e) {
      setReminderLoading(false);
    }
  };

  const submitStartRecord = async () => {
    try {
      setRecordLoading(true);
      const { id, ...rest } = selectedRow;
      const modifiedSelectedRow = { noc_id: id, ...rest };
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/data/record-document`,
        { selectedRow: modifiedSelectedRow, currentSession: currentSession },
        {
          withCredentials: true,
        }
      );
      if (response.data.success === true) {
        setRecordText(response.data.message);
      } else {
        setRecordLoading(false);
      }
    } catch (e) {
      setRecordText(e.response.data.message);
      setRecordLoading(false);
    }
  };

  const handleDownloadNoc = async (selectedRow) => {
    if (tabletSize) {
      setMobileDownloading(true);
    }
    try {
      setDownloadingRowId(selectedRow.id);
      const pdf_file =
        selectedRow?.reason === "record" ||
        selectedRow?.reason === "notaryRecord"
          ? selectedRow?.recording?.url
          : selectedRow?.document?.pdf_url;

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/data/download-created-file`,
        { pdf: pdf_file, notarized: true, currentSession: currentSession },
        {
          withCredentials: true,
          responseType: "blob", // Important: This tells Axios to expect a binary response
        }
      );
      // Create a URL for the blob
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      // Create an anchor (`<a>`) element
      const fileLink = document.createElement("a");
      fileLink.href = fileURL;
      // Set the download attribute to the desired file name
      fileLink.setAttribute("download", selectedRow?.document?.pdf_name); // Customize the file name if necessary
      // Append to the document and trigger the download
      document.body.appendChild(fileLink);
      fileLink.click();
      // Clean up
      document.body.removeChild(fileLink);
      window.URL.revokeObjectURL(fileURL); // Release the object URL
      // setDownloadingFile(true);
      setDownloadingRowId(null);
      if (tabletSize) {
        setMobileDownloading(false);
      }
    } catch (e) {
      setDownloadingRowId(null);
      if (tabletSize) {
        setMobileDownloading(false);
      }
    }
  };

  useEffect(() => {
    let interval;
    let recordInterval;
    let cancelInterval;
    let againInterval;

    if (emailText !== "") {
      interval = setInterval(() => {
        setEmailText("");
      }, 5000);
    }

    if (recordText !== "") {
      recordInterval = setInterval(() => {
        setRecordModal(false);
        setRecordText("");
      }, 5000);
    }

    if (failures?.cancelSuccessText !== "") {
      cancelInterval = setInterval(() => {
        setFailedModal(false);
        initialFailures();
      }, 5000);
    }

    if (failures?.againSuccessText !== "") {
      againInterval = setInterval(() => {
        setFailedModal(false);
        initialFailures();
      }, 5000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
      if (recordInterval) {
        clearInterval(recordInterval);
      }
      if (cancelInterval) {
        clearInterval(cancelInterval);
      }
      if (againInterval) {
        clearInterval(againInterval);
      }
    };
  }, [
    emailText,
    recordText,
    failures?.cancelSuccessText,
    failures?.againSuccessText,
  ]);

  const handleSelected = (params) => {
    setModal(true);
    setSelectedRow(params.row);
  };

  const handleRecordSelected = (params) => {
    setRecordModal(true);
    setSelectedRow(params.row);
  };

  const handleFailedForm = (params) => {
    setFailedModal(true);
    setSelectedRow(params.row);
  };

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const handleEmailChange = (event) => {
    const email = event.target.value;
    setNewEmail(email);

    const emailValid = isValidEmail(email);

    setEmailError(!emailValid);
  };

  const submitEmailChange = async () => {
    try {
      setChangeEmailLoading(true);
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/stripe/change-signee-email`,
        {
          selectedRow,
          newEmail,
          currentSession: currentSession,
        },
        { withCredentials: true }
      );

      if (resp.data.success === true) {
        setSelectedRow({ ...selectedRow, email: newEmail });
        dispatch(
          updateSigneeEmailAction(selectedRow.source, selectedRow.id, newEmail)
        );
        setEmailChanged(true);
        setEmailText(
          "Your email address has been successfully updated, and we've sent out the invitation."
        );
      }
      setChangeEmailLoading(false);
    } catch (e) {
      setChangeEmailLoading(false);
    }
  };
  // useEffect(() => {
  //   if (currentUser) {
  //     if (currentUser?.user?.expired === true) {
  //       navigate("/subscription");
  //     }
  //   }

  //   setLoading(false);
  // }, [currentUser]);

  //! MOBILE BLOCK //////////////////////////////////////////////////////////////////////
  const internalRef = useGridApiRef();
  const externalRef = useGridApiRef();
  const failedRef = useGridApiRef();
  const [modalTitle, setModalTitle] = useState("Documents Hub - Internal");
  const [currentRows, setCurrentRows] = useState(
    currentUser?.notary_forms?.internal
  );
  const [internalEnabled, setInternalEnabled] = useState(
    fromExternal ? false : true
  );
  const [externalEnabled, setExternalEnabled] = useState(
    fromExternal ? true : false
  );
  const [failedEnabled, setFailedEnabled] = useState(false);
  const [rowIndex, setRowIndex] = useState(0);
  const [rowOpen, setRowOpen] = useState(false);

  const [overviewEnabled, setOverviewEnabled] = useState(true);
  const [showFormEnabled, setShowFormEnabled] = useState(false);
  const [settingsEnabled, setSettingsEnabled] = useState(false);

  //? NOTARIZATION/RECORDING STATUSES
  const defaultStatus = {
    recorded: null,
    notarized: null,
    statusText: "",
    reason: "",
    completed: null,
    inProgress: null,
    failed: null,
    recordFailed: null,
    notaryFailed: null,
  };
  const [status, setStatus] = useState(defaultStatus);
  //? ///////////////////////////////
  function getStatusText(reason) {
    const reasonMappings = {
      "The notary has not joined":
        "Notarization failed because the notary did not attend the Remote Online Notarization (RON) session required for the notarization.",
      "Failed ID Verification":
        "Notarization failed because the Signee failed to verify his identity during ID verification.",
      "The ID verification match has failed":
        "Notarization failed because the Signee failed to verify his identity during ID verification.",
      "The ID verification process was unsuccessful":
        "Notarization failed because the Signee failed to verify his identity during ID verification.",
      "Has expired":
        "The Remote Online Notarization (RON) failed because the signee failed to join the session.",
      "The signer has not joined":
        "The Remote Online Notarization (RON) failed because the signee failed to join the session.",
      "The KBA was not successful":
        "The Notarization could not be completed because the Signee failed to verify his identity during Knowledge Based Authentication (KBA).",
      "The signer left without signing":
        "The notarization was unsuccessful because we lost contact with the signee during the Remote Online Notarization (RON) session.",
      "The notary ended the session before signing":
        "Unfortunately, the notary was unable to complete the notarization due to a technical issue during the Remote Online Notarization (RON).",
    };

    // Default message for unhandled reasons
    const defaultMsg =
      "The notarization of the document could not be completed due to an unexpected technical issue.";

    // Return the matching message or the default message if no match is found
    return reasonMappings?.[reason] || defaultMsg;
  }
  // Function to update the state, supporting both single and multiple updates
  const updateSetStatus = (key, value) => {
    if (typeof key === "object") {
      // If 'key' is an object, merge it with the current state
      setStatus((prevStatus) => ({
        ...prevStatus,
        ...key,
      }));
    } else {
      // If 'key' is a string, update the specific key with the value
      setStatus((prevStatus) => ({
        ...prevStatus,
        [key]: value,
      }));
    }
  };

  const updateStatusState = (row) => {
    // Start with a fresh object based on the defaultStatus
    const newState = { ...defaultStatus };

    const isNotaryInProgress =
      row?.is_notarized === false && row?.notary_failed === false;
    const isRecordingInProgress =
      row?.recording?.is_recorded === false &&
      (row?.isActive === true || row?.is_active === true);
    const hasNotaryFailed =
      row?.is_notarized === false && row?.notary_failed === true;
    const hasRecordingFailed =
      row?.recording?.is_recorded === false &&
      row?.recording?.failure?.length > 0;

    // Handle Notarization Status
    if (row?.reason === "notary" || row?.reason === "notaryRecord") {
      if (row?.is_notarized) {
        newState.notarized = true;
        newState.statusText =
          "Notarization of your document has been successfully completed.";
      } else if (isNotaryInProgress) {
        newState.statusText = row?.signee_registered
          ? "The Signee has scheduled a Remote Online Notarization (RON) session for the notarization of your document."
          : "Waiting for the Signee to schedule a Remote Online Notarization (RON) session.";
      } else if (hasNotaryFailed) {
        newState.statusText = getStatusText(row?.notary_failure?.[0]?.reason);
        newState.failed = true;
        newState.notaryFailed = true;
      }
    }

    // Handle Recording Status
    if (row?.reason === "record" || row?.reason === "notaryRecord") {
      if (row?.recording?.is_recorded) {
        newState.recorded = true;
        newState.statusText =
          row?.reason === "record"
            ? "Recording of your document has been successfully completed."
            : "Notarization and Recording of your document has been successfully completed.";
      } else if (isRecordingInProgress && !isNotaryInProgress) {
        newState.statusText = row?.signee_registered
          ? "The home owner or authorized agent has submitted the required information and the recording process has begun."
          : "To begin the recording process, we are awaiting the completion of the necessary details from the homeowner or authorized person.";
      } else if (hasRecordingFailed) {
        newState.statusText =
          "Document recording has been unsuccessful due to incorrect or missing information.";
        newState.failed = true;
        newState.recordFailed = true;
      }
    }

    // Set common properties based on notarization and recording results
    newState.reason =
      row?.reason === "notary"
        ? "Notarization"
        : row?.reason === "record"
        ? "Recording"
        : "Notarization And Recording";
    newState.completed = newState.notarized || newState.recorded;
    newState.inProgress = isNotaryInProgress || isRecordingInProgress;
    newState.failed = hasNotaryFailed || hasRecordingFailed;

    // Update state once with the new state object
    updateSetStatus(newState);
  };
  useEffect(() => {
    if (failedEnabled) {
      setCurrentRows(currentUser?.notary_forms?.failed);
    } else if (internalEnabled) {
      setCurrentRows(currentUser?.notary_forms?.internal);
    } else if (externalEnabled) {
      setCurrentRows(currentUser?.notary_forms?.external);
    }
  }, [currentUser?.notary_forms]);

  const handleSwipe = (index) => {
    setRowIndex(index);
    setSelectedRow(currentRows[index]);
    updateStatusState(currentRows[index]);
    setReminderSent(false);
    setReminderMessage("");
    if (!selectedRow?.reg_visited) {
      setEmailMessage("");
    }
    setNewEmail("");
    setEmailChanged(false);
    setEmailError(false);
  };

  const handleInternalClick = () => {
    if (internalEnabled) {
      return;
    }
    internalRef.current = {};
    setModalTitle("Documents Hub - Internal");
    setCurrentRows(currentUser?.notary_forms?.internal);
    setInternalEnabled(true);
    setExternalEnabled(false);
    setFailedEnabled(false);
  };

  const handleExternalClick = () => {
    if (externalEnabled) {
      return;
    }
    externalRef.current = {};
    setModalTitle("Documents Hub - External");
    setCurrentRows(currentUser?.notary_forms?.external);
    setExternalEnabled(true);
    setInternalEnabled(false);
    setFailedEnabled(false);
  };

  const handleFailedClick = () => {
    if (failedEnabled) {
      return;
    }
    failedRef.current = {};
    setModalTitle("Documents Hub - Failed");
    setCurrentRows(currentUser?.notary_forms?.failed);
    setFailedEnabled(true);
    setInternalEnabled(false);
    setExternalEnabled(false);
  };

  const handleOverviewClick = () => {
    if (
      overviewEnabled ||
      failures?.cancelNotaryLoading ||
      failures?.againNotaryLoading
    ) {
      return;
    }

    setOverviewEnabled(true);
    setShowFormEnabled(false);
    setSettingsEnabled(false);

    setCancelSelected(false);
    setCancelDisabled(false);
    setRestartSelected(false);
    setRestartDisabled(false);
    setIsCollapseOpen(false);
  };

  const handleShowFormClick = () => {
    if (showFormEnabled) {
      return;
    }
    setShowFormEnabled(true);
    setOverviewEnabled(false);
    setSettingsEnabled(false);
  };

  const handleSettingsClick = () => {
    if (settingsEnabled) {
      return;
    }
    setSettingsEnabled(true);
    setShowFormEnabled(false);
    setOverviewEnabled(false);
  };

  const handleOpen = (params, index) => {
    if (!tabletSize) {
      return;
    }
    setSelectedRow(params.row);
    updateStatusState(params.row);
    setRowIndex(index);
    setRowOpen(true);
  };

  const handleClose = () => {
    if (failures?.cancelNotaryLoading || failures?.againNotaryLoading) {
      return;
    }
    setStatus(defaultStatus);
    setRowOpen(false);
    setSelectedRow(null);
    handleOverviewClick();
    const currentPage = Math.floor(rowIndex / 5);
    const apiRef = internalEnabled
      ? internalRef
      : externalEnabled
      ? externalRef
      : failedRef;

    if (apiRef.current) {
      apiRef.current?.setPage(currentPage);
    }

    setReminderSent(false);
    setReminderMessage("");
    if (!selectedRow?.reg_visited) {
      setEmailMessage("");
    }
    setNewEmail("");
    setEmailChanged(false);
    setEmailError(false);

    setCancelSelected(false);
    setCancelDisabled(false);
    setRestartSelected(false);
    setRestartDisabled(false);
    setIsCollapseOpen(false);
  };

  useEffect(() => {
    if (rowOpen && !tabletSize) {
      setRowOpen(false);
    }

    if (failedModal && tabletSize) {
      setFailedModal(false);
    }
  }, [tabletSize, failedModal, rowOpen]);

  useEffect(() => {
    updateStatusState(rowIndex);
  }, [currentUser]);
  //! //////////////////////////////

  const createdFormMobile = [
    {
      field: "property_address",
      headerName: "Properties",
      flex: 1,
      editable: false,

      renderCell: (params) => {
        const dateOptions = { month: "short", day: "numeric" }; // Date formatting options
        const timeOptions = {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        }; // Time formatting options

        const date = new Date(params?.row?.local_time);
        const formattedDate = date.toLocaleDateString("en-US", dateOptions);
        const formattedTime = date.toLocaleTimeString("en-US", timeOptions);
        const reason = params?.row?.reason;
        const isActive = params?.row?.is_active;
        const notarized = params?.row?.is_notarized;
        const recorded = params?.row?.recording?.is_recorded;
        return (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",

                // borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                pb: 0.2,
                px: 1,
              }}
            >
              <Typography
                sx={{
                  fontSize: "0.75rem",
                  color: "rgba(0, 0, 0, 0.54)",
                }}
              >
                {formattedDate} - {formattedTime}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontSize: "0.85rem",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  width: "95%", // Ensure this is set so `text-overflow` can work
                }}
              >
                {params.row?.document?.property_address.split(" | ")[0]}
              </Typography>

              <Box
                sx={{ display: "flex", maxWidth: "95vw", position: "relative" }}
              >
                <Typography
                  sx={{
                    flex: 1,
                    fontSize: "0.75rem",
                    color: "rgba(0, 0, 0, 0.54)",
                  }}
                >
                  {params.row?.document?.county}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "0.75rem",
                    color: "rgba(0, 0, 0, 0.54)",
                  }}
                >
                  {reason === "notaryRecord"
                    ? "Notarization and Recording"
                    : reason === "notary"
                    ? "Notarization"
                    : reason === "record"
                    ? "Recording"
                    : ""}
                </Typography>

                <Box sx={{ position: "absolute", bottom: 26, right: 0 }}>
                  <StatusIcon
                    status={
                      isActive
                        ? "inProgress"
                        : reason === "notary" && notarized
                        ? "completed"
                        : reason === "record" && recorded
                        ? "completed"
                        : reason === "notaryRecord" && notarized && recorded
                        ? "completed"
                        : "failed"
                    }
                  />
                </Box>
              </Box>
            </Box>
          </>
        );
      },
    },
  ];

  const createdFormColumns = [
    {
      field: "is_notarized",
      headerName: "Notarized",
      // type: "actions",
      width: 80,
      renderCell: (params) => {
        if (
          params?.row?.is_notarized === true &&
          params?.row?.reason !== "record"
        ) {
          return (
            <Tooltip title="Notarized" arrow>
              <CheckCircleIcon
                sx={{ ml: "1rem", color: "#4CAF50" }}
                width={25}
              />
            </Tooltip>
          );
        } else if (
          params?.row?.is_notarized === false &&
          params?.row?.reason !== "record"
        ) {
          return (
            <Tooltip title="Notarization in Progress" arrow>
              <CachedIcon
                className="notary-rotate"
                sx={{ color: "#5C2C06", ml: "1rem" }}
                width={25}
              />
            </Tooltip>
          );
        } else if (params?.row?.reason === "record") {
          return (
            <Typography sx={{ ml: "1.5rem", fontWeight: "bold" }}>-</Typography>
          );
        } else if (
          params?.row?.reason !== "record" &&
          params?.row?.notary_failed === true
        ) {
          return (
            <Tooltip title="Recording Failed">
              <RemoveCircleOutlineIcon
                color="error"
                sx={{ ml: "1rem", cursor: "pointer" }}
                width={25}
              />
            </Tooltip>
          );
        } else {
          return (
            <IconButton
              variant="contained"
              sx={{ color: "#5C2C06", cursor: "auto", visibility: "hidden" }}
            >
              <CachedIcon width={25} />
            </IconButton>
          );
        }
      },
    },
    {
      field: "record",
      headerName: "Recorded",
      // type: "actions",
      width: 80,
      renderCell: (params) => {
        if (
          params?.row?.is_notarized === true &&
          params?.row?.status === "Completed" &&
          params?.row?.reason === "notaryRecord"
        ) {
          return (
            <Tooltip title="Recorded" arrow>
              <IconButton
                variant="contained"
                sx={{ color: "#4caf50", cursor: "auto" }}
              >
                <CheckCircleIcon width={25} />
              </IconButton>
            </Tooltip>
          );
        } else if (
          params?.row?.recording?.is_recorded === true &&
          params?.row?.recording?.in_progress === false &&
          params?.row?.reason === "record" &&
          params?.row?.is_active === false
        ) {
          return (
            <Tooltip title="Recorded" arrow>
              <IconButton
                variant="contained"
                sx={{ color: "#4caf50", cursor: "auto" }}
              >
                <CheckCircleIcon width={25} />
              </IconButton>
            </Tooltip>
          );
        } else if (
          params?.row?.is_notarized === true &&
          params?.row?.reason === "notaryRecord" &&
          params?.row?.recording?.in_progress === true
        ) {
          return (
            <Tooltip title="Recording in Progress" arrow>
              <IconButton
                className="notary-rotate"
                variant="contained"
                sx={{ color: "#5C2C06", cursor: "wait" }}
              >
                <CachedIcon width={25} />
              </IconButton>
            </Tooltip>
          );
        } else if (
          params?.row?.reason === "record" &&
          params?.row?.is_active === true &&
          params?.row?.recording?.is_recorded === false
        ) {
          return (
            <Tooltip title="Recording in Progress" arrow>
              <IconButton
                className="notary-rotate"
                variant="contained"
                sx={{ color: "#5C2C06", cursor: "wait" }}
              >
                <CachedIcon width={25} />
              </IconButton>
            </Tooltip>
          );
        } else if (
          params?.row?.reason !== "notary" &&
          params?.row?.recording_failed === true
        ) {
          return (
            <Tooltip title="Recording Failed">
              <RemoveCircleOutlineIcon
                color="error"
                sx={{ ml: "1rem", cursor: "pointer" }}
                width={25}
              />
            </Tooltip>
          );
        } else if (params?.row?.reason === "notary") {
          return (
            <Typography sx={{ ml: "1rem", fontWeight: "bold" }}>-</Typography>
          );
        } else {
          return (
            <IconButton
              variant="contained"
              sx={{ color: "#5C2C06", cursor: "auto", visibility: "hidden" }}
            >
              <CachedIcon width={25} />
            </IconButton>
          );
        }
      },
    },
    {
      field: "pdf1",
      headerName: "",
      // type: "actions",
      width: 50,
      valueGetter: (params) => {
        // Access the nested property
        return params.row.document?.pdf1 || "";
      },
      renderCell: (params) => {
        const pdfUrl = params.value;
        if (
          params?.row?.status !== "Completed" &&
          params?.row?.reason !== "record"
        ) {
          return (
            <div>
              <IconButton
                disabled
                variant="contained"
                color="primary"
                sx={{ visibility: "hidden" }}
              >
                <DocumentArrowDownIcon width={25} />
              </IconButton>
            </div>
          );
        } else if (
          params?.row?.recording?.is_recorded === false &&
          params?.row?.reason === "record"
        ) {
          return (
            <div>
              <IconButton
                disabled
                variant="contained"
                color="primary"
                sx={{ visibility: "hidden" }}
              >
                <DocumentArrowDownIcon width={25} />
              </IconButton>
            </div>
          );
        } else {
          const isLoading = downloadingRowId === params.row.id;
          if (isLoading === true) {
            return (
              <div>
                <CachedIcon
                  className="notary-rotate"
                  sx={{ color: "#004976", ml: 1 }}
                  width={25}
                />
              </div>
            );
          } else {
            return (
              <div>
                <Tooltip title="Download" arrow>
                  <IconButton
                    onClick={() => handleDownloadNoc(params?.row)}
                    variant="contained"
                    color="primary"
                  >
                    <DocumentArrowDownIcon width={25} />
                  </IconButton>
                </Tooltip>
              </div>
            );
          }
        }
      },
    },
    {
      field: "actions",
      headerName: "",
      // type: "actions",
      width: 50,
      renderCell: (params) => {
        if (
          params?.row?.status === "Waiting for Signee" ||
          params?.row?.status === "Waiting for Document(s)"
        ) {
          return (
            <div>
              <Tooltip title="Actions" arrow>
                <IconButton
                  variant="contained"
                  color="primary"
                  onClick={() => handleSelected(params)}
                >
                  <ManageAccountsIcon width={25} />
                </IconButton>
              </Tooltip>
            </div>
          );
        } else if (
          params?.row?.status === "Completed" &&
          params?.row?.reason === "notary" &&
          !unavailable_record_list.includes(params?.row?.county)
        ) {
          return (
            <Tooltip title="Record Document">
              <IconButton
                size="small"
                sx={{ padding: 0, mt: "2px", mr: "3px" }}
                variant="contained"
                color="warning"
                onClick={() => handleRecordSelected(params)}
              >
                <NotarizeIcon color="#5C2C06" width={40} />
              </IconButton>
            </Tooltip>
          );
        } else {
          return (
            <div>
              <IconButton
                disabled
                variant="contained"
                color="primary"
                sx={{ visibility: "hidden" }}
              >
                <ManageAccountsIcon width={25} />
              </IconButton>
            </div>
          );
        }
      },
    },
    {
      field: "local_time",
      headerName: "Started Date",
      width: 200,
      editable: false,
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      editable: false,
    },
    {
      field: "property_address",
      headerName: "Property",
      width: 300,
      editable: false,
      valueGetter: (params) => {
        return params.row.document?.property_address || "";
      },
    },
    {
      field: "reason",
      headerName: "Processing Type",
      width: 230,
      editable: false,
      valueGetter: (params) => {
        // Ensure the property exists and is a string
        if (params.row.reason === "notaryRecord") {
          return "Notarization and Recording";
        } else if (params.row.reason === "notary") {
          return "Notarization";
        } else if (params.row.reason === "record") {
          return "Recording";
        }
        return "";
      },
    },
    {
      field: "document_type",
      headerName: "Document Type",
      width: 230,
      editable: false,
      valueGetter: (params) => {
        // Ensure the property exists and is a string
        if (
          params.row.document_type &&
          typeof params.row.document_type === "string"
        ) {
          return toTitleCase(params.row.document_type);
        }
        return "";
      },
    },

    {
      field: "email",
      headerName: "Signee Email",
      width: 230,
      editable: false,
    },
  ];

  // !FAILED COLUMNS
  const failedFormColumns = [
    {
      field: "is_notarized",
      headerName: "Notarized",
      // type: "actions",
      width: 80,
      renderCell: (params) => {
        if (
          params?.row?.notary_failed === true &&
          params?.row?.reason !== "record"
        ) {
          return (
            <Tooltip title="Notarization Failed" arrow>
              <CancelIcon sx={{ ml: "1rem", color: "red" }} width={25} />
            </Tooltip>
          );
        } else if (
          params?.row?.is_notarized === true &&
          params?.row?.notary_failed === false &&
          params?.row?.reason === "notaryRecord"
        ) {
          return (
            <Tooltip title="Notarized" arrow>
              <CheckCircleIcon
                sx={{ ml: "1rem", color: "#4caf50" }}
                width={25}
              />
            </Tooltip>
          );
        } else if (params?.row?.reason === "record") {
          return (
            <Typography sx={{ ml: "1.5rem", fontWeight: "bold" }}>-</Typography>
          );
        } else {
          return (
            <IconButton
              variant="contained"
              sx={{ color: "#5C2C06", cursor: "auto", visibility: "hidden" }}
            >
              <CachedIcon width={25} />
            </IconButton>
          );
        }
      },
    },
    {
      field: "record",
      headerName: "Recorded",
      // type: "actions",
      width: 80,
      renderCell: (params) => {
        if (
          params?.row?.recording_failed === true &&
          (params?.row?.reason === "record" ||
            params?.row?.reason === "notaryRecord")
        ) {
          return (
            <Tooltip title="Notarization Failed" arrow>
              <CancelIcon sx={{ ml: "1rem", color: "red" }} width={25} />
            </Tooltip>
          );
        } else if (params?.row?.reason === "notary") {
          return (
            <Typography sx={{ ml: "1.5rem", fontWeight: "bold" }}>-</Typography>
          );
        } else {
          return (
            <IconButton
              variant="contained"
              sx={{ color: "#5C2C06", cursor: "auto", visibility: "hidden" }}
            >
              <CachedIcon width={25} />
            </IconButton>
          );
        }
      },
    },
    {
      field: "pdf1",
      headerName: "",
      // type: "actions",
      width: 50,
      valueGetter: (params) => {
        // Access the nested property
        return params.row.document?.pdf1 || "";
      },
      renderCell: (params) => {
        const pdfUrl = params.value;
        if (
          params?.row?.status !== "Completed" &&
          params?.row?.reason !== "record"
        ) {
          return (
            <div>
              <IconButton
                disabled
                variant="contained"
                color="primary"
                sx={{ visibility: "hidden" }}
              >
                <DocumentArrowDownIcon width={25} />
              </IconButton>
            </div>
          );
        } else if (
          params?.row?.recording?.is_recorded === false &&
          params?.row?.reason === "record"
        ) {
          return (
            <div>
              <IconButton
                disabled
                variant="contained"
                color="primary"
                sx={{ visibility: "hidden" }}
              >
                <DocumentArrowDownIcon width={25} />
              </IconButton>
            </div>
          );
        } else {
          return (
            <div>
              <Tooltip title="Download" arrow>
                <IconButton
                  href={pdfUrl}
                  target="_blank"
                  variant="contained"
                  color="primary"
                >
                  <DocumentArrowDownIcon width={25} />
                </IconButton>
              </Tooltip>
            </div>
          );
        }
      },
    },
    {
      field: "actions",
      headerName: "",
      // type: "actions",
      width: 50,
      renderCell: (params) => {
        const pdfUrl = params.value;
        if (
          params?.row?.status === "Waiting for Signee" ||
          params?.row?.status === "Waiting for Document(s)"
        ) {
          return (
            <div>
              <Tooltip title="Actions" arrow>
                <IconButton
                  variant="contained"
                  color="primary"
                  onClick={() => handleSelected(params)}
                >
                  <ManageAccountsIcon width={25} />
                </IconButton>
              </Tooltip>
            </div>
          );
        } else if (
          params?.row?.status === "Completed" &&
          params?.row?.reason === "notary"
        ) {
          return (
            <Tooltip title="Actions">
              <IconButton
                variant="contained"
                color="warning"
                onClick={() => handleSelected(params)}
              >
                <SettingsIcon width={20} />
              </IconButton>
            </Tooltip>
          );
        } else if (
          params?.row?.status === "Notarization Failed" ||
          params?.row?.status === "Recording Failed"
        ) {
          return (
            <Tooltip title="Manage failed document">
              <IconButton
                variant="contained"
                color="primary"
                onClick={() => handleFailedForm(params)}
              >
                <SettingsIcon width={20} />
              </IconButton>
            </Tooltip>
          );
        } else {
          return (
            <div>
              <IconButton
                disabled
                variant="contained"
                color="primary"
                sx={{ visibility: "hidden" }}
              >
                <ManageAccountsIcon width={25} />
              </IconButton>
            </div>
          );
        }
      },
    },
    {
      field: "local_time",
      headerName: "Failure Date",
      width: 200,
      editable: false,
    },
    {
      field: "status",
      headerName: "Reason",
      width: 150,
      editable: false,
      valueGetter: (params) => {
        if (params.row.reason === "notaryRecord" && params.row.notary_failed) {
          return params.row.notary_failure?.[0]?.reason;
        } else if (
          params.row.reason === "notaryRecord" &&
          params.row.recording_failed
        ) {
          return params.row.recording?.failure?.[0]?.reason;
        } else if (params.row.reason === "notary" && params.row.notary_failed) {
          return params.row.notary_failure?.[0]?.reason;
        } else if (
          params.row.reason === "record" &&
          params.row.recording_failed
        ) {
          return params.row.recording?.failure?.[0];
        }
        return "";
      },
    },
    {
      field: "property_address", // A simple field name for reference
      headerName: "Property",
      width: 300,
      editable: false,
      valueGetter: (params) => {
        // Access the nested property
        return params.row.document?.property_address || "";
      },
    },
    {
      field: "reason",
      headerName: "Processing Type",
      width: 230,
      editable: false,
      valueGetter: (params) => {
        // Ensure the property exists and is a string
        if (params.row.reason === "notaryRecord") {
          return "Notarization and Recording";
        } else if (params.row.reason === "notary") {
          return "Notarization";
        } else if (params.row.reason === "record") {
          return "Recording";
        }
        return "";
      },
    },
    {
      field: "document_type",
      headerName: "Document Type",
      width: 230,
      editable: false,
      valueGetter: (params) => {
        // Ensure the property exists and is a string
        if (
          params.row.document_type &&
          typeof params.row.document_type === "string"
        ) {
          return toTitleCase(params.row.document_type);
        }
        return "";
      },
    },

    {
      field: "email",
      headerName: "Signee Email",
      width: 230,
      editable: false,
    },
  ];
  return (
    <>
      {loading ? (
        <>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100vh",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {/* <CircularProgress color="warning" size="10rem" /> */}
            <Typography variant="h1" align="center">
              Coming Soon
            </Typography>
          </Box>
        </>
      ) : !tabletSize ? (
        <>
          <Container maxWidth="xl">
            <Typography
              variant="h3"
              sx={{ color: "#6C737F" }}
              align="center"
              mt={2}
            >
              Building Permits
            </Typography>
            <SealRecordGrids
              currentUser={currentUser}
              createdFormColumns={createdFormColumns}
              failedFormColumns={failedFormColumns}
            />
          </Container>
        </>
      ) : (
        //! MOBILE BLOCK ////////////////////////////////////////
        <Box
          sx={{
            mx: largePC ? "auto" : 0,
            maxWidth: tabletSize ? undefined : "1500px",
            height: tabletSize ? "88vh" : undefined,
            py: tabletSize ? 0 : 1,
            backgroundColor: tabletSize ? "#fff" : "#EEF2F6",
            overflowY: tabletSize ? "hidden" : undefined,
          }}
        >
          <MobileSealRecord
            currentUser={currentUser}
            createdFormColumns={createdFormColumns}
            failedFormColumns={failedFormColumns}
            handleInternalClick={handleInternalClick}
            handleExternalClick={handleExternalClick}
            handleFailedClick={handleFailedClick}
            internalEnabled={internalEnabled}
            externalEnabled={externalEnabled}
            failedEnabled={failedEnabled}
            createdFormMobile={createdFormMobile}
            handleOpen={handleOpen}
            rowOpen={rowOpen}
            rowIndex={rowIndex}
            tabletSize={tabletSize}
            internalRef={internalRef}
            externalRef={externalRef}
            failedRef={failedRef}
            currentRows={currentRows}
          />
        </Box>
        //! ////////////////////////////////////////////////////
      )}
      {rowOpen && (
        <MobileSealRecordModal
          selectedRow={selectedRow}
          internalEnabled={internalEnabled}
          externalEnabled={externalEnabled}
          failedEnabled={failedEnabled}
          rowIndex={rowIndex}
          rowOpen={rowOpen}
          handleClose={handleClose}
          mobileSize={mobileSize}
          tabletSize={tabletSize}
          modalTitle={modalTitle}
          overviewEnabled={overviewEnabled}
          showFormEnabled={showFormEnabled}
          settingsEnabled={settingsEnabled}
          handleOverviewClick={handleOverviewClick}
          handleShowFormClick={handleShowFormClick}
          handleSettingsClick={handleSettingsClick}
          handleSwipe={handleSwipe}
          currentRows={currentRows}
          status={status}
          timezone={
            currentUser?.subuser
              ? currentUser?.subuser?.timezone
              : currentUser?.user?.personal?.[0]?.timeZone || "America/New_York"
          }
          mobileDownloading={mobileDownloading}
          handleDownloadNoc={handleDownloadNoc}
          reminderMessage={reminderMessage}
          reminderSent={reminderSent}
          changeEmailLoading={changeEmailLoading}
          reminderLoading={reminderLoading}
          handleSendReminder={handleSendReminder}
          emailChanged={emailChanged}
          newEmail={newEmail}
          handleEmailChange={handleEmailChange}
          emailError={emailError}
          emailMessage={emailMessage}
          submitEmailChange={submitEmailChange}
          handleCancelNotarization={handleCancelNotarization}
          failures={failures}
          handleRepeateNotarization={handleRepeateNotarization}
          isCollapseOpen={isCollapseOpen}
          setIsCollapseOpen={setIsCollapseOpen}
          cancelSelected={cancelSelected}
          setCancelSelected={setCancelSelected}
          cancelDisabled={cancelDisabled}
          setCancelDisabled={setCancelDisabled}
          restartSelected={restartSelected}
          setRestartSelected={setRestartSelected}
          restartDisabled={restartDisabled}
          setRestartDisabled={setRestartDisabled}
        />
      )}
      <DocumentSettingsModal
        modal={modal}
        setModal={setModal}
        emailMessage={emailMessage}
        emailError={emailError}
        setEmailError={setEmailError}
        reminderMessage={reminderMessage}
        changeEmailLoading={changeEmailLoading}
        selectedRow={selectedRow}
        newEmail={newEmail}
        setNewEmail={setNewEmail}
        setReminderSent={setReminderSent}
        handleEmailChange={handleEmailChange}
        submitEmailChange={submitEmailChange}
        emailText={emailText}
        emailChanged={emailChanged}
        setEmailChanged={setEmailChanged}
        handleSendReminder={handleSendReminder}
        reminderLoading={reminderLoading}
        reminderSent={reminderSent}
        setReminderMessage={setReminderMessage}
      />
      <DocumentRecordModal
        recordModal={recordModal}
        setRecordModal={setRecordModal}
        selectedRow={selectedRow}
        recordLoading={recordLoading}
        submitStartRecord={submitStartRecord}
        recordText={recordText}
      />
      <DocumentFailedModal
        failedModal={failedModal}
        setFailedModal={setFailedModal}
        selectedRow={selectedRow}
        failures={failures}
        setFailures={setFailures}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
        notary_price={notaryPrice}
        handleCancelNotarization={handleCancelNotarization}
        handleRepeateNotarization={handleRepeateNotarization}
      />
    </>
  );
};

export default PermitInvitations;
