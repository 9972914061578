import React, { useState, useEffect } from "react";
import { useField, useFormikContext } from "formik";
import {
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  Typography,
  FormHelperText,
  Tooltip,
} from "@mui/material";

const SelectField = ({
  validLicense,
  name,
  label,
  setCustomLicenseType,
  buttons,
  setButtons,
  page,
  setDisableInputs,
  contractorList,
  setContractorList,
  setLicenseText,
  setContractorType,
  disableInputs,
  numSelection,
  combinedLicenses,
  mobileSize,
  size,
  ...props
}) => {
  const [field, meta, helpers] = useField(name);
  const { setFieldValue, setTouched } = useFormikContext();
  const [maxWidth, setMaxWidth] = useState("90%");
  let maxLength = 0;

  if (name === "licenseType") {
    maxLength = 100;
  } else {
    maxLength = 70; // For example, limit to 20 characters
  }

  useEffect(() => {
    const atLeastOneSelected = field.value && field.value.length > 0;
    const maxWidthSelection = atLeastOneSelected ? "100%" : "90%";
    setMaxWidth(maxWidthSelection);

    if (field.name === "licenseType") {
      setCustomLicenseType(field.value);
    }
  }, [field.value]);

  const handleChange = (event) => {
    const newValue = event.target.value;
    let shortLicense = null;

    if (name === "licenseType" && mobileSize) {
      shortLicense = combinedLicenses.filter((tuple) => tuple[0] === newValue);
      shortLicense = shortLicense[0][1];
    }

    setTouched({});
    // Update the buttons array based on the current page index
    if (name === "contractorType") {
      setContractorType(newValue);
    }

    if (name === "licenseType") {
      setLicenseText("");
      setDisableInputs(false);
      const updatedButtons = [...buttons];

      if (mobileSize && shortLicense) {
        updatedButtons[page] = shortLicense;
        setButtons(updatedButtons);
      } else {
        updatedButtons[page] = newValue;
        setButtons(updatedButtons);
      }

      // Update the contractorList with the new licenseType
      setContractorList((prevContractorList) => {
        const updatedList = [...prevContractorList];
        if (updatedList[page]) {
          updatedList[page].licenseType = newValue;
          updatedList[page].validLicense = true;
        }
        return updatedList;
      });

      setFieldValue("licenseNumber", "");
    }

    // Handle single select and multi-select differently
    if (props.multiple) {
      // Ensure no more than 5 items are selected
      if (newValue.length <= numSelection) {
        helpers.setValue(newValue); // Use formik helper to set value
      }
    } else {
      helpers.setValue(newValue); // Use formik helper to set value
    }

    // Enable validation
    setTouched({});
  };

  const renderSelectedValues = (selected) => {
    if (!Array.isArray(selected)) {
      selected = [selected]; // Convert to an array if not already
    }

    if (selected.length === 0) {
      return <em>Select an option</em>;
    }

    // Join the selected items into a string
    const selectedText = selected.join(", ");

    // Truncate the text if it exceeds the maximum length
    const truncatedText =
      selectedText.length > maxLength
        ? selectedText.substring(0, maxLength) + "..."
        : selectedText;

    return truncatedText;
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  return (
    <FormControl
      fullWidth
      disabled={Boolean(disableInputs)}
      variant="filled"
      error={meta.touched && Boolean(meta.error)}
    >
      <Tooltip title={label}>
        <InputLabel
          id={label}
          sx={{
            maxWidth: maxWidth, // Limit the label's width
            whiteSpace: "nowrap", // Prevent label from wrapping
            overflow: "hidden",
            textOverflow: "ellipsis", // Add ellipsis for overflow
          }}
        >
          {label}
        </InputLabel>
      </Tooltip>
      <Select
        // disabled={name === "licenseType" && validLicense}
        // disabled={Boolean(disableInputs)}
        size={size || "normal"}
        labelId={label}
        id={name}
        label={label}
        multiple={props.multiple}
        value={field.value || (props.multiple ? [] : "")}
        onChange={handleChange}
        MenuProps={{
          PaperProps: {
            style: {
              marginTop: "1.5px",
              maxHeight: 150, // Set the desired max height for the dropdown menu
              width: "auto",
              maxWidth: "100%",
            },
          },
        }}
        sx={{
          maxWidth: "100%", // Set the desired max-width for the input element
          "& .MuiTypography-body2": {
            whiteSpace: "wrap", // Prevent text from wrapping or shrinking
          },
        }}
        // {...field}
        renderValue={renderSelectedValues}
        {...props}
        // helpertext={
        //   meta.touched && meta.error ? meta.error.toString() : "false"
        // }
      >
        {props.menu?.map(function (item, i) {
          const truncatedItem = truncateText(item, 70); // Truncate item if too long
          const isDisabled =
            name === "licenseType" &&
            contractorList.some((obj) => obj.licenseType === item);

          return (
            <MenuItem disabled={isDisabled} key={i} value={item}>
              {item.length > maxLength ? (
                <Tooltip title={item}>
                  <Typography variant="body2">{truncatedItem}</Typography>
                </Tooltip>
              ) : (
                <Typography variant="body2">{item}</Typography>
              )}
            </MenuItem>
          );
        })}
      </Select>
      <FormHelperText>
        {meta.touched && meta.error ? meta.error.toString() : ""}
      </FormHelperText>
    </FormControl>
  );
};

export default SelectField;
