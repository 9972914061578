import { DataGrid } from "@mui/x-data-grid";
import { Card, CardHeader, CardContent, useMediaQuery } from "@mui/material";

const SealRecordGrids = ({
  currentUser,
  createdFormColumns,
  failedFormColumns,
}) => {
  return (
    <>
      <Card sx={{ position: "relative", mt: 3 }}>
        <CardHeader
          title={`${" \u00A0\u00A0"}I N T E R N A L${" \u00A0\u00A0"}D O C U M E N T S`}
        />
        <CardContent>
          <DataGrid
            sx={{
              "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                outline: "none !important",
              },
              "& .MuiDataGrid-columnHeader:focus-within": {
                outline: "none !important",
              },
            }}
            rows={currentUser?.notary_forms?.internal || []}
            columns={createdFormColumns}
            getRowId={(row) => row?.id}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5, 10, 25, 50, 100]}
            disableRowSelectionOnClick
          />
        </CardContent>
      </Card>

      <Card sx={{ position: "relative", mt: 3 }}>
        <CardHeader
          title={`${" \u00A0\u00A0"}E X T E R N A L${" \u00A0\u00A0"}D O C U M E N T S`}
        />
        <CardContent>
          <DataGrid
            sx={{
              "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                outline: "none !important",
              },
              "& .MuiDataGrid-columnHeader:focus-within": {
                outline: "none !important",
              },
            }}
            rows={currentUser?.notary_forms?.external || []}
            columns={createdFormColumns}
            getRowId={(row) => row?.id}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5, 10, 25, 50, 100]}
            disableRowSelectionOnClick
          />
        </CardContent>
      </Card>
      <Card sx={{ position: "relative", mt: 3, backgroundColor: "#FCF2F0" }}>
        <CardHeader
          sx={{ color: "red" }}
          title={`${" \u00A0\u00A0"}F A I L E D${" \u00A0\u00A0"}D O C U M E N T S`}
        />
        <CardContent>
          <DataGrid
            sx={{
              "& .MuiDataGrid-columnHeaders": {
                padding: 0, // Adjust as needed
                margin: 0, // Adjust as needed
              },
              "& .MuiDataGrid-columnHeadersInner": {
                width: "100%", // Adjust as needed
              },
              border: "1px solid #FCF2F0", // Change the border color of the entire DataGrid
              "& .MuiDataGrid-columnHeader": {
                backgroundColor: "#FFCCCC", // Change column header background color
                color: "black", // Optional: Change text color for contrast
              },
              "& .MuiDataGrid-cell": {
                borderColor: "#FCF2F0", // Change cell border color
              },
              "& .MuiDataGrid-columnSeparator": {
                display: "none", // Optional: Hide the column separators
              },
              "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                outline: "none !important",
              },
              "& .MuiDataGrid-columnHeader:focus-within": {
                outline: "none !important",
              },
            }}
            rows={currentUser?.notary_forms?.failed || []}
            columns={failedFormColumns || []}
            getRowId={(row) => row?.id}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5, 10, 25, 50, 100]}
          />
        </CardContent>
      </Card>
    </>
  );
};

export default SealRecordGrids;
