import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Tooltip,
  IconButton,
} from "@mui/material";

const NotaryCard = ({ title, value, icon: IconComponent, color, tooltip }) => {
  return (
    <Card
      raised
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: "100%",
      }}
    >
      <CardContent sx={{ flexGrow: 1 }}>
        <Typography variant="subtitle1" color="textSecondary">
          {title}
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            pt: 2,
          }}
        >
          <Typography variant="h5">{value}</Typography>
          <Tooltip title={tooltip}>
            <IconComponent fontSize="large" sx={{ color }} />
          </Tooltip>
        </Box>
      </CardContent>
    </Card>
  );
};

export default NotaryCard;
