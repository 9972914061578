import axios from "axios";
import { createDynamicReducer } from "../initailazeReducer";
import store from "../store";
import { DateTime } from "luxon";
import { fetchUserData } from "./userActions";
import { getFirstNocData } from "./dataActions";
import { fetchGuestData } from "./guestActions";
import { fetchNotaryData } from "./notaryActions";
import { fetchSubUserData } from "./subUserActions";
import { disconnectWebsocket } from "./websocketActions";

export const setExpiredSession = (isExpired) => {
  return {
    type: "SET_EXPIRED_SESSION",
    payload: isExpired,
  };
};

export const setWillExpireAlert = (data) => {
  if (data.sessionName === "userSession") {
    return {
      type: "SET_WILL_USER_EXPIRE_ALERT",
      payload: data.timeLeft,
    };
  } else if (data.sessionName === "subUserSession") {
    return {
      type: "SET_WILL_SUB_USER_EXPIRE_ALERT",
      payload: data.timeLeft,
    };
  }
};

export const extendExpiry = (sessionName) => {
  const extendedTime = Math.floor(DateTime.utc().toMillis() / 1000) + 3600;
  const data = { sessionName: sessionName, duration: extendedTime };

  if (sessionName === "userSession") {
    return {
      type: "EXTEND_USER_EXPIRY",
      payload: data,
    };
  }
};

export const setCurrentSession =
  (sessionName) => async (dispatch, getState) => {
    let data = null;
    const newReducers = createDynamicReducer(sessionName);
    store.replaceReducer(newReducers);

    if (sessionName === "userSession") {
      data = await dispatch(fetchUserData(sessionName));
      await dispatch(getFirstNocData(sessionName));
    } else if (sessionName === "subUserSession") {
      data = await dispatch(fetchSubUserData(sessionName));
      await dispatch(getFirstNocData(sessionName));
    } else if (sessionName === "guestSession") {
      data = await dispatch(fetchGuestData(sessionName));
    } else if (sessionName === "notarySession") {
      data = await dispatch(fetchNotaryData(sessionName));
    }

    if (data?.success === false) {
      window.location.reload();
    }

    dispatch({
      type: "SET_CURRENT_SESSION",
      payload: sessionName,
    });
  };

export const logoutHandler = (currentSession) => async (dispatch, getState) => {
  try {
    await axios.post(
      `${process.env.REACT_APP_BASE_URL}/nocApi/auth/logout`,
      {
        sessionType: currentSession,
      },
      {
        withCredentials: true,
      }
    );
    await dispatch(disconnectWebsocket(currentSession));
    const storedRole = sessionStorage.getItem("__nocCreator__r_rid");
    if (storedRole) {
      sessionStorage.removeItem("__nocCreator__r_rid");
      sessionStorage.removeItem("exp");
    }
    return true;
  } catch {
    return true;
  }
};

export const updateSession = (data) => async (dispatch) => {
  try {
    // Dispatch the synchronous action
    dispatch({
      type: "UPDATE_SESSION",
      payload: data,
    });
    return Promise.resolve(); // Return a resolved promise to allow .then() chaining
  } catch (error) {
    return Promise.reject(error); // Return a rejected promise if there's an error
  }
};

export const updateSessionWsConnection = (data) => {
  return {
    type: "UPDATE_SESSION_WS_CONNECTED",
    payload: data,
  };
};

export const resetSession = (resetSessionType) => {
  return {
    type: "RESET_SESSION",
    payload: resetSessionType,
  };
};

export const setRole = (role) => {
  return {
    type: "SET_ROLE",
    payload: role,
  };
};

export const clearRole = () => {
  return {
    type: "CLEAR_ROLE",
  };
};

export const invalidateSession = (invalidateSessionType) => {
  return {
    type: "INVALIDATE_SESSION",
    payload: invalidateSessionType,
  };
};

export const validateRole = () => async (dispatch) => {
  const storedRole = JSON.parse(sessionStorage.getItem("__nocCreator__r_rid"));
  if (storedRole) {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/sessions/validate-role`,
        {
          role: storedRole,
        },
        {
          withCredentials: true,
        }
      );

      if (response.data.success) {
        await dispatch(setRole(response.data.role));
        return true;
      } else {
        sessionStorage.removeItem("__nocCreator__r_rid");
        sessionStorage.removeItem("exp");
        dispatch(clearRole());
        return false;
      }
    } catch (error) {
      console.error("Error validating role:", error);
      sessionStorage.removeItem("__nocCreator__r_rid");
      sessionStorage.removeItem("exp");
      dispatch(clearRole());
      return false;
    }
  } else {
    dispatch(clearRole());
    return false;
  }
};

export const validateSession =
  (currentSession, requiredCookie, needSocket) => async (dispatch) => {
    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/sessions/validate-session`,
        {
          sessionName: currentSession,
          cookieName: requiredCookie,
          requiredSocket: needSocket,
        },
        { withCredentials: true }
      );
      if (
        resp?.data?.data?.isValidated === true &&
        resp?.data?.success === true
      ) {
        await dispatch(updateSession(resp?.data?.data));
      } else {
        await dispatch(disconnectWebsocket(currentSession));
        // await dispatch(invalidateSession(currentSession));
        sessionStorage.removeItem("__nocCreator__r_rid");
        sessionStorage.removeItem("exp");
        return false;
      }
    } catch (error) {
      await dispatch(disconnectWebsocket(currentSession));
      // await dispatch(invalidateSession(currentSession));
      sessionStorage.removeItem("__nocCreator__r_rid");
      sessionStorage.removeItem("exp");
      return false;
    }
  };
