import { useFormik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { useState, useEffect } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Stack,
  Link,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  InputAdornment,
  IconButton,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";

const ResetPasswordComponent = () => {
  const theme = useTheme();
  const [errorText, setErrorText] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [emailConfirmed, setEmailConfirmed] = useState(false);
  const navigate = useNavigate();
  const mobileSize = useMediaQuery(theme.breakpoints.down("sm"));
  const tabletSize = useMediaQuery(theme.breakpoints.down("md"));

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      repassword: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Must be a valid email")
        .max(255)
        .required("Email is required"),
      password: Yup.string()
        .min(8, "password must contain 8 or more characters")
        .max(255)
        .minLowercase(1, "password must contain at least 1 lower case letter")
        .minUppercase(1, "password must contain at least 1 upper case letter")
        .minNumbers(1, "password must contain at least 1 number")
        .minSymbols(1, "password must contain at least 1 special character")
        .required("Password is required"),
      repassword: Yup.string()
        .required("Password confirmation is required")
        .test("passwords-match", "Passwords must match", function (value) {
          return this.parent.password === value;
        }),
    }),
    onSubmit: async (values, helpers) => {
      setLoading(true);
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/auth/2fa/beforepwdChange`,
          {
            email: values.email,
            password: values.password,
            repassword: values.repassword,
          }
        );

        if (res.data.success) {
          if (res.data.data === "emailFa") {
            const token = res.data.token;
            navigate(`/2fa-secure/reset-pwd/email?token=${token}`);
            return;
          } else if (res.data.data === "totp") {
            const token = res.data.token;
            navigate(`/2fa-secure/reset-pwd/totp?token=${token}`);
            return;
          } else if (res.data.data === "smsFa") {
            const token = res.data.token;
            navigate(`/2fa-secure/reset-pwd/sms?token=${token}`);
            return;
          }
        }
        ////////////////////////////////////
      } catch (err) {
        helpers.resetForm();
        setErrorText(err.response.data.message);
        setLoading(false);
        helpers.setStatus({ success: false });
        helpers.setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (emailConfirmed === true && formik?.values?.email === "") {
      setEmailConfirmed(false);
    }
  }, [emailConfirmed, formik?.values?.email]);

  const handleValidateEmail = async () => {
    try {
      setLoading(true);
      if (formik.values.email === "" || formik.errors.email) {
        formik.setFieldError("email", "Email is required");
        formik.setFieldTouched("email", true);
        setLoading(false);
        return;
      }
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/auth/verify-email-exists`,
        {
          email: formik?.values?.email,
        }
      );
      if (res?.data?.success === true) {
        setEmailConfirmed(true);
        setLoading(false);
      } else {
        formik.setFieldValue("email", "");
        setEmailConfirmed(false);
        setLoading(false);
        setErrorText(res.data.message);
      }
    } catch (e) {
      setLoading(false);
      formik.setFieldValue("email", "");
      formik.setFieldTouched("email", true);
      setErrorText(e.response.data.message);
    }
  };

  useEffect(() => {
    if (errorText !== "") {
      const timer = setTimeout(() => setErrorText(""), 5000);
      return () => clearTimeout(timer);
    }
  }, [errorText]);

  return (
    // <Box
    //   sx={{
    //     display: "flex",
    //     flexDirection: "column",
    //     height: "100vh", // Use 100% of the viewport height
    //     justifyContent: "space-between", // Spread content and footer
    //   }}
    // >
    //   {/* Content Box */}
    //   <Box
    //     sx={{
    //       flexGrow: 1, // Allow this box to grow and push the footer down
    //       display: "flex",
    //       flexDirection: "column",
    //       justifyContent: mobileSize ? "flex-start" : "center", // Adjust this for mobile vs desktop
    //       alignItems: "center",
    //       overflow: "auto", // Enable scrolling for overflow
    //     }}
    //   ></Box>
    <>
      <Box
        sx={
          mobileSize
            ? {
                justifyContent: "center",
                overflow: "auto",

                minHeight: "100vh",
                overflowY: "hidden",
                display: "flex",
                mx: 1,
                flexDirection: "column",
              }
            : {
                backgroundImage: "url('/img/website_img/homeImg.jpeg')",
                backgroundRepeat: "repeat",

                minHeight: "100vh",
                overflow: "hidden",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }
        }
      >
        <Box
          sx={
            mobileSize
              ? {
                  backgroundColor: "#fff",
                  pb: "1rem",
                  pt: "2rem",

                  width: "100%",
                  flexGrow: 1,
                }
              : {
                  backgroundColor: "#fff",
                  mt: 2,
                  maxWidth: 700,
                  borderRadius: "25px",
                  borderBottomLeftRadius: "0px",
                  borderBottomRightRadius: "0px",
                  px: 3,
                  py: "10px",
                  width: "100%",
                  pt: "2rem",
                }
          }
        >
          {/* <div
            style={
              mobileSize
                ? { width: "100%", padding: "1rem" }
                : { padding: "1rem" }
            }
          > */}
          <Stack spacing={1} sx={{ mb: "8em" }}>
            <Box
              sx={{ cursor: "pointer" }}
              display="flex"
              justifyContent="center"
              onClick={() => {
                navigate("/");
              }}
            >
              <img
                style={{
                  width: "10rem",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginTop: "-9px",
                }}
                alt="Permit Rockstar Logo"
                src="/img/website_img/PermitRockstar.png"
              />
            </Box>
            <Typography
              sx={{ textAlign: "center" }}
              color="#004976"
              variant="h4"
            >
              Reset Password
            </Typography>
            <Typography
              sx={{ textAlign: "center" }}
              color="#004976"
              variant={mobileSize ? "body1" : "h6"}
            >
              To begin resetting your password, please enter your email address
              below.
            </Typography>
          </Stack>
        </Box>
        <Box
          sx={
            !mobileSize
              ? {
                  backgroundColor: "#fff",
                  mb: 2,
                  maxWidth: 700,
                  borderRadius: "25px",
                  borderTopLeftRadius: "0px",
                  borderTopRightRadius: "0px",
                  px: 3,
                  py: "10px",
                  width: "100%",
                  pt: "2rem",
                }
              : { mb: 3, backgroundColor: "#fff" }
          }
        >
          <form noValidate onSubmit={formik.handleSubmit}>
            <Stack spacing={2} sx={{ px: "1.8px" }}>
              <TextField
                error={!!(formik.touched.email && formik.errors.email)}
                fullWidth
                disabled={emailConfirmed}
                helperText={formik.touched.email && formik.errors.email}
                label="Email Address"
                name="email"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="email"
                value={formik.values.email}
              />

              <>
                <TextField
                  sx={{ display: !emailConfirmed ? "none" : "block" }}
                  error={!!(formik.touched.password && formik.errors.password)}
                  fullWidth
                  helperText={formik.touched.password && formik.errors.password}
                  label="New Password"
                  name="password"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type={showPassword ? "text" : "password"}
                  value={formik.values.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleTogglePasswordVisibility}
                          onMouseDown={(e) => e.preventDefault()} // Prevent focus change on mouse down
                          onMouseUp={(e) => e.preventDefault()} // Prevent focus change on mouse up
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  sx={{ display: !emailConfirmed ? "none" : "block" }}
                  error={
                    !!(formik.touched.repassword && formik.errors.repassword)
                  }
                  fullWidth
                  helperText={
                    formik.touched.repassword && formik.errors.repassword
                  }
                  label="Confirm Password"
                  name="repassword"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="password"
                  value={formik.values.repassword}
                />
              </>
            </Stack>

            {formik.errors.submit && (
              <Typography color="error" sx={{ mt: 3 }} variant="body2">
                {formik.errors.submit}
              </Typography>
            )}
            <Typography
              variant="body2"
              fontWeight="bold"
              textAlign="center"
              color="error"
              mt={2}
              sx={{ minHeight: "2.7rem" }}
            >
              {errorText}
            </Typography>
            <LoadingButton
              loading={loading}
              onClick={
                emailConfirmed === false ? handleValidateEmail : undefined
              }
              fullWidth
              size="large"
              sx={{ mt: 2 }}
              type={emailConfirmed ? "submit" : "button"}
              variant="contained"
            >
              {emailConfirmed ? "Change Password" : "Select"}
            </LoadingButton>
          </form>
          {!mobileSize && (
            <div
              style={{
                border: "1px solid #E7E5E5",
                width: "100%",
                marginTop: "1rem",
                marginBottom: "1rem",
              }}
            />
          )}

          <Box
            sx={{
              flex: "1 1 auto",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              mt: mobileSize && 2,
            }}
          >
            <Link
              sx={{ fontWeight: "bold", mx: "auto", cursor: "pointer" }}
              onClick={(e) => {
                navigate("/login");
              }}
              underline="hover"
              variant="subtitle2"
            >
              Back to Login
            </Link>
          </Box>
          {/* </div> */}
        </Box>
      </Box>
    </>
  );
};

export default ResetPasswordComponent;
