export const userRonSurveyQuestions = [
  "How would you rate the overall RON session experience?",
  "How user-friendly was the application?",
  "How clear were the instructions provided during the notarization process?",
  "How satisfied are you with the speed of the notarization process?",
  "How would you rate your notary's professionalism?",
  "How likely are you to recommend Permit Rockstar?",
];

export const notaryRonSurveyQuestions = [
  "How smooth was the platform's setup for your notarization session?",
  "How easy was it to guide the user through the notarization process?",
  "How responsive was the platform during the RON session?",
  "How clear were the instructions provided for the notarization steps?",
  "How likely are you to continue working with Permit Rockstar for future sessions?",
  "How satisfied are you with the overall support from Permit Rockstar?",
];
