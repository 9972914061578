import { useState, useEffect } from "react";
import { Box, Divider, Grid, Typography, Card } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const FinalizeUpload = ({
  formik,
  paymentCards,
  currentIndex,
  setCurrentIndex,
  prices,
  submitError,
}) => {
  const findPriceByTitle = (title) =>
    prices.find((price) => price.name === title)?.price || 0;
  const [vat, setVat] = useState(0);
  const [calculatedContent, setCalculatedContent] = useState(null);
  // Calculate discount if "Notarization and Record"
  const calculateDiscount = () => {
    const notarizationPrice = parseFloat(findPriceByTitle("notary")); // Convert to float
    const recordingPrice = parseFloat(findPriceByTitle("record")); // Convert to float
    const combinedPrice = parseFloat(findPriceByTitle("notaryRecord")); // Convert to float

    const discount = notarizationPrice + recordingPrice - combinedPrice;
    const discountPercent =
      (discount / (notarizationPrice + recordingPrice)) * 100;
    // Round to two decimal places and convert back to a float
    const roundedDiscountPercent = parseFloat(discountPercent.toFixed(2));

    return {
      notarizationPrice: notarizationPrice.toFixed(2),
      recordingPrice: recordingPrice.toFixed(2),
      combinedPrice,
      discount: discount.toFixed(2),
      discountPercent: isNaN(roundedDiscountPercent)
        ? 0
        : roundedDiscountPercent,
    };
  };

  useEffect(() => {
    let content;
    switch (formik.values.reason) {
      case "Notarization":
        let sub_price_notary = findPriceByTitle("notary");
        content = (
          <>
            <Grid container justifyContent="space-between" sx={{ mb: 1 }}>
              <Grid item>
                <Typography variant="body1">Notarization Fee</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">${sub_price_notary}</Typography>
              </Grid>
            </Grid>
            {/* VAT */}
            <Grid container justifyContent="space-between" sx={{ mb: 1 }}>
              <Grid item>
                <Typography variant="body1">VAT</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  ${parseFloat(vat).toFixed(2)}
                </Typography>
              </Grid>
            </Grid>

            <Divider sx={{ my: 2 }} />

            {/* Subtotal */}
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant="body1" fontWeight="bold">
                  Subtotal
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" fontWeight="bold">
                  ${(parseFloat(sub_price_notary) + parseFloat(vat)).toFixed(2)}
                </Typography>
              </Grid>
            </Grid>
          </>
        );
        break;
      case "Recording":
        let sub_price_record = findPriceByTitle("record");
        content = (
          <>
            <Grid container justifyContent="space-between" sx={{ mb: 1 }}>
              <Grid item>
                <Typography variant="body1">Recording Fee</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">${sub_price_record}</Typography>
              </Grid>
            </Grid>
            {/* VAT */}
            <Grid container justifyContent="space-between" sx={{ mb: 1 }}>
              <Grid item>
                <Typography variant="body1">VAT</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  ${parseFloat(vat).toFixed(2)}
                </Typography>
              </Grid>
            </Grid>

            <Divider sx={{ my: 2 }} />

            {/* Subtotal */}
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant="body1" fontWeight="bold">
                  Subtotal
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" fontWeight="bold">
                  ${(parseFloat(sub_price_record) + parseFloat(vat)).toFixed(2)}
                </Typography>
              </Grid>
            </Grid>
          </>
        );

        break;
      case "Notarization & Recording":
        const {
          notarizationPrice,
          recordingPrice,
          combinedPrice,
          discount,
          discountPercent,
        } = calculateDiscount();
        content = (
          <>
            <Grid container justifyContent="space-between" sx={{ mb: 1 }}>
              <Grid item>
                <Typography variant="body1">Notarization Fee</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">${notarizationPrice}</Typography>
              </Grid>
            </Grid>
            <Grid container justifyContent="space-between" sx={{ mb: 1 }}>
              <Grid item>
                <Typography variant="body1">Recording Fee</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">${recordingPrice}</Typography>
              </Grid>
            </Grid>
            <Grid container justifyContent="space-between" sx={{ mb: 1 }}>
              <Grid item>
                <Typography variant="body1">Total</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  $
                  {(
                    parseFloat(recordingPrice) + parseFloat(notarizationPrice)
                  ).toFixed(2)}
                </Typography>
              </Grid>
            </Grid>
            <Grid container justifyContent="space-between" sx={{ mb: 1 }}>
              <Grid item>
                <Typography
                  sx={{ color: "#4caf50", fontWeight: "bold" }}
                  variant="body1"
                >
                  Discount
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  sx={{ color: "#4caf50", fontWeight: "bold" }}
                  variant="body1"
                >
                  ${discount} / {discountPercent.toFixed(2)}%
                </Typography>
              </Grid>
            </Grid>
            {/* VAT */}
            <Grid container justifyContent="space-between" sx={{ mb: 1 }}>
              <Grid item>
                <Typography variant="body1">VAT</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  ${parseFloat(vat).toFixed(2)}
                </Typography>
              </Grid>
            </Grid>

            <Divider sx={{ my: 2 }} />

            {/* Subtotal */}
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant="body1" fontWeight="bold">
                  Subtotal
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" fontWeight="bold">
                  ${(parseFloat(combinedPrice) + parseFloat(vat)).toFixed(2)}
                </Typography>
              </Grid>
            </Grid>
          </>
        );

        break;
      default:
        content = <Typography>Please select a processing type.</Typography>;
    }

    setCalculatedContent(content);
  }, [formik.values.reason]);

  return (
    <>
      <Grid container>
        <Grid
          item
          sm={6}
          sx={{
            padding: "1rem",
          }}
          xs={12}
        >
          <Typography mb={2} variant="h5" align="center">
            Select Payment Method
          </Typography>
          {paymentCards?.map((card, index) => (
            <Box
              key={index}
              className="parent"
              sx={{
                width: "80%", // Adjust width as needed
                margin: "auto", // Center the Box
                position: "relative",
                display: index === currentIndex ? "flex" : "none",
                justifyContent: "center", // Center contents horizontally
              }}
            >
              <img
                src="/img/website_img/bankcardEmpty.jpg"
                style={{
                  width: "100%", // Make image fill the Box
                  borderRadius: "20px",
                  margin: "auto", // Center the image
                }}
              />
              {card?.default && (
                <Typography
                  className="child"
                  sx={{
                    position: "absolute",
                    color: "#fff",
                    position: "absolute",
                    transform: "translate(-2%,-40%)",
                    top: "8%",
                    right: "4%",
                    fontSize: "1rem",
                  }}
                >
                  <CheckCircleOutlineIcon
                    color="#fff"
                    width={20}
                    style={{
                      verticalAlign: "middle",
                      marginBottom: "2px",
                    }}
                  />{" "}
                  Default Payment Card
                </Typography>
              )}

              <Typography
                className="child"
                sx={{
                  position: "absolute",
                  color: "#fff",
                  position: "absolute",
                  transform: "translate(-2%,-40%)",
                  top: "50%",
                  left: "5%",
                  fontSize: "2rem",
                }}
              >
                {card.cardNum}
              </Typography>
              <Typography
                className="child"
                sx={{
                  position: "absolute",
                  color: "#fff",
                  transform: "translate(-2%,-40%)",
                  top: "85%",
                  left: "2.5%",
                  fontSize: "1rem",
                }}
              >
                {card.cardName}
              </Typography>
              <Typography
                sx={{
                  position: "absolute",
                  color: "#fff",
                  transform: "translate(-2%,-40%)",
                  top: "85%",
                  left: "37%",
                  fontSize: "1rem",
                }}
              >
                {card.expires}
              </Typography>
            </Box>
          ))}
          <Grid item xs={12}>
            <div style={{ textAlign: "center", marginTop: "10px" }}>
              {paymentCards?.map((_, index) => (
                <span
                  key={index}
                  onClick={() => setCurrentIndex(index)}
                  style={{
                    cursor: "pointer",
                    margin: "0 5px",
                    width: "10px",
                    height: "10px",
                    backgroundColor: index === currentIndex ? "#333" : "#ccc",
                    display: "inline-block",
                    borderRadius: "50%",
                  }}
                />
              ))}
            </div>{" "}
          </Grid>
        </Grid>
        <Grid item sm={6} xs={12} sx={{ px: "1rem" }}>
          <Box
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              width: "100%",
            }}
          >
            <Card sx={{ m: 2, p: 2 }}>
              <Typography variant="h6" gutterBottom>
                Receipt Overview
              </Typography>
              <Divider sx={{ mb: 2 }} />
              {/* Processing Type */}
              <Grid container justifyContent="space-between" sx={{ mb: 1 }}>
                <Grid item>
                  <Typography variant="body1">Processing Type</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1">
                    {formik?.values?.reason}
                  </Typography>
                </Grid>
              </Grid>
              {/* Document Type */}
              <Grid container justifyContent="space-between" sx={{ mb: 1 }}>
                <Grid item>
                  <Typography variant="body1">Document Type</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1">
                    {formik?.values?.documentType}
                  </Typography>
                </Grid>
              </Grid>
              {calculatedContent}
            </Card>
          </Box>
        </Grid>
        <Typography
          fontWeight="bold"
          color="error"
          variant="body2"
          align="center"
          mt="2rem"
          mx="auto"
        >
          {submitError}
        </Typography>
      </Grid>
    </>
  );
};

export default FinalizeUpload;
