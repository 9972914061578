export const getCitiesForCounty = (availablePermitForms, currentCounty) => {
  // Helper function to format city names
  const formatCityName = (city) => {
    return city
      .toLowerCase()
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  // Find the data for the current county
  const countyData =
    availablePermitForms.find((item) => item[currentCounty])?.[currentCounty] ||
    {};

  // Create a list of cities by filtering out empty arrays and formatting names
  const cities = Object.keys(countyData)
    .filter((city) => city !== "county_specific" && city !== "noc")
    .map((city) => formatCityName(city));

  // Return the filtered and formatted cities list
  return cities;
};

// Helper function to revert formatted city name to original format
const revertCityName = (formattedCity) => {
  return formattedCity.toLowerCase().replace(/ /g, "_");
};

export const getCombinedListForCity = (
  availablePermitForms,
  currentCounty,
  selectedCity
) => {
  // Revert the formatted city name to the original format
  const originalCityName = revertCityName(selectedCity);

  // Get the "Global" list of objects
  const globalData = availablePermitForms.find((item) => item.Global) || {};
  const globalList = globalData.Global || [];

  // Get the data for the current county
  const countyData =
    availablePermitForms.find((item) => item[currentCounty])?.[currentCounty] ||
    {};

  // Get the permits for the selected city
  const cityPermits = countyData[originalCityName] || [];

  // Combine the "Global" list with the city's permits
  const combinedPermits = [...cityPermits, ...globalList];

  // Return an object for the selected city with combined permits
  return {
    city: selectedCity,
    permits: combinedPermits,
  };
};

export const getCombinedList = (
  availablePermitForms,
  currentCounty,
  selectedCity,
  selectedForms,
  setSelectedForms,
  oldSelectedForms
) => {
  // Revert the formatted city name to the original format
  const originalCityName = revertCityName(selectedCity);

  // Get the "Global" list of objects
  const globalData = availablePermitForms.find((item) => item.Global) || {};
  const globalList = globalData.Global || [];

  // Get the data for the current county
  const countyData =
    availablePermitForms.find((item) => item[currentCounty])?.[currentCounty] ||
    {};

  // Get the permits for the selected city
  let cityPermits = countyData[originalCityName] || [];

  // Combine the "Global" list with the city's permits
  let combinedPermits = [...cityPermits, ...globalList];

  // Append "county_specific" and "noc" if they exist
  if (countyData?.county_specific) {
    combinedPermits = [...combinedPermits, ...countyData.county_specific];
  }
  if (countyData?.noc) {
    combinedPermits = [{ ...countyData.noc }, ...combinedPermits];
  }

  if (oldSelectedForms) {
    // Exclude the forms that the user has already selected
    combinedPermits = combinedPermits.filter(
      (permit) => !oldSelectedForms.includes(permit.name)
    );
  }

  if (!oldSelectedForms) {
    // Ensure "noc" is in the selectedForms
    if (!selectedForms.includes("noc")) {
      setSelectedForms(["noc", ...selectedForms]);
    }
  }

  // Return an object for the selected city with combined permits
  return {
    city: selectedCity,
    permits: combinedPermits,
    nocImg: countyData?.noc?.img.map(
      (img) => `${process.env.REACT_APP_URL}${img}`
    ),
  };
};
