export const usStates = [
  { name: "Alabama", abbreviation: "AL" },
  { name: "Alaska", abbreviation: "AK" },
  { name: "Arizona", abbreviation: "AZ" },
  { name: "Arkansas", abbreviation: "AR" },
  { name: "California", abbreviation: "CA" },
  { name: "Colorado", abbreviation: "CO" },
  { name: "Connecticut", abbreviation: "CT" },
  { name: "Delaware", abbreviation: "DE" },
  { name: "Florida", abbreviation: "FL" },
  { name: "Georgia", abbreviation: "GA" },
  { name: "Hawaii", abbreviation: "HI" },
  { name: "Idaho", abbreviation: "ID" },
  { name: "Illinois", abbreviation: "IL" },
  { name: "Indiana", abbreviation: "IN" },
  { name: "Iowa", abbreviation: "IA" },
  { name: "Kansas", abbreviation: "KS" },
  { name: "Kentucky", abbreviation: "KY" },
  { name: "Louisiana", abbreviation: "LA" },
  { name: "Maine", abbreviation: "ME" },
  { name: "Maryland", abbreviation: "MD" },
  { name: "Massachusetts", abbreviation: "MA" },
  { name: "Michigan", abbreviation: "MI" },
  { name: "Minnesota", abbreviation: "MN" },
  { name: "Mississippi", abbreviation: "MS" },
  { name: "Missouri", abbreviation: "MO" },
  { name: "Montana", abbreviation: "MT" },
  { name: "Nebraska", abbreviation: "NE" },
  { name: "Nevada", abbreviation: "NV" },
  { name: "New Hampshire", abbreviation: "NH" },
  { name: "New Jersey", abbreviation: "NJ" },
  { name: "New Mexico", abbreviation: "NM" },
  { name: "New York", abbreviation: "NY" },
  { name: "North Carolina", abbreviation: "NC" },
  { name: "North Dakota", abbreviation: "ND" },
  { name: "Ohio", abbreviation: "OH" },
  { name: "Oklahoma", abbreviation: "OK" },
  { name: "Oregon", abbreviation: "OR" },
  { name: "Pennsylvania", abbreviation: "PA" },
  { name: "Rhode Island", abbreviation: "RI" },
  { name: "South Carolina", abbreviation: "SC" },
  { name: "South Dakota", abbreviation: "SD" },
  { name: "Tennessee", abbreviation: "TN" },
  { name: "Texas", abbreviation: "TX" },
  { name: "Utah", abbreviation: "UT" },
  { name: "Vermont", abbreviation: "VT" },
  { name: "Virginia", abbreviation: "VA" },
  { name: "Washington", abbreviation: "WA" },
  { name: "West Virginia", abbreviation: "WV" },
  { name: "Wisconsin", abbreviation: "WI" },
  { name: "Wyoming", abbreviation: "WY" },
];

export const availableCounties = [
  "Duval",
  "Clay",
  "St Johns",
  "Nassau",
  "Seminole",
  "Orange",
  "Brevard",
  "Flagler",
  "Lake",
  "Osceola",
];

// export const countyCards = [
//   { name: "Duval", form: `${process.env.REACT_APP_URL}/images/noc/Duval.jpg` },
//   { name: "Clay", form: `${process.env.REACT_APP_URL}/images/noc/Clay.jpg` },
//   {
//     name: "St Johns",
//     form: `${process.env.REACT_APP_URL}/images/noc/St Johns.jpg`,
//   },
//   {
//     name: "Nassau",
//     form: `${process.env.REACT_APP_URL}/images/noc/Nassau.jpg`,
//   },
//   {
//     name: "Seminole",
//     form: `${process.env.REACT_APP_URL}/images/noc/Seminole.jpg`,
//   },
//   {
//     name: "Orange",
//     form: `${process.env.REACT_APP_URL}/images/noc/Orange.jpg`,
//   },
//   {
//     name: "Brevard",
//     form: `${process.env.REACT_APP_URL}/images/noc/Brevard1.jpg`,
//   }, // 2 imgs
//   {
//     name: "Flagler",
//     form: `${process.env.REACT_APP_URL}/images/noc/Flagler.jpg`,
//   },
//   { name: "Lake", form: `${process.env.REACT_APP_URL}/images/noc/Lake.jpg` },
//   {
//     name: "Osceola",
//     form: `${process.env.REACT_APP_URL}/images/noc/Osceola.jpg`,
//   },
// ];

export const unavailable_record_list = [
  "Bradford",
  "Dixie",
  "Gadsden",
  "Glades",
  "Levy",
  "Liberty",
  "Madison",
  "Sumter",
  "Taylor",
  "Union",
];

export const floridaCounties = [
  "Alachua",
  "Baker",
  "Bay",
  "Bradford",
  "Brevard",
  "Broward",
  "Calhoun",
  "Charlotte",
  "Citrus",
  "Clay",
  "Collier",
  "Columbia",
  "DeSoto",
  "Dixie",
  "Duval",
  "Escambia",
  "Flagler",
  "Franklin",
  "Gadsden",
  "Gilchrist",
  "Glades",
  "Gulf",
  "Hamilton",
  "Hardee",
  "Hendry",
  "Hernando",
  "Highlands",
  "Hillsborough",
  "Holmes",
  "Indian River",
  "Jackson",
  "Jefferson",
  "Lafayette",
  "Lake",
  "Lee",
  "Leon",
  "Levy",
  "Liberty",
  "Madison",
  "Manatee",
  "Marion",
  "Martin",
  "Miami-Dade",
  "Monroe",
  "Nassau",
  "Okaloosa",
  "Okeechobee",
  "Orange",
  "Osceola",
  "Palm Beach",
  "Pasco",
  "Pinellas",
  "Polk",
  "Putnam",
  "St. Johns",
  "St. Lucie",
  "Santa Rosa",
  "Sarasota",
  "Seminole",
  "Sumter",
  "Suwannee",
  "Taylor",
  "Union",
  "Volusia",
  "Wakulla",
  "Walton",
  "Washington",
];
