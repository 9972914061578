import {
  Box,
  Card,
  CardContent,
  CardHeader,
  useTheme,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";

import ApexChart from "react-apexcharts";
import { styled } from "@mui/material/styles";
const Chart = styled(ApexChart)``;

const useChartOptions = (labels) => {
  const theme = useTheme();

  return {
    chart: {
      background: "transparent",
    },
    colors: [theme.palette.warning.main],
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: true, // Enable data labels
      style: {
        colors: ["#fff"], // Set text color to white
        fontSize: "16px",
      },
      formatter: (value) => parseInt(value), // Convert to integer
    },
    xaxis: {
      categories: labels,
      labels: {
        show: false,
        formatter: (value) => parseInt(value), // Convert to integer
        offsetX: -10,
        style: {
          colors: theme.palette.text.secondary,
        },
      },
    },
    yaxis: {
      categories: labels.map((label) => label),
      labels: {
        style: {
          fontSize: "15px",
        },
      },
      // labels: {
      //   formatter: (value) => parseInt(value), // Convert Y-axis labels to integers
      //   style: {
      //     colors: theme.palette.text.secondary,
      //   },
      // },
    },
    tooltip: {
      enabled: false,
    },
  };
};

const TotalNotarizationByCounty = (props) => {
  const {
    chartSeries,
    labels,
    notaryCountySortValue,
    setNotaryCountySortValue,
  } = props;
  const chartOptions = useChartOptions(labels);
  const sortSelect = ["Default", "Ascending", "Descending"];

  const handleChange = (event) => {
    setNotaryCountySortValue(event.target.value);
  };

  return (
    <Card
      sx={{
        position: "relative",
        overflowY: "auto",
        overflowX: "hidden",
        height: "31rem",
      }}
    >
      <CardHeader title="Total Notarized Documents by Counties" sx={{}} />

      <CardContent>
        <Box
          sx={{
            position: "absolute",
            top: 30,
            right: 30,
            width: "10rem",
          }}
        >
          <FormControl fullWidth variant="filled">
            <InputLabel>Sort</InputLabel>
            <Select
              value={notaryCountySortValue}
              label="select"
              onChange={handleChange}
              sx={{ zIndex: 1 }}
            >
              {sortSelect.map(function (item, i) {
                return (
                  <MenuItem key={i} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
        <Chart
          height={1400}
          options={chartOptions}
          series={[{ data: chartSeries }]}
          type="bar"
          width="100%"
        />
      </CardContent>
    </Card>
  );
};

export default TotalNotarizationByCounty;
