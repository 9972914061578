import React, { useEffect, useState } from "react";
import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { keyframes } from "@emotion/react";
import CloseIcon from "@mui/icons-material/Close";
import {
  dismissAllRonRequest,
  dismissRonRequest,
} from "../../store/actions/notaryActions";

// Define the keyframes for the slide-down animation
const slideDown = keyframes`
  0% {
    transform: translateY(-50%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const NewRonRequestBox = () => {
  const notaryRequests = useSelector(
    (state) => state?.notarySession?.notaryRequests
  );
  const dispatch = useDispatch();

  const [currentRequest, setCurrentRequest] = useState(null);

  useEffect(() => {
    // Automatically set the first request from the list if currentRequest is null
    if (!currentRequest && notaryRequests && notaryRequests.length > 0) {
      setCurrentRequest(notaryRequests[0]);
    }
  }, [notaryRequests, currentRequest]);

  const handleDismiss = () => {
    dispatch(dismissRonRequest(currentRequest?.id));
    setCurrentRequest(null);
  };
  const handleDismissAll = () => {
    dispatch(dismissAllRonRequest());
    setCurrentRequest(null);
  };
  const handleAccept = () => {
    window.open(currentRequest?.accept_url, "_blank");

    dispatch(dismissRonRequest(currentRequest?.id));
    setCurrentRequest(null);
  };

  if (!currentRequest) {
    return null; // Don't render anything if there's no current request
  }

  return (
    <Box
      sx={{
        position: "fixed",
        top: "20px",
        right: "20px",
        width: "auto",
        backgroundColor: "#f7fcf7",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.4)",
        borderRadius: "8px",
        padding: "16px",
        zIndex: 1000,
        animation: `${slideDown} 0.5s ease-out`,
      }}
    >
      <Box px={2} sx={{ position: "relative" }}>
        <Typography mx={2} align="center" variant="h5" color="primary">
          New Notarization Request
        </Typography>
        <Box sx={{ position: "absolute", right: -20, top: -18 }}>
          <Tooltip title="Dismiss All">
            <IconButton onClick={handleDismissAll}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <Typography
          variant="body1"
          fontWeight="bold"
          align="center"
          color="primary"
        >
          {currentRequest?.instant ? "Expedited" : "Later Date"}
        </Typography>
        <Typography fontWeight="bold" align="center" sx={{ color: "#474747" }}>
          {currentRequest?.date}
        </Typography>

        <Box sx={{ display: "flex", justifyContent: "center", gap: 2, mt: 4 }}>
          <Button color="error" onClick={handleDismiss}>
            Dismiss
          </Button>
          <Button
            sx={{ width: "10rem" }}
            color="success"
            onClick={handleAccept}
            variant="outlined"
          >
            Accept
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default NewRonRequestBox;
