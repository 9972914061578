import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { DateTime } from "luxon";
import { setInstantRonInitialState } from "../../../store/actions/guestActions";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import TimezoneSelect from "../../layout/TimezoneSelect";
import { timezoneMappings } from "../../../data/timezones";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker } from "@mui/x-date-pickers";
import { subYears, isAfter } from "date-fns";
import validator from "validator";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import YupPassword from "yup-password";
import dayjs from "dayjs";
// import utc from "dayjs/plugin";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  TextField,
  Typography,
  Grid,
  useMediaQuery,
  useTheme,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Alert,
  InputAdornment,
  Tooltip,
  Toolbar,
  IconButton,
  Button,
} from "@mui/material";
import TableOverview from "./TableOverview";
import NotarizedModal from "../../NotarizedModal";
import Schedule from "./Schedule";
import LottieAnimation from "../../../animation/LottieAnimation";
import ExpandableTimezone from "../../modals/ExpandableTimezone";
import SigneeRegisterInfo from "../../popover/SigneeRegisterInfo";
import { usStates } from "../../../data/counties";
import ScheduleContractorRonModal from "../../modals/ScheduleContractorRonModal";

YupPassword(Yup);

const RegisterSignee = () => {
  const theme = useTheme();
  const currentSession = useSelector((state) => state.sessions.currentSession);
  const guestUser = useSelector((state) => state.guestSession);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const scrollableElementRef = useRef(null);
  const mobileSize = useMediaQuery(theme.breakpoints.down("sm"));
  const tabletSize = useMediaQuery(theme.breakpoints.down("md"));
  const largeTabletSize = useMediaQuery(theme.breakpoints.down("lg"));
  const largePC = useMediaQuery(theme.breakpoints.up("xl"));
  const [pageLoading, setPageLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [notaryError, setNotaryError] = useState("");
  const [propertyDetails, setPropertyDetails] = useState([]);
  const [propertyImage, setPropertyImage] = useState([]);
  const [ids, setIds] = useState();
  const [documentModal, setDocumentModal] = useState(false);
  const [availableChecked, setAvailableChecked] = useState(true);
  const [scheduleTimeLeft, setScheduleTimeLeft] = useState(60);
  const [startTimer, setStartTimer] = useState(false);
  const [startInstantTimer, setStartInstantTimer] = useState(false);
  const [checkDateLoading, setCheckDateLoading] = useState(false);
  const [isAvailable, setIsAvailable] = useState(false);
  const [availableDate, setAvailableDate] = useState(null);
  const [slots, setSlots] = useState([]);
  const [slotIndex, setSlotIndex] = useState(-1);
  const [unavailableText, setUnavailableText] = useState("");
  const [isEmergencyLeave, setIsEmergencyLeave] = useState(true);
  const [firstTimer, setFirstTimer] = useState(true);
  const [instantTimeLeft, setInstantTimeLeft] = useState(60);
  const [checkInstantLoading, setCheckInstantLoading] = useState(false);
  const [isInstantAvailable, setIsInstantAvailable] = useState(false);
  const [instantData, setInstantData] = useState({});
  const [instantError, setInstantError] = useState("");
  const [activeOwner, setActiveOwner] = useState({ type: null, index: null });
  const [recordError, setRecordError] = useState("");
  const [ownerTypes, setOwnerTypes] = useState({});
  const [fullOwners, setFullOwners] = useState([]);
  const [ownerships, setOwnerships] = useState([]);
  const [immediateRequested, setImmediateRequested] = useState(false);
  const [immediateLock, setImmediateLock] = useState(false);

  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState("");

  const [signerType, setSignerType] = useState("ORGANIZATION");

  const [canceledInstant, setCanceledInstant] = useState(false);
  const [instantAccepted, setInstantAccepted] = useState(false);

  const [tempDate, setTempDate] = useState(null);
  const [tempTime, setTempTime] = useState(null);
  const [furtherDateObj, setFurtherDateObj] = useState(null);
  const [furtherData, setFurtherData] = useState({});

  const [typeInfo, setTypeInfo] = useState(false);
  const [titleInfo, setTitleInfo] = useState(false);
  const [infoText, setInfoText] = useState("");
  const [isFadingOut, setIsFadingOut] = useState(false);
  const [titleIsFadingOut, setTitleIsFadingOut] = useState(false);

  const [expeditedSelectedBtn, setExpeditedSelectedBtn] = useState(false);
  const [furtherSelectedBtn, setFurtherSelectedBtn] = useState(false);
  const [yesLoading, setYesLoading] = useState(false);
  const [isFurtherAvailable, setIsFurtherAvailable] = useState(false);
  const [furtherRequested, setFurtherRequested] = useState(false);
  const [furtherError, setFurtherError] = useState("");

  const [userTimezone, setUserTimeZone] = useState("America/New_York");
  const initialValuesSet = useRef(false);
  const [open, setOpen] = useState(false);
  const [timezoneOpen, setTimezoneOpen] = useState(false);
  const [initialFormValues, setInitialFormValues] = useState({
    owners: [],
    ownerAddress: "",
    fullOwnerSelect: [],
    ownersSelect: [],
    name: "",
    signatoryTitle: "",
    authority: "",
    ownerAddress: "",
    address: "",
    city: "",
    state: "",
    zipCode: "",
    dob: null,
    email: "",
    phone: "",
    idType: "",
    scheduledDate: null,
  });
  const [copyOwners, setCopyOwners] = useState([]);
  const isFieldsDisabled = () =>
    (immediateRequested && startInstantTimer) ||
    instantAccepted ||
    checkInstantLoading;
  const selectedRow = { task_id: guestUser?.registerData?.task_id };

  useEffect(() => {
    if (tempDate && tempTime) {
      const dateObj = {
        day_of_month: tempDate.date(), // Get the day of the month
        month: tempDate.month() + 1, // Months are zero-indexed, add 1 for 1-12 format
        year: tempDate.year(),
        time: tempTime.format("HH:mm"), // Formats the time in 'HH:mm' format
      };

      // Convert tempDate and tempTime to the user's timezone
      const userDateTime = DateTime.fromObject(
        {
          year: tempDate.year(),
          month: tempDate.month() + 1, // Adjust for zero-indexed months
          day: tempDate.date(),
          hour: tempTime.hour(),
          minute: tempTime.minute(),
          second: tempTime.second(),
        },
        { zone: userTimezone }
      );

      // Check if the date and time is in the past
      if (userDateTime < DateTime.now().setZone(userTimezone)) {
        setUnavailableText("Selected datetime is in the past.");
        setFurtherDateObj(null);
      } else {
        setFurtherDateObj(dateObj);
        setUnavailableText("");
        formik.setFieldValue("scheduledDate", dateObj, true);
      }
    }
  }, [tempDate, tempTime, userTimezone]);
  useEffect(() => {
    if (typeInfo) {
      // First timeout to start the fade-out effect after 4500ms
      const timeout = setTimeout(() => {
        setIsFadingOut(true); // Start fading out
        // Second timeout to hide the alert and reset isFadingOut after the fade-out duration
        const fadeOutTimeout = setTimeout(() => {
          setTypeInfo(false); // Hide alert after fade-out completes
          setIsFadingOut(false); // Reset fading state
        }, 1000); // This duration should match the CSS fade-out duration

        return () => clearTimeout(fadeOutTimeout);
      }, 3500); // Show the alert for 5 seconds minus fade duration

      return () => clearTimeout(timeout);
    } else if (titleInfo) {
      const titleTimeout = setTimeout(() => {
        setTitleIsFadingOut(true); // Start fading out
        // Second timeout to hide the alert and reset isFadingOut after the fade-out duration
        const fadeOutTitleTimeout = setTimeout(() => {
          setTitleInfo(false); // Hide alert after fade-out completes
          setTitleIsFadingOut(false); // Reset fading state
        }, 1000); // This duration should match the CSS fade-out duration

        return () => clearTimeout(fadeOutTitleTimeout);
      }, 3500); // Show the alert for 5 seconds minus fade duration

      return () => clearTimeout(titleTimeout);
    }
  }, [typeInfo, titleInfo]);

  // useEffect(() => {
  //   if (guestUser?.instantRonAccepted === true && immediateLock === false) {
  //     setImmediateLock(true);
  //     setInstantAccepted(true);

  //     startInstantRon();

  //     // dispatch(setInstantRonInitialState());
  //   } else if (
  //     instantAccepted === true &&
  //     guestUser?.instantRonAccepted === false
  //   ) {
  //     setInstantAccepted(false);
  //   }
  // }, [guestUser?.instantRonAccepted]);

  // useEffect(() => {
  //   if (!activeOwner?.type) {
  //     setRecordError("");
  //   }
  // }, [recordError, activeOwner]);

  const extractOwnersData = (overviewData) => {
    const owners = [];
    const ownersSelect = [];
    let signerName = "";
    // let firstPersonMiddleName = "";
    // let firstPersonLastName = "";
    let ownerAddress = "";
    let ownerPhone = "";

    overviewData.forEach((item) => {
      if (
        item.label.startsWith("Owner") &&
        item.label !== "Owner Address" &&
        item.label !== "Ownership Type"
      ) {
        // Add to owners array
        owners.push(item);
        ownersSelect.push({ label: item.label, value: item.type });
      } else if (item.label === "Owner Address") {
        ownerAddress = item.value;
      } else if (item.label === "ownerPhone") {
        ownerPhone = item.value;
      }
    });

    for (const owner of owners) {
      if (owner.type === "PERSON") {
        signerName = owner.value.PERSON;
        // firstPersonMiddleName = owner.value.PERSON?.middle_name;
        // firstPersonLastName = owner.value.PERSON.last_name;
        setSignerType("PERSON");
        break;
      }
    }

    return {
      owners,
      ownersSelect,
      signerName,
      // firstPersonMiddleName,
      // firstPersonLastName,
      ownerAddress,
      ownerPhone,
    };
  };

  // function prepareBeaconData(payload) {
  //   const formData = new FormData();
  //   Object.keys(payload).forEach((key) => {
  //     formData.append(key, payload[key]);
  //   });
  //   return formData;
  // }

  // useEffect(() => {
  //   const payload = {
  //     task_id: ids?.task_id,
  //     noc_id: ids?.noc_id,
  //     currentSession: currentSession,
  //   };

  //   const handleUnload = async (event) => {
  //     if (instantAccepted) {
  //       event.preventDefault();
  //       event.returnValue = ""; // Necessary for Chrome
  //     } else {
  //       if (isEmergencyLeave === true) {
  //         await axios.post(
  //           `${process.env.REACT_APP_BASE_URL}/nocApi/signee/emergency-clear-ron-schedules`,
  //           {
  //             payload,
  //           },
  //           { withCredentials: true }
  //         );
  //       }
  //     }
  //   };

  //   window.addEventListener("beforeunload", handleUnload);

  //   return () => {
  //     window.removeEventListener("beforeunload", handleUnload);
  //   };
  // }, [ids, isEmergencyLeave, instantAccepted]);

  useEffect(() => {
    if (slots.length > 0 || isAvailable) {
      // Assuming `slots` is your state variable
      scrollToBottom();
    }
  }, [slots, isAvailable]);

  const scrollToBottom = () => {
    const scrollableElement = scrollableElementRef.current;
    if (scrollableElement) {
      scrollableElement.scrollTop = scrollableElement.scrollHeight;
    }
  };

  // function convertDayjsToObject(dayjsObj) {
  //   return {
  //     day_of_month: dayjsObj.date(), // Get the day of the month
  //     month: dayjsObj.month() + 1, // Months are zero-indexed, add 1 for 1-12 format
  //     year: dayjsObj.year(),
  //     time: dayjsObj.format("HH:mm"), // Formats the time in 'HH:mm' format
  //   };
  // }

  // function convertObjectToDayjs(dateInfo, setTempDate, setTempTime) {
  //   // Day.js month is zero-indexed, subtract 1
  //   const monthZeroIndexed = dateInfo.month - 1;

  //   // Constructing the full date string
  //   const dateString = `${dateInfo.year}-${String(
  //     monthZeroIndexed + 1
  //   ).padStart(2, "0")}-${String(dateInfo.day_of_month).padStart(2, "0")}`;

  //   // Constructing the time string
  //   const timeString = dateInfo.time;

  //   // Creating Day.js objects for date and time
  //   const date = dayjs(dateString);
  //   const time = dayjs(timeString, "HH:mm");

  //   // Setting the states
  //   setTempDate(date);
  //   setTempTime(time);
  // }

  // const handleTimeUp = async (formikState) => {
  //   const payload = {
  //     task_id: ids?.task_id,
  //     noc_id: ids?.noc_id,
  //     currentSession: currentSession,
  //   };

  //   try {
  //     setSlots([]);
  //     setSlotIndex(-1);
  //     setIsInstantAvailable(false);
  //     setIsAvailable(false);
  //     setInstantData({});
  //     formikState.values.scheduledDate = null;
  //     setUnavailableText("");
  //     setFirstTimer(true);
  //     if (immediateRequested === true) {
  //       if (
  //         canceledInstant === false &&
  //         guestUser?.instantRonAccepted === false
  //       ) {
  //         setInstantError(
  //           "Unfortunately, we have not received any response from the notaries at this moment. We apologize for the inconvenience as they are currently very busy. Please consider scheduling an appointment for a later time. Thank you for your understanding."
  //         );
  //       }

  //       setImmediateRequested(false);
  //       setCanceledInstant(false);
  //     }

  //     await axios.post(
  //       `${process.env.REACT_APP_BASE_URL}/nocApi/notary/clear-ron-schedules`,
  //       {
  //         payload,
  //       },
  //       {
  //         withCredentials: true,
  //       }
  //     );
  //   } catch (e) {}
  //   // Call your API here
  //   setStartTimer(false); // Reset the timer state
  //   setStartInstantTimer(false);
  // };

  // const handleImmediateRON = async () => {
  //   if (isInstantAvailable) {
  //     setCheckInstantLoading(true);
  //     try {
  //       const payload = {
  //         task_id: ids?.task_id,
  //         noc_id: ids?.noc_id,
  //         currentSession: currentSession,
  //         instantData: instantData,
  //         instant: true,
  //         timezone: userTimezone,
  //       };

  //       const resp = await axios.post(
  //         `${process.env.REACT_APP_BASE_URL}/nocApi/notary/request-immediate-ron`,
  //         {
  //           payload,
  //         },
  //         {
  //           withCredentials: true,
  //         }
  //       );
  //       if (resp.data.success) {
  //         setInstantTimeLeft(300);
  //         setStartInstantTimer(true);
  //         setImmediateRequested(true);
  //       }
  //     } catch (e) {}

  //     setCheckInstantLoading(false);
  //   } else {
  //     setCheckInstantLoading(true);
  //     setYesLoading(true);
  //     formik.values.scheduledDate = null;
  //     formik.setFieldError("scheduledDate", "");
  //     const dateTime = DateTime.now().setZone(userTimezone);

  //     const formattedObject = {
  //       day_of_month: dateTime.day,
  //       month: dateTime.month,
  //       year: dateTime.year,
  //       time: dateTime.toFormat("HH:mm"),
  //     };

  //     const payload = {
  //       task_id: ids?.task_id,
  //       noc_id: ids?.noc_id,
  //       ask_date: formattedObject,
  //       instant: true,
  //       timezone: userTimezone,
  //       currentSession: currentSession,
  //     };
  //     try {
  //       const resp = await axios.post(
  //         `${process.env.REACT_APP_BASE_URL}/nocApi/notary/available-ron-schedule`,
  //         {
  //           payload,
  //         },
  //         {
  //           withCredentials: true,
  //         }
  //       );

  //       if (resp.data.success) {
  //         setIsInstantAvailable(true);
  //         setInstantData(resp.data);
  //         setInstantTimeLeft(60);
  //         setStartInstantTimer(true);
  //       } else {
  //         setInstantError(resp.data.message);
  //       }

  //       setCheckInstantLoading(false);
  //       setYesLoading(false);
  //     } catch (e) {
  //       setCheckInstantLoading(false);
  //       setYesLoading(false);
  //     }
  //   }
  // };

  // //! FURTHER DATE ////////////////////////////////////
  // const handleStartClick = async (formik) => {
  //   if (isFurtherAvailable) {
  //     setCheckDateLoading(true);

  //     try {
  //       const payload = {
  //         task_id: ids?.task_id,
  //         noc_id: ids?.noc_id,
  //         currentSession: currentSession,
  //         instantData: furtherData,
  //         instant: true,
  //         timezone: userTimezone,
  //         further: furtherSelectedBtn,
  //       };
  //       const resp = await axios.post(
  //         `${process.env.REACT_APP_BASE_URL}/nocApi/notary/request-immediate-ron`,
  //         {
  //           payload,
  //         },
  //         {
  //           withCredentials: true,
  //         }
  //       );
  //       if (resp.data.success) {
  //         setScheduleTimeLeft(300);
  //         setStartTimer(true);
  //         setFurtherRequested(true);
  //       }
  //     } catch (e) {}
  //     setCheckDateLoading(false);
  //   } else {
  //     setCheckDateLoading(true);
  //     formik.values.scheduledDate = null;
  //     formik.setFieldError("scheduledDate", "");

  //     const payload = {
  //       task_id: ids?.task_id,
  //       noc_id: ids?.noc_id,
  //       ask_date: furtherDateObj,
  //       instant: true,
  //       timezone: userTimezone,
  //       currentSession: currentSession,
  //       is_further: furtherSelectedBtn,
  //     };
  //     try {
  //       const resp = await axios.post(
  //         `${process.env.REACT_APP_BASE_URL}/nocApi/notary/available-ron-schedule`,
  //         {
  //           payload,
  //         },
  //         {
  //           withCredentials: true,
  //         }
  //       );
  //       if (
  //         resp.data.isAvailable?.length === 0 &&
  //         resp.data?.slots?.length > 0
  //       ) {
  //         setScheduleTimeLeft(60);
  //         setStartTimer(true);
  //         setInstantAccepted(false);
  //         setIsFurtherAvailable(false);
  //         setUnavailableText(
  //           "The selected date is unavailable. Please choose a different date or select one from the list below."
  //         );
  //         setSlots(resp.data.slots);
  //       } else if (resp.data.success) {
  //         setIsFurtherAvailable(true);
  //         setFurtherData(resp.data);
  //         setScheduleTimeLeft(60);
  //         setStartTimer(true);
  //         setSlots([]);
  //       } else {
  //         setFurtherError(resp.data.message);
  //         setScheduleTimeLeft(60);
  //         setFirstTimer(false);
  //         setIsAvailable(false);
  //         setInstantAccepted(false);
  //         setUnavailableText(
  //           "The selected date is unavailable. Please choose a different date."
  //         );
  //         setSlots([]);
  //       }

  //       setCheckDateLoading(false);
  //     } catch (e) {
  //       setCheckDateLoading(false);
  //     }
  //   }
  // };

  //       setAvailableDate(convertedDate);
  //       setIsAvailable(true);
  //       setSlots([]);
  //     } else if (resp.data.isAvailable === false) {
  //       setIsAvailable(false);
  //       setUnavailableText(
  //         "The selected date is unavailable. Please choose a different date or select one from the list below."
  //       );
  //       formik.values.scheduledDate = null;
  //       setSlots(resp.data.slots);
  //     }
  //   } else {
  //     isWithinHour = false;
  //     setScheduleTimeLeft(60);
  //     setFirstTimer(false);
  //     setIsAvailable(false);
  //     setUnavailableText(
  //       "The selected date is unavailable. Please choose a different date."
  //     );
  //     formik.values.scheduledDate = null;
  //     setSlots([]);
  //   }
  // } catch (e) {}

  // formik.setFieldError("scheduledDate", "");
  // if (isWithinHour) {
  //   if (firstTimer) {
  //     setScheduleTimeLeft(60); // Reset the time to 60 seconds
  //     setStartTimer(true);
  //     setFirstTimer(false);
  //   }
  // }
  // setCheckDateLoading(false);

  useEffect(() => {
    if (!initialValuesSet.current && guestUser?.registerData) {
      initialValuesSet.current = true;
      const { owners, ownersSelect, signerName, ownerAddress, ownerPhone } =
        extractOwnersData(
          guestUser?.registerData?.overview_data.affected_property
        );
      let address = "";
      let city = "";
      let state = "";
      let zipCode = "";

      if (guestUser?.registerData?.ownerAddressParts) {
        address = guestUser?.registerData?.ownerAddressParts?.address;
        city = guestUser?.registerData?.ownerAddressParts?.city;
        state = guestUser?.registerData?.ownerAddressParts?.state;
        zipCode = guestUser?.registerData?.ownerAddressParts?.zipCode;
      }
      setCopyOwners(owners);
      setOwnerships(guestUser?.registerData?.ownerships);
      setFullOwners(guestUser?.registerData?.ownersList);
      setInitialFormValues((prevState) => ({
        ...prevState,
        owners: owners,
        fullOwnerSelect: guestUser?.registerData?.ownersList,
        ownersSelect: ownersSelect,
        name: signerName,
        email: guestUser?.registerData?.ownerEmail,
        ownerAddress: ownerAddress,
        address: address,
        city: city,
        state: state,
        zipCode: zipCode,
        phone: ownerPhone,
      }));
      setPropertyDetails(guestUser?.registerData?.overview_data);

      setPropertyImage(guestUser?.registerData?.img);
      setIds(guestUser?.registerData?.id);

      setPageLoading(false);
    }
  }, [guestUser?.registerData]);

  // const formatTime = (seconds) => {
  //   const minutes = Math.floor(seconds / 60);
  //   const remainingSeconds = seconds % 60;
  //   return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  // };

  // function isValidTime(time) {
  //   // Check if the input is a valid date string
  //   if (typeof time === "string" && !isNaN(Date.parse(time))) {
  //     const dateObj = new Date(time);
  //     const minutes = dateObj.getMinutes();
  //     return minutes === 0 || minutes === 30;
  //   }
  //   return false;
  // }

  const handlePhoneInputChange = (event) => {
    const { value, name } = event.target;

    // Existing code for phone number formatting
    const digitsOnly = value.replace(/\D/g, ""); // Remove non-numeric characters
    let formattedPhoneNumber = "";

    // Add dashes after 3rd and 6th digits
    for (let i = 0; i < digitsOnly.length; i++) {
      if (i === 3 || i === 6) {
        formattedPhoneNumber += "-";
      }
      formattedPhoneNumber += digitsOnly[i];
    }

    formik.setFieldValue("phone", formattedPhoneNumber);
  };

  const createValidationSchema = (availableChecked, isInstantAvailable) => {
    const ownerSchema = Yup.object().shape({
      value: Yup.object()
        .shape({
          PERSON: Yup.string(),
          ORGANIZATION: Yup.string(),
        })
        .test(
          "type-dependent-validation",
          "Invalid type dependent data",
          function (value) {
            // const { type } = this.parent;
            if (signerType === "PERSON") {
              if (!value.PERSON) {
                return this.createError({
                  path: `${this.path}.PERSON`,
                  message: "Individual Name is required",
                });
              }
            } else if (signerType === "ORGANIZATION") {
              if (!value.ORGANIZATION) {
                return this.createError({
                  path: `${this.path}.ORGANIZATION`,
                  message: "Organization name is required",
                });
              }
            }
            return true;
          }
        ),
    });

    const schemaFields = {
      name: Yup.string()
        .min(3, "Name is too short")
        .required("Name is required")
        .test(
          "two-parts", // Name of the test
          "Please enter at least first and last name and least 3 character each", // Error message
          (value) => {
            // Split the name into parts based on spaces
            const parts = value ? value.split(" ").filter((part) => part) : [];
            // Check that there are at least two parts and each part has at least 3 characters
            return parts.length >= 2 && parts.every((part) => part.length >= 3);
          }
        ),
      signatoryTitle:
        signerType === "PERSON"
          ? Yup.string()
          : Yup.string()
              .min(3, "Signer Title is too short")
              .required("Signer Title is required"),
      authority:
        signerType === "PERSON"
          ? Yup.string()
          : Yup.string()
              .min(3, "Type of Authority is too short")
              .required("Type of Authority is required"),
      ownerAddress: Yup.string()
        .min(5, "Owner address is too short")
        .required("Owner address is required"),
      address: Yup.string()
        .min(3, "Address is too short")
        .required("Address is required"),
      city: Yup.string()
        .min(3, "City is too short")
        .required("City is required"),
      state: Yup.string()
        .min(3, "State is too short")
        .required("State is required"),
      zipCode: Yup.string()
        .required("Zip Code is required")
        .test("zipCode", "Invalid zip code", function (value) {
          return validator.isPostalCode(value, "US");
        }),
      dob: Yup.date()
        .required("Date of Birth is required")
        .max(subYears(new Date(), 18), "You must be at least 18 years old")
        .typeError("Please enter a valid Date of Birth"),
      email: Yup.string().required("Email is required"),
      phone: Yup.string()
        .required("Phone number is required")
        .test("PhoneTest", "Phone number is invalid", function (value) {
          const isValidPhoneNumber = validator.isMobilePhone(value);
          return isValidPhoneNumber;
        }),

      idType: Yup.string().required("Identification document type is required"),
      owners: Yup.array().of(ownerSchema),
    };

    return Yup.object(schemaFields);
  };
  const formik = useFormik({
    key: ids,
    enableReinitialize: true,
    initialValues: initialFormValues,
    validationSchema: createValidationSchema(
      availableChecked,
      isInstantAvailable
    ),
    validateOnBlur: true, // Validate only when an input field is blurred
    validateOnChange: false,
    onSubmit: async (values, helpers) => {},
  });

  const formikRef = useRef(formik);
  formikRef.current = formik;

  useEffect(() => {
    if (!formik?.values?.owners?.length) {
      formik.setFieldValue("owners", copyOwners);
    }
  }, [formik?.values?.owners]);

  // Effect for the countdown timer
  useEffect(() => {
    let intervalId;

    if (startTimer && scheduleTimeLeft > 0) {
      intervalId = setInterval(() => {
        setScheduleTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [startTimer, scheduleTimeLeft]);

  useEffect(() => {
    let intervalIdInstant;

    if (startInstantTimer && instantTimeLeft > 0) {
      intervalIdInstant = setInterval(() => {
        setInstantTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    }

    return () => clearInterval(intervalIdInstant);
  }, [startInstantTimer, instantTimeLeft]);

  // useEffect(() => {
  //   instantResponseTimeLeft
  //   let intervalIdInstantResponse;

  //   if (startInstantResponseTimer && instantResponseTimeLeft > 0) {
  //     intervalIdInstantResponse = setInterval(() => {
  //       setInstantResponseTimeLeft((prevTime) => prevTime - 1);
  //     }, 1000);
  //   }

  //   return () => clearInterval(intervalIdInstant);
  // }, [startInstantResponseTimer, instantResponseTimeLeft]);

  // Separate effect to handle when the timer reaches zero
  // useEffect(() => {
  //   if (scheduleTimeLeft === 0) {
  //     handleTimeUp(formikRef.current); // Call the function when time is up
  //     setStartTimer(false); // Optionally reset the startTimer state
  //     setFurtherSelectedBtn(false);
  //     setIsInstantAvailable(false);
  //     setTempDate(null);
  //     setTempTime(null);
  //     setFurtherDateObj(null);
  //     setFurtherData(null);
  //     setIsFurtherAvailable(false);
  //   }
  // }, [scheduleTimeLeft]);

  // useEffect(() => {
  //   if (
  //     (instantTimeLeft === 0 || instantTimeLeft < 0) &&
  //     immediateLock === false
  //   ) {
  //     handleTimeUp(formikRef.current); // Call the function when time is up
  //     setStartInstantTimer(false); // Optionally reset the startTimer state
  //     setExpeditedSelectedBtn(false);
  //   }
  // }, [instantTimeLeft]);

  useEffect(() => {
    if (
      submitError !== "" &&
      submitError !==
        "** Warning ** You should not leave the page or refresh the page while we are assigning you a notary. The process will take less than a minute."
    ) {
      setSubmitting(false);
      const interv = setInterval(() => {
        setSubmitError("");
      }, 5000);
      return () => clearInterval(interv);
    }
  }, [submitError]);

  // useEffect(() => {
  //   if (instantResponseTimeLeft === 0) {
  //     handleTimeUp(formikRef.current); // Call the function when time is up
  //     setStartInstantResponseTimer(false); // Optionally reset the startTimer state
  //   }
  // }, [instantResponseTimeLeft]);

  // useEffect(() => {
  //   if (submitting) {
  //     const errors = formik.errors;
  //     const errorKeys = Object.keys(errors || {});
  //     const hasOtherErrors =
  //       errorKeys.length > 0 &&
  //       (errorKeys.length > 1 || errorKeys[0] !== "scheduledDate");

  //     if (hasOtherErrors) {
  //       setSubmitError(
  //         "There are unresolved issues with your form submission. Please review the highlighted fields below and correct any errors to proceed. Scroll through the form to ensure all errors are addressed."
  //       );
  //     }
  //   }
  // }, [submitting, formik.errors]);

  return (
    <>
      {pageLoading ? (
        <>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100vh",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {tabletSize ? (
              <>
                <Box sx={{ mb: 2 }}>
                  <img
                    style={{
                      width: "10rem",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                    alt="Permit Rockstar Logo"
                    src="/img/website_img/PermitRockstar.png"
                  />
                </Box>
                <LottieAnimation />
              </>
            ) : (
              <CircularProgress color="warning" size="10rem" />
            )}
          </Box>
        </>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100vh", // Full viewport height
              overflow: "hidden", // Prevents scrolling outside the main box
              backgroundImage: tabletSize
                ? undefined
                : "url('/img/website_img/homeImg.jpeg')",
              backgroundRepeat: "repeat",
              justifyContent: "center",
              alignItems: tabletSize ? undefined : "center",
            }}
          >
            {/* Top Fixed Area */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                pt: 1,
                pb: 0,
                maxWidth: "90rem",
                ml: "auto",
                mr: "auto",
                backgroundColor: "#fff",
                zIndex: 1,
                width: "100%",
                position: "relative",
                boxShadow: "2px 2px 14px 1px #d3d3d3",
              }}
            >
              <img
                style={{
                  width: mobileSize ? "90%" : tabletSize ? "8rem" : "8rem",
                  maxWidth: mobileSize ? "6rem" : undefined,
                }}
                alt="Permit Rockstar Logo"
                src="/img/website_img/PermitRockstar.png"
              />
              {!mobileSize && (
                <Box
                  sx={{
                    position: "absolute",
                    top: tabletSize ? 18 : 27,
                    right: tabletSize ? 10 : 20,
                  }}
                >
                  <TimezoneSelect
                    options={timezoneMappings}
                    timezone={userTimezone}
                    setTimezone={setUserTimeZone}
                    changeLocal={true}
                    specificWidth={largeTabletSize ? "11rem" : undefined}
                    specialDisable={
                      isInstantAvailable &&
                      !checkInstantLoading &&
                      !instantAccepted
                    }
                    specialDisabled2={
                      formik.values.scheduledDate ||
                      slots.length !== 0 ||
                      (instantError !== "" &&
                        instantError !==
                          "No available notaries at this moment.") ||
                      checkDateLoading
                    }
                  />
                </Box>
              )}
              <Box sx={{ px: 1 }}>
                <Typography
                  color="primary"
                  variant={mobileSize ? "h5" : "h4"}
                  sx={{ mb: mobileSize ? 1 : 2, mt: 1 }}
                  align="center"
                >
                  Schedule Your Remote Online Notarization (RON) Session
                </Typography>

                <Typography
                  color="primary"
                  variant={mobileSize ? "body2" : "body1"}
                  align="center"
                  sx={{ pb: mobileSize ? 0 : 1 }}
                >
                  To get started, carefully review the important details
                  provided below. Then, fill out the required fields and choose
                  a preferred date and time to schedule your Remote Online
                  Notarization (RON) session.
                </Typography>
                {mobileSize && (
                  <ExpandableTimezone
                    open={timezoneOpen}
                    setOpen={setTimezoneOpen}
                    timezoneMappings={timezoneMappings}
                    userTimezone={userTimezone}
                    setUserTimeZone={setUserTimeZone}
                    largeTabletSize={largeTabletSize}
                    isInstantAvailable={isInstantAvailable}
                    checkInstantLoading={checkInstantLoading}
                    instantAccepted={instantAccepted}
                    formik={formik}
                    slots={slots}
                    instantError={instantError}
                    checkDateLoading={checkDateLoading}
                  />
                )}
              </Box>
            </Box>

            {/* Scrollable Content Area */}
            <Box
              sx={{
                flexGrow: 2,
                overflowY: "auto",
                paddingRight: "calc(100% - 100vw)",
                py: 2,
                bgcolor: "background.paper",
                maxWidth: "90rem",
                width: "100%",
                ml: "auto",
                mr: "auto",
                mb: "70px",
              }}
            >
              <TableOverview
                propertyDetails={propertyDetails}
                setDocumentModal={setDocumentModal}
                ownerTypes={ownerTypes}
                setOwnerTypes={setOwnerTypes}
                formik={formik}
                fullOwners={fullOwners}
                copyOwners={copyOwners}
                setCopyOwners={setCopyOwners}
                ownerships={ownerships}
                isFieldsDisabled={isFieldsDisabled}
                setSignerType={setSignerType}
                mobileSize={mobileSize}
                tabletSize={tabletSize}
                largeTabletSize={largeTabletSize}
              />
              <Box
                sx={{
                  borderBottom: largeTabletSize ? "none" : "3px solid #f3f3f3",
                  pb: 1,
                  mb: largeTabletSize ? 0 : 2,
                  mt: 2,
                  mx: mobileSize ? 0.5 : 2,
                }}
              >
                <Typography variant={mobileSize ? "h5" : "h4"} align="center">
                  Property Owner or Authorized Agent
                </Typography>
                <Typography
                  sx={{
                    mt: largeTabletSize ? 0.2 : 1,
                  }}
                  variant={mobileSize ? "body2" : "body1"}
                  align="center"
                >
                  Please Enter the Information of the Person Signing the
                  Document
                </Typography>
              </Box>
              <Box sx={{ px: mobileSize ? 0.5 : 2 }}>
                <Grid container spacing={largeTabletSize ? 1 : 2}>
                  <Grid item xs={12} lg={4}>
                    <TextField
                      disabled={isFieldsDisabled()}
                      error={Boolean(formik.touched.name && formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                      name="name"
                      label="Signer Name"
                      variant="filled"
                      fullWidth
                      onBlur={formik.handleBlur}
                      value={formik.values.name}
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4} sx={{ position: "relative" }}>
                    <TextField
                      disabled={isFieldsDisabled()}
                      error={Boolean(
                        formik.touched.signatoryTitle &&
                          formik.errors.signatoryTitle
                      )}
                      helperText={
                        formik.touched.signatoryTitle &&
                        formik.errors.signatoryTitle
                      }
                      name="signatoryTitle"
                      label={
                        mobileSize
                          ? "Signer Title (Owner, President, CEO, etc):"
                          : "Signer Title (Owner, President, CEO, Manager, etc):"
                      }
                      variant="filled"
                      fullWidth
                      onBlur={formik.handleBlur}
                      value={formik.values.signatoryTitle}
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            sx={{ cursor: "help" }}
                          >
                            {tabletSize ? (
                              <IconButton
                                onClick={() => {
                                  if (typeInfo) {
                                    setTypeInfo(false);
                                  }
                                  if (titleInfo) {
                                    setTitleInfo(false);
                                  }
                                  if (!titleInfo) {
                                    setTitleInfo(true);
                                    setInfoText(
                                      "Please provide the job title of the person in the organization that owns the property, and who will sign the documents (e.g., Owner, President, Manager). If signing as an individual's agent specify your authority (like Agent or Trustee). Leave blank if signing for yourself."
                                    );
                                  }
                                }}
                                sx={{ pr: 0 }}
                              >
                                <HelpOutlineIcon color="success" />
                              </IconButton>
                            ) : (
                              <Tooltip title="Please provide the job title of the person in the organization that owns the property, and who will sign the documents (e.g., Owner, President, Manager). If signing as an individual's agent specify your authority (like Agent or Trustee). Leave blank if signing for yourself.">
                                <HelpOutlineIcon color="success" />
                              </Tooltip>
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                    {titleInfo && (
                      <SigneeRegisterInfo
                        infoText={infoText}
                        isFadingOut={titleIsFadingOut}
                      />
                    )}
                  </Grid>

                  <Grid item xs={12} lg={4} sx={{ position: "relative" }}>
                    <TextField
                      disabled={isFieldsDisabled()}
                      error={Boolean(
                        formik.touched.authority && formik.errors.authority
                      )}
                      helperText={
                        formik.touched.authority && formik.errors.authority
                      }
                      name="authority"
                      label={
                        mobileSize
                          ? "Type of authority (Trustee, Agent, etc):"
                          : "Type of authority (Owner, Agent, Trustee, Attorney in fact, etc):"
                      }
                      variant="filled"
                      fullWidth
                      onBlur={formik.handleBlur}
                      value={formik.values.authority}
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            sx={{ cursor: "help" }}
                          >
                            {tabletSize ? (
                              <IconButton
                                onClick={() => {
                                  if (titleInfo) {
                                    setTitleInfo(false);
                                  }
                                  if (typeInfo) {
                                    setTypeInfo(false);
                                  }
                                  if (!typeInfo) {
                                    setTypeInfo(true);
                                    setInfoText(
                                      "This is the authority under which you are signing this document. It could be as an Agent, trustee, or anything else on behalf of the Owner. Leave blank if signing for yourself."
                                    );
                                  }
                                }}
                                sx={{ pr: 0 }}
                              >
                                <HelpOutlineIcon color="success" />
                              </IconButton>
                            ) : (
                              <Tooltip title="This is the authority under which you are signing this document. It could be as an Agent, trustee, or anything else on behalf of the Owner. Leave blank if signing for yourself.">
                                <HelpOutlineIcon color="success" />
                              </Tooltip>
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                    {typeInfo && (
                      <SigneeRegisterInfo
                        infoText={infoText}
                        isFadingOut={isFadingOut}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <TextField
                      disabled={isFieldsDisabled()}
                      error={Boolean(
                        formik.touched.address && formik.errors.address
                      )}
                      helperText={
                        formik.touched.address && formik.errors.address
                      }
                      name="address"
                      label="Address"
                      variant="filled"
                      fullWidth
                      onBlur={formik.handleBlur}
                      value={formik.values.address}
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <TextField
                      disabled={isFieldsDisabled()}
                      error={Boolean(formik.touched.city && formik.errors.city)}
                      helperText={formik.touched.city && formik.errors.city}
                      name="city"
                      label="City"
                      variant="filled"
                      fullWidth
                      onBlur={formik.handleBlur}
                      value={formik.values.city}
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <FormControl
                      variant="filled"
                      fullWidth
                      error={Boolean(
                        formik.touched.state && formik.errors.state
                      )}
                    >
                      <InputLabel
                        sx={{
                          color:
                            formik.touched.state && formik.errors.state
                              ? "#f04438"
                              : "auto",
                          maxWidth: "90%", // Limit the label's width
                          whiteSpace: "nowrap", // Prevent label from wrapping
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                        id="fullOwnerSelect-label"
                      >
                        State
                      </InputLabel>
                      <Select
                        disabled={isFieldsDisabled()}
                        error={Boolean(
                          formik.touched.state && formik.errors.state
                        )}
                        fullWidth
                        name="state"
                        value={formik.values.state}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              marginTop: "1.5px",
                              maxHeight: 150, // Set the desired max height for the dropdown menu
                              width: "auto",
                              maxWidth: "100%",
                            },
                          },
                        }}
                        sx={{
                          maxWidth: "100%", // Set the desired max-width for the input element
                          "& .MuiTypography-body2": {
                            whiteSpace: "wrap", // Prevent text from wrapping or shrinking
                          },
                        }}
                      >
                        {usStates.map((item, index) => (
                          <MenuItem
                            key={`${item.name}-${index}`}
                            value={item.name}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText sx={{ color: "#f04438" }}>
                        {formik.touched.state && formik.errors.state}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <TextField
                      disabled={isFieldsDisabled()}
                      error={Boolean(
                        formik.touched.zipCode && formik.errors.zipCode
                      )}
                      helperText={
                        formik.touched.zipCode && formik.errors.zipCode
                      }
                      name="zipCode"
                      label="Zip Code"
                      variant="filled"
                      fullWidth
                      onBlur={formik.handleBlur}
                      value={formik.values.zipCode}
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box
                sx={{
                  my: 4,
                  py: 2,
                  mx: mobileSize ? 0 : 2,
                  px: mobileSize ? 0.5 : 2,
                  border: tabletSize
                    ? "1px solid #d3d3d3"
                    : "3px solid #d3d3d3",
                  borderRadius: mobileSize ? "0px" : "16px",
                  boxShadow: mobileSize
                    ? "2px 5px 5px 2px rgba(0, 0, 0, 0.1)"
                    : "1px 1px 15px 10px rgba(0, 0, 0, 0.2)",
                  backgroundColor: "#F0F8FF",
                }}
              >
                <Typography
                  variant={mobileSize ? "h6" : tabletSize ? "h5" : "h5"}
                  color="primary"
                  align="center"
                >
                  In order to identify you appropriately before signing the
                  document(s), you will be required to provide the following
                  information
                </Typography>
                <Grid
                  container
                  spacing={mobileSize ? 1 : 2}
                  mt={mobileSize ? 2 : 2}
                >
                  <Grid item xs={12} sm={6}>
                    <DatePicker
                      disabled={isFieldsDisabled()}
                      disableFuture
                      sx={{ width: "100%" }}
                      label="Date of Birth"
                      value={formik.values.dob}
                      onChange={(value) =>
                        formik.setFieldValue("dob", value, true)
                      }
                      slotProps={{
                        textField: {
                          variant: "filled",
                          onBlur: formik.handleBlur("dob"),
                          error: Boolean(
                            formik.touched.dob && formik.errors.dob
                          ),
                          helperText: formik.touched.dob && formik.errors.dob,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      disabled={isFieldsDisabled()}
                      error={Boolean(
                        formik.touched.email && formik.errors.email
                      )}
                      helperText={formik.touched.email && formik.errors.email}
                      name="email"
                      label="Email"
                      variant="filled"
                      fullWidth
                      onBlur={formik.handleBlur}
                      value={formik.values.email || ""}
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      disabled={isFieldsDisabled()}
                      error={Boolean(
                        formik.touched.phone && formik.errors.phone
                      )}
                      helperText={formik.touched.phone && formik.errors.phone}
                      name="phone"
                      label="Phone Number"
                      variant="filled"
                      fullWidth
                      onBlur={formik.handleBlur}
                      value={formik.values.phone}
                      onKeyUp={handlePhoneInputChange}
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl
                      variant="filled"
                      fullWidth
                      error={Boolean(
                        formik.touched.fullOwnerSelect &&
                          formik.errors.fullOwnerSelect
                      )}
                    >
                      <InputLabel
                        sx={
                          formik.touched.idType && formik.errors.idType
                            ? { color: "#f04438" }
                            : { color: "auto" }
                        }
                        id="fullOwnerSelect-label"
                      >
                        {mobileSize
                          ? "ID Type"
                          : "Identification Document Type"}
                      </InputLabel>
                      <Select
                        disabled={isFieldsDisabled()}
                        error={Boolean(
                          formik.touched.idType && formik.errors.idType
                        )}
                        labelId="id-label"
                        fullWidth
                        name="idType"
                        value={formik?.values?.idType}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        {[
                          "State Identification Card",
                          "Driver's License",
                          "US Passport",
                          "International Passport",
                        ].map((item, index) => (
                          <MenuItem key={`${item}-${index}`} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText sx={{ color: "#f04438" }}>
                        {formik.touched.idType && formik.errors.idType}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  {/* <Grid item xs={12} sm={6}>
                    <TextField
                      disabled={isFieldsDisabled()}
                      error={Boolean(formik.touched.id && formik.errors.id)}
                      helperText={formik.touched.id && formik.errors.id}
                      name="id"
                      label={
                        mobileSize
                          ? "ID Number"
                          : "Identification Document Number"
                      }
                      variant="filled"
                      fullWidth
                      onBlur={formik.handleBlur}
                      value={formik.values.id}
                      onKeyUp={handlePhoneInputChange}
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                    />
                  </Grid> */}
                  {/* <Grid item xs={12} sm={6}>
                    <DatePicker
                      disabled={isFieldsDisabled()}
                      disablePast
                      sx={{ width: "100%" }}
                      label={
                        mobileSize
                          ? "ID Expiration Date"
                          : "Identification Document Expiration Date"
                      }
                      value={formik.values.idExp}
                      onChange={(value) =>
                        formik.setFieldValue("idExp", value, true)
                      }
                      slotProps={{
                        textField: {
                          variant: "filled",
                          onBlur: formik.handleBlur("idExp"),
                          error: Boolean(
                            formik.touched.idExp && formik.errors.idExp
                          ),
                          helperText:
                            formik.touched.idExp && formik.errors.idExp,
                        },
                      }}
                    />
                  </Grid> */}
                </Grid>
              </Box>
              <Box>
                <ScheduleContractorRonModal
                  open={open}
                  setOpen={setOpen}
                  selectedRow={selectedRow}
                  setRonStarted={false}
                  setSnackOpen={null}
                  ownerData={formik?.values}
                  ownerZone={userTimezone}
                />
                {/* <Schedule
                  setInstantError={setInstantError}
                  propertyDetails={propertyDetails}
                  userTimezone={userTimezone}
                  setDocumentModal={setDocumentModal}
                  formik={formik}
                  instantTimeLeft={formatTime(instantTimeLeft)}
                  timeLeft={formatTime(scheduleTimeLeft)}
                  handleStartClick={handleStartClick}
                  handleImmediateRON={handleImmediateRON}
                  checkDateLoading={checkDateLoading}
                  slots={slots}
                  slotIndex={slotIndex}
                  setSlotIndex={setSlotIndex}
                  convertObjectToDayjs={convertObjectToDayjs}
                  isAvailable={isAvailable}
                  setIsAvailable={setIsAvailable}
                  availableDate={availableDate}
                  setAvailableDate={setAvailableDate}
                  startTimer={startTimer}
                  startInstantTimer={startInstantTimer}
                  unavailableText={unavailableText}
                  checkInstantLoading={checkInstantLoading}
                  setUnavailableText={setUnavailableText}
                  isInstantAvailable={isInstantAvailable}
                  setIsInstantAvailable={setIsInstantAvailable}
                  instantData={instantData}
                  instantError={instantError}
                  setInstantTimeLeft={setInstantTimeLeft}
                  setTimeLeft={setScheduleTimeLeft}
                  setInstantData={setInstantData}
                  setSlots={setSlots}
                  immediateRequested={immediateRequested}
                  immediateLock={immediateLock}
                  setCanceledInstant={setCanceledInstant}
                  instantAccepted={instantAccepted}
                  mobileSize={mobileSize}
                  tabletSize={tabletSize}
                  largeTabletSize={largeTabletSize}
                  setSubmitting={setSubmitting}
                  expeditedSelectedBtn={expeditedSelectedBtn}
                  setExpeditedSelectedBtn={setExpeditedSelectedBtn}
                  furtherSelectedBtn={furtherSelectedBtn}
                  setFurtherSelectedBtn={setFurtherSelectedBtn}
                  tempDate={tempDate}
                  tempTime={tempTime}
                  setTempDate={setTempDate}
                  setTempTime={setTempTime}
                  furtherDateObj={furtherDateObj}
                  setFurtherDateObj={setFurtherDateObj}
                  yesLoading={yesLoading}
                  furtherRequested={furtherRequested}
                  isFurtherAvailable={isFurtherAvailable}
                  setIsFurtherAvailable={setIsFurtherAvailable}
                  furtherData={furtherData}
                  setFurtherData={setFurtherData}
                  furtherError={furtherError}
                  setCheckDateLoading={setCheckDateLoading}
                  setFurtherRequested={setFurtherRequested}
                /> */}
              </Box>
            </Box>

            {/* Bottom Fixed Area */}
            <Box
              sx={{
                py: 0.5,
                position: "fixed",
                bgcolor: "#fff",
                maxWidth: "90rem",
                ml: "auto",
                mr: "auto",
                bottom: 0,
                left: 0,
                right: 0,
                opacity: 1,
                zIndex: 1100, // Higher index to ensure it's above other content
              }}
            >
              <Box
                sx={{
                  px: mobileSize ? 1 : 2,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  pb: 2,
                  boxShadow: "2px 2px 14px 1px #d3d3d3",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    mt: 2,
                    mb: 0,
                  }}
                >
                  {submitError ? (
                    <Typography
                      sx={{ mt: 1, mb: 1 }}
                      fontWeight="bold"
                      align="center"
                      variant={tabletSize ? "body2" : "h6"}
                      color="error"
                    >
                      {submitError}
                    </Typography>
                  ) : (
                    <Button
                      onClick={async () => {
                        const errors = await formik.validateForm();
                        // Check if the errors object is empty
                        if (!errors || Object.keys(errors).length === 0) {
                          setOpen(true);
                        } else {
                          // Iterate through the errors and log them
                          for (const [field, error] of Object.entries(errors)) {
                            await formik.setFieldTouched(field, true, true);
                          }
                          setSubmitError(
                            "Some fields are incomplete. Please review the form carefully before proceeding."
                          );
                        }
                      }}
                      color="success"
                      sx={{ width: "20rem" }}
                      size="large"
                      variant="contained"
                    >
                      Schedule your session
                    </Button>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
          <NotarizedModal
            notarizedModal={documentModal}
            setNotarizedModal={setDocumentModal}
            pdfImg={propertyImage[0]}
            pdfImg2={propertyImage[1]}
            previewImg=""
            preview={false}
            largeTabletSize={largeTabletSize}
            mobileSize={mobileSize}
            tabletSize={tabletSize}
            type={"registerSignee"}
          />
        </>
        //! ////////////////////////////////////////////////////////////
        // <Box
        //   sx={
        //     tabletSize
        //       ? {
        //           justifyContent: "center",
        //           overflowX: "hidden",
        //           px: 1,
        //           minHeight: "100vh",
        //           overflowY: "hidden",
        //           display: "flex",
        //           flexDirection: "column",
        //         }
        //       : {
        //           backgroundImage: "url('/img/website_img/homeImg.jpeg')",
        //           backgroundRepeat: "repeat",

        //           minHeight: "100vh",
        //           overflow: "hidden",
        //           alignItems: "center",
        //           display: "flex",
        //           flexDirection: "column",
        //           justifyContent: "center",
        //         }
        //   }
        // >
        //   <Box
        //     sx={
        //       tabletSize
        //         ? {
        //             backgroundColor: "#fff",
        //             py: "1rem",
        //             maxWidth: "100%",
        //             flexGrow: 1,
        //           }
        //         : {
        //             backgroundColor: "#fff",
        //             my: 2,
        //             maxWidth: largePC ? "1300px" : "80%",
        //             borderRadius: "25px",
        //             px: 3,
        //             width: "80%",
        //             py: "2rem",
        //             position: "relative",
        //           }
        //     }
        //   >
        //     <Box
        //       sx={{
        //         display: "flex",
        //         justifyContent: "center",
        //       }}
        //       // onClick={() => {
        //       //   navigate("/");
        //       // }}
        //     >
        //       <img
        //         style={{
        //           width: mobileSize ? "90%" : tabletSize ? "15rem" : "20rem",
        //           maxWidth: mobileSize ? "15rem" : undefined,
        //         }}
        //         alt="nocCreatorlogo"
        //         src="/img/logos/nocLogo.png"
        //       />
        //     </Box>
        //     {/* Top Fixed Text */}
        //     <Box
        //       sx={{
        //         position: "absolute",
        //         top: tabletSize ? 27 : 48,
        //         right: tabletSize ? 10 : 20,
        //       }}
        //     >
        //       {!mobileSize && (
        //         <TimezoneSelect
        //           options={timezoneMappings}
        //           timezone={userTimezone}
        //           setTimezone={setUserTimeZone}
        //           changeLocal={true}
        //           specificWidth={largeTabletSize ? "11rem" : undefined}
        //           specialDisable={
        //             isInstantAvailable &&
        //             !checkInstantLoading &&
        //             !instantAccepted
        //           }
        //           specialDisabled2={
        //             formik.values.scheduledDate ||
        //             slots.length !== 0 ||
        //             (instantError !== "" &&
        //               instantError !==
        //                 "No available notaries at this moment.") ||
        //             checkDateLoading
        //           }
        //         />
        //       )}
        //     </Box>

        //     <Typography
        //       color="primary"
        //       variant={mobileSize ? "h5" : "h4"}
        //       sx={{ mb: mobileSize ? 1 : 2, mt: 1 }}
        //       align="center"
        //     >
        //       Schedule Your Remote Online Notarization (RON) Session
        //     </Typography>

        //     <Typography
        //       color="primary"
        //       variant={mobileSize ? "body2" : "body1"}
        //       align="center"
        //       sx={{ mb: 1 }}
        //     >
        //       To get started, carefully review the important details provided
        //       below. Then, fill out the required fields and choose a preferred
        //       date and time to schedule your Remote Online Notarization (RON)
        //       session.
        //     </Typography>
        //     {mobileSize && (
        //       <Box sx={{ display: "flex", justifyContent: "center", mb: 1 }}>
        //         <TimezoneSelect
        //           options={timezoneMappings}
        //           timezone={userTimezone}
        //           setTimezone={setUserTimeZone}
        //           changeLocal={true}
        //           specificWidth={largeTabletSize ? "15rem" : undefined}
        //           specialDisable={
        //             isInstantAvailable &&
        //             !checkInstantLoading &&
        //             !instantAccepted
        //           }
        //           specialDisabled2={
        //             formik.values.scheduledDate ||
        //             slots.length !== 0 ||
        //             instantError !== "" ||
        //             checkDateLoading
        //           }
        //         />
        //       </Box>
        //     )}

        //     {/* Scrollable Area Start */}
        //     <form noValidate onSubmit={formik.handleSubmit}>
        //       <Box
        //         sx={{
        //           display: "flex",
        //           flexDirection: "column",
        //           height: "63vh", // Adjust the height as needed
        //         }}
        //       >
        //         {/* Scrollable Area */}
        //         <Box
        //           ref={scrollableElementRef}
        //           sx={{
        //             maxHeight: "calc(100vh - 45vh)", // Adjust this value based on your header and footer height
        //             overflowY: "auto",
        //             width: "100%",
        //             flex: "1 1 auto", // Makes this box flexible in terms of height
        //             mt: 1,
        //             pt: 1,
        //             boxShadow: "2px 2px 14px 1px #d3d3d3",
        //           }}
        //         >
        //           {/* Overview and Details */}
        //           <TableOverview
        //             propertyDetails={propertyDetails}
        //             setDocumentModal={setDocumentModal}
        //             ownerTypes={ownerTypes}
        //             setOwnerTypes={setOwnerTypes}
        //             formik={formik}
        //             fullOwners={fullOwners}
        //             copyOwners={copyOwners}
        //             setCopyOwners={setCopyOwners}
        //             ownerships={ownerships}
        //             isFieldsDisabled={isFieldsDisabled}
        //             setSignerType={setSignerType}
        //             mobileSize={mobileSize}
        //             tabletSize={tabletSize}
        //             largeTabletSize={largeTabletSize}
        //           />

        //           {/*  */}

        //           <Box
        //             sx={{
        //               borderBottom: largeTabletSize
        //                 ? "none"
        //                 : "3px solid #f3f3f3",
        //               width: "100%",
        //               pb: 1,
        //               mb: largeTabletSize ? 0 : 2,
        //               mt: 2,
        //             }}
        //           >
        //             <Typography
        //               variant={mobileSize ? "h5" : "h4"}
        //               sx={{ pl: mobileSize ? 1 : 0 }}
        //               align="center"
        //             >
        //               Property Owner or Authorized Agent
        //             </Typography>
        //             <Typography
        //               sx={{
        //                 mt: largeTabletSize ? 0.2 : 1,
        //                 pl: mobileSize ? 1 : 0,
        //               }}
        //               variant={mobileSize ? "body2" : "body1"}
        //               align="center"
        //             >
        //               Please Enter the Information of the Person Signing the
        //               Document
        //             </Typography>
        //           </Box>

        //           <Grid
        //             container
        //             spacing={largeTabletSize ? 1 : 2}
        //             sx={{ px: "2px" }}
        //           >
        //             <Grid item xs={12} lg={4}>
        //               <TextField
        //                 disabled={isFieldsDisabled()}
        //                 error={Boolean(
        //                   formik.touched.name && formik.errors.name
        //                 )}
        //                 helperText={formik.touched.name && formik.errors.name}
        //                 name="name"
        //                 label="Signer Name"
        //                 variant="filled"
        //                 fullWidth
        //                 onBlur={formik.handleBlur}
        //                 value={formik.values.name}
        //                 onChange={(e) => {
        //                   formik.handleChange(e);
        //                 }}
        //               />
        //             </Grid>
        //             <Grid item xs={12} lg={4}>
        //               <TextField
        //                 disabled={isFieldsDisabled()}
        //                 error={Boolean(
        //                   formik.touched.signatoryTitle &&
        //                     formik.errors.signatoryTitle
        //                 )}
        //                 helperText={
        //                   formik.touched.signatoryTitle &&
        //                   formik.errors.signatoryTitle
        //                 }
        //                 name="signatoryTitle"
        //                 label={
        //                   mobileSize
        //                     ? "Signer Title (Owner, President, CEO, etc):"
        //                     : "Signer Title (Owner, President, CEO, Manager, etc):"
        //                 }
        //                 variant="filled"
        //                 fullWidth
        //                 onBlur={formik.handleBlur}
        //                 value={formik.values.signatoryTitle}
        //                 onChange={(e) => {
        //                   formik.handleChange(e);
        //                 }}
        //                 InputProps={{
        //                   endAdornment: (
        //                     <InputAdornment
        //                       position="end"
        //                       sx={{ cursor: "help" }}
        //                     >
        //                       <Tooltip title="Please provide the job title of the person in the organization that owns the property, and who will sign the documents (e.g., Owner, President, Manager). If signing as an individual's agent specify your authority (like Agent or Trustee). Leave blank if signing for yourself.">
        //                         <HelpOutlineIcon color="success" />
        //                       </Tooltip>
        //                     </InputAdornment>
        //                   ),
        //                 }}
        //               />
        //             </Grid>

        //             <Grid item xs={12} lg={4}>
        //               <TextField
        //                 disabled={isFieldsDisabled()}
        //                 error={Boolean(
        //                   formik.touched.authority && formik.errors.authority
        //                 )}
        //                 helperText={
        //                   formik.touched.authority && formik.errors.authority
        //                 }
        //                 name="authority"
        //                 label={
        //                   mobileSize
        //                     ? "Type of authority (Trustee, Agent, etc):"
        //                     : "Type of authority (Owner, Agent, Trustee, Attorney in fact, etc):"
        //                 }
        //                 variant="filled"
        //                 fullWidth
        //                 onBlur={formik.handleBlur}
        //                 value={formik.values.authority}
        //                 onChange={(e) => {
        //                   formik.handleChange(e);
        //                 }}
        //                 InputProps={{
        //                   endAdornment: (
        //                     <InputAdornment
        //                       position="end"
        //                       sx={{ cursor: "help" }}
        //                     >
        //                       <Tooltip title="This is the authority under which you are signing this document. It could be as an Agent, trustee, or anything else on behalf of the Owner. Leave blank if signing for yourself.">
        //                         <HelpOutlineIcon color="success" />
        //                       </Tooltip>
        //                     </InputAdornment>
        //                   ),
        //                 }}
        //               />
        //             </Grid>
        //             <Grid item xs={12} lg={3}>
        //               <TextField
        //                 disabled={isFieldsDisabled()}
        //                 error={Boolean(
        //                   formik.touched.address && formik.errors.address
        //                 )}
        //                 helperText={
        //                   formik.touched.address && formik.errors.address
        //                 }
        //                 name="address"
        //                 label="Address"
        //                 variant="filled"
        //                 fullWidth
        //                 onBlur={formik.handleBlur}
        //                 value={formik.values.address}
        //                 onChange={(e) => {
        //                   formik.handleChange(e);
        //                 }}
        //               />
        //             </Grid>
        //             <Grid item xs={12} lg={3}>
        //               <TextField
        //                 disabled={isFieldsDisabled()}
        //                 error={Boolean(
        //                   formik.touched.city && formik.errors.city
        //                 )}
        //                 helperText={formik.touched.city && formik.errors.city}
        //                 name="city"
        //                 label="City"
        //                 variant="filled"
        //                 fullWidth
        //                 onBlur={formik.handleBlur}
        //                 value={formik.values.city}
        //                 onChange={(e) => {
        //                   formik.handleChange(e);
        //                 }}
        //               />
        //             </Grid>
        //             <Grid item xs={12} lg={3}>
        //               <TextField
        //                 disabled={isFieldsDisabled()}
        //                 error={Boolean(
        //                   formik.touched.state && formik.errors.state
        //                 )}
        //                 helperText={formik.touched.state && formik.errors.state}
        //                 name="state"
        //                 label="State"
        //                 variant="filled"
        //                 fullWidth
        //                 onBlur={formik.handleBlur}
        //                 value={formik.values.state}
        //                 onChange={(e) => {
        //                   formik.handleChange(e);
        //                 }}
        //               />
        //             </Grid>
        //             <Grid item xs={12} lg={3}>
        //               <TextField
        //                 disabled={isFieldsDisabled()}
        //                 error={Boolean(
        //                   formik.touched.zipCode && formik.errors.zipCode
        //                 )}
        //                 helperText={
        //                   formik.touched.zipCode && formik.errors.zipCode
        //                 }
        //                 name="zipCode"
        //                 label="Zip Code"
        //                 variant="filled"
        //                 fullWidth
        //                 onBlur={formik.handleBlur}
        //                 value={formik.values.zipCode}
        //                 onChange={(e) => {
        //                   formik.handleChange(e);
        //                 }}
        //               />
        //             </Grid>

        //             {/* INFO BOX STARTED */}
        //             <Box
        //               sx={{
        //                 ml: tabletSize ? 0 : 4,
        //                 mr: tabletSize ? 0 : 2,
        //                 mt: tabletSize ? 2 : 6,
        //                 mb: tabletSize ? 0 : 4,
        //                 pt: tabletSize ? 2 : 4,
        //                 pr: tabletSize ? 1 : 6,
        //                 pl: tabletSize ? 1 : 6,
        //                 pb: tabletSize ? 2 : 6,
        //                 width: "100%",
        //                 border: tabletSize
        //                   ? "1px solid #d3d3d3"
        //                   : "3px solid #d3d3d3",
        //                 borderRadius: tabletSize ? "0px" : "16px",
        //                 boxShadow: tabletSize
        //                   ? "2px 5px 5px 2px rgba(0, 0, 0, 0.1)"
        //                   : "1px 1px 15px 10px rgba(0, 0, 0, 0.2)",
        //                 backgroundColor: "#F0F8FF",
        //               }}
        //             >
        //               <Typography
        //                 variant={mobileSize ? "h6" : tabletSize ? "h5" : "h5"}
        //                 color="primary"
        //                 align="center"
        //               >
        //                 In order to identify you appropriately before signing
        //                 the document(s), you will be required to provide the
        //                 following information
        //               </Typography>
        //               <Grid
        //                 container
        //                 spacing={mobileSize ? 1 : 2}
        //                 mt={mobileSize ? 2 : 6}
        //               >
        //                 <Grid item xs={12} sm={6}>
        //                   <DatePicker
        //                     disabled={isFieldsDisabled()}
        //                     disableFuture
        //                     sx={{ width: "100%" }}
        //                     label="Date of Birth"
        //                     value={formik.values.dob}
        //                     onChange={(value) =>
        //                       formik.setFieldValue("dob", value, true)
        //                     }
        //                     slotProps={{
        //                       textField: {
        //                         variant: "filled",
        //                         onBlur: formik.handleBlur("dob"),
        //                         error: Boolean(
        //                           formik.touched.dob && formik.errors.dob
        //                         ),
        //                         helperText:
        //                           formik.touched.dob && formik.errors.dob,
        //                       },
        //                     }}
        //                   />
        //                 </Grid>
        //                 <Grid item xs={12} sm={6}>
        //                   <TextField
        //                     disabled={isFieldsDisabled()}
        //                     error={Boolean(
        //                       formik.touched.email && formik.errors.email
        //                     )}
        //                     helperText={
        //                       formik.touched.email && formik.errors.email
        //                     }
        //                     name="email"
        //                     label="Email"
        //                     variant="filled"
        //                     fullWidth
        //                     onBlur={formik.handleBlur}
        //                     value={formik.values.email || ""}
        //                     onChange={(e) => {
        //                       formik.handleChange(e);
        //                     }}
        //                   />
        //                 </Grid>
        //                 <Grid item xs={12} sm={6}>
        //                   <TextField
        //                     disabled={isFieldsDisabled()}
        //                     error={Boolean(
        //                       formik.touched.phone && formik.errors.phone
        //                     )}
        //                     helperText={
        //                       formik.touched.phone && formik.errors.phone
        //                     }
        //                     name="phone"
        //                     label="Phone Number"
        //                     variant="filled"
        //                     fullWidth
        //                     onBlur={formik.handleBlur}
        //                     value={formik.values.phone}
        //                     onKeyUp={handlePhoneInputChange}
        //                     onChange={(e) => {
        //                       formik.handleChange(e);
        //                     }}
        //                   />
        //                 </Grid>
        //                 <Grid item xs={12} sm={6}>
        //                   <FormControl
        //                     variant="filled"
        //                     fullWidth
        //                     error={Boolean(
        //                       formik.touched.fullOwnerSelect &&
        //                         formik.errors.fullOwnerSelect
        //                     )}
        //                   >
        //                     <InputLabel
        //                       sx={
        //                         formik.touched.idType && formik.errors.idType
        //                           ? { color: "#f04438" }
        //                           : { color: "auto" }
        //                       }
        //                       id="fullOwnerSelect-label"
        //                     >
        //                       {mobileSize
        //                         ? "ID Type"
        //                         : "Identification Document Type"}
        //                     </InputLabel>
        //                     <Select
        //                       disabled={isFieldsDisabled()}
        //                       error={Boolean(
        //                         formik.touched.idType && formik.errors.idType
        //                       )}
        //                       labelId="id-label"
        //                       fullWidth
        //                       name="idType"
        //                       value={formik?.values?.idType}
        //                       onChange={formik.handleChange}
        //                       onBlur={formik.handleBlur}
        //                     >
        //                       {[
        //                         "State Identification Card",
        //                         "Driver's License",
        //                         "US Passport",
        //                         "International Passport",
        //                       ].map((item, index) => (
        //                         <MenuItem key={`${item}-${index}`} value={item}>
        //                           {item}
        //                         </MenuItem>
        //                       ))}
        //                     </Select>
        //                     <FormHelperText sx={{ color: "#f04438" }}>
        //                       {formik.touched.idType && formik.errors.idType}
        //                     </FormHelperText>
        //                   </FormControl>
        //                 </Grid>
        //                 <Grid item xs={12} sm={6}>
        //                   <TextField
        //                     disabled={isFieldsDisabled()}
        //                     error={Boolean(
        //                       formik.touched.id && formik.errors.id
        //                     )}
        //                     helperText={formik.touched.id && formik.errors.id}
        //                     name="id"
        //                     label={
        //                       mobileSize
        //                         ? "ID Number"
        //                         : "Identification Document Number"
        //                     }
        //                     variant="filled"
        //                     fullWidth
        //                     onBlur={formik.handleBlur}
        //                     value={formik.values.id}
        //                     onKeyUp={handlePhoneInputChange}
        //                     onChange={(e) => {
        //                       formik.handleChange(e);
        //                     }}
        //                   />
        //                 </Grid>
        //                 <Grid item xs={12} sm={6}>
        //                   <DatePicker
        //                     disabled={isFieldsDisabled()}
        //                     disablePast
        //                     sx={{ width: "100%" }}
        //                     label={
        //                       mobileSize
        //                         ? "ID Expiration Date"
        //                         : "Identification Document Expiration Date"
        //                     }
        //                     value={formik.values.idExp}
        //                     onChange={(value) =>
        //                       formik.setFieldValue("idExp", value, true)
        //                     }
        //                     slotProps={{
        //                       textField: {
        //                         variant: "filled",
        //                         onBlur: formik.handleBlur("idExp"),
        //                         error: Boolean(
        //                           formik.touched.idExp && formik.errors.idExp
        //                         ),
        //                         helperText:
        //                           formik.touched.idExp && formik.errors.idExp,
        //                       },
        //                     }}
        //                   />
        //                 </Grid>
        //               </Grid>
        //             </Box>
        //             {/* INFO BOX ENDED */}
        //             <Schedule
        //               setInstantError={setInstantError}
        //               propertyDetails={propertyDetails}
        //               userTimezone={userTimezone}
        //               setDocumentModal={setDocumentModal}
        //               formik={formik}
        //               instantTimeLeft={formatTime(instantTimeLeft)}
        //               timeLeft={formatTime(scheduleTimeLeft)}
        //               handleStartClick={handleStartClick}
        //               handleImmediateRON={handleImmediateRON}
        //               checkDateLoading={checkDateLoading}
        //               slots={slots}
        //               slotIndex={slotIndex}
        //               setSlotIndex={setSlotIndex}
        //               convertObjectToDayjs={convertObjectToDayjs}
        //               isAvailable={isAvailable}
        //               setIsAvailable={setIsAvailable}
        //               availableDate={availableDate}
        //               setAvailableDate={setAvailableDate}
        //               startTimer={startTimer}
        //               startInstantTimer={startInstantTimer}
        //               unavailableText={unavailableText}
        //               checkInstantLoading={checkInstantLoading}
        //               setUnavailableText={setUnavailableText}
        //               isInstantAvailable={isInstantAvailable}
        //               setIsInstantAvailable={setIsInstantAvailable}
        //               instantData={instantData}
        //               instantError={instantError}
        //               setInstantTimeLeft={setInstantTimeLeft}
        //               setTimeLeft={setScheduleTimeLeft}
        //               setInstantData={setInstantData}
        //               setSlots={setSlots}
        //               immediateRequested={immediateRequested}
        //               immediateLock={immediateLock}
        //               setCanceledInstant={setCanceledInstant}
        //               instantAccepted={instantAccepted}
        //               mobileSize={mobileSize}
        //               tabletSize={tabletSize}
        //               largeTabletSize={largeTabletSize}
        //               setSubmitting={setSubmitting}
        //             />
        //             <Grid item xs={12}>
        //               <Typography
        //                 sx={{ minHeight: "1.38rem" }}
        //                 fontWeight="bold"
        //                 align="center"
        //                 variant="body2"
        //                 color="error"
        //               >
        //                 {notaryError}
        //               </Typography>
        //             </Grid>
        //           </Grid>
        //         </Box>
        //         <Typography
        //           sx={{ mt: 2, mb: 1 }}
        //           fontWeight="bold"
        //           align="center"
        //           variant={tabletSize ? "body2" : "h6"}
        //           color="error"
        //         >
        //           {submitError}
        //         </Typography>
        //       </Box>
        //       {/* Scrollable Area End */}

        //       {/* Fixed Submit Button */}
        //       <Grid
        //         item
        //         xs={12}
        //         sx={{
        //           flex: "0 0 auto",
        //         }}
        //       >
        //         {/* Ensures the button doesn't grow or shrink */}
        //         <LoadingButton
        //           onClick={(e) => {
        //             setSubmitting(true);
        //           }}
        //           type="submit"
        //           disabled={
        //             immediateRequested || isInstantAvailable || instantAccepted
        //           }
        //           loading={submitLoading}
        //           size="large"
        //           variant="contained"
        //           fullWidth
        //         >
        //           Submit
        //         </LoadingButton>
        //       </Grid>
        //     </form>
        //     {/* </div> */}
        //   </Box>

        //   {/* Other content */}
        //   <NotarizedModal
        //     notarizedModal={documentModal}
        //     setNotarizedModal={setDocumentModal}
        //     pdfImg={propertyImage[0]}
        //     pdfImg2={propertyImage[1]}
        //     previewImg=""
        //     preview={false}
        //     largeTabletSize={largeTabletSize}
        //     mobileSize={mobileSize}
        //     tabletSize={tabletSize}
        //     type={"registerSignee"}
        //   />
        // </Box>
      )}
    </>
  );
};

export default RegisterSignee;
