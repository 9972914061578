import React, { useState, useRef } from "react";
import { Box, Button } from "@mui/material";
import WizardImage from "./WizardImage";

const Magnifier = ({
  src,
  lensSize = 200,
  zoomFactor = 4,
  handleImageError,
  selectedPackage,
  second,
  customStyle,
}) => {
  const [isZooming, setIsZooming] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const imageRef = useRef(null);

  const handleMouseMove = (e) => {
    if (!isZooming || !imageRef.current) return;

    const { left, top } = imageRef.current.getBoundingClientRect();
    const x = e.pageX - left - window.scrollX;
    const y = e.pageY - top - window.scrollY;

    setMousePosition({ x, y });
  };

  const handleMouseDown = (e) => {
    if (e.target.tagName === "BUTTON") return; // Prevent zooming when clicking buttons
    setIsZooming(true);
    if (imageRef.current) {
      const { left, top } = imageRef.current.getBoundingClientRect();
      const x = e.pageX - left - window.scrollX;
      const y = e.pageY - top - window.scrollY;
      setMousePosition({ x, y });
    }
  };

  const handleMouseUp = () => {
    setIsZooming(false);
  };

  return (
    <Box
      sx={{
        position: "relative",
        "&:hover": {
          cursor: "crosshair",
        },
      }}
      onMouseMove={handleMouseMove}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
    >
      {/* CHANGE FORM BUTTONS */}
      {!second &&
        selectedPackage?.building === true &&
        selectedPackage?.noc === true && (
          <Box sx={{ position: "absolute", top: 10, left: 30, zIndex: 9999 }}>
            <Box sx={{ display: "flex", gap: 2 }}>
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault(); // Prevent default behavior
                  // Add other button click functionality here
                }}
                variant="contained"
                size="small"
                sx={{
                  padding: "10px 20px",
                  backgroundColor: "#f2eadf",
                  border: "2px solid",
                  borderColor: "#715e60",
                  color: "#715e60",
                  "&:hover": {
                    backgroundColor: "#715e60",
                    color: "#f2eadf",
                  },
                }}
              >
                Notice of Commencement
              </Button>
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault(); // Prevent default behavior
                  // Add other button click functionality here
                }}
                variant="outlined"
                size="small"
                sx={{
                  zIndex: 9999,
                  padding: "10px 20px",
                  backgroundColor: "#f2eadf",
                  border: "2px solid",
                  borderColor: "#715e60",
                  color: "#715e60",
                  "&:hover": {
                    backgroundColor: "#715e60",
                    color: "#f2eadf",
                  },
                }}
              >
                Building Permit
              </Button>
            </Box>
          </Box>
        )}

      {/* ********************* */}
      <img
        ref={imageRef}
        src={src}
        style={customStyle ? customStyle : { width: "100%", height: "auto" }}
        draggable="false"
        onError={handleImageError}
      />
      {/* <WizardImage /> */}
      {isZooming && (
        <Box
          sx={{
            position: "absolute",
            left: `${mousePosition.x - lensSize / 2}px`,
            top: `${mousePosition.y - lensSize / 2}px`,
            width: `${lensSize}px`,
            height: `${lensSize}px`,
            borderRadius: "50%",
            overflow: "hidden",
            border: "2px solid white",
            boxShadow: "0 0 15px 8px rgba(0, 0, 0, 0.5)",
            background: `url(${src}) no-repeat`,
            backgroundSize: `${imageRef.current?.width * zoomFactor}px ${
              imageRef.current?.height * zoomFactor
            }px`,
            backgroundPosition: `${
              -mousePosition.x * zoomFactor + lensSize / 2
            }px ${-mousePosition.y * zoomFactor + lensSize / 2}px`,
            pointerEvents: "none",
          }}
        />
      )}
    </Box>
  );
};

export default Magnifier;
