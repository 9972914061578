import {
  TextField,
  Typography,
  Grid,
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  Box,
  Checkbox,
} from "@mui/material";
import { useEffect, useRef } from "react";
import { cityScraperList } from "../../data/cityScraperList";

const TextFields = ({
  formCreated,
  nocStates,
  setNocStates,
  formik,
  county,
  city,
  prefill,
  type,
  inputs,
  propertyOwner,
  mobileLoading,
  isCurrentCountyNotFound,
  mobile,
  creatingDoc,
  selectedRow,
  initials,
  fieldRefs,
}) => {
  useEffect(() => {
    const getInitials = () => {
      const initialData = initials.find((initial) => initial.county === county);
      if (initialData) {
        const initialValues = initialData.values[0];
        const filteredInitialValues = Object.keys(initialValues).reduce(
          (acc, key) => {
            if (initialValues[key] !== "" && initialValues[key] !== undefined) {
              acc[key] = initialValues[key];
            }
            return acc;
          },
          {}
        );

        if (prefill) {
          const prefillValues = Object.keys(initialValues).reduce(
            (acc, key) => {
              if (inputs.hasOwnProperty(key) && inputs[key] !== undefined) {
                acc[key] = inputs[key];
              }
              return acc;
            },
            {}
          );

          // Update Formik values with prefill values
          formik.setValues({
            ...formik.values,
            ...prefillValues,
            address: propertyOwner?.address || "",
            owner: propertyOwner?.name || "",
            permitNumber: selectedRow?.permit_number || "",
          });
        } else {
          // Set values without prefill
          formik.setValues({
            ...formik.values,
            ...filteredInitialValues,
          });
        }
      }
    };
    if (initials) {
      getInitials();
    }
  }, [county, type, initials]);

  const formatMoneyValue = (value) => {
    if (value === "") {
      return "";
    }
    // Remove any non-numeric characters except the decimal point
    value = value.replace(/[^\d.]/g, "");

    // Split the input into integer and decimal parts
    let [integer, decimal] = value.split(".");

    // Limit the integer part to 10 digits
    integer = integer ? integer.slice(0, 10) : "";

    // Format the integer part with commas
    let formattedInteger = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Handle cases for decimal parts
    if (decimal === undefined) {
      // If there's no decimal part, ensure it ends with ".00"
      formattedInteger += ".00";
    } else if (decimal.length === 0) {
      // If the decimal part is empty, assume ".00"
      formattedInteger += ".00";
    } else if (decimal.length === 1) {
      // If there's only one decimal digit, add a trailing "0"
      formattedInteger += `.${decimal}0`;
    } else {
      // Otherwise, limit the decimal part to two digits
      formattedInteger += `.${decimal.slice(0, 2)}`;
    }

    // Add the dollar sign
    return `${formattedInteger}`;
  };

  const handleBlur = (event, formik, name) => {
    if (name === "amountOfBond") {
      let { value } = event.target;
      const formattedValue = formatMoneyValue(value);
      formik.setFieldValue(event.target.name, formattedValue);
    } else {
      formik.handleBlur(event);
    }
  };

  const handlePermitChange = (name, value) => {
    // Remove non-alphanumeric characters and convert to uppercase
    if (value.length >= 15) {
      return;
    }
    const alphanumericOnly = value.replace(/[^a-zA-Z0-9]/g, "").toUpperCase();

    let formattedPermitNumber = "";

    // Add a hyphen after the 3rd character and a period after the 9th character
    if (alphanumericOnly.length <= 3) {
      formattedPermitNumber = alphanumericOnly; // First 3 characters
    } else if (alphanumericOnly.length <= 9) {
      formattedPermitNumber =
        alphanumericOnly.substring(0, 3) + "-" + alphanumericOnly.substring(3); // e.g., B24-123456
    } else {
      formattedPermitNumber =
        alphanumericOnly.substring(0, 3) +
        "-" +
        alphanumericOnly.substring(3, 9) +
        "." +
        alphanumericOnly.substring(9); // e.g., B24-123456.000
    }

    formik.setFieldValue(name, formattedPermitNumber); // Update formik field value
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Update the formik values using setFieldValue

    if (name === "permitNumber" && county === "Duval") {
      handlePermitChange(name, value);
    } else {
      formik.setFieldValue(name, value);
    }
    // No need to update dynamicValues here
  };

  return (
    <>
      {type === "create-noc" && initials && (
        <>
          {!formCreated && (
            <>
              <Typography
                variant="body1"
                fontWeight="bold"
                sx={{ mb: 0, pb: 0 }}
              >
                Address of Property Being Improved {!mobile && "(Mandatory)"}
              </Typography>
              <Typography
                fontWeight="bold"
                color="primary"
                variant="body2"
                sx={{
                  m: 0,
                  p: 0,
                  pb: mobile ? 1 : undefined,
                  lineHeight: "10px",
                  marginTop: "0px !important",
                  marginBottom: "0px !important",
                }}
              >
                (Can be partial or full)
              </Typography>
            </>
          )}

          {!formCreated ? (
            <>
              <TextField
                ref={fieldRefs.current["streetNumber"]}
                disabled={formCreated || nocStates.createNocLoading}
                error={
                  Boolean(formik.touched.streetNumber) &&
                  Boolean(formik.errors.streetNumber)
                }
                fullWidth
                helperText={
                  Boolean(formik.touched.streetNumber) &&
                  formik.errors.streetNumber
                }
                label="Street Number*"
                name="streetNumber"
                onBlur={formik.handleBlur}
                onChange={handleInputChange}
                value={formik.values.streetNumber || ""}
              />
              <TextField
                ref={fieldRefs.current["streetName"]}
                disabled={formCreated || nocStates.createNocLoading}
                error={
                  Boolean(formik.touched.streetName) &&
                  Boolean(formik.errors.streetName)
                }
                fullWidth
                helperText={
                  Boolean(formik.touched.streetName) && formik.errors.streetName
                }
                label="Street Name*"
                name="streetName"
                onBlur={formik.handleBlur}
                onChange={handleInputChange}
                value={formik.values.streetName || ""}
              />
              {county === "Citrus" ? (
                <Grid container gap={1}>
                  <Grid sm={12} lg={3.5} item sx={{ ml: "auto" }}>
                    <TextField
                      disabled={formCreated || nocStates.createNocLoading}
                      fullWidth
                      label="UNIT"
                      name="unitNumber"
                      onBlur={formik.handleBlur}
                      onChange={handleInputChange}
                      value={formik.values.unitNumber || ""}
                    />
                  </Grid>
                  <Grid sm={12} lg={3.5} item>
                    <TextField
                      disabled={formCreated || nocStates.createNocLoading}
                      fullWidth
                      label="APT"
                      name="apt"
                      onBlur={formik.handleBlur}
                      onChange={handleInputChange}
                      value={formik.values.apt || ""}
                    />
                  </Grid>
                  <Grid sm={12} lg={3.5} item sx={{ mr: "auto" }}>
                    <TextField
                      disabled={formCreated || nocStates.createNocLoading}
                      fullWidth
                      label="LOT"
                      name="lot"
                      onBlur={formik.handleBlur}
                      onChange={handleInputChange}
                      value={formik.values.lot || ""}
                    />
                  </Grid>
                </Grid>
              ) : (
                <TextField
                  disabled={formCreated || nocStates.createNocLoading}
                  fullWidth
                  label="Unit Number"
                  name="unitNumber"
                  onBlur={formik.handleBlur}
                  onChange={handleInputChange}
                  value={formik.values.unitNumber || ""}
                />
              )}

              <TextField
                disabled={formCreated || nocStates.createNocLoading}
                error={
                  Boolean(formik.touched.zip) && Boolean(formik.errors.zip)
                }
                fullWidth
                helperText={Boolean(formik.touched.zip) && formik.errors.zip}
                label="ZIP Code"
                name="zip"
                onBlur={formik.handleBlur}
                onChange={handleInputChange}
                type="zip"
                value={formik.values.zip || ""}
              />
            </>
          ) : (
            <>
              <TextField
                disabled
                fullWidth
                label="Address"
                name="address"
                onChange={formik.handleChange}
                value={formik.values.address} // This will join non-empty values with a space and trim the result
              />
            </>
          )}
          <Typography
            variant={formCreated ? "h6" : "body1"}
            fontWeight="bold"
            sx={{ mb: 0, pb: 0 }}
          >
            {formCreated
              ? "The following fields are available for updating:"
              : "Optional Fields"}
          </Typography>
          {formCreated && (
            <>
              <Typography
                fontWeight="bold"
                color="primary"
                variant="body2"
                sx={{
                  m: 0,
                  p: 0,
                  lineHeight: "10px",
                  marginTop: "0px !important",
                  marginBottom: "0px !important",
                }}
              >
                (Click update after adding new information into NOC)
              </Typography>
            </>
          )}
        </>
      )}
      {type === "moreResults" && initials && (
        <>
          {!formCreated && (
            <>
              <Typography
                variant="body1"
                fontWeight="bold"
                sx={{ mb: 0, pb: 0 }}
              >
                Address of Property Being Improved (Mandatory)
              </Typography>
              <Typography
                fontWeight="bold"
                color="primary"
                variant="body2"
                sx={{
                  m: 0,
                  p: 0,
                  lineHeight: "10px",
                  marginTop: "0px !important",
                  marginBottom: "0px !important",
                }}
              >
                (Can be partial or full)
              </Typography>
            </>
          )}
          {!formCreated ? (
            <>
              <TextField
                ref={fieldRefs.current["streetNumber"]}
                disabled={formCreated || nocStates.createNocLoading}
                error={
                  Boolean(formik.touched.streetNumber) &&
                  Boolean(formik.errors.streetNumber)
                }
                fullWidth
                helperText={
                  Boolean(formik.touched.streetNumber) &&
                  formik.errors.streetNumber
                }
                label="Street Number*"
                name="streetNumber"
                onBlur={formik.handleBlur}
                onChange={handleInputChange}
                value={formik.values.streetNumber || ""}
              />
              <TextField
                ref={fieldRefs.current["streetName"]}
                disabled={formCreated || nocStates.createNocLoading}
                error={
                  Boolean(formik.touched.streetName) &&
                  Boolean(formik.errors.streetName)
                }
                fullWidth
                helperText={
                  Boolean(formik.touched.streetName) && formik.errors.streetName
                }
                label="Street Name*"
                name="streetName"
                onBlur={formik.handleBlur}
                onChange={handleInputChange}
                value={formik.values.streetName || ""}
              />
              <TextField
                disabled={formCreated || nocStates.createNocLoading}
                fullWidth
                label="Unit Number"
                name="unitNumber"
                onBlur={formik.handleBlur}
                onChange={handleInputChange}
                value={formik.values.unitNumber || ""}
              />
              <TextField
                disabled={formCreated || nocStates.createNocLoading}
                error={
                  Boolean(formik.touched.zip) && Boolean(formik.errors.zip)
                }
                fullWidth
                helperText={Boolean(formik.touched.zip) && formik.errors.zip}
                label="ZIP Code"
                name="zip"
                onBlur={formik.handleBlur}
                onChange={handleInputChange}
                type="zip"
                value={formik.values.zip || ""}
              />
            </>
          ) : (
            <>
              <TextField
                disabled
                fullWidth
                label="Address"
                value={formik?.values?.address}
                name="address"
                onChange={formik.handleChange}
              />
            </>
          )}

          <Typography
            variant={formCreated ? "h6" : "body1"}
            fontWeight="bold"
            sx={{ mb: 0, pb: 0 }}
          >
            {formCreated
              ? "The following fields are available for updating:"
              : "Optional Fields"}
          </Typography>

          {formCreated && (
            <Typography
              fontWeight="bold"
              color="primary"
              variant="body2"
              sx={{
                m: 0,
                p: 0,
                lineHeight: "10px",
                marginTop: "0px !important",
                marginBottom: "0px !important",
              }}
            >
              (Click update after adding new information into NOC)
            </Typography>
          )}
        </>
      )}
      {(formCreated || type === "edit") && initials ? (
        <TextField
          disabled={isCurrentCountyNotFound || nocStates.createNocLoading}
          fullWidth
          label="Owner's Name"
          name="owner"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik?.values?.owner}
        />
      ) : (
        <></>
      )}
      {initials &&
        initials?.map((initial) => {
          if (initial.county === county) {
            return initial.fields.map((field, i) => {
              const isPermitNumber = field.name === "permitNumber";
              return (
                <Box sx={{ display: "flex", alignItems: "center" }} key={i}>
                  {field.name === "surety" && county === "Collier" && (
                    <FormControl
                      component="fieldset"
                      disabled={mobileLoading || nocStates.createNocLoading}
                      sx={{ flex: 1, mr: 2 }}
                    >
                      <FormLabel component="legend">Surety?</FormLabel>
                      <RadioGroup
                        row
                        aria-label="option"
                        name="row-radio-buttons-group"
                        value={nocStates.isSuretyRadio}
                        onChange={(e) => {
                          setNocStates({
                            ...nocStates,
                            isSuretyRadio: e.target.value,
                          });

                          const fieldsToReset = [
                            "surety",
                            "suretyAddress",
                            "suretyPhone",
                            "amountOfBond",
                          ]; // Add more field names as needed
                          fieldsToReset.forEach((field) =>
                            formik.setFieldValue(field, "")
                          );
                        }}
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                  <Box sx={{ flex: 1, mr: 2 }}>
                    <TextField
                      ref={fieldRefs.current[field.name]}
                      disabled={
                        formik?.values?.permitCheck === true &&
                        field.name === "permitNumber"
                          ? true
                          : nocStates.createNocLoading
                          ? true
                          : county === "Collier"
                          ? nocStates.isSuretyRadio === "no" &&
                            [
                              "surety",
                              "suretyAddress",
                              "suretyPhone",
                              "amountOfBond",
                            ].includes(field.name)
                          : mobileLoading
                          ? true
                          : isCurrentCountyNotFound
                          ? true
                          : false
                      }
                      fullWidth
                      label={field.label}
                      name={field.name}
                      onBlur={(e) => handleBlur(e, formik, field.name)}
                      onChange={handleInputChange}
                      value={formik.values[field.name] || ""}
                      error={
                        isPermitNumber &&
                        Boolean(
                          formik.touched[field.name] &&
                            formik.errors[field.name]
                        )
                      }
                      helperText={
                        formik.touched[field.name] && formik.errors[field.name]
                      }
                    />
                  </Box>
                  {isPermitNumber && !formCreated && (
                    <Box sx={{ flex: 1 }}>
                      {cityScraperList.includes(city) ? (
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="permitCheck"
                              checked={formik.values.permitCheck}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          }
                          label={
                            <Typography
                              fontWeight="bold"
                              variant="body2"
                              color="primary"
                            >
                              Generate Building Permit
                            </Typography>
                          }
                        />
                      ) : (
                        <Typography
                          fontWeight="bold"
                          variant="body2"
                          color="primary"
                        >
                          Permit generation is not available for this city
                        </Typography>
                      )}
                    </Box>
                  )}
                </Box>
              );
            });
          }
          return null;
        })}
      {formik?.errors?.submit && (
        <Typography color="error" sx={{ mt: 3 }} variant="body2">
          {formik?.errors?.submit}
        </Typography>
      )}
    </>
  );
};

export default TextFields;
