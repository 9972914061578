export const connectWebsocket = (sessionType, token) => {
  const baseUrl = process.env.REACT_APP_WS_URL;
  let url = "";
  switch (sessionType) {
    case "guestSession":
      url = `${baseUrl}/nocApi_ws/guest_ws?token=${token}`;
      break;
    case "notarySession":
      url = `${baseUrl}/nocApi_ws/notary_ws?token=${token}`;
      break;
    case "userSession":
      url = `${baseUrl}/nocApi_ws/user_ws?token=${token}`;
      break;
    case "subUserSession":
      url = `${baseUrl}/nocApi_ws/subUser_ws?token=${token}`;
      break;
    default:
      console.error("Invalid session type for WebSocket connection.");
      return; // Do not dispatch an action if the session type is unknown
  }

  return {
    type: `${sessionType.toUpperCase()}_WEBSOCKET_CONNECT`,
    payload: { url, token, sessionType },
  };
};

export const disconnectWebsocket = (sessionType) => {
  return {
    type: `${sessionType.toUpperCase()}_WEBSOCKET_DISCONNECT`,
    payload: { sessionType },
  };
};

export const sendWebsocketMessage = (sessionType, message) => {
  return {
    type: `${sessionType.toUpperCase()}_WEBSOCKET_SEND_MESSAGE`,
    payload: { message, sessionType },
  };
};
