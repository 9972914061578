import { Alert } from "@mui/material";

const FormSelectedPersonInfo = ({ personInfo, isFadingOut, right, top }) => {
  return (
    <Alert
      sx={{
        color: "#fff",
        fontWeight: "bold",
        zIndex: 333,
        position: "absolute",
        right: right,
        top: top,
        opacity: isFadingOut ? 0 : 1,
        transition: "opacity 1000ms ease-in-out",
      }}
      variant="filled"
      severity="info"
    >
      {personInfo}
    </Alert>
  );
};

export default FormSelectedPersonInfo;
