import React from "react";
import {
  Button,
  Typography,
  Grid,
  useMediaQuery,
  useTheme,
  Checkbox,
  FormControlLabel,
  Box,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Link } from "react-router-dom";

const FormNavigation = (props) => {
  const handleLoading = () => {
    if (props.isLastStep) {
      return (
        <LoadingButton
          disabled={!props?.selectedPlan}
          sx={{ width: "100%" }}
          fullWidth={isSmallScreen}
          type="submit"
          color="primary"
          variant="contained"
          loading={props.isLoad}
          size="Large"
        >
          {handleLastStep()}
        </LoadingButton>
      );
    } else {
      return (
        <LoadingButton
          loading={props.nextLoad}
          type="submit"
          color="primary"
          sx={{ width: "100%" }}
          fullWidth={isSmallScreen}
          variant="contained"
          size="Large"
        >
          {handleLastStep()}
        </LoadingButton>
      );
    }
  };

  const handleLastStep = () => {
    if (props.isLastStep) {
      if (props.isExpired) {
        return "PAY";
      } else {
        // return "SIGN UP AND PAY NOTHING TODAY";
        return "PROCEED";
      }
    } else {
      return "Next";
    }
  };
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      {props?.isLastStep ? (
        <>
          <Grid container alignItems="center">
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: isSmallScreen ? "center" : "right",
                  alignItems: "center",
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={props?.terms}
                      onChange={(e) => {
                        props?.setTerms(e.target.checked);
                        props?.setTermsOnError(false);
                      }}
                      sx={{
                        color: props?.termsOnError ? "red" : "default",
                        "&.Mui-checked": {
                          color: props?.termsOnError ? "red" : "primary.main", // Change check color when there's an error
                        },
                        "&:hover": {
                          bgcolor: "transparent", // Optional: Change background color on hover
                        },
                      }}
                    />
                  }
                  label={
                    <Typography
                      sx={{ textAlign: "center", lineHeight: "3px" }}
                      color={props?.termsOnError ? "red" : "text.secondary"}
                      variant="body2"
                    >
                      I agree the{" "}
                      <Link
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          props?.setTermsOpen(true);
                        }}
                        style={{
                          color: props?.termsOnError ? "red" : "inherit",
                          fontWeight: "bold",
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                      >
                        {" "}
                        Terms & Conditions
                      </Link>
                    </Typography>
                  }
                  size="sm"
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                variant="body2"
                fontWeight="bold"
                textAlign="center"
                color="grey"
                mb={2}
                mt={2}
                sx={{ minHeight: "1.5rem" }}
              >
                You can cancel your plan at any time
              </Typography>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Typography
            variant="body1"
            fontWeight="bold"
            textAlign="center"
            color="#F04438"
            mb={2}
            sx={{ minHeight: "1.5rem" }}
          >
            {props.errorText}
          </Typography>
        </>
      )}

      <Grid
        gap={isSmallScreen ? 0 : 1}
        alignItems="center"
        container
        sx={{
          mb: isSmallScreen ? theme.spacing(2) : 0,
          justifyContent: isSmallScreen ? "center" : "space-between",
        }}
      >
        <Grid item xs={12} md={5} order={isSmallScreen ? 3 : 1}>
          {props.hasPrevious ? (
            <Button
              sx={
                isSmallScreen
                  ? { mt: "0.5rem", width: "100%" }
                  : { width: "100%" }
              }
              variant="contained"
              type="button"
              onClick={() => {
                props.onBackClick();
              }}
              fullWidth={isSmallScreen}
              size="Large"
              disabled={props?.isLoad}
            >
              Back
            </Button>
          ) : (
            <Button
              sx={
                isSmallScreen
                  ? { mt: "0.5rem", width: "100%" }
                  : { width: "100%" }
              }
              variant="contained"
              disabled
              type="button"
              fullWidth={isSmallScreen}
              size="Large"
            >
              Back
            </Button>
          )}
        </Grid>

        <Grid order={isSmallScreen ? 1 : 3} item xs={12} md={5}>
          {props.cardRemain !== 0 &&
          props.stepNumber === 2 &&
          props.isSSOLogin === true ? (
            <Button
              sx={{ width: "100%" }}
              type="submit"
              color="primary"
              variant="contained"
              fullWidth={isSmallScreen}
              size="large"
            >
              {handleLastStep()}
            </Button>
          ) : (
            handleLoading()
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default FormNavigation;
