import React, { useEffect, useState } from "react";
import { DateTime } from "luxon";
import {
  IconButton,
  Toolbar,
  Typography,
  Chip,
  Box,
  Stack,
  TextField,
  InputAdornment,
  CircularProgress,
  FormHelperText,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import SwipeableViews from "react-swipeable-views";
import MobileFooter from "../layout/MobileFooter";
import StarIcon from "@mui/icons-material/Star";
import PeopleIcon from "@mui/icons-material/People";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import EventIcon from "@mui/icons-material/Event";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import FormSelectedPersonInfo from "../modals/FormSelectedPersonInfo";
import RemoveIcon from "@mui/icons-material/Remove";
import SaveIcon from "@mui/icons-material/Save";
import ExpandableDefaults from "../modals/ExpandableDefaults";
import MaximisePDF from "../notary/modals/MaximisePDF";

const MobileSelectCountyForm = (props) => {
  const getImages = (form) => {
    const baseImg = form;
    const images = [baseImg];

    const imgNumberMatch = baseImg.match(/(\d+)(?=\.\w+$)/);
    if (imgNumberMatch) {
      const imgNumber = parseInt(imgNumberMatch[0], 10);
      const nextImg = baseImg.replace(imgNumberMatch[0], imgNumber + 1);
      images.push(nextImg);
    }

    return images;
  };

  const [availableOpen, setAvailableOpen] = useState(false);
  const [savedOpen, setSavedOpen] = useState(false);

  const getCountyData = (county, noc_data, currentTimezone) => {
    let avgCreationTime = "";
    let isAppraiserSuccess = "";
    let checkedDate = "";

    const currentCounty = noc_data?.appraiser_results?.[county.name];
    const matchingEntry = noc_data?.avg_nocs?.find(
      (entry) => entry?._id === county?.name
    );

    avgCreationTime = matchingEntry
      ? Math.floor(matchingEntry?.avg_second_took)
      : "N/A";

    // APPraiser
    isAppraiserSuccess = currentCounty?.is_success ? true : false;
    const checkedDateTimestamp = currentCounty?.date * 1000;
    const checkedDateMoment = DateTime.fromMillis(checkedDateTimestamp, {
      zone: currentTimezone,
    });

    checkedDate = checkedDateMoment.toFormat("MMM d, h:mm a"); // Other days, show date and time

    return { avgCreationTime, isAppraiserSuccess, checkedDate };
  };

  return (
    // props?.waitingCountiesUnlock
    <>
      <MobileFooter
        pos="top"
        py="6px"
        pl="0.5rem"
        success={
          props?.noc_data?.appraiser_results[props?.rowData?.name]?.is_success
        }
        lockedForms={props?.currentUser?.user?.locked_forms}
        waitingCountiesUnlock={props?.waitingCountiesUnlock}
        waitingText={props?.waitingText}
        waitingColor={props?.waitingColor}
      />

      <Box
        sx={{
          backgroundColor: "#f3f3f3",
          boxShadow: "1px 2px 2px 3px rgba(0, 0, 0, 0.1)",
          p: 1,
          mb: 1.5,
          pb: 1,
          //   backgroundColor: "#fff",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Typography sx={{ flex: 1.4, fontSize: "22px", fontWeight: "bold" }}>
          {props?.visitEnabled ? "Select Counties" : "Saved Counties"}
        </Typography>

        <Typography variant="h6" pt={"3px"}>
          {props?.visitEnabled
            ? `${props?.availableIndex + 1}/${props?.availableCounties?.length}`
            : `${props?.savedIndex + 1}/${props?.savedCounties?.length}`}
        </Typography>
        <TextField
          id="searchInput"
          onChange={(e) => {
            props?.handleSelectCountySearch(e);
          }}
          size="small"
          placeholder="SEARCH"
          sx={{ p: 0, ml: 1, flex: 1 }}
          InputProps={{
            sx: { padding: "0px 5px" },
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          variant="outlined"
        />
        {props?.visitEnabled && (
          <Box
            sx={{
              position: "absolute",
              top: 34,
              left: 0,
              overflow: "visible",
              zIndex: 233332,
            }}
          >
            <ExpandableDefaults
              open={props?.defaultOpen}
              setOpen={props?.setDefaultOpen}
              defaultValues={props?.defaultValues}
              setDefaultValues={props?.setDefaultValues}
            />
          </Box>
        )}
      </Box>

      {/* <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      ></Box> */}
      {(props?.visitEnabled && props?.availableCounties?.length !== 0) ||
      (props?.favoritEnabled && props?.savedCounties?.length !== 0) ? (
        props?.visitEnabled ? (
          <SwipeableViews
            axis={"x"}
            index={props?.availableIndex}
            onChangeIndex={props?.handleAvailableSwipe}
            enableMouseEvents
            style={{ height: "calc(100% - 110px)", overflow: "hidden" }}
          >
            {props?.availableCounties?.map((county, index) => {
              let avgCreationTime = "";
              let isAppraiserSuccess = "";
              let checkedDate = "";

              if (props?.noc_data) {
                const countyData = getCountyData(
                  county,
                  props?.noc_data,
                  props?.currentTimezone
                );
                avgCreationTime = countyData.avgCreationTime;
                isAppraiserSuccess = countyData.isAppraiserSuccess;
                checkedDate = countyData.checkedDate;
              }
              return (
                <Box
                  key={index}
                  style={{
                    padding: 15,
                    paddingTop: 0,
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "#fff",
                    overflow: "hidden",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      position: "relative",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h5">
                      {county?.name}{" "}
                      {avgCreationTime !== "N/A" && (
                        <Chip
                          size="small"
                          sx={{ fontWeight: "bold" }}
                          label={
                            <span style={{ fontSize: "14px" }}>
                              {avgCreationTime} sec in avg
                            </span>
                          }
                          color="success"
                        />
                      )}
                    </Typography>
                  </Box>
                  <Stack
                    sx={{ position: "relative" }}
                    direction="row"
                    alignItems="center"
                    spacing={1}
                  >
                    <EventIcon sx={{ color: "#333333", fontSize: "20px" }} />
                    <Typography variant="body2">
                      <span style={{ fontWeight: "bold" }}>Last NOC:</span>{" "}
                      {checkedDate}
                    </Typography>
                    {/* ENLARGE PDF ******************************************************/}
                    <Box sx={{ position: "absolute", right: -10, top: -12 }}>
                      <IconButton
                        onClick={() => {
                          setAvailableOpen(true);
                        }}
                      >
                        <SearchIcon sx={{ fontSize: "30px", zIndex: 99999 }} />
                      </IconButton>
                    </Box>
                    {/* *************************************************************** */}
                  </Stack>
                  {county?.form && (
                    <img
                      src={county?.form}
                      style={{ width: "100%", height: "auto" }}
                      alt={`Form Page ${index + 1}`}
                    />
                  )}
                </Box>
              );
            })}
          </SwipeableViews>
        ) : (
          <SwipeableViews
            axis={"x"}
            index={props?.savedIndex}
            onChangeIndex={props?.handleSavedSwipe}
            enableMouseEvents
            style={{ height: "calc(100% - 110px)", overflow: "hidden" }}
          >
            {props?.savedCounties?.map((county, index) => {
              let avgCreationTime = "";
              let isAppraiserSuccess = "";
              let checkedDate = "";

              if (props?.noc_data) {
                const countyData = getCountyData(
                  county,
                  props?.noc_data,
                  props?.currentTimezone
                );
                avgCreationTime = countyData.avgCreationTime;
                isAppraiserSuccess = countyData.isAppraiserSuccess;
                checkedDate = countyData.checkedDate;
              }
              return (
                <Box
                  key={index}
                  style={{
                    padding: 15,
                    paddingTop: 0,
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "#fff",
                    overflow: "hidden",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      position: "relative",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h5" sx={{ flex: 2 }}>
                      {county?.name}{" "}
                      {avgCreationTime !== "N/A" && (
                        <Chip
                          size="small"
                          sx={{ fontWeight: "bold" }}
                          label={
                            <span style={{ fontSize: "14px" }}>
                              {avgCreationTime} sec in avg
                            </span>
                          }
                          color="success"
                        />
                      )}
                    </Typography>
                    {county?.locked && (
                      <IconButton
                        onClick={() => {
                          props?.setPersonInfo(
                            "Modifications for this county are restricted because it has already been used."
                          );
                          props?.setShowPersonInfo(true);
                        }}
                      >
                        <LockIcon color="primary" />
                      </IconButton>
                    )}
                    <IconButton
                      onClick={() => {
                        props?.handlePersonInfoClick(county);
                      }}
                    >
                      <PeopleIcon color="primary" />
                    </IconButton>

                    {props?.showPersonInfo && (
                      <FormSelectedPersonInfo
                        personInfo={props?.personInfo}
                        isFadingOut={props?.isFadingOut}
                        right={5}
                        top={40}
                      />
                    )}
                  </Box>
                  <Stack
                    sx={{ position: "relative" }}
                    direction="row"
                    alignItems="center"
                    spacing={1}
                  >
                    <EventIcon sx={{ color: "#333333", fontSize: "20px" }} />
                    <Typography variant="body2">
                      <span style={{ fontWeight: "bold" }}>Last NOC:</span>{" "}
                      {checkedDate}
                    </Typography>
                    {/* ENLARGE PDF ******************************************************/}
                    <Box sx={{ position: "absolute", right: -10, top: -12 }}>
                      <IconButton
                        onClick={() => {
                          setSavedOpen(true);
                        }}
                      >
                        <SearchIcon sx={{ fontSize: "30px", zIndex: 99999 }} />
                      </IconButton>
                    </Box>
                    {/* *************************************************************** */}
                  </Stack>
                  <img
                    src={county?.form}
                    style={{ width: "100%", height: "auto" }}
                    alt={`Form Page ${index + 1}`}
                  />
                </Box>
              );
            })}
          </SwipeableViews>
        )
      ) : null}

      {availableOpen && (
        <MaximisePDF
          open={availableOpen}
          setOpen={setAvailableOpen}
          imgs={getImages(
            props?.availableCounties[props?.availableIndex]?.form
          )}
        />
      )}

      {savedOpen && (
        <MaximisePDF
          open={savedOpen}
          setOpen={setSavedOpen}
          imgs={getImages(props?.savedCounties[props?.savedIndex]?.form)}
        />
      )}
      <Toolbar
        sx={{
          px: 0,
          py: 0.5,
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          opacity: 1,
          backgroundColor: "#333333",
        }}
      >
        <IconButton
          disabled={props?.load}
          variant="contained"
          onClick={props?.handleVisitClick}
          sx={{ flex: 1 }}
        >
          <Box>
            <PictureAsPdfIcon width={25} sx={{ color: "#fff" }} />
            <FormHelperText
              sx={{ fontWeight: "bold", color: "#fff", mt: -1, px: "5px" }}
            >
              Counties
            </FormHelperText>
            <Box
              sx={{
                borderBottom: props?.visitEnabled
                  ? "3px solid #757575"
                  : "3px solid transparent",
                mt: "-4px",
              }}
            ></Box>
          </Box>
        </IconButton>

        <IconButton
          disabled={
            props?.disableAdd ||
            props?.disableRemove ||
            (props?.favoritEnabled && props?.savedData?.locked) ||
            (props?.favoritEnabled &&
              props?.currentUser?.subuser &&
              props?.savedData?.added_email &&
              props?.savedData?.added_email !==
                props?.currentUser?.subuser?.email)
          }
          sx={{
            flex: 1,
            backgroundColor: "#909090",
            "&:hover": {
              backgroundColor: "#909090", // Maintain the color even on hover
            },
            "&.Mui-focusVisible": {
              backgroundColor: "#909090", // Maintain the color when focused (clicked)
            },
            "&:active": {
              backgroundColor: "#909090", // Maintain the color when active
            },
          }}
          onClick={() => {
            if (props?.load === false) {
              props?.setLoad(true);
              props?.handleSaveCounties();
            }
          }}
          variant="contained"
        >
          {props?.load === true ? (
            <CircularProgress sx={{ color: "#fff" }} size="2rem" />
          ) : props?.favoritEnabled ? (
            <RemoveIcon
              sx={{
                fontSize: "2.5rem",
                opacity:
                  props?.favoritEnabled && props?.savedData?.locked === true
                    ? 0.3
                    : 0.8,
                color: "#fff",
              }}
            />
          ) : (
            <AddIcon
              sx={{
                fontSize: "2.5rem",
                opacity:
                  props?.favoritEnabled && props?.savedData?.locked === true
                    ? 0.3
                    : 0.8,
                color: "#fff",
              }}
            />
          )}
        </IconButton>
        <IconButton
          disabled={props?.load}
          sx={{ padding: 0, flex: 1 }}
          variant="contained"
          onClick={props?.handleFavoritClick}
        >
          <Box>
            <SaveIcon sx={{ color: "#fff" }} />
            <FormHelperText
              sx={{ fontWeight: "bold", color: "#fff", mt: -1, px: "5px" }}
            >
              Saved Counties
            </FormHelperText>
            <Box
              sx={{
                borderBottom: props?.favoritEnabled
                  ? "3px solid #757575"
                  : "3px solid transparent",
                mt: "-4px",
              }}
            ></Box>
          </Box>
        </IconButton>
      </Toolbar>
    </>
  );
};

export default MobileSelectCountyForm;
