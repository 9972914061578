import React, { useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Modal,
  Box,
  Typography,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  Stack,
  Link,
  Card,
  Divider,
  CardActions,
  CardContent,
  IconButton,
  FormHelperText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import YupPassword from "yup-password";
import dayjs from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers";
import { LoadingButton } from "@mui/lab";
import { convertDateStringToObject } from "../functions";

function ScheduledModal({
  open,
  onClose,
  selectedJob,
  selectedButton,
  setSelectedButton,
  setScheduledModalOpen,
  cancelErrorText,
  setCancelErrorText,
  rescheduleErrorText,
  setRescheduleErrorText,
  setSelectedScheduledJob,
  cancelLoading,
  setCancelLoading,
  emailLoading,
  setEmailLoading,
}) {
  const currentSession = useSelector((state) => state.sessions.currentSession);
  const [availableChecked, setAvailableChecked] = useState(true);
  const [unavailableText, setUnavailableText] = useState("");
  const [rescheduleLoading, setRescheduleLoading] = useState(false);
  const [cancelSuccess, setCancelSuccess] = useState(false);
  const [sendEmailBtn, setSendEmailBtn] = useState(false);

  function convertDayjsToObject(dayjsObj) {
    return {
      day_of_month: dayjsObj.date(), // Get the day of the month
      month: dayjsObj.month() + 1, // Months are zero-indexed, add 1 for 1-12 format
      year: dayjsObj.year(),
      time: dayjsObj.format("HH:mm"), // Formats the time in 'HH:mm' format
    };
  }

  function isValidTime(time) {
    // Check if the input is a valid date string
    if (typeof time === "string" && !isNaN(Date.parse(time))) {
      const dateObj = new Date(time);
      const minutes = dateObj.getMinutes();
      return minutes === 0 || minutes === 30;
    }
    return false;
  }

  const createValidationSchema = (availableChecked) => {
    return Yup.object({
      scheduledDate: Yup.string()
        .required("Date is required")
        .test(
          "check-availability",
          "Check availability is required",
          function (value) {
            if (typeof value === "string" && !isNaN(Date.parse(value))) {
              if (!isValidTime(value)) {
                return this.createError({
                  path: `scheduledDate`,
                  message: "Time must be on the hour or half-hour",
                });
              }
            } else {
              // Handle invalid or incomplete date format
              return this.createError({
                path: `scheduledDate`,
                message: "Invalid date format",
              });
            }
            // Now availableChecked is accessible
            return availableChecked;
          }
        ),
    });
  };

  const handleCancelAppointment = async () => {
    setCancelLoading(true);

    try {
      const formattedObject = convertDateStringToObject(selectedJob?.date);

      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/notary/cancel-appointment`,
        {
          formattedObject,
          noc_id: selectedJob.id,
          task_id: selectedJob.task_id,
          currentSession: currentSession,
        },
        {
          withCredentials: true,
        }
      );

      if (resp.data.success) {
        setCancelSuccess(true);
      }
      setCancelLoading(false);
    } catch (e) {
      setCancelErrorText(e.response.data.message);
      setCancelLoading(false);
    }
  };

  const handleSendEmail = async () => {
    setEmailLoading(true);

    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/notary/send-reminder-email`,
        {
          selectedJob,
          noc_id: selectedJob.id,
          task_id: selectedJob.task_id,
          currentSession: currentSession,
        },
        {
          withCredentials: true,
        }
      );

      if (resp.data.success) {
        setCancelErrorText(resp.data.message);
        setSendEmailBtn(true);
      }
      setEmailLoading(false);
    } catch (e) {
      setCancelErrorText(e.response.data.message);
      setEmailLoading(false);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      scheduledDate: null,
    },
    validationSchema: createValidationSchema(availableChecked),
    onSubmit: async (values, helpers) => {
      setRescheduleLoading(true);
      const formattedObject = convertDayjsToObject(values.scheduledDate);
      try {
        const resp = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/notary/reschedule-appointment`,
          {
            formattedObject,
            noc_id: selectedJob.id,
            task_id: selectedJob.task_id,
            currentSession: currentSession,
          },
          {
            withCredentials: true,
          }
        );

        setRescheduleErrorText(resp.data.message);
        setRescheduleLoading(false);
        setSelectedScheduledJob(resp.data.data);
        formik.resetForm();
      } catch (e) {
        setRescheduleErrorText(e.response.data.message);
        setRescheduleLoading(false);
      }
    },
  });

  const formikRef = useRef(formik);
  formikRef.current = formik;

  // Effect for the countdown timer
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "60%",
          bgcolor: "inherit",
          boxShadow: 24,
        }}
      >
        <IconButton
          onClick={() => {
            setScheduledModalOpen(false);
            // setSelectedButton("");
            // setCancelErrorText("");
            // setRescheduleErrorText("");
          }}
          sx={{ position: "absolute", right: 10, top: 10 }}
        >
          <CloseIcon />
        </IconButton>
        <Card sx={{ backgroundColor: "#FCF2F0", p: 4 }}>
          <Typography variant="h4" align="center" sx={{ mb: 4 }}>
            Current Appointment Details and Actions
          </Typography>

          <Box>
            <Box sx={{ display: "flex" }}>
              <List
                sx={
                  cancelSuccess
                    ? { flex: 1, textDecoration: "line-through" }
                    : { flex: 1 }
                }
              >
                <Divider variant="middle" />
                <ListItem>
                  <Typography
                    component="div"
                    variant="body2"
                    sx={{ display: "block" }}
                  >
                    <Typography variant="h6">Signee</Typography>
                    <Stack direction="column">
                      <Typography component="span" variant="body2">
                        {selectedJob?.signee?.fullName}
                      </Typography>
                      <Typography component="span" variant="body2">
                        {selectedJob?.signee?.address}
                      </Typography>
                      <Typography component="span" variant="body2">
                        {selectedJob?.signee?.phone}
                      </Typography>
                      <Link
                        href={`mailto:${selectedJob?.signee?.email}`}
                        color="inherit"
                      >
                        <Typography component="span" variant="body2">
                          {selectedJob?.signee?.email}
                        </Typography>
                      </Link>
                    </Stack>
                  </Typography>
                </ListItem>
                <Divider variant="middle" />
                <ListItem>
                  <Typography
                    component="div"
                    variant="body2"
                    sx={{ display: "block" }}
                  >
                    <Typography variant="h6">Document Details</Typography>
                    <Stack direction="column">
                      <Typography component="span" variant="body2">
                        {selectedJob?.document?.owner}
                      </Typography>
                      <Typography component="span" variant="body2">
                        {selectedJob?.document?.property_address}
                      </Typography>
                      <Typography component="span" variant="body2">
                        {selectedJob?.document?.county}
                      </Typography>
                    </Stack>
                  </Typography>
                </ListItem>
                <Divider variant="middle" />
                <ListItem>
                  <Typography
                    component="div"
                    variant="body2"
                    sx={{ display: "block" }}
                  >
                    <Typography variant="h6">Contractor</Typography>
                    <Stack direction="column">
                      <Typography component="span" variant="body2">
                        {selectedJob?.contractor?.name}
                      </Typography>
                      <Typography component="span" variant="body2">
                        {selectedJob?.contractor?.address}
                      </Typography>
                      <Typography component="span" variant="body2">
                        {selectedJob?.contractor?.phone}
                      </Typography>
                    </Stack>
                  </Typography>
                </ListItem>
              </List>
              <Box sx={{ flex: 1.5 }}>
                {selectedButton === "cancel" ? (
                  <>
                    <Card>
                      <CardContent>
                        {cancelSuccess ? (
                          <>
                            <Typography align="center" variant="h6">
                              Cancelled Appointment
                            </Typography>
                            <Typography align="center" mt={1} variant="body2">
                              Your appointment has been successfully cancelled.
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Typography align="center" variant="h6">
                              Cancel Appointment
                            </Typography>
                            <Typography align="center" mt={1} variant="body2">
                              Are you certain you wish to cancel this
                              appointment? Upon successful cancellation, the
                              slot will be reassigned to an available Notary.
                            </Typography>
                          </>
                        )}

                        <FormHelperText
                          sx={{ textAlign: "center", color: "#F44336" }}
                          mt={1}
                        >
                          {cancelErrorText}
                        </FormHelperText>
                        {cancelSuccess == false && (
                          <LoadingButton
                            onClick={handleCancelAppointment}
                            loading={cancelLoading}
                            sx={{ mt: 2 }}
                            fullWidth
                            variant="contained"
                            color="error"
                          >
                            Cancel
                          </LoadingButton>
                        )}
                      </CardContent>
                    </Card>
                  </>
                ) : selectedButton === "reschedule" ? (
                  <>
                    <form noValidate onSubmit={formik.handleSubmit}>
                      <Card>
                        <CardContent>
                          <Typography align="center" variant="h6">
                            Re-schedule Appointment
                          </Typography>
                          <Typography align="center" mt={1} variant="body2">
                            Please choose a preferred time to reschedule your
                            appointment. After you've made the change, we will
                            inform the customer about the update. Kindly note
                            that the customer will have the opportunity to
                            reschedule this new appointment once.
                          </Typography>
                          <FormHelperText
                            sx={{ textAlign: "center", color: "#F44336" }}
                            mt={1}
                          >
                            {rescheduleErrorText}
                          </FormHelperText>
                          <DateTimePicker
                            minutesStep={30}
                            disablePast
                            sx={{
                              mt: 3,
                              width: "100%",
                              border: formik.errors.scheduledDate
                                ? "2px solid red"
                                : "",
                              borderRadius: "10px",
                              "& .MuiFormLabel-root": {
                                // Target the label
                                color: formik.errors.scheduledDate ? "red" : "",
                              },
                              "& .MuiInputBase-root": {
                                borderColor: formik.errors.scheduledDate
                                  ? "red"
                                  : "",
                              },
                              "& .MuiInputBase-root.Mui-error": {
                                "&::after": {
                                  // Change underline color when error is present
                                  borderBottomColor: "red",
                                },
                              },
                            }}
                            label="New Appointment Date"
                            value={formik.values.scheduledDate}
                            onChange={(value) => {
                              formik.setFieldValue(
                                "scheduledDate",
                                value,
                                true
                              );
                              setUnavailableText("");
                            }}
                            className={
                              formik.errors.scheduledDate
                                ? "error-timepicker"
                                : ""
                            }
                            shouldDisableDate={(date) => {
                              // Calculate the date 60 days from now
                              const sixtyDaysFromNow = new Date();
                              sixtyDaysFromNow.setDate(
                                sixtyDaysFromNow.getDate() + 60
                              );

                              // Disable dates that are after 60 days from today
                              return date > sixtyDaysFromNow;
                            }}
                            shouldDisableTime={(timeValue, clockType) => {
                              if (clockType === "hours") {
                                const now = new Date();
                                const selectedDate = new Date(
                                  formik.values.scheduledDate
                                );

                                // Ensure selectedDate is valid
                                if (!isNaN(selectedDate.getTime())) {
                                  // Check if the selected date is today
                                  if (
                                    selectedDate.getDate() === now.getDate() &&
                                    selectedDate.getMonth() ===
                                      now.getMonth() &&
                                    selectedDate.getFullYear() ===
                                      now.getFullYear()
                                  ) {
                                    // Get the current hour and the next hour
                                    const currentHour = now.getHours();
                                    const nextHour = (currentHour + 1) % 24;

                                    // Get the hour from the timeValue Day.js object
                                    const timeValueHour = timeValue.hour();

                                    // Disable the current hour and the next hour
                                    return (
                                      timeValueHour === currentHour ||
                                      timeValueHour === nextHour
                                    );
                                  }
                                }
                              }
                              return false;
                            }}
                          />
                          <FormHelperText sx={{ color: "error.main" }}>
                            {formik.errors.scheduledDate}
                          </FormHelperText>
                          <LoadingButton
                            loading={rescheduleLoading}
                            onClick={() => {
                              setRescheduleErrorText("");
                            }}
                            type="submit"
                            sx={{ mt: 2 }}
                            fullWidth
                            variant="contained"
                            color="error"
                          >
                            Change
                          </LoadingButton>
                        </CardContent>
                      </Card>
                    </form>
                  </>
                ) : selectedButton === "reminder" ? (
                  <>
                    <Card>
                      <CardContent>
                        <Typography align="center" variant="h6">
                          Send Reminder Email
                        </Typography>
                        <Typography align="center" mt={1} variant="body2">
                          An automated email can be sent to the signee as a
                          reminder of their upcoming Remote Online Notarization
                          (RON) session appointment.
                        </Typography>

                        <FormHelperText
                          sx={{ textAlign: "center", color: "#F44336" }}
                          mt={1}
                        >
                          {cancelErrorText}
                        </FormHelperText>

                        <LoadingButton
                          disabled={sendEmailBtn}
                          onClick={handleSendEmail}
                          loading={emailLoading}
                          sx={{ mt: 2 }}
                          fullWidth
                          variant="contained"
                          color="primary"
                        >
                          Send Email
                        </LoadingButton>
                      </CardContent>
                    </Card>
                  </>
                ) : (
                  <></>
                )}
              </Box>
            </Box>
            {cancelSuccess === false && (
              <CardActions sx={{ justifyContent: "center" }}>
                {/* <Button
                  onClick={() => {
                    if (selectedButton === "cancel") {
                      setSelectedButton("");
                      setCancelErrorText("");
                    } else {
                      setSelectedButton("cancel");
                      setCancelErrorText("");
                    }
                  }}
                  variant={
                    selectedButton === "cancel" ? "contained" : "outlined"
                  }
                  color="primary"
                >
                  Cancel Appointment
                </Button> */}
                {/* <Button
                  onClick={() => {
                    if (selectedButton === "reschedule") {
                      setSelectedButton("");
                      setCancelErrorText(""); // Clearing the error text
                    } else {
                      setSelectedButton("reschedule");
                      setCancelErrorText(""); // Clearing the error text
                    }
                  }}
                  variant={
                    selectedButton === "reschedule" ? "contained" : "outlined"
                  }
                  color="secondary"
                >
                  Reschedule Appointment
                </Button> */}
                {/* <Button
                  onClick={() => {
                    if (selectedButton === "reminder") {
                      setSelectedButton("");
                      setCancelErrorText("");
                    } else {
                      setSelectedButton("reminder");
                      setCancelErrorText("");
                    }
                  }}
                  variant={
                    selectedButton === "reminder" ? "contained" : "outlined"
                  }
                  color="info"
                >
                  Send Reminder Email
                </Button> */}
              </CardActions>
            )}
          </Box>
        </Card>
      </Box>
    </Modal>
  );
}

export default ScheduledModal;
