import React, { useState } from "react";
import { Modal, Box, Button, Typography, IconButton } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CreditCardDisplay from "../noc/CreditCardDisplay";

const PermitPackagePaymentModal = ({
  open,
  setOpen,
  paymentCards,
  activeStep,
  setActiveStep,
  handlePermitPackagePayment,
  paymentLoading,
}) => {
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
  };

  return (
    <div>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            color="primary"
            id="modal-modal-title"
            align="center"
            variant="h4"
          >
            Purchase Permit Package
          </Typography>
          <Typography align="center" mt={1} variant="h6">
            Select Payment Card
          </Typography>
          <Box sx={{ position: "relative" }}>
            <Box sx={{ cursor: "default" }}>
              <CreditCardDisplay
                cards={paymentCards}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
              />
            </Box>

            <Box
              sx={{
                position: "absolute",
                top: "35%",
                left: 0,
                display:
                  paymentCards.length === 0 || activeStep === 0
                    ? "none"
                    : undefined,
              }}
            >
              <IconButton
                onClick={() => {
                  if (activeStep > 0) {
                    setActiveStep(activeStep - 1);
                  }
                }}
              >
                <ArrowBackIcon />
              </IconButton>
            </Box>

            <Box
              sx={{
                position: "absolute",
                top: "35%",
                right: 0,
                display:
                  paymentCards.length - 1 === activeStep ||
                  paymentCards.length === 0
                    ? "none"
                    : undefined,
              }}
            >
              <IconButton
                onClick={() => {
                  if (paymentCards.length - 1 !== activeStep) {
                    setActiveStep(activeStep + 1);
                  }
                }}
              >
                <ArrowForwardIcon />
              </IconButton>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              gap: 1,
              width: "100%",
              justifyContent: "center",
            }}
          >
            <Button
              disabled={paymentLoading}
              color="error"
              onClick={handleClose}
              sx={{ mt: 2 }}
            >
              Cancel
            </Button>
            <LoadingButton
              loading={paymentLoading}
              color="success"
              variant="contained"
              onClick={handlePermitPackagePayment}
              sx={{ mt: 2 }}
            >
              Proceed
            </LoadingButton>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default PermitPackagePaymentModal;
