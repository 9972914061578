import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  FormHelperText,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

function ChangePackageNameModal({
  open,
  setOpen,
  onConfirm,
  confirmLoading,
  editObj,
  setEditObj,
}) {
  return (
    <Modal open={open}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          borderRadius: "10px",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography
          id="modal-modal-title"
          variant="h4"
          align="center"
          component="h2"
          color="primary"
          mb={2}
        >
          Edit Package Name
        </Typography>
        {/* <Typography
          align="center"
          id="modal-modal-description"
          sx={{ color: "#e57373", fontWeight: "bold" }}
        >
          Are you sure you want to remove this permit package?
        </Typography> */}
        <TextField
          error={Boolean(editObj?.nameError)}
          onChange={(e) => {
            setEditObj((prev) => ({
              ...prev,
              packageName: e.target.value,
            }));
          }}
          fullWidth
          label="Package Name"
          value={editObj?.packageName}
        />
        <Typography>{editObj?.nameRespText}</Typography>

        <Box sx={{ mt: 5, display: "flex", justifyContent: "space-between" }}>
          <LoadingButton
            loading={confirmLoading}
            sx={{ mr: 1 }}
            fullWidth
            color="error"
            onClick={() => setOpen(false)}
          >
            Cancel
          </LoadingButton>
          <Button
            disabled={confirmLoading || editObj?.packageName === ""}
            sx={{ ml: 1 }}
            fullWidth
            variant="contained"
            color="success"
            onClick={onConfirm}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default ChangePackageNameModal;
