import { DateTime } from "luxon";

export const filterNotaryJobs = (notaryJobs, myJobs) => {
  const myJobsDates = myJobs.map((job) => job.date);
  return notaryJobs.filter((job) => !myJobsDates.includes(job.date));
};

export function convertDateStringToObject(dateStr) {
  // Remove ordinal suffixes (st, nd, rd, th) from the date
  let cleanedDateStr = dateStr.replace(/(\d+)(st|nd|rd|th)/, "$1");

  // Parse the date string
  let parsedDate = new Date(cleanedDateStr);

  // Extract the components and format the time
  let dayOfMonth = parsedDate.getDate();
  let month = parsedDate.getMonth() + 1; // getMonth returns 0-11
  let year = parsedDate.getFullYear();
  let hours = parsedDate.getHours();
  let minutes = parsedDate.getMinutes();

  // Format hours and minutes to ensure leading zero
  let formattedHours = hours.toString().padStart(2, "0");
  let formattedMinutes = minutes.toString().padStart(2, "0");

  return {
    day_of_month: dayOfMonth,
    month: month,
    year: year,
    time: `${formattedHours}:${formattedMinutes}`,
  };
}

export const convertObjectDateToUSString = (appointment, user_timezone) => {
  if (appointment) {
    const {
      day_of_month,
      month,
      time, // e.g., "19:55"
      timezone = "UTC", // default to UTC if not provided
      year,
    } = appointment;

    // Ensure the month and day are zero-padded
    const paddedMonth = String(month).padStart(2, "0");
    const paddedDay = String(day_of_month).padStart(2, "0");

    // Combine day, month, year, and time into a string
    const dateTimeString = `${year}-${paddedMonth}-${paddedDay}T${time}:00`;

    // Parse the original date-time string in the given timezone
    const originalDate = DateTime.fromISO(dateTimeString, {
      zone: "UTC",
    });

    // Check if the original DateTime object is valid
    if (!originalDate.isValid) {
      return "Invalid DateTime";
    }

    // Convert to the user's timezone
    const convertedDate = originalDate.setZone(
      user_timezone || "America/New_York"
    );

    // Format the date into MM/DD/YYYY hh:mm a format
    const formattedDate = convertedDate.toFormat("MM/dd/yyyy hh:mm a");

    return formattedDate;
  }
  return "Invalid Appointment Data";
};
