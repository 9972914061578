import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import LottieAnimation from "../animation/LottieAnimation";
import axios from "axios";
import {
  Box,
  Grid,
  Button,
  Typography,
  Stack,
  useMediaQuery,
  useTheme,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Paper,
} from "@mui/material";

const SuccessChangePlan = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const mobileSize = useMediaQuery(theme.breakpoints.down("sm"));
  const tabletSize = useMediaQuery(theme.breakpoints.down("md"));
  const queryParams = new URLSearchParams(location.search);
  const tokenQuery = queryParams.get("token");
  const sessionIdQuery = queryParams.get("session_id");
  const [forwardUrl, setForwardUrl] = useState("");
  const [pageLoading, setPageLoading] = useState(true);

  let runs = 0;
  useEffect(() => {
    const validate = async () => {
      runs += 1;
      const tokenValues = {
        tokenQuery,
        sessionIdQuery,
      };

      if (!tokenQuery) {
        navigate("/login");
      } else {
        try {
          const resp = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/nocApi/stripe/validate-change-plan-token`,
            {
              tokenValues,
            },
            { withCredentials: true }
          );

          if (resp.data.success === true) {
            setForwardUrl("/dashboard");
            setPageLoading(false);
          } else {
            navigate("/login");
          }
        } catch (e) {
          navigate("/login");
        }
      }
    };
    if (runs < 1) {
      validate();
    }
  }, []);

  return (
    <>
      {pageLoading ? (
        <>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100vh",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {tabletSize ? (
              <>
                <Box>
                  <img
                    style={{
                      width: "10rem",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                    alt="Permit Rockstar Logo"
                    src="/img/website_img/PermitRockstar.png"
                  />
                </Box>
                <LottieAnimation />
              </>
            ) : (
              <CircularProgress color="warning" size="10rem" />
            )}
          </Box>
        </>
      ) : (
        <Box
          sx={
            mobileSize
              ? {
                  justifyContent: "center",
                  overflowY: "auto",
                  overflowX: "hidden",
                  width: "100%",
                  height: "100vh",
                }
              : {
                  backgroundImage: "url('/img/website_img/homeImg.jpeg')",
                  backgroundRepeat: "repeat",
                  width: "100%",
                  flex: "1 1 auto",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  height: "100vh",
                }
          }
        >
          <Box
            sx={
              mobileSize
                ? {
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    flex: "1 1 auto",
                    justifyContent: "center",
                    backgroundColor: "#fff",
                    py: "10px",
                    pb: 0,
                    width: "100%",
                  }
                : {
                    overflowY: "auto",
                    backgroundColor: "#fff",
                    maxWidth: 900,
                    maxHeight: "95vh",
                    borderRadius: "25px",
                    px: 3,
                    py: "10px",
                    width: "100%",
                    pt: "2rem",
                  }
            }
          >
            <div
              style={
                mobileSize
                  ? {
                      width: "100%",
                      padding: "0rem",
                      paddingTop: "0.5rem",
                    }
                  : { padding: "1rem" }
              }
            >
              <Stack spacing={1} sx={{ mb: mobileSize ? 1 : 3 }}>
                <Box
                  sx={{ cursor: "pointer" }}
                  display="flex"
                  justifyContent="center"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      maxWidth: mobileSize ? "8rem" : "10rem",
                      height: "auto",
                      marginLeft: "auto",
                      marginRight: "auto",
                      marginTop: "-9px",
                    }}
                    alt="Permit Rockstar Logo"
                    src="/img/website_img/PermitRockstar.png"
                  />
                </Box>
              </Stack>
              <Typography
                sx={{ mb: mobileSize ? 0 : 2, px: 1 }}
                color="primary"
                variant={mobileSize ? "h5" : "h4"}
                align="center"
              >
                Plan Has Been Changed Successfully
              </Typography>
              <Typography
                sx={{ mb: 2, mt: mobileSize ? 2 : 5 }}
                color="primary"
                variant={mobileSize ? "h6" : "h5"}
                align="center"
              >
                Important Information
              </Typography>
              <Paper
                elevation={3}
                style={{ padding: "16px", maxWidth: "400px", margin: "auto" }}
              >
                <List>
                  <ListItem>
                    <ListItemText
                      primary="New Plan"
                      secondary="Your account has been upgraded/downgraded to the new selected plan. This plan is now active and ready for use."
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Payment Confirmation"
                      secondary="The payment for the new plan has been successfully processed."
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Invoice"
                      secondary="An invoice for the recent transaction has been sent to your registered email address. You can review and download the invoice for your records."
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Cancellation of Old Plan"
                      secondary="Your previous plan has been cancelled. No future payments will be made for the old plan."
                    />
                  </ListItem>
                </List>
              </Paper>
              <Grid
                item
                sm={12}
                sx={{
                  mt: mobileSize ? 2 : 4,
                  mx: 1,
                  position: mobileSize ? "sticky" : undefined,
                  bottom: mobileSize ? 0 : undefined,
                  pb: mobileSize ? 1 : undefined,
                }}
              >
                <Button
                  fullWidth
                  onClick={() => navigate(forwardUrl)}
                  size={mobileSize ? "small" : "large"}
                  sx={{ mt: 0, p: 2 }}
                  variant="contained"
                >
                  F I N I S H
                </Button>
              </Grid>
            </div>
          </Box>
        </Box>
      )}
    </>
  );
};

export default SuccessChangePlan;
