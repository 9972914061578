import React, { useEffect } from "react";
import { useField } from "formik";
import { Autocomplete, TextField } from "@mui/material";
import usePlacesAutocomplete from "use-places-autocomplete";

const AutoField = ({
  name,
  label,
  setAddress,
  options,
  setOptions,
  defaultValue,
}) => {
  const {
    value,
    suggestions: { data },
    setValue,
  } = usePlacesAutocomplete();
  const [field, meta] = useField(name);

  function buildAddress() {
    let newOptions = [];

    data.map(({ description }) => newOptions.push(description));

    setOptions(newOptions);
  }

  useEffect(() => {
    // Set default value when defaultValue prop changes
    setValue(defaultValue || "");
  }, [defaultValue, setValue]);

  return (
    <Autocomplete
      autoComplete={false}
      onChange={(event, newValue) => {
        setAddress(newValue);
      }}
      inputValue={value}
      onInputChange={(event, newInputValue) => {
        setValue(newInputValue);
      }}
      id="controllable-states-demo"
      options={options}
      freeSolo={false}
      filterOptions={(options, object) => options}
      renderInput={(params) => (
        <TextField
          onKeyDown={buildAddress}
          label={label}
          {...field}
          {...params}
          error={meta.touched && Boolean(meta.error)}
          helperText={meta.touched && meta.error}
        />
      )}
    />
  );
};

export default AutoField;
