import { useNavigate } from "react-router-dom";
import { Box, Button, Grid, useTheme, useMediaQuery } from "@mui/material";
import ReactPlayer from "react-player";
import { useRef } from "react";

const HomeComponent = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("lg"));
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const mobileSize = useMediaQuery(theme.breakpoints.down("sm"));
  const tabletSize = useMediaQuery(theme.breakpoints.down("md"));

  const playerRef = useRef(null);

  const handleEnded = () => {
    // When the video ends, seek to the beginning and start playing
    if (playerRef.current) {
      playerRef.current.seekTo(0);
    }
  };

  return (
    <Grid
      container
      sx={
        isXs
          ? {
              backgroundImage: "url('/img/website_img/homeImg.jpeg')",
              backgroundSize: "cover",
              height: "40vh",
            }
          : isMd
          ? {
              backgroundImage: "url('/img/website_img/homeImg.jpeg')",
              backgroundSize: "cover",
              height: "82vh",
            }
          : {
              backgroundImage: "url('/img/website_img/homeImg.jpeg')",
              height: "100vh",
            }
      }
    >
      <Grid item sm={12} xs={12}>
        <Box
          display="flex"
          // justifyContent="left"
          sx={{ flexDirection: "column", position: "relative" }}
        >
          <img
            style={
              isXs
                ? {
                    width: "8rem",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: "0.5rem",
                    marginBottom: "4.2rem",
                  }
                : {
                    width: "10rem",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: "1rem",
                  }
            }
            alt="Permit Rockstar Logo"
            src="/img/website_img/PermitRockstar.png"
          />
          <Button
            onClick={(e) => {
              navigate("/login");
            }}
            color="warning"
            fullWidth
            size="large"
            sx={{
              backgroundColor: "#F2CD00",
              borderRadius: 0,
              ":hover": { backgroundColor: "#CDAF0B" },
            }}
            variant="contained"
          >
            L O G I N / R E G I S T E R
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default HomeComponent;
