import {
  Box,
  Card,
  Grid,
  useMediaQuery,
  CircularProgress,
  CardHeader,
  Typography,
  IconButton,
  TextField,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import validator from "validator";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import LottieAnimation from "../../animation/LottieAnimation";
import React, { useEffect, useState } from "react";
import { useTheme } from "@emotion/react";
import ContractorCard from "../permitComponents/ContractorCard";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useDispatch, useSelector } from "react-redux";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useLocation } from "react-router-dom";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { license_types_suffix } from "../../data/dbpr";
import { LoadingButton } from "@mui/lab";
import { addContractor } from "../../store/actions/userActions";

const Contractors = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  //? SCREEN SIZES ##################################################################
  const mobileSize = useMediaQuery(theme.breakpoints.down("sm"));
  const tabletSize = useMediaQuery(theme.breakpoints.down("md"));
  const largeTabletSize = useMediaQuery(theme.breakpoints.down("lg"));
  const largePC = useMediaQuery(theme.breakpoints.up("xl"));
  //? ###############################################################################

  const currentSession = useSelector((state) => state.sessions.currentSession);
  const currentUser = useSelector((state) => state[currentSession].user);

  const [fromPermit, setFromPermit] = useState(location.state);

  const [dataLoading, setDataLoading] = useState(true);
  const [contractors, setContractors] = useState([]);
  const [foundContractor, setFoundContractor] = useState(null);
  const [cardPage, setCardPage] = useState(1);
  const [selectedUser, setSelectedUser] = useState(true);
  const [licenseNumber, setLicenseNumber] = useState("");
  const [userList, setUserList] = useState([]);
  const [licenseInputError, setLicenseInputError] = useState("");
  const [addContractorError, setAddContractorError] = useState("");
  const [licenseOnError, setLicenseOnError] = useState(false);
  const [searchDisabled, setSearchDisabled] = useState(true);
  const [searchLoading, setSearchLoading] = useState(false);
  const [addContractorLoading, setAddContractorLoading] = useState(false);

  const [showLicenseInput, setShowLicenseInput] = useState(false);
  const [showAddInput, setShowAddInput] = useState(false);
  const [addNewActive, setAddNewActive] = useState(false);

  let contractorCount = 0;

  useEffect(() => {
    if (currentUser?.user?.expired === true) {
      navigate("/subscription");
    }

    if (currentSession === "subUserSession") {
      navigate("/dashboard");
    }
  }, [currentUser]);

  const handleNavigate = (direction) => {
    let newPage;
    if (direction === "forward") {
      newPage = cardPage + 1;
    } else if (direction === "back") {
      newPage = cardPage - 1;
    }

    contractorFormik.setValues(currentUser?.user?.contractors?.[newPage - 1]);
    setCardPage(newPage);
  };

  const validateLicenseNumberPrefix = (licenseNumber) => {
    // Extract the prefix part from the license number
    const prefix = licenseNumber.match(/^[A-Za-z]+/);

    if (prefix) {
      const prefixStr = prefix[0];
      // Check if the extracted prefix exists in the license_types_suffix
      for (let key in license_types_suffix) {
        if (license_types_suffix[key].includes(prefixStr)) {
          return true;
        }
      }
    }
    return false;
  };

  const handleAddClick = () => {
    if (searchLoading || addContractorLoading) {
      return;
    }
    if (showLicenseInput || addNewActive) {
      setLicenseNumber("");
      setShowAddInput(false);
      setFoundContractor(null);
      setAddNewActive(false);
      setShowLicenseInput(false);
      if (currentUser?.user?.contractors.length > 0) {
        contractorFormik?.setValues(
          currentUser?.user?.contractors[cardPage - 1]
        );
      } else {
        contractorFormik.resetForm();
      }
    }
    // else if (addNewActive) {
    //   setAddNewActive(false);
    // }
    else {
      contractorFormik.resetForm();
      setAddNewActive(true);
      setShowLicenseInput(true);
    }
  };

  const handleLicenseNumberInputChange = (event) => {
    // Replace letters with capital letters
    const value = event?.target.value.toUpperCase();
    setLicenseInputError("");

    // Update Formik value using setFieldValue
    setLicenseNumber(value);

    if (value.length >= 7 && value?.length <= 12) {
      setSearchDisabled(false);
      setLicenseOnError(false);
    } else {
      setSearchDisabled(true);
      setLicenseOnError(true);
    }
  };

  // CONTRACTOR FORMIK ///
  const handlePhoneChange = (event) => {
    const { value } = event.target;

    if (value.length > 12) {
      return;
    }
    const digitsOnly = value.replace(/\D/g, ""); // Remove non-numeric characters
    let formattedPhoneNumber = "";

    // Add dashes after 3rd and 6th digits
    for (let i = 0; i < digitsOnly.length; i++) {
      if (i === 3 || i === 6) {
        formattedPhoneNumber += "-";
      }
      formattedPhoneNumber += digitsOnly[i];
    }

    // setFormattedPhone(formattedPhoneNumber); // Update the formatted phone in state
    contractorFormik.setValues((oldValue) => ({
      ...oldValue,
      mobile: formattedPhoneNumber,
    })); // Update formik field value
  };

  const handleSearchLicense = async () => {
    if (!validateLicenseNumberPrefix(licenseNumber)) {
      setLicenseInputError("License Number is Invalid");
      setLicenseOnError(true);
      return;
    }

    try {
      setSearchLoading(true);

      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/data/validate-license`,
        {
          license_number: licenseNumber,
          currentSession: currentSession,
        },
        { withCredentials: true }
      );

      if (resp?.data?.success) {
        setFoundContractor(resp?.data?.data);
        setShowAddInput(true);
        setShowLicenseInput(false);
        contractorFormik?.setValues((prevValue) => ({
          ...prevValue,
          id: resp?.data?.data?.id,
          selectedUser: currentUser?.user?.email,
          firstName: resp?.data?.data?.first_name,
          middleName: resp?.data?.data?.middle_name,
          lastName: resp?.data?.data?.last_name,
          businessName: resp?.data?.data?.dba_name,
          licenseType: resp?.data?.data?.license_type,
          licenseNumber: resp?.data?.data?.license_number,
          rank: resp?.data?.data?.rank,
          status: resp?.data?.data?.status,
          expires: resp?.data?.data?.expires,
          mainAddress: resp?.data?.data?.main_address,
          mainAddressCounty: resp?.data?.data?.main_address_county,
          email: currentUser?.user?.email,
        }));
      } else {
        setLicenseOnError(true);
        setLicenseInputError(resp?.data?.message);
      }
    } catch (error) {
      setLicenseOnError(true);
      setLicenseInputError(error?.response?.data?.message);
    } finally {
      setSearchLoading(false);
    }
  };

  // useEffect(() => {
  //   if (licenseInputError) {
  //     const licenseTimeOut = setTimeout(() => {
  //       setLicenseInputError("");
  //     }, 3000);
  //     return () => clearTimeout(licenseTimeOut);
  //   }
  // }, [licenseInputError]);

  useEffect(() => {
    const mergeUsers = () => {
      let combinedUsers = [];

      // Get the current user's full name
      if (currentUser?.user?.personal && currentUser.user.personal.length > 0) {
        const currentUserFullName =
          currentUser.user.personal[0].firstName +
          " " +
          currentUser.user.personal[0].lastName;
        combinedUsers.push({
          fullName: currentUserFullName,
          email: currentUser.user.email,
        });
      }

      // Append each subuser's full name
      if (currentUser?.subusers && Array.isArray(currentUser.subusers)) {
        const subusersFullNames = currentUser.subusers.map((subuser) => ({
          fullName: subuser.first + " " + subuser.last,
          email: subuser.email,
        }));
        combinedUsers = combinedUsers.concat(subusersFullNames);
      }

      setUserList(combinedUsers);
    };

    mergeUsers();
  }, [currentUser]);

  useEffect(() => {
    if (currentUser) {
      setContractors(currentUser?.user?.contractors);
      if (currentUser?.user?.contractors.length > 0) {
        contractorFormik?.setValues(currentUser?.user?.contractors[0]);
      }
      setDataLoading(false);
    }
  }, [currentUser?.user?.contractors]);

  // CONTRACTOR FORMIK //
  const contractorFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: "",
      selectedUser: "",
      firstName: "",
      middleName: "",
      lastName: "",
      businessName: "",
      licenseType: "",
      licenseNumber: "",
      rank: "",
      status: "",
      expires: "",
      mainAddress: "",
      mainAddressCounty: "",
      email: "",
      mobile: "",
    },
    validationSchema: Yup.object({
      selectedUser: Yup.string().min(3).required("Please select a user"),
      firstName: Yup.string()
        .min(2, "First name is too short")
        .required("First name is required"),
      middleName: Yup.string(),
      lastName: Yup.string()
        .min(2, "Last name is too short")
        .required("Last name is required"),
      businessName: Yup.string()
        .min(2, "Business name is too short")
        .required("Business name is required"),
      mainAddress: Yup.string()
        .min(2, "Main address is too short")
        .required("Main address is required"),
      mainAddressCounty: Yup.string()
        .min(2, "County is too short")
        .required("County is required"),
      // email: Yup.string()
      //   .email("Must be a valid email")
      //   .required("Email address is required"),
      mobile: Yup.string()
        .required("Phone number is required")
        .test("PhoneTest", "Phone number is invalid", function (value) {
          const isValidPhoneNumber = validator.isMobilePhone(value);

          if (isValidPhoneNumber) {
            return true;
          } else {
            return false;
          }
        }),
    }),

    onSubmit: async (values, helpers) => {
      try {
        setAddContractorLoading(true);

        const resp = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/auth/finalize-contractor`,
          {
            values,
            currentSession: currentSession,
          },
          { withCredentials: true }
        );

        if (resp.data.success) {
          await dispatch(addContractor(resp.data.data));
          setLicenseNumber("");
          setShowAddInput(false);
          setFoundContractor(null);
          setAddNewActive(false);
          setShowLicenseInput(false);
          contractorFormik?.setValues(resp.data.data);
          setCardPage(currentUser?.user?.contractors.length + 1);
        }
      } catch (error) {
        setAddContractorError(error?.response?.data?.message);
      } finally {
        setAddContractorLoading(false);
      }
    },
  });

  return (
    <>
      {dataLoading ? (
        <>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100vh",
              backgroundColor: "#fff",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              zIndex: 9999,
            }}
          >
            {tabletSize ? (
              <>
                <Box sx={{ mb: 2 }}>
                  <img
                    style={{
                      width: "10rem",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                    alt="Permit Rockstar Logo"
                    src="/img/website_img/PermitRockstar.png"
                  />
                </Box>
                <LottieAnimation />
              </>
            ) : (
              <CircularProgress color="warning" size="10rem" />
            )}
          </Box>
        </>
      ) : (
        <>
          <Box
            sx={{
              mx: largePC ? "auto" : 0,
              maxWidth: "1500px",
              py: 1,
              backgroundColor: "#EEF2F6",
              mb: tabletSize ? "5rem" : undefined,
              mt: 3,
            }}
          >
            <Grid
              container
              sx={{
                px: mobileSize ? 1 : tabletSize ? 2 : 3,
              }}
            >
              <Card
                py={3}
                // px={3}
                mb={4}
                component={Grid}
                sx={{
                  width: largeTabletSize ? "90vw" : "60vw",
                  maxWidth: "1100px",
                  mx: "auto",
                }}
              >
                <Box width="100%" mb={2}>
                  <Typography variant="h4" align="center">
                    Manage Contractors
                  </Typography>
                </Box>
                <Grid
                  container
                  sx={{
                    borderTop: "6px solid #f3f3f3",
                    borderBottom: "6px solid #f3f3f3",
                    py: 2,
                  }}
                >
                  <Grid
                    item
                    xs={6}
                    px={1}
                    sx={{ borderRight: "6px solid #f3f3f3", my: 2 }}
                  >
                    <Box
                      sx={{
                        visibility: Boolean(addNewActive)
                          ? "hidden"
                          : undefined,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                      }}
                      mb={2}
                    >
                      <IconButton
                        onClick={() => handleNavigate("back")}
                        sx={{
                          visibility:
                            cardPage === 1 || Boolean(addNewActive)
                              ? "hidden"
                              : "visible",
                        }}
                      >
                        <ArrowBackIosNewIcon sx={{ fontSize: "20px" }} />
                      </IconButton>

                      <Typography align="center" variant="h5">
                        {contractors.length === 0
                          ? "0/0"
                          : cardPage + "/" + contractors.length}
                      </Typography>
                      <IconButton
                        onClick={() => handleNavigate("forward")}
                        sx={{
                          visibility:
                            contractors.length === 0 ||
                            cardPage === contractors.length ||
                            Boolean(addNewActive)
                              ? "hidden"
                              : "visible",
                        }}
                      >
                        <ArrowForwardIosIcon sx={{ fontSize: "20px" }} />
                      </IconButton>
                    </Box>

                    <ContractorCard
                      data={contractorFormik?.values}
                      mb={4}
                      colorIndex={cardPage}
                      empty={
                        (Boolean(addNewActive) ||
                          currentUser?.user?.contractors.length === 0) &&
                        !Boolean(foundContractor)
                      }
                      showLicenseInput={showLicenseInput}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: "100%",
                        position: "relative",
                      }}
                    >
                      <Typography
                        variant="h3"
                        color="primary"
                        className={addNewActive ? "moveUp" : ""}
                        sx={{
                          position: "absolute",
                          top: addNewActive ? "10px" : "35%",
                          transition: "top 1s",
                        }}
                      >
                        {currentUser?.user?.contractors.length >=
                        parseInt(currentUser?.tier?.license)
                          ? "LIMIT REACHED"
                          : "Add New Contractor"}
                      </Typography>
                      {!addNewActive && (
                        <Typography
                          className="fadeIn"
                          sx={{
                            position: "absolute",
                            top: "42%",
                            transition: "fade 1s",
                          }}
                          color="primary"
                          align="center"
                          variant="h4"
                        >
                          {currentUser?.user?.contractors.length +
                            "/" +
                            currentUser?.tier?.license}
                        </Typography>
                      )}

                      <IconButton
                        disabled={
                          currentUser?.user?.contractors.length >=
                          parseInt(currentUser?.tier?.license)
                        }
                        onClick={handleAddClick}
                        sx={{
                          color: "#4caf50", // Green color
                          fontSize: 80, // Increase the size
                          "&:hover": {
                            color: "#388e3c", // Darker green on hover
                          },
                          position: "absolute",
                          top: addNewActive ? "20px" : "47%",
                          // marginTop: showLicenseInput ? "38px" : "0",
                          transition: "top 1s",
                        }}
                        className={
                          addNewActive
                            ? "fadeInBtn"
                            : !showAddInput && "growBtn"
                        }
                      >
                        {addNewActive ? (
                          <RemoveCircleIcon color="error" fontSize="inherit" />
                        ) : (
                          <AddCircleIcon fontSize="inherit" />
                        )}
                      </IconButton>
                      {showAddInput && foundContractor ? (
                        <Box
                          className={addNewActive ? "fadeIn" : "fadeOut"}
                          sx={{ mt: 11, px: 1, transition: "fade 1s" }}
                        >
                          <Grid spacing={1} container>
                            <Grid item xs={12}>
                              <FormControl
                                disabled
                                fullWidth
                                variant="filled"
                                error={
                                  !!(
                                    contractorFormik.touched?.selectedUser &&
                                    contractorFormik.errors?.selectedUser
                                  )
                                }
                              >
                                <InputLabel>Contractor Assigning To</InputLabel>
                                <Select
                                  name="selectedUser"
                                  value={
                                    contractorFormik.values?.selectedUser || ""
                                  }
                                  onChange={contractorFormik.handleChange}
                                  MenuProps={{
                                    PaperProps: {
                                      style: {
                                        marginTop: "1.5px",
                                        maxHeight: 150, // Set the desired max height for the dropdown menu
                                        width: "auto",
                                        maxWidth: "100%",
                                      },
                                    },
                                  }}
                                  sx={{
                                    maxWidth: "100%", // Set the desired max-width for the input element
                                    "& .MuiTypography-body2": {
                                      whiteSpace: "wrap", // Prevent text from wrapping or shrinking
                                    },
                                  }}
                                >
                                  {userList?.map((u, index) => (
                                    <MenuItem key={index} value={u?.email}>
                                      <Typography variant="body2">
                                        {u?.fullName}
                                      </Typography>
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <TextField
                                error={
                                  !!(
                                    contractorFormik.touched.firstName &&
                                    contractorFormik.errors.firstName
                                  )
                                }
                                helperText={
                                  contractorFormik.touched.firstName &&
                                  contractorFormik.errors.firstName
                                }
                                onBlur={contractorFormik.handleBlur}
                                onChange={contractorFormik.handleChange}
                                label="First Name"
                                name="firstName"
                                value={contractorFormik.values.firstName}
                                variant="filled"
                                fullWidth
                                sx={{ mb: 2 }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <TextField
                                onChange={contractorFormik.handleChange}
                                label="Middle Name"
                                name="middleName"
                                value={contractorFormik.values.middleName}
                                variant="filled"
                                fullWidth
                                sx={{ mb: 2 }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <TextField
                                error={
                                  !!(
                                    contractorFormik.touched.lastName &&
                                    contractorFormik.errors.lastName
                                  )
                                }
                                helperText={
                                  contractorFormik.touched.lastName &&
                                  contractorFormik.errors.lastName
                                }
                                onBlur={contractorFormik.handleBlur}
                                onChange={contractorFormik.handleChange}
                                label="Last Name"
                                name="lastName"
                                value={contractorFormik.values.lastName}
                                variant="filled"
                                fullWidth
                                sx={{ mb: 2 }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                error={
                                  !!(
                                    contractorFormik.touched.businessName &&
                                    contractorFormik.errors.businessName
                                  )
                                }
                                helperText={
                                  contractorFormik.touched.businessName &&
                                  contractorFormik.errors.businessName
                                }
                                onBlur={contractorFormik.handleBlur}
                                onChange={contractorFormik.handleChange}
                                label="Business Name"
                                name="businessName"
                                value={contractorFormik.values.businessName}
                                variant="filled"
                                fullWidth
                                sx={{ mb: 2 }}
                              />
                            </Grid>
                            <Grid item xs={8}>
                              <TextField
                                error={
                                  !!(
                                    contractorFormik.touched.mainAddress &&
                                    contractorFormik.errors.mainAddress
                                  )
                                }
                                helperText={
                                  contractorFormik.touched.mainAddress &&
                                  contractorFormik.errors.mainAddress
                                }
                                onBlur={contractorFormik.handleBlur}
                                onChange={contractorFormik.handleChange}
                                label="Main Address"
                                name="mainAddress"
                                value={contractorFormik.values.mainAddress}
                                variant="filled"
                                fullWidth
                                sx={{ mb: 2 }}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <TextField
                                error={
                                  !!(
                                    contractorFormik.touched
                                      .mainAddressCounty &&
                                    contractorFormik.errors.mainAddressCounty
                                  )
                                }
                                helperText={
                                  contractorFormik.touched.mainAddressCounty &&
                                  contractorFormik.errors.mainAddressCounty
                                }
                                onBlur={contractorFormik.handleBlur}
                                onChange={contractorFormik.handleChange}
                                label="County"
                                name="mainAddressCounty"
                                value={
                                  contractorFormik.values.mainAddressCounty
                                }
                                variant="filled"
                                fullWidth
                                sx={{ mb: 2 }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                error={
                                  !!(
                                    contractorFormik.touched.mobile &&
                                    contractorFormik.errors.mobile
                                  )
                                }
                                helperText={
                                  contractorFormik.touched.mobile &&
                                  contractorFormik.errors.mobile
                                }
                                onBlur={contractorFormik.handleBlur}
                                onChange={handlePhoneChange}
                                label="Mobile Phone"
                                name="mobile"
                                value={contractorFormik.values.mobile}
                                variant="filled"
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Typography
                                align="center"
                                variant="h6"
                                color="error"

                                // sx={{ minHeight: "1.4rem" }}
                              >
                                {addContractorError}
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sx={{ width: "100%" }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <LoadingButton
                                  loading={addContractorLoading}
                                  color="success"
                                  variant="contained"
                                  onClick={() => {
                                    contractorFormik.handleSubmit();
                                  }}
                                >
                                  ADD CONTRACTOR
                                </LoadingButton>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      ) : (
                        showLicenseInput &&
                        !foundContractor && (
                          <Box sx={{ mt: 0, px: 1 }}>
                            <TextField
                              disabled={searchLoading}
                              label="License Number"
                              variant="filled"
                              value={licenseNumber}
                              onChange={(e) =>
                                handleLicenseNumberInputChange(e)
                              }
                              fullWidth
                              className="fadeIn"
                              inputProps={{
                                style: {
                                  fontSize: "32px",
                                  fontWeight: "bold",
                                  color: licenseOnError ? "red" : undefined,
                                },
                              }}
                              sx={
                                licenseOnError
                                  ? {
                                      mb: 2,
                                      "& .MuiFilledInput-root": {
                                        "&:before": {
                                          borderBottomColor: "red",
                                        },
                                        "&:hover:not(.Mui-disabled):before": {
                                          borderBottomColor: "red",
                                        },
                                        "&.Mui-focused:before": {
                                          borderBottomColor: "red",
                                        },
                                      },
                                      "& .MuiInputLabel-root": {
                                        color: "red", // Optional: Change the label color to red
                                      },
                                    }
                                  : { mb: 2 }
                              }
                            />
                            <Typography
                              align="center"
                              variant="h6"
                              color="error"
                              mb={1}
                              sx={{ minHeight: "1.4rem" }}
                            >
                              {licenseInputError}
                            </Typography>
                            <Box
                              className="fadeIn"
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <LoadingButton
                                loading={searchLoading}
                                onClick={handleSearchLicense}
                                disabled={searchDisabled}
                                color="success"
                                sx={{ minWidth: "158px" }}
                                variant="contained"
                              >
                                SEARCH
                              </LoadingButton>
                            </Box>
                          </Box>
                        )
                      )}
                    </Box>
                  </Grid>
                </Grid>
                {fromPermit?.fromPermit && (
                  <Box
                    sx={{ display: "flex", justifyContent: "center", mt: 2 }}
                  >
                    <Button
                      onClick={() => {
                        navigate("/permits/create", {
                          state: fromPermit,
                        });
                      }}
                      disabled={addNewActive}
                      variant="contained"
                    >
                      Back to Create Permit Package
                    </Button>
                  </Box>
                )}
              </Card>
            </Grid>
          </Box>
        </>
      )}
    </>
  );
};

export default Contractors;
