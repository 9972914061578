import { LoadingButton } from "@mui/lab";
import { Modal, Box, Button, Typography, TextField } from "@mui/material";

const DocumentRecordModal = ({
  recordModal,
  setRecordModal,
  selectedRow,
  recordLoading,
  submitStartRecord,
  recordText,
}) => {
  return (
    <Modal
      open={recordModal}
      //   onClose={() => {
      //     setRecordModal(false);
      //   }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          bgcolor: "background.paper",
          boxShadow: 24,
          borderRadius: "1rem",
          p: 4,
        }}
      >
        <Typography id="modal-title" variant="h4" align="center" component="h2">
          Are you sure you want to record this document?
        </Typography>
        <Box sx={{ mt: 1, mb: 3 }}>
          <Typography sx={{ mb: 1 }} variant="h6" align="center">
            {selectedRow?.document?.primary_address}
          </Typography>
        </Box>
        <Typography
          align="center"
          sx={{ color: "#4caf50", minHeight: "1.5rem" }}
          fontWeight="bold"
        >
          {recordText}
        </Typography>
        <Box>
          <LoadingButton
            loading={recordLoading}
            sx={{ mt: "1rem" }}
            variant="contained"
            color="success"
            fullWidth
            onClick={submitStartRecord}
          >
            Record Document
          </LoadingButton>
        </Box>

        <div
          style={{
            borderBottom: "3px solid #d3d3d3",
            marginTop: "1rem",
            marginBottom: "1rem",
          }}
        ></div>

        <Button
          disabled={recordLoading}
          sx={{ mt: 2 }}
          variant="contained"
          fullWidth
          onClick={() => {
            setRecordModal(false);
          }}
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
};

export default DocumentRecordModal;
