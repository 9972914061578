import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { filterNotaryJobs } from "./functions";
import { DateTime } from "luxon";
import axios from "axios";
import {
  Box,
  CircularProgress,
  Container,
  Unstable_Grid2 as Grid,
} from "@mui/material";

import ScheduleIcon from "@mui/icons-material/Schedule"; // For appointment schedule
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"; // For completed jobs
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive"; // For urgent tasks
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { convertIsoToLocalFormat } from "../../functions/helperFunctions";

import NotaryCard from "./dashboardComponents/NotaryCard";
import NotaryPerformanceChart from "./charts/NotaryPerformanceChart";
import NotaryJobNotice from "./dashboardComponents/NotaryJobNotice";

const NotaryDashboard = () => {
  const navigate = useNavigate();
  const [loadingPage, setLoadingPage] = useState(false);
  const currentSession = useSelector((state) => state.sessions.currentSession);
  const currentUser = useSelector((state) => state.notarySession.user);
  const notaryJobs = useSelector((state) => state.notarySession.notaryJobs);
  const [year, setYear] = useState(String(new Date().getFullYear()));
  const [filteredNotaryJobs, setFilteredNotaryJobs] = useState([]);
  const [closestJobLogs, setClosestJobLogs] = useState(null);
  const [setCompletedLoading, setSetCompletedLoading] = useState(false);
  const [setCompletedText, setSetCompletedText] = useState("");
  const [closestJobDate, setClosestJobDate] = useState("N/A");
  const [secondClosestJobDate, setSecondClosestJobDate] = useState("N/A");
  const [fiveMinBeforeSession, setFiveMinBeforeSession] = useState(false);

  console.log(notaryJobs);
  console.log(currentUser?.closest_job);

  useEffect(() => {
    if (setCompletedText !== "") {
      const interval = setInterval(() => {
        setSetCompletedText("");
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [setCompletedText]);

  useEffect(() => {
    if (currentUser?.closest_job && currentUser?.closest_job?.booked_date) {
      const closestIso = currentUser.closest_job.booked_date;
      const userZone = currentUser?.timezone || "America/New_York";

      // Convert the closestIso to the user's local time format
      const dateString = convertIsoToLocalFormat(
        closestIso,
        userZone,
        "cccc, LLLL dd, yyyy hh:mm a"
      );
      setClosestJobDate(dateString);

      // Convert closestIso to a Date object in UTC
      const closestDateUTC = DateTime.fromISO(closestIso, { zone: "utc" });

      const checkTimeDifference = () => {
        // Get the current time in UTC
        const nowUTC = DateTime.utc();

        // Convert both dates to UTC for comparison
        const timeDifference = closestDateUTC - nowUTC;

        // Check if the difference is less than 10 minutes (300,000 milliseconds)
        if (timeDifference <= 600000) {
          setFiveMinBeforeSession(true);
        } else {
          setFiveMinBeforeSession(false);
        }
      };

      // Perform the initial check
      checkTimeDifference();

      const intervalId = setInterval(checkTimeDifference, 30000);

      // Clear the interval when the component unmounts or dependencies change
      return () => clearInterval(intervalId);
    } else {
      setClosestJobDate("N/A");
      setFiveMinBeforeSession(false);
    }

    if (
      currentUser?.second_closest_date &&
      currentUser.second_closest_date !== "N/A"
    ) {
      const secondClosestIso = currentUser.second_closest_date;
      const userZone = currentUser?.timezone || "America/New_York";

      // Convert the secondClosestIso to the user's local time format
      const dateString = convertIsoToLocalFormat(
        secondClosestIso,
        userZone,
        "cccc, LLLL dd, yyyy hh:mm a"
      );
      setSecondClosestJobDate(dateString);
    } else {
      setSecondClosestJobDate("N/A");
    }
  }, [currentUser]);

  useEffect(() => {
    let found = false; // Flag to check if a matching job has been found
    for (const job of currentUser?.my_current_jobs || []) {
      const closestDateIso = currentUser?.closest_job?.date;
      const JobDateIso = job?.date;

      if (JobDateIso === closestDateIso) {
        const closestDt = DateTime.fromISO(closestDateIso).toUTC();
        const currentTime = DateTime.now().toUTC();

        // if (job.document && job.document?.document_logs?.length > 0) {
        if (currentTime > closestDt) {
          setClosestJobLogs(job);
          found = true;
          break; // Stop the loop once a valid job is found
        }
      }
    }
    if (!found) {
      setClosestJobLogs(null); // Only set to null if no jobs or no match is found
    }
  }, [currentUser, closestJobLogs]);

  //!MODIFY THIS ////////////////////////////////////////
  useEffect(() => {
    if (!currentUser || !notaryJobs) {
      return;
    }
    if (!currentUser?.role === "notary") {
      navigate("/login");
    }

    if (
      currentUser?.on_site_registered === false ||
      currentUser?.bn_registered === false ||
      currentUser?.availabilities.length === 0
    ) {
      navigate("/notary/finalize");
      return;
    }

    //   (currentUser?.actived === false &&
    //     currentUser?.notary_enabled === false) ||
    //   (currentUser?.actived === true &&
    //     currentUser?.locked === false &&
    //     currentUser?.notary_enabled === false) ||
    //   currentUser?.availabilities.length === 0
    // ) {
    //   navigate("/notary/finalize");
    //   return;
    // }

    // setFilteredNotaryJobs(
    //   filterNotaryJobs(notaryJobs, currentUser?.my_current_jobs)
    // );

    setLoadingPage(false);
  }, [currentUser, notaryJobs]);
  //! /////////////////////////////////////////////////////////////////
  const handleSetCompleted = async () => {
    setSetCompletedLoading(true);
    const { id, ...restOfJobLogs } = closestJobLogs;
    const modifiedJobLogs = { noc_id: id, ...restOfJobLogs };
    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/notary/set-completed-notarization`,
        {
          job: modifiedJobLogs,
          currentSession: currentSession,
        },
        {
          withCredentials: true,
        }
      );

      if (resp.data.success === false) {
        setSetCompletedText(resp.data.message);
      }

      setSetCompletedLoading(false);
    } catch (e) {
      setSetCompletedLoading(false);
    }
  };

  const handleChartValues = () => {
    let completedJobs = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let acceptedJobs = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let cancelledJobs = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    currentUser?.activity.map((item) => {
      if (item?.year === year) {
        completedJobs[item.month] = item.completed;
        acceptedJobs[item.month] = item.accepted;
        cancelledJobs[item.month] = item.cancelled;
      }
      return null;
    });

    return [
      {
        name: "Completed Jobs",
        type: "column",
        data: completedJobs,
      },
      {
        name: "Accepted Jobs",
        type: "area",
        data: acceptedJobs,
      },
      {
        name: "Failed Jobs",
        type: "line",
        data: cancelledJobs,
      },
    ];
  };

  return (
    <>
      {loadingPage ? (
        <>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100vh",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CircularProgress color="warning" size="10rem" />
          </Box>
        </>
      ) : (
        <>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              py: 1,
              my: 2,
              backgroundColor: "#EEF2F6",
            }}
          >
            <Container maxWidth="xxl">
              <Grid container spacing={2} justifyContent="center">
                <Grid xs={12} sm={6} md={2}>
                  <NotaryCard
                    title="Current Requests"
                    value={notaryJobs?.jobs_list?.length}
                    icon={NotificationsActiveIcon}
                    color="primary.main"
                    tooltip="Requests awaiting your action"
                  />
                </Grid>
                <Grid xs={12} sm={6} md={2}>
                  <NotaryCard
                    title="Completed Jobs"
                    value={currentUser?.completed_jobs}
                    icon={CheckCircleOutlineIcon}
                    color="success.main"
                    tooltip="Jobs successfully completed"
                  />
                </Grid>
                <Grid xs={12} sm={6} md={2}>
                  <NotaryCard
                    title="Pending Jobs"
                    value={currentUser?.pending_jobs}
                    icon={TaskAltIcon}
                    color="warning.main"
                    tooltip="Your Pending Jobs"
                  />
                </Grid>
                <Grid xs={12} sm={6} md={2}>
                  <NotaryCard
                    title="Failed Jobs"
                    value={currentUser?.cancelled_jobs}
                    icon={CancelIcon}
                    color="error.main"
                    tooltip="Time for your next notarization"
                  />
                </Grid>
                <Grid xs={12} sm={6} md={3}>
                  <NotaryCard
                    title="Upcoming Notarization"
                    value={secondClosestJobDate}
                    icon={ScheduleIcon}
                    color="info.main"
                    tooltip="Time for your upcoming notarization"
                  />
                </Grid>
                <Grid xs={12}>
                  <NotaryJobNotice
                    currentUser={currentUser}
                    closestJobLogs={closestJobLogs}
                    handleSetCompleted={handleSetCompleted}
                    setCompletedLoading={setCompletedLoading}
                    setCompletedText={setCompletedText}
                    closestJobDate={closestJobDate}
                    fiveMinBeforeSession={fiveMinBeforeSession}
                  />
                </Grid>
                <Grid xs={12}>
                  <NotaryPerformanceChart
                    year={year}
                    setYear={setYear}
                    handleChartValues={handleChartValues}
                    chartSeries={handleChartValues()}
                    sx={{ height: "100%" }}
                  />
                </Grid>
              </Grid>
            </Container>
          </Box>
        </>
      )}
    </>
  );
};

export default NotaryDashboard;
