import {
  Grid,
  Stack,
  Typography,
  Box,
  IconButton,
  useMediaQuery,
  useTheme,
  Drawer,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Toolbar,
} from "@mui/material";
import { useState } from "react";
import TextFields from "./TextFields";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import { PencilIcon } from "@heroicons/react/24/outline";
import { DocumentArrowDownIcon } from "@heroicons/react/24/outline";
import Magnifier from "../customComponents/Magnifier";
import Footer from "../layout/Footer";

const EnlargeEditForm = ({
  fromSub,
  setCopyInputs,
  editFromCreate,
  selectedPreName,
  enlargeHeader,
  enlargeNote,
  setOpenDrawer,
  formik,
  type,
  preview,
  previewImg,
  previewLoading,
  pdfImg,
  pdfImg2,
  inputs,
  editLoading,
  errorText,
  disableEdit,
  pdf,
  setSelectedRow,
  setFormDrawerOpen,
  formDrawerOpen,
  handleDownloadNoc,
  licenseType,
  licenseNumber,
  licenseStatus,
  downloadingFileLoading,
  isSuretyRadio,
  setIsSuretyRadio,
  selectedRow,
}) => {
  const [dynamicValues, setDynamicValues] = useState({});
  const theme = useTheme();
  const between900and1200 = useMediaQuery(
    "(min-width:900px) and (max-width:1200px)"
  );

  const handleIMG = () => {
    if (pdfImg2) {
      if (preview) {
        return (
          <>
            <Magnifier src={previewImg[0]} lensSize={400} zoomFactor={4} />
            <Magnifier src={previewImg[1]} lensSize={400} zoomFactor={4} />
          </>
        );
      } else {
        return (
          <>
            <Magnifier src={pdfImg} lensSize={400} zoomFactor={4} />
            <Magnifier src={pdfImg2} lensSize={400} zoomFactor={4} />
          </>
        );
      }
    } else {
      if (preview) {
        return (
          <>
            <Magnifier src={previewImg[0]} lensSize={400} zoomFactor={4} />
          </>
        );
      } else {
        return (
          <>
            <Magnifier src={pdfImg} lensSize={400} zoomFactor={4} />
          </>
        );
      }
    }
  };

  const handleIconButton = () => {
    setSelectedRow(null);
    if (setCopyInputs) {
      setCopyInputs(null);
    }
    setOpenDrawer(false);
  };

  const handleReturnForm = () => {
    return (
      <>
        <Stack spacing={1} mb={2}>
          <Typography
            variant={editFromCreate ? "h6" : "body1"}
            fontWeight="bold"
            sx={{ mb: 0, pb: 0 }}
          >
            The following fields are available for updating:
          </Typography>

          <Typography
            fontWeight="bold"
            color="primary"
            variant="body2"
            sx={{
              m: 0,
              p: 0,
              pb: 0.5,
              lineHeight: "10px",
              marginTop: "0px !important",
              marginBottom: "0px !important",
            }}
          >
            (Click update after adding new information into NOC)
          </Typography>

          <TextFields
            isSuretyRadio={isSuretyRadio}
            setIsSuretyRadio={setIsSuretyRadio}
            editFromCreate={editFromCreate}
            dynamicValues={dynamicValues}
            setDynamicValues={setDynamicValues}
            formik={formik}
            selectedPreName={selectedPreName}
            type={type}
            inputs={inputs}
          />
        </Stack>
      </>
    );
  };

  const handleCheckButton = () => {
    return (
      <>
        <LoadingButton
          startIcon={<PencilIcon color="#fff" width={20} />}
          loading={editLoading}
          onClick={formik?.submitForm}
          disabled={Boolean(downloadingFileLoading)}
          color="warning"
          type="submit"
          fullWidth
          size={"large"}
          sx={{ mt: 2, mb: 2 }}
          variant="contained"
        >
          UPDATE
        </LoadingButton>
        <LoadingButton
          loading={Boolean(downloadingFileLoading)}
          disabled={!disableEdit || editLoading}
          startIcon={<DocumentArrowDownIcon color="#fff" width={20} />}
          onClick={() => handleDownloadNoc(selectedRow)}
          fullWidth
          size={"large"}
          sx={{ mt: 2, mb: 2, background: "primary" }}
          variant="contained"
        >
          Download
        </LoadingButton>
      </>
    );
  };

  return (
    <>
      <Grid
        container
        sx={{ display: "flex", overflow: "hidden", width: "100%" }}
      >
        {/* LEFT SIDE */}
        <Grid item md={6} sx={{ maxHeight: "90vh", overflow: "auto" }}>
          <Box>{handleIMG()}</Box>
        </Grid>

        {/* RIGHT SIDE */}
        <Grid
          item
          md={6}
          sx={{ position: "relative", mb: 1, mt: 2, height: "100vh" }}
        >
          {/* HEADER */}
          <IconButton
            onClick={handleIconButton}
            sx={{ position: "absolute", right: 10 }}
          >
            <CloseIcon />
          </IconButton>
          <Box
            sx={{
              mb: 1.5,
              pb: 1,

              backgroundColor: "#fff",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography color="primary" variant="h4" textAlign="center">
              {selectedPreName} County
            </Typography>
            <Typography variant="body1" textAlign="center">
              {!fromSub ? enlargeHeader : "View Form"}
            </Typography>
            <Grid
              container
              sx={{
                alignItems: "center",
                mt: 2,
                marginBottom: "-2rem",
                backgroundColor: "#f6f6f6",
                p: 2,
                // borderRadius: "1rem",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Grid
                sx={{ ml: "auto", mr: "auto" }}
                item
                lg={6}
                sm={12}
                order={1}
              >
                <Box
                  sx={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography variant="h6">{licenseType}</Typography>
                </Box>
              </Grid>
              <Grid sx={{ ml: "auto", mr: "auto" }} item lg={6} sm={12}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center", // Vertically center the content
                    justifyContent: "center",
                    height: "100%", // Ensure the Box takes up the full height
                  }}
                >
                  <Box sx={{ borderBottom: "5px solid #006400" }}>
                    <Typography
                      variant="h4"
                      sx={{ marginBottom: "-7px", color: "#006400" }}
                    >
                      {licenseNumber}
                    </Typography>
                  </Box>

                  <Typography variant="body2" sx={{ color: "#006400" }}>
                    {licenseStatus === ""
                      ? "Pre-Verified License Number"
                      : "Verified License Number"}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box
            sx={{
              mt: 6,
              height: between900and1200
                ? "calc(100% - 400px)"
                : "calc(100% - 380px)",
              overflow: "auto",
              px: 0.3,
            }}
          >
            {!fromSub ? (
              handleReturnForm()
            ) : (
              <>
                <Box sx={{ position: "relative" }}>
                  <Box
                    component="a"
                    href="https://www.sr360solutions.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      display: "flex",
                      mt: "10vh",
                      mx: "auto",
                      width: "500px",
                      "& img": {
                        width: "100%",
                      },
                    }}
                  >
                    <img alt="SR360solutions" src="/img/logos/Ball-icon.png" />
                  </Box>
                  <Box>
                    <Typography
                      sx={{ position: "absolute", top: 30, left: 280 }}
                      align="center"
                      variant="h3"
                    >
                      Powered by
                    </Typography>
                  </Box>
                </Box>
              </>
            )}
          </Box>
          {!fromSub && (
            <Toolbar
              sx={{
                px: 0,
                py: 0.5,
                position: "fixed",
                bottom: 88.5,
                left: 0,
                right: 0,
                opacity: 1,
              }}
            >
              <Grid container>
                <Grid item xs={6}></Grid>
                <Grid item xs={6}>
                  <Box sx={{ display: "flex", gap: 2 }}>
                    {handleCheckButton()}
                  </Box>
                </Grid>
              </Grid>
            </Toolbar>
          )}
        </Grid>
      </Grid>
      <Footer height="60px" />

      <Drawer
        PaperProps={{}}
        anchor="right"
        open={formDrawerOpen}
        onClose={() => setFormDrawerOpen(false)}
      >
        <Stack sx={{ width: "auto", ml: "auto" }}>
          <IconButton onClick={() => setFormDrawerOpen(false)}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Grid item md={6} sx={{ maxHeight: "100%", order: 2 }}>
          <Box>{handleIMG()}</Box>
        </Grid>
      </Drawer>
    </>
  );
};

export default EnlargeEditForm;
