import React, { useEffect, useState } from "react";
import {
  AppBar,
  Box,
  Dialog,
  Grow,
  IconButton,
  Step,
  StepLabel,
  Stepper,
  Toolbar,
  Typography,
  StepConnector,
  FormHelperText,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Visibility, VisibilityOffOutlined } from "@mui/icons-material";
import { useFormikContext } from "formik";
import ConfirmModal from "../popover/ConfirmModal";
import { LoadingButton } from "@mui/lab";

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Grow
      ref={ref}
      {...props}
      timeout={{
        enter: 500, // Duration for the enter animation in milliseconds
        exit: 300, // Duration for the exit animation
      }}
      style={{
        transformOrigin: "center",
        transitionDelay: props.in ? "0ms" : "200ms", // Delay the exit animation
      }}
    />
  );
});

const CustomStepConnector = (props) => (
  <StepConnector
    {...props}
    sx={{
      alternativeLabel: {
        top: 22,
      },
      "& .MuiStepConnector-line": {
        borderColor: "#eaeaf0",
        borderTopWidth: 3,
        borderRadius: 1,
      },
      "&.Mui-active .MuiStepConnector-line": {
        borderColor: "#185A83",
      },
      "&.Mui-completed .MuiStepConnector-line": {
        borderColor: "#185A83",
      },
    }}
  />
);

const MobileUploader = (props) => {
  const { values, setFieldValue, resetForm, errors, handleSubmit } =
    useFormikContext();

  const confirmedHandleClose = () => {
    props?.handleMobileUploaderClose();
    resetForm({ values: props?.initialValues });
    props?.setExitMobileUploaderModal(false);
    props?.setStepNumber(0);
  };

  useEffect(() => {
    if (
      props?.tabletSize &&
      values.previewUrl &&
      !props?.mobileUploaderOpen &&
      (values.files || values.fileName)
    ) {
      props?.setMobileUploaderOpen(true);
    } else if (
      props?.tabletSize &&
      !values.previewUrl &&
      props?.mobileUploaderOpen &&
      (!values.files || !values.fileName)
    ) {
      props?.setMobileUploaderOpen(false);
    }
  }, [props?.tabletSize, values.files, values.fileName]);

  return (
    <Dialog
      fullScreen={props?.tabletSize}
      open={props?.mobileUploaderOpen}
      onClose={() => {
        props?.handleMobileUploaderClose();
        resetForm();
      }}
      TransitionComponent={Transition}
    >
      <AppBar
        sx={{
          position: "relative",
          backgroundColor: "#333333",
          pl: props?.mobileSize ? 2 : undefined,
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              if (props?.submitLoading) {
                return;
              }
              props?.setExitMobileUploaderModal(true);
            }}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Document Uploader
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        style={{
          height: props?.mobileSize
            ? "calc(100% - 180px)"
            : "calc(100% - 190px)",
          overflow: "auto",
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginBottom: props?.tabletSize ? "0rem" : "1.5rem",
              marginTop: props?.tabletSize ? "1rem" : undefined,
            }}
          >
            <Stepper
              activeStep={props?.stepNumber}
              connector={<CustomStepConnector />}
              alternativeLabel
            >
              {props?.steps.map((currentStep, id) => {
                const label = currentStep.stepName;
                return (
                  <Step key={id} sx={{ width: "5rem" }}>
                    <StepLabel
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    ></StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Box>
          <Typography
            sx={{
              mt: 1,
              mb: 1,
              fontWeight: "bold",
            }}
            variant="h6"
            align="center"
          >
            {props?.steps[props?.stepNumber].stepName}
          </Typography>
        </Box>

        {props?.StepComponent && props?.uploadingEnabled ? (
          <props.StepComponent
            contractors={props?.contractors}
            paymentCards={props?.paymentCards}
            currentIndex={props?.currentIndex}
            setCurrentIndex={props?.setCurrentIndex}
            prices={props?.prices}
            submitError={props?.submitError}
            mobileSize={props?.mobileSize}
            tabletSize={props?.tabletSize}
            setMobileUploaderOpen={props?.setMobileUploaderOpen}
            setUploadError={props?.setUploadError}
            uploadError={props?.uploadError}
            iphone12Height={props?.iphone12Height}
            below1450={props?.below1450}
          />
        ) : (
          <Box p={1}>
            <img
              src={values.previewUrl}
              style={{ width: "100%", height: "auto" }}
              draggable="false"
            />
          </Box>
        )}
      </Box>
      {/* BUTTON NAVIGATION AREA */}
      {props?.uploadingEnabled && (
        <Box
          sx={{
            boxShadow: "0 -4px 4px 0 #ddd",
            display: "flex",
            justifyContent: "center",
            gap: "10px",
            mt: 1.5,
            pt: 1,
          }}
        >
          <Button
            size="small"
            disabled={props?.stepNumber === 0 || props?.submitLoading === true}
            onClick={() => {
              props?.handleGoBack(values);
            }}
            sx={{
              minWidth: "6.9rem",
              display: props?.stepNumber === 0 ? "none" : "inline-flex",
            }}
            variant="contained"
            color="warning"
            startIcon={<ArrowBackIosIcon />}
          >
            Previous
          </Button>
          {props?.isLastStep ? (
            <>
              <LoadingButton
                size="small"
                loading={props?.submitLoading}
                onClick={handleSubmit}
                sx={{
                  minWidth: "5rem",
                }}
                variant="contained"
                color="success"
              >
                Pay and Finalize
              </LoadingButton>
            </>
          ) : (
            <>
              <Button
                size="small"
                onClick={handleSubmit}
                sx={{
                  minWidth: "7rem",
                  display:
                    (props?.stepNumber === 0 && !values.file) ||
                    values.county === "" ||
                    values.documentType === "" ||
                    values.reason === "" ||
                    (props?.stepNumber === 1 &&
                      (values.propertyAddress === "" ||
                        values.contractor === "" ||
                        values.signeeFirst === "" ||
                        values.signeeLast === "" ||
                        values.email === "" ||
                        values.signeePhone === "" ||
                        props?.hasStepErrors(props?.stepNumber, errors))) // Update this condition to use hasStepErrors function
                      ? "none"
                      : "inline-flex",
                }}
                variant="contained"
                endIcon={<ArrowForwardIosIcon />}
              >
                Next
              </Button>
            </>
          )}
        </Box>
      )}
      {/* ************************* */}

      {/* FOOTER */}
      <Toolbar
        sx={{
          px: 0,
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          opacity: 0.7,
          backgroundColor: "#333333",
          px: "1rem",
        }}
      >
        <IconButton
          variant="contained"
          onClick={props?.handleUploadingEnabled}
          sx={{ flex: 1 }}
        >
          <Box>
            <AssignmentTurnedInIcon
              sx={{ color: "#fff", fontSize: "1.6rem" }}
            />
            {!props?.miniSize && (
              <FormHelperText
                sx={{ fontWeight: "bold", color: "#fff", mt: -1, px: "5px" }}
              >
                Uploading Progress
              </FormHelperText>
            )}

            <Box
              sx={{
                borderBottom: props?.uploadingEnabled
                  ? "3px solid #757575"
                  : "3px solid transparent",
                mt: "-4px",
              }}
            ></Box>
          </Box>
        </IconButton>

        <IconButton
          variant="contained"
          onClick={props?.handlePreviewEnabled}
          sx={{ flex: 1 }}
        >
          <Box>
            {props?.previewEnabled ? (
              <Visibility width={25} sx={{ color: "#fff" }} />
            ) : (
              <VisibilityOffOutlined width={25} sx={{ color: "#fff" }} />
            )}
            {!props?.miniSize && (
              <FormHelperText
                sx={{ fontWeight: "bold", color: "#fff", mt: -1, px: "5px" }}
              >
                Form Preview
              </FormHelperText>
            )}

            <Box
              sx={{
                borderBottom: props?.previewEnabled
                  ? "3px solid #757575"
                  : "3px solid transparent",
                mt: "-4px",
              }}
            ></Box>
          </Box>
        </IconButton>
      </Toolbar>
      <ConfirmModal
        open={props?.exitMobileUploaderModal}
        setOpen={props?.setExitMobileUploaderModal}
        handleClose={confirmedHandleClose}
        title="Do you really want to leave?"
        subTitle="You will lose all the progress you entered!"
        btnText1="Leave"
        btnText2="Stay"
      />
    </Dialog>
  );
};

export default MobileUploader;
