import React, { useEffect, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import * as Yup from "yup";
import { parseLocation } from "parse-address";
import zipcodes from "zipcodes";
import { loadStripe } from "@stripe/stripe-js";
import { subYears, isAfter } from "date-fns";
import validator from "validator";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { convertObjectToDayjs } from "../../utils/dateConverts";

import {
  Modal,
  Box,
  Typography,
  IconButton,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Switch,
  Grid,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Alert,
  Snackbar,
  Checkbox,
  Button,
  useMediaQuery,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DatePicker } from "@mui/x-date-pickers";
import { useFormik } from "formik";
import CreditCardDisplay from "../noc/CreditCardDisplay";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { updatePermitPackage } from "../../store/actions/dataActions";

function StartNotarizationModal({
  open,
  setOpen,
  selectedRow,
  paymentCards,
  setReviewDocModalOpen,
  setSnackOpen,
  setRonScheduleOpen,
  contractorSigning,
  ownerSigning,
}) {
  const currentSession = useSelector((state) => state.sessions.currentSession);
  const currentUser = useSelector((state) => state[currentSession].user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [signingOrder, setSigningOrder] = useState("contractor");
  const [autoStartRecording, setAutoStartRecording] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [notaryPaymentLoading, setNotaryPaymentLoading] = useState(false);
  const [isAcknowledged, setIsAcknowledged] = useState(false);
  const [paymentErrorText, setPaymentErrorText] = useState("");
  const [saveCityZip, setSaveCityZip] = useState(false);
  const [instructionText, setInstructionText] = useState(
    "The contractor is required to sign & notarize some documents as well. Choose who signs first, and the second signer will automatically get an invitation after the first completes the process."
  );
  const [submitError, setSubmitError] = useState("");
  useEffect(() => {
    if (
      contractorSigning?.notarizationNeeded &&
      instructionText !==
        "The contractor is required to sign & notarize some documents as well. Choose who signs first, and the second signer will automatically get an invitation after the first completes the process."
    ) {
      setInstructionText(
        "The contractor is required to sign & notarize some documents as well. Choose who signs first, and the second signer will automatically get an invitation after the first completes the process."
      );
    } else if (
      !contractorSigning?.notarizationNeeded &&
      contractorSigning?.signingNeeded &&
      instructionText !==
        "Contractors are only required to sign documents. Upon providing some basic information, you can begin signing your documents. We will then send an automated email to the property owner/agent inviting them to schedule a Remote Online Notarization."
    ) {
      setInstructionText(
        "Contractors are only required to sign documents. Upon providing some basic information, you can begin signing your documents. We will then send an automated email to the property owner/agent inviting them to schedule a Remote Online Notarization."
      );
    } else if (
      !contractorSigning?.notarizationNeeded &&
      !contractorSigning?.signingNeeded &&
      instructionText !==
        "Property owners or agents are only required to complete the notarization. After providing some basic information, we will send an automated email inviting them to schedule a Remote Online Notarization."
    ) {
      setInstructionText(
        "Property owners or agents are only required to complete the notarization. After providing some basic information, we will send an automated email inviting them to schedule a Remote Online Notarization."
      );
    }
  }, [contractorSigning, selectedRow]);

  // Parse the contractor's address
  const parsedAddress = selectedRow?.contractor?.mainAddress
    ? parseLocation(selectedRow.contractor.mainAddress)
    : {};

  // Extract city and state using ZIP code if available
  const zipInfo = parsedAddress.zip ? zipcodes.lookup(parsedAddress.zip) : {};

  //? CORRECT //////////////////////////////////////////////////////////
  useEffect(() => {
    if ((!parsedAddress?.city && !zipInfo?.city) || !parsedAddress?.zip) {
      setSaveCityZip(true);
    }
  }, [parsedAddress, zipInfo]);
  //? //////////////////////////////////////////////////////////

  //! WRONG /////////////////////////////////////////////////////////////////
  // if ((!parsedAddress?.city && !zipInfo?.city) || !parsedAddress?.zip) {
  //   setSaveCityZip(true);
  // }
  //! ///////////////////////////////////////////////////////////////////////

  function convertDayjsToObject(dayjsObj) {
    return {
      day_of_month: dayjsObj.date(),
      month: dayjsObj.month() + 1,
      year: dayjsObj.year(),
      time: dayjsObj.format("HH:mm"),
    };
  }

  useEffect(() => {
    if (submitError) {
      const interval = setTimeout(() => {
        setSubmitError("");
      }, 4000);

      return () => clearTimeout(interval);
    }
  }, [submitError]);

  const formatMoneyValue = (value) => {
    if (!value) {
      return "";
    }
    // Remove any non-numeric characters except the decimal point
    value = value.replace(/[^\d.]/g, "");

    // Split the input into integer and decimal parts
    let [integer, decimal] = value.split(".");

    // Limit the integer part to 10 digits
    integer = integer ? integer.slice(0, 10) : "";

    // Format the integer part with commas
    let formattedInteger = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Handle cases for decimal parts
    if (decimal === undefined) {
      // If there's no decimal part, ensure it ends with ".00"
      formattedInteger += ".00";
    } else if (decimal.length === 0) {
      // If the decimal part is empty, assume ".00"
      formattedInteger += ".00";
    } else if (decimal.length === 1) {
      // If there's only one decimal digit, add a trailing "0"
      formattedInteger += `.${decimal}0`;
    } else {
      // Otherwise, limit the decimal part to two digits
      formattedInteger += `.${decimal.slice(0, 2)}`;
    }

    // Add the dollar sign
    return `$${formattedInteger}`;
  };

  const refs = {
    oName: useRef(null),
    cFirst: useRef(null),
    cLast: useRef(null),
    cAddress: useRef(null),
    cCity: useRef(null),
    cZip: useRef(null),
    cEmail: useRef(null),
    oEmail: useRef(null),
    cDOB: useRef(null),
    cMobile: useRef(null),
    oMobile: useRef(null),
    cIdType: useRef(null),
  };

  const scrollToFirstError = (errors) => {
    const fields = Object.keys(errors);
    // Find the first field with an error and scroll to it
    for (const field of fields) {
      if (refs[field]?.current) {
        refs[field].current.scrollIntoView({
          behavior: "smooth",
          block: "center", // Ensure the field scrolls to the center of the modal
          inline: "nearest",
        });
        break;
      }
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      cFirst: selectedRow?.contractor?.firstName,
      cMiddle: selectedRow?.contractor?.middleName,
      cLast: selectedRow?.contractor?.lastName,
      cAddress: parsedAddress.number + " " + parsedAddress.street || "",
      cCity: parsedAddress.city || zipInfo.city || "",
      cState: parsedAddress.state || zipInfo.state || "",
      cZip: parsedAddress.zip || "",
      cDOB: convertObjectToDayjs(selectedRow?.contractor?.dob) || "",
      cEmail: selectedRow?.contractor?.email,
      cMobile: selectedRow?.contractor?.mobile,
      cIdType: selectedRow?.contractor?.idType || "",
      oName: selectedRow?.property_owner?.name,
      oEmail: selectedRow?.property_owner?.email,
      oMobile: selectedRow?.property_owner?.mobile,
    },
    validationSchema: Yup.object({
      oName: Yup.string().required("Full name is required"),
      cFirst: Yup.string().required("First name is required"),
      cLast: Yup.string().required("Last name is required"),
      cAddress: contractorSigning?.notarizationNeeded
        ? Yup.string().required("Address is required")
        : Yup.string(),
      cCity: contractorSigning?.notarizationNeeded
        ? Yup.string().required("City is required")
        : Yup.string(),
      cZip: contractorSigning?.notarizationNeeded
        ? Yup.string()
            .required("Zip Code is required")
            .test("zipCode", "Invalid zip code", function (value) {
              return validator.isPostalCode(value, "US");
            })
        : Yup.string(),
      cEmail: Yup.string()
        .required("Email is required")
        .email("Must be a valid email"),
      oEmail: Yup.string()
        .required("Email is required")
        .email("Must be a valid email"),
      cDOB: contractorSigning?.notarizationNeeded
        ? Yup.date()
            .required("Date of Birth is required")
            .max(subYears(new Date(), 18), "You must be at least 18 years old")
            .typeError("Please enter a valid Date of Birth")
        : Yup.date(),
      cMobile: Yup.string()
        .required("Mobile number is required")
        .test("PhoneTest", "Mobile number is invalid", function (value) {
          const isValidPhoneNumber = validator.isMobilePhone(value);
          return isValidPhoneNumber;
        }),
      oMobile: Yup.string()
        .required("Mobile number is required")
        .test("PhoneTest", "Mobile number is invalid", function (value) {
          const isValidPhoneNumber = validator.isMobilePhone(value);
          return isValidPhoneNumber;
        }),
      cIdType: contractorSigning?.notarizationNeeded
        ? Yup.string().required("Identification document type is required")
        : Yup.string(),
    }),

    onSubmit: async (values, helpers) => {
      try {
        setNotaryPaymentLoading(true);
        const payload = {
          ...values,
          cDOB: convertDayjsToObject(values.cDOB),
          cBusinessName: selectedRow?.contractor?.businessName,
          primaryAddress: selectedRow?.project_address,
          autoStartRecording: autoStartRecording,
          isAcknowledged: isAcknowledged,
          signingOrder: signingOrder,
          paymentCard: paymentCards[activeStep],
          saveCityZip: saveCityZip,
          cLicense: selectedRow?.contractor?.licenseNumber,
          onlyOwner:
            !contractorSigning?.notarizationNeeded &&
            !contractorSigning?.signingNeeded,
        };

        if (payload["onlyOwner"]) {
          payload["signingOrder"] = "owner";
        }
        const resp = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/stripe/process-notary-payment`,
          {
            paymentCard: paymentCards[activeStep]?.id,
            task_id: selectedRow?.task_id,
            acknowledged: isAcknowledged,
            currentSession: currentSession,
          },
          { withCredentials: true }
        );

        const stripe = await loadStripe(process.env.REACT_APP_STRIPE);
        const result = await stripe.confirmCardPayment(resp.data.data);
        if (result.error) {
          setPaymentErrorText(result.error.message);
        } else {
          if (
            result.paymentIntent.status === "succeeded" &&
            resp.data.valid === true
          ) {
            const paymentIntent = result.paymentIntent;
            const resp = await axios.post(
              `${process.env.REACT_APP_BASE_URL}/nocApi/stripe/finish-notary-payment`,
              {
                paymentIntent,
                task_id: selectedRow?.task_id,
                currentSession: currentSession,
                payload,
              },
              { withCredentials: true }
            );
            if (resp?.data?.success) {
              await dispatch(updatePermitPackage(resp?.data?.data));
              setSnackOpen(true);
              setOpen(false);
              if (!resp?.data?.onlyOwner) {
                setRonScheduleOpen(true);
              }
              setIsAcknowledged(false);
              helpers?.resetForm();
            }
          }
        }
      } catch (error) {
      } finally {
        setNotaryPaymentLoading(false);
      }
    },
  });

  return (
    <Modal open={open} sx={{ overflow: "auto" }}>
      <Box
        sx={{
          overflowY: "none",
          position: "relative",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxHeight: "90%",
          maxWidth: "70rem",
          bgcolor: "background.paper",
          borderRadius: "18px",
          boxShadow: 24,
          px: 4,
          py: 2,
          animation: "fadeIn 0.5s ease-in-out",
        }}
      >
        <Typography
          id="modal-modal-title"
          variant="h4"
          align="center"
          component="h2"
          color="primary"
        >
          Welcome in the Notarization & Signing Portal
        </Typography>
        <Box sx={{ position: "absolute", right: 10, top: 10 }}>
          <IconButton
            disabled={notaryPaymentLoading}
            onClick={() => {
              setOpen(false);
              formik.resetForm();
              setIsAcknowledged(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography
          color="primary"
          align="center"
          id="modal-modal-description"
          sx={{ fontWeight: "bold", mb: 0 }}
        >
          Notarizing / Signing your documents has never been so easy
        </Typography>
        {/* Auto-Start Recording Option */}
        <Box sx={{ mb: 1, display: "flex", justifyContent: "center" }}>
          <FormControlLabel
            // disabled={notaryPaymentLoading}
            disabled
            control={
              <Switch
                checked={autoStartRecording}
                onChange={(e) => setAutoStartRecording(e.target.checked)}
              />
            }
            label="Automatically start recording process after notarization"
          />
        </Box>
        <Box
          sx={{
            maxHeight: "35.3rem", // Adjust the max height as needed
            overflowY: "auto",
            height: "calc(90vh - 350px)",
            border: "2px solid #d3d3d3",
            mb: 9,
            p: 2,
            borderRadius: "18px",
            boxShadow: "3px 4px 12px rgba(0, 0, 0, 0.3)", // Added box shadow
          }}
        >
          {/* Signing Order Selection */}
          <Box sx={{ mb: 1, borderBottom: "3px solid #f3f3f3" }}>
            <Typography
              align="center"
              variant="body2"
              color="primary"
              fontWeight="bold"
              mb={!contractorSigning?.notarizationNeeded ? 1 : undefined}
            >
              {instructionText}
            </Typography>
            {contractorSigning?.notarizationNeeded && (
              <Box sx={{ display: "flex", justifyContent: "center", mt: 1 }}>
                <FormControl
                  disabled
                  component="fieldset"
                  // disabled={notaryPaymentLoading}
                >
                  <FormLabel
                    component="legend"
                    sx={{ fontWeight: "bold", mb: -1, color: "text.secondary" }}
                    focused={false}
                  >
                    Select Signing Order
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-label="signing-order"
                    name="signing-order"
                    value={signingOrder}
                    onChange={(e) => setSigningOrder(e.target.value)}
                    sx={{ justifyContent: "center" }}
                  >
                    <FormControlLabel
                      value="contractor"
                      control={<Radio />}
                      label="Contractor First"
                    />
                    <FormControlLabel
                      value="owner"
                      control={<Radio />}
                      label="Owner/Agent First"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            )}
          </Box>

          <Typography align="center" color="primary" variant="h6">
            {!contractorSigning?.notarizationNeeded &&
            !contractorSigning?.signingNeeded
              ? "Overview of the signer"
              : "Overview of the signers"}
          </Typography>
          <Grid container spacing={1} mt={2} mb={2}>
            {contractorSigning?.notarizationNeeded ||
            contractorSigning?.signingNeeded ? (
              <Grid item sm={12} md={6} pr={1}>
                <Typography fontWeight="bold" align="center" mb={1}>
                  Contractor
                </Typography>
                <Box sx={{ display: "flex", gap: 1, mb: 1 }}>
                  <TextField
                    ref={refs.cFirst}
                    disabled={notaryPaymentLoading}
                    size={"small"}
                    error={!!(formik.touched.cFirst && formik.errors.cFirst)}
                    fullWidth
                    helperText={formik.touched.cFirst && formik.errors.cFirst}
                    name="cFirst"
                    value={formik.values.cFirst}
                    label="First Name"
                    variant="filled"
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                  />
                  <TextField
                    disabled={notaryPaymentLoading}
                    size={"small"}
                    error={!!(formik.touched.cMiddle && formik.errors.cMiddle)}
                    fullWidth
                    helperText={formik.touched.cMiddle && formik.errors.cMiddle}
                    name="cMiddle"
                    value={formik.values.cMiddle}
                    label="Middle Name"
                    variant="filled"
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                  />
                  <TextField
                    ref={refs.cLast}
                    disabled={notaryPaymentLoading}
                    size={"small"}
                    error={!!(formik.touched.cLast && formik.errors.cLast)}
                    fullWidth
                    helperText={formik.touched.cLast && formik.errors.cLast}
                    name="cLast"
                    value={formik.values.cLast}
                    label="Last Name"
                    variant="filled"
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                  />
                </Box>
                {contractorSigning?.notarizationNeeded && (
                  <Box sx={{ display: "flex", gap: 1, mb: 1 }}>
                    <TextField
                      ref={refs.cAddress}
                      disabled={notaryPaymentLoading}
                      size={"small"}
                      error={
                        !!(formik.touched.cAddress && formik.errors.cAddress)
                      }
                      fullWidth
                      helperText={
                        formik.touched.cAddress && formik.errors.cAddress
                      }
                      name="cAddress"
                      value={formik.values.cAddress}
                      label="Address"
                      variant="filled"
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                    />
                    <TextField
                      ref={refs.cCity}
                      disabled={notaryPaymentLoading}
                      size={"small"}
                      error={!!(formik.touched.cCity && formik.errors.cCity)}
                      fullWidth
                      helperText={formik.touched.cCity && formik.errors.cCity}
                      name="cCity"
                      value={formik.values.cCity}
                      label="City"
                      variant="filled"
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                    />
                    <TextField
                      ref={refs.cZip}
                      disabled={notaryPaymentLoading}
                      size={"small"}
                      error={!!(formik.touched.cZip && formik.errors.cZip)}
                      fullWidth
                      helperText={formik.touched.cZip && formik.errors.cZip}
                      name="cZip"
                      value={formik.values.cZip}
                      label="ZIP"
                      variant="filled"
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                    />
                  </Box>
                )}

                <Box sx={{ display: "flex", gap: 1, mb: 1 }}>
                  <TextField
                    ref={refs.cEmail}
                    disabled={notaryPaymentLoading}
                    size={"small"}
                    error={!!(formik.touched.cEmail && formik.errors.cEmail)}
                    fullWidth
                    helperText={formik.touched.cEmail && formik.errors.cEmail}
                    name="cEmail"
                    value={formik.values.cEmail}
                    label="Email"
                    variant="filled"
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                  />
                  <TextField
                    ref={refs.cMobile}
                    disabled={notaryPaymentLoading}
                    size={"small"}
                    error={!!(formik.touched.cMobile && formik.errors.cMobile)}
                    fullWidth
                    helperText={formik.touched.cMobile && formik.errors.cMobile}
                    name="cMobile"
                    value={formik.values.cMobile}
                    label="Mobile"
                    variant="filled"
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                  />
                </Box>
                {contractorSigning?.notarizationNeeded && (
                  <Box sx={{ display: "flex", gap: 1, mb: 1 }}>
                    <FormControl
                      disabled={notaryPaymentLoading}
                      size="small"
                      variant="filled"
                      fullWidth
                      error={Boolean(
                        formik.touched.cIdType && formik.errors.cIdType
                      )}
                    >
                      <InputLabel
                        sx={
                          formik.touched.cIdType && formik.errors.cIdType
                            ? { color: "#f04438" }
                            : { color: "auto" }
                        }
                        id="cIdType-label"
                      >
                        Identification Document Type
                      </InputLabel>
                      <Select
                        ref={refs.cIdType}
                        error={Boolean(
                          formik.touched.cIdType && formik.errors.cIdType
                        )}
                        labelId="id-label"
                        fullWidth
                        name="cIdType"
                        value={formik?.values?.cIdType}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        {[
                          "State Identification Card",
                          "Driver's License",
                          "US Passport",
                          "International Passport",
                        ].map((item, index) => (
                          <MenuItem key={`${item}-${index}`} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText sx={{ color: "#f04438" }}>
                        {formik.touched.cIdType && formik.errors.cIdType}
                      </FormHelperText>
                    </FormControl>
                    <DatePicker
                      ref={refs.cDOB}
                      disabled={notaryPaymentLoading}
                      disableFuture
                      sx={{ width: "100%" }}
                      label="Date of Birth"
                      value={formik.values.cDOB}
                      onChange={(value) =>
                        formik.setFieldValue("cDOB", value, true)
                      }
                      slotProps={{
                        textField: {
                          size: "small",
                          variant: "filled",
                          onBlur: formik.handleBlur("cDOB"),
                          error: Boolean(
                            formik.touched.cDOB && formik.errors.cDOB
                          ),
                          helperText: formik.touched.cDOB && formik.errors.cDOB,
                        },
                      }}
                    />
                  </Box>
                )}
              </Grid>
            ) : (
              <></>
            )}

            <Grid
              item
              sm={12}
              md={6}
              sx={{
                pr:
                  !contractorSigning?.notarizationNeeded &&
                  !contractorSigning?.signingNeeded
                    ? 1
                    : undefined,
                borderLeft:
                  !contractorSigning?.notarizationNeeded &&
                  !contractorSigning?.signingNeeded
                    ? undefined
                    : "2px solid #d3d3d3",
                borderRight:
                  !contractorSigning?.notarizationNeeded &&
                  !contractorSigning?.signingNeeded
                    ? "2px solid #d3d3d3"
                    : undefined,
              }}
            >
              <Typography fontWeight="bold" align="center" mb={1}>
                Owner/Agent
              </Typography>
              <Box sx={{ display: "flex", gap: 1, mb: 1 }}>
                <TextField
                  ref={refs.oName}
                  disabled={notaryPaymentLoading}
                  size={"small"}
                  error={!!(formik.touched.oName && formik.errors.oName)}
                  fullWidth
                  helperText={formik.touched.oName && formik.errors.oName}
                  name="oName"
                  value={formik.values.oName}
                  label="Full Name / Business Name"
                  variant="filled"
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                />
              </Box>
              <Box sx={{ display: "flex", gap: 1, mb: 1 }}>
                <TextField
                  ref={refs.oEmail}
                  disabled={notaryPaymentLoading}
                  size={"small"}
                  error={!!(formik.touched.oEmail && formik.errors.oEmail)}
                  fullWidth
                  helperText={formik.touched.oEmail && formik.errors.oEmail}
                  name="oEmail"
                  value={formik.values.oEmail}
                  label="Email"
                  variant="filled"
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                />
                <TextField
                  ref={refs.oMobile}
                  disabled={notaryPaymentLoading}
                  size={"small"}
                  error={!!(formik.touched.oMobile && formik.errors.oMobile)}
                  fullWidth
                  helperText={formik.touched.oMobile && formik.errors.oMobile}
                  name="oMobile"
                  value={formik.values.oMobile}
                  label="Mobile"
                  variant="filled"
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                />
              </Box>
              {!contractorSigning?.notarizationNeeded &&
              !contractorSigning?.signingNeeded ? (
                <></>
              ) : (
                <Typography
                  variant="body2"
                  color="primary"
                  fontWeight="bold"
                  align="center"
                >
                  All other information will be gathered from the signer. Please
                  ensure that the contact information you enter is correct.
                </Typography>
              )}
            </Grid>
            {!contractorSigning?.notarizationNeeded &&
              !contractorSigning?.signingNeeded && (
                <Grid item sm={12} md={6}>
                  <Box
                    sx={{
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="body2"
                      color="primary"
                      fontWeight="bold"
                      align="center"
                    >
                      All other information will be gathered from the signer.
                      Please ensure that the contact information you enter is
                      correct.
                    </Typography>
                  </Box>
                </Grid>
              )}
          </Grid>
          <Box sx={{ mb: 1, borderBottom: "3px solid #f3f3f3" }}></Box>

          <Typography align="center" color="primary" variant="h6" mb={4}>
            Notarization & Recording Overview
          </Typography>

          {/* Overview and Payment Section */}
          <Grid container spacing={1}>
            <Grid item sm={12} md={6} pr={1}>
              {/* PAYMENT CARDS */}
              {paymentCards?.length === 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    align="center"
                    variant="body2"
                    fontWeight="bold"
                    color="error"
                    px={1}
                  >
                    You do not have any payment method saved. Please click the
                    button below to navigate to the payments page and set up a
                    payment method.
                  </Typography>
                  <Button
                    sx={{ mt: 1 }}
                    onClick={() => navigate("/payment")}
                    variant="contained"
                  >
                    Set up card payment
                  </Button>
                </Box>
              ) : (
                <>
                  <Typography align="center" variant="h6" mb={-1}>
                    Select Payment Card
                  </Typography>
                  <Box sx={{ position: "relative" }}>
                    <Box sx={{ cursor: "default" }}>
                      <CreditCardDisplay
                        cards={paymentCards}
                        activeStep={activeStep}
                        setActiveStep={setActiveStep}
                      />
                    </Box>

                    <Box
                      sx={{
                        position: "absolute",
                        top: "35%",
                        left: 0,
                        display:
                          paymentCards.length === 0 || activeStep === 0
                            ? "none"
                            : undefined,
                      }}
                    >
                      <IconButton
                        onClick={() => {
                          if (activeStep > 0) {
                            setActiveStep(activeStep - 1);
                          }
                        }}
                      >
                        <ArrowBackIcon />
                      </IconButton>
                    </Box>
                    <Box
                      sx={{
                        position: "absolute",
                        top: "35%",
                        right: 0,
                        display:
                          paymentCards.length - 1 === activeStep ||
                          paymentCards.length === 0
                            ? "none"
                            : undefined,
                      }}
                    >
                      <IconButton
                        onClick={() => {
                          if (paymentCards.length - 1 !== activeStep) {
                            setActiveStep(activeStep + 1);
                          }
                        }}
                      >
                        <ArrowForwardIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </>
              )}
            </Grid>
            <Grid item sm={12} md={6} sx={{ borderLeft: "2px solid #d3d3d3" }}>
              <Typography
                variant="h6"
                align="center"
                sx={{ fontWeight: "bold", mb: 1 }}
              >
                Total Cost
              </Typography>
              <Box
                sx={{
                  minHeight: "86px",
                  ml: 2,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  p: 2,
                  borderRadius: "8px",
                  bgcolor: "background.paper",
                  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Modern box shadow
                }}
              >
                <Typography
                  variant="h4"
                  sx={{ fontWeight: "bold", color: "primary.main" }}
                >
                  {formatMoneyValue(currentUser?.service_tiers?.notary_record)}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: "text.secondary", mt: 1 }}
                >
                  This is the total amount for Notarization & Recording for all
                  documents
                </Typography>
              </Box>
            </Grid>
          </Grid>
          {/* Declaration Section */}
          <Box sx={{ mt: 2, ml: 2, mr: 2 }}>
            <FormControlLabel
              disabled={notaryPaymentLoading}
              control={
                <Checkbox
                  color="primary"
                  checked={isAcknowledged}
                  onChange={(event) => setIsAcknowledged(event.target.checked)}
                />
              }
              label={
                <Typography variant="body2" sx={{ color: "text.primary" }}>
                  I acknowledge that I have reviewed the forms I created, and
                  understand that once notarization begins, no further
                  modifications can be made to the documents.
                </Typography>
              }
            />
            <Typography
              variant="body2"
              sx={{
                color: "primary.main",
                mt: 1,
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => {
                setReviewDocModalOpen(true);
              }}
            >
              Double-check your documents here
            </Typography>
          </Box>
        </Box>

        {/* Submit Button */}
        <Box
          sx={{
            borderBottomLeftRadius: "18px",
            borderBottomRightRadius: "18px",
            backgroundColor: "#fff",
            position: "fixed",
            bottom: -20,
            left: 0,
            width: "100%",
            // borderTop: "3px solid #d3d3d3",
            py: 1,
            animation: "slideUp 0.5s ease-in-out",
          }}
        >
          <Typography color="error" mb={1} fontWeight="bold" align="center">
            {submitError}
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center", pb: 1 }}>
            <LoadingButton
              disabled={!isAcknowledged}
              onClick={async () => {
                const errors = await formik?.validateForm();

                if (errors && Object.keys(errors).length > 0) {
                  scrollToFirstError(errors);
                  setSubmitError(
                    "Some fields are incomplete. Please review the form carefully before proceeding."
                  );
                }
                formik.handleSubmit();
              }}
              loading={notaryPaymentLoading}
              color="success"
              variant="contained"
              sx={{ width: "10rem" }}
            >
              Submit Purchase
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

export default StartNotarizationModal;
