import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  Avatar,
} from "@mui/material";
import { alpha } from "@mui/system";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import RoofingIcon from "@mui/icons-material/Roofing";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import PoolIcon from "@mui/icons-material/Pool";
import PlumbingIcon from "@mui/icons-material/Plumbing";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import ConstructionIcon from "@mui/icons-material/Construction";
import HouseIcon from "@mui/icons-material/House";
import BuildIcon from "@mui/icons-material/Build";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import SettingsIcon from "@mui/icons-material/Settings";
import EngineeringIcon from "@mui/icons-material/Engineering";
import GavelIcon from "@mui/icons-material/Gavel";

const ContractorCard = ({
  //   name,
  //   businessName,
  //   licenseNumber,
  //   address,
  //   email,
  //   phone,
  data,
  mb,
  colorIndex,
  empty,
  showLicenseInput,
  deleteLicenses,
  currLicense,
}) => {
  const getInitials = (fullName) => {
    if (fullName) {
      const nameParts = fullName.split(" ");
      const initials =
        nameParts.length > 1
          ? nameParts[0][0] + nameParts[1][0]
          : nameParts[0][0];

      if (initials) {
        return initials.toUpperCase();
      } else {
        return "N/A";
      }
    } else {
      return "N/A";
    }
  };

  const getRankIcon = (rank) => {
    switch (rank) {
      case "Cert Roofing":
        return <RoofingIcon />;
      case "Cert Specialty":
      case "Reg Specialty":
        return <HomeRepairServiceIcon />;
      case "Cert Pool":
      case "Reg Pool":
        return <PoolIcon />;
      case "Cert Plumbing":
      case "Reg Plumbing":
        return <PlumbingIcon />;
      case "Cert Solar":
        return <WbSunnyIcon />;
      case "Cert Building":
      case "Reg Building":
        return <ConstructionIcon />;
      case "Cert Residential":
      case "Reg Residential":
        return <HouseIcon />;
      case "Cert General":
      case "Reg General":
        return <BuildIcon />;
      case "Cert Under":
      case "Reg Under":
        return <VerifiedUserIcon />;
      case "Cert Pollutant":
        return <SettingsIcon />;
      case "Cert Mechanical":
      case "Reg Mech":
        return <EngineeringIcon />;
      case "Reg Tank Tester":
        return <GavelIcon />;
      case "Reg Air":
      case "Cert Air":
        return <WbSunnyIcon />;
      case "Reg Sheet Metal":
      case "Cert Metal":
        return <BuildIcon />;
      default:
        return <ConstructionIcon />;
    }
  };

  const colors = [
    "#3f51b5", // Indigo
    "#f44336", // Red
    "#4caf50", // Green
    "#ff9800", // Orange
    "#9c27b0", // Purple
    "#673ab7", // Deep Purple
    "#009688", // Teal
    "#795548", // Brown
    "#1e88e5", // Blue
    "#d32f2f", // Dark Red
    "#3f51b5", // Indigo
    "#f44336", // Red
    "#4caf50", // Green
    "#ff9800", // Orange
    "#9c27b0", // Purple
    "#673ab7", // Deep Purple
    "#009688", // Teal
    "#795548", // Brown
    "#1e88e5", // Blue
    "#d32f2f", // Dark Red
  ];

  const getRandomColor = () => {
    if (colorIndex) {
      return colors[colorIndex];
    } else {
      return colors[0];
    }
  };

  const avatarColor = getRandomColor();

  return (
    <Card
      sx={{
        maxWidth: 400,
        margin: "auto",
        mb: mb || undefined,
        boxShadow: 6,
        borderRadius: 3,
        border: "3px solid",
        borderColor:
          currLicense && deleteLicenses?.includes(currLicense)
            ? "red"
            : alpha("#000000", 0.1),
        backgroundColor:
          currLicense && deleteLicenses?.includes(currLicense)
            ? alpha("#FF0000", 0.1)
            : undefined,
        position: "relative",
      }}
    >
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <Avatar
              sx={{
                bgcolor: avatarColor,
                width: 80,
                height: 80,
                margin: "auto",
                mb: 2,
              }}
            >
              <Typography variant="h5">
                {getInitials(data?.firstName + " " + data?.lastName)}
              </Typography>
            </Avatar>
            {empty ? (
              <Box
                className={showLicenseInput && "fadeInPlaceHolder1"}
                sx={{
                  mb: 1,
                  mx: "auto",
                  height: "1.5rem",
                  width: "14rem",
                  backgroundColor: "#f3f3f3",
                  borderRadius: "20px",
                }}
              ></Box>
            ) : data?.middleName ? (
              <Typography variant="h5" component="div">
                {data?.firstName +
                  " " +
                  data?.middleName +
                  " " +
                  data?.lastName}
              </Typography>
            ) : (
              <Typography variant="h5" component="div">
                {data?.firstName + " " + data?.lastName}
              </Typography>
            )}
            {empty ? (
              <Box
                className={showLicenseInput && "fadeInPlaceHolder2"}
                sx={{
                  mx: "auto",
                  mb: 1,
                  height: "1.5rem",
                  width: "14rem",
                  backgroundColor: "#f3f3f3",
                  borderRadius: "20px",
                }}
              ></Box>
            ) : (
              <Typography
                variant="body1"
                fontWeight="bold"
                color="textSecondary"
              >
                {data?.businessName}
              </Typography>
            )}
            {empty ? (
              <Box
                className={showLicenseInput && "fadeInPlaceHolder3"}
                sx={{
                  mx: "auto",
                  mb: 1,
                  height: "1.5rem",
                  width: "10rem",
                  backgroundColor: "#f3f3f3",
                  borderRadius: "20px",
                }}
              ></Box>
            ) : (
              <Typography
                variant="body1"
                fontWeight="bold"
                color="textSecondary"
                sx={{ mt: 1 }}
              >
                {data?.licenseType}
              </Typography>
            )}
            {empty ? (
              <Box
                className={showLicenseInput && "fadeInPlaceHolder4"}
                sx={{
                  mx: "auto",
                  height: "1.5rem",
                  width: "10rem",
                  backgroundColor: "#f3f3f3",
                  borderRadius: "20px",
                }}
              ></Box>
            ) : (
              <Typography variant="h6" color="textSecondary" sx={{ mt: 0 }}>
                {data?.licenseNumber}
              </Typography>
            )}
            {data?.status && (
              <Box
                sx={{
                  position: "absolute",
                  top: 16,
                  left: 16,
                  borderRadius: 1,
                  padding: "0.5rem",
                }}
              >
                {/* <RoofingIcon sx={{ mr: 1 }} /> */}
                <Typography
                  variant="h6"
                  color={
                    currLicense && deleteLicenses?.includes(currLicense)
                      ? "red"
                      : "#4caf50"
                  }
                >
                  {currLicense && deleteLicenses?.includes(currLicense)
                    ? "Removed"
                    : data?.status}
                </Typography>
                <Typography variant="body1" fontWeight="bold" color="primary">
                  {data?.expires}
                </Typography>
              </Box>
            )}
            {data?.rank && (
              <Box
                sx={{
                  position: "absolute",
                  top: 16,
                  right: 16,
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: alpha("#000000", 0.1),
                  borderRadius: 1,
                  padding: "0.5rem",
                }}
              >
                {getRankIcon(data?.rank)}
                {/* <RoofingIcon sx={{ mr: 1 }} /> */}
                <Typography variant="body2" color="textSecondary">
                  {data?.rank}
                </Typography>
              </Box>
            )}
          </Grid>
          <Grid item xs={12} sx={{ textAlign: "left", mt: 2 }}>
            <Box
              className={showLicenseInput && "fadeInPlaceHolder5"}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <LocationOnIcon
                sx={{ mr: 1, color: empty ? "#d3d3d3" : undefined }}
              />
              {empty ? (
                <Box
                  className={showLicenseInput && "fadeInPlaceHolder5"}
                  sx={{
                    mb: 1,
                    height: "1.5rem",
                    width: "100%",
                    backgroundColor: "#f3f3f3",
                    borderRadius: "20px",
                  }}
                ></Box>
              ) : (
                <Typography variant="body2" color="textSecondary">
                  {data?.mainAddress}
                </Typography>
              )}
            </Box>
            <Box
              className={showLicenseInput && "fadeInPlaceHolder6"}
              sx={{ display: "flex", alignItems: "center", mb: 1 }}
            >
              <LocationOnIcon sx={{ mr: 1, visibility: "hidden" }} />
              {empty ? (
                <Box
                  className={showLicenseInput && "fadeInPlaceHolder6"}
                  sx={{
                    height: "1.5rem",
                    width: "30%",
                    backgroundColor: "#f3f3f3",
                    borderRadius: "20px",
                  }}
                ></Box>
              ) : (
                <Typography variant="body2" color="textSecondary">
                  {data?.mainAddressCounty}
                </Typography>
              )}
            </Box>
            <Box
              className={showLicenseInput && "fadeInPlaceHolder7"}
              sx={{ display: "flex", alignItems: "center", mb: 1 }}
            >
              <EmailIcon sx={{ mr: 1, color: empty ? "#d3d3d3" : undefined }} />
              {empty ? (
                <Box
                  className={showLicenseInput && "fadeInPlaceHolder7"}
                  sx={{
                    height: "1.5rem",
                    width: "100%",
                    backgroundColor: "#f3f3f3",
                    borderRadius: "20px",
                  }}
                ></Box>
              ) : (
                <Typography variant="body2" color="textSecondary">
                  {data?.email}
                </Typography>
              )}
            </Box>
            <Box
              className={showLicenseInput && "fadeInPlaceHolder8"}
              sx={{ display: "flex", alignItems: "center", mb: 1 }}
            >
              <PhoneIcon sx={{ mr: 1, color: empty ? "#d3d3d3" : undefined }} />
              {empty ? (
                <Box
                  className={showLicenseInput && "fadeInPlaceHolder8"}
                  sx={{
                    mb: 1,
                    height: "1.5rem",
                    width: "100%",
                    backgroundColor: "#f3f3f3",
                    borderRadius: "20px",
                  }}
                ></Box>
              ) : (
                <Typography variant="body2" color="textSecondary">
                  {data?.mobile}
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ContractorCard;
