import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Grid,
  Container,
  Card,
  Typography,
  TextField,
  useMediaQuery,
  CardHeader,
  CardContent,
  Select,
  useTheme,
  InputLabel,
  FormControl,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import axios from "axios";

const NotaryCustomerService = () => {
  const theme = useTheme();
  const currentSession = useSelector((state) => state.sessions.currentSession);
  const issm = useMediaQuery(theme.breakpoints.up("md"));

  const [selected, setSelected] = useState("");
  const [errorText, setErrorText] = useState("");
  const [loading, setLoading] = useState(false);
  const [textField, setTextField] = useState("");
  const [data, setData] = useState([]);
  let count = 0;

  const onSubmit = async () => {
    setLoading(true);
    if (selected === "") {
      setLoading(false);
      return setErrorText("Please select a reason");
    }
    if (textField === "") {
      setLoading(false);
      return setErrorText("Message is required");
    }

    if (textField.length < 60) {
      setLoading(false);
      return setErrorText("Message needs to be at least 60 character");
    }

    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/auth/sendmessage`,
        {
          reason: selected,
          message: textField,
          currentSession: currentSession,
        },
        { withCredentials: true }
      );

      if (resp.data.success) {
        setErrorText(resp.data.message);
        setSelected("");
        setTextField("");
        setLoading(false);
      } else {
        setErrorText(resp.data.message);
        setLoading(false);
      }
    } catch (err) {
      setErrorText("Something went wrong");
      setLoading(false);
    }
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const resp = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/nocApi/data/inquiryReasons`,
          { withCredentials: true }
        );

        setData(resp.data.data.reasons);
      } catch (e) {}
    };

    if (count < 1) {
      getData();
      count += 1;
    }
  }, []);

  useEffect(() => {
    if (errorText !== "") {
      const timer = setTimeout(() => setErrorText(""), 10000);
      return () => clearTimeout(timer);
    }
  }, [errorText]);
  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 1,
          backgroundColor: "#EEF2F6",
          justifyContent: "center",
        }}
      >
        <Container sx={issm ? { width: "60vw" } : { width: "100%" }}>
          <Grid container justifyContent="center">
            <Grid item xs={12} md={12} lg={12} mb={3} mt={3}>
              <Card>
                <CardHeader title={"How Can We Help?"} />
                <CardContent>
                  <Grid container>
                    <Grid item sm={12}>
                      <FormControl fullWidth variant="filled">
                        <InputLabel>Select Reason</InputLabel>
                        <Select
                          label="Select"
                          value={selected}
                          onChange={(e) => setSelected(e.target.value)}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                marginTop: "1.5px",
                                maxHeight: 150, // Set the desired max height for the dropdown menu
                                width: "auto",
                                maxWidth: "100%",
                              },
                            },
                          }}
                          sx={{
                            maxWidth: "100%", // Set the desired max-width for the input element
                            "& .MuiTypography-body2": {
                              whiteSpace: "wrap", // Prevent text from wrapping or shrinking
                            },
                          }}
                        >
                          {data.map(function (item, i) {
                            return (
                              <MenuItem key={i} value={item}>
                                {item}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>

                      <TextField
                        value={textField}
                        onChange={(e) => {
                          setTextField(e.target.value);
                        }}
                        sx={{ width: "100%", mt: 4 }}
                        label="Message"
                        multiline
                        rows={10}
                      />
                      <Typography
                        variant="body1"
                        color="#F2CD00"
                        fontWeight="bold"
                        textAlign="center"
                        sx={{ mt: 2, minHeight: "1.5rem" }}
                      >
                        {errorText}
                      </Typography>
                      <LoadingButton
                        loading={loading}
                        onClick={onSubmit}
                        fullWidth
                        size="large"
                        sx={{
                          mt: 2,
                          backgroundColor: "primary",
                        }}
                        variant="contained"
                      >
                        Send Message
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default NotaryCustomerService;
