import * as yup from "yup";
import { unavailable_record_list } from "../../data/counties";
import validator from "validator";

export const schema1 = yup.object({
  file: yup.string().required("A file is required"),
  documentType: yup.string().required("Document type is required"),
  county: yup
    .string()
    .required("County is required")
    .test(
      "county-check",
      "This county doesn’t allow electronic recording at the moment",
      function (value) {
        // Accessing other values in the schema for comparison
        const { reason } = this.parent;

        // Check if reason is either "Notarization & Recording" or "Recording"
        if (reason === "Notarization & Recording" || reason === "Recording") {
          // Check if the selected county is in the unavailable_record_list
          if (unavailable_record_list.includes(value)) {
            return false; // Return false if the county is unavailable for recording, which triggers the validation message
          }
        }

        return true; // Return true if none of the conditions are met, meaning the validation passes
      }
    ),
  reason: yup.string().required("Process type is required"),
});

export const schema2 = yup.object({
  propertyAddress: yup
    .string()
    .required("Property address is required")
    .min(4, "Property address must be at least 4 characters long"),
  contractor: yup.string().required("Contractor is required"),
  signeeFirst: yup
    .string()
    .required("Signee first name is required")
    .min(3, "First name must be at least 3 characters long"),
  signeeLast: yup
    .string()
    .required("Signee last name is required")
    .min(3, "Last name must be at least 3 characters long"),
  email: yup
    .string()
    .email("Email address is invalid")
    .required("Signee email address is required"),
  signeePhone: yup
    .string()
    .required("Signee phone number is required")
    .test("PhoneTest", "Signee phone number is invalid", function (value) {
      const isValidPhoneNumber = validator.isMobilePhone(value);
      return isValidPhoneNumber;
    }),
});
