export const timezoneMappings = [
  // USA Timezones
  { value: "America/New_York", label: "New York, USA", countryCode: "US" },
  { value: "America/Chicago", label: "Chicago, USA", countryCode: "US" },
  { value: "America/Denver", label: "Denver, USA", countryCode: "US" },
  {
    value: "America/Los_Angeles",
    label: "Los Angeles, USA",
    countryCode: "US",
  },
  { value: "America/Anchorage", label: "Anchorage, USA", countryCode: "US" },
  { value: "Pacific/Honolulu", label: "Honolulu, USA", countryCode: "US" },
  // Spain Timezones
  { value: "Europe/Madrid", label: "Madrid, Spain", countryCode: "ES" },
  // For simplicity, using Madrid for mainland Spain and Canary Islands
  {
    value: "Atlantic/Canary",
    label: "Canary Islands, Spain",
    countryCode: "ES",
  },
  // Argentina Timezones
  {
    value: "America/Buenos_Aires",
    label: "Buenos Aires, Argentina",
    countryCode: "AR",
  },
  { value: "America/Cordoba", label: "Cordoba, Argentina", countryCode: "AR" },
  // England (UK) Timezones
  { value: "Europe/London", label: "London, England", countryCode: "GB" },
  // France Timezones
  { value: "Europe/Paris", label: "Paris, France", countryCode: "FR" },
];
