import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
  Rating,
} from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import LottieAnimation from "../../../animation/LottieAnimation";
import { userRonSurveyQuestions } from "../../../data/ronData";
import { LoadingButton } from "@mui/lab";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const UserRonCompleted = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const mobileSize = useMediaQuery(theme.breakpoints.down("sm"));
  const tabletSize = useMediaQuery(theme.breakpoints.down("md"));
  const miniSize = useMediaQuery("(max-width:330px)");
  const [loadingPage, setLoadingPage] = useState(false);
  const [ratings, setRatings] = useState(
    Array(userRonSurveyQuestions?.length).fill(null)
  );
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  //   const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  // Handle change in rating
  const handleRatingChange = (index, value) => {
    const newRatings = [...ratings];
    newRatings[index] = value; // Update the rating for the specific question
    setRatings(newRatings); // Save the updated ratings to state
  };

  //   useEffect(() => {
  //     let isDisabled = ratings.some((rating) => rating === null);

  //     if (isDisabled !== isSubmitDisabled) {
  //       setIsSubmitDisabled(isDisabled);
  //     }

  //     console.log("ISDISABLED: ", isDisabled);
  //   }, [ratings]);

  return (
    <>
      {loadingPage ? (
        <>
          <Box sx={{ mb: 2 }}>
            <img
              style={{
                width: "10rem",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              alt="Permit Rockstar Logo"
              src="/img/website_img/PermitRockstar.png"
            />
          </Box>
          <LottieAnimation />
        </>
      ) : (
        <>
          <Box
            sx={
              mobileSize
                ? {
                    justifyContent: "center",
                    overflow: "auto",

                    minHeight: "100vh",
                    overflowY: "hidden",
                    display: "flex",
                    mx: 1,
                    flexDirection: "column",
                  }
                : {
                    backgroundImage: "url('/img/website_img/homeImg.jpeg')",
                    backgroundRepeat: "repeat",

                    minHeight: "100vh",
                    overflow: "hidden",
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }
            }
          >
            <Box
              sx={
                mobileSize
                  ? {
                      backgroundColor: "#fff",
                      py: "1rem",
                      width: "100%",
                      flexGrow: 1,
                    }
                  : {
                      backgroundColor: "#fff",
                      mt: 2,
                      maxWidth: 900,
                      borderRadius: "25px",
                      borderBottomLeftRadius: "0px",
                      borderBottomRightRadius: "0px",
                      px: 3,
                      py: "10px",
                      width: "100%",
                      pt: "2rem",
                    }
              }
            >
              <Stack spacing={0} sx={{ mb: 0 }}>
                <Box
                  sx={{ cursor: "pointer" }}
                  display="flex"
                  justifyContent="center"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <img
                    style={{
                      width: miniSize ? "8rem" : "9rem",
                      marginLeft: "auto",
                      marginRight: "auto",
                      marginTop: "-9px",
                    }}
                    alt="Permit Rockstar Logo"
                    src="/img/website_img/PermitRockstar.png"
                  />
                </Box>

                <Typography
                  sx={{ textAlign: "center", pt: 1, pb: 0 }}
                  color="#004976"
                  variant="h3"
                >
                  Your Notarization is completed!{" "}
                  <CheckCircleIcon
                    style={{
                      color: "green",
                      fontSize: "2rem",
                      paddingLeft: "0.5rem",
                    }}
                  />
                </Typography>

                <Typography
                  sx={{ textAlign: "center" }}
                  color="#004976"
                  variant="body2"
                  fontWeight="bold"
                >
                  Your signed documents will be emailed to you once all the
                  parties have signed.
                </Typography>
                {/* <Typography
                  sx={{ textAlign: "center", pt: 0, mt: 0 }}
                  color="#004976"
                  variant="body2"
                  fontWeight="bold"
                >
                  In addition, you can log in to your account to access your
                  notarized documents.
                </Typography> */}

                <Typography
                  sx={{ textAlign: "center", mt: 4 }}
                  color="#004976"
                  variant="h5"
                >
                  Thank you for choosing Permit Rockstar!
                </Typography>
                <Typography
                  sx={{ textAlign: "center" }}
                  color="#004976"
                  variant="body1"
                  fontWeight="bold"
                >
                  Please take a moment to share your experience with us.
                </Typography>
                {/* Rating Questions Section */}
                <div
                  style={{
                    border: "1px solid #E7E5E5",
                    width: "100%",
                    marginTop: "1rem",
                    marginBottom: "1rem",
                  }}
                />
                <Stack spacing={2} sx={{ mt: 3 }}>
                  {userRonSurveyQuestions.map((question, index) => (
                    <Box
                      key={index}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography
                        color="#004976"
                        variant="body1"
                        fontWeight="bold"
                      >
                        {question}
                      </Typography>
                      <Rating
                        disabled={submitted}
                        name={`ron-session-rating-${index}`}
                        value={ratings[index]}
                        precision={0.5}
                        sx={{ cursor: "pointer", fontSize: "2rem" }}
                        onChange={(event, newValue) =>
                          handleRatingChange(index, newValue)
                        }
                      />
                    </Box>
                  ))}

                  <LoadingButton
                    disabled={submitted}
                    onClick={() => {
                      setSubmitLoading(true);
                      setTimeout(() => {
                        setSubmitted(true);
                        setSubmitLoading(false);
                      }, 2000);
                    }}
                    loading={submitLoading}
                    variant="outlined"
                    endIcon={
                      !submitLoading && submitted ? (
                        <CheckCircleIcon style={{ color: "green" }} />
                      ) : null
                    }
                  >
                    {submitted ? "SUBMITTED" : "SUBMIT"}
                  </LoadingButton>
                </Stack>
              </Stack>
            </Box>
            <Box
              sx={
                !mobileSize
                  ? {
                      backgroundColor: "#fff",
                      mb: 2,
                      maxWidth: 900,
                      borderRadius: "25px",
                      borderTopLeftRadius: "0px",
                      borderTopRightRadius: "0px",
                      px: 3,
                      py: "10px",
                      width: "100%",
                    }
                  : { mb: 3, backgroundColor: "#fff" }
              }
            >
              {!mobileSize && (
                <div
                  style={{
                    border: "1px solid #E7E5E5",
                    width: "100%",

                    marginBottom: "1rem",
                  }}
                />
              )}

              <Box
                sx={{
                  flex: "1 1 auto",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  px: tabletSize && !mobileSize ? "0.5rem" : undefined,
                }}
              >
                <Button
                  onClick={(e) => {
                    navigate("/login");
                  }}
                  color="warning"
                  fullWidth
                  sx={{
                    ":hover": { backgroundColor: "#CDAF0B" },
                  }}
                  variant="contained"
                >
                  Back to Login
                </Button>
              </Box>
              {/* </div> */}
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default UserRonCompleted;
