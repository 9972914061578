import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Stack,
  Link,
  Typography,
  Grid,
  useMediaQuery,
  useTheme,
  CircularProgress,
} from "@mui/material";

const EmailResetFaAuth = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const mobileSize = useMediaQuery(theme.breakpoints.down("sm"));
  const tabletSize = useMediaQuery(theme.breakpoints.down("md"));
  const largeTabletSize = useMediaQuery(theme.breakpoints.down("lg"));
  const largePC = useMediaQuery(theme.breakpoints.up("xl"));
  const [selectedImage, setSelectedImage] = useState(null);
  const [pageLoading, setPageLoading] = useState(true);
  const [blockedText, setBlockedText] = useState("");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tokenFromQuery = queryParams.get("token");
  const [resendDisabled, setResendDisabled] = useState(false);
  let count = 0;

  useEffect(() => {
    if (!tokenFromQuery) {
      navigate("/login");
      return;
    }

    const process_data = async () => {
      try {
        const tokenResponse = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/auth/2fa/reset-pwd-verify`,
          {
            token: tokenFromQuery,
          }
        );
        if (tokenResponse.data.success) {
          const emailResp = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/nocApi/auth/2fa/email-pwd-reset-pass`,
            {
              token: tokenFromQuery,
            },
            { withCredentials: true }
          );

          setPageLoading(false);
        } else {
          navigate("/login");
          throw new Error("Network response was not ok");
        }
        //}
      } catch (error) {
        console.error("Error fetching Email:", error);
        navigate("/login");
      }
    };

    if (count < 1) {
      count += 1;
      process_data();
    }
  }, []);

  const handleResendCode = async () => {
    try {
      setResendDisabled(true);
      const emailResp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/auth/2fa/email-pass`,
        {
          token: tokenFromQuery,
          second_try: true,
        },
        { withCredentials: true }
      );

      setBlockedText("The email has been resent");
    } catch (e) {
      navigate("/login");
    }
  };

  return (
    <>
      {pageLoading ? (
        <>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100vh",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CircularProgress color="warning" size="10rem" />
          </Box>
        </>
      ) : (
        <Box
          sx={
            mobileSize
              ? {
                  justifyContent: "center",
                  overflowX: "hidden",
                  px: 1,
                  minHeight: "100vh",
                  overflowY: "hidden",
                  display: "flex",
                  flexDirection: "column",
                }
              : {
                  backgroundImage: "url('/img/website_img/homeImg.jpeg')",
                  backgroundRepeat: "repeat",

                  minHeight: "100vh",
                  overflow: "hidden",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }
          }
        >
          <Box
            sx={
              mobileSize
                ? {
                    backgroundColor: "#fff",
                    py: "1rem",
                    maxWidth: "100%",
                    flexGrow: 1,
                  }
                : {
                    backgroundColor: "#fff",
                    mt: 2,
                    maxWidth: largePC ? "1100px" : "70%",
                    borderRadius: "25px",
                    borderBottomLeftRadius: "0px",
                    borderBottomRightRadius: "0px",
                    px: 3,
                    py: "10px",
                    width: "80%",
                    pt: "2rem",
                  }
            }
          >
            <Stack spacing={1} sx={{ mb: 3 }}>
              <Box
                sx={{ cursor: "pointer" }}
                display="flex"
                justifyContent="center"
                onClick={() => {
                  navigate("/");
                }}
              >
                <img
                  style={{
                    width: mobileSize ? "75%" : "10rem",
                  }}
                  alt="Permit Rockstar Logo"
                  src="/img/website_img/PermitRockstar.png"
                />
              </Box>
            </Stack>
            <Typography
              sx={{ mb: 2 }}
              color="primary"
              variant={mobileSize ? "h5" : "h4"}
              align="center"
            >
              Change your Password
            </Typography>
            <Typography
              color="primary"
              variant={mobileSize ? "h6" : "h5"}
              align="center"
            >
              An email has already been sent to you
            </Typography>

            <Box
              sx={{
                borderRadius: "10px",
                backgroundColor: "transparent",
                flexDirection: "column",
                cursor: "auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <img
                alt="authenticator"
                src="/img/logos/2falogo.png"
                width="80%"
                style={{ maxWidth: !mobileSize ? "250px" : undefined }}
              />
            </Box>
          </Box>
          <Box
            sx={
              !mobileSize
                ? {
                    backgroundColor: "#fff",
                    mb: 2,
                    maxWidth: largePC ? "1100px" : "70%",
                    borderRadius: "25px",
                    borderTopLeftRadius: "0px",
                    borderTopRightRadius: "0px",
                    px: 3,
                    py: "10px",
                    width: "80%",
                    pt: "2rem",
                  }
                : { mb: 5, backgroundColor: "#fff" }
            }
          >
            <Grid container justifyContent="center" mb={2} mt={5}>
              <Grid item xs={12} sx={{ width: "100%" }}>
                <Typography
                  color="primary"
                  variant="body1"
                  align="center"
                  sx={{ mb: 5 }}
                >
                  Please follow the instructions in the email provided for
                  two-factor authentication.
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ mt: 2, minHeight: "1.5rem" }}>
                <Typography
                  variant="body1"
                  textAlign="center"
                  color="error"
                  fontWeight="bold"
                >
                  {blockedText}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                  onClick={handleResendCode}
                >
                  <Typography
                    component={Link}
                    variant="body1"
                    sx={
                      resendDisabled
                        ? {
                            textAlign: "center",
                            cursor: "auto",
                            textDecoration: "none",
                          }
                        : { textAlign: "center", cursor: "pointer" }
                    }
                    color={resendDisabled ? "#d4d4d4" : "primary"}
                    fontWeight="bold"
                  >
                    Resend Code
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
    </>
  );
};

export default EmailResetFaAuth;
