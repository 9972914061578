import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  IconButton,
  Box,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import ClearIcon from "@mui/icons-material/Clear"; // Import the ClearIcon

const EditCustomer = ({
  setOpenDrawer,
  row,
  setCustomersDataRows,
  setSelectedRow,
}) => {
  const theme = useTheme();
  const currentSession = useSelector((state) => state.sessions.currentSession);

  const numSelects = ["1", "2", "3", "4", "5"];
  const roles = ["user", "tester", "admin"];
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("");
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const [state, setState] = useState({
    addContractorChanges: "",
    removeContractorChanges: "",
    addNumNoc: "",
    removeNumNoc: "",
    addNumCounties: "",
    removeNumCounties: "",
    addCounty: "",
    removeCounty: "",
    licenseTypes: "",
    addLicense: "",
    removeLicense: "",
    role: row.role,
  });

  const availableContractors = Array.from(
    { length: row?.availableContractors },
    (_, index) => index + 1
  );

  useEffect(() => {
    const clearTextInterval = setInterval(() => {
      if (text !== "") {
        setText("");
      }
    }, 3000);

    return () => {
      clearInterval(clearTextInterval); // Clear the interval when the component unmounts
    };
  }, [text]);

  const submitChange = async () => {
    try {
      setLoading(true);
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/admin/user-changes`,
        {
          role: state["role"],
          email: row.email,
          addContractorChanges: state["addContractorChanges"],
          removeContractorChanges: state["removeContractorChanges"],
          addNumNoc: state["addNumNoc"],
          removeNumNoc: state["removeNumNoc"],
          addNumCounties: state["addNumCounties"],
          removeNumCounties: state["removeNumCounties"],
          addCounty: state["addCounty"],
          removeCounty: state["removeCounty"],
          addLicense: state["addLicense"],
          removeLicense: state["removeLicense"],
          currentSession: currentSession,
        },
        { withCredentials: true }
      );

      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/admin/getUsers`,
        { currentSession: currentSession },
        { withCredentials: true }
      );

      setCustomersDataRows(resp.data.data);

      const userWithEmail = resp.data.data.find(
        (user) => user.email === row.email
      );

      if (userWithEmail) {
        setSelectedRow(userWithEmail);
      } else {
        setSelectedRow(null); // If no user matches, you can clear the selected row
      }

      setState({
        addContractorChanges: "",
        removeContractorChanges: "",
        addNumNoc: "",
        removeNumNoc: "",
        addNumCounties: "",
        removeNumCounties: "",
        addCounty: "",
        removeCounty: "",
        addLicense: "",
        removeLicense: "",
        role: state.role, // Keep the role unchanged
      });

      setText("Changes has been added successfully");
      setLoading(false);
    } catch (err) {}
  };

  return (
    <>
      <Grid
        container
        spacing={isSm ? 1 : 2}
        sx={isSm ? { px: "0.5rem", py: "2rem" } : { px: "2rem", py: "2rem" }}
      >
        <Grid item xs={12} sx={isSm ? { mb: 2 } : {}}>
          <Stack direction="row" width="100%">
            <Typography
              pl={isSm ? 1 : 2}
              sx={isSm ? { mb: "0.5rem" } : { mb: 2 }}
              variant="h5"
            >
              Edit User Details
            </Typography>

            <IconButton
              onClick={() => setOpenDrawer(false)}
              sx={{ mb: 2, ml: "auto" }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <Box
            sx={{
              border: "1px solid #707070",
              p: 3,
              ml: "auto",
              borderRadius: 3,
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h5" textAlign="center" fontWeight="bold">
                  {row?.customerName}
                </Typography>
                <Typography
                  variant="body1"
                  textAlign="center"
                  fontWeight="bold"
                >
                  {row?.contractorName}
                </Typography>
                <Typography
                  variant="body1"
                  textAlign="center"
                  fontWeight="bold"
                >
                  {row?.customerPhone}
                </Typography>
                <Typography
                  mb={4}
                  variant="body2"
                  textAlign="center"
                  fontWeight="bold"
                >
                  {row?.email}
                </Typography>
              </Grid>

              <Grid item sm={6}>
                <Typography variant="h6" fontWeight="bold">
                  {row?.tierName} ({row?.subscriptionType})
                </Typography>

                <Typography variant="body2" fontWeight="bold">
                  Available NOC: {row?.currentNoc}
                </Typography>
                <Typography variant="body2" fontWeight="bold">
                  Status:{" "}
                  <span style={{ color: "#07B713" }}>{row?.status}</span>
                </Typography>
              </Grid>
              <Grid item sm={6} style={{ textAlign: "right" }}>
                <Typography variant="body2" fontWeight="bold">
                  Total Licenses: {row?.analytics?.contractors_limit}
                </Typography>
                <Typography variant="body2" fontWeight="bold">
                  Available Licenses: {row?.availableContractors}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        {/* <Grid item xs={12} md={6}>
          <FormControl
            fullWidth
            variant="filled"
            disabled={
              state["removeContractorChanges"] !== "" || row?.role === "admin"
            }
          >
            <InputLabel id="add-contractor-changes">
              Add # Contractor changes
            </InputLabel>
            <Select
              value={state["addContractorChanges"]}
              labelId="add-contractor-changes"
              label="addContractorChanges"
              onChange={(e) =>
                setState({ ...state, addContractorChanges: e.target.value })
              }
              MenuProps={{
                PaperProps: {
                  style: {
                    marginTop: "1.5px",
                    maxHeight: 150, // Set the desired max height for the dropdown menu
                    width: "auto",
                    maxWidth: "100%",
                  },
                },
              }}
              endAdornment={
                state.addContractorChanges !== "" ? ( // Place the clear icon to the right of the Select
                  <IconButton
                    onClick={() => {
                      setState({ ...state, addContractorChanges: "" });
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                ) : undefined
              }
              IconComponent={
                state.addContractorChanges !== "" ? "div" : undefined
              } // Remove the default MUI arrow
            >
              {numSelects.map(function (item, i) {
                return (
                  <MenuItem key={i} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid> */}
        {/* <Grid item xs={12} md={6}>
          <FormControl
            fullWidth
            variant="filled"
            disabled={
              state["addContractorChanges"] !== "" ||
              row?.remove_contractor_changes.length === 0 ||
              row?.role === "admin"
            }
          >
            <InputLabel id="remove-contractor-changes">
              Remove # Contractor changes
            </InputLabel>
            <Select
              value={state["removeContractorChanges"]}
              labelId="remove-contractor-changes"
              label="removeContractorChanges"
              onChange={(e) =>
                setState({ ...state, removeContractorChanges: e.target.value })
              }
              MenuProps={{
                PaperProps: {
                  style: {
                    marginTop: "1.5px",
                    maxHeight: 150, // Set the desired max height for the dropdown menu
                    width: "auto",
                    maxWidth: "100%",
                  },
                },
              }}
              endAdornment={
                state.removeContractorChanges !== "" ? ( // Place the clear icon to the right of the Select
                  <IconButton
                    onClick={() => {
                      setState({ ...state, removeContractorChanges: "" });
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                ) : undefined
              }
              IconComponent={
                state.removeContractorChanges !== "" ? "div" : undefined
              } // Remove the default MUI arrow
            >
              {row?.remove_contractor_changes.map(function (item, i) {
                return (
                  <MenuItem key={i} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid> */}
        <Grid item xs={12} md={6}>
          <FormControl
            fullWidth
            variant="filled"
            disabled={
              state["removeNumNoc"] !== "" ||
              row?.role === "admin" ||
              row?.currentNoc === "Unlimited"
            }
          >
            <InputLabel id="add-addNumNoc">Add Available NOC</InputLabel>
            <Select
              value={state["addNumNoc"] || ""}
              labelId="add-addNumNoc"
              label="addNumNoc"
              onChange={(e) =>
                setState({ ...state, addNumNoc: e.target.value })
              }
              MenuProps={{
                PaperProps: {
                  style: {
                    marginTop: "1.5px",
                    maxHeight: 150, // Set the desired max height for the dropdown menu
                    width: "auto",
                    maxWidth: "100%",
                  },
                },
              }}
              endAdornment={
                state.addNumNoc !== "" ? ( // Place the clear icon to the right of the Select
                  <IconButton
                    onClick={() => {
                      setState({ ...state, addNumNoc: "" });
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                ) : undefined
              }
              IconComponent={state.addNumNoc !== "" ? "div" : undefined} // Remove the default MUI arrow
            >
              {numSelects.map(function (item, i) {
                return (
                  <MenuItem key={i} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl
            fullWidth
            variant="filled"
            disabled={
              state["addNumNoc"] !== "" ||
              row?.remove_num_noc.length === 0 ||
              row?.role === "admin"
            }
          >
            <InputLabel id="remove-addNumNoc">Remove Available NOC</InputLabel>
            <Select
              value={state["removeNumNoc"] || ""}
              labelId="remove-numNoc"
              label="removeNumNoc"
              onChange={(e) =>
                setState({ ...state, removeNumNoc: e.target.value })
              }
              MenuProps={{
                PaperProps: {
                  style: {
                    marginTop: "1.5px",
                    maxHeight: 150, // Set the desired max height for the dropdown menu
                    width: "auto",
                    maxWidth: "100%",
                  },
                },
              }}
              endAdornment={
                state.removeNumNoc !== "" ? ( // Place the clear icon to the right of the Select
                  <IconButton
                    onClick={() => {
                      setState({ ...state, removeNumNoc: "" });
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                ) : undefined
              }
              IconComponent={state.removeNumNoc !== "" ? "div" : undefined} // Remove the default MUI arrow
            >
              {row?.remove_num_noc.map(function (item, i) {
                return (
                  <MenuItem key={i} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl
            fullWidth
            variant="filled"
            disabled={
              state["removeNumCounties"] !== "" ||
              row?.add_num_county.length === 0 ||
              row?.role === "admin" ||
              row?.currentNoc === "Unlimited"
            }
          >
            <InputLabel id="add-NumCounties">Add Available Counties</InputLabel>
            <Select
              value={state["addNumCounties"] || ""}
              labelId="add-NumCounties"
              label="addNumCounties"
              onChange={(e) =>
                setState({ ...state, addNumCounties: e.target.value })
              }
              MenuProps={{
                PaperProps: {
                  style: {
                    marginTop: "1.5px",
                    maxHeight: 150, // Set the desired max height for the dropdown menu
                    width: "auto",
                    maxWidth: "100%",
                  },
                },
              }}
              endAdornment={
                state.addNumCounties !== "" ? ( // Place the clear icon to the right of the Select
                  <IconButton
                    onClick={() => {
                      setState({ ...state, addNumCounties: "" });
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                ) : undefined
              }
              IconComponent={state.addNumCounties !== "" ? "div" : undefined} // Remove the default MUI arrow
            >
              {row?.add_num_county.map(function (item, i) {
                return (
                  <MenuItem key={i} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl
            fullWidth
            variant="filled"
            disabled={
              state["addNumCounties"] !== "" ||
              row?.remove_num_county.length === 0 ||
              row?.role === "admin"
            }
          >
            <InputLabel id="remove-NumCounties">
              Remove Available Counties
            </InputLabel>
            <Select
              value={state["removeNumCounties"] || ""}
              labelId="remove-NumCounties"
              label="removeNumCounties"
              onChange={(e) =>
                setState({ ...state, removeNumCounties: e.target.value })
              }
              MenuProps={{
                PaperProps: {
                  style: {
                    marginTop: "1.5px",
                    maxHeight: 150, // Set the desired max height for the dropdown menu
                    width: "auto",
                    maxWidth: "100%",
                  },
                },
              }}
              endAdornment={
                state.removeNumCounties !== "" ? ( // Place the clear icon to the right of the Select
                  <IconButton
                    onClick={() => {
                      setState({ ...state, removeNumCounties: "" });
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                ) : undefined
              }
              IconComponent={state.removeNumCounties !== "" ? "div" : undefined} // Remove the default MUI arrow
            >
              {row?.remove_num_county.map(function (item, i) {
                return (
                  <MenuItem key={i} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl
            fullWidth
            variant="filled"
            disabled={
              state["removeCounty"] !== "" ||
              row?.counties.length === 0 ||
              row?.role === "admin"
            }
          >
            <InputLabel id="extraCounty">Add extra county</InputLabel>
            <Select
              value={state["addCounty"] || ""}
              onChange={(e) =>
                setState({ ...state, addCounty: e.target.value })
              }
              labelId="extraCounty"
              label="extraCounty1"
              MenuProps={{
                PaperProps: {
                  style: {
                    marginTop: "1.5px",
                    maxHeight: 150, // Set the desired max height for the dropdown menu
                    width: "auto",
                    maxWidth: "100%",
                  },
                },
              }}
              endAdornment={
                state.addCounty !== "" ? ( // Place the clear icon to the right of the Select
                  <IconButton
                    onClick={() => {
                      setState({ ...state, addCounty: "" });
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                ) : undefined
              }
              IconComponent={state.addCounty !== "" ? "div" : undefined} // Remove the default MUI arrow
            >
              {row?.counties.map(function (item, i) {
                return (
                  <MenuItem key={i} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl
            fullWidth
            variant="filled"
            disabled={
              state["addCounty"] !== "" ||
              row?.removable_counties.length === 0 ||
              row?.role === "admin"
            }
          >
            <InputLabel id="removeCounty">Remove county</InputLabel>
            <Select
              onChange={(e) =>
                setState({ ...state, removeCounty: e.target.value })
              }
              value={state["removeCounty"] || ""}
              labelId="removeCounty"
              label="removeCounty1"
              MenuProps={{
                PaperProps: {
                  style: {
                    marginTop: "1.5px",
                    maxHeight: 150, // Set the desired max height for the dropdown menu
                    width: "auto",
                    maxWidth: "100%",
                  },
                },
              }}
              endAdornment={
                state.removeCounty !== "" ? ( // Place the clear icon to the right of the Select
                  <IconButton
                    onClick={() => {
                      setState({ ...state, removeCounty: "" });
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                ) : undefined
              }
              IconComponent={state.removeCounty !== "" ? "div" : undefined} // Remove the default MUI arrow
            >
              {row?.removable_counties.map(function (item, i) {
                return (
                  <MenuItem key={i} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl
            fullWidth
            variant="filled"
            disabled={state["removeLicense"] !== ""}
          >
            <InputLabel id="add_contractors">Add Available Licenses</InputLabel>
            <Select
              onChange={(e) =>
                setState({ ...state, addLicense: e.target.value })
              }
              value={state["addLicense"] || ""}
              labelId="addLicense"
              label="addLicense"
              MenuProps={{
                PaperProps: {
                  style: {
                    marginTop: "1.5px",
                    maxHeight: 150, // Set the desired max height for the dropdown menu
                    width: "auto",
                    maxWidth: "100%",
                  },
                },
              }}
              endAdornment={
                state.addLicense !== "" ? ( // Place the clear icon to the right of the Select
                  <IconButton
                    onClick={() => {
                      setState({ ...state, addLicense: "" });
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                ) : undefined
              }
              IconComponent={state.addLicense !== "" ? "div" : undefined} // Remove the default MUI arrow
            >
              {["1", "2", "3", "4", "5"].map(function (item, i) {
                return (
                  <MenuItem key={i} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl
            fullWidth
            variant="filled"
            disabled={state["addLicense"] !== ""}
          >
            <InputLabel id="removeLicense">
              Remove Available Licenses
            </InputLabel>
            <Select
              onChange={(e) =>
                setState({ ...state, removeLicense: e.target.value })
              }
              value={state["removeLicense"] || ""}
              labelId="removeLicense"
              label="removeLicense"
              MenuProps={{
                PaperProps: {
                  style: {
                    marginTop: "1.5px",
                    maxHeight: 150, // Set the desired max height for the dropdown menu
                    width: "auto",
                    maxWidth: "100%",
                  },
                },
              }}
              endAdornment={
                state.removeLicense !== "" ? ( // Place the clear icon to the right of the Select
                  <IconButton
                    onClick={() => {
                      setState({ ...state, removeLicense: "" });
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                ) : undefined
              }
              IconComponent={state.removeLicense !== "" ? "div" : undefined} // Remove the default MUI arrow
            >
              {availableContractors.map(function (item, i) {
                return (
                  <MenuItem key={i} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <FormControl
            fullWidth
            variant="filled"
            // disabled={state["licenseTypes"] !== ""}
          >
            <InputLabel id="add_contractors">License Types</InputLabel>
            <Select
              onChange={(e) =>
                setState({ ...state, licenseTypes: e.target.value })
              }
              value={state["licenseTypes"]}
              labelId="licenseTypes"
              label="licenseTypes"
              MenuProps={{
                PaperProps: {
                  style: {
                    marginTop: "1.5px",
                    maxHeight: 150, // Set the desired max height for the dropdown menu
                    width: "auto",
                    maxWidth: "100%",
                  },
                },
              }}
              endAdornment={
                state.licenseTypes !== "" ? ( // Place the clear icon to the right of the Select
                  <IconButton
                    onClick={() => {
                      setState({ ...state, licenseTypes: "" });
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                ) : undefined
              }
              IconComponent={state.licenseTypes !== "" ? "div" : undefined} // Remove the default MUI arrow
            >
              {row?.licenseTypes.map(function (item, i) {
                return (
                  <MenuItem key={i} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid> */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="filled">
            <InputLabel id="removeCounty">Change Role</InputLabel>
            <Select
              onChange={(e) => setState({ ...state, role: e.target.value })}
              value={state["role"] || ""}
              labelId="role"
              label="role"
              MenuProps={{
                PaperProps: {
                  style: {
                    marginTop: "1.5px",
                    maxHeight: 150, // Set the desired max height for the dropdown menu
                    width: "auto",
                    maxWidth: "100%",
                  },
                },
              }}
            >
              {roles.map(function (item, i) {
                return (
                  <MenuItem key={i} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Stack mx={4}>
        <Typography
          variant="body1"
          color="#F2CD00"
          fontWeight="bold"
          textAlign="center"
          sx={
            isSm
              ? { mt: 0, minHeight: "2rem" }
              : { mt: "1rem", minHeight: "3rem" }
          }
        >
          {text}
        </Typography>
        <LoadingButton
          loading={loading}
          size="large"
          onClick={() => submitChange()}
          sx={isSm ? { mt: 0, mb: 2 } : { mt: 2, mb: 2 }}
          variant="contained"
        >
          SAVE CHANGES
        </LoadingButton>
      </Stack>
    </>
  );
};

export default EditCustomer;
