import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";
import axios from "axios";
import { useState, useEffect, useRef } from "react";
import AvailableCountiesModal from "../modals/AvailableCountiesModal";

export default function LandingLayout() {
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mobileSize = useMediaQuery(theme.breakpoints.down("sm"));
  const tabletSize = useMediaQuery(theme.breakpoints.down("md"));
  const [countyModalOpen, setCountyModalOpen] = useState(false);
  const [availableCounties, setAvailableCounties] = useState([]);
  const [selectedCounty, setSelectedCounty] = useState("");
  const playerRef = useRef(null);
  const navigate = useNavigate();
  let count = 0;

  const handleEnded = () => {
    // When the video ends, seek to the beginning and start playing
    if (playerRef.current) {
      playerRef.current.seekTo(0);
    }
  };

  useEffect(() => {
    const getCounties = async () => {
      try {
        const resp = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/nocApi/data/available-counties`,
          { withCredentials: true }
        );

        setAvailableCounties(resp.data.data.counties);
      } catch (e) {}
    };

    if (count < 1) {
      count += 1;
      getCounties();
    }
  }, []);

  return (
    <>
      <Grid
        sx={
          isMatch
            ? {
                alignItems: "top",
                backgroundColor: "#EEF2F6",
                color: "#004976",
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                overflowY: "auto",
                overflowx: "hidden",
                // height: "100vh",
                pb: "1rem",
              }
            : {
                alignItems: "top",
                background: "#ededeb",
                color: "white",
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                overflowY: "auto",
                overflowx: "hidden",
                height: "100vh",
                pb: "1rem",
              }
        }
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            px: isMatch ? 2 : 10, // Adjust the padding here
            pt: 4,
            zIndex: "2",
            align: "center",
          }}
        >
          <Box>
            <Typography
              align="center"
              sx={{ mt: 0, mb: 2, color: "#004976", fontWeight: "bold" }}
              variant={isMatch ? "h4" : "h3"}
            >
              Rock Your Permit Packages Like a Pro!
            </Typography>
            <Box>
              <ReactPlayer
                ref={playerRef}
                url="https://permitrockstar.app/videos/Permit_Rockstar_promo_video.mp4"
                width="100%" // Set the width of the player
                height={mobileSize ? "200px" : "360px"} // Set the height of the player
                controls // Display video controls (play, pause, etc.)
                onEnded={handleEnded}
                // playing={true}
                // muted={true}
                // onPlay={handlePlay}
              />
            </Box>
            <Typography
              align="center"
              sx={
                isMatch
                  ? {
                      mt: 2,
                      color: "#004976",
                      fontWeight: "bold",
                      fontSize: "20px",
                    }
                  : {
                      mt: 2,
                      color: "#004976",
                      fontWeight: "bold",
                      fontSize: "25px",
                    }
              }
              variant="subtitle1"
            >
              Crank Out Custom Permit Packages for 67 Florida Counties with
              Ease!
            </Typography>
            {/* <Typography
              align="center"
              onClick={() => {
                if (tabletSize) {
                  setSelectedCounty("Alachua");
                }
                setCountyModalOpen(true);
              }}
              variant="subtitle1"
              color="error"
              sx={
                isMatch
                  ? {
                      fontWeight: "bold",
                      textDecoration: "underline",
                      cursor: "pointer",
                      fontSize: "20px",
                    }
                  : {
                      fontWeight: "bold",
                      textDecoration: "underline",
                      cursor: "pointer",
                      fontSize: "20px",
                    }
              }
            >
              67 Florida Counties
            </Typography> */}
            {/* <Typography
              align="center"
              sx={
                isMatch
                  ? {
                      mt: 2,
                      color: "#004976",
                      fontWeight: "bold",
                      fontSize: "20px",
                    }
                  : {
                      mt: 2,
                      color: "#004976",
                      fontWeight: "bold",
                      fontSize: "25px",
                    }
              }
              variant="subtitle1"
            >
              Say goodbye to the complexities of NOC creation.
            </Typography> */}
          </Box>

          <Typography
            align="center"
            sx={{ color: "#004976", mt: "2rem" }}
            variant="body1"
          >
            No more wrestling with complex permit processes. With just a few
            clicks, you’ll be shredding through tailored permit packages that
            hit all the right notes for Duval, St. Johns, Clay, and Nassau
            Counties—including all the local hotspots like Jacksonville, St.
            Augustine, and beyond. The best part? You’re not just creating
            killer packages—you’re getting them notarized and recorded, slashing
            your workload by an average of 4 hours and saving serious cash. Most
            contractors pull 10 to 15 permits a year. That’s up to 60 hours of
            your life back and thousands of dollars still in your pocket!
          </Typography>
          <Typography
            align="center"
            sx={
              isMatch
                ? {
                    mt: 2,
                    color: "#004976",
                    fontWeight: "bold",
                    fontSize: "20px",
                  }
                : {
                    mt: 2,
                    color: "#004976",
                    fontWeight: "bold",
                    fontSize: "25px",
                  }
            }
            variant="subtitle1"
          >
            Crush Time and Costs with Our Permit Package Creator!
          </Typography>
          <Typography
            align="center"
            sx={{ color: "#004976", mb: "1rem", mt: "1rem" }}
            variant="body1"
          >
            Why waste time fumbling with forms, chasing signatures, and running
            all over town? Our platform amps up your workflow, letting you do
            what you do best—rocking your projects. With Permit Rockstar, you’ll
            spend just 15 minutes creating and signing documents, while we
            handle the rest—from homeowner signatures to getting everything
            recorded with the clerk of courts. We’ll even let you know when your
            permit package is ready to roll. It’s as easy as smashing out your
            favorite riff!
          </Typography>
          <Typography
            align="center"
            sx={
              isMatch
                ? {
                    mt: 2,
                    color: "#004976",
                    fontWeight: "bold",
                    fontSize: "20px",
                  }
                : {
                    mt: 2,
                    color: "#004976",
                    fontWeight: "bold",
                    fontSize: "25px",
                  }
            }
            variant="subtitle1"
          >
            Stay Ahead of the Game—We’re Rolling Out to All 67 Counties!
          </Typography>
          <Typography
            align="center"
            sx={{ color: "#004976", mb: "1rem", mt: "1rem" }}
            variant="body1"
          >
            We’re on a mission to cover every inch of Florida. Soon, you’ll have
            a backstage pass to permit packages and building forms across all 67
            counties. We’re constantly adding new features and forms, so you’re
            always in tune with the latest. Right now, our full permit packages
            rock Duval, St. Johns, Clay, and Nassau Counties, with new locations
            going live every month. Check out our deployment schedule to see
            when we’ll be jamming in your area.
          </Typography>
          {/* <Typography
            align="center"
            sx={
              isMatch
                ? {
                    color: "#004976",
                    fontWeight: "bold",
                    mt: "1rem",
                    mb: "0rem",
                    fontSize: "20px",
                  }
                : {
                    color: "#004976",
                    fontWeight: "bold",
                    mt: "1rem",
                    mb: "0.5rem",
                    fontSize: "25px",
                  }
            }
            variant="subtitle1"
          >
            Watch the Magic Happen—In Under THREE MINUTES!
          </Typography>
          <Typography
            align="center"
            sx={{ color: "#004976", mb: "1rem", mt: "1rem" }}
            variant="body1"
          >
            Ready to see how easy it is? Hit play and watch our quick video to
            get pro-level results in less time than it takes to tune your
            guitar!
          </Typography> */}

          {/* <Typography
            align="center"
            sx={{ color: "#004976", mt: "1rem" }}
            variant="body1"
          >
            Imagine having a fully filled-out, professionally designed NOC PDF
            form at your fingertips, ready for download, print or email to your
            customers in less than one minute. Our user-friendly interface and
            intuitive steps make this a reality.
          </Typography>
          <Typography
            align="center"
            sx={{ color: "#004976", mt: "1rem" }}
            variant="body1"
          >
            Unlock Efficiency – Embrace Simplicity. Experience the convenience
            and efficiency of our NOC Creator today. Best of all, for a limited
            time, you can access all our platform's features absolutely FREE!
            Yes, you read that right – no charges, no commitments.
          </Typography>
          <Typography
            align="center"
            sx={
              isMatch
                ? {
                    color: "#004976",
                    fontWeight: "bold",
                    mt: "1rem",
                    mb: "1rem",
                    fontSize: "20px",
                  }
                : {
                    color: "#004976",
                    fontWeight: "bold",
                    mt: "1rem",
                    mb: "1rem",
                    fontSize: "25px",
                  }
            }
            variant="subtitle1"
          >
            Act now and witness firsthand the revolution in NOC form creation.
          </Typography> */}
          {/* <Typography
            align="center"
            onClick={() => {
              navigate("/register");
            }}
            variant="subtitle1"
            color="error"
            sx={{
              fontWeight: "bold",
              textDecoration: "underline",
              cursor: "pointer",
              fontSize: "20px",
            }}
          >
            Join Us Today!
          </Typography> */}
        </Box>
      </Grid>
      <AvailableCountiesModal
        open={countyModalOpen}
        onClose={() => setCountyModalOpen(false)}
        counties={availableCounties}
        mobileSize={mobileSize}
        tabletSize={tabletSize}
        selectedCounty={selectedCounty}
        setSelectedCounty={setSelectedCounty}
      />
    </>
  );
}
