// authReducer.js
const initialState = {
  user: null,
  notifications: [],
  urls: null,
  expireAlert: null,
  alertSet: false,
  instantRonAccepted: false,
};

const userSessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_INSTANT_RON_ACCEPTED":
      return { ...state, instantRonAccepted: true };
    case "SET_INSTANT_RON_INITIAL_STATE":
      return { ...state, instantRonAccepted: false };
    case "SET_INSTANT_RON_STOP":
      return { ...state, instantRonAccepted: "stop" };
    case "SET_INSTANT_RON_SUCCESS":
      return { ...state, instantRonAccepted: "success" };
    case "ADD_CONTRACTOR":
      return {
        ...state,
        user: {
          ...state.user,
          user: {
            ...state.user.user,
            contractors: [...state.user.user.contractors, action.payload],
          },
        },
      };
    case "USER_FIRST_LOGIN_OFF":
      return {
        ...state,
        user: {
          ...state.user,
          user: {
            ...state.user.user,
            firstLogin: false,
          },
        },
      };
    case "SET_WILL_USER_EXPIRE_ALERT":
      const isSetAlert = !action.payload ? false : true;
      return {
        ...state,
        expireAlert: action.payload,
        alertSet: isSetAlert,
      };
    case "PUSH_NEW_DESIGN_PRO": {
      return {
        ...state,
        user: {
          ...state.user,
          design_professionals: [
            ...state.user.design_professionals,
            action.payload,
          ],
        },
      };
    }
    case "ADD_PERMIT_PACKAGE_SIGNED_URL": {
      const { task_id, document } = action.payload;

      // Find the selected form and update the documents within that form
      const updatedNocUserForms = state.user.nocUserForms.map((form) => {
        if (form.task_id === task_id) {
          return {
            ...form,
            building_package: {
              ...form.building_package,
              selected_documents: document,
            },
          };
        }
        return form;
      });

      return {
        ...state,
        user: {
          ...state.user,
          nocUserForms: updatedNocUserForms,
        },
      };
    }
    case "UPDATE_DESIGN_PRO": {
      return {
        ...state,
        user: {
          ...state.user,
          design_professionals: state.user.design_professionals.map((pro) =>
            pro.id === action.payload.id ? { ...pro, ...action.payload } : pro
          ),
        },
      };
    }
    case "REMOVE_DESIGN_PRO": {
      return {
        ...state,
        user: {
          ...state.user,
          design_professionals: state.user.design_professionals.filter(
            (pro) => pro.id !== action.payload
          ),
        },
      };
    }
    case "UPDATE_NOC_FORMS": {
      return {
        ...state,
        user: {
          ...state.user,
          nocUserForms: action.payload,
        },
      };
    }
    case "UPDATE_NOTARY_USER_FORMS": {
      const new_form = action.payload;

      return {
        ...state,
        user: {
          ...state.user,
          notary_forms: new_form,
        },
      };
    }
    case "UPDATE_PAYMENT_CARDS": {
      const new_cards = action.payload;
      return {
        ...state,
        user: {
          ...state.user,
          payment_cards: new_cards,
        },
      };
    }
    case "UPDATE_NOC_TASKS": {
      const new_tasks = action.payload;
      return {
        ...state,
        user: {
          ...state.user,
          nocUserTasks: new_tasks,
        },
      };
    }
    case "UPDATE_SIGNEE_EMAIL": {
      const { source, updatedList } = action.payload;

      return {
        ...state,
        user: {
          ...state.user,
          notary_forms: {
            ...state.user.notary_forms,
            [source]: updatedList,
          },
        },
      };
    }
    case "ADD_USER_FULL_DESIGN_PROFESSIONALS":
      return {
        ...state,
        user: {
          ...state.user,
          design_professionals: action.payload,
          design_professionals_locked: false,
        },
      };
    case "UPDATE_URLS":
      return {
        ...state,
        urls: action.payload,
      };
    case "ADD_URL":
      return {
        ...state,
        urls: [...state.urls, action.payload],
      };
    case "UPDATE_USER":
      return {
        ...state,
        user: action.payload,
      };

    case "ADD_URL_TO_CREATED_FORM":
      return {
        ...state,
        user: {
          ...state.user,
          nocUserForms: action.payload,
        },
      };

    // case "BROADCAST_UPDATE":
    //   return {
    //     ...state,
    //     user: {
    //       ...state.user,
    //       user: {
    //         ...state.user?.user,
    //         ...action.payload?.user,
    //         analytics: {
    //           ...state.user?.user?.analytics,
    //           ...action.payload?.user?.analytics,
    //           additions: {
    //             ...state.user?.user?.analytics?.additions,
    //             ...action.payload?.user?.analytics?.additions,
    //           },
    //         },
    //         subuser: {
    //           ...state.user?.user?.subuser,
    //           ...action.payload?.user?.subuser,
    //         },
    //       },
    //       tier: {
    //         ...state.user?.tier,
    //         ...action.payload?.tier,
    //       },
    //     },
    //   };
    case "GET_NOTIFICATION":
      return {
        ...state,
        notifications: action.payload,
      };

    default:
      return state;
  }
};

export default userSessionReducer;
