import React from "react";
import { useSelector } from "react-redux";
import { Layout } from "../layout/Layout";
import { CircularProgress, Box, useMediaQuery } from "@mui/material";
import LottieAnimation from "../../animation/LottieAnimation";

export const withSessionCheck = (
  Component,
  allowedSessionTypes,
  WrapperComponent = null,
  wrapperProps = {},
  useLayout = true
) => {
  return function ProtectedRouteComponent() {
    const sessions = useSelector((state) => state.sessions);
    const tabletSize = useMediaQuery((theme) => theme.breakpoints.down("md"));
    const currentSessionType = sessions.currentSession;

    const isSessionValid =
      sessions[sessions.currentSession]?.token &&
      sessions[sessions.currentSession]?.isValidated &&
      sessions[sessions.currentSession]?.wsConnected &&
      allowedSessionTypes.includes(currentSessionType);

    if (!isSessionValid) {
      return (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "100vh",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {/* {tabletSize ? ( */}
          <>
            <Box sx={{ mb: 2 }}>
              <img
                style={{
                  width: "10rem",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                alt="Permit Rockstar Logo"
                src="/img/website_img/PermitRockstar.png"
              />
            </Box>
            <LottieAnimation />
          </>
          {/* ) : (
            <CircularProgress color="warning" size="10rem" />
          )} */}
        </Box>
      );
    }

    const ComponentToRender = WrapperComponent ? (
      <WrapperComponent {...wrapperProps}>
        <Component />
      </WrapperComponent>
    ) : (
      <Component />
    );

    return useLayout ? <Layout>{ComponentToRender}</Layout> : ComponentToRender;
  };
};
