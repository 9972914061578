import dayjs from "dayjs";

export function convertObjectToDayjs(dateInfo) {
  // Day.js month is zero-indexed, subtract 1

  if (dateInfo && dateInfo?.month && dateInfo?.year && dateInfo?.day_of_month) {
    const monthZeroIndexed = dateInfo.month - 1;

    // Constructing the full date string
    const dateString = `${dateInfo.year}-${String(
      monthZeroIndexed + 1
    ).padStart(2, "0")}-${String(dateInfo.day_of_month).padStart(2, "0")}`;

    // Constructing the time string
    const timeString = dateInfo.time;

    // Creating Day.js objects for date and time
    const date = dayjs(dateString);
    const time = dayjs(timeString, "HH:mm");

    return date;
  } else {
    return "";
  }
}
