import { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Tooltip,
  Box,
  Typography,
} from "@mui/material";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import { DocumentArrowDownIcon } from "@heroicons/react/24/outline";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import InvoiceModal from "./popover/datagridRowPopover/InvoiceModal";

const InvoiceGrid = ({
  title,
  invoiceRow,
  tabletSize,
  mobileSize,
  iphone12Height,
  smallHeight,
}) => {
  //! MOBILE SIZE AREA /////////////////////////////////////////////////////////////////
  const apiRef = useGridApiRef();
  const [selectedRow, setSelectedRow] = useState(null);
  const [rowData, setRowData] = useState(null);
  const [rowIndex, setRowIndex] = useState(0);
  const [rowOpen, setRowOpen] = useState(false);

  const handleOpen = (params, index) => {
    if (!tabletSize) {
      return;
    }
    setSelectedRow(params.row);
    setRowData(params.row);
    setRowIndex(index);
    setRowOpen(true);
  };

  const handleClose = () => {
    setRowOpen(false);
    const currentPage = Math.floor(rowIndex / 5);
    if (apiRef.current) {
      apiRef.current.setPage(currentPage);
    }
  };

  const handleSwipe = (index) => {
    setRowIndex(index);
    setRowData(invoiceRow[index]);
    setSelectedRow(invoiceRow[index]);
  };

  useEffect(() => {
    if (!tabletSize && rowData) {
      setRowData(false);
    }
  }, [tabletSize, rowData]);

  //! //////////////////////////////////////////////////////////////////////////////
  const invoiceColumnsSmall = [
    {
      field: "plan",
      headerName: title,
      flex: 1,
      editable: false,
      renderCell: (params) => {
        const formattedDate = new Date(params.row.created).toLocaleDateString(
          "en-US",
          {
            month: "short",
            day: "numeric",
            year: "numeric",
          }
        );

        return (
          <>
            <Box sx={{ display: "flex", width: "100%", pr: 2 }}>
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  // borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                  pb: 0.2,
                  pl: 1,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "0.75rem",
                    color: "rgba(0, 0, 0, 0.54)",
                  }}
                >
                  {formattedDate}
                </Typography>
                <Typography variant="body1" sx={{ fontSize: "0.85rem" }}>
                  {params.value}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "0.75rem",
                    color: "rgba(0, 0, 0, 0.54)",
                  }}
                >
                  {params.row?.price}
                </Typography>
              </Box>
              <Box sx={{ display: "flex" }}>
                <IconButton
                  href={params.row.invoice_pdf}
                  target="_blank"
                  variant="contained"
                  color="primary"
                >
                  <CloudDownloadIcon width={25} />
                </IconButton>
              </Box>
            </Box>
          </>
        );
      },
    },
  ];

  const invoiceColumns = [
    { field: "id", headerName: "Invoice Number", width: 160 },
    {
      field: "created",
      headerName: "Date",
      width: 150,
      editable: false,
    },
    {
      field: "billing_reason",
      headerName: "Billing Reason",
      width: 160,
      editable: false,
    },
    {
      field: "status",
      headerName: "Status",
      width: 110,
      editable: false,
    },
    {
      field: "plan",
      headerName: "Plan",
      width: 170,
      editable: false,
    },
    {
      field: "price",
      headerName: "Price",
      width: 110,
      editable: false,
    },
    {
      field: "interval",
      headerName: "Interval",
      width: 100,
      editable: false,
    },
    {
      field: "hosted_invoice_url",
      headerName: "",
      // type: "actions",
      width: 50,
      renderCell: (params) => {
        return (
          <div>
            <Tooltip title="View Invoice" arrow>
              <IconButton
                href={params.row.hosted_invoice_url}
                target="_blank"
                variant="contained"
                color="warning"
              >
                <OpenInNewIcon width={25} />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "invoice_pdf",
      headerName: "",
      // type: "actions",
      width: 50,
      renderCell: (params) => {
        return (
          <div>
            <Tooltip title="Download Invoice" arrow>
              <IconButton
                href={params.row.invoice_pdf}
                target="_blank"
                variant="contained"
                color="primary"
              >
                <CloudDownloadIcon width={25} />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Card>
        {!tabletSize && <CardHeader title={title} />}

        <CardContent
          sx={{
            p: tabletSize ? 0 : undefined,
          }}
        >
          <DataGrid
            apiRef={apiRef}
            sx={{
              "& .MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                outline: "none !important",
              },
              "& .MuiDataGrid-columnHeader:focus-within": {
                outline: "none !important",
              },
              "& .MuiDataGrid-cell": {
                borderColor: tabletSize ? "rgba(0, 0, 0, 0.1)" : undefined, // Change cell border color
                p: tabletSize ? 0 : undefined,
              },
              "& .MuiDataGrid-columnHeader": tabletSize
                ? {
                    backgroundColor: "#333333", // Change column header background color
                    color: "white",
                    fontSize: "15px",
                    paddingLeft: 1,
                    paddingRight: 2,
                    margin: 0,
                    borderTopRightRadius: 0,
                    borderTopLeftRadius: 0,
                  }
                : {},
              "& .MuiDataGrid-columnHeaders": {
                padding: 0,
                margin: 0,
                // borderTopRightRadius: 0,
                // borderTopLeftRadius: 0,
              },
              "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                outline: "none !important",
              },
            }}
            rows={invoiceRow}
            columns={tabletSize ? invoiceColumnsSmall : invoiceColumns}
            getRowId={(row) => row?.id}
            rowHeight={tabletSize ? 75 : undefined}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5, 10, 25, 50, 100]}
            disableRowSelectionOnClick
            onRowClick={(params, event, details) =>
              handleOpen(params, invoiceRow.indexOf(params.row))
            }
            disableColumnFilter={tabletSize}
          />
        </CardContent>
      </Card>
      {rowData && (
        <InvoiceModal
          mobileSize={mobileSize}
          tabletSize={tabletSize}
          rowOpen={rowOpen}
          selectedRow={selectedRow}
          rowIndex={rowIndex}
          handleClose={handleClose}
          invoiceRow={invoiceRow}
          handleSwipe={handleSwipe}
          iphone12Height={iphone12Height}
          smallHeight={smallHeight}
        />
      )}
    </>
  );
};

export default InvoiceGrid;
