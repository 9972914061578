import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  FormHelperText,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

function RemovePermitPackage({
  open,
  setOpen,
  onConfirm,
  confirmLoading,
  selectedRow,
  cancelPermitComment,
  setCancelPermitComment,
}) {
  return (
    <Modal open={open}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          bgcolor: "background.paper",
          borderRadius: "10px",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography
          id="modal-modal-title"
          variant="h4"
          align="center"
          component="h2"
          color="error"
        >
          Remove Permit Package
        </Typography>
        <Typography
          align="center"
          id="modal-modal-description"
          sx={{ color: "#e57373", fontWeight: "bold" }}
        >
          Are you sure you want to remove this permit package?
        </Typography>

        <Box sx={{ mt: 2 }}>
          {selectedRow?.city_scraper?.permit_id && (
            <TextField
              variant="filled"
              multiline
              rows={4}
              value={cancelPermitComment}
              onChange={(e) => setCancelPermitComment(e.target.value)}
              label="Please enter a comment about why the permit is being voided"
              fullWidth
            />
          )}
        </Box>

        <Box sx={{ mt: 5, display: "flex", justifyContent: "space-between" }}>
          <LoadingButton
            disabled={Boolean(
              cancelPermitComment.length < 10 &&
                selectedRow?.city_scraper?.permit_id
            )}
            loading={confirmLoading}
            sx={{ mr: 1 }}
            fullWidth
            color="error"
            onClick={onConfirm}
          >
            Remove
          </LoadingButton>
          <Button
            disabled={Boolean(confirmLoading)}
            sx={{ ml: 1 }}
            fullWidth
            variant="contained"
            color="success"
            onClick={() => {
              setCancelPermitComment("");
              setOpen(false);
            }}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default RemovePermitPackage;
