import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  getCitiesForCounty,
  getCombinedListForCity,
  getCombinedList,
} from "../../functions/availablePermitForms";
import { useSelector } from "react-redux";
import ContractorCard from "../permitComponents/ContractorCard";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const SelectPermitPackageForms = (props) => {
  const navigate = useNavigate();

  const availablePermitForms = useSelector(
    (state) => state.nocData?.available_permit_forms
  );

  const handleNavigate = (direction) => {
    let newPage;
    if (direction === "forward") {
      newPage = props?.contractorIndex + 1;
    } else if (direction === "back") {
      newPage = props?.contractorIndex - 1;
    }

    props?.setContractorIndex(newPage);
  };

  useEffect(() => {
    const countyExists = availablePermitForms.some(
      (item) => item[props?.county]
    );

    if (countyExists) {
      props?.setCities(getCitiesForCounty(availablePermitForms, props?.county));
      const forms = getCombinedList(
        availablePermitForms,
        props?.county,
        props?.selectedCity,
        props?.selectedForms,
        props?.setSelectedForms
      );
      props?.setAvailableForms(forms?.permits);
      const containsFloridaTemplates = props?.displayImage?.some((img) =>
        img.includes("florida-templates")
      );
      if (containsFloridaTemplates) {
        props?.setFormDisplay(true);
        props?.setDisplayImage(forms?.nocImg);
      }
    }
  }, [availablePermitForms, props?.selectedCity, props?.county]);

  const handleCardClick = useCallback((item) => {
    props?.setSelectedForms((prevSelectedForms) => {
      const isAlreadySelected = prevSelectedForms.includes(item?.name);
      const updatedSelectedForms = isAlreadySelected
        ? prevSelectedForms.filter((formName) => formName !== item?.name)
        : [...prevSelectedForms, item?.name];

      return updatedSelectedForms;
    });

    props?.setFormDisplay(true);
  }, []);

  useEffect(() => {
    if (props?.selectedForms.length === 0) return;

    const lastFormName = props?.selectedForms[props?.selectedForms.length - 1];
    const lastForm = props?.availableForms.find(
      (form) => form.name === lastFormName
    );

    if (lastForm && Array.isArray(lastForm.img)) {
      const updatedImageUrls = lastForm.img.map(
        (img) => `${process.env.REACT_APP_URL}/${img}`
      );
      props?.setDisplayImage(updatedImageUrls);
    }
  }, [props?.selectedForms, props?.availableForms]);

  const updatePreviewFormImg = useCallback((item, prevSelectedForms) => {
    if (!prevSelectedForms.includes(item?.name) && Array.isArray(item?.img)) {
      const updatedImageUrls = item.img.map(
        (img) => `${process.env.REACT_APP_URL}/${img}`
      );
      props?.setDisplayImage(updatedImageUrls);
    }
  }, []);

  useEffect(() => {
    if (props?.selectedForms.length > 0) {
      const lastFormName =
        props?.selectedForms[props?.selectedForms.length - 1];
      const lastForm = props?.availableForms.find(
        (form) => form.name === lastFormName
      );
      if (lastForm && Array.isArray(lastForm.img)) {
        const updatedImageUrls = lastForm.img.map(
          (img) => `${process.env.REACT_APP_URL}/${img}`
        );
        props?.setDisplayImage(updatedImageUrls);
      }
    }
  }, [props?.selectedForms, props?.availableForms]);

  return (
    <Box>
      <Typography variant="h2" px={2} color="primary" align="center" mt={1}>
        Create Your Permit Package
      </Typography>
      <Typography align="center" color="primary" variant="h5">
        {props?.county} County
      </Typography>
      <Typography variant="h6" px={2} color="primary" align="center" mt={1}>
        {props?.page === 0
          ? "Choose the forms you wish to create for your permit package. Notice of Commencement form is mandatory and will be created first. Once the permit package has been need to be notarized and/or recorded."
          : props?.page === 1 &&
            "If you have previously added more than one contractor, you can select which contractor will be listed on the documents and which one will sign them."}
      </Typography>

      {props?.page === 1 && (
        <Box
          sx={{
            border: "2px solid #d3d3d3",
            boxShadow: "6px 1px 16px 1px rgba(0, 0, 0, 0.3)",
            mx: 2,
            p: 1,
            mt: 1,
            borderRadius: "20px",
          }}
        >
          <Typography
            align="center"
            px={1}
            pb={1}
            mt={1}
            variant="body2"
            fontWeight="bold"
          >
            A permit package name can be added for display purposes only. This
            name will not be included in any documents.
          </Typography>

          <Box sx={{ display: "flex", justifyContent: "center", pb: 1 }}>
            <TextField
              // fullWidth
              // helperText={Boolean(formik.touched.zip) && formik.errors.zip}
              label="Permit Package Name"
              name="ppName"
              // onBlur={formik.handleBlur}
              onChange={(e) => {
                props?.setPpName(e.target.value);
              }}
              type="zip"
              value={props?.ppName}
            />
          </Box>
        </Box>
      )}
      <Box sx={{ px: 2 }}>
        <Typography
          align="center"
          variant={props?.page === 0 ? "h5" : props?.page === 1 && "h4"}
          mt={2}
          mb={1}
        >
          {props?.page === 0
            ? "Select a city to get more forms"
            : props?.page === 1 &&
              props?.currentUser?.user?.contractors?.length > 1
            ? "Who Will Be The Contractor?"
            : props?.page === 1 &&
              props?.currentUser?.user?.contractors?.length === 0
            ? "No Contractor Selected"
            : "Selected Contractor"}
        </Typography>
        {props?.page === 1 &&
          props?.currentUser?.user?.contractors?.length === 0 && (
            <>
              <Typography align="center" variant="h6" mb={2}>
                Please add a new contractor to proceed
              </Typography>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                  onClick={() => {
                    navigate("/contractors", {
                      state: {
                        fromPermit: true,
                        selectedCity: props?.selectedCity,
                        selectedForms: props?.selectedForms,
                        availableForms: props?.availableForms,
                        county: props?.county,
                        displayImage: props?.displayImage,
                        availableFormsNum: props?.availableForms?.length,
                        cities: props?.cities,
                      },
                    });
                  }}
                  variant="contained"
                >
                  Add Contractor
                </Button>
              </Box>
            </>
          )}

        {props?.page === 0 ? (
          <>
            <Box
              sx={{
                display: "flex",
                gap: 2,
                justifyContent: "center",
                alignItems: "center",
                mt: 1,
                mb: 2,
              }}
            >
              <FormControl fullWidth variant="filled" sx={{ width: "15rem" }}>
                <InputLabel id="remove-NumCounties">
                  Available Cities
                </InputLabel>
                <Select
                  size="small"
                  value={props?.selectedCity || ""}
                  labelId="remove-NumCounties"
                  label="removeNumCounties"
                  onChange={(e) => {
                    props?.setSelectedForms([]);
                    props?.setSelectedCity(e.target.value);
                    const nocForm = props?.availableForms.find(
                      (form) => form.name === "noc"
                    );
                    if (nocForm && Array.isArray(nocForm.img)) {
                      const updatedImageUrls = nocForm.img.map(
                        (img) => `${process.env.REACT_APP_URL}${img}`
                      );
                      props?.setDisplayImage(updatedImageUrls);
                    }
                  }}
                  renderValue={(selected) => (
                    <Typography sx={{ fontWeight: "bold", fontSize: "1.3rem" }}>
                      {selected}
                    </Typography>
                  )}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        marginTop: "1.5px",
                        maxHeight: 150, // Set the desired max height for the dropdown menu
                        width: "auto",
                        maxWidth: "100%",
                      },
                    },
                  }}
                >
                  {props?.cities?.map(function (item, i) {
                    const toTitleCase = (str) => {
                      return str.replace(/\w\S*/g, (txt) => {
                        return (
                          txt.charAt(0).toUpperCase() +
                          txt.slice(1).toLowerCase()
                        );
                      });
                    };
                    return (
                      <MenuItem
                        key={i}
                        value={item}
                        sx={{
                          fontWeight:
                            props?.selectedCity === item ? "bold" : "normal",
                          fontSize:
                            props?.selectedCity === item ? "1.1rem" : "inherit",
                        }}
                      >
                        {toTitleCase(item)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
            <Box
              sx={{
                borderBottom: "3px solid #d3d3d3",
              }}
            />
            {props?.countyLoading ? (
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  mt: 10,
                }}
              >
                <CircularProgress color="warning" size="8rem" />
              </Box>
            ) : (
              <Box>
                <Typography mt={1} fontWeight="bold" align="center">
                  Selected {props?.selectedForms?.length}/
                  {props?.availableForms.length}
                </Typography>

                <Box
                  sx={{
                    maxHeight: "35.3rem", // Adjust the max height as needed
                    minHeight: "35rem",
                    overflowY: "auto",
                  }}
                >
                  {props?.availableForms.length > 0 ? (
                    <Grid container spacing={2} mt={2} mb={2}>
                      {props?.availableForms?.map((item, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                          <Card
                            sx={{
                              cursor:
                                item?.name === "noc" ? "normal" : "pointer",
                              border: "3px solid #1976d2",
                              p: 1,
                              minHeight: "14rem",
                              backgroundColor: props?.selectedForms.includes(
                                item?.name
                              )
                                ? "rgba(56, 142, 60, 0.3)"
                                : "#fff",
                            }}
                            onClick={() => {
                              if (item?.name === "noc") {
                                return;
                              } else {
                                handleCardClick(item);
                              }
                            }}
                          >
                            <CardMedia
                              component="img"
                              height="140"
                              image={`${process.env.REACT_APP_URL}${item?.cover_img}`}
                              alt={item.label}
                            />
                            <CardContent
                              className="bottomCard"
                              sx={{ px: 0, pt: 1 }}
                            >
                              <Typography
                                align="center"
                                variant="body2"
                                color="primary"
                                fontWeight="bold"
                                component="div"
                              >
                                {item.label}
                              </Typography>
                            </CardContent>
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    props?.availableForms.length === 0 &&
                    props?.selectedCity && (
                      <Typography
                        align="center"
                        my={3}
                        variant="h6"
                        sx={{ color: "red" }}
                      >
                        Currently, there are no documents available
                      </Typography>
                    )
                  )}
                </Box>
              </Box>
            )}
          </>
        ) : (
          props?.page === 1 &&
          props?.currentUser?.user?.contractors.length >= 1 && (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flex: 1,
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <IconButton
                    onClick={() => handleNavigate("back")}
                    sx={{
                      visibility:
                        props?.contractorIndex === 0 ||
                        props?.currentUser?.user?.contractors?.length <= 1
                          ? "hidden"
                          : "visible",
                    }}
                  >
                    <ArrowBackIosNewIcon
                      sx={{ fontSize: "60px", color: "#d3d3d3" }}
                    />
                  </IconButton>
                </Box>

                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <ContractorCard
                    data={
                      props?.currentUser?.user?.contractors?.[
                        props?.contractorIndex
                      ]
                    }
                    mb={4}
                    colorIndex={props?.contractorIndex}
                    empty={props?.currentUser?.user?.contractors.length === 0}
                    showLicenseInput={false}
                  />
                </Box>
                <Box sx={{ display: "flex", flex: 1, alignItems: "center" }}>
                  <IconButton
                    onClick={() => handleNavigate("forward")}
                    sx={{
                      visibility:
                        props?.currentUser?.user?.contractors?.length <= 0 ||
                        props?.contractorIndex + 1 ===
                          props?.currentUser?.user?.contractors?.length
                          ? "hidden"
                          : "visible",
                    }}
                  >
                    <ArrowForwardIosIcon
                      sx={{ fontSize: "60px", color: "#d3d3d3" }}
                    />
                  </IconButton>
                </Box>
              </Box>
              <Typography align="center" variant="h5">
                {props?.contractorIndex +
                  1 +
                  "/" +
                  props?.currentUser?.user?.contractors?.length}
              </Typography>
            </>
          )
        )}
      </Box>
    </Box>
  );
};

export default SelectPermitPackageForms;
