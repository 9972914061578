import * as yup from "yup";
import YupPassword from "yup-password";
import validator from "validator";
import { green, red } from "@mui/material/colors";
import ChangePlanMultistepForm, { FormStep } from "../ChangePlanMultistepForm";
import { DateTime } from "luxon";
import {
  Box,
  Grid,
  Container,
  Card,
  Button,
  Typography,
  Stack,
  CircularProgress,
  Drawer,
  useMediaQuery,
  useTheme,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import LottieAnimation from "../../animation/LottieAnimation";
import axios from "axios";
import ChoosePlan from "../auth/registerSteps/ChoosePlan";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import TermsAndConditionsModal from "../modals/TermsAndConditionsModal";
import ChangePlan from "../ChangePlan";
import SubscribeExpiredPlan from "../SubscribeExpiredPlan";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import ScheduleIcon from "@mui/icons-material/Schedule";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EventIcon from "@mui/icons-material/Event";
import { fetchUserData } from "../../store/actions/userActions";
import { useDispatch } from "react-redux";
import ChangePlanHeader from "../ChangePlanHeader";
import ChangePlanContractor from "../auth/registerSteps/ChangePlanContractor";
import { fetchSubUserData } from "../../store/actions/subUserActions";
import MobileFooter from "../layout/MobileFooter";

const Subscription = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  //? SCREEN SIZES ##################################################################
  const below1450 = useMediaQuery("(max-width:1450px)");
  const iphone12Height = useMediaQuery("(min-height: 840px)");
  const smallHeight = useMediaQuery("(max-height: 735px)");
  const mobileSize = useMediaQuery(theme.breakpoints.down("sm"));
  const mobileSizeUp = useMediaQuery(theme.breakpoints.up("md"));
  const tabletSize = useMediaQuery(theme.breakpoints.down("md"));
  const largePC = useMediaQuery(theme.breakpoints.up("xl"));
  //? ###############################################################################

  const [oldPlan, setOldPlan] = useState("");
  const [currentPlan, setCurrentPlan] = useState();
  const currentSession = useSelector((state) => state.sessions.currentSession);
  const currentUser = useSelector((state) => state[currentSession].user);
  const [selectedName, setSelectedName] = useState(null);
  const [checked, setChecked] = useState(true);

  const [tiers, setTiers] = useState([]);
  const [terms, setTerms] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [newSubscriptionDrawer, setNewSubscriptionDrawer] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [defPlan, setDefPlan] = useState("");
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [isLoad, setIsload] = useState(false);
  const [buttons, setButtons] = useState(["New Contractor"]);
  const [disableInputs, setDisableInputs] = useState(true);
  const [duplicatedLicenses, setDuplicatedLicenses] = useState([]);
  const [disabledTabs, setDisabledTabs] = useState([]);
  const [validLicense, setValidLicense] = useState(false);
  const [licenseText, setLicenseText] = useState("");
  const [additionalData, setAdditionalData] = useState();
  const [customLicenseType, setCustomLicenseType] = useState("");
  const [oldChecked, setOldChecked] = useState(false);
  const [oldPlanType, setOldPlanType] = useState("");
  const [lastDisabled, setLastDisabled] = useState(true);
  const [contractorList, setContractorList] = useState([]);
  const [timezone, setTimezone] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [renewDate, setRenewDate] = useState(null);

  const [selectedPlan, setSelectedPlan] = useState();
  const [plans, setPlans] = useState();
  const [promoCodeName, setPromoCodeName] = useState("");

  const [contractorIndex, setContractorIndex] = useState(0);
  const [deleteLicenses, setDeleteLicenses] = useState([]);

  let count = 0;

  useEffect(() => {
    if (currentUser?.user?.expired === true) {
      setDefPlan({
        expired: true,
        canceled: false,
        package_type: "Expired Subscription",
      });
    }
  }, [currentUser]);

  const handleRemoveContractor = () => {
    const selectedCon =
      currentUser?.user?.contractors[contractorIndex]?.licenseNumber;

    setDeleteLicenses((prevSelected) => {
      if (prevSelected.includes(selectedCon)) {
        // Remove the license number if it's already included
        return prevSelected.filter((license) => license !== selectedCon);
      } else {
        // Add the license number if it's not included
        return [...prevSelected, selectedCon];
      }
    });
  };

  const handleReactivatePlan = async () => {
    setIsLoading(true);

    try {
      const changePlan = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/stripe/resume-canceled-subscription`,
        { currentSession: currentSession },
        { withCredentials: true }
      );

      if (changePlan.data.success) {
        setDefPlan((prevDefPlan) => ({
          ...prevDefPlan,
          expired: false,
          canceled: false,
          package_type: "Paid Package",
        }));
        setErrorText(changePlan.data.message);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setErrorText(changePlan.data.message);
      }
      if (currentSession === "subUserSession") {
        await dispatch(fetchSubUserData(currentSession));
      } else {
        await dispatch(fetchUserData(currentSession));
      }
    } catch (err) {
      setErrorText(err.message);
      setIsLoading(false);
    }
  };

  const handleCreateExpiredPlan = async () => {
    const priceId = checked
      ? selectedPlan.price_id_year
      : selectedPlan.price_id_month;
    if (!terms) {
      setErrorText("You must accept the terms and conditions.");
    } else {
      setIsLoading(true);

      try {
        const changePlan = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/stripe/expired-create-plan`,
          {
            priceId,
            currentSession: currentSession,
          },
          { withCredentials: true }
        );

        if (changePlan.data.success) {
          window.location.href = changePlan.data.data;
        } else {
          setIsLoading(false);
          setErrorText(changePlan.data.message);
        }
      } catch (err) {
        setErrorText(err.message);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (currentUser) {
      let startDateObj = DateTime.fromMillis(
        currentUser?.user?.current_period_start * 1000
      ).setZone(timezone);

      let renewDateObj = DateTime.fromMillis(
        currentUser?.user?.current_period_end * 1000
      ).setZone(timezone);

      startDateObj = startDateObj.toFormat("LL/dd/yyyy");
      renewDateObj = renewDateObj.toFormat("LL/dd/yyyy");

      setStartDate(startDateObj);
      setRenewDate(renewDateObj);
    }
    // }
  }, [timezone, currentUser]);

  useEffect(() => {
    const getTiers = async () => {
      if (!currentUser || !currentUser.user) {
        if (currentUser) {
          if (currentUser?.role === "notary") {
            navigate("/notary/dashboard");
          }
        }
        return;
      }

      if (currentUser?.subuser) {
        setTimezone(currentUser?.subuser?.timezone);
      } else {
        setTimezone(currentUser?.user?.personal[0]?.timezone);
      }

      if (
        currentUser?.subuser &&
        currentUser?.subuser.role === "Basic User" &&
        !currentUser?.user?.expired
      ) {
        navigate("/dashboard");
      }

      try {
        const url1 = `${process.env.REACT_APP_BASE_URL}/nocApi/stripe/get-current-tier`;
        const url2 = `${process.env.REACT_APP_BASE_URL}/nocApi/data/additional`;

        // Start both requests concurrently
        const [resp, res] = await Promise.all([
          axios.post(
            url1,
            { currentSession: currentSession },
            { withCredentials: true }
          ),
          axios.get(url2),
        ]);

        if (res.data.success) {
          setAdditionalData(res.data.data);
        }

        if (resp?.data?.success) {
          setTiers(resp?.data?.data);
          setPlans(res?.data?.data?.plans);
          setDefPlan(resp?.data?.myPlan);

          setOldPlanType(resp?.data?.myPlan?.license_type);

          setDisableInputs(false);
          if (!res?.data?.data?.plans.includes(resp?.data?.myPlan?.title)) {
            setPromoCodeName(resp?.data?.myPlan?.title);
          } else {
            setPromoCodeName("");
          }
          setChecked(resp?.data?.myPlan?.interval === "month" ? false : true);
          setOldChecked(
            resp?.data?.myPlan?.interval === "month" ? false : true
          );

          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (e) {
        setLoading(false);
      }
    };

    if (count < 1) {
      getTiers();
      count += 1;
    }
  }, [currentUser]);

  useEffect(() => {
    if (errorText !== "") {
      const timer = setTimeout(() => setErrorText(""), 5000);
      return () => clearTimeout(timer);
    }
  }, [errorText]);

  return (
    <>
      {loading ? (
        <>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100vh",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {tabletSize ? (
              <>
                <Box sx={{ mb: 2 }}>
                  <img
                    style={{
                      width: "10rem",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                    alt="Permit Rockstar Logo"
                    src="/img/website_img/PermitRockstar.png"
                  />
                </Box>
                <LottieAnimation />
              </>
            ) : (
              <CircularProgress color="warning" size="10rem" />
            )}
          </Box>
        </>
      ) : (
        <>
          <Box
            sx={{
              mx: largePC ? "auto" : 0,
              maxWidth: "1500px",
              py: 1,
              backgroundColor: "#EEF2F6",
              mb: tabletSize ? "5rem" : undefined,
            }}
          >
            <Grid
              container
              sx={{
                px: mobileSize ? 1 : tabletSize ? 2 : 3,
              }}
            >
              <Card
                mt={1}
                py={3}
                px={mobileSize ? 0 : 3}
                component={Grid}
                container
              >
                <Grid container justifyContent="center">
                  <Grid item xs={12}>
                    {currentUser?.subuser &&
                    currentUser?.subuser?.role === "Basic User" &&
                    currentUser?.user?.expired ? (
                      <></>
                    ) : (
                      <Typography
                        sx={{
                          textAlign: "left",
                          mb: 2,
                          pl: mobileSize ? 2 : undefined,
                        }}
                        variant={mobileSize ? "h6" : "h5"}
                      >
                        Current Plan
                      </Typography>
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <Stack>
                      <Paper
                        elevation={10} // Increase elevation for a stronger shadow
                        sx={
                          !defPlan?.expired
                            ? {
                                pt: 2,
                                width: "100%",
                                margin: "auto",
                                backgroundColor: "#f0f0f0", // Subtle background color
                                borderRadius: "18px", // Rounded corners
                              }
                            : {
                                pt: 2,
                                width: "100%",
                                margin: "auto",
                                backgroundColor: "#FFD9DC", // Subtle background color
                                border: "4px solid #FF0000",
                                borderRadius: "18px", // Rounded corners
                              }
                        } // Subtle 3D effect
                      >
                        <Typography
                          align="center"
                          variant={mobileSize ? "h5" : "h4"}
                          gutterBottom
                          style={
                            defPlan?.expired || defPlan?.canceled
                              ? { color: "#FF0000" }
                              : { color: "#4CAF50" }
                          }
                        >
                          {currentUser?.trial?.has_trial
                            ? "Trial Plan"
                            : defPlan?.package_type}
                        </Typography>
                        {defPlan?.expired && (
                          <>
                            <Typography
                              align="center"
                              variant="body1"
                              gutterBottom
                              style={{ color: "#FF0000" }}
                            >
                              {currentUser?.subuser
                                ? "Your subscription is expired. Please contact your Administrator for further assistance."
                                : "Your payment has failed. Please create a new subscription to continue using our services."}
                            </Typography>
                          </>
                        )}
                        {defPlan?.canceled && (
                          <>
                            <Typography
                              align="center"
                              variant="body1"
                              gutterBottom
                              style={{ color: "#FF0000" }}
                            >
                              {`Your subscription is scheduled to be canceled on ${renewDate}. To continue using NOC Creator, please consider reactivating your subscription.`}
                            </Typography>
                          </>
                        )}
                        <List>
                          <ListItem>
                            <ListItemIcon>
                              <MonetizationOnIcon
                                style={{ color: "#2196F3" }}
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary="Plan Name"
                              secondary={defPlan?.title || "N/A"}
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemIcon>
                              <MonetizationOnIcon
                                style={{ color: "#2196F3" }}
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary="Cost"
                              secondary={
                                (currentUser?.trial?.has_trial
                                  ? "Free Trial"
                                  : currentUser?.subuser &&
                                    currentUser?.subuser?.role ===
                                      "Basic User" &&
                                    currentUser?.user?.expired
                                  ? "-------"
                                  : defPlan?.price !== undefined
                                  ? `$${defPlan?.price}`
                                  : "N/A") || "N/A"
                              }
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemIcon>
                              <ScheduleIcon style={{ color: "#FFC107" }} />
                            </ListItemIcon>
                            <ListItemText
                              primary="Interval"
                              secondary={
                                defPlan?.interval
                                  ? defPlan.interval.charAt(0).toUpperCase() +
                                    defPlan.interval.slice(1) +
                                    "ly"
                                  : "N/A"
                              }
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemIcon>
                              <LocationOnIcon style={{ color: "#E91E63" }} />
                            </ListItemIcon>
                            <ListItemText
                              primary="Counties and NOC"
                              secondary={
                                defPlan?.counties && defPlan?.noc
                                  ? `${defPlan.counties} counties, ${defPlan.noc} NOC`
                                  : "N/A"
                              }
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemIcon>
                              <EventIcon style={{ color: "#9C27B0" }} />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                currentUser?.trial?.has_trial
                                  ? "Trial Subscription Start Date"
                                  : "Subscription Start Date"
                              }
                              secondary={
                                currentUser?.subuser &&
                                currentUser?.subuser?.role === "Basic User" &&
                                currentUser?.user?.expired
                                  ? "-------"
                                  : startDate && startDate !== "12/31/1969"
                                  ? startDate
                                  : "N/A"
                              }
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemIcon>
                              <EventIcon style={{ color: "#9C27B0" }} />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                currentUser?.trial?.has_trial
                                  ? "Trial Subscription End Date"
                                  : "Subscription Renew Date"
                              }
                              secondary={
                                currentUser?.subuser &&
                                currentUser?.subuser?.role === "Basic User" &&
                                currentUser?.user?.expired
                                  ? "-------"
                                  : defPlan?.canceled
                                  ? "-"
                                  : renewDate && renewDate !== "12/31/1969"
                                  ? renewDate
                                  : "N/A"
                              }
                            />
                          </ListItem>
                        </List>
                      </Paper>
                      <Typography
                        variant="body1"
                        color="#F2CD00"
                        fontWeight="bold"
                        textAlign="center"
                        sx={{ height: "1rem", mt: 3 }}
                      >
                        {errorText}
                      </Typography>
                      {currentUser.subuser ? (
                        <></>
                      ) : defPlan?.canceled ? (
                        <>
                          <LoadingButton
                            loading={isLoading}
                            size={mobileSize ? "small" : "large"}
                            onClick={handleReactivatePlan}
                            sx={{
                              mt: mobileSize ? 0 : 2,
                              mx: mobileSize ? 1 : undefined,
                            }}
                            variant="contained"
                          >
                            Re-Activate Plan
                          </LoadingButton>
                        </>
                      ) : (
                        <>
                          <Button
                            size={mobileSize ? "small" : "large"}
                            onClick={() => {
                              setOpenDrawer(true);
                            }}
                            sx={{
                              mt: mobileSize ? 0 : 2,
                              mx: mobileSize ? 1 : undefined,
                            }}
                            variant="outlined"
                          >
                            {defPlan?.expired
                              ? "Create New Subscription"
                              : "Change Plan"}
                          </Button>
                        </>
                      )}
                    </Stack>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            {tabletSize && <MobileFooter pos="bottom" pl="0.5rem" />}
          </Box>
        </>
      )}

      <Drawer
        PaperProps={{
          sx: {
            // width: tabletSize ? "100vw" : "100vw",
            height: "100vh",
            position: "relative",
          },
        }}
        anchor="right"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",

            position: "relative",
            maxWidth: "1500px",
            width: "100%",
            mx: "auto",
          }}
        >
          <ChangePlanHeader
            isLoad={isLoad}
            tabletSize={tabletSize}
            mobileSize={mobileSize}
            title={defPlan?.expired ? "Create Subscription" : "Change Plan"}
            p1={
              defPlan?.expired
                ? "By creating a new subscription, you are authorizing immediate payment processing."
                : "Payment will be taken immediately upon implementation of the changes."
            }
            p2={
              defPlan?.expired
                ? "Any forms previously created will be retained. However, selected counties will be reset, requiring you to make the necessary selections again."
                : "Forms that have already been created will be retained. Selected counties will be lost and you will need to select them again."
            }
            setOpenDrawer={setOpenDrawer}
          />
          <ChangePlanMultistepForm
            setIsload={setIsload}
            currentUser={currentUser}
            type={defPlan?.expired ? "subscribe" : "change"}
            isLoad={isLoad}
            errorText={errorText}
            setErrorText={setErrorText}
            lastDisabled={lastDisabled}
            setLastDisabled={setLastDisabled}
            validLicense={validLicense}
            setContractorList={setContractorList}
            contractorList={contractorList}
            defPlan={defPlan}
            terms={terms}
            selectedName={selectedName}
            oldChecked={oldChecked}
            checked={checked}
            oldPlan={oldPlan}
            setPage={setPage}
            page={page}
            setDuplicatedLicenses={setDuplicatedLicenses}
            selectedPlan={selectedPlan}
            mobileSize={mobileSize}
            tabletSize={tabletSize}
            initialValues={{
              licenseType: contractorList[page]?.licenseType || "",
              licenseNumber: contractorList[page]?.licenseNumber || "",
              contractorName: contractorList[page]?.contractorName || "",
              contractorAddress: contractorList[page]?.contractorAddress || "",
              contractorPhone: contractorList[page]?.contractorPhone || "",
              contractorFax: contractorList[page]?.contractorFax || "",
              contractorEmail: contractorList[page]?.contractorEmail || "",
            }}
            deleteLicenses={deleteLicenses}
            currLicense={
              currentUser?.user?.contractors[contractorIndex]?.licenseNumber
            }
            onSubmit={async (values) => {
              setIsload(true);
              setIsLoading(true);
              setErrorText("Please Wait...");

              const priceId =
                checked === true
                  ? selectedPlan?.yearly_price_id
                  : selectedPlan?.monthly_price_id;

              const plan_obj = {
                title: selectedPlan?.title,
                product_id: selectedPlan?.product_id,
                price_id: priceId,
                monthly_restart: selectedPlan?.monthly_restart,
                license: parseInt(selectedPlan?.license),
                subuser: parseInt(selectedPlan?.subuser),
                noc: selectedPlan?.noc,
                county: selectedPlan?.county,
                interval: checked === true ? "year" : "month",
              };

              values["deleteLicenses"] = deleteLicenses;
              values["plan"] = plan_obj;

              if (defPlan?.expired) {
                try {
                  const changePlan = await axios.post(
                    `${process.env.REACT_APP_BASE_URL}/nocApi/stripe/expired-create-plan`,
                    {
                      values,
                      currentSession: currentSession,
                    },
                    { withCredentials: true }
                  );

                  if (changePlan.data.success) {
                    window.location.href = changePlan.data.data;
                  } else {
                    setIsLoading(false);
                    setErrorText(changePlan.data.message);
                  }
                } catch (err) {
                  setErrorText(err.message);
                  setIsLoading(false);
                }
              } else {
                try {
                  const changePlan = await axios.post(
                    `${process.env.REACT_APP_BASE_URL}/nocApi/stripe/change-plan`,
                    {
                      values,
                      currentSession: currentSession,
                    },
                    { withCredentials: true }
                  );

                  if (changePlan.data.success) {
                    window.location.href = changePlan.data.data;
                  } else {
                    setIsLoading(false);
                    setIsload(false);
                    setErrorText(changePlan.data.message);
                  }
                } catch (err) {
                  setErrorText(err.message);
                  setIsLoading(false);
                  setIsload(false);
                }
              }
            }}
          >
            <FormStep stepName="Choose a Plan" onSubmit={(e) => {}}>
              <ChangePlan
                type={defPlan?.expired ? "subscribe" : "change"}
                isMd={tabletSize}
                setOpenDrawer={setOpenDrawer}
                checked={checked}
                setChecked={setChecked}
                promoCodeName={promoCodeName}
                selectedName={selectedName}
                currentPlan={currentPlan}
                currentUser={currentUser}
                setSelectedName={setSelectedName}
                tiers={tiers}
                trial={currentUser?.trial}
                plans={plans}
                defPlan={defPlan}
                terms={terms}
                setTerms={setTerms}
                setOpen={setOpen}
                errorText={errorText}
                isLoading={isLoading}
                selectedPlan={selectedPlan}
                setSelectedPlan={setSelectedPlan}
                contractorList={contractorList}
                mobileSize={mobileSize}
                tabletSize={tabletSize}
              />
            </FormStep>
            <FormStep stepName="Contractor Details" onSubmit={(e) => {}}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    mt={0}
                    color="primary"
                    align="center"
                    variant={mobileSize ? "h6" : tabletSize ? "h5" : "h3"}
                  >
                    Manage Contractors
                  </Typography>
                  <Typography
                    mt={0}
                    color="primary"
                    align="center"
                    variant="h6"
                    px={1}
                  >
                    The extra contractors should be removed or removed all if
                    the new selected plan allows fewer contractors; then you
                    will be able to add a new contractor in the contractors area
                    if required
                  </Typography>
                </Grid>
              </Grid>
              <Box sx={{ mb: 2 }}></Box>
              <Box sx={{ display: "flex", justifyContent: "center", mb: 1 }}>
                <Typography variant="h6">
                  Allowed Contractors:{" "}
                  <span
                    style={{
                      fontSize: "25px",
                      color:
                        currentUser?.user?.contractors.length -
                          deleteLicenses.length >
                        parseInt(selectedPlan?.license)
                          ? red[500]
                          : green[500],
                    }}
                  >
                    {parseInt(selectedPlan?.license)}
                  </span>{" "}
                  / Selected:{" "}
                  <span
                    style={{
                      fontSize: "25px",
                      color:
                        currentUser?.user?.contractors.length -
                          deleteLicenses.length >
                        parseInt(selectedPlan?.license)
                          ? red[500]
                          : green[500],
                    }}
                  >
                    {currentUser?.user?.contractors.length -
                      deleteLicenses.length}
                  </span>
                </Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "center", mb: 1 }}>
                {currentUser?.user?.contractors?.length > 0 && (
                  <Button
                    disabled={isLoad}
                    onClick={handleRemoveContractor}
                    variant="contained"
                    size="small"
                    color={
                      deleteLicenses?.includes(
                        currentUser?.user?.contractors[contractorIndex]
                          ?.licenseNumber
                      )
                        ? "warning"
                        : "error"
                    }
                  >
                    {deleteLicenses?.includes(
                      currentUser?.user?.contractors[contractorIndex]
                        ?.licenseNumber
                    )
                      ? "Undo"
                      : "Remove"}
                  </Button>
                )}
              </Box>
              <ChangePlanContractor
                contractorIndex={contractorIndex}
                setContractorIndex={setContractorIndex}
                currentUser={currentUser}
                deleteLicenses={deleteLicenses}
                type="subscription"
              />
            </FormStep>
          </ChangePlanMultistepForm>
        </Box>
      </Drawer>

      {/* NEW SUBSCRIPTION DRAWER */}
      <Drawer
        PaperProps={{
          sx: {
            width: tabletSize ? "100vw" : "50vw",
          },
        }}
        anchor="right"
        open={newSubscriptionDrawer}
        onClose={() => setNewSubscriptionDrawer(false)}
      >
        <SubscribeExpiredPlan
          isMd={tabletSize}
          setOpenDrawer={setNewSubscriptionDrawer}
          checked={checked}
          setChecked={setChecked}
          selectedName={selectedName}
          currentPlan={currentPlan}
          tiers={tiers}
          currentUser={currentUser}
          setSelectedName={setSelectedName}
          defPlan={defPlan}
          terms={terms}
          setTerms={setTerms}
          setOpen={setOpen}
          errorText={errorText}
          handleCreateExpiredPlan={handleCreateExpiredPlan}
          isLoading={isLoading}
        />
      </Drawer>
    </>
  );
};

export default Subscription;
