import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  Box,
  Dialog,
  Grid,
  Button,
  IconButton,
} from "@mui/material";

const AvailableCountiesModal = ({
  open,
  onClose,
  counties,
  mobileSize,
  tabletSize,
  selectedCounty,
  setSelectedCounty,
}) => {
  const handleClose = () => {
    setSelectedCounty("");
    onClose();
  };

  const handleCountyClick = (county) => {
    setSelectedCounty(county);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={tabletSize ? "lg" : "md"} // Corrected maxWidth for tabletSize
      fullWidth
      PaperProps={{
        sx: {
          margin: tabletSize ? "0px" : undefined,
          padding: tabletSize ? "0px" : undefined,
          width: tabletSize ? "100%" : undefined,
          display: "flex",
          p: tabletSize ? undefined : 2, // Ensured fallback to default padding
          px: tabletSize ? "5px" : undefined, // Corrected conditional styling
          py: tabletSize ? "2rem" : undefined, // Corrected conditional styling
          backgroundColor: "#f0f0f0",
          overflow: "hidden",
        },
      }}
      aria-labelledby="available-counties-modal-title"
      aria-describedby="available-counties-modal-description"
    >
      <Grid container>
        <Grid position="relative" item md={6} xs={12}>
          <Typography
            variant="h6"
            id="available-counties-modal-title"
            gutterBottom
          >
            Available Counties - 67
          </Typography>
          <List
            sx={{
              height: tabletSize ? "11em" : "44rem",
              overflowY: "auto",
              pr: "10px",
            }}
          >
            {counties.map((county, index) => (
              <ListItem
                sx={
                  selectedCounty === county
                    ? {
                        pl: 0,
                        cursor: "pointer",
                        backgroundColor: "#f2cd00",
                        pl: "10px",
                      }
                    : { pl: 0, cursor: "pointer" }
                }
                key={index}
                onClick={() => handleCountyClick(county)}
              >
                <ListItemText primary={county} />
              </ListItem>
            ))}
          </List>
          {!tabletSize && (
            <Box>
              <Button
                sx={{
                  width: "96%",
                }}
                variant="contained"
                onClick={handleClose}
              >
                Close
              </Button>
            </Box>
          )}
          {tabletSize && (
            <IconButton
              onClick={handleClose}
              sx={{ position: "absolute", top: -25, right: -8 }}
            >
              <CloseIcon />
            </IconButton>
          )}
        </Grid>
        <Grid item md={6} xs={12}>
          {selectedCounty && (
            <Box sx={{ mt: 2, overflowY: "auto", height: "50rem" }}>
              {!tabletSize && (
                <Typography variant="h6" gutterBottom>
                  {selectedCounty}
                </Typography>
              )}

              {["Brevard", "Polk", "Taylor"].includes(selectedCounty) ? (
                <>
                  <img
                    src={`${process.env.REACT_APP_URL}/images/noc/${selectedCounty}1.jpg`}
                    alt={`${selectedCounty}1`}
                    style={{
                      width: "100%",
                      cursor: "pointer",
                      transition: "transform 0.2s ease",
                      "&:hover": {
                        transform: "scale(1.1)",
                      },
                    }}
                  />
                  <img
                    src={`${process.env.REACT_APP_URL}/images/noc/${selectedCounty}2.jpg`}
                    alt={`${selectedCounty}2`}
                    style={{
                      width: "100%",
                      cursor: "pointer",
                      transition: "transform 0.2s ease",
                      "&:hover": {
                        transform: "scale(1.1)",
                      },
                    }}
                  />
                </>
              ) : (
                <img
                  src={`${process.env.REACT_APP_URL}/images/noc/${selectedCounty}.jpg`}
                  alt={selectedCounty}
                  style={{
                    width: "100%",
                    // maxWidth: 400,
                    cursor: "pointer",
                    transition: "transform 0.2s ease",
                    "&:hover": {
                      transform: "scale(1.1)",
                    },
                  }}
                />
              )}
            </Box>
          )}
          {!selectedCounty && (
            <Typography variant="body2" sx={{ mt: 2 }}>
              Click on a county to view its image
            </Typography>
          )}
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default AvailableCountiesModal;
