import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  TextField,
  Typography,
  FormControl,
  Tooltip,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import ClearIcon from "@mui/icons-material/Clear"; // Import the ClearIcon

export const Advertising = ({
  title,
  advertisingRows,
  setManagementData,
  isSm,
}) => {
  const currentSession = useSelector((state) => state.sessions.currentSession);
  const [selectedCell, setSelectedCell] = useState(null);
  const [textFieldValue, setTextFieldValue] = useState("");
  const [newCellText, setNewCellText] = useState("");
  const [selectValue, setSelectValue] = useState("");
  const [removeLoading, setRemoveLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [addLinkValue, setAddLinkValue] = useState("");
  const [addTextValue, setAddTextValue] = useState("");

  useEffect(() => {
    const clearTextInterval = setInterval(() => {
      if (errorText !== "") {
        setErrorText("");
      }
    }, 3000);

    return () => {
      clearInterval(clearTextInterval); // Clear the interval when the component unmounts
    };
  }, [errorText]);

  const handleEdit = async (type) => {
    const dataObj = {
      submitType: type,
      id: selectedCell?.id,
      column: selectedCell?.field,
      oldValue: selectedCell?.formattedValue,
      changeValue: textFieldValue,
      addCategory: selectValue,
      addValue: newCellText,
      addTextValue: addTextValue,
      addLinkValue: addLinkValue,
    };

    try {
      if (type === "remove") {
        setRemoveLoading(true);
      } else if (type === "edit") {
        setEditLoading(true);
      } else if (type === "add") {
        setAddLoading(true);
      }

      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/admin/advert-management`,
        {
          dataObj,
          currentSession: currentSession,
        },
        { withCredentials: true }
      );

      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/data/managementData`,
        { currentSession: currentSession },
        { withCredentials: true }
      );

      setManagementData(res.data.data);

      setErrorText(resp.data.message);

      if (type === "remove") {
        setRemoveLoading(false);
      } else if (type === "edit") {
        setEditLoading(false);
      } else if (type === "add") {
        setAddLoading(false);
      }

      setSelectedCell(null);
      setTextFieldValue("");
      setNewCellText("");
      setSelectValue("");
      setAddLinkValue("");
      setAddTextValue("");
    } catch (e) {}
  };

  const handleCellClick = (params) => {
    setSelectedCell(params);
    setTextFieldValue(params?.formattedValue);
  };

  const renderSelectedValues = (selected) => {
    if (!Array.isArray(selected)) {
      selected = [selected]; // Convert to an array if not already
    }

    if (selected.length === 0) {
      return <em>Select an option</em>;
    }

    // Join the selected items into a string
    const selectedText = selected.join(", ");

    // Define the maximum length you want to display
    const maxLength = 30; // For example, limit to 20 characters

    // Truncate the text if it exceeds the maximum length
    const truncatedText =
      selectedText.length > maxLength
        ? selectedText.substring(0, maxLength) + "..."
        : selectedText;

    return truncatedText;
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const advertisingColumns = [
    {
      field: "text",
      headerName: "Advertising Texts",
      width: "100%",
      flex: 1,
      headerClassName: "management-datagrid-title",
    },
    {
      field: "link",
      headerName: "Links",
      width: "100%",
      flex: 1,
      headerClassName: "management-datagrid-title",
    },
  ];

  return (
    <Card sx={{ minHeight: "35rem" }}>
      <CardHeader title={title} />
      <CardContent>
        <DataGrid
          sx={{
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
            minHeight: "24rem",
          }}
          rows={advertisingRows}
          columns={advertisingColumns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5, 10, 25, 50, 100]}
          disableRowSelectionOnClick
          onCellClick={handleCellClick}
          classes={{
            cell: "cell", // Apply custom cell class
          }}
        />

        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <Typography sx={{ fontWeight: "bold" }}>
              {selectedCell && (
                <>
                  {selectedCell.colDef.headerName} -{" "}
                  <span style={{ fontWeight: "normal" }}>
                    {selectedCell.formattedValue}
                  </span>
                </>
              )}
            </Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <TextField
              disabled={!selectedCell}
              label={!selectedCell ? "Select Cell" : "Enter New Value"}
              variant="filled"
              fullWidth
              value={textFieldValue || ""}
              onChange={(e) => setTextFieldValue(e.target.value)}
              InputProps={{
                startAdornment: selectedCell &&
                  selectedCell.colDef.headerName === "Footer Links" && (
                    <InputAdornment position="start">
                      <Typography>https://www.</Typography>
                    </InputAdornment>
                  ),
                endAdornment: selectedCell ? (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        setTextFieldValue("");
                        setSelectedCell(null);
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ) : null,
              }}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <LoadingButton
              loading={editLoading}
              disabled={
                !selectedCell ||
                textFieldValue === selectedCell?.formattedValue ||
                removeLoading ||
                addLoading
              }
              fullWidth
              variant="contained"
              color="warning"
              onClick={() => {
                handleEdit("edit");
              }}
            >
              Edit
            </LoadingButton>
          </Grid>
          <Grid item xs={12} md={2}>
            <LoadingButton
              loading={removeLoading}
              disabled={!selectedCell || editLoading || addLoading}
              fullWidth
              variant="contained"
              color="error"
              onClick={() => {
                handleEdit("remove");
              }}
            >
              Remove
            </LoadingButton>
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              label="Enter Advertising Text"
              variant="filled"
              fullWidth
              value={addTextValue || ""}
              onChange={(e) => setAddTextValue(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              label="Enter Link"
              variant="filled"
              fullWidth
              value={addLinkValue || ""}
              onChange={(e) => setAddLinkValue(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Typography>https://www.</Typography>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <LoadingButton
              loading={addLoading}
              disabled={
                !addTextValue || !addLinkValue || removeLoading || editLoading
              }
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => {
                handleEdit("add");
              }}
            >
              Add
            </LoadingButton>
          </Grid>
        </Grid>
        <Typography
          variant="body1"
          color="#F2CD00"
          fontWeight="bold"
          textAlign="center"
          sx={{ mt: "1rem", minHeight: "1.5rem" }}
        >
          {errorText}
        </Typography>
      </CardContent>
    </Card>
  );
};
