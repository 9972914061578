import { useCallback } from "react";
import axios from "axios";
import { Formik } from "formik";
import { loadStripe } from "@stripe/stripe-js";
import {
  Typography,
  CircularProgress,
  Box,
  Container,
  Card,
  CardContent,
  Stack,
  Grid,
  CardHeader,
  Stepper,
  Step,
  StepLabel,
  Button,
  Modal,
  useMediaQuery,
  useTheme,
  Collapse,
} from "@mui/material";
import MobileUploader from "../popover/MobileUploader";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import FileUpload from "../fileUploader/stepComponents/FileUpload";
import DocumentDetails from "../fileUploader/stepComponents/DocumentDetails";
import FinalizeUpload from "../fileUploader/stepComponents/FinalizeUpload";
import { schema1, schema2 } from "../fileUploader/UploaderValidationSchemas";
import { LoadingButton } from "@mui/lab";
import MobileFooter from "../layout/MobileFooter";
import MobileFileUpload from "../fileUploader/stepComponents/MobileFileUpload";
import FileUploadBox from "../fileUploader/stepComponents/FileUploadBox";
import MobileDocumentDetails from "../fileUploader/stepComponents/MobileDocumentDetails";
import MobileFinalizeUpload from "../fileUploader/stepComponents/MobileFinalizeUpload";

const Uploader = () => {
  const currentSession = useSelector((state) => state.sessions.currentSession);
  const currentUser = useSelector((state) => state[currentSession].user);
  const theme = useTheme();
  //? SCREEN SIZES ##################################################################
  const up1870 = useMediaQuery("(min-width:1870px)");
  const below1450 = useMediaQuery("(max-width:1450px)");
  const miniSize = useMediaQuery("(max-width:320px)");
  const iphone12Height = useMediaQuery("(min-height: 840px)");
  const mobileSize = useMediaQuery(theme.breakpoints.down("sm"));
  const tabletSize = useMediaQuery(theme.breakpoints.down("md"));
  //? ###############################################################################

  const navigate = useNavigate();
  const [contractors, setContractors] = useState([]);
  const [paymentCards, setPaymentCards] = useState([]);
  const [prices, setPrices] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitError, setSubmitError] = useState("");
  const [successModal, setSuccessModal] = useState(false);
  const [uploadError, setUploadError] = useState("");
  const stepFields = [
    ["file", "county", "documentType", "reason"], // Fields for step 0
    [
      "propertyAddress",
      "contractor",
      "signeeFirst",
      "signeeLast",
      "email",
      "signeePhone",
    ], // Fields for step 1
    // Add arrays for further steps as needed
  ];

  //! MOBILE STYLES ///////////////////////////////////////////////////////////////////////
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);
  const [mobileUploaderOpen, setMobileUploaderOpen] = useState(false);
  const [uploadingEnabled, setUploadingEnabled] = useState(true);
  const [previewEnabled, setPreviewEnabled] = useState(false);
  const [exitMobileUploaderModal, setExitMobileUploaderModal] = useState(false);

  const handleMobileUploaderClose = () => {
    setMobileUploaderOpen(false);
    setUploadError("");
  };

  const handleUploadingEnabled = () => {
    if (uploadingEnabled) {
      return;
    }

    setUploadingEnabled(true);
    setPreviewEnabled(false);
  };

  const handlePreviewEnabled = () => {
    if (previewEnabled) {
      return;
    }
    setPreviewEnabled(true);
    setUploadingEnabled(false);
  };

  useEffect(() => {
    if (!tabletSize && exitMobileUploaderModal) {
      setExitMobileUploaderModal(false);
    }
  }, [tabletSize, exitMobileUploaderModal]);
  //! ///////////////////////////////////////////////////////////////////////

  let count = 0;
  const hasStepErrors = (stepNumber, errors) => {
    const fields = stepFields[stepNumber];
    return fields.some((field) => errors[field]);
  };

  useEffect(() => {
    let timeoutID;
    if (submitError !== "") {
      // Use setTimeout instead of setInterval
      timeoutID = setTimeout(() => {
        setSubmitError("");
      }, 5000);
    }
    return () => clearTimeout(timeoutID);
  }, [submitError]);
  useEffect(() => {
    const getContractors = async () => {
      try {
        const resp = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/data/my-contractors`,
          { currentSession: currentSession },

          {
            withCredentials: true,
          }
        );
        setContractors(resp?.data?.data);
        setPrices(resp?.data?.prices);
      } catch (e) {}
    };

    if (count < 1) {
      count += 1;
      getContractors();
      setPaymentCards(
        currentUser?.payment_cards.map((card) => {
          const lastTwo = String(card.exp_year).slice(-2);
          const first = card.exp_month;

          return {
            id: card.id,
            cardNum: "**** **** **** " + card.last4,
            cardName: card.name ? card.name : card.card_holder,
            expires:
              (first < 10 ? "0" + String(first) : String(first)) +
              "/" +
              lastTwo,
            default: card.default,
          };
        })
      );
    }
  }, []);

  const initialValues = {
    file: null,
    county: "",
    documentType: "",
    propertyAddress: "",
    reason: "",
    previewUrl: "",
    files: [],
    fileName: "",
    propertyAddress: "",
    contractor: "",
    signeeFirst: "",
    signeeLast: "",
    email: "",
    signeePhone: "",
  };

  const [loading, setLoading] = useState(true);

  const [stepLabel, setStepLabel] = useState("");
  const [stepNumber, setStepNumber] = useState(0);
  const [snapshot, setSnapshot] = useState(initialValues);

  const steps = [
    {
      stepName: tabletSize ? "Document Details" : "Upload Document",
      validationSchema: schema1,
      Component: tabletSize ? MobileFileUpload : FileUpload,
    },
    {
      stepName: "Property Details",
      validationSchema: schema2,
      Component: tabletSize ? MobileDocumentDetails : DocumentDetails,
    },
    {
      stepName: "Finalize",
      Component: tabletSize ? MobileFinalizeUpload : FinalizeUpload,
    },
  ];
  const step = steps[stepNumber];
  const totalSteps = steps.length;
  const isLastStep = stepNumber === totalSteps - 1;

  const StepComponent = step.Component || null;
  const validationSchema = step.validationSchema;

  const updateStepLabel = useCallback(
    (stepIndex) => {
      if (stepIndex >= 0 && stepIndex < steps.length) {
        setStepLabel(steps[stepIndex].stepName);
      }
    },
    [steps]
  );

  useEffect(() => {}, [stepNumber, StepComponent]);

  useEffect(() => {
    updateStepLabel(stepNumber);
  }, [stepNumber]);

  useEffect(() => {
    if (!currentUser || !currentUser.user) {
      if (currentUser) {
        if (currentUser?.role === "notary") {
          navigate("/notary/dashboard");
        }
        if (currentUser?.user?.expired === true) {
          navigate("/subscription");
        }
      }
      return;
    }

    setLoading(false);
  }, [currentUser]);

  const nextStep = (values) => {
    setSnapshot(values);
    setStepNumber(stepNumber + 1);
  };

  const previousStep = (values) => {
    setSnapshot(values);
    setStepNumber(stepNumber - 1);
  };

  const handleGoBack = (values) => {
    previousStep(values);
    updateStepLabel(stepNumber - 1);
  };
  const handleSubmit = async (values, actions) => {
    if (isLastStep) {
      setSubmitLoading(true);
      const selectedCard = paymentCards[currentIndex];
      const { files, ...payload } = values;
      try {
        const resp = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/stripe/make-external-doc-payment`,
          {
            paymentCard: selectedCard.id,
            reason: values.reason,
            currentSession: currentSession,
          },
          { withCredentials: true }
        );

        const stripe = await loadStripe(process.env.REACT_APP_STRIPE); // Replace with your actual publishable key
        const result = await stripe.confirmCardPayment(resp.data.data);
        if (result.error) {
          setSubmitError(result.error.message);
          // Handle the error on the frontend
        } else {
          // The payment has been processed!
          if (
            result.paymentIntent.status === "succeeded" &&
            resp.data.valid === true
          ) {
            payload["paymentIntent"] = result.paymentIntent;
            payload["paymentCard"] = selectedCard.id;
            const resp = await axios.post(
              `${process.env.REACT_APP_BASE_URL}/nocApi/stripe/finish-external-doc-payment`,
              {
                payload,
                currentSession: currentSession,
              },
              { withCredentials: true }
            );

            if (resp.data.success === true) {
              setSuccessModal(true);
            }
          }
        }

        setSubmitLoading(false);
      } catch (e) {
        setSubmitLoading(false);
      }
    } else {
      actions.setTouched({});
      nextStep(values);
    }
  };

  return (
    <>
      {loading ? (
        <>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100vh",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CircularProgress color="warning" size="10rem" />
          </Box>
        </>
      ) : (
        <>
          <Formik
            initialValues={snapshot}
            onSubmit={handleSubmit}
            enableReinitialize={true}
            validationSchema={validationSchema}
          >
            {(formik) => (
              <>
                <Card
                  sx={{
                    overflowX: "hidden",
                    height: tabletSize
                      ? "calc(100vh - 190px)"
                      : "calc(100vh - 240px)",
                    py: tabletSize ? 2 : 3,
                    px: tabletSize ? 1 : 3,
                    mt: 2,
                    mb: 2,
                    mx: up1870 ? "auto" : tabletSize ? 1 : 3,
                    maxWidth: "1500px",
                  }}
                >
                  <CardHeader
                    sx={{ p: 0, textAlign: "center" }}
                    title={
                      <Stack>
                        <Typography
                          color="primary"
                          variant={mobileSize ? "h5" : "h3"}
                          mb={1}
                        >
                          Document Uploader
                        </Typography>
                        <Typography
                          color="primary"
                          variant="body2"
                          sx={{ fontWeight: "bold" }}
                        >
                          This page allows you to upload external documents in
                          order to be notarized, recorded or both.
                          {!tabletSize &&
                            "Once the documents have been uploaded, the wizard will guide you through the process. Your documents will then be sent to a notary or recorder who will review them and either approve or deny them. You will receive a notarized or recorded copy once your documents have been approved. You can find these documents in the 'Document Uploader' page."}
                        </Typography>
                        {tabletSize && (
                          <>
                            <Button
                              sx={{ mt: iphone12Height ? 2 : undefined }}
                              color="warning"
                              size="small"
                              variant={
                                isCollapseOpen ? "contained" : "outlined"
                              }
                              onClick={() => {
                                setIsCollapseOpen(!isCollapseOpen);
                              }}
                            >
                              More Info
                            </Button>
                            <Collapse
                              in={isCollapseOpen}
                              sx={{ width: "100%" }}
                            >
                              <Box pb={1} bgcolor="background.paper">
                                <Typography
                                  color="primary"
                                  variant="body2"
                                  sx={{ fontWeight: "bold" }}
                                >
                                  Once the documents have been uploaded, the
                                  wizard will guide you through the process.
                                  Your documents will then be sent to a notary
                                  or recorder who will review them and either
                                  approve or deny them. You will receive a
                                  notarized or recorded copy once your documents
                                  have been approved. You can find these
                                  documents in the 'Document Uploader' page.
                                </Typography>
                              </Box>
                            </Collapse>
                          </>
                        )}
                      </Stack>
                    }
                  />

                  <form onSubmit={formik.handleSubmit}>
                    {/* DIALOG APP BAR POP UP MODULE FOR MOBILE*/}
                    {tabletSize && (
                      <MobileUploader
                        mobileUploaderOpen={mobileUploaderOpen}
                        tabletSize={tabletSize}
                        handleMobileUploaderClose={handleMobileUploaderClose}
                        mobileSize={mobileSize}
                        contractors={contractors}
                        paymentCards={paymentCards}
                        currentIndex={currentIndex}
                        setCurrentIndex={setCurrentIndex}
                        prices={prices}
                        submitError={submitError}
                        setMobileUploaderOpen={setMobileUploaderOpen}
                        stepNumber={stepNumber}
                        steps={steps}
                        iphone12Height={iphone12Height}
                        StepComponent={StepComponent}
                        handleUploadingEnabled={handleUploadingEnabled}
                        handlePreviewEnabled={handlePreviewEnabled}
                        uploadingEnabled={uploadingEnabled}
                        previewEnabled={previewEnabled}
                        exitMobileUploaderModal={exitMobileUploaderModal}
                        setExitMobileUploaderModal={setExitMobileUploaderModal}
                        handleGoBack={handleGoBack}
                        submitLoading={submitLoading}
                        isLastStep={isLastStep}
                        hasStepErrors={hasStepErrors}
                        initialValues={initialValues}
                        setStepNumber={setStepNumber}
                        miniSize={miniSize}
                        below1450={below1450}
                      />
                    )}
                    {/* ********************************************************************* */}
                    <CardContent
                      sx={{
                        py: tabletSize ? 0 : undefined,
                        display: "flex",
                        px: 0,
                        flexDirection: "column",
                        height: "60vh", // Ensure CardContent takes the full height of the Card.
                      }}
                    >
                      {/* STEP COMPONENT/BUTTONS FOR NON MOBILE SIZES */}
                      {!tabletSize && (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            marginBottom: tabletSize ? "0rem" : "1.5rem",
                            marginTop: tabletSize ? "1rem" : undefined,
                          }}
                        >
                          <Stepper activeStep={stepNumber}>
                            {steps.map((currentStep, id) => {
                              const label = currentStep.stepName;
                              return (
                                <Step key={id}>
                                  <StepLabel>{label}</StepLabel>
                                </Step>
                              );
                            })}
                          </Stepper>
                        </Box>
                      )}
                      {/* ************************************************ */}
                      {/* MAIN PAGE FOR MOBILE SIZE UPLOADER BOX */}
                      {tabletSize && (
                        <>
                          <Typography
                            sx={{
                              mt: iphone12Height ? 10 : 3,
                              mb: 1,
                              fontWeight: "bold",
                            }}
                            variant="h5"
                            align="center"
                          >
                            Upload Document
                            {/* {steps[stepNumber].stepName} */}
                          </Typography>
                          <FileUploadBox
                            formik={formik}
                            uploadError={uploadError}
                            setUploadError={setUploadError}
                            mobileSize={mobileSize}
                            tabletSize={tabletSize}
                            setMobileUploaderOpen={setMobileUploaderOpen}
                          />
                        </>
                      )}
                      {/* ****************************************************************** */}
                      {/* STEP COMPONENT/BUTTONS FOR NON MOBILE SIZES */}
                      {!tabletSize && (
                        <>
                          <Box
                            sx={{
                              flexGrow: 1,
                              overflowY: "auto",
                              height: "100%",
                            }}
                          >
                            {StepComponent && (
                              <StepComponent
                                formik={formik}
                                contractors={contractors}
                                paymentCards={paymentCards}
                                currentIndex={currentIndex}
                                setCurrentIndex={setCurrentIndex}
                                prices={prices}
                                submitError={submitError}
                                mobileSize={mobileSize}
                                tabletSize={tabletSize}
                                setMobileUploaderOpen={setMobileUploaderOpen}
                                setUploadError={setUploadError}
                                uploadError={uploadError}
                                initialValues={initialValues}
                              />
                            )}
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              gap: "10px",
                              mt: 1.5,
                            }}
                          >
                            <Button
                              disabled={
                                stepNumber === 0 || submitLoading === true
                              }
                              onClick={() => {
                                handleGoBack(formik?.values);
                              }}
                              sx={{
                                minWidth: "15rem",
                                display:
                                  stepNumber === 0 ? "none" : "inline-flex",
                              }}
                              variant="contained"
                              color="warning"
                              startIcon={<ArrowBackIosIcon />}
                            >
                              Previous
                            </Button>
                            {isLastStep ? (
                              <>
                                <LoadingButton
                                  loading={submitLoading}
                                  type="submit"
                                  sx={{
                                    minWidth: "15rem",
                                  }}
                                  variant="contained"
                                  color="success"
                                >
                                  Pay and Start Processing Document
                                </LoadingButton>
                              </>
                            ) : (
                              <>
                                <Button
                                  type="submit"
                                  sx={{
                                    minWidth: "15rem",
                                    display:
                                      (stepNumber === 0 &&
                                        !formik?.values.file) ||
                                      formik?.values.county === "" ||
                                      formik?.values.documentType === "" ||
                                      formik?.values.reason === "" ||
                                      (stepNumber === 1 &&
                                        (formik?.values.propertyAddress ===
                                          "" ||
                                          formik?.values.contractor === "" ||
                                          formik?.values.signeeFirst === "" ||
                                          formik?.values.signeeLast === "" ||
                                          formik?.values.email === "" ||
                                          formik?.values.signeePhone === "" ||
                                          hasStepErrors(
                                            stepNumber,
                                            formik?.errors
                                          ))) // Update this condition to use hasStepErrors function
                                        ? "none"
                                        : "inline-flex",
                                  }}
                                  variant="contained"
                                  endIcon={<ArrowForwardIosIcon />}
                                >
                                  Next
                                </Button>
                              </>
                            )}
                          </Box>
                        </>
                      )}
                      {/* ********************************************************************** */}
                    </CardContent>
                  </form>
                  {tabletSize && <MobileFooter pos="bottom" pl="0.5rem" />}
                </Card>
                <Modal
                  open={successModal}
                  onClose={() => {
                    setSuccessModal(false);
                    navigate("/permits/internal");
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: mobileSize ? "95%" : 400,
                      bgcolor: "background.paper",
                      boxShadow: 24,
                      borderRadius: "1rem",
                      maxHeight: mobileSize ? "100%" : "90vh", // Set a maximum height
                      overflowY: "auto",
                      p: mobileSize ? 1 : 4,
                    }}
                  >
                    <Typography
                      id="modal-title"
                      variant={mobileSize ? "h4" : "h3"}
                      align="center"
                      component="h2"
                      mt={mobileSize ? 1 : undefined}
                    >
                      Payment Successful
                    </Typography>
                    <Box sx={{ mt: 3 }}>
                      <img
                        style={{ borderRadius: "3rem", width: "100%" }}
                        src="/img/website_img/notarization_payment_success.png"
                      />
                    </Box>
                    <Typography id="modal-description" sx={{ mt: 2 }}>
                      <span
                        style={{
                          fontWeight: "bold",
                          color: "green",
                          width: "100%",
                        }}
                      >
                        Your payment has been successfully processed.
                      </span>
                      <br />
                      {formik?.values?.reason === "Notarization & Recording" ||
                      formik?.values?.reason === "Notarization"
                        ? "Information and invoice details have been sent to your email. We have started the notarization process and are awaiting the property owner to schedule a Remote Online Notarization (RON) session."
                        : "We've emailed you the details and initiated the recording process. Currently, we're waiting for the property owner to provide the necessary details about the property."}
                    </Typography>
                    <Button
                      sx={{ mt: 4, mb: 1 }}
                      variant="contained"
                      fullWidth
                      onClick={() => {
                        if (tabletSize) {
                          navigate("/permits/internal", {
                            state: { fromExternal: true },
                          });
                        } else {
                          navigate("/permits/internal");
                        }
                        setSuccessModal(false);
                      }}
                    >
                      Close
                    </Button>
                  </Box>
                </Modal>
              </>
            )}
          </Formik>
        </>
      )}
    </>
  );
};

export default Uploader;
