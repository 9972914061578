import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useFormik, Formik } from "formik";
import * as Yup from "yup";
import validator from "validator";
import { LoadingButton } from "@mui/lab";
import { useDispatch } from "react-redux";
import { PencilIcon } from "@heroicons/react/24/outline";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Collapse,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import ProfessionalsTextFields from "./ProfessionalsTextFields";
import { addSubUserFullDesignProfessionals } from "../../store/actions/subUserActions";
import { addUserFullDesignProfessionals } from "../../store/actions/userActions";
import ConfirmModal from "../popover/ConfirmModal";

const DesignProfessionals = (props) => {
  const scrollableBoxRef = useRef(null);
  const dispatch = useDispatch();
  const [professionalsIndex, setProfessionalsIndex] = useState(0);
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [addProfessionalsLoading, setAddProfessionalsLoading] = useState(false);
  const [isDuplicated, setIsDuplicated] = useState(false);
  const [canClearErrorText, setCanClearErrorText] = useState(true);
  const [isDesignProfessionalsLocked, setIsDesignProfessionalsLocked] =
    useState(false);
  const [designProfessionalsErrorText, setDesignProfessionalsErrorText] =
    useState("");
  const [waitingColor, setWaitingColor] = useState("red");
  const [gridKey, setGridKey] = useState(0);
  const [requestsSelectionModel, setRequestsSelectionModel] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [formattedPhone, setFormattedPhone] = useState(null);
  const [editEnabled, setEditEnabled] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  // Helper function to truncate text
  const truncateText = (text, maxLength = 30) => {
    if (!text) return "";
    return text.length > maxLength
      ? text.substring(0, maxLength) + "..."
      : text;
  };

  const professionalsColumnsSmall = [
    {
      field: "companyName",
      headerName: "Design Professionals",
      flex: 1,
      editable: false,
      renderCell: (params) => {
        return (
          <>
            <Box sx={{ display: "flex", width: "100%", pr: 1 }}>
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  // borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                  pb: 0.2,
                  pl: 1,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "0.75rem",
                    color: "rgba(0, 0, 0, 0.54)",
                  }}
                >
                  {truncateText(params?.row?.address)}
                </Typography>
                <Typography variant="body1" sx={{ fontSize: "0.85rem" }}>
                  {truncateText(params.value)}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "0.75rem",
                  }}
                >
                  {truncateText(params.row?.licenseName)}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "0.75rem",
                    color: "rgba(0, 0, 0, 0.54)",
                  }}
                >
                  {truncateText(params?.row?.email)}
                </Typography>
              </Box>

              <Box
                sx={{
                  textAlign: "right",
                  display: "flex",
                  flexDirection: "column",
                  pb: 0.2,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "0.75rem",
                    color: "rgba(0, 0, 0, 0.54)",
                  }}
                >
                  {params?.row?.licenseNumber}
                </Typography>
                <Typography variant="body1" sx={{ fontSize: "0.85rem" }}>
                  {params?.row?.phone}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "0.75rem",
                    color: "rgba(0, 0, 0, 0.54)",
                  }}
                >
                  {params?.row?.fax}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "0.75rem",
                    color: "rgba(0, 0, 0, 0.54)",
                  }}
                >
                  {params?.row?.is_subuser ? "by sub-user" : "by main-user"}
                </Typography>
              </Box>
            </Box>
          </>
        );
      },
    },
  ];

  const professionalsColumns = [
    {
      field: "companyName",
      headerName: "Company Name",
      flex: 1,
      editable: false,
    },
    {
      field: "licenseName",
      headerName: "License Name",
      flex: 1,
      editable: false,
    },
    {
      field: "licenseNumber",
      headerName: "License Number",
      flex: 1,
      editable: false,
    },
    {
      field: "address",
      headerName: "Address",
      flex: 1,
      editable: false,
    },
    {
      field: "email",
      headerName: "Email Address",
      flex: 1,
      editable: false,
    },
    {
      field: "phone",
      headerName: "Phone Number",
      flex: 1,
      editable: false,
    },
    {
      field: "fax",
      headerName: "Fax",
      flex: 1,
      editable: false,
    },
  ];

  const professionalsFormik = useFormik({
    initialValues: {
      companyName: selectedRow?.companyName,
      licenseName: selectedRow?.licenseName,
      licenseNumber: selectedRow?.licenseNumber,
      address: selectedRow?.address,
      phone: selectedRow?.phone,
      fax: selectedRow?.fax,
      email: selectedRow?.email,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      companyName: Yup.string()
        .min(3, "Company Name must be at least 3 characters long.")
        .required("Company Name is required"),
      licenseName: Yup.string()
        .min(3, "License Name must be at least 3 characters long.")
        .required("License Name is required"),
      licenseNumber: Yup.string()
        .min(5, "License Number must be at least 5 characters long.")
        .required("License Number is required"),
      address: Yup.string()
        .min(5, "Address must be at least 5 characters long.")
        .required("Address is required"),
      phone: Yup.string()
        .required("Phone Number is required")
        .test("PhoneTest", "Phone number is invalid", function (value) {
          const isValidPhoneNumber = validator.isMobilePhone(value);
          if (isValidPhoneNumber) {
            return true;
          } else {
            return false;
          }
        }),
      fax: Yup.string().matches(
        /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/,
        "Fax Number is Invalid"
      ),
      email: Yup.string()
        .required("Email Address is required")
        .email("Must be a valid email"),
    }),
    onSubmit: async (values, helpers) => {
      if (props?.design_professionals_locked) {
        if (isDesignProfessionalsLocked === false) {
          setIsDesignProfessionalsLocked(true);
          setDesignProfessionalsErrorText(
            "Another user is updating design professionals. Please wait for the update to be completed."
          );
        }
        return;
      }
      if (collapseOpen) {
        setAddProfessionalsLoading(true);
        values["addData"] = true;
      } else if (confirmModalOpen && selectedRow) {
        setDeleteLoading(true);
        values["removeData"] = true;
        values["id"] = selectedRow.id;
      } else if (editEnabled && selectedRow) {
        setSubmitLoading(true);
        values["editData"] = true;
        values["id"] = selectedRow.id;
      }

      setConfirmModalOpen(false);
      setWaitingColor("red");
      setCanClearErrorText(true);
      setIsDesignProfessionalsLocked(false);
      setDesignProfessionalsErrorText("");

      try {
        const resp = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/building/modify-design-professional`,
          {
            values,
            currentSession: props?.currentSession,
          },
          { withCredentials: true }
        );

        if (resp?.data?.success) {
          // professionals_new_data
          if (props?.currentSession === "subUserSession") {
            dispatch(
              addSubUserFullDesignProfessionals(
                resp?.data?.design_professionals
              )
            );
          } else if (props?.currentSession === "userSession") {
            dispatch(
              addUserFullDesignProfessionals(resp?.data?.design_professionals)
            );
          }

          setCollapseOpen(false);
          setAddProfessionalsLoading(false);
          setDeleteLoading(false);
          setSubmitLoading(false);
          setEditEnabled(false);
          setSelectedRow(null);
          helpers.resetForm();
        } else {
          if (resp?.data?.duplicated) {
            setDesignProfessionalsErrorText(resp?.data?.message);
            helpers?.setFieldValue(resp?.data?.duplicated_field, "");
            setAddProfessionalsLoading(false);
            setDeleteLoading(false);
            setSubmitLoading(false);
          } else if (resp?.data?.is_locked) {
            setIsDesignProfessionalsLocked(true);
            setCanClearErrorText(false);
            setDesignProfessionalsErrorText(resp?.data?.message);
            setAddProfessionalsLoading(false);
            setDeleteLoading(false);
            setSubmitLoading(false);
          }
        }
      } catch (e) {
        setAddProfessionalsLoading(false);
        setDeleteLoading(false);
        setSubmitLoading(false);
        setEditEnabled(false);
        setDesignProfessionalsErrorText(e?.response?.data?.message);
      }
    },
  });

  const getProfessionalsData = (params, event, details) => {
    if (deleteLoading || addProfessionalsLoading || submitLoading) {
      return;
    }
    setCollapseOpen(false);
    if (requestsSelectionModel.includes(params.id)) {
      setRequestsSelectionModel([]);
      setGridKey((prevKey) => prevKey + 1);
      setSelectedRow(null);
      setEditEnabled(false);
      professionalsFormik.resetForm();
    } else {
      setRequestsSelectionModel([params.id]);
      setSelectedRow(params.row);
    }
  };

  const confirmedHandleClose = () => {
    professionalsFormik.submitForm();
  };

  useEffect(() => {
    if (canClearErrorText && designProfessionalsErrorText !== "") {
      const timeOutID = setTimeout(() => {
        setDesignProfessionalsErrorText("");
      }, 4000);
      return () => clearTimeout(timeOutID);
    }
  }, [canClearErrorText, designProfessionalsErrorText]);

  useEffect(() => {
    if (
      props?.design_professionals_locked === false &&
      isDesignProfessionalsLocked === true
    ) {
      setDesignProfessionalsErrorText(
        "The user has completed the modifications. You are now able to make changes"
      );
      setIsDesignProfessionalsLocked(false);
      setWaitingColor("#0E870F");
    }
  }, [props?.design_professionals_locked]);

  return (
    <>
      <Card
        py={3}
        px={props?.mobileSize ? 0 : 3}
        mb={2}
        component={Grid}
        sx={!props?.tabletSize ? { width: "100%" } : undefined}
        container
      >
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <Typography sx={{ textAlign: "center" }} variant="h5">
              {/* {defaultCounty ? "Edit Default" : "Add / Edit Defaults"} */}
              Your Design Professionals
            </Typography>
            <Typography sx={{ textAlign: "center" }} variant="body2">
              {/* {defaultCounty ? "Edit Default" : "Add / Edit Defaults"} */}
              You can add, remove, and modify the Design Professionals you are
              using, then you can select one from the list when creating a
              building permit
            </Typography>
            <Typography
              sx={{ textAlign: "center", mb: 5, fontWeight: "bold", px: 1 }}
              color="primary"
              variant="body2"
            >
              (You can update or delete Design Professionals by selecting the
              appropriate row)
            </Typography>
          </Grid>
          {props?.professionalsRow.length !== 0 && (
            <>
              <Grid item xs={12}>
                <Box
                  display="flex"
                  justifyContent={props?.tabletSize ? "center" : undefined}
                  mb={collapseOpen ? 1 : 0}
                >
                  <Button
                    disabled={
                      submitLoading || deleteLoading || addProfessionalsLoading
                    }
                    size={props?.mobileSize ? "small" : undefined}
                    onClick={() => {
                      setCollapseOpen(!collapseOpen);
                      setSelectedRow(null);
                      setRequestsSelectionModel([]);
                      setGridKey((prevKey) => prevKey + 1);
                      setEditEnabled(false);
                      professionalsFormik.resetForm();
                    }}
                    variant={collapseOpen ? "contained" : "outlined"}
                    color={collapseOpen ? "warning" : "success"}
                    endIcon={
                      collapseOpen ? (
                        <RemoveIcon width={props?.mobileSize ? 15 : 20} />
                      ) : (
                        <AddIcon width={props?.mobileSize ? 15 : 20} />
                      )
                    }
                  >
                    {collapseOpen
                      ? "Cancel Adding New Professionals"
                      : "Add New Design Professional"}
                  </Button>
                </Box>

                <Collapse
                  in={collapseOpen}
                  sx={{
                    width: "100%",
                    pb: !collapseOpen && props?.tabletSize ? 1 : 3,
                  }}
                >
                  <Box
                    sx={{
                      px: 1,
                      py: 2,
                      boxShadow: "3px 2px 3px 2px rgba(0, 0, 0, 0.3)",
                      borderRadius: "16px",
                    }}
                  >
                    <ProfessionalsTextFields
                      mobileSize={props?.mobileSize}
                      professionalsFormik={professionalsFormik}
                      setFormattedPhone={setFormattedPhone}
                      addProfessionalsLoading={addProfessionalsLoading}
                      waitingColor={waitingColor}
                      designProfessionalsErrorText={
                        designProfessionalsErrorText
                      }
                    />
                  </Box>
                </Collapse>
              </Grid>
              <Grid item xs={12}>
                <DataGrid
                  key={gridKey}
                  className="cell"
                  sx={{
                    "& .MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                      outline: "none !important",
                    },
                    "& .MuiDataGrid-columnHeader:focus-within": {
                      outline: "none !important",
                    },
                    "& .MuiDataGrid-cell": {
                      borderColor: props?.tabletSize
                        ? "rgba(0, 0, 0, 0.1)"
                        : undefined, // Change cell border color
                      p: props?.tabletSize ? 0 : undefined,
                    },
                    "& .MuiDataGrid-columnHeader": props?.tabletSize
                      ? {
                          backgroundColor: "#333333", // Change column header background color
                          color: "white",
                          fontSize: "15px",
                          paddingLeft: 1,
                          paddingRight: 2,
                          margin: 0,
                          borderTopRightRadius: 0,
                          borderTopLeftRadius: 0,
                        }
                      : {},
                    "& .MuiDataGrid-columnHeaders": {
                      padding: 0,
                      margin: 0,
                      // borderTopRightRadius: 0,
                      // borderTopLeftRadius: 0,
                    },
                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                      outline: "none !important",
                    },
                  }}
                  rows={props?.professionalsRow}
                  columns={
                    props?.tabletSize
                      ? professionalsColumnsSmall
                      : professionalsColumns
                  }
                  getRowId={(row) => row?.id}
                  rowHeight={props?.tabletSize ? 85 : undefined}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 5,
                      },
                    },
                  }}
                  pageSizeOptions={[5, 10, 25, 50, 100]}
                  onRowClick={(params, event, details) => {
                    getProfessionalsData(params, event, details);
                  }}
                  disableColumnFilter={props?.tabletSize}
                />
              </Grid>
            </>
          )}
          {props?.professionalsRow.length === 0 && (
            <Grid item xs={12}>
              <Box
                sx={{
                  width: "100%",
                  mb: 2,
                  py: 2,

                  boxShadow: "3px 2px 3px 2px rgba(0, 0, 0, 0.3)",
                  borderRadius: "16px",
                }}
              >
                <Typography align="center" fontWeight="bold" sx={{ px: 1 }}>
                  There are no Design Professionals recorded in the database.
                </Typography>
                <Typography align="center" variant="body2" sx={{ px: 1 }}>
                  Click on the button below to add one
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    mt: 3,
                    mb: 1,
                  }}
                >
                  <Button
                    size={props?.mobileSize ? "small" : undefined}
                    onClick={() => {
                      setCollapseOpen(!collapseOpen);
                    }}
                    variant={collapseOpen ? "contained" : "outlined"}
                    color={collapseOpen ? "warning" : "success"}
                    endIcon={
                      collapseOpen ? (
                        <RemoveIcon width={props?.mobileSize ? 15 : 20} />
                      ) : (
                        <AddIcon width={props?.mobileSize ? 15 : 20} />
                      )
                    }
                  >
                    {collapseOpen
                      ? "Cancel Adding New Professionals"
                      : "Add New Design Professional"}
                  </Button>
                </Box>

                {/* FORM BASE */}
                {/* ADD NEW */}
                <Collapse in={collapseOpen} sx={{ width: "100%", pb: 3 }}>
                  <Box sx={{ px: 2, mt: 2 }}>
                    <ProfessionalsTextFields
                      mobileSize={props?.mobileSize}
                      professionalsFormik={professionalsFormik}
                      setFormattedPhone={setFormattedPhone}
                      addProfessionalsLoading={addProfessionalsLoading}
                      waitingColor={waitingColor}
                      designProfessionalsErrorText={
                        designProfessionalsErrorText
                      }
                    />
                  </Box>
                </Collapse>
              </Box>
            </Grid>
          )}
          {/* EDIT / REMOVE */}
          <Grid item xs={12}>
            <Collapse
              in={Boolean(selectedRow)}
              sx={{ width: "100%", pb: props?.mobileSize ? 0 : 3 }}
            >
              <Box
                sx={{
                  px: 1,
                  py: 2,
                  boxShadow: "3px 2px 3px 2px rgba(0, 0, 0, 0.3)",
                  borderRadius: "16px",
                }}
              >
                <ProfessionalsTextFields
                  mobileSize={props?.mobileSize}
                  professionalsFormik={professionalsFormik}
                  setFormattedPhone={setFormattedPhone}
                  addProfessionalsLoading={addProfessionalsLoading}
                  waitingColor={waitingColor}
                  designProfessionalsErrorText={designProfessionalsErrorText}
                  edit={true}
                  editEnabled={editEnabled}
                  setEditEnabled={setEditEnabled}
                  setConfirmModalOpen={setConfirmModalOpen}
                  deleteLoading={deleteLoading}
                  submitLoading={submitLoading}
                />
              </Box>
            </Collapse>
          </Grid>
          {/* <Stack direction="row" gap={2}>
          <Button
            variant="outlined"
            color="warning"
            endIcon={<PencilIcon width={props?.mobileSize ? 15 : 20} />}
          >
            Edit / Remove
          </Button>
          
        </Stack> */}
        </Grid>
      </Card>
      <ConfirmModal
        open={confirmModalOpen}
        setOpen={setConfirmModalOpen}
        handleClose={confirmedHandleClose}
        title="Do you really want to remove this Design Professional?"
        subTitle="This action cannot be reversed"
        btnText1="Delete"
        btnText2="Cancel"
      />
    </>
  );
};

export default DesignProfessionals;
