import { DateTime } from "luxon";

const initialState = {
  user: null,
  notaryJobs: null,
  notaryRequests: [],
};

const notarySessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case "DISMISS_RON_REQUEST":
      return {
        ...state,
        notaryRequests: state.notaryRequests.filter(
          (request) => request.id !== action.payload
        ),
      };
    case "DISMISS_ALL_RON_REQUEST":
      return {
        ...state,
        notaryRequests: [],
      };
    case "ADD_REAL_TIME_REQUEST":
      return {
        ...state,
        notaryRequests: [...state.notaryRequests, action.payload],
      };
    case "ADD_NOTARY_USER_DATA":
      return {
        ...state,
        user: action.payload.data,
        notaryJobs: action.payload.jobs,
      };
    case "UPDATE_NOTARY_USER_DATA":
      return {
        ...state,
        user: action.payload.user,
        notaryJobs: action.payload.jobs,
      };
    case "UPDATE_NOTARY_JOBS":
      return {
        ...state,
        notaryJobs: {
          ...state.notaryJobs,
          jobs_list: action.payload.jobs_list,
          accepted_job_list: action.payload.accepted_job_list,
        },
      };
    case "ADD_JOB_TO_QUEUE": //! BROADCAST
      const job = action.payload;
      // Assuming `ronDateRaw` always contains at least one element and the required fields.
      const rawDate = job.ronDateRaw[0];

      // Create a UTC DateTime object from the raw date information
      const utcDate = DateTime.utc(
        rawDate.year,
        rawDate.month,
        rawDate.day_of_month,
        parseInt(rawDate.time.split(":")[0]),
        parseInt(rawDate.time.split(":")[1])
      );

      // Get the user's timezone from the state or default to America/New_York if not available
      const userTimezone = state.user?.timezone || "America/New_York";

      // Convert UTC DateTime to the user's timezone using the `signee_timezone` field
      const localDate = utcDate.setZone(userTimezone);

      // Format the localDate to the desired string format "April 22nd 2024, 10:30 PM"
      const formattedDate = localDate.toFormat("MMMM dd'th' yyyy, h:mm a");

      // Update the job object with the new date string
      const updatedJob = {
        ...job,
        ronDateString: formattedDate,
      };

      return {
        ...state,
        notaryJobs: {
          ...state.notaryJobs,
          jobs_list: [updatedJob, ...state.notaryJobs.jobs_list],
        },
      };
    case "REMOVE_JOB_FROM_QUEUE": //! BROADCAST
      const jobIdToRemove = action.payload.id;
      return {
        ...state,
        notaryJobs: {
          ...state.notaryJobs,
          // Filter out the job that matches the ID to remove
          jobs_list: state.notaryJobs.jobs_list.filter(
            (job) => job.id !== jobIdToRemove
          ),
        },
      };
    case "ADD_ACCEPTED_QUEUE_JOB": //! PERSONAL
      return {
        ...state,
        notaryJobs: {
          ...state.notaryJobs,
          accepted_job_list: [
            action.payload,
            ...state.notaryJobs.accepted_job_list,
          ],
        },
      };
    case "REMOVE_ACCEPTED_QUEUE_JOB": //! PERSONAL
      const acceptedIdToRemove = action.payload.id; // Assuming each job has a unique 'id' field
      return {
        ...state,
        notaryJobs: {
          ...state.notaryJobs,
          // Filter out the job that matches the ID to remove
          accepted_job_list: state.notaryJobs.accepted_job_list.filter(
            (job) => job.id !== acceptedIdToRemove
          ),
        },
      };
    //! //////////////////////////////////////////////////////////////////////////////////
    default:
      return state;
  }
};

export default notarySessionReducer;
