import {
  Box,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const mobileSize = useMediaQuery(theme.breakpoints.down("sm"));
  const tabletSize = useMediaQuery(theme.breakpoints.down("md"));
  const pcSize = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "100vh", // Ensure it covers at least the full viewport height
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-around", // Distributes space around items
        backgroundColor: "#FEFEF1",
        padding: "1rem", // Consistent padding around the edges
        boxSizing: "border-box", // Includes padding in height calculation
      }}
    >
      {/* Logo at the top */}

      {/* Main image and text/button aligned in the middle */}
      <Box
        sx={{
          width: "100%",
          //   maxWidth: "25%",
          flexGrow: 1, // Allows this box to grow and take available space
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          alt="Permit Rockstar Logo"
          src="/img/website_img/PermitRockstar.png"
          style={{ maxWidth: mobileSize ? "50%" : "20%" }}
        />
        <Typography
          color="primary"
          variant={mobileSize ? "h6" : tabletSize ? "h4" : "h1"}
          sx={{ margin: "1rem" }}
        >
          Page Not Found
        </Typography>
        <Button
          onClick={() => navigate("/")}
          variant="contained"
          sx={{ width: mobileSize ? "90%" : "15rem" }}
          color="primary"
        >
          Go Home
        </Button>
      </Box>
    </Box>
  );
};

export default NotFound;
