import { Box, Typography } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";

const OwnerTab = (props) => {
  const row = props?.selectedRow;
  const { property_owner } = row;
  const isEmptyObject = (obj) => {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          mt: 1,
        }}
      >
        <img
          src="/img/website_img/house.png"
          style={{
            width: "100%",
            maxWidth: "5rem",
            height: "auto",
            borderRadius: "20px",
          }}
        />
        {!property_owner || isEmptyObject(property_owner) ? (
          <Box mt={6}>
            <Typography align="center" px={1} variant="h6" color="primary">
              To display property owner information, please create a Notice of
              Commencement first
            </Typography>
          </Box>
        ) : (
          <>
            <Typography mt={1} fontWeight="bold">
              {property_owner?.name}
            </Typography>

            <Box mt={6}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 0.5,
                  mb: 0.5,
                }}
              >
                <LocationOnIcon />
                <Typography>
                  {property_owner?.address_parts?.address}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 0.5,
                  mb: 0.5,
                }}
              >
                <LocationOnIcon />
                <Typography>
                  {property_owner?.address_parts?.city}{" "}
                  {property_owner?.address_parts?.state}{" "}
                  {property_owner?.address_parts?.zipCode}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 0.5,
                  mb: 0.5,
                }}
              >
                <EmailIcon />
                <Typography>{property_owner?.email || "Unknown"}</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 0.5,
                  mb: 0.5,
                }}
              >
                <PhoneIcon />
                <Typography>{property_owner?.mobile || "Unknown"}</Typography>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default OwnerTab;
