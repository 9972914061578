import {
  Alert,
  Box,
  Button,
  Snackbar,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { green } from "@mui/material/colors";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useEffect, useState } from "react";
import StartNotarizationModal from "../../modals/StartNotarizationModal";
import ReviewCreatedFormsModal from "../../modals/ReviewCreatedFormsModal";
import ContractorSignerTab from "./ContractorSignerTab";
import OwnerSignerTab from "./OwnerSignerTab";
import ScheduleContractorRonModal from "../../modals/ScheduleContractorRonModal";

const NotarizationTab = (props) => {
  const row = props?.selectedRow;
  const { processing_permit } = row;
  const isEmptyObject = (obj) => {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
  };
  const [notarizeModalOpen, setNotarizeModalOpen] = useState(false);
  const [reviewDocModalOpen, setReviewDocModalOpen] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [signerValue, setSignerValue] = useState(0);
  const [isFading, setIsFading] = useState(false);
  const [ronScheduleOpen, setRonScheduleOpen] = useState(false);
  const [ronStarted, setRonStarted] = useState(false);
  const [fiveMinBeforeSession, setFiveMinBeforeSession] = useState(false);
  const lg = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  const snackHandleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
  };

  const handleSignerChange = (event, newValue) => {
    setIsFading(true);
    setTimeout(() => {
      setSignerValue(newValue);
      setIsFading(false);
    }, 300); // Duration should match the CSS transition duration
  };

  useEffect(() => {
    if (
      processing_permit?.notarization?.signing_order === "contractor" &&
      (processing_permit?.notarization?.contractor?.notarization?.is_signed ===
        true ||
        processing_permit?.notarization?.contractor?.notarization
          ?.is_notarized === true)
    ) {
      setSignerValue(1);
    } else if (
      processing_permit?.notarization?.signing_order === "owner" &&
      processing_permit?.notarization?.owner?.notarization?.is_notarized ===
        true
    ) {
      setSignerValue(1);
    } else {
      setSignerValue(0);
    }
  }, [props?.selectedRow]);

  useEffect(() => {
    if (ronStarted) {
      const timeOut = setTimeout(() => {
        setRonStarted(false);
      }, 15000);
      return () => clearTimeout(timeOut);
    }
  }, [ronStarted]);

  const getSignerTabComponent = () => {
    switch (signerValue) {
      case 0:
        return processing_permit?.notarization?.signing_order ===
          "contractor" ? (
          <ContractorSignerTab
            {...props}
            setRonScheduleOpen={setRonScheduleOpen}
            fiveMinBeforeSession={fiveMinBeforeSession}
            setFiveMinBeforeSession={setFiveMinBeforeSession}
          />
        ) : (
          <OwnerSignerTab {...props} processing_permit={processing_permit} />
        );
      case 1:
        return processing_permit?.notarization?.signing_order ===
          "contractor" ? (
          <OwnerSignerTab {...props} processing_permit={processing_permit} />
        ) : Object.keys(processing_permit?.notarization?.contractor).length ===
          0 ? (
          <></>
        ) : (
          <ContractorSignerTab
            {...props}
            setRonScheduleOpen={setRonScheduleOpen}
            fiveMinBeforeSession={fiveMinBeforeSession}
            setFiveMinBeforeSession={setFiveMinBeforeSession}
          />
        );

      default:
        return (
          <ContractorSignerTab
            {...props}
            setRonScheduleOpen={setRonScheduleOpen}
            fiveMinBeforeSession={fiveMinBeforeSession}
            setFiveMinBeforeSession={setFiveMinBeforeSession}
          />
        );
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          mt: 1,
        }}
      >
        <img
          src="/img/website_img/notarize.png"
          style={{
            width: "100%",
            maxWidth: "5rem",
            height: "auto",
            borderRadius: "20px",
          }}
        />

        {!row?.permit_package_created ? (
          //? DEFAULT DISPLAY
          <Box mt={6}>
            <Typography align="center" px={1} variant="h6" color="primary">
              Notarization requests have not yet been initiated
            </Typography>
          </Box>
        ) : //? START NOTARIZATION
        !row?.notarized && !processing_permit?.notarization?.started ? (
          <>
            <Box mt={2}>
              <Typography
                align="center"
                px={1}
                variant="h5"
                sx={{ color: "#388e3c" }}
              >
                {!props?.contractorSigning?.notarizationNeeded &&
                props?.contractorSigning?.signingNeeded
                  ? "Sign Your Documents!"
                  : "Notarize Your Documents!"}
              </Typography>
              <Typography
                align="center"
                px={1}
                fontWeight="bold"
                variant="body2"
                sx={{
                  color:
                    row?.city_scraper?.is_accepted &&
                    !row?.city_scraper?.first_done
                      ? "#f44336"
                      : "#388e3c",
                }}
              >
                {!props?.contractorSigning?.notarizationNeeded &&
                props?.contractorSigning?.signingNeeded
                  ? "Your permit package has been successfully created. It is time to sign your documents. Click on the button below to begin."
                  : row?.city_scraper?.is_accepted &&
                    !row?.city_scraper?.first_done
                  ? "The city permitting process has been initiated. While it is being processed, please wait so that the permit number can be inserted into your documents."
                  : "Your permit package has been successfully created. It is time to notarize all of your documents. Click on the button below to begin."}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
              <Button
                disabled={
                  row?.city_scraper?.is_accepted &&
                  !row?.city_scraper?.first_done
                }
                onClick={() => {
                  setNotarizeModalOpen(true);
                }}
                variant="contained"
              >
                Get Started
              </Button>
            </Box>
          </>
        ) : !row?.notarized &&
          !processing_permit?.notarization?.finalized &&
          processing_permit?.notarization?.started ? (
          <>
            <Box
              sx={{
                maxWidth: "100%",
                bgcolor: "background.paper",
                // display: "flex",
                // justifyContent: "center",
              }}
            >
              <Tabs
                value={signerValue}
                onChange={handleSignerChange}
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
                aria-label="scrollable auto tabs example"
                TabIndicatorProps={{
                  style: {
                    height: "3px", // Customize the height of the indicator
                    bottom: "10px", // Adjust the position closer to the text
                  },
                }}
              >
                <Tab
                  label={
                    processing_permit?.notarization?.signing_order ===
                    "contractor"
                      ? "1. Contractor"
                      : "1. Owner/Agent"
                  }
                  disableRipple
                />
                {Object.keys(processing_permit?.notarization?.contractor)
                  .length !== 0 && (
                  <Tab
                    label={
                      processing_permit?.notarization?.signing_order ===
                      "contractor"
                        ? "2. Owner/Agent"
                        : "2. Contractor"
                    }
                    disableRipple
                  />
                )}
              </Tabs>
            </Box>
            <Box
              sx={{ width: "100%" }}
              className={
                isFading
                  ? "fade-exit fade-exit-active"
                  : "fade-enter fade-enter-active"
              }
            >
              {getSignerTabComponent()}
            </Box>
          </>
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography align="center" variant="h5" color="primary" mt={2}>
                Notarized Permit Package
              </Typography>
              <CheckCircleIcon
                style={{
                  color: green[500],
                  marginLeft: 8,
                  marginTop: 15,
                  fontSize: "30px",
                }}
              />
            </Box>

            <Typography
              variant="body2"
              fontWeight="bold"
              color="primary"
              align="center"
              px={2}
              mt={2}
            >
              All of your documents have been notarized successfully. The
              notarized documents can be downloaded by clicking on the download
              button for each file.
            </Typography>
          </>
        )}
      </Box>
      <StartNotarizationModal
        open={notarizeModalOpen}
        setOpen={setNotarizeModalOpen}
        selectedRow={props?.selectedRow}
        paymentCards={props?.paymentCards}
        setReviewDocModalOpen={setReviewDocModalOpen}
        setSnackOpen={setSnackOpen}
        setRonScheduleOpen={setRonScheduleOpen}
        contractorSigning={props?.contractorSigning}
        ownerSigning={props?.ownerSigning}
      />
      <ReviewCreatedFormsModal
        open={reviewDocModalOpen}
        setOpen={setReviewDocModalOpen}
        selectedRow={props?.selectedRow}
      />
      <ScheduleContractorRonModal
        open={ronScheduleOpen}
        setOpen={setRonScheduleOpen}
        selectedRow={props?.selectedRow}
        setRonStarted={setRonStarted}
        setSnackOpen={setSnackOpen}
        fiveMinBeforeSession={fiveMinBeforeSession}
      />
      <Snackbar
        sx={{ zIndex: 9999999 }}
        open={snackOpen}
        autoHideDuration={4000}
        onClose={snackHandleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={snackHandleClose}
          severity="success" // Changed severity to success
          sx={{
            width: "100%",
            marginTop: "80px",
            marginLeft: lg ? "280px" : "0px",
            backgroundColor: "#388e3c", // Green shade for success
            color: "#fff",
            fontWeight: "bold",
          }}
          iconMapping={{
            success: <CheckCircleIcon sx={{ color: "#fff" }} />, // Changed to success icon
          }}
        >
          {ronStarted
            ? "RON Session has been scheduled successfully!"
            : "Payment Successful. Thank you"}
        </Alert>
      </Snackbar>
    </>
  );
};

export default NotarizationTab;
