import { Box, Typography } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";

const ContractorTab = (props) => {
  const row = props?.selectedRow;
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          mt: 1,
        }}
      >
        <img
          src="/img/website_img/contractor_img.png"
          style={{
            width: "100%",
            maxWidth: "5rem",
            height: "auto",
            borderRadius: "20px",
          }}
        />
        <Typography mt={1} fontWeight="bold">
          {row?.contractor?.businessName}
        </Typography>
        <Typography mt={-0.5} fontWeight="bold">
          {row?.contractor?.licenseType}
        </Typography>
        <Typography mt={-0.5} fontWeight="bold">
          {row?.contractor?.licenseNumber}
        </Typography>
        <Box mt={6}>
          <Box
            sx={{ display: "flex", alignItems: "center", gap: 0.5, mb: 0.5 }}
          >
            <LocationOnIcon />
            <Typography>{row?.contractor?.mainAddress}</Typography>
          </Box>

          <Box
            sx={{ display: "flex", alignItems: "center", gap: 0.5, mb: 0.5 }}
          >
            <LocationOnIcon />
            <Typography>{row?.contractor?.mainAddressCounty}</Typography>
          </Box>

          <Box
            sx={{ display: "flex", alignItems: "center", gap: 0.5, mb: 0.5 }}
          >
            <EmailIcon />
            <Typography>{row?.contractor?.email}</Typography>
          </Box>
          <Box
            sx={{ display: "flex", alignItems: "center", gap: 0.5, mb: 0.5 }}
          >
            <PhoneIcon />
            <Typography>{row?.contractor?.mobile}</Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ContractorTab;
