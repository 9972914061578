import { Modal, Box, Button, Typography, TextField } from "@mui/material";

const RecordingStartedModal = ({ modal, tabletSize, mobileSize, largePC }) => {
  return (
    <Modal open={modal}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: mobileSize ? "100%" : tabletSize ? "70%" : "80%",
          maxWidth: largePC ? "1100px" : undefined,
          bgcolor: "background.paper",
          boxShadow: 24,
          borderRadius: "1rem",
          p: 4,
        }}
      >
        <Typography
          id="modal-title"
          variant={mobileSize ? "h5" : "h4"}
          align="center"
          component="h2"
          sx={{ px: mobileSize ? 1 : 0 }}
        >
          The Recording Process Has Been Initiated
        </Typography>
        <Box sx={{ my: 2, display: "flex", justifyContent: "center" }}>
          <img
            src="/img/website_img/recording_started.jpeg"
            alt="Recording Process Started"
            style={{ width: "40%", height: "auto", borderRadius: "1rem" }}
          />
        </Box>

        <div
          style={{
            borderBottom: "3px solid #d3d3d3",
            marginTop: "1rem",
            marginBottom: "1rem",
          }}
        ></div>
        <Box>
          <Typography
            id="modal-title"
            variant="h5"
            align="center"
            component="h2"
            sx={{ mb: 2 }}
          >
            Keeping you updated
          </Typography>
        </Box>

        <Typography
          variant="body1"
          align="center"
          sx={{ color: "#FFC107", px: mobileSize ? 1 : 0 }}
          fontWeight="bold"
        >
          We would like to thank you for deciding to use NOC Creator for
          document recording. We will keep you updated on the project's progress
          via email.
        </Typography>
      </Box>
    </Modal>
  );
};

export default RecordingStartedModal;
