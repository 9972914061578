import {
  IconButton,
  Toolbar,
  Typography,
  Box,
  Card,
  CardContent,
  CircularProgress,
  FormHelperText,
} from "@mui/material";
import MobileFooter from "../layout/MobileFooter";

// ICONS
import FolderSpecialIcon from "@mui/icons-material/FolderSpecial";
import PublicIcon from "@mui/icons-material/Public";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { DataGrid } from "@mui/x-data-grid";

const MobileSealRecord = (props) => {
  const apiRef = props?.internalEnabled
    ? props?.internalRef
    : props?.externalEnabled
    ? props?.externalRef
    : props?.failedRef;

  return (
    <>
      <MobileFooter pos="top" py="6px" pl="0.5rem" />

      <Box
        sx={{
          p: 1,
          mb: 1,
          backgroundColor: "#fff",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography sx={{ fontSize: "22px", fontWeight: "bold" }}>
          Notarizations and Recordings
        </Typography>
        <Typography
          color={props?.failedEnabled ? "error" : "primary"}
          fontWeight="bold"
        >
          {props?.internalEnabled
            ? `${" \u00A0\u00A0"}I N T E R N A L${" \u00A0\u00A0"}D O C U M E N T S`
            : props?.externalEnabled
            ? `${" \u00A0\u00A0"}E X T E R N A L${" \u00A0\u00A0"}D O C U M E N T S`
            : `${" \u00A0\u00A0"}F A I L E D${" \u00A0\u00A0"}D O C U M E N T S`}
        </Typography>
      </Box>
      {/* DATA GRIDS */}
      <Box
        sx={{
          height: "calc(100% - 145px)",
          overflow: "auto",
          flexDirection: "column",
        }}
      >
        <Card sx={{ position: "relative", mt: 0 }}>
          <CardContent
            sx={{
              p: 0,
              px: 0,
              overflowY: "auto",
            }}
          >
            <DataGrid
              apiRef={apiRef}
              sx={{
                "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                  outline: "none !important",
                },
                "& .MuiDataGrid-columnHeader:focus-within": {
                  outline: "none !important",
                },
                "& .MuiDataGrid-cell": {
                  borderColor: "rgba(0, 0, 0, 0.1)", // Change cell border color
                  p: 0,
                },
                "& .MuiDataGrid-columnHeader": {
                  backgroundColor: props?.failedEnabled
                    ? "#FFCCCC"
                    : props?.externalEnabled
                    ? "#004976"
                    : "#333333",
                  color: props?.failedEnabled ? "#333333" : "white",
                  fontSize: "15px",
                  paddingLeft: 1,
                  paddingRight: 2,
                  margin: 0,
                  borderTopRightRadius: 0,
                  borderTopLeftRadius: 0,
                },
                "& .MuiDataGrid-columnHeaders": {
                  padding: 0,
                  margin: 0,
                },
                "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                  outline: "none !important",
                },
              }}
              rows={props?.currentRows}
              columns={props?.createdFormMobile}
              getRowId={(row) => row?.id}
              rowHeight={props?.tabletSize ? 72 : undefined}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSizeOptions={[5, 10, 25, 50, 100]}
              disableRowSelectionOnClick
              onRowClick={(params, event, details) => {
                const index = props?.currentRows.indexOf(params.row);
                props?.handleOpen(params, index);
              }}
              disableColumnFilter
            />
          </CardContent>
        </Card>

        {/* TOOLBAR - FOOTER */}
        <Toolbar
          sx={{
            px: 0,
            py: 0.5,
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            opacity: 1,
            backgroundColor: "#333333",
          }}
        >
          <IconButton
            variant="contained"
            onClick={props?.handleInternalClick}
            sx={{ flex: 1 }}
          >
            <Box>
              <FolderSpecialIcon width={25} sx={{ color: "#fff" }} />
              <FormHelperText
                sx={{ fontWeight: "bold", color: "#fff", mt: -1, px: "5px" }}
              >
                Internal
              </FormHelperText>
              <Box
                sx={{
                  borderBottom: props?.internalEnabled
                    ? "3px solid #757575"
                    : "3px solid transparent",
                  mt: "-4px",
                }}
              ></Box>
            </Box>
          </IconButton>
          <IconButton
            variant="contained"
            onClick={props?.handleExternalClick}
            sx={{ flex: 1 }}
          >
            <Box>
              <PublicIcon width={25} sx={{ color: "#fff" }} />
              <FormHelperText
                sx={{ fontWeight: "bold", color: "#fff", mt: -1, px: "5px" }}
              >
                External
              </FormHelperText>
              <Box
                sx={{
                  borderBottom: props?.externalEnabled
                    ? "3px solid #757575"
                    : "3px solid transparent",
                  mt: "-4px",
                }}
              ></Box>
            </Box>
          </IconButton>
          <IconButton
            variant="contained"
            onClick={props?.handleFailedClick}
            sx={{ flex: 1 }}
          >
            <Box>
              <ErrorOutlineIcon width={25} sx={{ color: "#fff" }} />
              <FormHelperText
                sx={{ fontWeight: "bold", color: "#fff", mt: -1, px: "5px" }}
              >
                Failed
              </FormHelperText>
              <Box
                sx={{
                  borderBottom: props?.failedEnabled
                    ? "3px solid #757575"
                    : "3px solid transparent",
                  mt: "-4px",
                }}
              ></Box>
            </Box>
          </IconButton>
        </Toolbar>
      </Box>
    </>
  );
};

export default MobileSealRecord;
