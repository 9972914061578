import React, { useState } from "react";
import { TimePicker } from "@mui/x-date-pickers";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";

import {
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  Typography,
  FormHelperText,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

function NotaryAvailability({
  formik,
  availabilities,
  saveLoading,
  settings,
  selectedDays,
  setSelectedDays,
  selectedStartTime,
  setSelectedStartTime,
  selectedEndTime,
  setSelectedEndTime,
  prefillLoading,
  handlePrefillTimes,
}) {
  const maxLength = 100;
  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const renderDayValues = (selected) => {
    if (!Array.isArray(selected)) {
      selected = [selected]; // Convert to an array if not already
    }

    if (selected.length === 0) {
      return <em>Select an option</em>;
    }

    // Join the selected items into a string
    const selectedText = selected.join(", ");

    // Truncate the text if it exceeds the maximum length
    const truncatedText =
      selectedText.length > maxLength
        ? selectedText.substring(0, maxLength) + "..."
        : selectedText;

    return truncatedText;
  };

  const findDay = (dayName) => {
    return (
      formik.values.availability.find((day) => day.day === dayName) || {
        day: dayName,
        available: false,
        startTime: null,
        endTime: null,
      }
    );
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      {/* START OF PRE FILL AVAILABILITIES  */}
      <Grid container align="center" mb={3}>
        <Grid item xs={12}>
          <Box
            sx={{
              border: "2px solid #f3f3f3",
              p: 3,
              borderRadius: "6px",
              boxShadow: "1px 3px 3px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Typography variant="h6">Prefill Availabilities</Typography>
            <Grid container spacing={2} mt={3}>
              <Grid item sm={3} xs={12}>
                <FormControl fullWidth variant="filled">
                  <InputLabel id="role-label">Days</InputLabel>
                  <Select
                    disabled={
                      prefillLoading ||
                      (availabilities.length !== 0 && !settings)
                    }
                    labelId="prefillAvailabilities"
                    multiple
                    onChange={(e) => setSelectedDays(e.target.value)}
                    label="Select Days"
                    value={selectedDays}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          marginTop: "1.5px",
                          maxHeight: 150, // Set the desired max height for the dropdown menu
                          width: "auto",
                          maxWidth: "100%",
                          "&& .Mui-selected": {
                            backgroundColor: "#E2E3E5",
                          },
                        },
                      },
                    }}
                    renderValue={renderDayValues}
                    sx={{
                      maxWidth: "100%", // Set the desired max-width for the input element
                      "& .MuiTypography-body2": {
                        whiteSpace: "wrap", // Prevent text from wrapping or shrinking
                      },
                      maxHeight: "10rem",
                    }}
                  >
                    {[
                      "Monday",
                      "Tuesday",
                      "Wednesday",
                      "Thursday",
                      "Friday",
                      "Saturday",
                      "Sunday",
                    ].map(function (day, i) {
                      return (
                        <MenuItem key={i} value={day}>
                          <Typography variant="body2">{day}</Typography>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={3} xs={12}>
                <TimePicker
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                  }}
                  closeOnSelect={false}
                  sx={{
                    borderRadius: "10px",
                  }}
                  label="Start Time"
                  disabled={
                    prefillLoading || (availabilities.length !== 0 && !settings)
                  }
                  value={selectedStartTime}
                  minutesStep={30}
                  onChange={(value) => {
                    setSelectedStartTime(value ? value : null);
                  }}
                />
              </Grid>
              <Grid item sm={3} xs={12}>
                <TimePicker
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                  }}
                  closeOnSelect={false}
                  sx={{
                    borderRadius: "10px",
                  }}
                  label="End Time"
                  disabled={
                    prefillLoading || (availabilities.length !== 0 && !settings)
                  }
                  value={selectedEndTime}
                  minutesStep={30}
                  onChange={(value) => {
                    setSelectedEndTime(value ? value : null);
                  }}
                />
              </Grid>
              <Grid item sm={3} xs={12}>
                <LoadingButton
                  onClick={handlePrefillTimes}
                  loading={prefillLoading}
                  disabled={
                    selectedDays.length === 0 ||
                    !selectedStartTime ||
                    !selectedEndTime ||
                    (availabilities.length !== 0 && !settings)
                  }
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                >
                  P R E F I L L
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      {/* END OF PRE FILL AVAILABILITIES  */}
      <Grid container spacing={2}>
        {daysOfWeek.map((day) => {
          const dayObj = findDay(day);
          const index = formik.values.availability.findIndex(
            (d) => d.day === day
          );
          return (
            <Grid item xs={12} sm={6} md={4} key={day}>
              <Grid container>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={availabilities.length !== 0 && !settings}
                        checked={dayObj?.available || false}
                        onChange={(event) => {
                          // Update the 'available' field
                          formik.setFieldValue(
                            `availability[${index}].available`,
                            event.target.checked
                          );

                          // If unchecking, clear the TimePicker values
                          if (!event.target.checked) {
                            formik.setFieldValue(
                              `availability[${index}].startTime`,
                              null
                            );
                            formik.setFieldValue(
                              `availability[${index}].endTime`,
                              null
                            );
                          }
                        }}
                        name={`availability[${formik.values.availability.indexOf(
                          dayObj
                        )}].available`}
                      />
                    }
                    label={day}
                  />
                </Grid>
                <Grid item xs={12}>
                  {dayObj?.available && (
                    <>
                      <TimePicker
                        viewRenderers={{
                          hours: renderTimeViewClock,
                          minutes: renderTimeViewClock,
                        }}
                        closeOnSelect={false}
                        sx={{
                          border: formik.errors.availability?.[index]?.startTime
                            ? "2px solid red"
                            : "",
                          borderRadius: "10px",
                          "& .MuiFormLabel-root": {
                            // Target the label

                            color: formik.errors.availability?.[index]
                              ?.startTime
                              ? "red"
                              : "",
                          },
                          "& .MuiInputBase-root": {
                            borderColor: formik.errors.availability?.[index]
                              ?.startTime
                              ? "red"
                              : "",
                          },
                          "& .MuiInputBase-root.Mui-error": {
                            "&::after": {
                              // Change underline color when error is present
                              borderBottomColor: "red",
                            },
                          },

                          // Set the width of the TimePicker to 100%
                        }}
                        className={
                          formik.errors.availability?.[index]?.startTime
                            ? "error-timepicker"
                            : ""
                        }
                        label="Start Time"
                        disabled={availabilities.length !== 0 && !settings}
                        value={dayObj.startTime}
                        minutesStep={30}
                        onChange={(value) => {
                          formik.setFieldValue(
                            `availability[${index}].startTime`,
                            value ? value : null
                          );
                        }}
                      />
                      <FormHelperText sx={{ color: "error.main" }}>
                        {formik.errors.availability?.[index]?.startTime}
                      </FormHelperText>
                      <TimePicker
                        viewRenderers={{
                          hours: renderTimeViewClock,
                          minutes: renderTimeViewClock,
                        }}
                        closeOnSelect={false}
                        sx={{
                          border: formik.errors.availability?.[index]?.endTime
                            ? "2px solid red"
                            : "",
                          borderRadius: "10px",
                          "& .MuiFormLabel-root": {
                            // Target the label
                            color: formik.errors.availability?.[index]?.endTime
                              ? "red"
                              : "",
                          },
                          "& .MuiInputBase-root": {
                            borderColor: formik.errors.availability?.[index]
                              ?.endTime
                              ? "red"
                              : "",
                          },
                          "& .MuiInputBase-root.Mui-error": {
                            "&::after": {
                              // Change underline color when error is present
                              borderBottomColor: "red",
                            },
                          },
                          // Add styles for placeholder if needed
                        }}
                        className={
                          formik.errors.availability?.[index]?.endTime
                            ? "error-timepicker"
                            : ""
                        }
                        minutesStep={30}
                        disabled={availabilities.length !== 0 && !settings}
                        label="End Time"
                        value={dayObj.endTime}
                        onChange={(value) => {
                          formik.setFieldValue(
                            `availability[${index}].endTime`,
                            value ? value : null
                          );
                        }}
                      />
                      <FormHelperText sx={{ color: "error.main" }}>
                        {formik.errors.availability?.[index]?.endTime}
                      </FormHelperText>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          );
        })}
        <Grid item xs={12}>
          <Typography color="error" fontWeight="bold" align="center">
            {typeof formik.errors?.availability === "string"
              ? formik.errors.availability
              : ""}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <LoadingButton
            loading={saveLoading}
            fullWidth
            disabled={availabilities.length !== 0 && !settings}
            size="large"
            variant="contained"
            type="submit"
          >
            {availabilities.length !== 0
              ? "Availabilities Saved"
              : "Save Availability"}
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
}

export default NotaryAvailability;
