import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  IconButton,
  Box,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

function EmptyPermitPopover({ nocStates, setNocStates }) {
  const tabletSize = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <div>
      <Dialog
        open={nocStates?.emptyPermitOpen || false}
        onClose={() =>
          setNocStates({
            ...nocStates,
            emptyPermitOpen: false,
            isCreatingNoc: false,
          })
        }
        fullWidth
        maxWidth={tabletSize ? false : undefined}
        aria-labelledby="responsive-dialog-title"
        PaperProps={
          tabletSize
            ? {
                style: {
                  width: "100%", // Set Paper width to 100%
                  maxWidth: "100%", // Ensure it does not exceed 100%
                  height: "auto", // Optional: in case you also want full height
                  margin: 0, // Remove margins to ensure it fits to the edge
                },
              }
            : {}
        }
      >
        <DialogTitle
          variant="h5"
          id="responsive-dialog-title"
          sx={{ mr: 1, textAlign: "center" }}
        >
          Missing Permit Number
          <IconButton
            aria-label="close"
            onClick={() =>
              setNocStates({
                ...nocStates,
                emptyPermitOpen: false,
                isCreatingNoc: false,
              })
            }
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ px: 2 }}>
          <DialogContentText sx={{ mb: 3, mt: 2 }}>
            {tabletSize
              ? "You are about to create a Notice of Commencement (NOC) without a permit number. In Florida, a permit number is required to comply with building regulations and legally start construction or remodeling."
              : "You are attempting to proceed with creating a Notice of Commencement (NOC) without entering a permit number. Please note that in Florida, a permit number is typically required to comply with local building regulations and legally initiate any construction or remodeling work."}
          </DialogContentText>
          <DialogContentText sx={{ mb: 3 }}>
            {tabletSize
              ? "Continuing without a permit number may cause legal issues, delays in approval, or problems with your NOC validation."
              : "Proceeding to create an NOC without a valid permit number can lead to potential delays in recording it and might be rejected by some clerks of courts. If your NOC is rejected because of this, you might still incur some costs even if the process is unsuccessful."}
          </DialogContentText>
          <DialogContentText sx={{ mb: 4 }}>
            <span style={{ fontWeight: "bold" }}>
              {tabletSize
                ? "Proceed without permit?"
                : "Are you sure you want to continue without a permit number?"}
            </span>{" "}
            {tabletSize
              ? "Review and obtain a permit to avoid complications."
              : "We highly recommend reviewing this information and obtaining the necessary permit number to ensure a trouble-free recording and acceptance of the NOC by the building department."}
          </DialogContentText>
          <Box
            sx={{
              gap: 2,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 2,
            }}
          >
            <Button
              onClick={() => {
                setNocStates({
                  ...nocStates,
                  emptyPermitOpen: false,
                  isCreatingNoc: true,
                  emptyPermitConfirmed: true,
                  createNocLoading: true,
                });
              }}
              color="error"
              fullWidth
              variant="contained"
            >
              Proceed Anyway
            </Button>
            <Button
              color="success"
              onClick={() =>
                setNocStates({
                  ...nocStates,
                  emptyPermitOpen: false,
                  isCreatingNoc: false,
                  emptyPermitConfirmed: false,
                })
              }
              fullWidth
              variant="contained"
            >
              Update Form
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default EmptyPermitPopover;
