import { LoadingButton } from "@mui/lab";
import { Alert, Box, Typography } from "@mui/material";

const MobileAlertComponent = ({
  alertText,
  btnText,
  severity,
  secondaryColor,
  loading,
  onClick,
  license,
}) => {
  return (
    <Alert
      sx={{
        px: 1,
        zIndex: 333,
        alignItems: "center", // Align items vertically
        position: "absolute",
        width: "100%",
        left: 0,
        top: 55,
        color: secondaryColor,
        fontWeight: "bold",
        borderRadius: 0,
        opacity: 1,
        transition: "opacity 1000ms ease-in-out",
      }}
      variant="filled"
      severity={severity}
    >
      <Box
        sx={{
          pr: 1,
          mr: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 10,
        }}
      >
        <Box sx={{ maxWidth: "100vw" }}>
          <Typography
            sx={{ fontSize: license ? "16px" : "12px", fontWeight: "bold" }}
          >
            {alertText}
          </Typography>
        </Box>

        {license ? (
          <></>
        ) : (
          <LoadingButton
            loading={loading}
            variant="outlined"
            color="primary"
            onClick={onClick}
            sx={{
              color: secondaryColor, // White text
              borderColor: secondaryColor, // White border
              "&:hover": {
                backgroundColor: "rgba(255, 255, 255, 0.08)", // Slight white tint on hover for feedback
                borderColor: secondaryColor, // Ensure border stays white on hover
              },
              "&.Mui-focusVisible": {
                boxShadow: "0 0 0 3px rgba(255, 255, 255, 0.5)", // White shadow box for focus (accessibility)
              },
              "&:active": {
                backgroundColor: "rgba(255, 255, 255, 0.24)", // White tint when pressed
              },
              // This style overrides the default ripple color
              ".MuiTouchRipple-child": {
                backgroundColor: secondaryColor, // White ripple effect
              },
            }}
          >
            {btnText}
          </LoadingButton>
        )}
      </Box>
    </Alert>
  );
};

export default MobileAlertComponent;
