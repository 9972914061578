import {
  updateNotaryJobs,
  addJobToQueue,
  removeJobFromQueue,
  addAcceptedQueueJob,
  removeAcceptedQueueJob,
  addRealTimeRequest,
} from "../notaryActions";

import { updateNotaryUser } from "../notaryActions";

export const notarySessionOnMessage =
  (receivedData) => async (dispatch, getState) => {
    switch (receivedData.event) {
      case "notary_user_update":
        dispatch(updateNotaryUser(receivedData.data));
        break;
      case "update_notary_jobs":
        dispatch(updateNotaryJobs(receivedData.data));
        break;
      case "broadcast_queue_job":
        dispatch(addJobToQueue(receivedData.data));
        break;
      case "broadcast_remove_queue_job":
        dispatch(removeJobFromQueue(receivedData.data));
        break;
      case "update_accepted_job_list":
        dispatch(addAcceptedQueueJob(receivedData.data));
        break;
      case "remove_accepted_job_list":
        dispatch(removeAcceptedQueueJob(receivedData.data));
        break;
      case "new_ron_request":
        dispatch(addRealTimeRequest(receivedData.data));
        break;
      default:
        return;
    }
  };
