// src/utils/loadComponent.js
const globalInitials = {
  energy: "EnergyInitials",
  noc: "NocInitials",
};

const countySpecificInitials = {
  //   Duval: {
  //     building_permit: "CreatingCountySpecificForm",
  //     pais: "CreatingCountySpecificPaisForm",
  //     // Add more county-specific forms here
  //   },
  // Add more counties here
};

const preprocessName = (name) => {
  if (!name) return ""; // Handle cases where name might be undefined or null
  return name.toLowerCase().replace(/\s+/g, "_");
};

export const loadInitials = async (creatingDoc, county, city) => {
  // Preprocess county and city names
  const processedCounty = preprocessName(county);
  const processedCity = preprocessName(city);

  // Check if the document is a global component
  if (globalInitials[creatingDoc]) {
    const module = await import(
      `../components/permitForms/Global/initials/${globalInitials[creatingDoc]}`
    );
    return module.Initials;
  }

  // Check if the document is a county-specific component
  if (Object.keys(countySpecificInitials).length > 0) {
    if (
      countySpecificInitials[processedCounty] &&
      countySpecificInitials[processedCounty][creatingDoc]
    ) {
      const module = await import(
        `../components/permitForms/CountySpecific/${processedCounty}/${countySpecificInitials[processedCounty][creatingDoc]}`
      );
      return module.Initials;
    }
  }

  // Otherwise, assume it's county/city-specific
  if (processedCity) {
    switch (creatingDoc) {
      case "building_permit":
        const module = await import(
          `../components/permitForms/BuildingPermit/${processedCounty}/${processedCity}/Initials`
        );
        return module.Initials;
      case "pais":
        const paisModule = await import(
          `../components/permitForms/PAIS/${processedCounty}/${processedCity}/Initials`
        );
        return paisModule.Initials;
      case "obtain_permit":
        const obtainModule = await import(
          `../components/permitForms/ObtainPermit/${processedCounty}/${processedCity}/Initials`
        );
        return obtainModule.Initials;
      case "asbestos":
        const asbestosModule = await import(
          `../components/permitForms/Asbestos/${processedCounty}/${processedCity}/Initials`
        );
        return asbestosModule.Initials;
      case "ownership":
        const ownershipModule = await import(
          `../components/permitForms/Ownership/${processedCounty}/${processedCity}/Initials`
        );
        return ownershipModule.Initials;
      case "roofing":
        const roofingModule = await import(
          `../components/permitForms/Roofing/${processedCounty}/${processedCity}/Initials`
        );
        return roofingModule.Initials;
      default:
        return null;
    }
  }

  return null;
};
