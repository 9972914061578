import {
  Grid,
  Stack,
  Typography,
  Button,
  Box,
  IconButton,
  useMediaQuery,
  useTheme,
  Drawer,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Toolbar,
} from "@mui/material";
import { useState, useEffect } from "react";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import validator from "validator";
import Footer from "../layout/Footer";
import { loadStripe } from "@stripe/stripe-js";
import TextFields from "./TextFields";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { PencilIcon } from "@heroicons/react/24/outline";
import { unavailable_record_list } from "../../data/counties";
import { DocumentArrowDownIcon } from "@heroicons/react/24/outline";
import ReactCardFlip from "react-card-flip";
import MoreResultsList from "../noc/MoreResultsList";
import OpenDocuments from "./OpenDocuments";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import Magnifier from "../customComponents/Magnifier";

const EnlargeNotificationForm = ({
  Nloading,
  setDisableSelect,
  disableSelect,
  licenseType,
  setLicenseType,
  setSelectedLicenseIndex,
  invalid,
  licenseNumber,
  setLicenseNumber,
  licenseText,
  copySelected,
  setCopySelected,
  currentUser,
  moreResultType,
  updatedTask,
  currentTask,
  setFullRetrieve,
  fullRetrieve,
  editFromCreate,
  retrieveLoading,
  defaultAddress,
  selectedPreName,
  enlargeHeader,
  enlargeNote,
  setOpenDrawer,
  formik,
  type,
  preview,
  setIsPreview,
  previewImg,
  previewLoading,
  setCounty,
  createNocLoading,
  pdfImg,
  inputs,
  errorText,
  pdf,
  isReadyToDownload,
  text,
  setSelectedRow,
  addressRow,
  cardFlip,
  setCardFlip,
  setAddressRow,
  isRetrievingAddress,
  setIsRetrievingAddress,
  setRetrieveLoading,
  defaultValues,
  setCreateNocLoading,
  setPreviewLoading,
  setDefaultAddress,
  formDrawerOpen,
  setFormDrawerOpen,
  setPreview,
  isFormCreated,
  setNewRequestID,
  setNewRequest,
  setInQueueMessage,
  setInQueueDrawer,
  moreResultsText,
  handleDownloadNoc,
  downloadingFileLoading,
  isSuretyRadio,
  setIsSuretyRadio,
  copyInputs,
}) => {
  const [dynamicValues, setDynamicValues] = useState({});
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const [notarizeDrawer, setNotarizeDrawer] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [notaryErrorText, setNotaryErrorText] = useState("");
  const [paymentCards, setPaymentCards] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [ownerEmail, setOwnerEmail] = useState("");
  const [ownerPhone, setOwnerPhone] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [notaryLoading, setNotaryLoading] = useState(false);
  const [tierPrice, setTierPrice] = useState("");
  const navigate = useNavigate();
  const between900and1200 = useMediaQuery(
    "(min-width:900px) and (max-width:1200px)"
  );
  const between1200and1475 = useMediaQuery(
    "(min-width:1200px) and (max-width:1475px)"
  );

  let formData = null;
  if (!currentTask?.return_values?.createdForm) {
    formData = { ...currentTask?.return_values };
  } else {
    formData = { ...currentTask?.return_values?.createdForm };
  }
  const currentSession = useSelector((state) => state.sessions.currentSession);
  const [licenses, setLicenses] = useState({
    contractorType: "ORGANIZATION",
    contractorTypeError: false,
    contractorName: "",
    contractorNameError: false,
    contractorFirst: "",
    contractorFirstError: false,
    contractorMiddle: "",
    contractorLast: "",
    contractorLastError: false,
  });

  const [radioValue, setRadioValue] = useState("notaryRecord");
  const [unavailableRecord, setUnavailableRecord] = useState(false);

  const validateEmail = async (email) => {
    try {
      await Yup.string().email().validate(email);
      // If the validation is successful
      return true;
      // You can do something here if the email is valid
    } catch (error) {
      // If the validation fails
      return false;

      // Handle the validation error
    }
  };

  useEffect(() => {
    const foundTierPrice =
      currentUser?.service_tiers.find((tier) => tier.name === radioValue)
        ?.price || "0";

    setTierPrice(foundTierPrice);
  }, [radioValue]);

  useEffect(() => {
    const isRecordUnavailable =
      unavailable_record_list.includes(selectedPreName);

    if (isRecordUnavailable) {
      setRadioValue("notary");
      setUnavailableRecord(true);
    } else {
      setUnavailableRecord(false);
    }
  }, [selectedPreName, notarizeDrawer]);

  useEffect(() => {
    setPaymentCards(
      currentUser?.payment_cards.map((card) => {
        const lastTwo = String(card.exp_year).slice(-2);
        const first = card.exp_month;

        return {
          id: card.id,
          cardNum: "**** **** **** " + card.last4,
          cardName: card.name ? card.name : card.card_holder,
          expires:
            (first < 10 ? "0" + String(first) : String(first)) + "/" + lastTwo,
          default: card.default,
        };
      })
    );
  }, []);

  const handleNotarize = async () => {
    setNotaryLoading(true);
    const isValidEmail = await validateEmail(ownerEmail);
    const isValidPhoneNumber = validator.isMobilePhone(ownerPhone);
    let hasError = false;
    if (
      isValidEmail === false ||
      ownerEmail === "" ||
      !isValidPhoneNumber ||
      ownerPhone === ""
    ) {
      if (isValidEmail === false || ownerEmail === "") {
        setEmailError(true);
      }
      if (ownerPhone === "" || !isValidPhoneNumber) {
        setPhoneError(true);
      }
      hasError = true;
    }

    if (!formData?.inputs?.contractorConfirmed) {
      if (licenses?.contractorType === "ORGANIZATION") {
        if (
          licenses?.contractorNameError === true ||
          licenses?.contractorName === ""
        ) {
          const updatedLicenses = {
            ...licenses,
            contractorNameError: licenses?.contractorName === "",
          };
          setLicenses(updatedLicenses);
          hasError = true;
        }
      } else if (licenses?.contractorType === "PERSON") {
        if (
          licenses?.contractorTypeError === true ||
          licenses?.contractorType === "" ||
          licenses?.contractorFirstError === true ||
          licenses?.contractorFirst === "" ||
          licenses?.contractorLastError === true ||
          licenses?.contractorLast === ""
        ) {
          const updatedLicenses = {
            ...licenses,
            contractorTypeError: licenses?.contractorType === "",
            contractorFirstError: licenses?.contractorFirst === "",
            contractorLastError: licenses?.contractorLast === "",
          };
          setLicenses(updatedLicenses);
          hasError = true;
        }
      }
    }

    if (hasError) {
      setNotaryLoading(false);
      return;
    }

    const selectedCard = paymentCards[currentIndex];

    formData.task_id = currentTask?.task_id;
    formData.second_took = currentTask?.second_took;
    const payload = {
      paymentCard: selectedCard,
      ownerEmail: ownerEmail,
      ownerPhone: ownerPhone,
      submitType: radioValue,
      formData: formData,
      documentType: "notice of commencement",
      licenseStatus: formData?.inputs?.contractorConfirmed,
      licenses: licenses,
    };

    let noc_id = currentTask?.return_values?.createdForm?.id;
    if (!noc_id) {
      noc_id = currentTask?.return_values?.id;
    }

    if (radioValue === "record") {
      try {
        const resp = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/stripe/process-notary-payment`,
          {
            paymentCard: selectedCard?.id,
            ids: { noc_id: currentTask.id, task_id: currentTask.task_id },
            reason: radioValue,
            currentSession: currentSession,
          },
          { withCredentials: true }
        );

        const stripe = await loadStripe(process.env.REACT_APP_STRIPE); // Replace with your actual publishable key
        const result = await stripe.confirmCardPayment(resp.data.data);
        if (result.error) {
          setNotaryErrorText(result.error.message);
          // Handle the error on the frontend
        } else {
          if (
            result.paymentIntent.status === "succeeded" &&
            resp.data.valid === true
          ) {
            payload["paymentIntent"] = result.paymentIntent;

            const resp = await axios.post(
              `${process.env.REACT_APP_BASE_URL}/nocApi/recording/recording-invitation`,
              {
                payload,
                currentSession: currentSession,
              },
              { withCredentials: true }
            );

            if (resp.data.success === true) {
              setSuccessModal(true);
            }
          }
        }
      } catch (e) {}
    } else {
      try {
        const resp = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/stripe/process-notary-payment`,
          {
            paymentCard: selectedCard.id,
            ids: {
              noc_id: noc_id,
              task_id: currentTask?.task_id,
            },
            reason: radioValue,
            currentSession: currentSession,
          },
          { withCredentials: true }
        );

        const stripe = await loadStripe(process.env.REACT_APP_STRIPE);
        const result = await stripe.confirmCardPayment(resp.data.data);
        if (result.error) {
          setNotaryErrorText(result.error.message);
          // Handle the error on the frontend
        } else {
          // The payment has been processed!
          if (
            result.paymentIntent.status === "succeeded" &&
            resp.data.valid === true
          ) {
            payload["paymentIntent"] = result.paymentIntent;
            const resp = await axios.post(
              `${process.env.REACT_APP_BASE_URL}/nocApi/stripe/finish-notary-payment`,
              {
                payload,
                currentSession: currentSession,
              },
              { withCredentials: true }
            );

            if (resp.data.success === true) {
              setSuccessModal(true);
            }
          }
        }
      } catch (e) {}
    }

    setNotaryLoading(false);
  };

  const handleRowClick = (inputs) => {
    // Extract the required fields from the rowData object (adjust as per your actual data structure)
    const { street, number, unit, zip, owner } = inputs;

    // Get the current formik values

    // Update the dynamicValues state with the clicked row's data for streetName, streetNumber, unitNumber, and zip
    setDynamicValues({
      streetName: street || "",
      streetNumber: number || "",
      unitNumber: unit || "",
      zip: zip || "",
      owner: owner || "",
    });

    // Set the other formik values back to the formik state
    // formik.setValues({ ...formik.values, ...otherValues });
  };

  const handleIMG = () => {
    let regex = /(\d+)(?=\.[^.]+$)/;
    // Check if the filename contains a number
    if (regex.test(pdfImg)) {
      // If it does, keep the original file path
      let originalFilePath = pdfImg;

      // And create a new file path with the number replaced with the specific number
      let newFilePath = pdfImg.replace(regex, 2);
      if (preview) {
        return (
          <>
            <Magnifier src={previewImg[0]} lensSize={400} zoomFactor={4} />
            <Magnifier src={previewImg[1]} lensSize={400} zoomFactor={4} />
          </>
        );
      } else {
        return (
          <>
            <Magnifier src={originalFilePath} lensSize={400} zoomFactor={4} />
            <Magnifier src={newFilePath} lensSize={400} zoomFactor={4} />
          </>
        );
      }
    } else {
      if (preview) {
        return (
          <>
            <Magnifier src={previewImg[0]} lensSize={400} zoomFactor={4} />
          </>
        );
      } else {
        return (
          <>
            <Magnifier src={pdfImg} lensSize={400} zoomFactor={4} />
          </>
        );
      }
    }
  };
  const handleIconButton = async () => {
    setOpenDrawer(false);
    setCreateNocLoading(false);
    setPreviewLoading(false);
    // setNewRequest(false);
    // setNewRequestID("");
  };
  const handlePreview = () => {
    formik?.submitForm();
    setCardFlip(false);
    setCounty(selectedPreName);
    setIsPreview(true);
  };
  const handleReturnForm = () => {
    return (
      <>
        <Stack spacing={1} mb={2}>
          <TextFields
            isSuretyRadio={isSuretyRadio}
            setIsSuretyRadio={setIsSuretyRadio}
            editFromCreate={editFromCreate}
            dynamicValues={dynamicValues}
            setDynamicValues={setDynamicValues}
            formik={formik}
            selectedPreName={selectedPreName}
            type={type}
            inputs={inputs}
          />
        </Stack>
      </>
    );
  };

  const handleCheckButton = () => {
    // AFTER CREATED
    if (editFromCreate) {
      return (
        <>
          <LoadingButton
            startIcon={<PencilIcon color="#fff" width={20} />}
            type="submit"
            loading={Boolean(previewLoading)}
            disabled={downloadingFileLoading}
            onClick={() => formik?.submitForm()}
            fullWidth
            size={"large"}
            sx={{
              mt: 2,
              mb: 2,
              color: "#fff",
              backgroundColor: "#F2CD00",
              ":hover": { backgroundColor: "#CDAF0B" },
            }}
            variant="contained"
          >
            UPDATE
          </LoadingButton>
          {isReadyToDownload ? (
            <>
              <LoadingButton
                startIcon={<DocumentArrowDownIcon color="#fff" width={20} />}
                onClick={() => handleDownloadNoc(pdf)}
                disabled={Nloading}
                loading={
                  Boolean(createNocLoading) || Boolean(downloadingFileLoading)
                }
                fullWidth
                size={"large"}
                sx={{ mt: 2, mb: 2, backgroundColor: "primary" }}
                variant="contained"
              >
                DOWNLOAD
              </LoadingButton>
              <Button
                onClick={() => {
                  setNotarizeDrawer(true);
                }}
                startIcon={<NoteAltIcon color="#fff" width={20} />}
                color="success"
                disabled={Nloading || downloadingFileLoading}
                fullWidth
                size={"large"}
                sx={{ mt: 2, mb: 2, backgroundColor: "success" }}
                variant="contained"
              >
                NOTARIZE / RECORD
              </Button>
            </>
          ) : (
            <>
              <LoadingButton
                onClick={(e) => {
                  setCounty(selectedPreName);
                  setCardFlip(false);
                  setAddressRow([]);
                  setIsPreview(false);
                  formik?.submitForm();
                }}
                loading={Boolean(createNocLoading)}
                disabled={retrieveLoading}
                type="submit"
                fullWidth
                size="large"
                sx={{
                  mt: 2,
                  mb: 2,
                  backgroundColor: "primary",
                }}
                variant="contained"
              >
                CREATE
              </LoadingButton>
            </>
          )}
        </>
      );
    } else {
      return (
        <>
          <LoadingButton
            disabled={isReadyToDownload || retrieveLoading}
            type="submit"
            loading={Boolean(previewLoading)}
            onClick={handlePreview}
            fullWidth
            size={"large"}
            sx={{
              mt: 2,
              mb: 2,
              color: "#fff",
              backgroundColor: "#F2CD00",
              ":hover": { backgroundColor: "#CDAF0B" },
            }}
            variant="contained"
          >
            {preview ? "RESET" : "PREVIEW"}
          </LoadingButton>
          {isReadyToDownload ? (
            <>
              <LoadingButton
                href={pdf}
                target="_blank"
                loading={Boolean(createNocLoading)}
                fullWidth
                size={"large"}
                sx={{ mt: 2, mb: 2, backgroundColor: "primary" }}
                variant="contained"
              >
                Download
              </LoadingButton>
            </>
          ) : (
            <>
              <LoadingButton
                onClick={(e) => {
                  // setNocStartedDrawer(true);
                  formik?.submitForm();
                  setCounty(selectedPreName);
                  setCardFlip(false);
                  setAddressRow([]);
                  setIsPreview(false);
                }}
                loading={Boolean(createNocLoading)}
                disabled={retrieveLoading}
                type="submit"
                fullWidth
                size={"large"}
                sx={{ mt: 2, mb: 2, backgroundColor: "primary" }}
                variant="contained"
              >
                CREATE
              </LoadingButton>
            </>
          )}
        </>
      );
    }
  };

  return (
    <>
      <Grid
        container
        sx={{ display: "flex", overflow: "hidden", width: "100%" }}
      >
        {/* LEFT SIDE */}
        <Grid item md={6} sx={{ maxHeight: "90vh", overflow: "auto" }}>
          <Box
            sx={{
              display: "column",
              position: "relative",
            }}
          >
            <IconButton
              sx={
                type !== "create-noc" && type !== "moreResults"
                  ? { display: "none", mt: "1rem", ml: "1rem" }
                  : {
                      display: "block",
                      mt: "1rem",
                      ml: "1rem",
                      position: "absolute",
                      left: 0,
                      top: 5,
                      zIndex: 2,
                    }
              }
              disabled={addressRow?.length === 0}
              onClick={() => setCardFlip(!cardFlip)}
            >
              {cardFlip === true ? <CloseIcon /> : <FormatListBulletedIcon />}
            </IconButton>

            <ReactCardFlip
              isFlipped={cardFlip}
              flipDirection="horizontal"
              flipSpeedBackToFront={1}
              flipSpeedFrontToBack={1}
            >
              <Box>{handleIMG()}</Box>
              <Box sx={{ height: "100vh" }}>
                <MoreResultsList
                  setDisableSelect={setDisableSelect}
                  moreResultType={moreResultType}
                  updatedTask={updatedTask}
                  currentTask={currentTask}
                  moreResultsText={moreResultsText}
                  setIsPreview={setIsPreview}
                  setNewRequestID={setNewRequestID}
                  setNewRequest={setNewRequest}
                  setInQueueMessage={setInQueueMessage}
                  setInQueueDrawer={setInQueueDrawer}
                  setFullRetrieve={setFullRetrieve}
                  fullRetrieve={fullRetrieve}
                  setRetrieveLoading={setRetrieveLoading}
                  isRetrievingAddress={isRetrievingAddress}
                  setIsRetrievingAddress={setIsRetrievingAddress}
                  retrieveLoading={retrieveLoading}
                  defaultAddress={defaultAddress}
                  handleRowClick={handleRowClick}
                  setSelectedRow={setSelectedRow}
                  addressRow={addressRow}
                  formik={formik}
                  defaultValues={defaultValues}
                  setCreateNocLoading={setCreateNocLoading}
                  setPreviewLoading={setPreviewLoading}
                  setAddressRow={setAddressRow}
                  setDefaultAddress={setDefaultAddress}
                />
                <Typography
                  variant="body1"
                  sx={{
                    color: "#BCA631",
                    fontWeight: "bold",
                    textAlign: "center",
                    pb: 2,
                    mx: 1,
                  }}
                >
                  {type === "edit" ? errorText : text}
                </Typography>
              </Box>
            </ReactCardFlip>
          </Box>
        </Grid>

        {/* RIGHT SIDE */}
        <Grid
          item
          md={6}
          sx={{ position: "relative", mb: 1, mt: 2, height: "100vh" }}
        >
          {/* HEADER */}
          <IconButton
            onClick={handleIconButton}
            sx={{ position: "absolute", right: 10 }}
          >
            <CloseIcon />
          </IconButton>
          <Box
            sx={{
              mb: 1.5,
              pb: 1,

              backgroundColor: "#fff",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography color="primary" variant="h4" textAlign="center">
              {selectedPreName} County
            </Typography>
            <Typography variant="body1" textAlign="center">
              {enlargeHeader}
            </Typography>
            <Typography mt={2} variant="body2" textAlign="center">
              {enlargeNote}
            </Typography>
            {copySelected?.valid === true &&
            copySelected?.confirmed === false ? (
              <Typography sx={{ color: "#FF0000" }} align="center">
                {currentUser?.subuser
                  ? "This license number is still needs to be confirmed. Please reach out to your administrator for further assistance."
                  : !copySelected?.tried || copySelected?.tried < 4
                  ? "Please confirm your license number through your profile settings."
                  : "Please confirm your license number through your profile settings to be able to create an NOC for this license type."}
              </Typography>
            ) : copySelected?.process_status === "queued" ? (
              <Typography sx={{ color: "#FF0000" }} align="center">
                Validation in progress. Please wait.
              </Typography>
            ) : (
              invalid && (
                <Typography sx={{ color: "#FF0000" }} align="center">
                  {currentUser?.subuser
                    ? "We're sorry, but this license number is not valid. Please reach out to your administrator for further assistance."
                    : !copySelected?.tried || copySelected?.tried < 4
                    ? "Please confirm your license number through your profile settings."
                    : "Please confirm your license number through your profile settings to be able to create an NOC for this license type."}
                </Typography>
              )
            )}
            {invalid && !currentUser?.subuser && (
              <Button
                sx={{ mt: 2 }}
                variant="outlined"
                onClick={() => {
                  navigate("/contractors", {
                    state: { option: copySelected?.licenseType },
                  });
                }}
              >
                {copySelected?.valid === true &&
                copySelected?.confirmed === false
                  ? "Confirm Your Contractor Details"
                  : copySelected?.process_status === "queued"
                  ? "Details"
                  : "Change License Number"}
              </Button>
            )}
            {type === "moreResults" && (
              <Grid
                container
                sx={{
                  alignItems: "center",
                  mt: 2,
                  marginBottom: "-2rem",
                  backgroundColor: "#f6f6f6",
                  p: 2,
                  // borderRadius: "1rem",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Grid item lg={6} sm={12} order={1}>
                  <FormControl fullWidth variant="filled">
                    <InputLabel id="licenseType-label">License Type</InputLabel>
                    <Select
                      disabled={Boolean(disableSelect)}
                      labelId="license_types"
                      name="licenseType"
                      label="License Type"
                      value={licenseType}
                      onChange={(e) => {
                        const index = currentUser?.user?.contractors.findIndex(
                          (contractor) =>
                            contractor.licenseType === e.target.value
                        );

                        setSelectedLicenseIndex(index);
                        // Use Formik's setFieldValue to update the licenseType
                        setLicenseType(e.target.value);

                        // Now you can update other form fields based on the selected licenseType
                        const selectedContractor =
                          currentUser?.user?.contractors.find(
                            (contractor) =>
                              contractor.licenseType === e.target.value
                          );

                        setLicenseNumber(selectedContractor.licenseNumber);
                        setCopySelected(selectedContractor);
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            marginTop: "1.5px",
                            maxHeight: 150, // Set the desired max height for the dropdown menu
                            width: "auto",
                            maxWidth: "100%",
                          },
                        },
                      }}
                      sx={{
                        maxWidth: "100%", // Set the desired max-width for the input element
                        "& .MuiTypography-body2": {
                          whiteSpace: "wrap", // Prevent text from wrapping or shrinking
                        },
                      }}
                    >
                      {currentUser?.user?.contractors?.map(function (
                        contractor,
                        i
                      ) {
                        const getStyles = () => {
                          let dotColor = "";

                          if (contractor.process_status === "queued") {
                            dotColor = "#004976";
                          } else if (
                            contractor.valid === true &&
                            contractor.confirmed === false
                          ) {
                            dotColor = "#F2CD00";
                          } else if (
                            contractor.valid === true &&
                            contractor.confirmed === true
                          ) {
                            dotColor = "#006400";
                          } else if (contractor.valid === false) {
                            dotColor = "#a21717";
                          }

                          return {
                            "&::after": {
                              content: '""',
                              display: "inline-block",
                              width: "10px", // Adjust the size of the dot
                              height: "10px", // Adjust the size of the dot
                              marginLeft: "5px", // Adjust the spacing between text and dot
                              borderRadius: "50%",
                              backgroundColor: dotColor,
                            },
                          };
                        };

                        return (
                          <MenuItem
                            key={i}
                            value={contractor.licenseType}
                            sx={getStyles()}
                          >
                            <Typography variant="body2">
                              {contractor.licenseType}
                            </Typography>
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item lg={6} sm={12}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center", // Vertically center the content
                      justifyContent: "center",
                      height: "100%", // Ensure the Box takes up the full height
                    }}
                  >
                    <Box
                      sx={
                        copySelected?.valid === false &&
                        copySelected?.process_status === "completed"
                          ? { borderBottom: "5px solid #990000" }
                          : copySelected?.process_status === "queued"
                          ? { borderBottom: "5px solid #004976" }
                          : { borderBottom: "5px solid #006400" }
                      }
                    >
                      <Typography
                        variant="h4"
                        sx={
                          copySelected?.valid === false &&
                          copySelected?.process_status === "completed"
                            ? { marginBottom: "-7px", color: "#990000" }
                            : copySelected?.process_status === "queued"
                            ? { marginBottom: "-7px", color: "#004976" }
                            : { marginBottom: "-7px", color: "#006400" }
                        }
                      >
                        {licenseNumber}
                      </Typography>
                    </Box>

                    <Typography
                      variant="body2"
                      sx={
                        copySelected?.valid === false &&
                        copySelected?.process_status === "completed"
                          ? { color: "#990000" }
                          : copySelected?.process_status === "queued"
                          ? { color: "#004976" }
                          : { color: "#006400" }
                      }
                    >
                      {licenseText}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={
                        copySelected?.valid === false &&
                        copySelected?.process_status === "completed"
                          ? { color: "#990000" }
                          : copySelected?.process_status === "queued"
                          ? { color: "#004976" }
                          : { color: "#006400" }
                      }
                    >
                      {copySelected?.expiry}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            )}
          </Box>
          <Box
            sx={{
              height:
                // Start with checking if invalid is true, along with other conditions
                invalid
                  ? between900and1200
                    ? "calc(100% - 560px)" // Height for invalid and screen width between 900 and 1200
                    : between1200and1475
                    ? "calc(100% - 480px)" // Height for invalid and screen width between 1200 and 1475
                    : "calc(100% - 480px)" // Default height when invalid, not fitting other sizes
                  : // Then check for the non-invalid conditions with breakpoints
                  copySelected?.confirmed === false ||
                    copySelected?.process_status === "queued"
                  ? between900and1200
                    ? "calc(100% - 505px)" // Height when copySelected conditions and screen width between 900 and 1200
                    : between1200and1475
                    ? "calc(100% - 450px)" // Height when copySelected conditions and screen width between 1200 and 1475
                    : "calc(100% - 560px)" // Default height for copySelected conditions, not fitting other sizes
                  : between900and1200
                  ? "calc(100% - 505px)" // Standard height for screen width between 900 and 1200
                  : between1200and1475
                  ? "calc(100% - 450px)" // Standard height for screen width between 1200 and 1475
                  : "calc(100% - 420px)", // Default height for conditions not met above
              overflow: "auto",
              px: 0.3,
              mt: 4,
            }}
          >
            {handleReturnForm()}
          </Box>
          <Toolbar
            sx={{
              px: 0,
              py: 0.5,
              position: "fixed",
              bottom: 88.5,
              left: 0,
              right: 0,
              opacity: 1,
            }}
          >
            <Grid container>
              <Grid item xs={6}></Grid>
              <Grid item xs={6}>
                <Box sx={{ display: "flex", gap: 2 }}>
                  {handleCheckButton()}
                </Box>
              </Grid>
            </Grid>
          </Toolbar>
        </Grid>
      </Grid>
      <Footer height="60px" />

      <Drawer
        PaperProps={{}}
        anchor="right"
        open={formDrawerOpen}
        onClose={() => setFormDrawerOpen(false)}
      >
        <Stack sx={{ width: "auto", ml: "auto" }}>
          <IconButton onClick={() => setFormDrawerOpen(false)}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Grid item md={6} sx={{ maxHeight: "100%", order: 2 }}>
          <Box
            sx={{
              display: "column",
              position: "relative",
            }}
          >
            <IconButton
              sx={
                type !== "create-noc"
                  ? { display: "none", mt: "1rem", ml: "1rem" }
                  : {
                      display: "block",
                      mt: "1rem",
                      ml: "1rem",
                      position: "absolute",
                      left: 0,
                      top: 5,
                      zIndex: 2,
                    }
              }
              disabled={addressRow?.length === 0}
              onClick={() => setCardFlip(!cardFlip)}
            >
              {cardFlip === true ? <CloseIcon /> : <FormatListBulletedIcon />}
            </IconButton>

            <ReactCardFlip
              isFlipped={cardFlip}
              flipDirection="horizontal"
              flipSpeedBackToFront={1}
              flipSpeedFrontToBack={1}
            >
              <Box>{handleIMG()}</Box>
              <Box sx={{ height: "100vh" }}>
                <MoreResultsList
                  setDisableSelect={setDisableSelect}
                  moreResultType={moreResultType}
                  updatedTask={updatedTask}
                  currentTask={currentTask}
                  moreResultsText={moreResultsText}
                  setIsPreview={setIsPreview}
                  setNewRequestID={setNewRequestID}
                  setNewRequest={setNewRequest}
                  setInQueueMessage={setInQueueMessage}
                  setInQueueDrawer={setInQueueDrawer}
                  setFullRetrieve={setFullRetrieve}
                  fullRetrieve={fullRetrieve}
                  setRetrieveLoading={setRetrieveLoading}
                  isRetrievingAddress={isRetrievingAddress}
                  setIsRetrievingAddress={setIsRetrievingAddress}
                  retrieveLoading={retrieveLoading}
                  defaultAddress={defaultAddress}
                  handleRowClick={handleRowClick}
                  setSelectedRow={setSelectedRow}
                  addressRow={addressRow}
                  formik={formik}
                  defaultValues={defaultValues}
                  setCreateNocLoading={setCreateNocLoading}
                  setPreviewLoading={setPreviewLoading}
                  setAddressRow={setAddressRow}
                  setDefaultAddress={setDefaultAddress}
                />
                <Typography
                  variant="body1"
                  sx={{
                    color: "#BCA631",
                    fontWeight: "bold",
                    textAlign: "center",
                    pb: 2,
                    mx: 1,
                  }}
                >
                  {type === "edit" ? errorText : text}
                </Typography>
              </Box>
            </ReactCardFlip>
          </Box>
          {preview && !isFormCreated && (
            <LoadingButton
              type="submit"
              loading={Boolean(previewLoading)}
              onClick={handlePreview}
              fullWidth
              size="mall"
              sx={{
                position: "fixed",
                bottom: 0,
                borderRadius: 0,
                mt: 2,
                color: "#fff",
                backgroundColor: "#F2CD00",
                ":hover": { backgroundColor: "#CDAF0B" },
              }}
              variant="contained"
            >
              RESET
            </LoadingButton>
          )}
        </Grid>
      </Drawer>
      <Drawer
        PaperProps={{
          sx: {
            width: "100vw",
          },
        }}
        anchor="right"
        open={notarizeDrawer}
        onClose={() => {
          setNotarizeDrawer(false);
          setLicenses({
            contractorType: "ORGANIZATION",
            contractorTypeError: false,
            contractorName: "",
            contractorNameError: false,
            contractorFirst: "",
            contractorFirstError: false,
            contractorMiddle: "",
            contractorLast: "",
            contractorLastError: false,
          });
        }}
      >
        <OpenDocuments
          licenses={licenses}
          setLicenses={setLicenses}
          selectedRow={formData?.inputs}
          setOpenDrawer={setOpenDrawer}
          type="enlarge"
          setRadioValue={setRadioValue}
          unavailableRecord={unavailableRecord}
          radioValue={radioValue}
          successModal={successModal}
          setSuccessModal={setSuccessModal}
          notaryErrorText={notaryErrorText}
          serviceTiers={currentUser?.service_tiers}
          handleNotarize={handleNotarize}
          notaryLoading={notaryLoading}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          paymentCards={paymentCards}
          emailError={emailError}
          setEmailError={setEmailError}
          phoneError={phoneError}
          setPhoneError={setPhoneError}
          ownerEmail={ownerEmail}
          setOwnerEmail={setOwnerEmail}
          ownerPhone={ownerPhone}
          setOwnerPhone={setOwnerPhone}
          setNotarizeDrawer={setNotarizeDrawer}
          setFormDrawerOpen={setFormDrawerOpen}
          formDrawerOpen={formDrawerOpen}
          previewImg={previewImg}
          errorText={errorText}
          // editLoading={editLoading}
          previewLoading={previewLoading}
          preview={preview}
          formik={formik}
          pdfImg={currentTask?.return_values?.createdForm?.pdfImage}
          pdfImg2={currentTask?.return_values?.createdForm?.pdfImage2}
          inputs={currentTask?.return_values?.createdForm?.inputs}
          selectedPreName={currentTask?.return_values?.createdForm?.county}
          setEditDrawerOpen={setOpenDrawer}
          selectedPreForm="/assets/images/nocForms/NOC-empty.jpg"
          enlargeHeader=""
          enlargeNote=""
          addressRow=""
          cardFlip=""
          tierPrice={tierPrice}
        />
      </Drawer>
    </>
  );
};

export default EnlargeNotificationForm;
