import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { updateSession } from "../../store/actions/sessionsActions";
import { setLocalStorageItem } from "../../store/actions/localStorageActions";

import LoadingButton from "@mui/lab/LoadingButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Stack,
  Link,
  TextField,
  Typography,
  Grid,
  useMediaQuery,
  useTheme,
  CircularProgress,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { transformMemorableOption } from "../../functions/helperFunctions";

const TotpAuth = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const mobileSize = useMediaQuery(theme.breakpoints.down("sm"));
  const tabletSize = useMediaQuery(theme.breakpoints.down("md"));
  const largePC = useMediaQuery(theme.breakpoints.up("xl"));
  const [inputValues, setInputValues] = useState(["", "", "", "", "", ""]);
  const [isPasting, setIsPasting] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [tryLeft, setTryLeft] = useState(0);
  const [blocked, setBlocked] = useState(false);
  const [blockedText, setBlockedText] = useState("");
  const [userEmail, setUserEmail] = useState("");

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tokenFromQuery = queryParams.get("token");
  const rememberFromQuery = queryParams.get("remember");
  const [isTrouble, setIsTrouble] = useState(false);
  const [memorableValue, setMemorableValue] = useState("");
  const [memorableOption, setMemorableOption] = useState("");
  const [showMemorable, setShowMemorable] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [submitBlocked, setSubmitBlocked] = useState(true);

  function prepareBeaconData(payload) {
    const formData = new FormData();
    Object.keys(payload).forEach((key) => {
      formData.append(key, payload[key]);
    });
    return formData;
  }

  useEffect(() => {
    const payload = {
      token: tokenFromQuery,
    };

    const handleUnload = () => {
      const beaconData = prepareBeaconData(payload);
      navigator.sendBeacon(
        `${process.env.REACT_APP_BASE_URL}/nocApi/notary/emergency-clear-2fa-auth`,
        beaconData
      );
    };

    window.addEventListener("beforeunload", handleUnload);
    window.addEventListener("pagehide", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleUnload);
      window.removeEventListener("pagehide", handleUnload);
    };
  }, [tokenFromQuery]);

  let count = 0;
  useEffect(() => {
    if (blocked) {
      setSubmitBlocked(true);
    } else if (isTrouble && memorableValue === "") {
      setSubmitBlocked(true);
    } else if (
      !isTrouble &&
      (inputValues[0] === "" ||
        inputValues[1] === "" ||
        inputValues[2] === "" ||
        inputValues[3] === "" ||
        inputValues[4] === "" ||
        inputValues[5] === "")
    ) {
      setSubmitBlocked(true);
    } else {
      setSubmitBlocked(false);
    }
  }, [isTrouble, memorableValue, inputValues, blocked]);

  useEffect(() => {
    const memInterval = setInterval(() => {
      setBlockedText("");
    }, 5000);

    return () => {
      clearInterval(memInterval);
    };
  }, [blockedText]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (blocked) {
        navigate("/login");
        clearInterval(timer);
      }
    }, 6000);
    return () => {
      clearInterval(timer);
    };
  }, [blocked]);

  const callAuth = async () => {
    const code = inputValues.join("");
    setLoading(true);

    if (isTrouble && !isEmailSent) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/auth/2fa/memorable-from-sms`,
          {
            token: tokenFromQuery,
            memorableValue: memorableValue,
          },
          { withCredentials: true }
        );

        setBlockedText(response.data.message);

        if (response.data.success) {
          setIsEmailSent(true);
          setLoading(false);
          setMemorableValue("");
        } else {
          setMemorableValue("");
          setLoading(false);
        }
      } catch (e) {
        setMemorableValue("");
        setLoading(false);
        setBlockedText(e.response.data.message);
      }
    } else if (isTrouble && isEmailSent) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/auth/2fa/validate-memorable-email`,
          {
            token: tokenFromQuery,
            memorableValue: memorableValue,
            remember_token: rememberFromQuery,
            auth_type: "totp",
          },
          { withCredentials: true }
        );

        if (response.data.success) {
          if (rememberFromQuery === "true") {
            dispatch(
              setLocalStorageItem("ujsffq", { email: response.data.data.email })
            );
          }
          await dispatch(updateSession(response?.data?.data));

          if (response?.data?.data?.sessionType === "notarySession") {
            navigate("/notary/dashboard");
          } else if (
            response?.data?.data?.sessionType === "userSession" ||
            response?.data?.data?.sessionType === "subUserSession"
          ) {
            navigate("/dashboard");
          }
        } else {
          setLoading(false);
          setMemorableValue("");
        }
      } catch (e) {
        setLoading(false);
        setBlockedText(e.response.data.message);
        setMemorableValue("");
        setShowMemorable(false);
      }
    } else {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/auth/2fa/totp-pass`,
          {
            code: code,
            token: tokenFromQuery,
            remember_token: rememberFromQuery,
          },
          { withCredentials: true }
        );
        if (response.data.success) {
          if (rememberFromQuery === "true") {
            dispatch(
              setLocalStorageItem("ujsffq", { email: response.data.data.email })
            );
          }

          await dispatch(updateSession(response?.data?.data));

          if (response?.data?.data?.sessionType === "notarySession") {
            navigate("/notary/dashboard");
          } else if (
            response?.data?.data?.sessionType === "userSession" ||
            response?.data?.data?.sessionType === "adminSession" ||
            response?.data?.data?.sessionType === "subUserSession"
          ) {
            navigate("/dashboard");
          }
        }
      } catch (e) {
        if (e.response.data.message === "blocked") {
          setBlocked(true);
          if (e.response.data.data == null) {
            setTryLeft(3);
          } else {
            setTryLeft(e.response.data.data);
          }

          setBlockedText(
            "You have been blocked due to too many unsuccessful requests. Please try logging in again after an hour."
          );
        } else if (e.response.data.message === "countDown") {
          setBlockedText(`Invalid code. Please try again`);
          setTryLeft(e.response.data.data);
        }

        setInputValues(["", "", "", "", "", ""]);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (!tokenFromQuery) {
      navigate("/login");
      return;
    }

    const process_data = async () => {
      try {
        const tokenResponse = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/auth/2fa/totp-verify`,
          {
            token: tokenFromQuery,
          }
        );
        if (tokenResponse.data.success) {
          setUserEmail(tokenResponse.data.data);
          setMemorableOption(tokenResponse.data.memorable);
          setPageLoading(false);
        } else {
          navigate("/login");
          throw new Error("Network response was not ok");
        }
        //}
      } catch (error) {
        if (error.response.data.message === "blocked") {
          setBlocked(true);
          setBlockedText(
            "You have been blocked due to too many unsuccessful requests. Please try again later."
          );
          if (error.response.data.data == null) {
            setTryLeft(3);
          } else {
            setTryLeft(error.response.data.data);
          }
        } else {
          console.error("Error fetching QR code:", error);
          navigate("/login");
        }
      }
    };

    if (count < 1) {
      process_data();
      count += 1;
    }
  }, []);

  const handlePaste = (event) => {
    const pasteData = event.clipboardData.getData("text");

    if (pasteData.length === 6) {
      const newInputValues = pasteData.split("");
      setInputValues(newInputValues);
      setIsPasting(true);
      setTimeout(() => {
        setIsPasting(false);
      }, 0);
      event.preventDefault(); // Prevent default paste behavior
    }
  };

  const handleInputChange = (index, value) => {
    if (!isPasting && value.length > 1) {
      value = value.slice(0, 1); // Allow only one character
    }
    const newInputValues = [...inputValues];
    newInputValues[index] = value;
    setInputValues(newInputValues);

    if (value && index < 5) {
      const nextInput = document.getElementById(`input-${index + 1}`);
      if (nextInput) {
        nextInput.focus();
      }
    } else if (!value && index > 0) {
      const prevInput = document.getElementById(`input-${index - 1}`);
      if (prevInput) {
        prevInput.focus();
      }
    }
  };

  return (
    <>
      {pageLoading ? (
        <>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100vh",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CircularProgress color="warning" size="10rem" />
          </Box>
        </>
      ) : (
        <Box
          sx={
            mobileSize
              ? {
                  justifyContent: "center",
                  overflowX: "hidden",
                  px: 1,
                  minHeight: "100vh",
                  overflowY: "hidden",
                  display: "flex",
                  flexDirection: "column",
                }
              : {
                  backgroundImage: "url('/img/website_img/homeImg.jpeg')",
                  backgroundRepeat: "repeat",

                  minHeight: "100vh",
                  overflow: "hidden",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }
          }
        >
          <Box
            sx={
              mobileSize
                ? {
                    backgroundColor: "#fff",
                    py: "1rem",
                    maxWidth: "100%",
                    flexGrow: 1,
                  }
                : {
                    backgroundColor: "#fff",
                    mt: 2,
                    maxWidth: largePC ? "1100px" : "70%",
                    borderRadius: "25px",
                    borderBottomLeftRadius: "0px",
                    borderBottomRightRadius: "0px",
                    px: 3,
                    py: "10px",
                    width: "80%",
                    pt: "2rem",
                  }
            }
          >
            <Stack spacing={1} sx={{ mb: 3 }}>
              <Box
                sx={{ cursor: "pointer" }}
                display="flex"
                justifyContent="center"
                onClick={() => {
                  navigate("/");
                }}
              >
                <img
                  style={{
                    width: mobileSize ? "75%" : "10rem",
                  }}
                  alt="Permit Rockstar Logo"
                  src="/img/website_img/PermitRockstar.png"
                />
              </Box>
            </Stack>
            <Typography
              sx={{ mb: 2 }}
              color="primary"
              variant={mobileSize ? "h5" : "h4"}
              align="center"
            >
              Two Factor Authentication is Required
            </Typography>
            <Typography
              color="primary"
              variant={mobileSize ? "h6" : "h5"}
              align="center"
            >
              {isTrouble
                ? "Unlock with your security answer"
                : "If you have not already done so, please open Google Authenticator and enter the code below"}
            </Typography>
            <Typography
              color="primary"
              variant="body1"
              align="center"
              sx={{ mb: mobileSize ? 0 : 5 }}
            >
              {isTrouble
                ? !isEmailSent
                  ? "To proceed with the login process, please enter the answer to your security question."
                  : "To proceed with the login process, please enter your verification code sent by email."
                : ""}
            </Typography>

            <Box
              sx={{
                borderRadius: "10px",
                backgroundColor: "transparent",
                flexDirection: "column",
                cursor: "auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <img
                alt="authenticator"
                src="/img/logos/2falogo.png"
                width="80%"
                style={{ maxWidth: !mobileSize ? "250px" : undefined }}
              />
            </Box>
          </Box>
          <Box
            sx={
              !mobileSize
                ? {
                    backgroundColor: "#fff",
                    mb: 2,
                    maxWidth: largePC ? "1100px" : "70%",
                    borderRadius: "25px",
                    borderTopLeftRadius: "0px",
                    borderTopRightRadius: "0px",
                    px: 3,
                    py: "10px",
                    width: "80%",
                    pt: "2rem",
                  }
                : { mb: 0, backgroundColor: "#fff" }
            }
          >
            <Grid container>
              <Grid item xs={12}>
                <Box
                  sx={
                    isTrouble
                      ? {
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          mx: "auto",
                        }
                      : {
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }
                  }
                >
                  {isTrouble ? (
                    <>
                      <Grid container align="center" sx={{ width: "100%" }}>
                        <Grid item xs={12}>
                          <Typography variant="h6" sx={{ mb: 2 }}>
                            {isEmailSent
                              ? "Enter your Verification Code"
                              : transformMemorableOption(memorableOption)}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth={mobileSize}
                            sx={{
                              width: mobileSize
                                ? "100%" // If mobileSize, ensure width is set to 100% to avoid conflicts
                                : tabletSize
                                ? "90%" // If largeTabletSize and not mobileSize, set width to 60%
                                : "55%", // Default width when not mobileSize and not largeTabletSize
                            }}
                            type={showMemorable ? "text" : "password"}
                            label={
                              isEmailSent
                                ? "Verification Code"
                                : mobileSize
                                ? "Answer to your security question"
                                : "Please enter the answer to your security question"
                            }
                            value={memorableValue}
                            onChange={(e) => setMemorableValue(e.target.value)}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() => {
                                      setShowMemorable(!showMemorable);
                                    }}
                                    onMouseDown={(e) => e.preventDefault()} // Prevent focus change on mouse down
                                    onMouseUp={(e) => e.preventDefault()} // Prevent focus change on mouse up
                                    edge="end"
                                  >
                                    {showMemorable ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <Box
                      sx={{
                        mt: tabletSize ? 0 : 0,
                        mx: "6px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "row",
                        gap: 1,
                        overflow: "auto",
                        width: "100%",
                        // maxWidth: "95vw",
                      }}
                    >
                      {inputValues.map((value, index) => (
                        <TextField
                          key={index}
                          disabled={blocked}
                          id={`input-${index}`}
                          value={value}
                          variant="outlined"
                          onChange={(e) =>
                            handleInputChange(index, e.target.value)
                          }
                          onPaste={index === 0 ? handlePaste : undefined}
                          inputProps={{
                            maxLength: 1,
                            style: {
                              textAlign: "center",
                              padding: mobileSize && "5px",
                              fontSize: "28px",
                              fontWeight: "bold",
                            },
                          }}
                          sx={{
                            width: mobileSize ? "35px" : "48px",
                            minWidth: mobileSize ? "15px" : undefined,
                            textAlign: "center",
                            border: "3px solid #000",
                            borderRadius: "10px",
                          }}
                        />
                      ))}
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>

            <Grid container justifyContent="center" mb={2} mt={5}>
              <Grid item xs={12} sx={{ width: "100%" }}>
                <LoadingButton
                  onClick={callAuth}
                  disabled={submitBlocked}
                  loading={loading}
                  fullWidth
                  size="large"
                  sx={{ mt: 0, p: 2 }}
                  type="submit"
                  variant="contained"
                >
                  S U B M I T
                </LoadingButton>
              </Grid>
              <Link
                // component={NextLink}
                sx={{
                  fontWeight: "bold",
                  // ml: "auto",
                  mt: 2,
                  cursor: "pointer",
                }}
                onClick={() => {
                  setIsTrouble(!isTrouble);
                  setMemorableValue("");
                  setInputValues(["", "", "", "", "", ""]);
                }}
                underline="hover"
                variant="subtitle2"
              >
                {isTrouble ? "Back to 2FA" : "Having trouble logging in?"}
              </Link>
              <Grid
                item
                xs={12}
                sx={{ mt: 2, minHeight: mobileSize ? "0rem" : "1.5rem" }}
              >
                <Typography
                  variant={mobileSize ? "body2" : "body1"}
                  textAlign="center"
                  sx={{ color: "#4caf50" }}
                  fontWeight="bold"
                >
                  {blockedText}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
    </>
  );
};

export default TotpAuth;
