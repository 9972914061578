import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  Box,
  Tooltip,
  IconButton,
  Alert,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { green } from "@mui/material/colors";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const featureMapping = {
  0: "noc",
  1: "county",
  2: "license",
  3: "subuser",
  4: "permit_package",
  5: "notary_record",
};

const prefixMapping = {
  0: "",
  1: "",
  2: "",
  3: "",
  4: "permit_package",
  5: "notary_record",
};

const PricingCard = ({
  planOptions,
  title,
  price,
  checked,
  selectedPlan,
  setSelectedPlan,
  plan,
  contractorList,
  showAlert,
  setShowAlert,
  isFadingOut,
  type,
  handleDisable,
  mobileSize,
  promoCodeName,
  trial,
  currentUser,
}) => {
  const theme = useTheme();
  const isDisabled = contractorList.length > 1 && plan?.monthly_restart;

  const formatValue = (value) => {
    // Check if the value is a string containing a number
    if (!isNaN(value)) {
      const floatValue = parseFloat(value);
      // Format the float value to two decimal places if it doesn't already have them
      return floatValue % 1 === 0 ? floatValue.toFixed(2) : value;
    }
    return value; // Return the original value if it's not a number
  };

  return (
    <>
      <Card
        sx={{
          maxWidth: 400,
          boxShadow:
            "0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)",
          position: "relative", // Ensure relative positioning for the banner
        }}
      >
        {/* Red Banner */}
        {promoCodeName && (
          <Box
            sx={{
              position: "absolute",
              top: 40,
              right: -40,
              transform: "rotate(45deg)",
              backgroundColor: "red",
              color: "white",
              padding: "8px 40px",
              fontSize: "1rem",
              fontWeight: "bold",
              zIndex: 10,
            }}
          >
            {promoCodeName}
          </Box>
        )}

        <CardContent
          sx={{
            "&.MuiCardContent-root": { paddingBottom: 0 },
          }}
        >
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            color={
              selectedPlan?.title === title ? green[500] : "text.secondary"
            }
          >
            {title}
          </Typography>
          <Typography variant="h4" color="primary">
            ${price}
            <Typography
              variant="subtitle1"
              component="span"
              color="text.secondary"
            >
              / {checked ? "year" : "month"}
            </Typography>
          </Typography>
          <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 2 }}>
            billed {checked ? "yearly" : "monthly"}
          </Typography>
          {selectedPlan?.title === title ? (
            <>
              <Button
                disabled={
                  type === "change" && !currentUser?.user?.expired
                    ? handleDisable(title)
                    : undefined
                }
                onClick={() => setSelectedPlan()}
                variant="contained"
                sx={{
                  backgroundColor: green[500],
                  "&:hover": { backgroundColor: green[700] },
                  mb: 2,
                }}
              >
                {type === "change" &&
                !currentUser?.user?.expired &&
                handleDisable(title)
                  ? "CURRENT PLAN"
                  : "PLAN SELECTED"}
              </Button>
            </>
          ) : (
            <>
              <div style={{ display: "inline-flex", alignItems: "center" }}>
                <Button
                  disabled={
                    isDisabled ||
                    (type === "change" &&
                      handleDisable(title) &&
                      !currentUser?.user?.expired)
                  }
                  onClick={() => setSelectedPlan(plan)}
                  variant="outlined"
                  sx={{
                    color: green[500], // Text color
                    borderColor: green[500], // Border color
                    "&:hover": {
                      backgroundColor: green[700], // Background color on hover
                      borderColor: green[700], // Optional: Change border color on hover
                      color: "#fff", // Change text color on hover if needed
                    },
                    mb: 2,
                    // Adding styles for the disabled state
                    "&.Mui-disabled":
                      type !== "change" || !handleDisable(title)
                        ? {
                            color: theme.palette.error.main, // Text color when disabled
                            borderColor: theme.palette.error.main, // Border color when disabled
                            // Optionally, set a background color for the disabled state if needed
                            // backgroundColor: theme.palette.error.light,
                          }
                        : {},
                  }}
                >
                  {isDisabled
                    ? "PLAN UNAVAILABLE"
                    : type === "change" &&
                      handleDisable(title) &&
                      !currentUser?.user?.expired
                    ? "CURRENT PLAN"
                    : "CHOOSE PLAN"}
                </Button>
                {isDisabled && (
                  <IconButton sx={{ mb: 2 }} onClick={() => setShowAlert(true)}>
                    <HelpOutlineIcon
                      sx={{ color: theme.palette.error.main, ml: 1 }}
                    />
                  </IconButton>
                )}
              </div>
            </>
          )}

          <Typography
            variant="subtitle1"
            gutterBottom
            component="div"
            fontWeight="bold"
            color="text.secondary"
          >
            {title} plan includes:
          </Typography>

          <List dense>
            {planOptions?.map((option, index) => (
              <ListItem key={index} disableGutters sx={{ p: 0 }}>
                <ListItemText
                  primary={
                    featureMapping[index] === "permit_package" &&
                    title === "Pay As You Go"
                      ? `$${formatValue(
                          plan[featureMapping[index]]
                        )}  ${option}`
                      : featureMapping[index] === "notary_record"
                      ? `$${formatValue(
                          plan[featureMapping[index]]
                        )}  ${option}`
                      : `${plan[featureMapping[index]]} ${option}`
                  }
                  primaryTypographyProps={{
                    variant: "body2",
                    color: "text.secondary",
                  }}
                />
              </ListItem>
            ))}
          </List>
        </CardContent>
      </Card>
      {isDisabled && showAlert && (
        <Alert
          sx={{
            zIndex: 333,
            position: "absolute",
            right: 8,
            left: type === "change" ? 230 : 24,
            top: type === "change" ? 160 : 150,
            opacity: isFadingOut ? 0 : 1,
            transition: "opacity 1000ms ease-in-out",
          }}
          variant="filled"
          severity="error"
        >
          There is only one contractor license allowed under this plan. You've
          selected {contractorList.length} contractors. To select this plan,
          please select only one contractor.
        </Alert>
      )}
    </>
  );
};

export default PricingCard;
