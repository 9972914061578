import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import { LoadingButton } from "@mui/lab";
import { Modal, Box, Button, Typography, TextField, Grid } from "@mui/material";

const DocumentFailedModal = ({
  failedModal,
  setFailedModal,
  selectedRow,
  failures,
  setFailures,
  setCurrentIndex,
  currentIndex,
  notary_price,
  handleCancelNotarization,
  handleRepeateNotarization,
}) => {
  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };
  return (
    <Modal
      open={failedModal}
      //   onClose={() => {
      //     setRecordModal(false);
      //   }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 700,
          bgcolor: "background.paper",
          boxShadow: 24,
          borderRadius: "1rem",
          p: 4,
        }}
      >
        {!failures.failedHeader ||
        (selectedRow?.notary_failed &&
          failures.failedHeader !== "Notarization Failed") ||
        (selectedRow?.recording_failed &&
          failures.failedHeader !== "Recording Failed") ? (
          <Typography
            id="modal-title"
            color="error"
            variant="h3"
            align="center"
            component="h2"
          >
            Loading
          </Typography>
        ) : (
          <>
            <Typography
              id="modal-title"
              color="error"
              variant="h3"
              align="center"
              component="h2"
            >
              {failures.failedHeader}
            </Typography>
            <Typography
              id="modal-title"
              color="primary"
              variant="h6"
              align="center"
            >
              {selectedRow?.source === "internal"
                ? "(Internal Document)"
                : "(External Document)"}
            </Typography>
            <Typography variant="h5" align="center">
              {failures?.notaryErrorReason}
            </Typography>
            <Box sx={{ mt: 3 }}>
              <Typography sx={{ mb: 2 }} variant="h6" align="center">
                Please select one of the following options
              </Typography>
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Button
                  disabled={failures?.cancelButtonDisabled}
                  onClick={() => {
                    setFailures({
                      ...failures,
                      cancelButtonSelected: !failures.cancelButtonSelected,
                      againButtonSelected: false,
                    });
                  }}
                  variant={
                    failures?.cancelButtonSelected === false
                      ? "outlined"
                      : "contained"
                  }
                  color="error"
                  fullWidth
                >
                  {failures.cancelButtonText}
                </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  disabled={failures?.againButtonDisabled}
                  onClick={() => {
                    setFailures({
                      ...failures,
                      againButtonSelected: !failures.againButtonSelected,
                      cancelButtonSelected: false,
                    });
                  }}
                  variant={
                    failures?.againButtonSelected === false
                      ? "outlined"
                      : "contained"
                  }
                  color="success"
                  fullWidth
                >
                  {failures.againButtonText}
                </Button>
              </Grid>
            </Grid>

            {failures?.cancelButtonSelected === true ? (
              <>
                <div
                  style={{
                    borderBottom: "3px solid #d3d3d3",
                    marginTop: "1rem",
                    marginBottom: "1rem",
                  }}
                ></div>
                <Box sx={{ mt: 3 }}>
                  {failures?.isNotarized === false ? (
                    <>
                      <Typography variant="h5" align="center" mb={0}>
                        {failures.refundText}
                      </Typography>
                      <Typography
                        align="center"
                        color="primary"
                        sx={{ fontWeight: "bold", mb: "2rem" }}
                      >
                        {selectedRow?.source === "internal"
                          ? "(In the event that cancellation is selected, the document will be returned to the dashboard's 'NOC PDF Forms Created' section)"
                          : "(In the event that cancellation is selected, the document will be permanently removed)"}
                      </Typography>
                      <Typography
                        align="center"
                        mb={1}
                        color="error"
                        sx={{ fontWeight: "bold" }}
                        variant="body2"
                      >
                        {failures?.cancelSuccessText}
                      </Typography>
                      <LoadingButton
                        onClick={handleCancelNotarization}
                        loading={failures?.cancelNotaryLoading}
                        fullWidth
                        variant="contained"
                        color="error"
                      >
                        {selectedRow?.source === "internal"
                          ? "I AGREE AND MOVE THE DOCUMENT BACK TO THE DASHBOARD"
                          : "I AGREE AND REMOVE MY DOCUMENT"}
                      </LoadingButton>

                      {/* <Typography
                        align="center"
                        sx={{ fontSize: "10px", mt: "0.5rem" }}
                      >
                        In the event that cancellation is selected, <br /> the
                        document will be returned to the "{selectedRow?.source}{" "}
                        documents" section
                      </Typography> */}
                    </>
                  ) : (
                    <>
                      <Typography variant="h5" align="center" mb={0}>
                        Cancel Recording
                      </Typography>
                      <Typography
                        align="center"
                        color="primary"
                        sx={{ fontWeight: "bold", mb: "2rem" }}
                      >
                        In the event that cancellation is selected, <br /> the
                        document will be returned to the "{selectedRow?.source}{" "}
                        documents" section
                      </Typography>
                      <Typography
                        align="center"
                        mb={1}
                        color="error"
                        sx={{ fontWeight: "bold" }}
                        variant="body2"
                      >
                        {failures?.cancelSuccessText}
                      </Typography>
                      <LoadingButton
                        loading={failures?.cancelNotaryLoading}
                        onClick={handleCancelNotarization}
                        fullWidth
                        variant="contained"
                        color="error"
                      >
                        MOVE THE DOCUMENT BACK TO THE "
                        {selectedRow?.source.toUpperCase()} DOCUMENTS"
                      </LoadingButton>
                      {failures?.hasRefund === false && (
                        <Typography
                          align="center"
                          sx={{ fontSize: "10px", mt: "0.5rem" }}
                        >
                          Documents remain notarized and are accessible in the
                          {selectedRow?.source === "internal"
                            ? "internal documents"
                            : "external documents"}{" "}
                          section, where they can be downloaded if necessary.
                        </Typography>
                      )}
                    </>
                  )}
                </Box>
              </>
            ) : failures?.againButtonSelected === true ? (
              <>
                <div
                  style={{
                    borderBottom: "3px solid #d3d3d3",
                    marginTop: "1rem",
                    marginBottom: "1rem",
                  }}
                ></div>
                <Box sx={{ mt: 3 }}>
                  {failures?.isNotarized === false ? (
                    <>
                      <Typography variant="h4" align="center" mb={0.5}>
                        {failures.againButtonHeader}
                      </Typography>
                      <Typography
                        variant="h6"
                        align="center"
                        color="primary"
                        sx={{ fontWeight: "bold" }}
                      >
                        {selectedRow?.document?.property_address}
                      </Typography>
                      <Typography
                        variant="h6"
                        align="center"
                        color="primary"
                        sx={{
                          fontWeight: "bold",
                          mb:
                            selectedRow?.reason === "record"
                              ? "0.5rem"
                              : "2rem",
                        }}
                      >
                        {selectedRow?.document?.county}
                      </Typography>

                      {selectedRow?.reason === "record" && (
                        <>
                          <Typography
                            align="center"
                            color={
                              selectedRow?.attempt >= 3 ? "error" : "#FFA726"
                            }
                            sx={{ fontWeight: "bold", mb: "2rem" }}
                          >
                            {selectedRow?.attempt < 3
                              ? `Your remaining recording attempts are
                        ${3 - selectedRow?.attempt} out of 3`
                              : "You have no more recording attempts left"}
                          </Typography>
                        </>
                      )}

                      <Typography
                        align="center"
                        mb={1}
                        color="error"
                        sx={{ fontWeight: "bold" }}
                        variant="body2"
                      >
                        {failures?.againSuccessText}
                      </Typography>
                      <LoadingButton
                        disabled={3 - selectedRow?.attempt === 0}
                        loading={failures?.againNotaryLoading}
                        onClick={handleRepeateNotarization}
                        fullWidth
                        variant="contained"
                        color="success"
                      >
                        {selectedRow?.reason === "notaryRecord"
                          ? `START NOTARIZE - RECORD DOCUMENT`
                          : selectedRow?.reason === "notary"
                          ? `START NOTARIZE DOCUMENT`
                          : "SEND DOCUMENT AND START RECORDING"}
                      </LoadingButton>
                      {failures?.hasRefund === false && (
                        <Typography
                          align="center"
                          sx={{ fontSize: "12px", mt: "0.5rem" }}
                        >
                          The signer will receive an invitation to schedule an
                          appointment for Remote Online Notarization (RON).
                        </Typography>
                      )}
                    </>
                  ) : (
                    <>
                      <Typography variant="h5" align="center" mb={0}>
                        Record Notarized Document
                      </Typography>
                      <Typography
                        align="center"
                        color={selectedRow?.attempt >= 3 ? "error" : "primary"}
                        sx={{ fontWeight: "bold", mb: "2rem" }}
                      >
                        {selectedRow?.attempt < 3
                          ? `Your remaining recording attempts are
                        ${3 - selectedRow?.attempt} out of 3`
                          : "You have no more recording attempts left"}
                      </Typography>
                      <Typography
                        align="center"
                        mb={1}
                        color="error"
                        sx={{ fontWeight: "bold" }}
                        variant="body2"
                      >
                        {failures?.againSuccessText}
                      </Typography>
                      <LoadingButton
                        loading={failures?.againNotaryLoading}
                        onClick={handleRepeateNotarization}
                        disabled={selectedRow?.attempt >= 3}
                        fullWidth
                        variant="contained"
                        color="success"
                      >
                        START RECORD DOCUMENT
                      </LoadingButton>
                      {failures?.hasRefund === false && (
                        <Typography
                          align="center"
                          sx={{ fontSize: "10px", mt: "0.5rem" }}
                        >
                          Documents remain notarized and are accessible in the
                          {selectedRow?.source === "internal"
                            ? "internal documents"
                            : "external documents"}{" "}
                          section, where they can be downloaded if necessary.
                        </Typography>
                      )}
                    </>
                  )}
                </Box>
              </>
            ) : null}

            <div
              style={{
                borderBottom: "3px solid #d3d3d3",
                marginTop: "0.5rem",
                marginBottom: "1rem",
              }}
            ></div>

            <Button
              disabled={failures?.closeDisabled}
              variant="contained"
              fullWidth
              onClick={() => {
                setFailedModal(false);
                setFailures({
                  ...failures,
                  cancelButtonSelected: false,
                  againButtonSelected: false,
                });
              }}
            >
              Close
            </Button>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default DocumentFailedModal;
