import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import { green } from "@mui/material/colors";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PermitPackagePaymentModal from "../modals/PermitPackagePaymentModal";
import { updatePermitPackage } from "../../store/actions/dataActions";

const PermitPackagePaymentCard = (props) => {
  const dispatch = useDispatch();

  const [paymentLoading, setPaymentLoading] = useState(false);
  const [paymentErrorText, setPaymentErrorText] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [permitModalOpen, setPermitModalOpen] = useState(false);

  const handlePermitPackagePayment = async () => {
    try {
      setPaymentLoading(true);
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/stripe/process-permit-package-payment`,
        {
          paymentCard: props?.paymentCards[activeStep]?.id,
          task_id: props?.task_id,
          currentSession: props?.currentSession,
        },
        { withCredentials: true }
      );

      const stripe = await loadStripe(process.env.REACT_APP_STRIPE); // Replace with your actual publishable key
      const result = await stripe.confirmCardPayment(resp.data.data);
      if (result.error) {
        setPaymentErrorText(result.error.message);
        // Handle the error on the frontend
      } else {
        // The payment has been processed!
        if (
          result.paymentIntent.status === "succeeded" &&
          resp.data.valid === true
        ) {
          const paymentIntent = result.paymentIntent;
          const resp = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/nocApi/stripe/finish-permit-package-payment`,
            {
              paymentIntent,
              task_id: props?.task_id,
              currentSession: props?.currentSession,
            },
            { withCredentials: true }
          );

          if (resp?.data?.success) {
            await dispatch(updatePermitPackage(resp?.data?.data));
            props?.setSnackOpen(true);
          }
        }
      }
    } catch (e) {
    } finally {
      setPaymentLoading(false);
    }
  };

  return (
    <>
      <Card
        sx={{
          maxWidth: "800px",
          mt: 0,
          mx: "auto",
          boxShadow:
            "0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)",
          position: "relative", // Ensure relative positioning for the banner
        }}
      >
        {/* Red Banner */}
        {true === true && (
          <Box
            sx={{
              position: "absolute",
              top: 30,
              right: -40,
              transform: "rotate(45deg)",
              backgroundColor: "#ff9800",
              color: "white",
              padding: "8px 40px",
              fontSize: "1rem",
              fontWeight: "bold",
              zIndex: 10,
            }}
          >
            {props?.plan?.title}
          </Box>
        )}

        <CardContent
          sx={{
            "&.MuiCardContent-root": { paddingBottom: 0 },
          }}
        >
          <Typography
            gutterBottom
            variant="h4"
            align="center"
            component="div"
            color="primary"
            sx={{ px: 4 }}
          >
            Streamline Your Permit Process
          </Typography>
          <Typography
            gutterBottom
            variant="h6"
            align="center"
            component="div"
            color="primary"
            sx={{ px: 4 }}
          >
            Take advantage of the full permit package by gaining access to the
            documents
          </Typography>
          <Typography mt={6} align="center" variant="h4" color={green[700]}>
            Only{" "}
            <span style={{ fontSize: "55px", color: green[500] }}>
              ${Number(props?.serviceTiers?.permit_package).toFixed(2)}
            </span>
          </Typography>

          <Box textAlign="center" mt={4} mb={3}>
            <Typography variant="body1" fontWeight="bold" gutterBottom>
              Our cutting-edge technology is designed with busy contractors in
              mind, putting an end to endless paperwork and the hassle of
              chasing homeowners for signatures. With our platform, creating
              documents for your permits is incredibly quick and
              straightforward. We auto-calculate and auto-fill 80% of the fields
              and provide easy to follow guidance for the rest so you can save
              time and avoid the frustration of getting stuck on complex forms.
              Simplify your workflow and focus on getting the job done. Sign up
              now and experience the ease and efficiency of our solution.
            </Typography>
          </Box>
          <Box sx={{ borderBottom: "3px solid #d3d3d3" }} />

          <Box sx={{ borderBottom: "3px solid #d3d3d3" }} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              mt: 3,
              mb: 3,
            }}
          >
            <Button
              onClick={() => setPermitModalOpen(true)}
              variant="contained"
              sx={{
                width: "50%",
                backgroundColor: green[500],
                "&:hover": { backgroundColor: green[700] },
              }}
            >
              GET OFFER
            </Button>
          </Box>
        </CardContent>
      </Card>

      <PermitPackagePaymentModal
        open={permitModalOpen}
        setOpen={setPermitModalOpen}
        paymentCards={props?.paymentCards}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        handlePermitPackagePayment={handlePermitPackagePayment}
        paymentLoading={paymentLoading}
      />
    </>
  );
};

export default PermitPackagePaymentCard;
