import { Card, CardContent } from "@mui/material";
import { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";

const NotaryRequestsGrid = ({
  setNotaryRequests,
  setRequestsSelectionModel,
  requestsSelectionModel,
  notaryRequestRows,
  setFirstSData,
  setSecondSData,
  setSelectedCurrentScheduleRow,
  setIsActiveLog,
  setActiveLogs,
  setNotarySelected,
  setSigneeSelected,
}) => {
  const [gridKey, setGridKey] = useState(0);

  const tableData = (notary_task) => {
    const firstData = [
      { label: "Task ID", value: notary_task.task_id },
      { label: "NOC ID", value: notary_task.id },
      { label: "RON Status", value: notary_task.ron_status },
      {
        label: "Date of Payment",
        value: new Date(notary_task.date_of_payment * 1000).toLocaleDateString(
          "en-US"
        ),
      },
      {
        label: "Amount of Payment",
        value: "$" + notary_task.amount_of_payment,
      },
      {
        label: "Requestor Full Name",
        value: notary_task.payment_full_name,
      },
      { label: "Requestor Email", value: notary_task.payment_email },
      {
        label: "Signee Email",
        value: notary_task.signee_email,
      },
      {
        label: "RON Date",
        value: notary_task.ron_date,
      },
    ];

    const secondData = [
      {
        label: "Signee Registered",
        value: notary_task.signee_registered ? "Yes" : "No",
      },
      {
        label: "Scheduled Appointment",
        value: !notary_task.waiting_to_schedule ? "Yes" : "No",
      },
      { label: "Has Notary", value: notary_task.has_notary ? "Yes" : "No" },
      { label: "Notary ID", value: notary_task.notary_id },
      {
        label: "Notary Accepted Job Date",
        value: notary_task.notary_accepted_job_date
          ? new Date(
              notary_task.notary_accepted_job_date * 1000
            ).toLocaleDateString("en-US")
          : "",
      },
      { label: "Contractor Name", value: notary_task.contractor_name },
      { label: "Contractor Email", value: notary_task.contractor_email },
      { label: "Document County", value: notary_task.document_county },
      { label: "Document Owner", value: notary_task.document_owner },
      {
        label: "Document Property Address",
        value: notary_task.document_property_address,
      },
    ];

    return { firstData, secondData };
  };

  const notaryRequestsColumn = [
    {
      field: "document_property_address",
      headerName: "Property Address",
      flex: 2,
      editable: false,
    },
    {
      field: "document_county",
      headerName: "County",
      flex: 0.5,
      editable: false,
    },
    {
      field: "signee_email",
      headerName: "Signee Email",
      flex: 1.5,
      editable: false,
    },
    {
      field: "payment_email",
      headerName: "Requestor Email",
      flex: 1.5,
      editable: false,
    },
    {
      field: "ron_status",
      headerName: "RON Status",
      flex: 1.5,
      editable: false,
    },
    {
      field: "ron_date",
      headerName: "Scheduled RON Date",
      flex: 1,
      editable: false,
    },
  ];
  const getNotaryRequests = (params, event) => {
    if (!event.target.closest(".delete-icon")) {
      if (requestsSelectionModel.includes(params.id)) {
        setRequestsSelectionModel([]);
        setNotaryRequests(null);
        // Force the DataGrid to re-render with a new key
        setGridKey((prevKey) => prevKey + 1);
        setIsActiveLog(false);
        setNotarySelected(true);
        setSigneeSelected(true);
        setActiveLogs([]);
        setSelectedCurrentScheduleRow([]);
      } else {
        setRequestsSelectionModel([params.id]);
        setNotaryRequests(params.row);

        const data = tableData(params.row);
        setFirstSData(data.firstData);
        setSecondSData(data.secondData);
        setIsActiveLog(false);
        setActiveLogs([]);
        setSelectedCurrentScheduleRow(params.row);
        setNotarySelected(true);
        setSigneeSelected(true);
      }
    }
  };

  return (
    <>
      <Card
        sx={{
          backgroundColor: "#FCF2F0",
        }}
      >
        <CardContent sx={{ padding: 0 }}>
          <DataGrid
            key={gridKey}
            className="cell"
            sx={{
              "& .MuiDataGrid-columnHeaders": {
                padding: 0, // Adjust as needed
                margin: 0, // Adjust as needed
              },
              "& .MuiDataGrid-columnHeadersInner": {
                width: "100%", // Adjust as needed
              },
              border: "1px solid #FCF2F0", // Change the border color of the entire DataGrid
              "& .MuiDataGrid-columnHeader": {
                backgroundColor: "#333333", // Change column header background color
                color: "white", // Optional: Change text color for contrast
              },
              "& .MuiDataGrid-cell": {
                borderColor: "#333333", // Change cell border color
              },
              "& .MuiDataGrid-columnSeparator": {
                display: "none", // Optional: Hide the column separators
              },
              "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                outline: "none !important",
              },
              "& .MuiDataGrid-columnHeader:focus-within": {
                outline: "none !important",
              },
            }}
            rows={notaryRequestRows || []}
            columns={notaryRequestsColumn}
            getRowId={(row) => row?.id}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5, 10, 25, 50, 100]}
            onRowClick={getNotaryRequests}
            selectionModel={requestsSelectionModel}
            onSelectionModelChange={(newSelectionModel) => {
              setRequestsSelectionModel(newSelectionModel);
            }}
          />
        </CardContent>
      </Card>
    </>
  );
};

export default NotaryRequestsGrid;
