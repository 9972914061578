import { useState } from "react";
import axios from "axios";
import {
  Box,
  Tooltip,
  Typography,
  IconButton,
  Card,
  CardHeader,
  CardContent,
  Button,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import HomeIcon from "@mui/icons-material/Home";
import PersonIcon from "@mui/icons-material/Person";
import CachedIcon from "@mui/icons-material/Cached";
import { DocumentArrowDownIcon } from "@heroicons/react/24/outline";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import MaximisePDF from "../notary/modals/MaximisePDF";

const SubUserCreatedForms = (props) => {
  const [downloadingRowId, setDownloadingRowId] = useState(null);
  const [formModalOpen, setFormModalOpen] = useState(false);
  const [mobileDownloading, setMobileDownloading] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);

  const handleOpenModal = (row) => {
    setSelectedRow(row);
    setFormModalOpen(true);
  };

  const handleDownloadNoc = async (selectedRow) => {
    if (mobileDownloading) {
      return;
    }
    try {
      setMobileDownloading(true);
      setDownloadingRowId(selectedRow.id);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/data/download-created-file`,
        {
          pdf: selectedRow?.pdfUrl,
          currentSession: "userSession",
        },
        {
          withCredentials: true,
          responseType: "blob", // Important: This tells Axios to expect a binary response
        }
      );
      // Create a URL for the blob
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      // Create an anchor (`<a>`) element
      const fileLink = document.createElement("a");
      fileLink.href = fileURL;
      // Set the download attribute to the desired file name
      fileLink.setAttribute("download", selectedRow?.pdfName); // Customize the file name if necessary
      // Append to the document and trigger the download
      document.body.appendChild(fileLink);
      fileLink.click();
      // Clean up
      document.body.removeChild(fileLink);
      window.URL.revokeObjectURL(fileURL); // Release the object URL
      // setDownloadingFile(true);
      setDownloadingRowId(null);
      setMobileDownloading(false);
    } catch (e) {
      setDownloadingRowId(null);
      setMobileDownloading(false);
    }
  };

  const createdFormColumnsSmall = [
    {
      field: "address",
      headerName: "NOC - Created Forms",
      flex: 1,
      editable: false,
      renderCell: (params) => {
        const formattedDate = isNaN(new Date(params.row.date))
          ? ""
          : new Date(params.row.date).toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
            });

        return (
          <>
            <Box
              sx={{
                width: "97%",
                display: "flex",
                flexDirection: "column",
                // borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                pb: 0.2,
                pl: 1,
              }}
            >
              <Typography
                sx={{
                  textAlign: "left",
                  fontSize: "0.75rem",
                  color: "rgba(0, 0, 0, 0.54)",
                }}
              >
                {formattedDate}
              </Typography>
              <Box sx={{ display: "flex" }}>
                <HomeIcon sx={{ fontSize: "20px", color: "#333" }} />
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "0.85rem",
                    textAlign: "left",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  {params.value}
                </Typography>
              </Box>
              <Box sx={{ display: "flex" }}>
                <PersonIcon sx={{ fontSize: "20px", color: "#333" }} />
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "0.85rem",
                    textAlign: "left",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  {params?.row?.ownersName}
                </Typography>
              </Box>

              <Typography
                sx={{
                  textAlign: "left",
                  fontSize: "0.75rem",
                  color: "rgba(0, 0, 0, 0.54)",
                }}
              >
                {params.row?.county}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  mt: -3,
                }}
              >
                <Box>
                  {downloadingRowId === params.row.id ? (
                    <CachedIcon
                      className="notary-rotate"
                      sx={{ color: "#004976", mt: 1 }}
                      width={25}
                    />
                  ) : (
                    <IconButton
                      onClick={() => handleDownloadNoc(params?.row)}
                      variant="contained"
                      color="primary"
                    >
                      <DocumentArrowDownIcon width={21} />
                    </IconButton>
                  )}
                </Box>
                <Box>
                  <IconButton
                    variant="contained"
                    color="warning"
                    onClick={() => handleOpenModal(params?.row)}
                  >
                    <VisibilityIcon width={25} />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          </>
        );
      },
    },
  ];

  const createdFormColumns = [
    {
      field: "id",
      headerName: "ID",
      width: 100,
      renderCell: (params) => {
        // Extract last 5 characters from the ID field
        const lastFiveChars = params.value.slice(-6);
        return <span>{lastFiveChars}</span>;
      },
    },
    {
      field: "date",
      headerName: "Date",
      width: 100,
      editable: false,
    },
    {
      field: "ownersName",
      headerName: "Owner's Name",
      flex: 1,
      editable: false,
    },
    {
      field: "address",
      headerName: "Address",
      flex: 2,
      editable: false,
    },
    {
      field: "county",
      headerName: "County",
      width: 100,
      editable: false,
    },
    {
      field: "download",
      headerName: "",
      // type: "actions",
      width: 50,
      renderCell: (params) => {
        const isLoading = downloadingRowId === params.row.id;
        if (isLoading === true) {
          return (
            <div>
              <CachedIcon
                className="notary-rotate"
                sx={{ color: "#004976", ml: 1 }}
                width={25}
              />
            </div>
          );
        } else {
          return (
            <div>
              <Tooltip title="Download PDF" arrow>
                <IconButton
                  onClick={() => handleDownloadNoc(params?.row)}
                  variant="contained"
                  color="primary"
                >
                  <DocumentArrowDownIcon width={25} />
                </IconButton>
              </Tooltip>
            </div>
          );
        }
      },
    },
    {
      field: "edit",
      headerName: "",
      // type: "actions",
      width: 50,
      renderCell: (params) => {
        return (
          <div>
            <Tooltip title="View Form" arrow>
              <IconButton
                variant="contained"
                color="warning"
                onClick={() => handleOpenModal(params?.row)}
              >
                <VisibilityIcon width={25} />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
  ];
  return (
    <>
      <Card
        sx={{
          position: "relative",
          backgroundColor: props?.tabletSize ? "transparent" : undefined,
        }}
      >
        {!props?.tabletSize && <CardHeader title={props?.title} />}

        <CardContent
          sx={{
            p: 0,
            px: props?.tabletSize ? 0 : 1,
            mt: props?.tabletSize ? "1rem" : undefined,
          }}
        >
          <DataGrid
            sx={{
              "& .MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                outline: "none !important",
              },
              "& .MuiDataGrid-columnHeader:focus-within": {
                outline: "none !important",
              },
              "& .MuiDataGrid-cell": {
                borderColor: props?.tabletSize
                  ? "rgba(0, 0, 0, 0.1)"
                  : undefined, // Change cell border color
                p: props?.tabletSize ? 0 : undefined,
              },
              "& .MuiDataGrid-columnHeader": props?.tabletSize
                ? {
                    backgroundColor: "#333333", // Change column header background color
                    color: "white",
                    fontSize: "15px",
                    paddingLeft: 1,
                    paddingRight: 2,
                    margin: 0,
                    borderTopRightRadius: 0,
                    borderTopLeftRadius: 0,
                  }
                : {},
              "& .MuiDataGrid-columnHeaders": {
                padding: 0,
                margin: 0,
                // borderTopRightRadius: 0,
                // borderTopLeftRadius: 0,
              },
              "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                outline: "none !important",
              },
              backgroundColor: props?.tabletSize ? "#fff" : undefined,
            }}
            rows={props?.createdFormRow}
            columns={
              props?.tabletSize ? createdFormColumnsSmall : createdFormColumns
            }
            getRowId={(row) => row?.id}
            rowHeight={props?.tabletSize ? 105 : undefined}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5, 10, 25, 50, 100]}
            disableRowSelectionOnClick
            disableColumnFilter={props?.tabletSize}
          />
        </CardContent>
      </Card>
      <MaximisePDF
        open={formModalOpen}
        setOpen={setFormModalOpen}
        fullScreen={props?.tabletSize}
        imgs={selectedRow?.signed}
      />
    </>
  );
};

export default SubUserCreatedForms;
