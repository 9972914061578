import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FunFact } from "../noc/FunFact";
import axios from "axios";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import LottieAnimation from "../../animation/LottieAnimation";

const AcceptInstant = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const notary = useSelector((state) => state.notarySession);
  const location = useLocation();
  const theme = useTheme();
  const queryParams = new URLSearchParams(location.search);

  const notaryIDQuery = queryParams.get("ni");
  const instantTokenQuery = queryParams.get("it");
  const taskIdQuery = queryParams.get("ti");
  const signeeTokenQuery = queryParams.get("st");
  const reasonQuery = queryParams.get("reason");

  const mobileSize = useMediaQuery(theme.breakpoints.down("sm"));
  const tabletSize = useMediaQuery(theme.breakpoints.down("md"));
  const [pageLoading, setPageLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(null);
  const [errorMessage, setErrorMessage] = useState("Please Wait");
  const [errorType, setErrorType] = useState("waiting");
  const [loading, setLoading] = useState(true);
  const [startChecking, setStartChecking] = useState(false);
  const [funData, setFunData] = useState([]);
  const [appointment, setAppointment] = useState(null);
  const [session_url, setSession_url] = useState(null);

  let count = 0;
  let funCount = 0;

  useEffect(() => {
    if (isSuccess === true) {
      const timert = setTimeout(() => {
        navigate("/login");
      }, 120000);

      return () => clearTimeout(timert);
    }
  }, [isSuccess]);

  const handleCheckFinalize = async () => {
    const resp = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/nocApi/notary/check-finalize-state`,
      {
        instant_token: instantTokenQuery,
        notary_id: notaryIDQuery,
        task_id: taskIdQuery,
        reason: reasonQuery,
      },
      {
        withCredentials: true,
      }
    );

    if (resp?.data?.type === "accepted") {
      setErrorMessage(resp?.data?.message);
      setErrorType(resp?.data?.type);
      setIsSuccess(true);
      setStartChecking(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    const getFunFacts = async () => {
      const resp = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/nocApi/data/funFacts`
      );

      setFunData(resp.data.data.funFacts);
    };
    if (funCount < 1) {
      getFunFacts();
      funCount += 1;
    }
  }, []);

  useEffect(() => {
    if (
      !instantTokenQuery ||
      !notaryIDQuery ||
      !taskIdQuery ||
      !signeeTokenQuery ||
      !reasonQuery
    ) {
      navigate("/");
      return;
    }

    const validate = async () => {
      try {
        const resp = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/notary/check-finalize-state`,
          {
            instant_token: instantTokenQuery,
            notary_id: notaryIDQuery,
            task_id: taskIdQuery,
            reason: reasonQuery,
          },
          {
            withCredentials: true,
          }
        );

        if (resp?.data?.type === "process") {
          const tokenResponse = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/nocApi/notary/accept-instant`,
            {
              instant_token: instantTokenQuery,
              notary_id: notaryIDQuery,
              task_id: taskIdQuery,
              signee_token: signeeTokenQuery,
              reason: reasonQuery,
            },
            {
              withCredentials: true,
            }
          );

          if (tokenResponse.data.success === true) {
            setIsSuccess(true);
            setLoading(false);
            setErrorMessage(tokenResponse?.data?.message);
            setErrorType(tokenResponse?.data?.type);
            setStartChecking(false);
            setAppointment(tokenResponse?.data?.appointment);
            setSession_url(tokenResponse?.data?.session_url);
          } else {
            setErrorMessage(tokenResponse?.data?.message);
            setErrorType(tokenResponse?.data?.type);
            setStartChecking(false);
            setIsSuccess(false);
            setLoading(false);
          }
        } else {
          setErrorMessage(resp?.data?.message);
          setErrorType(resp?.data?.type);

          if (resp?.data?.type === "waiting") {
            setStartChecking(true);
          } else {
            setStartChecking(false);
            setLoading(false);
            setIsSuccess(true);
          }
        }
        setPageLoading(false);
      } catch (error) {
        setErrorMessage(error?.response?.data?.message);
        setErrorType(error?.response?.data?.type);
        if (error?.response?.data?.type === "unauthorized") {
          navigate("/login");
        }
        setPageLoading(false);
        setLoading(false);
        setStartChecking(false);
      }
    };

    if (count < 1) {
      validate();
    }
    count += 1;
  }, []);
  useEffect(() => {
    if (startChecking) {
      const interval = setInterval(() => {
        handleCheckFinalize();
      }, 10000);

      return () => clearInterval(interval);
    }
  }, [startChecking]);

  return (
    <>
      {pageLoading ? (
        <>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100vh",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {tabletSize ? (
              <>
                <Box sx={{ mb: 2 }}>
                  <img
                    style={{
                      width: "10rem",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                    alt="Permit Rockstar Logo"
                    src="/img/website_img/PermitRockstar.png"
                  />
                </Box>
                <LottieAnimation />
              </>
            ) : (
              <CircularProgress color="warning" size="10rem" />
            )}
          </Box>
        </>
      ) : (
        <>
          <Box
            sx={
              mobileSize
                ? {
                    justifyContent: "center",
                    overflow: "hidden",
                    width: "100%",
                    height: "100vh",
                  }
                : {
                    backgroundImage: "url('/img/website_img/homeImg.jpeg')",
                    backgroundRepeat: "repeat",
                    width: "100%",
                    flex: "1 1 auto",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                    height: "100vh",
                  }
            }
          >
            <Box
              sx={
                tabletSize
                  ? {
                      display: "flex",
                      alignItems: "center",
                      flex: "1 1 auto",
                      justifyContent: "center",
                      backgroundColor: "#fff",
                      py: "10px",
                      width: "100%",
                    }
                  : {
                      backgroundColor: "#fff",
                      maxWidth: "60%",
                      maxHeight: "90%",
                      // minHeight: "80%",
                      borderRadius: "25px",
                      px: 3,
                      py: "10px",
                      width: "100%",
                      pt: "2rem",
                    }
              }
            >
              <div
                style={
                  tabletSize
                    ? { width: "100%", padding: "1rem", position: "relative" }
                    : { paddingBottom: "1rem", position: "relative" }
                }
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <img
                    style={{
                      maxWidth: mobileSize
                        ? "90%"
                        : tabletSize
                        ? "50%"
                        : "10rem",
                      height: "auto",
                    }}
                    alt="Permit Rockstar Logo"
                    src="/img/website_img/PermitRockstar.png"
                  />
                </Box>
                {isSuccess && isSuccess === true ? (
                  <>
                    <Box>
                      <Typography
                        color="#4CAF50"
                        variant="h5"
                        sx={{ mb: 2, mt: 1 }}
                        align="center"
                      >
                        Documents have been validated and the appointment for
                        the RON has been confirmed.
                      </Typography>

                      <Typography
                        color="primary"
                        variant="h5"
                        fontWeight="bold"
                        align="center"
                        sx={{ mb: 1 }}
                      >
                        Session Starts at:
                      </Typography>
                      <Typography
                        color="primary"
                        variant="h4"
                        fontWeight="bold"
                        align="center"
                        sx={{ mb: 3 }}
                      >
                        {appointment}
                      </Typography>
                      {/* <Typography
                        color="primary"
                        variant="body1"
                        fontWeight="bold"
                        align="center"
                        sx={{ mb: 3 }}
                      >
                        Please check your email for further instructions.
                      </Typography> */}
                      <Grid container justifyContent="center">
                        <Grid item xs={12}>
                          <Button
                            size="large"
                            onClick={() => {
                              window.open(session_url, "_blank");
                              navigate("/login");
                            }}
                            fullWidth
                            variant="contained"
                          >
                            Join Session
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </>
                ) : errorType === "waiting" ? (
                  <>
                    <Box>
                      <Typography
                        color="primary"
                        variant="h5"
                        sx={{ mb: 2, mt: 1 }}
                        align="center"
                      >
                        Accepting Remote Online Notarization (RON) Appointment!
                      </Typography>
                      <Typography
                        color="primary"
                        variant="h5"
                        sx={{ mb: 2, mt: 1 }}
                        align="center"
                      >
                        {errorMessage}
                      </Typography>
                      <Typography
                        color="primary"
                        variant="body1"
                        align="center"
                        sx={{ mb: 3 }}
                      >
                        We are ensuring the document/s can be notarized to
                        ensure a smooth experience. Once we validate the
                        documents, you will be assigned the job and can start
                        the session.
                      </Typography>
                      <Typography
                        color="primary"
                        variant="body1"
                        align="center"
                        sx={{ mb: 3, fontWeight: "bold" }}
                      >
                        In the meantime, did you know...
                      </Typography>
                      <FunFact type="accept" data={funData} />
                      <Grid container justifyContent="center" mt={3}>
                        <Grid item xs={12}>
                          <LoadingButton
                            loading={true}
                            fullWidth
                            variant="contained"
                          >
                            Please Wait
                          </LoadingButton>
                        </Grid>
                      </Grid>
                    </Box>
                  </>
                ) : (
                  <>
                    <Box>
                      <Typography
                        color="error"
                        variant="h5"
                        sx={{ mb: 2, mt: 1 }}
                        align="center"
                      >
                        Remote Online Notarization (RON) Appointment is
                        Unavailable!
                      </Typography>
                      <Typography
                        color="primary"
                        variant="h5"
                        sx={{ mb: 2, mt: 1 }}
                        align="center"
                      >
                        {errorMessage}
                      </Typography>
                      <Typography
                        color="primary"
                        variant="body1"
                        align="center"
                        sx={{ mb: 3 }}
                      >
                        {`We regret to inform you ${
                          errorType === "taken"
                            ? "that the Remote Online Notarization (RON) appointment you selected has already been accepted by you or another notary."
                            : errorType === "not_found" &&
                              errorMessage ==
                                "You can not accept this job this time. The RON session is expired or does not exist"
                            ? "that the RON session you trying to accept is expired or does not exist."
                            : errorType === "not_found"
                            ? "that we were unable to find and validate your notary account."
                            : errorMessage ===
                              "You can not accept this job this time. Failed to start RON session"
                            ? "that we encountered an issue during the process of processing and initiating the RON session for the signee."
                            : errorMessage ===
                              "You can not accept this job this time. Job has been cancelled or doesn't exist"
                            ? "that the RON session has been cancelled or doesn't exist."
                            : "that you need to authenticate before you can accept any notarization jobs."
                        } We appreciate your prompt response and encourage you to log into your
                        dashboard for other available notarization
                        opportunities.`}
                      </Typography>
                      <Grid container justifyContent="center">
                        <Grid item xs={12}>
                          <Button
                            onClick={() => {
                              navigate("/");
                            }}
                            fullWidth
                            variant="contained"
                          >
                            BACK TO MAIN MENU
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </>
                )}
              </div>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default AcceptInstant;
