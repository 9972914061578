import React, { useCallback, useState, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import * as pdfjsLib from "pdfjs-dist/webpack";

const FileUploadBox = ({
  formik,
  uploadError,
  setUploadError,
  mobileSize,
  tabletSize,
  setMobileUploaderOpen,
}) => {
  const [dragOver, setDragOver] = useState(false);

  const handleDragOver = (event) => {
    event.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    setDragOver(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setDragOver(false);
    const newFiles = Array.from(event.dataTransfer.files);
    const file = newFiles[0]; // Assuming single file upload
    handleFileValidation(file);
  };

  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files);
    const file = newFiles[0]; // Assuming single file upload
    handleFileValidation(file);
  };

  const handleFileValidation = (file) => {
    if (file) {
      if (file.type === "application/pdf" && file.size <= 2 * 1024 * 1024) {
        const fileReader = new FileReader();

        fileReader.onload = function (e) {
          const typedArray = new Uint8Array(e.target.result);

          pdfjsLib
            .getDocument({ data: typedArray })
            .promise.then(() => {
              // PDF loaded successfully, it's not password protected
              const reader = new FileReader();
              reader.onloadend = function () {
                // This result contains the PDF file in base64 format
                const base64String = reader.result;

                // Set the PDF's base64 string to Formik or state
                formik.setFieldValue("file", base64String);
                formik.setFieldValue("files", [file]);
                formik.setFieldValue("fileName", file.name);
                if (tabletSize) {
                  setMobileUploaderOpen(true);
                }
              };
              reader.readAsDataURL(file); // Convert file to base64 string
            })
            .catch((error) => {
              if (error.name === "PasswordException") {
                // Handle the encrypted PDF case here
                formik.resetForm();
                setUploadError("The PDF is encrypted and requires a password.");
              } else {
                // Handle other PDF loading errors here
                console.error("Error loading PDF:", error);
                setUploadError("An error occurred while loading the PDF.");
              }
            });
        };
        fileReader.readAsArrayBuffer(file);
      } else {
        formik.resetForm();
        setUploadError("Only PDF files less than 2MB are allowed.");
      }
    }
  };

  const openFileDialog = () => {
    document.getElementById("fileInput").click();
  };

  useEffect(() => {
    if (formik?.values.files.length > 0) {
      const file = formik?.values.files[0];
      if (file.type === "application/pdf" && file.size <= 2 * 1024 * 1024) {
        // Check if the file needs to be converted to base64 (drag and drop scenario)
        if (!formik?.values.file) {
          const reader = new FileReader();
          reader.onloadend = () => {
            // Set the PDF's base64 string to Formik or state
            const base64String = reader.result;
            formik.setFieldValue("file", base64String);
            formik.setFieldValue("fileName", file.name);
            // Generate the preview from the base64 string
            generatePreview(base64String);
          };
          reader.readAsDataURL(file); // Convert file to base64 string
        } else {
          // File was added via selection, generate preview directly
          generatePreview(formik?.values.file);
        }
      } else {
        formik.resetForm();
        setUploadError("Only PDF files and less than 2MB are allowed.");
      }
    }
  }, [formik?.values.files]);

  // Adjusted Function to include encryption check
  const generatePreview = (base64String) => {
    // Convert base64 to binary for PDF.js
    const binaryString = window.atob(base64String.split(",")[1]);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    // Use PDF.js to check for encryption and generate the preview
    pdfjsLib
      .getDocument({ data: bytes })
      .promise.then((pdf) => {
        // PDF is not encrypted, proceed to generate preview
        pdf.getPage(1).then((page) => {
          const viewport = page.getViewport({ scale: 1.5 });
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          canvas.height = viewport.height;
          canvas.width = viewport.width;

          page
            .render({ canvasContext: ctx, viewport: viewport })
            .promise.then(() => {
              formik.setFieldValue("previewUrl", canvas.toDataURL("image/png"));
            });
        });
      })
      .catch((error) => {
        // Check if the error is due to the PDF being encrypted
        if (error.name === "PasswordException" || error.code === 2) {
          formik.resetForm();
          setUploadError("The PDF is encrypted and requires a password.");
        } else {
          console.error("Error loading PDF:", error);
          setUploadError("An error occurred while loading the PDF.");
        }

        formik.resetForm();
      });
  };

  return (
    <Box
      sx={{
        height: tabletSize ? "70%" : "80%",
        border: "2px dashed gray",
        padding: 2,
        backgroundColor: dragOver ? "#e0e0e0" : "#fafafa",
        textAlign: "center",
        cursor: "pointer",
        borderRadius: tabletSize ? "10px" : undefined,
        "&:hover": {
          backgroundColor: "#e0e0e0",
        },
      }}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      onClick={openFileDialog}
    >
      <input
        type="file"
        id="fileInput"
        multiple
        onChange={handleFileChange}
        accept="application/pdf" // If you're accepting only PDFs
        style={{ display: "none" }}
      />
      <>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <>
            <div>
              <UploadFileIcon
                sx={{ fontSize: tabletSize ? 80 : 120, color: "gray" }}
              />
              <Typography variant={tabletSize ? "h6" : "h5"} sx={{ mb: 3 }}>
                Drag and drop files here, or click to select files.
              </Typography>
              <Typography
                color="error"
                variant="body2"
                sx={{ mb: 3, fontWeight: "bold" }}
              >
                {uploadError}
              </Typography>
            </div>
          </>
        </Box>
      </>
    </Box>
  );
};

export default FileUploadBox;
