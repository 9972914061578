import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  IconButton,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

function ConfirmModal({
  open,
  setOpen,
  handleClose,
  title,
  subTitle,
  btnText1,
  btnText2,
  handleStartCreate,
  type,
}) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title" sx={{ mr: 1 }}>
          {title}
          <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{subTitle}</DialogContentText>
          <Box
            sx={{
              gap: 2,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 2,
            }}
          >
            <Button
              onClick={type === "create" ? () => setOpen(false) : handleClose}
              color="error"
              fullWidth
              variant="contained"
            >
              {btnText1}
            </Button>
            <Button
              color="success"
              onClick={
                type === "create" ? handleStartCreate : () => setOpen(false)
              }
              fullWidth
              variant="contained"
            >
              {btnText2}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ConfirmModal;
