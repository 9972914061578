import { Alert } from "@mui/material";

const SigneeRegisterInfo = ({ isFadingOut, infoText, top = 55 }) => {
  return (
    <>
      <Alert
        sx={{
          zIndex: 333,
          position: "absolute",
          right: 0,
          left: 0,
          top: top,
          opacity: isFadingOut ? 0 : 1,
          transition: "opacity 1000ms ease-in-out",
          color: "#fff",
          fontWeight: "bold",
        }}
        variant="filled"
        severity="info"
      >
        {infoText}
      </Alert>
    </>
  );
};

export default SigneeRegisterInfo;
