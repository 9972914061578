import InputField from "../../inputComponents/InputField";
import AutoField from "../../inputComponents/AutoField";
import { Grid } from "@mui/material";

const PersonalDetails = ({
  isEmailError,
  isPhoneError,
  setIsPhoneError,
  setPersonalAddress,
  options,
  setOptions,
}) => {
  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{
          mt: "1rem",
          minHeight: "64.8vh",
          overflowY: "auto",
          paddingRight: "2px",
          pb: "2px",
        }}
      >
        <Grid item xs={12} md={6}>
          <InputField name="firstName" label="First Name*" />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputField name="lastName" label="Last Name*" />
        </Grid>
        <Grid item xs={12} md={6}>
          <AutoField
            name="address"
            label="Address*"
            setAddress={setPersonalAddress}
            options={options}
            setOptions={setOptions}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <InputField
            name="email"
            label="Email Address*"
            isEmailError={isEmailError}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputField type="password" name="password" label="Password*" />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputField
            type="password"
            name="confirmPassword"
            label="Confirm Password*"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputField
            name="phone"
            label="Phone Number*"
            isPhoneError={isPhoneError}
            setIsPhoneError={setIsPhoneError}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default PersonalDetails;
