import React, { useEffect, useRef } from "react";
import {
  Route,
  Routes,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  validateSession,
  resetSession,
  validateRole,
  logoutHandler,
} from "./store/actions/sessionsActions";
import { setExpiredSession } from "./store/actions/sessionsActions";
import { withSessionCheck } from "./components/hoc/withSessionCheck";
import {
  connectWebsocket,
  disconnectWebsocket,
} from "./store/actions/websocketActions";
import { DateTime } from "luxon";
import SSORedirect from "./components/auth/SSORedirect";
import Home from "./components/pages/Home";
import Subscription from "./components/pages/Subscription";
import SigneeLogin from "./components/signee/SigneeLogin";
import Login from "./components/pages/Login";
import AcceptInstant from "./components/notary/AcceptInstant";
import SuccessChangePlan from "./components/SuccessChangePlan";
import SuccessCreateExpiredSubscription from "./components/SuccessCreateExpiredSubscription";
import Register from "./components/pages/Register";
import Dashboard from "./components/pages/Dashboard";
import CreateNoc from "./components/pages/CreateNoc";
import Payment from "./components/pages/Payment";
import SelectCounties from "./components/pages/SelectCounties";
import Presets from "./components/pages/Presets";
import Contractors from "./components/pages/Contractors";
import ProfileSettings from "./components/pages/ProfileSettings";
import CustomerService from "./components/pages/CustomerService";
import Overview from "./components/pages/admin/Overview";
import Management from "./components/pages/admin/Management";
import Messages from "./components/pages/admin/Messages";
import Licenses from "./components/pages/admin/Licenses";
import Tasks from "./components/pages/admin/Tasks";
import { Layout } from "./components/layout/Layout";
import ResetPassword from "./components/pages/ResetPassword";
import { ClerkProvider } from "@clerk/clerk-react";
import Customers from "./components/pages/admin/Customers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DynamicAuth from "./components/pages/DynamicAuth";
import SubuserFinalize from "./components/pages/SubuserFinalize";
import "./styles/globals.css";
import TotpAuth from "./components/pages/TotpAuth";
import EmailFaAuth from "./components/pages/EmailFaAuth";
import EmailFaAuthPass from "./components/pages/EmailFaAuthPass";
import SmsFaAuth from "./components/pages/SmsFaAuth";

import NotaryRegister from "./components/notary/NotaryRegister";
import RegisterSignee from "./components/notary/signee/RegisterSignee";
import RegisterRecordSignee from "./components/notary/signee/RegisterRecordSignee";

import ManageSigneeAppointment from "./components/signee/ManageSigneeAppointment";

// import EmbeddedSigningComponent from "./components/securedSigning/EmbeddedSigningComponent";

import TotpResetAuth from "./components/pages/TotpResetAuth";
import EmailResetFaAuth from "./components/pages/EmailResetFaAuth";
import EmailFaResetAuthPass from "./components/pages/EmailFaResetAuthPass";
import SmsFaResetAuth from "./components/pages/SmsFaResetAuth";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { Box, CircularProgress } from "@mui/material";
import Subusers from "./components/pages/Subusers";
import Notaries from "./components/pages/admin/Notaries";
import NotaryDashboard from "./components/notary/NotaryDashboard";
import NotaryJobManagement from "./components/notary/NotaryJobManagement";
import FinishNotary from "./components/notary/FinishNotary";
import NotarySettings from "./components/notary/NotarySettings";
import MyPermits from "./components/pages/permits/MyPermits";
import PermitInvitations from "./components/pages/permits/PermitInvitations";
import Uploader from "./components/pages/Uploader";
import NotFound from "./components/pages/NotFound";
import { setCurrentSession } from "./store/actions/sessionsActions";

import {
  checkRoleValidationNeeded,
  getSessionNameByPath,
  isPathProtected,
} from "./functions/helperFunctions";
import NotaryCustomerService from "./components/pages/NotaryCustomerService";
import BuildingPermit from "./components/pages/BuildingPermit";
import UserRonCompleted from "./components/pages/ron/UserRonCompleted";
import NotaryRonCompleted from "./components/pages/ron/NotaryRonCompleted";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE);

const App = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const sessions = useSelector((state) => state.sessions);
  const currentRole = useSelector((state) => state?.sessions?.currentRole);

  useEffect(() => {
    if (process.env.REACT_APP_PROCESS_TYPE === "PRODUCTION") {
      // Disable right-click context menu
      const disableContextMenu = (e) => {
        e.preventDefault();
      };
      window.addEventListener("contextmenu", disableContextMenu);

      // Detect if Developer Tools is open
      const detectDevTools = (e) => {
        if (e.ctrlKey && e.shiftKey && (e.key === "I" || e.key === "i")) {
          // alert("Developer Tools is disabled!");
          e.preventDefault();
        }
      };
      window.addEventListener("keydown", detectDevTools);

      // Clean up event listeners
      return () => {
        window.removeEventListener("contextmenu", disableContextMenu);
        window.removeEventListener("keydown", detectDevTools);
      };
    }
  }, []);

  useEffect(() => {
    const roleHandler = setTimeout(() => {
      const handleRoleValidation = async () => {
        const validatedRole = await dispatch(validateRole());

        if (validatedRole === false) {
          navigate("/login");
        }
      };

      const needRoleValidation = checkRoleValidationNeeded(location.pathname);

      if (needRoleValidation && !currentRole) {
        handleRoleValidation();
      }
    }, 500);

    return () => {
      clearTimeout(roleHandler);
    };
  }, [location.pathname]);

  const exitHandler = async (sessionName) => {
    sessionStorage.removeItem("__nocCreator__r_rid");
    sessionStorage.removeItem("exp");
    dispatch(setExpiredSession(true));
    await dispatch(disconnectWebsocket(sessionName));
    navigate("/login");
  };

  // const exitHandler = async (sessionName) => {
  //   navigate("/login");
  //   switch (true) {
  //     case sessionName.includes("admin"):
  //       await dispatch(disconnectWebsocket("userSession"));
  //       break;
  //     case sessionName.includes("user"):
  //       await dispatch(disconnectWebsocket("userSession"));
  //       break;
  //     case sessionName.includes("guest"):
  //       await dispatch(disconnectWebsocket("guestSession"));
  //       break;
  //     case sessionName.includes("notary"):
  //       await dispatch(disconnectWebsocket("notarySession"));
  //       break;
  //     case sessionName.includes("subUser"):
  //       await dispatch(disconnectWebsocket("subUserSession"));
  //       break;
  //     default:
  //       navigate("/login");
  //       break;
  //   }
  //   sessionStorage.removeItem("__nocCreator__r_rid");
  //   sessionStorage.removeItem("exp");
  //   setTimeout(() => {
  //     dispatch(setExpiredSession(true));
  //   }, 100);
  // };
  // const testTime = Math.floor(Date.now() / 1000);

  // useEffect(() => {
  //   const checkSessionExpired = setInterval(async () => {
  //     const sessionDetails = getSessionNameByPath(
  //       location.pathname,
  //       currentRole
  //     );
  //     if (sessionDetails) {
  //       const timeNow = Math.floor(new Date().getTime() / 1000);
  //       const { sessionName } = sessionDetails;
  //       const expiry = sessions[sessionName]?.expiry;
  //       if (expiry && timeNow > expiry) {
  //         await exitHandler(sessionName);
  //       }
  //     }
  //   }, 5000); // Check every 5 seconds

  //   // Cleanup interval on component unmount
  //   return () => clearInterval(checkSessionExpired);
  // }, [location.pathname, sessions, currentRole]);

  useEffect(() => {
    const checkSessionExpired = setInterval(async () => {
      // Get the current time in UTC (in seconds)
      const timeNow = Math.floor(DateTime.utc().toSeconds());

      // Retrieve sessionName and expiry from sessionStorage
      const sessionName = sessionStorage.getItem("__nocCreator__r_rid");
      const expiry = sessionStorage.getItem("exp");

      // Check if expiry exists and if the current time has passed the expiry
      if (expiry && timeNow > expiry) {
        await exitHandler(sessionName); // Call your exit handler if the session has expired
      }
    }, 5000); // Check every 5 seconds

    // Cleanup interval on component unmount
    return () => clearInterval(checkSessionExpired);
  }, []);

  useEffect(() => {
    const handler = setTimeout(() => {
      const checkSession = async () => {
        // Determine session details based on the current path
        const sessionDetails = getSessionNameByPath(
          location.pathname,
          currentRole
        );
        //! sessionDetails => MEAN IT'S HIT A PROTECTED ROUTE!!!!
        if (sessionDetails) {
          const { sessionName, requiredCookie, needSocket } = sessionDetails;
          const sessionToken = sessions[sessionName]?.token;
          const validated = sessions[sessionName]?.isValidated;
          const wsConnected = sessions[sessionName]?.wsConnected;

          if (
            !sessions?.currentSession &&
            validated &&
            sessionToken &&
            wsConnected
          ) {
            dispatch(setCurrentSession(sessionName));
          } else if (!sessionToken || !validated) {
            const validated = await dispatch(
              validateSession(sessionName, requiredCookie, needSocket)
            );
            // console.log("VALIDATED: ", validated);
            if (validated === false) {
              navigate("/login");
            }
          } else if (sessionToken && validated && !wsConnected) {
            dispatch(connectWebsocket(sessionName, sessionToken));
          }
        } else {
          if (sessions?.currentSession) {
            dispatch(disconnectWebsocket(sessions?.currentSession));
            dispatch(resetSession(sessions?.currentSession));
          } else if (
            sessions?.currentRole &&
            sessions?.currentRole?.length > 0 &&
            isPathProtected(location.pathname)
          ) {
            navigate(-1);
          }
        }
      };

      checkSession();
    }, 1300);
    //     if (sessionDetails) {
    //       const { sessionName, requiredCookie, needSocket } = sessionDetails;
    //       const sessionToken = sessions[sessionName]?.token;
    //       const validated = sessions[sessionName]?.isValidated;
    //       const wsConnected = sessions[sessionName]?.wsConnected;

    //       if (
    //         !sessions?.currentSession &&
    //         validated &&
    //         sessionToken &&
    //         wsConnected
    //       ) {
    //         dispatch(setCurrentSession(sessionName));
    //       } else if (validated === "invalid") {
    //         //? After validation, invalid user/token/session
    //         dispatch(disconnectWebsocket(sessionName));
    //         dispatch(resetSession(sessionName));
    //         navigate("/login");
    //         //? //////////////////////////////////////////////////////////////
    //       } else if (!sessionToken || !validated) {
    //         dispatch(validateSession(sessionName, requiredCookie, needSocket));
    //         //? //////////////////////////////////////////////////////////////
    //       } else if (sessionToken && validated && !wsConnected) {
    //         dispatch(connectWebsocket(sessionName, sessionToken));
    //       }
    //       //! OUTSIDE PROTECTED ROUTES ////////////////////////
    //     } else {
    //       if (sessions?.currentSession) {
    //         dispatch(disconnectWebsocket(sessions?.currentSession));
    //         dispatch(resetSession(sessions?.currentSession));
    //       } else if (
    //         sessions?.currentRole &&
    //         sessions?.currentRole?.length > 0 &&
    //         isPathProtected(location.pathname)
    //       ) {
    //         navigate(-1);
    //       }
    //     }
    //   };

    //   checkSession();
    // }, 1300);

    return () => {
      clearTimeout(handler);
    };
  }, [location.pathname, sessions, currentRole]);

  const DashboardComponent = withSessionCheck(Dashboard, [
    "userSession",
    "subUserSession",
  ]);
  const CreateNocComponent = withSessionCheck(CreateNoc, [
    "userSession",
    "subUserSession",
  ]);
  const BuildingPermitComponent = withSessionCheck(BuildingPermit, [
    "userSession",
    "subUserSession",
  ]);
  const SelectCountiesComponent = withSessionCheck(SelectCounties, [
    "userSession",
    "subUserSession",
  ]);
  const PresetsComponent = withSessionCheck(Presets, [
    "userSession",
    "subUserSession",
  ]);
  const MyPermitsComponent = withSessionCheck(MyPermits, [
    "userSession",
    "subUserSession",
  ]);
  const PermitInvitationsComponent = withSessionCheck(PermitInvitations, [
    "userSession",
    "subUserSession",
  ]);
  const UploaderComponent = withSessionCheck(Uploader, [
    "userSession",
    "subUserSession",
  ]);
  const ProfileSettingsComponent = withSessionCheck(ProfileSettings, [
    "userSession",
    "subUserSession",
  ]);
  const ContractorsComponent = withSessionCheck(Contractors, ["userSession"]);
  const PaymentComponent = withSessionCheck(
    Payment,
    ["userSession", "subUserSession"],
    Elements,
    {
      stripe: stripePromise,
    }
  );
  const SubusersComponent = withSessionCheck(Subusers, ["userSession"]);
  const SubscriptionComponent = withSessionCheck(Subscription, [
    "userSession",
    "subUserSession",
  ]);
  const CustomerServiceComponent = withSessionCheck(CustomerService, [
    "userSession",
    "subUserSession",
  ]);
  //! ADMIN ONLY ROUTES ///////////////////////////////////////////////////////////////////////////
  const OverviewComponent = withSessionCheck(Overview, ["userSession"]);
  const CustomersComponent = withSessionCheck(Customers, ["userSession"]);
  const TasksComponent = withSessionCheck(Tasks, ["userSession"]);
  const LicensesComponent = withSessionCheck(Licenses, ["userSession"]);
  const ManagementComponent = withSessionCheck(Management, ["userSession"]);
  const MessagesComponent = withSessionCheck(Messages, ["userSession"]);
  const NotariesComponent = withSessionCheck(Notaries, ["userSession"]);
  //! ////////////////////////////////////////////////////////////////////////////////////////
  const NotaryDashboardComponent = withSessionCheck(NotaryDashboard, [
    "notarySession",
  ]);
  const NotaryCustomerServiceComponent = withSessionCheck(
    NotaryCustomerService,
    ["notarySession"]
  );
  // const NotaryQueueComponent = withSessionCheck(NotaryQueue, "notarySession");
  const NotaryJobManagementComponent = withSessionCheck(NotaryJobManagement, [
    "notarySession",
  ]);
  const NotarySettingsComponent = withSessionCheck(NotarySettings, [
    "notarySession",
  ]);
  const FinishNotaryComponent = withSessionCheck(FinishNotary, [
    "notarySession",
  ]);

  // ! SIGNEE ROUTES - GUEST SESSION /////////////////////////////////////////////////////////////////////
  const RegisterSigneeComponent = withSessionCheck(
    RegisterSignee,
    ["guestSession"],
    null,
    {},
    false
  );
  const RegisterRecordSigneeComponent = withSessionCheck(
    RegisterRecordSignee,
    ["guestSession"],
    null,
    {},
    false
  );
  const ManageSigneeAppointmentComponent = withSessionCheck(
    ManageSigneeAppointment,
    ["guestSession"],
    null,
    {},
    false
  );
  //! /////////////////////////////////////////////////////////////////////////////////////////////////////

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {/* <ClerkProvider
        publishableKey={clerk_pub_key}
        navigate={(to) => navigate(to)}
      > */}
      <Routes>
        <Route path="/" element={<Home />} />
        {/* userSession */}
        <Route path="/dashboard" element={<DashboardComponent />} />

        <Route path="/permits/create" element={<CreateNocComponent />} />
        <Route path="/building-permit" element={<BuildingPermitComponent />} />
        <Route path="/selectcounties" element={<SelectCountiesComponent />} />
        <Route path="/defaults" element={<PresetsComponent />} />
        <Route path="/permits/my-permits" element={<MyPermitsComponent />} />
        <Route
          path="/permits/invitations"
          element={<PermitInvitationsComponent />}
        />
        <Route path="/document-uploader" element={<UploaderComponent />} />
        <Route path="/profilesettings" element={<ProfileSettingsComponent />} />
        <Route path="/contractors" element={<ContractorsComponent />} />
        <Route path="/payment" element={<PaymentComponent />} />
        <Route path="/subusers" element={<SubusersComponent />} />
        <Route path="/subscription" element={<SubscriptionComponent />} />
        <Route path="/contact" element={<CustomerServiceComponent />} />
        {/* userSession -> ADMIN */}
        <Route path="/admin/notaries" element={<NotariesComponent />} />
        <Route path="/admin/overview" element={<OverviewComponent />} />
        <Route path="/admin/customers" element={<CustomersComponent />} />
        <Route path="/admin/tasks" element={<TasksComponent />} />
        <Route path="/admin/licenses" element={<LicensesComponent />} />
        <Route path="/admin/management" element={<ManagementComponent />} />
        <Route path="/admin/messages" element={<MessagesComponent />} />
        {/* notarySession */}
        <Route
          path="/notary/dashboard"
          element={<NotaryDashboardComponent />}
        />
        {/* <Route path="/notary/queue" element={<NotaryQueueComponent />} /> */}
        <Route
          path="/notary/history"
          element={<NotaryJobManagementComponent />}
        />
        <Route path="/notary/settings" element={<NotarySettingsComponent />} />
        <Route path="/notary/finalize" element={<FinishNotaryComponent />} />
        {/* ************************************************************************************ */}
        {/* guestSession */}
        <Route
          path="/notary/signee/register"
          element={<RegisterSigneeComponent />}
        />
        <Route
          path="/record/signee/register"
          element={<RegisterRecordSigneeComponent />}
        />
        <Route
          path="/signee/manage"
          element={<ManageSigneeAppointmentComponent />}
        />
        {/* ********************** */}
        {/* STRIPE */}
        <Route path="/changed-plan" element={<SuccessChangePlan />} />
        <Route
          path="/created-expired-plan"
          element={<SuccessCreateExpiredSubscription />}
        />
        {/* SUBUSER */}
        <Route path="/subuser/finalize" element={<SubuserFinalize />} />
        {/* 2FA */}
        <Route path="/2fa-secure" element={<DynamicAuth />} />
        <Route path="/2fa-secure/totp" element={<TotpAuth />} />
        <Route path="/2fa-secure/sms" element={<SmsFaAuth />} />
        <Route path="/2fa-secure/email" element={<EmailFaAuth />} />
        <Route path="2fa-secure/email/pass" element={<EmailFaAuthPass />} />
        {/* NOTARY */}
        <Route path="/notary/register" element={<NotaryRegister />} />
        <Route path="/notary/accept-instant" element={<AcceptInstant />} />
        <Route
          path="/notary/contact"
          element={<NotaryCustomerServiceComponent />}
        />
        <Route path="/2fa-secure/reset-pwd/totp" element={<TotpResetAuth />} />
        <Route path="/2fa-secure/reset-pwd/sms" element={<SmsFaResetAuth />} />
        <Route
          path="2fa-secure/reset-pwd/email"
          element={<EmailResetFaAuth />}
        />
        <Route
          path="2fa-secure/email/reset/pass"
          element={<EmailFaResetAuthPass />}
        />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signee/auth-login" element={<SigneeLogin />} />
        {/* <Route
          path="/sso_redirect_1saf32mf23mnk23f23"
          element={
            user?.isAuthenticated ? (
              <Navigate to="/dashboard" />
            ) : (
              <SSORedirect />
            )
          }
        /> */}
        {/* RON COMPLETION PAGES */}
        <Route path="/ron/notary/completed" element={<NotaryRonCompleted />} />
        <Route path="/ron/user/completed" element={<UserRonCompleted />} />

        <Route path="/resetpassword" element={<ResetPassword />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      {/* </ClerkProvider> */}
    </LocalizationProvider>
  );
};

export default App;
