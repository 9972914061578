import { Box, IconButton, Modal, Typography } from "@mui/material";
import { useState } from "react";
import SwipeableViews from "react-swipeable-views";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Magnifier from "../customComponents/Magnifier";
import CloseIcon from "@mui/icons-material/Close";

const ReviewCreatedFormsModal = ({ open, setOpen, selectedRow }) => {
  const [activeStep, setActiveStep] = useState(0);
  const totalImages = Object.values(selectedRow.documents).reduce(
    (acc, doc) => {
      return acc + (Array.isArray(doc.signed) ? doc.signed.length : 0);
    },
    0
  );

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
        setActiveStep(0);
      }}
      sx={{ overflow: "auto" }}
    >
      <Box
        sx={{
          overflowY: "auto",
          position: "relative",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          height: "95%",
          maxWidth: "70rem",
          bgcolor: "background.paper",
          borderRadius: "18px",
          boxShadow: 24,
          px: 4,
          py: 2,
          animation: "fadeIn 0.5s ease-in-out",
        }}
      >
        <Box sx={{ position: "relative" }}>
          <Box sx={{ position: "absolute", right: 10, top: 10, zIndex: 999 }}>
            <IconButton
              onClick={() => {
                setOpen(false);
                setActiveStep(0);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          <Box sx={{ cursor: "default" }}>
            <Box sx={{ width: "100%", overflow: "hidden" }}>
              <SwipeableViews
                axis={"x"}
                index={activeStep}
                onChangeIndex={(step) => setActiveStep(step)}
                // enableMouseEvents
              >
                {Object?.entries(selectedRow?.documents || {})?.map(
                  ([key, doc], docIndex) => {
                    const selectedDocument =
                      selectedRow.selected_documents?.find(
                        (item) => item.name === key // Assuming the objects in the array have a "key" property
                      );

                    const label = selectedDocument?.label;

                    return doc?.signed?.map((url, index) => (
                      <>
                        <Box
                          sx={{
                            width: "100%",

                            zIndex: 999,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="h5" color="primary">
                            {label} Form
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            width: "100%",

                            zIndex: 999,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="h5" color="primary">
                            Page {index + 1} of {doc?.signed?.length}
                          </Typography>
                        </Box>

                        <Box
                          key={`${docIndex}-${index}`}
                          sx={{
                            p: 2,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            maxHeight: "83vh",
                          }}
                        >
                          <Magnifier
                            src={url}
                            lensSize={800}
                            zoomFactor={3}
                            customStyle={{
                              maxWidth: "100%", // Restrict width to 100% of container
                              maxHeight: "82vh", // Restrict height to 75% of the viewport height
                              objectFit: "contain", // Ensure image scales properly
                              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                            }}
                          />
                          {/* <img
                          src={url}
                          alt={`Document ${docIndex + 1} Page ${index + 1}`}
                          style={{
                            maxWidth: "100%",
                            maxHeight: "90vh",
                            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                          }}
                        /> */}
                        </Box>
                      </>
                    ));
                  }
                )}
              </SwipeableViews>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography sx={{ fontWeight: "bold" }} color="primary">
                  Left click and hold over any part of the document to use a
                  magnifying glass to inspect the document
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: 0,
              display: activeStep === 0 ? "none" : undefined,
            }}
          >
            <IconButton
              onClick={() => {
                if (activeStep > 0) {
                  setActiveStep(activeStep - 1);
                }
              }}
            >
              <ArrowBackIcon sx={{ fontSize: "40px" }} />
            </IconButton>
          </Box>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              right: 0,
              display:
                totalImages - 1 === activeStep || totalImages === 0
                  ? "none"
                  : undefined,
            }}
          >
            <IconButton
              onClick={() => {
                if (totalImages - 1 !== activeStep) {
                  setActiveStep(activeStep + 1);
                }
              }}
            >
              <ArrowForwardIcon sx={{ fontSize: "40px" }} />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ReviewCreatedFormsModal;
