import React, { useEffect, useState } from "react";
import {
  AppBar,
  Dialog,
  Grow,
  IconButton,
  Toolbar,
  Typography,
  CircularProgress,
  Box,
  Collapse,
  Stack,
  Chip,
  Button,
  FormHelperText,
  TextField,
  useMediaQuery,
} from "@mui/material";
import {
  DocumentsHubWaiting,
  DocumentsHubCompleted,
  DocumentsHubFailed,
} from "../../../animation/DocumentsHubWaiting";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import HomeIcon from "@mui/icons-material/Home";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { DocumentArrowDownIcon } from "@heroicons/react/24/outline";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SwipeableViews from "react-swipeable-views";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {
  convertIsoToLocalFormat,
  convertTimezoneFromObj,
} from "../../../functions/helperFunctions";
import { LoadingButton } from "@mui/lab";

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Grow
      ref={ref}
      {...props}
      timeout={{
        enter: 500,
        exit: 300,
      }}
      style={{
        transformOrigin: "center",
        transitionDelay: props.in ? "0ms" : "200ms",
      }}
    />
  );
});

const MobileSealRecordModal = (props) => {
  const [open, setOpen] = useState(false);
  const [expanded, setExpanded] = useState({});
  const [addressExpanded, setAddressExpanded] = useState({});
  const iphone12Height = useMediaQuery("(min-height: 840px)");
  const iphone14Height = useMediaQuery("(min-height: 920px)");

  useEffect(() => {
    setExpanded({});
    setAddressExpanded({});
  }, [props?.rowIndex]);

  const handleToggle = (index) => {
    setExpanded((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const handleAddressToggle = (index) => {
    setAddressExpanded((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  function toTitleCase(str) {
    if (!str) return str;
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  return (
    <Dialog
      fullScreen={props?.tabletSize}
      open={props?.rowOpen}
      onClose={props?.handleClose}
      TransitionComponent={Transition}
    >
      <AppBar
        sx={{
          position: "relative",
          backgroundColor: props?.failedEnabled
            ? "#FFCCCC"
            : props?.internalEnabled
            ? "#333333"
            : undefined,
          color: props?.failedEnabled ? "red" : undefined,
          pl: props?.mobileSize ? 2 : undefined,
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color={"inherit"}
            onClick={props?.handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {props?.modalTitle}
          </Typography>
        </Toolbar>
      </AppBar>
      {props?.settingsEnabled && !props?.failedEnabled ? (
        (() => {
          const ownerParts = props?.selectedRow?.document?.owner
            ?.split("|")
            .map((owner) => owner.trim());
          const hasMultipleOwners = ownerParts.length > 1;

          const addressParts = props?.selectedRow?.document?.property_address
            ?.split("|")
            .map((address) => address.trim());
          const hasMultipleAddress = addressParts.length > 1;
          return (
            <Box
              style={{
                height: "calc(100% - 110px)",
                overflow: "scroll",
                WebkitOverflowScrolling: "touch",
                "&::WebkitScrollbar": {
                  display: "none",
                },
                scrollbarWidth: "none",
                msOverflowStyle: "none",
              }}
            >
              <Box
                p={1}
                m={1}
                mt={iphone12Height ? 2 : undefined}
                mb={iphone12Height ? 2 : undefined}
                sx={{
                  boxShadow: "0 8px 16px rgba(0,0,0,0.4)",
                  borderRadius: "10px",
                  py: 1.5,
                  pt: 1,
                  pb: 1,
                  backgroundColor: "#fff",
                  border: "1px solid #ddd",
                  transition: "box-shadow 0.3s ease-in-out",
                }}
              >
                <Typography color="primary" variant="h5" align="center">
                  {props?.status?.reason}
                </Typography>
                <Typography
                  sx={{ color: "#333333" }}
                  variant="h6"
                  align="center"
                >
                  {toTitleCase(props?.selectedRow?.document_type)}
                </Typography>
                <Typography
                  sx={{ color: "#333333" }}
                  variant="h6"
                  align="center"
                >
                  {props?.rowIndex + 1}/{props?.currentRows.length}
                </Typography>
              </Box>
              <Box
                sx={{
                  p: 2,
                  pt: hasMultipleOwners ? 0 : 1,
                  pb: 1,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    mb: hasMultipleOwners ? "-10px" : undefined,
                  }}
                >
                  <Typography variant="h6">{ownerParts[0]}</Typography>
                  {hasMultipleOwners && (
                    <IconButton
                      onClick={() => handleToggle(props?.rowIndex)}
                      aria-expanded={expanded[props?.rowIndex] || false}
                      aria-label="show more"
                    >
                      {expanded[props?.rowIndex] ? (
                        <ExpandLessIcon sx={{ fontSize: "30px" }} />
                      ) : (
                        <ExpandMoreIcon sx={{ fontSize: "30px" }} />
                      )}
                    </IconButton>
                  )}
                </Box>
                {hasMultipleOwners && (
                  <Collapse
                    sx={{ pl: 1 }}
                    in={expanded[props?.rowIndex]}
                    timeout="auto"
                    unmountOnExit
                  >
                    {ownerParts.slice(1).map((owner, idx) => (
                      <Typography key={`owner-${idx}`} variant="h6">
                        {owner}
                      </Typography>
                    ))}
                  </Collapse>
                )}
                <Stack
                  mt={hasMultipleAddress ? 0 : 0.5}
                  direction="row"
                  sx={{ height: "2rem" }}
                  alignItems="center"
                  spacing={1}
                >
                  <HomeIcon sx={{ color: "#333333" }} />
                  <Typography sx={{ flex: 2 }} variant="body2">
                    {addressParts[0]}
                  </Typography>
                  {hasMultipleAddress && (
                    <IconButton
                      sx={{ pb: 1.5 }}
                      onClick={() => handleAddressToggle(props?.rowIndex)}
                      aria-expanded={addressExpanded[props?.rowIndex] || false}
                      aria-label="show more"
                    >
                      {addressExpanded[props?.rowIndex] ? (
                        <ExpandLessIcon sx={{ fontSize: "30px" }} />
                      ) : (
                        <ExpandMoreIcon sx={{ fontSize: "30px" }} />
                      )}
                    </IconButton>
                  )}
                </Stack>
                {hasMultipleAddress && (
                  <Collapse
                    sx={{
                      pl: 4,
                      mt: addressExpanded ? -0.7 : undefined,
                      mb: addressExpanded ? 1 : undefined,
                    }}
                    in={addressExpanded[props?.rowIndex]}
                    timeout="auto"
                    unmountOnExit
                  >
                    {addressParts.slice(1).map((owner, idx) => (
                      <Typography key={`address-${idx}`} variant="body2">
                        {owner}
                      </Typography>
                    ))}
                  </Collapse>
                )}

                <Stack mt={0.5} direction="row" alignItems="center" spacing={1}>
                  <PersonIcon sx={{ color: "#333333" }} />
                  <Typography variant="body2">
                    {props?.selectedRow?.document?.owner_address}
                  </Typography>
                </Stack>
              </Box>
              <Box
                sx={{
                  border: "2px solid #ddd",
                  mt: iphone12Height ? 4 : 0,
                  mb: iphone12Height ? 2 : 0,
                  p: iphone12Height ? 3 : 2,
                  borderRadius: "10px",
                  boxShadow: "0 8px 16px rgba(0,0,0,0.4)",
                }}
              >
                <Typography variant="h6" align="center">
                  Send Reminder Email to Signee
                </Typography>
                <Box>
                  <Typography
                    color="primary"
                    sx={{ mb: 1, fontWeight: "bold" }}
                    variant="body1"
                    align="center"
                  >
                    {props?.selectedRow?.email}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: "red" }}
                    align="center"
                  >
                    {props?.reminderMessage}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <LoadingButton
                    size="small"
                    disabled={
                      props?.reminderSent || props?.changeEmailLoading === true
                    }
                    loading={props?.reminderLoading}
                    variant="contained"
                    color="success"
                    fullWidth
                    onClick={props?.handleSendReminder}
                  >
                    Send Email
                  </LoadingButton>
                </Box>
              </Box>
              <Box
                sx={{
                  border: "2px solid #ddd",
                  mt: iphone12Height ? 6 : 2,
                  mb: 1,
                  p: 2,
                  borderRadius: "10px",
                  boxShadow: "0 8px 16px rgba(0,0,0,0.4)",
                }}
              >
                <Typography variant="h6" align="center" mb={1}>
                  Change Signee Email
                </Typography>
                <Box>
                  <TextField
                    size="small"
                    disabled={
                      props?.emailChanged || props?.selectedRow?.reg_visited
                    }
                    fullWidth
                    label="Signee New Email"
                    value={props?.newEmail}
                    onChange={props?.handleEmailChange}
                    error={props?.emailError}
                    helperText={
                      props?.emailError ? "Invalid email address" : ""
                    }
                  />
                  <Typography
                    variant="body2"
                    sx={{ color: "red", mt: 1, mb: 0 }}
                    align="center"
                  >
                    {props?.selectedRow?.reg_visited && !props?.emailMessage
                      ? "The signee's email cannot be changed because the registration process has already begun"
                      : props?.emailMessage}
                  </Typography>
                  <LoadingButton
                    size="small"
                    loading={props?.changeEmailLoading}
                    disabled={
                      props?.selectedRow?.email === props?.newEmail ||
                      props?.emailChanged ||
                      props?.emailError === true ||
                      props?.newEmail === ""
                    }
                    variant="contained"
                    color="success"
                    fullWidth
                    onClick={props?.submitEmailChange}
                  >
                    Change Email
                  </LoadingButton>
                </Box>
              </Box>
            </Box>
          );
        })()
      ) : props?.settingsEnabled && props?.failedEnabled ? (
        (() => {
          const ownerParts = props?.selectedRow?.document?.owner
            ?.split("|")
            .map((owner) => owner.trim());
          const hasMultipleOwners = ownerParts.length > 1;

          const addressParts = props?.selectedRow?.document?.property_address
            ?.split("|")
            .map((address) => address.trim());
          const hasMultipleAddress = addressParts.length > 1;
          return (
            <Box
              style={{
                height: "calc(100% - 110px)",
                overflow: "scroll",
                WebkitOverflowScrolling: "touch",
                "&::WebkitScrollbar": {
                  display: "none",
                },
                scrollbarWidth: "none",
                msOverflowStyle: "none",
              }}
            >
              <Box
                p={1}
                m={1}
                mt={iphone12Height ? 2 : undefined}
                mb={iphone12Height ? 2 : undefined}
                sx={{
                  boxShadow: "0 8px 16px rgba(0,0,0,0.4)",
                  borderRadius: "10px",
                  py: 1.5,
                  pt: 1,
                  pb: 1,
                  backgroundColor: "#fff",
                  border: "1px solid #ddd",
                  transition: "box-shadow 0.3s ease-in-out",
                }}
              >
                <Typography color="primary" variant="h5" align="center">
                  {props?.status?.reason}
                </Typography>
                <Typography
                  sx={{ color: "#333333" }}
                  variant="h6"
                  align="center"
                >
                  {toTitleCase(props?.selectedRow?.document_type)}
                </Typography>
                <Typography
                  sx={{ color: "#333333" }}
                  variant="h6"
                  align="center"
                >
                  {props?.rowIndex + 1}/{props?.currentRows.length}
                </Typography>
              </Box>
              <Box
                sx={{
                  p: 2,
                  pt: hasMultipleOwners ? 0 : 1,
                  pb: 1,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    mb: hasMultipleOwners ? "-10px" : undefined,
                  }}
                >
                  <Typography variant="h6">{ownerParts[0]}</Typography>
                  {hasMultipleOwners && (
                    <IconButton
                      onClick={() => handleToggle(props?.rowIndex)}
                      aria-expanded={expanded[props?.rowIndex] || false}
                      aria-label="show more"
                    >
                      {expanded[props?.rowIndex] ? (
                        <ExpandLessIcon sx={{ fontSize: "30px" }} />
                      ) : (
                        <ExpandMoreIcon sx={{ fontSize: "30px" }} />
                      )}
                    </IconButton>
                  )}
                </Box>
                {hasMultipleOwners && (
                  <Collapse
                    sx={{ pl: 1 }}
                    in={expanded[props?.rowIndex]}
                    timeout="auto"
                    unmountOnExit
                  >
                    {ownerParts.slice(1).map((owner, idx) => (
                      <Typography key={`owner-${idx}`} variant="h6">
                        {owner}
                      </Typography>
                    ))}
                  </Collapse>
                )}
                <Stack
                  mt={hasMultipleAddress ? 0 : 0.5}
                  direction="row"
                  sx={{ height: "2rem" }}
                  alignItems="center"
                  spacing={1}
                >
                  <HomeIcon sx={{ color: "#333333" }} />
                  <Typography sx={{ flex: 2 }} variant="body2">
                    {addressParts[0]}
                  </Typography>
                  {hasMultipleAddress && (
                    <IconButton
                      sx={{ pb: 1.5 }}
                      onClick={() => handleAddressToggle(props?.rowIndex)}
                      aria-expanded={addressExpanded[props?.rowIndex] || false}
                      aria-label="show more"
                    >
                      {addressExpanded[props?.rowIndex] ? (
                        <ExpandLessIcon sx={{ fontSize: "30px" }} />
                      ) : (
                        <ExpandMoreIcon sx={{ fontSize: "30px" }} />
                      )}
                    </IconButton>
                  )}
                </Stack>
                {hasMultipleAddress && (
                  <Collapse
                    sx={{
                      pl: 4,
                      mt: addressExpanded ? -0.7 : undefined,
                      mb: addressExpanded ? 1 : undefined,
                    }}
                    in={addressExpanded[props?.rowIndex]}
                    timeout="auto"
                    unmountOnExit
                  >
                    {addressParts.slice(1).map((owner, idx) => (
                      <Typography key={`address-${idx}`} variant="body2">
                        {owner}
                      </Typography>
                    ))}
                  </Collapse>
                )}

                <Stack mt={0.5} direction="row" alignItems="center" spacing={1}>
                  <PersonIcon sx={{ color: "#333333" }} />
                  <Typography variant="body2">
                    {props?.selectedRow?.document?.owner_address}
                  </Typography>
                </Stack>
              </Box>
              <Typography align="center" variant="h5" mt={2} color="primary">
                What would you like to do?
              </Typography>
              <Typography px={1} align="center" variant="body2" color="primary">
                {props?.selectedRow?.source === "internal"
                  ? "In the event that cancellation is selected, the document will be returned to the dashboard's 'NOC PDF Forms Created' section"
                  : "In the event that cancellation is selected, the document will be permanently removed"}
              </Typography>
              <Box
                sx={{
                  mx: 1,
                  mt: 2,
                }}
              >
                <LoadingButton
                  disabled={
                    props?.cancelDisabled ||
                    props?.failures?.cancelNotaryLoading
                  }
                  onClick={() => {
                    if (props?.isCollapseOpen) {
                      props?.setCancelSelected(false);
                      props?.setIsCollapseOpen(false);
                      props?.setRestartDisabled(false);
                    } else {
                      props?.setCancelSelected(true);
                      props?.setIsCollapseOpen(true);
                      props?.setRestartDisabled(true);
                    }
                  }}
                  fullWidth
                  variant={props?.cancelSelected ? "contained" : "outlined"}
                  color="error"
                >
                  Cancel {props?.status?.reason}
                </LoadingButton>
              </Box>

              <Box
                sx={{
                  mx: 1,
                  mt: 1,
                }}
              >
                <LoadingButton
                  disabled={
                    props?.restartDisabled ||
                    props?.failures?.againNotaryLoading
                  }
                  onClick={() => {
                    if (props?.isCollapseOpen) {
                      props?.setRestartSelected(false);
                      props?.setIsCollapseOpen(false);
                      props?.setCancelDisabled(false);
                    } else {
                      props?.setRestartSelected(true);
                      props?.setIsCollapseOpen(true);
                      props?.setCancelDisabled(true);
                    }
                  }}
                  fullWidth
                  variant={props?.restartSelected ? "contained" : "outlined"}
                  color="success"
                >
                  Restart {props?.status?.reason}
                </LoadingButton>
              </Box>

              <Box
                sx={{
                  p: props?.isCollapseOpen ? 2 : 0,
                  mt: 1,
                  mx: 1.5,
                  borderRadius: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow: "0 8px 16px rgba(0,0,0,0.4)",
                  border: props?.isCollapseOpen ? "1px solid #ddd" : undefined,
                }}
              >
                <Collapse in={props?.isCollapseOpen}>
                  <Typography align="center" variant="h6">
                    Are You Sure?
                  </Typography>
                  {props?.cancelSelected ? (
                    <LoadingButton
                      sx={{ mt: 2 }}
                      disabled={props?.cancelDisabled}
                      onClick={props?.handleCancelNotarization}
                      loading={props?.failures?.cancelNotaryLoading}
                      fullWidth
                      variant={props?.cancelSelected ? "contained" : "outlined"}
                      color="error"
                    >
                      Yes, Cancel
                    </LoadingButton>
                  ) : (
                    props?.restartSelected && (
                      <LoadingButton
                        sx={{ mt: 2 }}
                        disabled={props?.restartDisabled}
                        onClick={props?.handleRepeateNotarization}
                        loading={props?.failures?.againNotaryLoading}
                        fullWidth
                        variant={
                          props?.restartSelected ? "contained" : "outlined"
                        }
                        color="success"
                      >
                        Yes, Restart
                      </LoadingButton>
                    )
                  )}
                </Collapse>
              </Box>
            </Box>
          );
        })()
      ) : (
        <SwipeableViews
          axis="x"
          index={props?.rowIndex}
          onChangeIndex={props?.handleSwipe}
          enableMouseEvents
          style={{
            height: "calc(100% - 110px)",
            overflow: "scroll",
            WebkitOverflowScrolling: "touch",
            "&::WebkitScrollbar": {
              display: "none",
            },
            scrollbarWidth: "none",
            msOverflowStyle: "none",
          }}
        >
          {props?.currentRows?.map((row, index) => {
            const ownerParts = row?.document?.owner
              ?.split("|")
              .map((owner) => owner.trim());
            const hasMultipleOwners = ownerParts.length > 1;

            const addressParts = row?.document?.property_address
              ?.split("|")
              .map((address) => address.trim());
            const hasMultipleAddress = addressParts.length > 1;

            return (
              <React.Fragment key={row.id}>
                <Box
                  key={`box-${row.id}`}
                  p={1}
                  m={1}
                  mt={iphone12Height ? 2 : undefined}
                  mb={iphone12Height ? 2 : undefined}
                  sx={{
                    boxShadow: "0 8px 16px rgba(0,0,0,0.4)",
                    borderRadius: "10px",
                    py: 1.5,
                    pt: iphone14Height ? 2 : 1,
                    pb: iphone14Height ? 2 : 1,
                    backgroundColor: "#fff",
                    border: "1px solid #ddd",
                    transition: "box-shadow 0.3s ease-in-out",
                  }}
                >
                  <Typography color="primary" variant="h5" align="center">
                    {props?.status?.reason}
                  </Typography>
                  <Typography
                    sx={{ color: "#333333" }}
                    variant="h6"
                    align="center"
                  >
                    {toTitleCase(row?.document_type)}
                  </Typography>
                  <Typography
                    sx={{ color: "#333333" }}
                    variant="h6"
                    align="center"
                  >
                    {props?.rowIndex + 1}/{props?.currentRows.length}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    p: 2,
                    pt: hasMultipleOwners ? 0 : 1,
                    pb: 1,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      mb: hasMultipleOwners ? "-10px" : undefined,
                    }}
                  >
                    <Typography variant="h6">{ownerParts[0]}</Typography>
                    {hasMultipleOwners && (
                      <IconButton
                        onClick={() => handleToggle(index)}
                        aria-expanded={expanded[index] || false}
                        aria-label="show more"
                      >
                        {expanded[index] ? (
                          <ExpandLessIcon sx={{ fontSize: "30px" }} />
                        ) : (
                          <ExpandMoreIcon sx={{ fontSize: "30px" }} />
                        )}
                      </IconButton>
                    )}
                  </Box>
                  {hasMultipleOwners && (
                    <Collapse
                      sx={{ pl: 1 }}
                      in={expanded[index]}
                      timeout="auto"
                      unmountOnExit
                    >
                      {ownerParts.slice(1).map((owner, idx) => (
                        <Typography key={`owner-${idx}`} variant="h6">
                          {owner}
                        </Typography>
                      ))}
                    </Collapse>
                  )}

                  <Stack
                    mt={hasMultipleAddress ? 0 : 0.5}
                    direction="row"
                    sx={{ height: "2rem" }}
                    alignItems="center"
                    spacing={1}
                  >
                    <HomeIcon sx={{ color: "#333333" }} />
                    <Typography sx={{ flex: 2 }} variant="body2">
                      {addressParts[0]}
                    </Typography>
                    {hasMultipleAddress && (
                      <IconButton
                        sx={{ pb: 1.5 }}
                        onClick={() => handleAddressToggle(index)}
                        aria-expanded={addressExpanded[index] || false}
                        aria-label="show more"
                      >
                        {addressExpanded[index] ? (
                          <ExpandLessIcon sx={{ fontSize: "30px" }} />
                        ) : (
                          <ExpandMoreIcon sx={{ fontSize: "30px" }} />
                        )}
                      </IconButton>
                    )}
                  </Stack>
                  {hasMultipleAddress && (
                    <Collapse
                      sx={{
                        pl: 4,
                        mt: addressExpanded ? -0.7 : undefined,
                        mb: addressExpanded ? 1 : undefined,
                      }}
                      in={addressExpanded[index]}
                      timeout="auto"
                      unmountOnExit
                    >
                      {addressParts.slice(1).map((owner, idx) => (
                        <Typography key={`address-${idx}`} variant="body2">
                          {owner}
                        </Typography>
                      ))}
                    </Collapse>
                  )}
                  <Stack
                    mt={0.5}
                    direction="row"
                    alignItems="center"
                    spacing={1}
                  >
                    <PersonIcon sx={{ color: "#333333" }} />
                    <Typography variant="body2">
                      {row?.document?.owner_address}
                    </Typography>
                  </Stack>

                  {/* {!props?.settingsEnabled && } */}

                  <Box>
                    <Box
                      sx={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "space-between",
                        mt: iphone12Height ? 2 : 1,
                        pb: iphone12Height ? 2 : undefined,
                      }}
                    >
                      <Chip
                        sx={{ fontWeight: "bold" }}
                        label={row?.document?.county?.toUpperCase()}
                        variant="outlined"
                        color="primary"
                        size="small"
                      />
                      <Chip
                        sx={{
                          fontWeight: "bold",
                          color: props?.status?.inProgress
                            ? "#33333"
                            : props?.status?.completed
                            ? "#fff"
                            : props?.status?.failed && "#fff",
                        }}
                        label={
                          props?.status?.inProgress
                            ? "IN PROGRESS..."
                            : props?.status?.completed
                            ? "COMPLETED"
                            : props?.status?.failed && "FAILED"
                        }
                        variant="contained"
                        color={
                          props?.status?.completed
                            ? "success"
                            : props?.status?.failed
                            ? "error"
                            : undefined
                        }
                        size="small"
                      />
                      <Chip
                        sx={{ fontWeight: "bold" }}
                        label={`ID: ${row.id?.slice(-6)}`}
                        variant="outlined"
                        color="primary"
                        size="small"
                      />
                    </Box>
                    <Box mt={2}>
                      <Typography
                        align="center"
                        sx={{
                          color:
                            props?.status?.completed === true
                              ? "#10B981"
                              : props?.status?.failed
                              ? "red"
                              : "#004976",
                        }}
                        variant="h6"
                      >
                        {props?.status?.statusText}
                      </Typography>
                    </Box>
                    {props?.status?.inProgress ? (
                      <Box mt={iphone14Height ? 5 : 1}>
                        <DocumentsHubWaiting width={150} height="auto" />
                      </Box>
                    ) : props?.status?.completed ? (
                      <Box mt={iphone14Height ? 5 : 1}>
                        <DocumentsHubCompleted width={110} height="auto" />
                      </Box>
                    ) : props?.status?.failed ? (
                      <Box mt={iphone14Height ? 3 : -2}>
                        <DocumentsHubFailed
                          width={
                            iphone14Height ? 220 : iphone12Height ? 180 : 150
                          }
                          height="auto"
                        />
                      </Box>
                    ) : (
                      <></>
                    )}

                    <Box mt={iphone12Height ? 7 : 2}>
                      <Stack direction="row" justifyContent="space-between">
                        <Typography fontWeight="bold" variant="body2">
                          Process Initiated:
                        </Typography>
                        <Typography variant="body2">
                          {convertIsoToLocalFormat(
                            row?.created_at,
                            props?.timezone,
                            "LLLL dd, yyyy hh:mm a"
                          )}
                        </Typography>
                      </Stack>
                      {(row?.reason === "notary" ||
                        row?.reason === "notaryRecord") &&
                        row?.signee_registered === true && (
                          <Stack direction="row" justifyContent="space-between">
                            <Typography fontWeight="bold" variant="body2">
                              Signee Registered:
                            </Typography>
                            <Typography variant="body2">
                              {convertIsoToLocalFormat(
                                row?.assigned_notary_at,
                                props?.timezone,
                                "LLLL dd, yyyy hh:mm a"
                              )}
                            </Typography>
                          </Stack>
                        )}

                      {(row?.reason === "notary" ||
                        row?.reason === "notaryRecord") &&
                        row?.signee_registered === true && (
                          <Stack direction="row" justifyContent="space-between">
                            <Typography fontWeight="bold" variant="body2">
                              RON Session Date:
                            </Typography>
                            <Typography variant="body2">
                              {convertTimezoneFromObj(
                                row?.appointments?.booked?.[0],
                                props?.timezone,
                                "LLLL dd, yyyy hh:mm a"
                              )}
                            </Typography>
                          </Stack>
                        )}

                      {(row?.reason === "notary" ||
                        row?.reason === "notaryRecord") &&
                      row?.notary_failed === true ? (
                        <Stack
                          sx={{
                            backgroundColor: "red",
                            color: "#fff",
                            py: 0.5,
                            px: 1,
                            borderRadius: "10px",
                          }}
                          direction="row"
                          justifyContent="space-between"
                        >
                          <Typography fontWeight="bold" variant="body2">
                            RON Session Failed:
                          </Typography>
                          <Typography fontWeight="bold" variant="body2">
                            {convertIsoToLocalFormat(
                              row?.notary_failure?.[0]?.date,
                              props?.timezone,
                              "LLLL dd, yyyy hh:mm a"
                            )}
                          </Typography>
                        </Stack>
                      ) : (
                        (row?.reason === "notary" ||
                          row?.reason === "notaryRecord") &&
                        row?.is_notarized === true && (
                          <Stack
                            sx={{
                              backgroundColor: "#10B981",
                              color: "#fff",
                              py: 0.5,
                              px: 1,
                              borderRadius: "10px",
                            }}
                            direction="row"
                            justifyContent="space-between"
                          >
                            <Typography fontWeight="bold" variant="body2">
                              Notarized At:
                            </Typography>
                            <Typography fontWeight="bold" variant="body2">
                              {convertIsoToLocalFormat(
                                row?.notarized_at,
                                props?.timezone,
                                "LLLL dd, yyyy hh:mm a"
                              )}
                            </Typography>
                          </Stack>
                        )
                      )}
                      {(row?.reason === "record" ||
                        row?.reason === "notaryRecord") &&
                        Boolean(row?.recording?.started_at) && (
                          <Stack direction="row" justifyContent="space-between">
                            <Typography fontWeight="bold" variant="body2">
                              Recording Started:
                            </Typography>
                            <Typography variant="body2">
                              {convertIsoToLocalFormat(
                                row?.recording?.started_at,
                                props?.timezone,
                                "LLLL dd, yyyy hh:mm a"
                              )}
                            </Typography>
                          </Stack>
                        )}
                      {(row?.reason === "record" ||
                        row?.reason === "notaryRecord") &&
                      row?.recording?.failure?.length > 0 ? (
                        <Stack
                          sx={{
                            backgroundColor: "red",
                            color: "#fff",
                            py: 0.5,
                            px: 1,
                            borderRadius: "10px",
                          }}
                          direction="row"
                          justifyContent="space-between"
                        >
                          <Typography fontWeight="bold" variant="body2">
                            Recording Failed:
                          </Typography>
                          <Typography fontWeight="bold" variant="body2">
                            {convertIsoToLocalFormat(
                              row?.recording?.failure?.[0]?.date,
                              props?.timezone,
                              "LLLL dd, yyyy hh:mm a"
                            )}
                          </Typography>
                        </Stack>
                      ) : (
                        (row?.reason === "record" ||
                          row?.reason === "notaryRecord") &&
                        row?.recording?.is_recorded === true && (
                          <Stack
                            sx={{
                              backgroundColor: "#10B981",
                              color: "#fff",
                              py: 0.5,
                              px: 1,
                              borderRadius: "10px",
                            }}
                            direction="row"
                            justifyContent="space-between"
                          >
                            <Typography fontWeight="bold" variant="body2">
                              Recorded At:
                            </Typography>
                            <Typography fontWeight="bold" variant="body2">
                              {convertIsoToLocalFormat(
                                row?.recording?.finished_at,
                                props?.timezone,
                                "LLLL dd, yyyy hh:mm a"
                              )}
                            </Typography>
                          </Stack>
                        )
                      )}
                    </Box>
                  </Box>
                </Box>
              </React.Fragment>
            );
          })}
        </SwipeableViews>
      )}
      <Toolbar
        sx={{
          px: 0,
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          opacity: 0.7,
          backgroundColor: "#333333",
        }}
      >
        <IconButton
          variant="contained"
          onClick={props?.handleOverviewClick}
          sx={{ flex: 1 }}
        >
          <Box>
            <DashboardIcon sx={{ color: "#fff" }} />
            <FormHelperText
              sx={{ fontWeight: "bold", color: "#fff", mt: -1, px: "5px" }}
            >
              Overview
            </FormHelperText>
            <Box
              sx={{
                borderBottom: props?.overviewEnabled
                  ? "3px solid #757575"
                  : "3px solid transparent",
                mt: "-4px",
              }}
            ></Box>
          </Box>
        </IconButton>

        <IconButton
          disabled={props?.status?.inProgress || !props?.status?.completed}
          sx={{
            flex: 1,
            opacity:
              props?.status?.inProgress || !props?.status?.completed ? 0.3 : 1,
          }}
          onClick={() => props?.handleDownloadNoc(props?.selectedRow)}
          variant="contained"
        >
          {props?.mobileDownloading ? (
            <Box>
              <CircularProgress sx={{ color: "#fff" }} size="18px" />
            </Box>
          ) : (
            <Box>
              <DocumentArrowDownIcon width={25} color="#fff" />
              <FormHelperText
                sx={{ fontWeight: "bold", color: "#fff", mt: -1, px: "5px" }}
              >
                Download
              </FormHelperText>
              <Box
                sx={{
                  borderBottom: "3px solid transparent",
                  mt: "-4px",
                }}
              ></Box>
            </Box>
          )}
        </IconButton>
        <IconButton
          disabled={
            !props?.failedEnabled
              ? props?.selectedRow?.signee_registered
              : false
          }
          sx={{
            padding: 0,
            flex: 1,
            opacity:
              !props?.failedEnabled && props?.selectedRow?.signee_registered
                ? 0.3
                : 1,
          }}
          variant="contained"
          onClick={props?.handleSettingsClick}
        >
          <Box>
            <SettingsIcon sx={{ color: "#fff", fontSize: "1.4rem" }} />
            <FormHelperText
              sx={{ fontWeight: "bold", color: "#fff", mt: -1, px: "5px" }}
            >
              Actions
            </FormHelperText>
            <Box
              sx={{
                borderBottom: props?.settingsEnabled
                  ? "3px solid #757575"
                  : "3px solid transparent",
                mt: "-4px",
              }}
            ></Box>
          </Box>
        </IconButton>
      </Toolbar>
    </Dialog>
  );
};

export default MobileSealRecordModal;
