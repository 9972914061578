import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setSignerRonInitial,
  setNotarizationStatusInitial,
  fetchGuestData,
} from "../../store/actions/guestActions";
import { DateTime } from "luxon";
import TimezoneSelect from "../layout/TimezoneSelect";
import { timezoneMappings } from "../../data/timezones";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import YupPassword from "yup-password";
import dayjs from "dayjs";
import {
  convertIsoToLocalFormat,
  convertTimezoneFromObj,
} from "../../functions/helperFunctions";
import Cookies from "js-cookie";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Typography,
  Grid,
  useMediaQuery,
  useTheme,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from "@mui/material";
import Schedule from "../notary/signee/Schedule";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

YupPassword(Yup);

const ManageSigneeAppointment = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const scrollableElementRef = useRef(null);
  const currentSession = useSelector((state) => state.sessions.currentSession);
  const guestUser = useSelector((state) => state.guestSession);
  const mobileSize = useMediaQuery(theme.breakpoints.down("sm"));
  const tabletSize = useMediaQuery(theme.breakpoints.down("md"));
  const largeTabletSize = useMediaQuery(theme.breakpoints.down("lg"));

  const [pageLoading, setPageLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [notaryError, setNotaryError] = useState("");
  const [propertyDetails, setPropertyDetails] = useState({});
  const [documentModal, setDocumentModal] = useState(false);
  const [availableChecked, setAvailableChecked] = useState(true);
  const [scheduleTimeLeft, setScheduleTimeLeft] = useState(60);
  const [startTimer, setStartTimer] = useState(false);
  const [checkDateLoading, setCheckDateLoading] = useState(false);
  const [isAvailable, setIsAvailable] = useState(false);
  const [availableDate, setAvailableDate] = useState(null);
  const [slots, setSlots] = useState([]);
  const [slotIndex, setSlotIndex] = useState(-1);
  const [unavailableText, setUnavailableText] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);
  const [changeIsExpanded, setChangeIsExpanded] = useState(false);
  const [isEmergencyLeave, setIsEmergencyLeave] = useState(true);
  const [firstTimer, setFirstTimer] = useState(true);
  const [inSession, setInSession] = useState(false);
  const [changeAvailable, setChangeAvailable] = useState(false);
  const [commonText, setCommonText] = useState("");
  const [timezone, setTimezone] = useState("America/New_York");
  const [appointment, setAppointment] = useState("");
  const [notaryFinished, setNotaryFinished] = useState(false);
  const [ids, setIds] = useState();

  const [uiTexts, setUiTexts] = useState({
    title: "Remote Online Notarization (RON) Appointment",
    description:
      "We have scheduled a notarization session for you. You can join the session through the button provided on our platform 5 minutes before the scheduled time. Before joining the session, you will need to complete the identity verification process. This includes biometric face authentication using your mobile phone and validating your ID card. Once both steps are completed, you will be redirected to the signing session to meet your notary. Please note that there is a 15-minute grace period after the scheduled time, after which the notarization session will no longer be available, and a new appointment will need to be scheduled.",
    idWarn:
      "In the mean time make sure you have your identification documents ready.",
    sessionStartWarn: "",
    rescheduleWarn: "",
  });

  useEffect(() => {
    console.log(guestUser);
    if (!guestUser?.manageData) {
      dispatch(fetchGuestData("guestSession"));
    }
  }, []);

  useEffect(() => {
    if (guestUser?.signerRonDate) {
      const dateTime = DateTime.fromISO(guestUser?.signerRonDate, {
        zone: timezone,
      });
      // Formatting the DateTime object
      const formattedDateTime = dateTime.toFormat(
        "cccc, LLLL dd, yyyy hh:mm a"
      );
      setAppointment(`Your session is scheduled for ${formattedDateTime}`);

      setPropertyDetails((oldValue) => ({
        ...oldValue,
        appointment: guestUser?.signerRonDate,
      }));
      setChangeAvailable(true);

      dispatch(setSignerRonInitial());
    }
  }, [guestUser]);

  useState(() => {
    const appointment =
      guestUser?.manageData?.owner?.notarization?.appointments?.booked;
    const formattedDateTime = convertTimezoneFromObj(
      appointment?.[0],
      timezone,
      "LLLL dd, yyyy hh:mm a"
    );

    setAppointment(`Your session is scheduled for ${formattedDateTime}`);
  }, [timezone, appointment]);

  useEffect(() => {
    if (guestUser?.notaryRecordCompleted) {
      setNotaryFinished(true);
      setUiTexts({
        ...uiTexts,
        title:
          "Remote Online Notarization (RON) Appointment Has Been Completed",
        description:
          "We are pleased to inform you that your document has been notarized successfully. The recording process for the notarized document has already begun. The document has been sent to the recording office for recording. Please wait while they process your document. It may take up to five days for this process to be completed.",
        idWarn: "",
        sessionStartWarn: "Notarization completed at:",
        rescheduleWarn: "",
      });
      const dateTime = DateTime.fromISO(guestUser?.notaryRecordCompletedTime, {
        zone: timezone,
      });
      // Formatting the DateTime object
      const formattedDateTime = dateTime.toFormat(
        "cccc, LLLL dd, yyyy hh:mm a"
      );
      setAppointment(formattedDateTime);
    }
  }, [guestUser]);

  useEffect(() => {
    const payload = {
      task_id: ids?.task_id,
      noc_id: ids?.noc_id,
      currentSession: currentSession,
    };

    const handleUnload = async () => {
      if (isEmergencyLeave === true && propertyDetails?.isChanged === false) {
        await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/signee/emergency-clear-ron-schedules`,
          {
            payload,
          },
          { withCredentials: true }
        );
      }
    };

    window.addEventListener("beforeunload", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, [ids, isEmergencyLeave, propertyDetails?.isChanged]);

  useEffect(() => {
    if (slots.length > 0 || isAvailable) {
      // Assuming `slots` is your state variable
      scrollToBottom();
    }
  }, [slots, isAvailable]);

  const scrollToBottom = () => {
    const scrollableElement = scrollableElementRef.current;
    if (scrollableElement) {
      scrollableElement.scrollTop = scrollableElement.scrollHeight;
    }
  };

  useEffect(() => {
    if (guestUser?.notarizationCompleted === true) {
      dispatch(setNotarizationStatusInitial());
      navigate("/");
    }
  }, [guestUser]);

  function convertDayjsToObject(dayjsObj) {
    return {
      day_of_month: dayjsObj.date(), // Get the day of the month
      month: dayjsObj.month() + 1, // Months are zero-indexed, add 1 for 1-12 format
      year: dayjsObj.year(),
      time: dayjsObj.format("HH:mm"), // Formats the time in 'HH:mm' format
    };
  }
  function convertObjectToDayjs(dateInfo) {
    // Day.js month is zero-indexed, subtract 1
    const monthZeroIndexed = dateInfo.month - 1;

    // Reconstructing the full date-time string
    const dateTimeString = `${dateInfo.year}-${String(
      monthZeroIndexed + 1
    ).padStart(2, "0")}-${String(dateInfo.day_of_month).padStart(2, "0")} ${
      dateInfo.time
    }`;

    return dayjs(dateTimeString);
  }

  const handleTimeUp = async (formikState) => {
    const payload = {
      task_id: ids?.task_id,
      noc_id: ids?.noc_id,
      currentSession: currentSession,
    };

    try {
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/signee/clear-change-appointment-date`,
        {
          payload,
        },
        {
          withCredentials: true,
        }
      );

      setSlots([]);
      setSlotIndex(-1);
      formikState.values.scheduledDate = null;
      setUnavailableText("");
      setIsAvailable(false);
      setFirstTimer(true);
    } catch (e) {}
    // Call your API here
    setStartTimer(false); // Reset the timer state
  };

  const handleStartClick = async (formik) => {
    setCheckDateLoading(true);
    let isWithinHour = false;
    if (
      (formik.errors.scheduledDate &&
        formik.errors.scheduledDate !==
          "Please check availability before Submit") ||
      isAvailable
    ) {
      setCheckDateLoading(false);
      return;
    }
    if (
      formik.values.scheduledDate === "" ||
      formik.values.scheduledDate === null
    ) {
      formik.setFieldTouched("scheduledDate", true, false);
      formik.setFieldError("scheduledDate", "Date is required");
      setCheckDateLoading(false);
      return;
    }
    const formattedObject = convertDayjsToObject(formik.values.scheduledDate);

    const payload = {
      task_id: ids?.task_id,
      noc_id: ids?.noc_id,
      ask_date: formattedObject,
      changeAppointment: true,
      currentSession: currentSession,
      timezone: timezone,
    };

    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/notary/available-ron-schedule`,
        {
          payload,
        },
        {
          withCredentials: true,
        }
      );

      if (resp.data.success) {
        isWithinHour = true;
        if (resp.data.isAvailable === true) {
          const convertedDate = convertObjectToDayjs(resp.data.available);
          setScheduleTimeLeft(60); // Reset the time to 60 seconds
          setStartTimer(true);
          setFirstTimer(false);
          setAvailableDate(convertedDate);
          setIsAvailable(true);
          setSlots([]);
        } else if (resp.data.isAvailable === false) {
          setScheduleTimeLeft(60); // Reset the time to 60 seconds
          setStartTimer(true);
          setFirstTimer(false);
          setIsAvailable(false);
          setUnavailableText(
            "The selected date is unavailable. Please choose a different date or select one from the list below."
          );
          formik.values.scheduledDate = null;
          setSlots(resp.data.slots);
        }
      } else {
        isWithinHour = false;
        setScheduleTimeLeft(60);
        setFirstTimer(false);
        setIsAvailable(false);
        setUnavailableText(
          "The selected date is unavailable. Please choose a different date."
        );
        formik.values.scheduledDate = null;
        setSlots([]);
      }
    } catch (e) {}

    formik.setFieldError("scheduledDate", "");
    if (isWithinHour) {
      if (firstTimer) {
        setScheduleTimeLeft(60); // Reset the time to 60 seconds
        setStartTimer(true);
        setFirstTimer(false);
      }
    }

    setCheckDateLoading(false);
  };

  useEffect(() => {
    if (propertyDetails?.appointment) {
      const checkTimeAndDifference = () => {
        const appointmentTime = DateTime.fromISO(
          propertyDetails.appointment
        ).toUTC();

        const currentTime = DateTime.now().toUTC();

        // Check if current time is later than the appointment time
        if (currentTime > appointmentTime && notaryFinished === false) {
          setInSession(true);
          setUiTexts((prev) => ({
            ...prev,
            sessionStartWarn:
              "The session has begun. Please join the session using the link provided in the email sent to you",
          }));
          setAppointment(`Your session is scheduled for ${appointment}`);
          // Optionally, clear the interval if you no longer need to check the time
          // clearInterval(intervalId);
        }

        // Check if the time difference is less than 2 hours
        const timeDifference = appointmentTime.diff(currentTime, "hours");
        if (timeDifference.hours <= 2 || notaryFinished) {
          // 2 hours * 60 minutes * 60 seconds
          setChangeAvailable(false);
        } else {
          setChangeAvailable(true);
        }
      };

      checkTimeAndDifference();
      // Set an interval to check the time every minute (60,000 milliseconds)
      const intervalId = setInterval(checkTimeAndDifference, 60000);

      // Clear the interval on component unmount
      return () => clearInterval(intervalId);
    }
  }, [propertyDetails]);

  useEffect(() => {
    setPropertyDetails(guestUser?.manageData?.overview_data);

    if (
      guestUser?.manageData?.overview_data?.instant &&
      notaryFinished === false
    ) {
      setUiTexts((prev) => ({
        ...prev,
        description:
          "A notarization session has been scheduled for your immediate use. Please check your email so you can start the verification process of your identity prior to the scheduled date/time. You won’t be able to join the signing session without having verified your identity. This is a simple process comprising two steps: Knowledge Based Answers (The system will ask you 5 questions based on public records) and then you need to validate your ID using your mobile phone. Once you complete both steps you will be redirected to the signing session to meet your notary. There is a grace period of 15 minutes following the scheduled date, after which the notarization session will not be available and need to schedule a new appointment.",
      }));
    }

    if (guestUser?.manageData?.notary_finished === true) {
      setNotaryFinished(true);
      setUiTexts({
        ...uiTexts,
        title:
          "Remote Online Notarization (RON) Appointment Has Been Completed",
        description:
          "We are pleased to inform you that your document has been notarized successfully. The recording process for the notarized document has already begun. The document has been sent to the recording office for recording. Please wait while they process your document. It may take up to five days for this process to be completed.",
        idWarn: "",
        sessionStartWarn: "The session finished at:",
        rescheduleWarn: "",
      });
    }

    if (
      guestUser?.manageData?.notary_finished === true &&
      guestUser?.manageData?.notarized_at
    ) {
      const notarizedTime = convertIsoToLocalFormat(
        guestUser?.manageData?.notarized_at,
        guestUser?.manageData?.overview_data?.timezone,
        "cccc, LLLL dd, yyyy hh:mm a"
      );
      setUiTexts((prev) => ({
        ...prev,
        sessionStartWarn: "Notarization completed at:",
      }));
      setAppointment(`Your session is scheduled for ${notarizedTime}`);
    }

    setPageLoading(false);
  }, []);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  function isValidTime(time) {
    // Check if the input is a valid date string
    if (typeof time === "string" && !isNaN(Date.parse(time))) {
      const dateObj = new Date(time);
      const minutes = dateObj.getMinutes();
      return minutes === 0 || minutes === 30;
    }
    return false;
  }

  const createValidationSchema = (availableChecked) => {
    return Yup.object({
      scheduledDate: Yup.string()
        .required("Date is required")
        .test(
          "check-availability",
          "Check availability is required",
          function (value) {
            if (typeof value === "string" && !isNaN(Date.parse(value))) {
              if (!isValidTime(value)) {
                return this.createError({
                  path: `scheduledDate`,
                  message: "Time must be on the hour or half-hour",
                });
              }
            } else {
              // Handle invalid or incomplete date format
              return this.createError({
                path: `scheduledDate`,
                message: "Invalid date format",
              });
            }
            // Now availableChecked is accessible
            return availableChecked;
          }
        ),
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      scheduledDate: null,
    },
    validationSchema: createValidationSchema(availableChecked),
    onSubmit: async (values, helpers) => {
      setSubmitLoading(true);
      if (isAvailable) {
        stopAndResetTimer();
      }
      try {
        const formErrors = await helpers.validateForm(values);
        if (Object.keys(formErrors).length === 0 && !isAvailable) {
          // Manually set an error for the 'scheduledDate' field
          helpers.setFieldError(
            "scheduledDate",
            "Please check availability before Submit"
          );
          setSubmitLoading(false);
          return; // Prevent form submission
        }

        const payload = {
          task_id: ids?.task_id,
          noc_id: ids?.noc_id,
          currentSession: currentSession,
          timezone: timezone,
        };

        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/signee/finalize-change-appointment`,
          {
            payload,
          },
          { withCredentials: true }
        );
        setSubmitLoading(false);
        if (response.data.success === false) {
          setNotaryError(response.data.message);
          setUnavailableText(response.data.message);
          setSubmitLoading(false);
        } else {
          setPropertyDetails((prevState) => ({
            ...prevState,
            appointment: response.data.newDate,
          }));

          const dateTime = DateTime.fromISO(response?.data?.newDate, {
            zone: timezone,
          });
          // Formatting the DateTime object
          const formattedDateTime = dateTime.toFormat(
            "cccc, LLLL dd, yyyy hh:mm a"
          );
          setAppointment(`Your session is scheduled for ${formattedDateTime}`);

          setCommonText(response.data.message);
          setIsAvailable(false);
          setSubmitLoading(false);
          setChangeIsExpanded(false);
        }
      } catch (e) {
        setNotaryError(e.response.data.message);
        setSubmitLoading(false);
      }
    },
  });

  const formikRef = useRef(formik);
  formikRef.current = formik;

  // Effect for the countdown timer
  useEffect(() => {
    let intervalId;

    if (startTimer && scheduleTimeLeft > 0) {
      intervalId = setInterval(() => {
        setScheduleTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [startTimer, scheduleTimeLeft]);

  // Separate effect to handle when the timer reaches zero
  useEffect(() => {
    if (scheduleTimeLeft === 0) {
      handleTimeUp(formikRef.current); // Call the function when time is up
      setStartTimer(false); // Optionally reset the startTimer state
    }
  }, [scheduleTimeLeft]);

  const stopAndResetTimer = () => {
    setStartTimer(false);
    setScheduleTimeLeft(60);
  };

  useEffect(() => {
    if (commonText !== "") {
      const commonID = setInterval(() => {
        setCommonText("");
      }, 10000);

      return () => clearInterval(commonID);
    }
  }, [commonText]);

  return (
    <>
      {pageLoading ? (
        <>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100vh",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CircularProgress color="warning" size="10rem" />
          </Box>
        </>
      ) : (
        <Box
          sx={
            tabletSize
              ? {
                  justifyContent: "center",
                  overflowX: "hidden",
                  pl: 1,
                  minHeight: "100vh",
                  overflowY: "hidden",
                  display: "flex",
                  flexDirection: "column",
                }
              : {
                  backgroundImage: "url('/img/website_img/homeImg.jpeg')",
                  backgroundRepeat: "repeat",

                  minHeight: "100vh",
                  overflow: "hidden",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }
          }
        >
          <Box
            sx={
              tabletSize
                ? {
                    backgroundColor: "#fff",
                    py: "1rem",
                    maxWidth: "100%",
                    flexGrow: 1,
                  }
                : {
                    backgroundColor: "#fff",
                    my: 2,
                    maxWidth: "80%",
                    borderRadius: "25px",
                    px: 3,
                    width: "80%",
                    py: "2rem",
                    position: "relative",
                  }
            }
          >
            {/* <div
              style={
                mobileSize
                  ? { width: "100%", padding: "1rem", position: "relative" }
                  : { paddingBottom: "1rem", position: "relative" }
              }
            > */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
              // onClick={() => {
              //   navigate("/");
              // }}
            >
              <img
                style={{
                  width: tabletSize ? "15rem" : "10rem",
                }}
                alt="Permit Rockstar Logo"
                src="/img/website_img/PermitRockstar.png"
              />
            </Box>
            {/* Top Fixed Text */}
            <Box
              sx={{
                position: "absolute",
                top: tabletSize ? 27 : 48,
                right: tabletSize ? 10 : 20,
              }}
            >
              {!mobileSize && !notaryFinished && (
                <TimezoneSelect
                  specialDisabled2={
                    formik.values.scheduledDate ||
                    slots.length !== 0 ||
                    unavailableText !== "" ||
                    checkDateLoading
                  }
                  options={timezoneMappings}
                  timezone={timezone}
                  setTimezone={setTimezone}
                  specificWidth={tabletSize ? "11rem" : undefined}
                  changeLocal={true}
                />
              )}
            </Box>
            <Typography
              color={notaryFinished ? "#4CAF50" : "primary"}
              variant={mobileSize ? "h5" : "h4"}
              sx={{ mb: mobileSize ? 1 : 2, mt: 1 }}
              align="center"
            >
              {uiTexts?.title}
            </Typography>
            {notaryFinished && (
              <Typography
                color={"primary"}
                variant={mobileSize ? "h5" : "h4"}
                sx={{ mb: mobileSize ? 1 : 2, mt: 1 }}
                align="center"
              >
                The Document Recording Process Has Been Initiated
              </Typography>
            )}

            <Typography
              color="primary"
              variant={mobileSize ? "body2" : "body1"}
              align="center"
              sx={{ mb: tabletSize && !mobileSize ? 0 : 0 }}
            >
              {uiTexts?.description}
            </Typography>

            <Typography
              color="#FF9800"
              variant="body1"
              align="center"
              fontWeight="bold"
              sx={{ mb: tabletSize && !mobileSize ? 5 : mobileSize ? 0.5 : 5 }}
            >
              {uiTexts?.idWarn}
            </Typography>
            {mobileSize && !notaryFinished && (
              <Box sx={{ display: "flex", justifyContent: "center", mb: 5 }}>
                <TimezoneSelect
                  specialDisabled2={
                    formik.values.scheduledDate ||
                    slots.length !== 0 ||
                    unavailableText !== "" ||
                    checkDateLoading
                  }
                  options={timezoneMappings}
                  timezone={timezone}
                  setTimezone={setTimezone}
                  specificWidth={largeTabletSize ? "15rem" : undefined}
                  changeLocal={true}
                />
              </Box>
            )}

            {/* IMMEDIATE */}
            {propertyDetails?.instant ? (
              <>
                <Typography
                  variant={mobileSize ? "h6" : "h5"}
                  align="center"
                  sx={{ mb: 1 }}
                  color="#F57C00"
                >
                  {uiTexts?.sessionStartWarn}
                </Typography>
                <Typography
                  mb={guestUser?.notaryRecordCompleted ? 2 : undefined}
                  variant={mobileSize ? "h5" : "h4"}
                  align="center"
                  color="#4CAF50"
                >
                  {appointment}
                </Typography>
                <Box align="center" mb={2}>
                  <Button
                    onClick={() => {
                      window.open(
                        guestUser?.manageData?.owner?.notarization?.bn_session
                          ?.signer_url,
                        "_blank"
                      );
                    }}
                    size="large"
                    variant="contained"
                  >
                    Join Session
                  </Button>
                </Box>
                {!guestUser?.notaryRecordCompleted && (
                  <Typography
                    variant="h5"
                    align="center"
                    color="#4CAF50"
                    sx={{ mb: "2rem" }}
                  >
                    Please complete the identity verification before this time
                    by following the instructions that were sent to your email:{" "}
                    {guestUser?.manageData?.owner?.personal?.email}
                  </Typography>
                )}

                <Grid
                  container
                  spacing={tabletSize ? 1 : 0}
                  align="center"
                  mb={tabletSize ? 2 : 5}
                >
                  <div
                    style={{
                      height: "4px",
                      backgroundColor: "#f3f3f3",
                      width: "100%",
                      marginBottom: "1rem",
                    }}
                  ></div>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h6">Property Address</Typography>
                    <Typography variant="body2">
                      {
                        guestUser?.manageData?.overview_data
                          ?.affected_property[0]?.value
                      }
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h6">Contractor</Typography>
                    <Typography variant="body2">
                      {guestUser?.manageData?.contractor?.businessName}
                    </Typography>
                    <Typography variant="body2">
                      {guestUser?.manageData?.contractor?.mobile}
                    </Typography>
                  </Grid>
                </Grid>
                <Typography variant="h6" mb={0.5} color="error">
                  {uiTexts?.rescheduleWarn}
                </Typography>
              </>
            ) : (
              <>
                <form noValidate onSubmit={formik.handleSubmit}>
                  <Box
                    ref={scrollableElementRef}
                    sx={{
                      width: "100%",
                      mt: "2rem",
                      mb: "1rem",
                    }}
                  >
                    <Typography
                      variant={mobileSize ? "h6" : "h5"}
                      align="center"
                      sx={{ mb: 1 }}
                      color="#F57C00"
                    >
                      {uiTexts?.sessionStartWarn}
                    </Typography>
                    <Typography
                      sx={{ mb: "2rem" }}
                      variant={mobileSize ? "h5" : "h4"}
                      align="center"
                      color="#4CAF50"
                    >
                      {appointment}
                    </Typography>
                    <Box align="center" mb={2}>
                      <Button
                        onClick={() => {
                          window.open(
                            guestUser?.manageData?.owner?.notarization
                              ?.bn_session?.signer_url,
                            "_blank"
                          );
                        }}
                        size="large"
                        variant="contained"
                      >
                        Join Session
                      </Button>
                    </Box>

                    <Grid container align="center" mb={5}>
                      <div
                        style={{
                          height: "4px",
                          backgroundColor: "#f3f3f3",
                          width: "100%",
                          marginBottom: "1rem",
                        }}
                      ></div>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="h6">Property Address</Typography>
                        <Typography variant="body2">
                          {
                            guestUser?.manageData?.overview_data
                              ?.affected_property[0]?.value
                          }
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="h6">Contractor</Typography>
                        <Typography variant="body2">
                          {guestUser?.manageData?.contractor?.businessName}
                        </Typography>
                        <Typography variant="body2">
                          {guestUser?.manageData?.contractor?.mobile}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Typography
                      variant={commonText === "" ? "h6" : "h4"}
                      mb={commonText === "" ? 2 : 1}
                      color={commonText === "" ? "error" : "primary"}
                      align={commonText === "" ? "left" : "center"}
                    >
                      {commonText === "" ? uiTexts?.rescheduleWarn : commonText}
                    </Typography>
                    {changeAvailable === true && (
                      <Accordion
                        disabled={propertyDetails?.instant}
                        expanded={changeIsExpanded}
                        onClick={() => {
                          if (propertyDetails?.instant) {
                            return;
                          }

                          setChangeIsExpanded(!changeIsExpanded);

                          setUnavailableText("");
                          setIsExpanded(false);
                          if (formik && formik.values) {
                            formik.resetForm();
                          }
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
                          sx={{
                            color: "#fff",
                            fontSize: "24px",
                            fontWeight: "bold",
                            backgroundColor: "#ff9800",
                          }}
                        >
                          Change Appointment
                        </AccordionSummary>
                        <AccordionDetails
                          onClick={(event) => {
                            event.stopPropagation();
                          }}
                          sx={{ p: 0, mt: 3 }}
                        >
                          <Grid container spacing={2} sx={{ px: "2px" }}>
                            <Schedule
                              type="manage"
                              propertyDetails={propertyDetails}
                              setDocumentModal={setDocumentModal}
                              formik={formik}
                              timeLeft={formatTime(scheduleTimeLeft)}
                              handleStartClick={handleStartClick}
                              checkDateLoading={checkDateLoading}
                              slots={slots}
                              slotIndex={slotIndex}
                              setSlotIndex={setSlotIndex}
                              convertObjectToDayjs={convertObjectToDayjs}
                              isAvailable={isAvailable}
                              setIsAvailable={setIsAvailable}
                              availableDate={availableDate}
                              setAvailableDate={setAvailableDate}
                              startTimer={startTimer}
                              setTimeLeft={setScheduleTimeLeft}
                              setSlots={setSlots}
                              unavailableText={unavailableText}
                              setUnavailableText={setUnavailableText}
                              isManage={true}
                              mobileSize={mobileSize}
                              tabletSize={tabletSize}
                              largeTabletSize={largeTabletSize}
                            />
                            <Grid item xs={12}>
                              <LoadingButton
                                type="submit"
                                loading={submitLoading}
                                size="large"
                                variant="contained"
                                fullWidth
                              >
                                Change
                              </LoadingButton>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    )}
                  </Box>
                </form>
              </>
            )}
            {/* </div> */}
          </Box>
          {/* <NotarizedModal
            notarizedModal={documentModal}
            setNotarizedModal={setDocumentModal}
            pdfImg={propertyImage[0]}
            pdfImg2={propertyImage[1]}
            previewImg=""
            preview={false}
          /> */}
        </Box>
      )}
    </>
  );
};

export default ManageSigneeAppointment;
