import { LoadingButton } from "@mui/lab";
import { Modal, Box, Button, Typography, TextField } from "@mui/material";

const DocumentSettingsModal = ({
  modal,
  setModal,
  newEmail,
  setNewEmail,
  handleEmailChange,
  submitEmailChange,
  emailText,
  emailMessage,
  emailError,
  setEmailError,
  reminderMessage,
  changeEmailLoading,
  selectedRow,
  emailChanged,
  setEmailChanged,
  handleSendReminder,
  reminderLoading,
  reminderSent,
  setReminderSent,
  setReminderMessage,
}) => {
  return (
    <Modal
      open={modal}
      onClose={() => {
        setModal(false);
        setEmailError(false);
        setEmailChanged(false);
        setNewEmail("");
        setReminderSent(false);
        setReminderMessage("");
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          bgcolor: "background.paper",
          boxShadow: 24,
          borderRadius: "1rem",
          p: 4,
        }}
      >
        <Typography id="modal-title" variant="h4" align="center" component="h2">
          Send Reminder Email to Signee
        </Typography>
        <Box sx={{ my: 1 }}>
          <Typography sx={{ mb: 1 }} variant="h6" align="center">
            {selectedRow?.email}
          </Typography>
          <Typography variant="h6" sx={{ color: "red" }} align="center">
            {reminderMessage}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <LoadingButton
            disabled={reminderSent || changeEmailLoading === true}
            loading={reminderLoading}
            variant="contained"
            color="success"
            fullWidth
            onClick={handleSendReminder}
          >
            Send Email
          </LoadingButton>
        </Box>
        <div
          style={{
            borderBottom: "3px solid #d3d3d3",
            marginTop: "1rem",
            marginBottom: "1rem",
          }}
        ></div>
        <Box>
          <Typography
            id="modal-title"
            variant="h4"
            align="center"
            component="h2"
            sx={{ mb: 2 }}
          >
            Change Signee Email
          </Typography>

          <TextField
            disabled={emailChanged || selectedRow?.reg_visited}
            fullWidth
            label="Signee New Email"
            value={newEmail}
            onChange={handleEmailChange}
            error={emailError}
            helperText={emailError ? "Invalid email address" : ""}
          />
          <Typography mt={1} variant="h6" sx={{ color: "red" }} align="center">
            {selectedRow?.reg_visited && !emailMessage
              ? "The signee's email cannot be changed because the registration process has already begun"
              : emailMessage}
          </Typography>
          <LoadingButton
            loading={changeEmailLoading}
            disabled={
              selectedRow?.email === newEmail ||
              emailChanged ||
              emailError === true ||
              newEmail === ""
            }
            sx={{ mt: "1rem" }}
            variant="contained"
            color="success"
            fullWidth
            onClick={submitEmailChange}
          >
            Change Email
          </LoadingButton>
        </Box>
        <div
          style={{
            borderBottom: "3px solid #d3d3d3",
            marginTop: "1rem",
            marginBottom: "1rem",
          }}
        ></div>
        <Typography align="center" sx={{ color: "#FFC107" }} fontWeight="bold">
          {/* Your email address has been successfully updated, and we've sent out
      the invitation. */}
          {emailText}
        </Typography>
        <Button
          sx={{ mt: 2 }}
          variant="contained"
          fullWidth
          onClick={() => {
            setModal(false);
            setEmailError(false);
            setEmailChanged(false);
            setNewEmail("");
            setReminderSent(false);
            setReminderMessage("");
          }}
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
};

export default DocumentSettingsModal;
