import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  useMediaQuery,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import { userFirstLoginOff } from "../../../store/actions/userActions";
import { subUserFirstLoginOff } from "../../../store/actions/subUserActions";
import axios from "axios";
import { useDispatch } from "react-redux";

const steps = [
  {
    label: "Select Counties",
    description:
      "Choose the counties where you will be creating building permits.",
  },
  {
    label: "Preset Configurations",
    description:
      "Set up default values for notices of commencement and design professionals.",
  },
  {
    label: "Enter Contractor Details",
    description:
      "Input your license number to auto-fill contractor details in your documents.",
  },
  {
    label: "Manage Permit Packages",
    description:
      "Oversee all your permit packages in one place, with options to edit and manage them.",
  },
  {
    label: "Create Permit Package",
    description:
      "Initiate the creation of a permit package by selecting a previously chosen county.",
  },
  {
    label: "My Permit Packages",
    description:
      "View and manage all created permit packages, with options for notarization, recording, and more.",
  },
];

const WelcomeModal = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);

  const handleClose = async () => {
    try {
      setLoading(true);
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/data/first-login-off`,
        {
          currentSession: props?.currentSession,
        },
        { withCredentials: true }
      );

      if (props?.currentSession === "userSession") {
        await dispatch(userFirstLoginOff());
      } else if (props?.currentSession === "subUserSession") {
        await dispatch(subUserFirstLoginOff());
      }
      navigate("/selectcounties");
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (props.open) {
      let stepIndex = 0;
      const interval = setInterval(() => {
        setActiveStep((prev) => {
          if (prev < steps.length - 1) {
            return prev + 1;
          } else {
            clearInterval(interval);
            return prev;
          }
        });
      }, 4000);

      return () => clearInterval(interval);
    }
  }, [props.open]);

  return (
    <Modal
      open={props?.open}
      //   onClose={handleClose}
      sx={{ overflow: "auto" }}
      aria-labelledby="welcome-modal-title"
      aria-describedby="welcome-modal-description"
    >
      <Box
        sx={{
          overflowY: "auto",
          position: "relative",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxHeight: "90%",
          width: 600,
          bgcolor: "background.paper",
          //   border: "2px solid #000",
          borderRadius: "18px",
          boxShadow: 24,
          px: 4,
          py: 2,
        }}
      >
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <img
            alt="Permit Rockstar Logo"
            src="/img/website_img/PermitRockstar.png"
            style={{ maxWidth: "10rem", height: "auto" }}
          />
        </Box>
        <Box
          sx={{
            textAlign: "center",
            mt: 1,
          }}
        >
          <Typography variant="h4" color="primary">
            Welcome aboard, Let's get started
          </Typography>
        </Box>
        <Typography
          id="welcome-modal-description"
          sx={{ mt: 2, fontWeight: "bold" }}
        >
          We are excited to have you on board! Here are a few steps to get you
          started:
        </Typography>
        <Box sx={{ maxHeight: "30rem", overflowY: "auto" }}>
          <Stepper
            activeStep={activeStep}
            orientation="vertical"
            sx={{ mt: 1 }}
          >
            {steps.map((step, index) => (
              <Step
                disabled={false}
                key={index}
                sx={{ cursor: "pointer" }}
                onClick={() => setActiveStep(index)}
              >
                <StepLabel>{step.label}</StepLabel>
                <StepContent>
                  <Typography>{step.description}</Typography>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 4,
            textAlign: "center",
            gap: 1,
          }}
        >
          <LoadingButton
            sx={{ flex: 1 }}
            loading={loading}
            variant="contained"
            color="primary"
            onClick={() => {
              props?.setOpen(false);
            }}
          >
            Get Started
          </LoadingButton>
          <LoadingButton
            loading={loading}
            color="primary"
            onClick={handleClose}
          >
            Don't show again
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default WelcomeModal;
