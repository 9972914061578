import { floridaCounties } from "../../../data/counties";
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Magnifier from "../../customComponents/Magnifier";

const DocumentDetails = ({ formik, contractors }) => {
  const handlePhoneInputChange = (event) => {
    const { value, name } = event.target;

    // Existing code for phone number formatting
    const digitsOnly = value.replace(/\D/g, ""); // Remove non-numeric characters
    let formattedPhoneNumber = "";

    // Add dashes after 3rd and 6th digits
    for (let i = 0; i < digitsOnly.length; i++) {
      if (i === 3 || i === 6) {
        formattedPhoneNumber += "-";
      }
      formattedPhoneNumber += digitsOnly[i];
    }

    formik.setFieldValue("signeePhone", formattedPhoneNumber);
  };

  return (
    <>
      <Grid container>
        <Grid
          item
          sm={6}
          sx={{
            position: "relative",
            backgroundColor: "#e0e0e0",
            padding: "1rem",
            border: "2px dashed gray",
          }}
          xs={12}
        >
          <Magnifier
            src={formik.values.previewUrl}
            lensSize={400}
            zoomFactor={4}
          />
        </Grid>
        <Grid item sm={6} xs={12} sx={{ px: "1rem" }}>
          <Box
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              width: "100%",
            }}
          >
            <Typography variant="h5" align="center" noWrap>
              {formik.values.fileName}
            </Typography>
          </Box>
          <Grid container>
            <Grid item xs={12} sx={{ mt: 3 }}>
              <FormControl
                sx={{ mt: 0.5 }}
                fullWidth
                variant="filled"
                error={Boolean(
                  formik.touched.contractor && formik.errors.contractor
                )}
              >
                <InputLabel
                  sx={
                    formik.touched.contractor && formik.errors.contractor
                      ? { color: "#f04438" }
                      : { color: "auto" }
                  }
                  id="contractor-label"
                >
                  Contractor who will carry on the work
                </InputLabel>
                <Select
                  error={Boolean(
                    formik.touched.contractor && formik.errors.contractor
                  )}
                  labelId="contractor-label"
                  id="contractor"
                  fullWidth
                  name="contractor"
                  value={formik?.values?.contractor}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        marginTop: "1.5px",
                        maxHeight: 350, // Set the desired max height for the dropdown menu
                        width: "auto",
                        maxWidth: "100%",
                      },
                    },
                  }}
                  sx={{
                    maxWidth: "100%", // Set the desired max-width for the input element
                    "& .MuiTypography-body2": {
                      whiteSpace: "wrap", // Prevent text from wrapping or shrinking
                    },
                  }}
                >
                  {contractors?.map((item, index) => (
                    <MenuItem key={index} value={item.licenseNumber}>
                      {item.licenseType} - {item.licenseNumber}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText sx={{ color: "#f04438" }}>
                  {formik.touched.contractor && formik.errors.contractor
                    ? formik.errors.contractor
                    : ""}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sx={{ mt: 3 }}>
              <TextField
                error={Boolean(
                  formik.touched.propertyAddress &&
                    formik.errors.propertyAddress
                )}
                helperText={
                  formik.touched.propertyAddress &&
                  formik.errors.propertyAddress
                }
                name="propertyAddress"
                label="Property Address"
                variant="filled"
                fullWidth
                onBlur={formik.handleBlur}
                value={formik.values.propertyAddress}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid container spacing={1}>
              <Grid item sm={6} xs={12} sx={{ mt: 3 }}>
                <TextField
                  error={Boolean(
                    formik.touched.signeeFirst && formik.errors.signeeFirst
                  )}
                  helperText={
                    formik.touched.signeeFirst && formik.errors.signeeFirst
                  }
                  name="signeeFirst"
                  label={
                    formik.values.reason === "Recording"
                      ? "Owner First Name"
                      : "Signee First Name"
                  }
                  variant="filled"
                  fullWidth
                  onBlur={formik.handleBlur}
                  value={formik.values.signeeFirst}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item sm={6} xs={12} sx={{ mt: 3 }}>
                <TextField
                  error={Boolean(
                    formik.touched.signeeLast && formik.errors.signeeLast
                  )}
                  helperText={
                    formik.touched.signeeLast && formik.errors.signeeLast
                  }
                  name="signeeLast"
                  label={
                    formik.values.reason === "Recording"
                      ? "Owner Last Name"
                      : "Signee Last Name"
                  }
                  variant="filled"
                  fullWidth
                  onBlur={formik.handleBlur}
                  value={formik.values.signeeLast}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item sm={6} xs={12} sx={{ mt: 3 }}>
                <TextField
                  error={Boolean(formik.touched.email && formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  name="email"
                  label={
                    formik.values.reason === "Recording"
                      ? "Owner Email Address"
                      : "Signee Email Address"
                  }
                  variant="filled"
                  fullWidth
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item sm={6} xs={12} sx={{ mt: 3 }}>
                <TextField
                  error={Boolean(
                    formik.touched.signeePhone && formik.errors.signeePhone
                  )}
                  helperText={
                    formik.touched.signeePhone && formik.errors.signeePhone
                  }
                  name="signeePhone"
                  label={
                    formik.values.reason === "Recording"
                      ? "Owner Phone Number"
                      : "Signee Phone Number"
                  }
                  variant="filled"
                  fullWidth
                  onBlur={formik.handleBlur}
                  value={formik.values.signeePhone}
                  onKeyUp={handlePhoneInputChange}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} sx={{ mt: 3 }}>
                <FormHelperText sx={{ color: "#F0463A" }}>
                  {contractors?.length === 0
                    ? "Contractors are not currently available"
                    : ""}
                </FormHelperText>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default DocumentDetails;
