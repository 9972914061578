import {
  Grid,
  Stack,
  Typography,
  Box,
  IconButton,
  useMediaQuery,
  useTheme,
  Button,
  Toolbar,
  Snackbar,
  Alert,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { cityScraperList } from "../../data/cityScraperList";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ReactCardFlip from "react-card-flip";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import Magnifier from "../customComponents/Magnifier";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import { useState, useEffect, useRef, Suspense } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForward from "@mui/icons-material/ArrowForward";
import Footer from "../layout/Footer";

import MoreResultsList from "./MoreResultsList";
import PermitPackagePaymentCard from "../buildingComponents.js/PermitPackagePaymentCard";
import { loadComponent } from "../../utils/loadPermitComponent";
import { loadInitials } from "../../utils/loadPermitInitials";
import CityScraperStartModal from "../modals/CityScraperStartModal";

const OpenDocuments = ({
  designProList,
  setSelectedRow,
  notaryLoading,
  selectedRow,
  up1177,
  creatingDoc,
  setCreatingDoc,
  creatingDocTitle,
  setCreatingDocTitle,
  usingTemplate,
  setUsingTemplate,
  savedProjects,
  setPermitDrawerOpen,
  documentImage,
  setDocumentImage,
  leftValue,
  setLeftValue,
  permitDrawerOpen,
  setTempLoading,
  serviceTiers,
  plan,
  paymentCards,
  rightValue,
  setRightValue,
}) => {
  const dispatch = useDispatch();
  const currentSession = useSelector((state) => state.sessions.currentSession);
  const currentUser = useSelector((state) => state[currentSession].user);

  const theme = useTheme();
  const leftGridRef = useRef(null);
  const RightGridRef = useRef(null);
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const [createDocLoading, setCreateDocLoading] = useState(false);
  const [consentAlert, setConsentAlert] = useState(false);

  //! NOC STATES /////////////////////////////////////
  const [nocStates, setNocStates] = useState({
    isNocPreview: false,
    previewLoading: false,
    createNocLoading: false,
    isCreatingNoc: false,
    dynamicValues: {},
    isSuretyRadio: false,
    emptyPermitConfirmed: false,
    pdfProgressModalOpen: false,
    inQueueMessage: "",
    inQueueDrawer: false,
    emptyPermitOpen: false,
    emptyPermitConfirmed: false,
    items: [],
    dotStates: {},
    completed: {},
    intervalIds: {},
    isFinished: false,
    initializeQueue: true,
    isMoreError: false,
    isFailedError: false,
    waitingList: false,
    cardFlip: false,
    moreResultRow: null,
    editNocLoading: false,
    isEditNoc: false,
  });
  //! /////////////////////////////////////////////
  //! ROOFING_PERMIT /////////////////////////////////////
  const [roofingStates, setRoofingStates] = useState({
    isCreatingRoofing: false,
  });
  //! /////////////////////////////////////////////
  //! BUILDING_PERMIT /////////////////////////////////////
  const [buildingStates, setBuildingStates] = useState({
    designProListState: [],
    projectContactListState: [],
    feeSimpleListState: [],
    bondingCompanyListState: [],
    mortgageLenderListState: [],
    addingNewDesignPro: false,
    submitDisabled: false,
    projectSubmitDisabled: false,
    feeSimpleSubmitDisabled: false,
    bondingSubmitDisabled: false,
    mortgageSubmitDisabled: false,
    addingNewProject: false,
    addingNewFeeSimple: false,
    addingNewBonding: false,
    addingNewMortgage: false,
    workDesc: "",
    createBuildingLoading: false,
    isCreatingBuilding: false,
  });
  //! /////////////////////////////////////////////
  //! PAIS STATES ////////////////////////////////
  const [paisStates, setPaisStates] = useState({
    loadingData: false,
    paisSearching: false,
    productsData: null,
    manufacturerOptions: [],
    flNumberOptions: [],
    manDetailsModal: false,
    descriptionOptions: [],
    modelOptions: [],
    addingProduct: false,
    editingProduct: false,
    expandedCategory: false,
    editExpanded: false,
    detailsExpanded: false,
    addProductExpanded: false,
    templateExpanded: true,
    editData: false,
    addNewWaiting: true,
    useTemplate: false,
    tempContinue: true,
    saveIsReady: false,
    saveTemplateLoading: false,
    formAccDisabled: false,
    snackbarOpen: false,
    paisSelectedTemplate: {
      id: "",
      name: "",
    },
    savePaisSelectedTemplate: {
      id: "",
      name: "",
    },
    savePaisName: "",
    templateError: "",
    templateLoading: false,
    openModal: false,
    descriptionValue: "",
    noProductError: "",
    resettingData: false,
    startDeleteProduct: false,
    category: "",
    subCategory: "",
    isCreatingPais: false,
  });
  //! /////////////////////////////////////////////
  //! OBTAIN_PERMIT STATES ////////////////////////////////
  const [obtainPermitStates, setObtainPermitStates] = useState({
    permissionLoading: false,
    isCreatingObtainPermit: false,
  });
  //! /////////////////////////////////////////////
  //! ENERGY STATES ////////////////////////////////
  const [energyStates, setEnergyStates] = useState({
    isCreatingEnergy: false,
  });
  //! /////////////////////////////////////////////
  //! ASBESTOS STATES ////////////////////////////////
  const [asbestosStates, setAsbestosStates] = useState({
    isCreatingAsbestos: false,
  });
  //! /////////////////////////////////////////////
  //! OWNERSHIP STATES ////////////////////////////////
  const [ownershipStates, setOwnershipStates] = useState({
    isCreatingOwnership: false,
  });
  //! /////////////////////////////////////////////
  const [snackOpen, setSnackOpen] = useState(false);
  const [descriptionError, setDescriptionError] = useState("");
  const [showSelectedCity, setShowSelectedCity] = useState("");
  const [LoadedComponent, setLoadedComponent] = useState(null);
  const [initials, setInitials] = useState(null);
  const [formError, setFormError] = useState(false);
  const [cityModalOpen, setCityModalOpen] = useState(false);

  // useEffect(() => {
  //   if (
  //     leftValue === 0 &&
  //     !selectedRow?.noc_created &&
  //     !selectedRow?.city_scraper?.is_accepted &&
  //     selectedRow?.documents?.building_permit &&
  //     cityScraperList.includes(selectedRow?.city)
  //   ) {
  //     if (cityModalOpen !== true) {
  //       setCityModalOpen(true);
  //     }
  //   }
  // }, []);

  useEffect(() => {
    const load = async () => {
      const { default: Component } = await loadComponent(
        creatingDoc,
        selectedRow?.county,
        selectedRow?.city
      );
      const Initials = await loadInitials(
        creatingDoc,
        selectedRow?.county,
        selectedRow?.city
      );

      setLoadedComponent(() => Component);
      setInitials(Initials);
    };

    if (creatingDoc && selectedRow?.county) {
      // Only require county, as city may be optional
      load();
    }
  }, [creatingDoc, selectedRow?.county, selectedRow?.city]);

  useEffect(() => {
    if (descriptionError) {
      const interv = setTimeout(() => {
        setDescriptionError(false);
      }, 6000);
      return () => clearTimeout(interv);
    }
  }, [descriptionError]);

  useEffect(() => {
    if (leftGridRef.current) {
      leftGridRef.current.scrollTo(0, 0);
    }
  }, [documentImage]);

  useEffect(() => {
    if (RightGridRef.current) {
      RightGridRef.current.scrollTo(0, 0);
    }
  }, [leftValue]);

  const handleIMG = () => {
    return (
      <>
        {documentImage?.map((item, index) => {
          const src = item.startsWith("https")
            ? item
            : `${process.env.REACT_APP_URL}${item}`;
          return (
            <Magnifier key={index} src={src} lensSize={400} zoomFactor={4} />
          );
        })}
      </>
    );
  };

  const handleIconButton = () => {
    if (buildingStates?.submitDisabled || buildingStates?.addingNewDesignPro) {
      return;
    }
    if (creatingDoc === "pais") {
      setPaisStates((prev) => ({
        ...prev,
        resettingData: true,
      }));
    }
    if (creatingDoc === "noc") {
      setNocStates((prevState) => ({
        ...prevState,
        cardFlip: false,
      }));
    }

    if (selectedRow?.city_scraper?.is_accepted) {
      setRightValue(4);
    } else if (selectedRow?.permit_package_created) {
      if (rightValue !== 2) {
        setRightValue(2);
      }
    }
    setPermitDrawerOpen(false);
  };

  const handleNavigate = async (direction) => {
    // try {
    let newIndex;
    let nextForm;
    if (direction === "back") {
      newIndex = leftValue - 1;
      // setBackLoading(true);
    } else {
      // setForwardLoading(true);
      newIndex = leftValue + 1;
    }

    if (direction === "back") {
      nextForm = selectedRow?.selected_documents[leftValue - 1];
    } else {
      nextForm = selectedRow?.selected_documents[leftValue + 1];
    }

    setCreatingDoc(nextForm?.name);
    setCreatingDocTitle(nextForm?.label);
    setLeftValue(newIndex);
    setNocStates((prevState) => ({
      ...prevState,
      cardFlip: false,
    }));
  };

  const snackHandleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
  };

  const consentHandleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setConsentAlert(false);
  };

  return (
    <>
      <Grid
        container
        sx={{
          display: "flex",
          overflow: "hidden",
          width: "100%",
        }}
      >
        {/* LEFT SIDE */}
        <Grid
          ref={leftGridRef}
          item
          md={!up1177 ? 6 : 6}
          sx={{ maxHeight: "90vh", overflow: "auto", position: "relative" }}
        >
          {creatingDoc === "noc" && selectedRow?.documents?.noc?.more ? (
            <Box>
              <IconButton
                sx={{
                  display: "block",
                  position: "absolute",
                  left: 20,
                  top: 20,
                  zIndex: 2,
                  backgroundColor: "#fff",
                }}
                onClick={() =>
                  setNocStates((prevState) => ({
                    ...prevState,
                    cardFlip: !prevState.cardFlip,
                  }))
                }
              >
                {nocStates.cardFlip === true ? (
                  <CloseIcon />
                ) : (
                  <FormatListBulletedIcon />
                )}
              </IconButton>
              <ReactCardFlip
                isFlipped={nocStates.cardFlip}
                flipDirection="horizontal"
                flipSpeedBackToFront={1}
                flipSpeedFrontToBack={1}
              >
                <Box>{handleIMG()}</Box>
                <Box sx={{ height: "100vh" }}>
                  <MoreResultsList
                    addressRow={selectedRow?.documents?.noc?.more}
                    handleRowClick={(row) => {
                      setNocStates({ ...nocStates, moreResultRow: row });
                    }}
                  />
                </Box>
              </ReactCardFlip>
            </Box>
          ) : (
            <Box>{handleIMG()}</Box>
          )}
        </Grid>

        {/* RIGHT SIDE */}
        <Grid
          item
          md={!up1177 ? 6 : 6}
          sx={{
            position: "relative",
            mb: 1,
            mt: 2,
            height: "100vh",

            borderLeft: "4px solid #f3f3f3",
          }}
        >
          {/* HEADER */}
          <IconButton
            disabled={
              createDocLoading ||
              paisStates?.loadingData ||
              paisStates?.saveTemplateLoading ||
              nocStates.editNocLoading
            }
            onClick={handleIconButton}
            sx={{ position: "absolute", right: 10 }}
          >
            <CloseIcon disabled={notaryLoading} />
          </IconButton>
          <Snackbar
            open={consentAlert}
            autoHideDuration={26000}
            onClose={consentHandleClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={consentHandleClose}
              severity="success"
              sx={{
                width: "100%",
                backgroundColor: "#388e3c", // Green shade for success
                color: "#fff",
                fontWeight: "bold",
              }}
              iconMapping={{
                success: <CheckCircleIcon sx={{ color: "#fff" }} />, // Changed to success icon
              }}
            >
              Consent Accepted! The city permitting process will automatically
              start when a building permit is created.
            </Alert>
          </Snackbar>
          <Box
            sx={{
              backgroundColor: "#fff",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {serviceTiers?.permit_package !== "Unlimited" &&
            !selectedRow?.payments?.paid &&
            !selectedRow?.payments?.invoice_num &&
            creatingDoc !== "noc" ? (
              <Typography mb={2}></Typography>
            ) : (
              <>
                <Typography color="primary" mx={1} variant="h5">
                  Creating {creatingDocTitle}
                </Typography>
                <Typography color="primary" variant="h6">
                  {leftValue + 1} / {selectedRow?.selected_documents?.length}
                </Typography>
              </>
            )}

            <Typography
              variant="h6"
              sx={{
                mb: 0.5,
                mt: 0,
                visibility:
                  serviceTiers?.permit_package !== "Unlimited" &&
                  !selectedRow?.payments?.paid &&
                  !selectedRow?.payments?.invoice_num &&
                  creatingDoc !== "noc"
                    ? "hidden"
                    : undefined,
              }}
              textAlign="center"
            >
              {selectedRow?.county} County
            </Typography>
            {creatingDoc === "pais" && (
              <Typography align="center" fontWeight="bold" variant="body2">
                Product Approval form is saved when you load a template, add a
                product or edit/remove a product
              </Typography>
            )}
          </Box>
          {/* TEXTFIELD/CARD */}
          <Box
            ref={RightGridRef}
            sx={{
              height:
                creatingDoc === "pais" && paisStates?.paisSearching
                  ? "calc(100% - 365px)"
                  : "calc(100% - 300px)",
              overflow: "auto",
              px: 0.3,
              mt: 0,
              pb: 1.5,
              pt: 0.3,
              borderTop: "4px solid #f3f3f3",
              borderBottom: "4px solid #f3f3f3",
            }}
          >
            <Snackbar
              open={snackOpen}
              autoHideDuration={6000}
              onClose={snackHandleClose}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <Alert
                onClose={snackHandleClose}
                severity="success"
                sx={{ width: "100%" }}
              >
                Payment Successful. Thank you
              </Alert>
            </Snackbar>
            {serviceTiers?.permit_package !== "Unlimited" &&
            !selectedRow?.payments?.paid &&
            !selectedRow?.payments?.invoice_num &&
            creatingDoc !== "noc" ? (
              <PermitPackagePaymentCard
                plan={plan}
                serviceTiers={serviceTiers}
                county={selectedRow?.county}
                paymentCards={paymentCards}
                currentSession={currentSession}
                task_id={selectedRow?.task_id}
                setSelectedRow={setSelectedRow}
                selectedCity={showSelectedCity}
                setSelectedCity={setShowSelectedCity}
                setSnackOpen={setSnackOpen}
              />
            ) : //! RENDER THE DYNAMIC COMPONENT //////////////////////////////////
            LoadedComponent ? (
              <Suspense fallback={<div>Loading...</div>}>
                <LoadedComponent
                  //! STATES
                  nocStates={nocStates}
                  setNocStates={setNocStates}
                  roofingStates={roofingStates}
                  setRoofingStates={setRoofingStates}
                  buildingStates={buildingStates}
                  setBuildingStates={setBuildingStates}
                  paisStates={paisStates}
                  setPaisStates={setPaisStates}
                  obtainPermitStates={obtainPermitStates}
                  setObtainPermitStates={setObtainPermitStates}
                  energyStates={energyStates}
                  setEnergyStates={setEnergyStates}
                  asbestosStates={asbestosStates}
                  setAsbestosStates={setAsbestosStates}
                  ownershipStates={ownershipStates}
                  setOwnershipStates={setOwnershipStates}
                  //! //////////////////////////////////////////
                  leftValue={leftValue}
                  currentSession={currentSession}
                  setDocumentImage={setDocumentImage}
                  permitDrawerOpen={permitDrawerOpen}
                  setPermitDrawerOpen={setPermitDrawerOpen}
                  setSelectedRow={setSelectedRow}
                  selectedRow={selectedRow}
                  setTempLoading={setTempLoading}
                  designProList={designProList}
                  savedProjects={savedProjects}
                  createDocLoading={createDocLoading}
                  setCreateDocLoading={setCreateDocLoading}
                  creatingDoc={creatingDoc}
                  initials={initials}
                  usingTemplate={usingTemplate}
                  setUsingTemplate={setUsingTemplate}
                  setDescriptionError={setDescriptionError}
                  setCityModalOpen={setCityModalOpen}
                  currentUser={currentUser}
                />
              </Suspense>
            ) : null}
            {/* ************************************** */}
          </Box>
          {/* {creatingDoc ? ( */}
          <Toolbar
            sx={{
              px: 0,
              py: 0.5,
              position: "fixed",
              bottom: 88.5,
              left: 0,
              right: 0,
              opacity: 1,
            }}
          >
            <Grid container>
              <Grid item xs={!up1177 ? 6 : 6}></Grid>
              <Grid item xs={!up1177 ? 6 : 6}>
                <Typography
                  sx={{ backgroundColor: "#fff" }}
                  mt={1}
                  ml={1}
                  pt={2}
                  color="error"
                  align="center"
                >
                  {descriptionError}
                  {buildingStates?.submitDisabled ||
                  buildingStates?.addingNewDesignPro
                    ? "Please save the new design professional after filling all required fields before proceeding."
                    : buildingStates?.submitDisabled ||
                      buildingStates?.addingNewProject
                    ? "Please save the new project contact after filling all required fields before proceeding."
                    : buildingStates?.submitDisabled ||
                      buildingStates?.addingNewFeeSimple
                    ? "Please save the new fee simple title holder after filling all required fields before proceeding."
                    : buildingStates?.submitDisabled ||
                      buildingStates?.addingNewBonding
                    ? "Please save the new bonding company after filling all required fields before proceeding."
                    : buildingStates?.submitDisabled ||
                      buildingStates?.addingNewMortgage
                    ? "Please save the new mortgage lender after filling all required fields before proceeding."
                    : buildingStates?.submitDisabled &&
                      "To continue, you must complete the design professional modifications/additions"}
                </Typography>

                {paisStates?.paisSearching && creatingDoc === "pais" && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      ml: "24px",
                      alignItems: "center",

                      gap: 1,
                      mb: 1,
                    }}
                  >
                    <LoadingButton
                      onClick={(e) => {
                        e.stopPropagation();
                        if (Boolean(paisStates?.editData)) {
                          setPaisStates((prev) => ({
                            ...prev,
                            startDeleteProduct: true,
                          }));
                        } else {
                          setPaisStates((prev) => ({
                            ...prev,
                            resettingData: true,
                          }));
                        }
                      }}
                      color="error"
                      loading={paisStates?.loadingData}
                      disabled={
                        !paisStates?.category || !paisStates?.subCategory
                      }
                      variant="outlined"
                    >
                      {Boolean(paisStates?.editData)
                        ? "Delete Product"
                        : "New Search"}
                    </LoadingButton>
                    <LoadingButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setPaisStates((prev) => ({
                          ...prev,
                          isCreatingPais: true,
                        }));
                      }}
                      color="success"
                      loading={paisStates?.loadingData}
                      disabled={
                        !paisStates?.category || !paisStates?.subCategory
                      }
                      variant="contained"
                    >
                      {Boolean(paisStates?.editData)
                        ? "Edit Product"
                        : "Add Product"}
                    </LoadingButton>
                    {Boolean(paisStates?.editData) && (
                      <LoadingButton
                        onClick={(e) => {
                          e.stopPropagation();
                          setPaisStates((prev) => ({
                            ...prev,
                            resettingData: true,
                          }));
                        }}
                        color="primary"
                        loading={paisStates?.loadingData}
                        disabled={
                          !paisStates?.category || !paisStates?.subCategory
                        }
                        variant="outlined"
                      >
                        Cancel Edit
                      </LoadingButton>
                    )}
                  </Box>
                )}
                <Box
                  sx={{
                    borderTop:
                      paisStates?.paisSearching && creatingDoc === "pais"
                        ? "4px solid #f3f3f3"
                        : undefined,
                    display: "flex",
                    justifyContent: "space-between",
                    ml: "24px",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  {serviceTiers?.permit_package !== "Unlimited" &&
                  !selectedRow?.payments?.paid &&
                  !selectedRow?.payments?.invoice_num &&
                  creatingDoc !== "noc" ? (
                    <></>
                  ) : (
                    <LoadingButton
                      onClick={() => handleNavigate("back")}
                      disabled={
                        leftValue === 0 ||
                        createDocLoading ||
                        paisStates?.loadingData ||
                        paisStates?.saveTemplateLoading ||
                        buildingStates?.addingNewDesignPro ||
                        buildingStates?.addingNewProject ||
                        buildingStates?.addingNewFeeSimple ||
                        buildingStates?.addingNewBonding ||
                        buildingStates?.addingNewMortgage
                      }
                      sx={{
                        visibility: leftValue === 0 ? "hidden" : undefined,
                      }}
                      variant="contained"
                      startIcon={<ArrowBackIcon />}
                    >
                      Back
                    </LoadingButton>
                  )}
                  {serviceTiers?.permit_package !== "Unlimited" &&
                  !selectedRow?.payments?.paid &&
                  !selectedRow?.payments?.invoice_num &&
                  creatingDoc !== "noc" ? (
                    <></>
                  ) : (
                    <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                      {creatingDoc === "noc" && !selectedRow?.noc_created && (
                        <LoadingButton
                          onClick={() =>
                            setNocStates({
                              ...nocStates,
                              previewLoading: true,
                              isNocPreview: true,
                              cardFlip: false,
                            })
                          }
                          disabled={nocStates.createNocLoading}
                          loading={nocStates.previewLoading}
                          variant="contained"
                          color="warning"
                          sx={{
                            mt: 2,
                            mb: isMd ? 1 : 2,
                          }}
                          endIcon={<EditIcon />}
                        >
                          Create Preview
                        </LoadingButton>
                      )}

                      <Button
                        disabled={
                          createDocLoading ||
                          obtainPermitStates?.permissionLoading ||
                          paisStates?.loadingData ||
                          nocStates.previewLoading ||
                          nocStates.editNocLoading
                        }
                        sx={{ mt: 2, mb: isMd ? 1 : 2, minWidth: "10rem" }}
                        color="primary"
                        variant="outlined"
                        onClick={handleIconButton}
                      >
                        Main Menu
                      </Button>

                      {creatingDoc === "pais" ? (
                        <LoadingButton
                          disabled={
                            createDocLoading ||
                            obtainPermitStates?.permissionLoading ||
                            paisStates?.loadingData ||
                            (creatingDoc === "pais" && !paisStates?.saveIsReady)
                          }
                          endIcon={
                            up1177 && creatingDoc !== "pais" ? (
                              <SendIcon color="#fff" />
                            ) : undefined
                          }
                          loading={paisStates?.saveTemplateLoading}
                          color="warning"
                          // onClick={handleSaveTemplate}
                          onClick={() => {
                            setPaisStates((prev) => ({
                              ...prev,
                              openModal: true,
                            }));
                          }}
                          sx={{
                            // visibility:
                            //   creatingDoc === "pais" ? "hidden" : undefined,
                            mt: 2,
                            mb: isMd ? 1 : 2,
                            px: 1,
                            minWidth: "100px",
                          }}
                          variant="contained"
                        >
                          SAVE TEMPLATE
                        </LoadingButton>
                      ) : (
                        <LoadingButton
                          disabled={
                            buildingStates?.submitDisabled ||
                            buildingStates?.addingNewDesignPro ||
                            buildingStates?.addingNewProject ||
                            buildingStates?.addingNewFeeSimple ||
                            buildingStates?.addingNewBonding ||
                            buildingStates?.addingNewMortgage ||
                            nocStates.previewLoading
                          }
                          // endIcon={up1177 ? <SendIcon color="#fff" /> : undefined}
                          loading={
                            createDocLoading ||
                            obtainPermitStates?.permissionLoading ||
                            nocStates.createNocLoading ||
                            nocStates.editNocLoading
                          }
                          color={
                            selectedRow?.documents?.[creatingDoc]?.is_created
                              ? "warning"
                              : "success"
                          }
                          onClick={() => {
                            if (creatingDoc === "obtain_permit") {
                              setObtainPermitStates((prev) => ({
                                ...prev,
                                isCreatingObtainPermit: true,
                              }));
                            } else if (creatingDoc === "energy") {
                              setEnergyStates((prev) => ({
                                ...prev,
                                isCreatingEnergy: true,
                              }));
                            } else if (
                              creatingDoc === "noc" &&
                              !selectedRow?.noc_created
                            ) {
                              setNocStates({
                                ...nocStates,
                                createNocLoading: true,
                                isCreatingNoc: true,
                                cardFlip: false,
                              });
                            } else if (
                              creatingDoc === "noc" &&
                              selectedRow?.noc_created
                            ) {
                              setNocStates({
                                ...nocStates,
                                editNocLoading: true,
                                isEditNoc: true,
                              });
                            } else if (creatingDoc === "roofing") {
                              setRoofingStates((prev) => ({
                                ...prev,
                                isCreatingRoofing: true,
                              }));
                            } else if (creatingDoc === "asbestos") {
                              setAsbestosStates((prev) => ({
                                ...prev,
                                isCreatingAsbestos: true,
                              }));
                            } else if (creatingDoc === "ownership") {
                              setOwnershipStates((prev) => ({
                                ...prev,
                                isCreatingOwnership: true,
                              }));
                            } else {
                              setBuildingStates({
                                ...buildingStates,
                                // createBuildingLoading: true,
                                isCreatingBuilding: true,
                              });
                              // formik?.handleSubmit();
                            }
                          }}
                          sx={{
                            // visibility:
                            //   creatingDoc === "pais" ? "hidden" : undefined,
                            mt: 2,
                            mb: isMd ? 1 : 2,
                          }}
                          endIcon={
                            selectedRow?.documents?.[creatingDoc]
                              ?.is_created ? (
                              <EditIcon />
                            ) : undefined
                          }
                          variant="contained"
                        >
                          {!selectedRow?.selected_documents[leftValue]?.created
                            ? "Create Document"
                            : "Update Document"}
                        </LoadingButton>
                      )}
                    </Box>
                  )}
                  {serviceTiers?.permit_package !== "Unlimited" &&
                  !selectedRow?.payments?.paid &&
                  !selectedRow?.payments?.invoice_num &&
                  creatingDoc !== "noc" ? (
                    <></>
                  ) : (
                    <LoadingButton
                      sx={{
                        visibility:
                          selectedRow?.selected_documents.length === 1
                            ? "hidden"
                            : undefined,
                        minWidth: "100px",
                      }}
                      color={
                        leftValue === selectedRow?.selected_documents.length - 1
                          ? "success"
                          : "primary"
                      }
                      onClick={() => {
                        if (
                          leftValue ===
                          selectedRow?.selected_documents.length - 1
                        ) {
                          handleIconButton();
                        } else {
                          handleNavigate("forward");
                        }
                      }}
                      disabled={
                        !selectedRow?.selected_documents[leftValue]?.created ||
                        createDocLoading ||
                        paisStates?.loadingData ||
                        paisStates?.saveTemplateLoading ||
                        buildingStates?.addingNewDesignPro ||
                        buildingStates?.addingNewProject ||
                        buildingStates?.addingNewFeeSimple ||
                        buildingStates?.addingNewBonding ||
                        buildingStates?.addingNewMortgage ||
                        nocStates.previewLoading ||
                        nocStates.editNocLoading
                      }
                      variant="contained"
                      endIcon={
                        leftValue ===
                        selectedRow?.selected_documents.length -
                          1 ? undefined : (
                          <ArrowForward />
                        )
                      }
                    >
                      {leftValue === selectedRow?.selected_documents.length - 1
                        ? "Finish"
                        : "Next"}
                    </LoadingButton>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Toolbar>
        </Grid>
      </Grid>
      <Footer height="60px" />
      <CityScraperStartModal
        open={cityModalOpen}
        setOpen={setCityModalOpen}
        selectedRow={selectedRow}
        setConsentAlert={setConsentAlert}
        setNocStates={setNocStates}
      />
    </>
  );
};

export default OpenDocuments;
