import React from "react";
import { Paper, Typography, Box, Pagination } from "@mui/material";
import SwipeableViews from "react-swipeable-views";

function CreditCardDisplay({ cards, activeStep, setActiveStep }) {
  return (
    <Box sx={{ width: "100%", overflow: "hidden" }}>
      <SwipeableViews
        axis={"x"}
        index={activeStep}
        onChangeIndex={(step) => setActiveStep(step)}
        enableMouseEvents
      >
        {cards.map((card, index) => (
          <Paper
            key={index}
            elevation={4}
            sx={{
              padding: 3,
              margin: 2,
              textAlign: "center",
            }}
          >
            <Typography variant="h6" component="div">
              {`**** **** **** ${card?.cardNum.slice(-4)}`}
            </Typography>
            <Typography color="text.secondary">{card?.cardName}</Typography>
            <Typography color="text.secondary">
              {`Exp: ${card?.expires}`}
            </Typography>
          </Paper>
        ))}
      </SwipeableViews>
    </Box>
  );
}

export default CreditCardDisplay;
