import React, { useCallback, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Modal,
  Box,
  Typography,
  Button,
  FormHelperText,
  Grid,
  Card,
  CardMedia,
  CardContent,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { getCombinedList } from "../../functions/availablePermitForms";
import {
  addAvailablePermitForms,
  setPrevCityCounty,
  updatePermitPackage,
} from "../../store/actions/dataActions";
import axios from "axios";
import { useDispatch } from "react-redux";
import DocumentAddedDialog from "./DocumentAddedDialog";

function AddSinglePermitDocumentModal({
  open,
  setOpen,
  onConfirm,
  confirmLoading,
  selectedRow,
  availablePermitForms,
  currentSession,
  prevCityCounty,
  setRightValue,
}) {
  const dispatch = useDispatch();
  const [availableForms, setAvailableForms] = useState([]);
  const [oldSelectedForms, setOldSelectedForms] = useState([]);
  const [newSelectedForms, setNewSelectedForms] = useState([]);
  const [addNewDocLoading, setAddNewDocLoading] = useState(false);
  const [countyLoading, setCountyLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleAddingDoc = async () => {
    try {
      setAddNewDocLoading(true);
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/building/add-new-permit-doc`,
        {
          task_id: selectedRow?.task_id,
          currentSession: currentSession,
          newSelectedForms: newSelectedForms,
        },
        {
          withCredentials: true,
        }
      );
      if (resp?.data?.success) {
        await dispatch(updatePermitPackage(resp?.data?.data));
        setDialogOpen(true);
      }
    } catch (error) {
    } finally {
      setAddNewDocLoading(false);
    }
  };

  useEffect(() => {
    const loadPermitForms = async () => {
      try {
        setCountyLoading(true);
        const resp = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/data/get-county-permit`,
          { currentSession: currentSession, county: selectedRow?.county },
          {
            withCredentials: true,
          }
        );
        await dispatch(addAvailablePermitForms(resp?.data?.forms));
        await dispatch(
          setPrevCityCounty({
            city: selectedRow?.city,
            county: selectedRow?.county,
          })
        );

        return resp?.data?.forms;
      } catch (e) {
        // Handle error if needed
        console.error("Failed to load permit forms", e);
        return [];
      } finally {
        setCountyLoading(false);
      }
    };

    const processPermitForms = async () => {
      const loadedForms =
        !availablePermitForms ||
        availablePermitForms?.length === 0 ||
        prevCityCounty?.county === "" ||
        prevCityCounty?.county !== selectedRow?.county ||
        prevCityCounty?.city !== selectedRow?.city
          ? await loadPermitForms()
          : availablePermitForms;

      const oldForms =
        selectedRow?.selected_documents?.map((selected_doc) => {
          return selected_doc.name;
        }) || [];
      setOldSelectedForms(oldForms);

      const forms = getCombinedList(
        loadedForms,
        selectedRow?.county,
        selectedRow?.city,
        newSelectedForms,
        setNewSelectedForms,
        oldForms
      );
      setAvailableForms(forms?.permits);
    };

    if (selectedRow?.city && selectedRow?.county) {
      processPermitForms();
    }
  }, [selectedRow?.city, selectedRow?.county]);

  const handleCardClick = useCallback((item) => {
    setNewSelectedForms((prevSelectedForms) => {
      const isAlreadySelected = prevSelectedForms.includes(item?.name);
      const updatedSelectedForms = isAlreadySelected
        ? prevSelectedForms.filter((formName) => formName !== item?.name)
        : [...prevSelectedForms, item?.name];

      return updatedSelectedForms;
    });

    // props?.setFormDisplay(true);
  }, []);

  return (
    <Modal open={open} sx={{ overflow: "auto" }}>
      <Box
        sx={{
          overflowY: "none",
          position: "relative",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxHeight: "90%",
          maxWidth: "50rem",
          bgcolor: "background.paper",
          //   border: "2px solid #000",
          borderRadius: "18px",
          boxShadow: 24,
          px: 4,
          py: 2,
        }}
      >
        <Box
          sx={{
            p: 1,
            position: "absolute",

            width: "30rem",
            height: "8rem",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)", // Center the box horizontally and vertically
            zIndex: 9999,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <DocumentAddedDialog
              open={dialogOpen}
              setOpen={setDialogOpen}
              closeParent={setOpen}
              setRightValue={setRightValue}
            />
          </Box>
        </Box>
        <Typography
          id="modal-modal-title"
          variant="h4"
          align="center"
          component="h2"
          color="primary"
        >
          Select Additional Documents
        </Typography>
        <Box sx={{ position: "absolute", right: 10, top: 10 }}>
          <IconButton
            disabled={addNewDocLoading}
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography
          color="primary"
          align="center"
          id="modal-modal-description"
          sx={{ fontWeight: "bold" }}
        >
          From the list of available documents, you can add more documents to
          your permit package.
        </Typography>

        <Box
          sx={{
            maxHeight: "35.3rem", // Adjust the max height as needed
            overflowY: "auto",
            height: "calc(100vh - 410px)",
            mb: 5,
          }}
        >
          {countyLoading ? (
            <Box
              sx={{
                display: "flex",
                width: "100%",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <CircularProgress color="warning" size="10rem" mt={5} />
            </Box>
          ) : availableForms?.length > 0 ? (
            <Grid container spacing={2} mt={2} mb={2}>
              {availableForms?.map((item, index) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                  <Card
                    sx={{
                      cursor: item?.name === "noc" ? "normal" : "pointer",
                      border: "3px solid #1976d2",
                      p: 1,
                      minHeight: "14rem",
                      backgroundColor: newSelectedForms.includes(item?.name)
                        ? "rgba(56, 142, 60, 0.3)"
                        : "#fff",
                    }}
                    onClick={() => {
                      if (item?.name === "noc") {
                        return;
                      } else {
                        handleCardClick(item);
                      }
                    }}
                  >
                    <CardMedia
                      component="img"
                      height="140"
                      image={`${process.env.REACT_APP_URL}${item?.cover_img}`}
                      alt={item.label}
                    />
                    <CardContent className="bottomCard" sx={{ px: 0, pt: 1 }}>
                      <Typography
                        align="center"
                        variant="body2"
                        color="primary"
                        fontWeight="bold"
                        component="div"
                      >
                        {item.label}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography align="center" mt={10} variant="h6" color="error">
              We do not have any forms available at this time.
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            backgroundColor: "#fff",
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100%",
            borderTop: "3px solid #d3d3d3",
            py: 1,
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <LoadingButton
              onClick={handleAddingDoc}
              loading={addNewDocLoading}
              disabled={newSelectedForms.length === 0}
              color="success"
              variant="contained"
              sx={{ width: "10rem" }}
            >
              Submit
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

export default AddSinglePermitDocumentModal;
