import React, { useState } from "react";
import { Box, Button, Collapse, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const CollapsiblePaymentCard = ({
  paymentCards,
  currentIndex,
  handleDotClick,
  scrollToBottom,
  screenLimit,
  documentUploader,
  tabletSize,
  mobileSize,
  fromPayment,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  const getCardName = (cardName) => {
    if (cardName.length > 15) {
      return cardName.substring(0, 15) + "...";
    }
    return cardName;
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "100%",
      }}
    >
      {!fromPayment && (
        <Button
          sx={{ mb: 1, mx: 2 }}
          endIcon={isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          onClick={() => {
            toggleCollapse();
            setTimeout(() => {
              scrollToBottom();
            }, 250);
          }}
          variant={isOpen ? "contained" : "outlined"}
        >
          Select / Change Payment Card
        </Button>
      )}

      <Collapse in={fromPayment ? true : isOpen}>
        {!fromPayment && (
          <Box sx={{ textAlign: "center", mb: 1 }}>
            {paymentCards?.map((_, index) => (
              <span
                key={index}
                onClick={() => handleDotClick(index)}
                style={{
                  cursor: "pointer",
                  margin: "0 5px",
                  width: "10px",
                  height: "10px",
                  backgroundColor: index === currentIndex ? "#333" : "#ccc",
                  display: "inline-block",
                  borderRadius: "50%",
                }}
              />
            ))}
          </Box>
        )}

        {paymentCards?.map((card, index) => (
          <Box
            key={index}
            className="parent"
            sx={{
              width:
                documentUploader && mobileSize
                  ? "100%"
                  : documentUploader && tabletSize && !fromPayment
                  ? "60%"
                  : documentUploader && tabletSize && !fromPayment
                  ? "70%"
                  : screenLimit
                  ? "80%"
                  : fromPayment
                  ? "100%"
                  : "50%", // Adjust width as needed
              maxWidth: documentUploader ? "40rem" : "25rem",
              margin: "auto", // Center the Box
              position: "relative",
              display: index === currentIndex ? "flex" : "none",
              justifyContent: "center", // Center contents horizontally
            }}
          >
            <img
              src="/img/website_img/bankcardEmpty.jpg"
              style={{
                width: "100%", // Make image fill the Box
                borderRadius: "20px",
                margin: "auto", // Center the image
              }}
            />
            {card?.default && (
              <Typography
                className="child"
                sx={{
                  position: "absolute",
                  color: "#fff",
                  position: "absolute",
                  transform: "translate(-2%,-40%)",
                  top: screenLimit ? "10%" : "8%",
                  right: "4%",
                  fontSize: "1rem",
                }}
              >
                <CheckCircleOutlineIcon
                  color="#fff"
                  width={20}
                  style={{
                    verticalAlign: "middle",
                    marginBottom: "2px",
                  }}
                />{" "}
                Default Payment Card
              </Typography>
            )}

            <Typography
              className="child"
              sx={{
                position: "absolute",
                color: "#fff",
                position: "absolute",
                transform: "translate(-2%,-40%)",
                top: "50%",
                left: "5%",
                fontSize: mobileSize ? "1.8rem" : "2rem",
              }}
            >
              {card.cardNum}
            </Typography>
            <Typography
              className="child"
              sx={{
                position: "absolute",
                color: "#fff",
                transform: "translate(-2%,-40%)",
                top: "85%",
                left: "2.5%",
                fontSize: mobileSize ? "0.8rem" : "1rem",
              }}
            >
              {getCardName(card.cardName)}
            </Typography>
            <Typography
              sx={{
                position: "absolute",
                color: "#fff",
                transform: "translate(-2%,-40%)",
                top: "85%",
                left: "37%",
                fontSize: mobileSize ? "0.8rem" : "1rem",
              }}
            >
              {card.expires}
            </Typography>
          </Box>
        ))}
        {fromPayment && (
          <Box sx={{ textAlign: "center", mt: 1 }}>
            {paymentCards?.map((_, index) => (
              <span
                key={index}
                onClick={() => handleDotClick(index)}
                style={{
                  cursor: "pointer",
                  margin: "0 5px",
                  width: "10px",
                  height: "10px",
                  backgroundColor: index === currentIndex ? "#333" : "#ccc",
                  display: "inline-block",
                  borderRadius: "50%",
                }}
              />
            ))}
          </Box>
        )}
      </Collapse>
    </Box>
  );
};

export default CollapsiblePaymentCard;
