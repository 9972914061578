import CloseIcon from "@mui/icons-material/Close";

import { Grid, Typography, Stack, IconButton, Box } from "@mui/material";

const ChangePlanHeader = ({
  tabletSize,
  mobileSize,
  setOpenDrawer,
  title,
  p1,
  p2,
  formik,
  isLoad,
}) => {
  return (
    <Grid
      container
      sx={{
        display: "flex",
        overflow: "hidden",
        width: "100%",
        position: "relative",
        mb: mobileSize ? 1 : undefined,
      }}
    >
      <Grid item xs={12} sx={tabletSize ? { order: 1, width: "100%" } : {}}>
        <Stack sx={{ justifyContent: "center", flex: "2" }}>
          <Stack
            sx={{
              width: "auto",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <div></div>
            <Typography
              variant={tabletSize ? "h5" : "h4"}
              textAlign="center"
              sx={{ mb: 2, mt: 2 }}
            >
              {title}
            </Typography>

            <Box sx={{ position: "absolute", right: 0, top: 10 }}>
              <IconButton
                disabled={isLoad}
                onClick={() => {
                  setOpenDrawer(false);
                  formik && formik.resetForm();
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Stack>
          <Stack>
            <Typography
              variant={tabletSize ? "body2" : "body1"}
              textAlign="center"
              sx={{ color: "#990000", fontWeight: "bold" }}
            >
              {p1}
            </Typography>
            <Typography
              sx={{ color: "#990000", fontWeight: "bold" }}
              textAlign="center"
              variant={tabletSize ? "body2" : "body1"}
            >
              {p2}
            </Typography>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default ChangePlanHeader;
